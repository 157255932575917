import React, { useEffect } from "react";
import { components } from "react-select";
import WindowedSelect from "../windowed-select";
import { FaviconLogo } from "../favicon-logo";

type Props = Omit<
  React.ComponentProps<typeof WindowedSelect>,
  "windowThreshold"
> & {
  website?: string | null;
};

type NativeControlProps = React.ComponentProps<typeof components.Control>;
type CustomControlProps = NativeControlProps & {
  selectProps: Props;
};

const CustomControl = ({ children, ...props }: CustomControlProps) => {
  return (
    <components.Control {...props}>
      <FaviconLogo website={props.selectProps.website} />
      {children}
    </components.Control>
  );
};

export const InstitutionSelect = (props: Props) => {
  return (
    <WindowedSelect
      {...props}
      windowThreshold={100}
      isSearchable
      components={{ Control: CustomControl }}
    />
  );
};
