import React from "react";

type Props = {
  title: string;
  value: string;
  direction?: "up" | "down";
};

export function CalculatorResult(props: Props) {
  return (
    <div className="calculator-result">
      <div className="calculator-result-title">{props.title}</div>

      <div className="calculator-result-value">
        {props.direction === "down" ? (
          <svg
            width="25"
            height="15"
            viewBox="0 0 25 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.0227 3.46702L13.6522 13.8375C13.0158 14.4739 11.984 14.4739 11.3477 13.8375L0.977171 3.46702C0.340786 2.83063 0.340786 1.79885 0.977171 1.16247C1.61356 0.526084 2.64534 0.526084 3.28173 1.16247L12.3601 10.2409L12.6397 10.2409L21.7182 1.16247C22.3545 0.526084 23.3863 0.526085 24.0227 1.16247C24.6591 1.79885 24.6591 2.83064 24.0227 3.46702Z"
              fill="#34A367"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.477289 11.5329L10.8478 1.16247C11.4842 0.526087 12.516 0.526087 13.1523 1.16247L23.5228 11.5329C24.1592 12.1693 24.1592 13.2011 23.5228 13.8375C22.8864 14.4739 21.8547 14.4739 21.2183 13.8375L12.1399 4.75909L11.8603 4.75909L2.78184 13.8375C2.14546 14.4739 1.11367 14.4739 0.477289 13.8375C-0.159096 13.2011 -0.159096 12.1693 0.477289 11.5329Z"
              fill="#34A367"
            />
          </svg>
        )}
        {props.value}
      </div>
    </div>
  );
}
