import React, { FormEvent, useEffect, useLayoutEffect } from "react";
import { options } from "./credit-union-options";
import { GridLoader } from "react-spinners";
import { InstitutionSelect } from "../institution-select";
import { creditUnionData } from "./credit-union-data";
import { adjustFeatureListPadding, validateEmail } from "../../utils";
import { FaviconLogo } from "../favicon-logo";
import { CalculatorResult } from "./calculator-result";

const loadingTimeout = 4000;

function LoadingText() {
  const [text, setText] = React.useState<string>(
    "Sit tight! Your personalized results are being prepared."
  );

  useEffect(() => {
    setTimeout(() => {
      setText("We're adding the final touches to your calculations!");
    }, loadingTimeout / 2);
  }, []);

  return <>{text}</>;
}

export function Calculator() {
  const [selectedCreditUnion, setSelectedCreditUnion] =
    React.useState<string>();
  const [step, setStep] = React.useState<
    "initial" | "processing" | "email" | "results"
  >("initial");
  const [email, setEmail] = React.useState<string>();

  function handleInstitutionSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (selectedCreditUnion) {
      setStep("processing");
    } else {
      alert("Please select an institution.");
    }
  }

  async function handleEmailSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (email && validateEmail(email)) {
      const result = await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/44718098/3cebed56-4a1b-4b48-9332-c660423a9f3c",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                name: "email",
                value: email,
              },
              {
                name: "institution",
                value: selectedCreditUnion,
              },
            ],
          }),
        }
      );
      setStep("results");
    } else {
      alert("Please enter a valid email address.");
    }
  }

  useEffect(() => {
    if (step === "processing") {
      setTimeout(() => {
        setStep("email");
      }, loadingTimeout);
    }
  }, [step]);

  useLayoutEffect(() => {
    adjustFeatureListPadding();
    window.addEventListener("resize", adjustFeatureListPadding);

    return () => {
      window.removeEventListener("resize", adjustFeatureListPadding);
    };
  });

  function renderStep() {
    const name = creditUnionData[selectedCreditUnion as string]?.name;
    const website = creditUnionData[selectedCreditUnion as string]?.website;

    switch (step) {
      case "initial":
        return (
          <form onSubmit={handleInstitutionSubmit}>
            <h4>Get your tailored results</h4>
            <label htmlFor="institutionSelect">
              Your credit union’s website
            </label>
            <div className="input-row">
              <InstitutionSelect
                className="institution-select"
                inputId="institutionSelect"
                website={website}
                options={options}
                onChange={(selected) =>
                  setSelectedCreditUnion(
                    (selected as { label: string; value: string })?.value
                  )
                }
              />
              <button className="cta-primary">Get results</button>
            </div>
          </form>
        );
      case "processing":
        return (
          <div className="calculator-loading">
            <GridLoader color="#328c92" />
            <h4 className="h4">Processing</h4>
            <p className="body-text-small">
              <LoadingText />
            </p>
          </div>
        );
      case "email":
        return (
          <form onSubmit={handleEmailSubmit}>
            <h4>Your custom results</h4>
            <div className="selected-institution-row">
              <FaviconLogo website={website} />
              <span className="selected-institution">{name}</span>
            </div>
            <p>Results are ready! Provide an email to see them.</p>
            <div className="input-container">
              <svg
                width="25"
                height="19"
                viewBox="0 0 25 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.65383 18.8333C2.04017 18.8333 1.52778 18.6278 1.11667 18.2167C0.705556 17.8056 0.5 17.2932 0.5 16.6795V2.3205C0.5 1.70684 0.705556 1.19445 1.11667 0.783338C1.52778 0.372227 2.04017 0.166672 2.65383 0.166672H22.3462C22.9598 0.166672 23.4722 0.372227 23.8833 0.783338C24.2944 1.19445 24.5 1.70684 24.5 2.3205V16.6795C24.5 17.2932 24.2944 17.8056 23.8833 18.2167C23.4722 18.6278 22.9598 18.8333 22.3462 18.8333H2.65383ZM12.5 9.65387L1.83333 2.67951V16.6795C1.83333 16.9188 1.91026 17.1154 2.0641 17.2692C2.21794 17.4231 2.41452 17.5 2.65383 17.5H22.3462C22.5855 17.5 22.7821 17.4231 22.9359 17.2692C23.0897 17.1154 23.1667 16.9188 23.1667 16.6795V2.67951L12.5 9.65387ZM12.5 8.16667L22.7564 1.50001H2.2436L12.5 8.16667ZM1.83333 2.67951V1.50001V16.6795C1.83333 16.9188 1.91026 17.1154 2.0641 17.2692C2.21794 17.4231 2.41452 17.5 2.65383 17.5H1.83333V2.67951Z"
                  fill="#727272"
                />
              </svg>
              <input
                ref={(input) => input?.focus()}
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span className="body-text-small">
              We will never sell your data or email.
            </span>
            <div>
              <button className="cta-primary">See results</button>
            </div>
          </form>
        );
      case "results":
        const creditUnion = creditUnionData[selectedCreditUnion as string];
        return (
          <>
            <h4>Your custom results</h4>
            <div className="selected-institution-row">
              <FaviconLogo website={website} />
              <span className="selected-institution">{name}</span>
            </div>
            <p>
              With MKIII, the below incremental benefits are achievable for your
              credit union.
            </p>
            <div className="calculator-results">
              <CalculatorResult
                title="Loans Produced"
                value={`$${parseFloat(creditUnion?.loansProduced)}M`}
              />
              <CalculatorResult
                title="Members Served"
                value={creditUnion?.membersServed}
              />
              <CalculatorResult
                title="Interest Earned"
                value={`$${creditUnion?.interestEarned}M`}
              />
            </div>
            <div>
              <a
                className="cta-primary"
                href="https://calendly.com/mark3/mkiii-demo"
              >
                Request demo
              </a>
            </div>
          </>
        );
    }
  }

  return (
    <>
      {renderStep()}
      <p className="calculator-disclaimer">
        Disclaimer: The information provided by MarkIII, Inc is for general
        informational purposes only and is derived from publicly available
        information on your credit union. MarkIII, Inc is not liable for any
        direct or indirect damages arising from the use of this information.
      </p>
    </>
  );
}
