import React from "react";
import { createRoot } from "react-dom/client";
import { Calculator } from "./components/calculator";

const container = document.getElementById("calculator");

if (!container) throw new Error("No container!");

const root = createRoot(container);
root.render(<Calculator />);
