import React, { useEffect, useRef, useState } from "react";

type Props = {
  website?: string | null;
};

export function FaviconLogo({ website }: Props) {
  const [useFallback, setUseFallback] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setUseFallback(false);
  }, [website]);

  return (
    <div className="institution-icon">
      {website && !useFallback ? (
        <img
          ref={imageRef}
          src={`https://www.google.com/s2/favicons?domain=${website}&sz=128`}
          onLoad={() => {
            if (
              !imageRef.current?.naturalWidth ||
              imageRef.current?.naturalWidth < 25
            ) {
              setUseFallback(true);
            }
          }}
        />
      ) : (
        <svg
          className="institution-icon-fallback"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.81183 21.3065V10.1237H6.20967V21.3065H4.81183ZM11.8011 21.3065V10.1237H13.1989V21.3065H11.8011ZM0.672028 25.5V24.1022H24.328V25.5H0.672028ZM18.7903 21.3065V10.1237H20.1882V21.3065H18.7903ZM0.672028 7.32797V6.14518L12.5 0.5L24.328 6.14518V7.32797H0.672028ZM4.3656 5.93012H20.6344L12.5 2.08603L4.3656 5.93012Z"
            fill="#727272"
          />
        </svg>
      )}
    </div>
  );
}