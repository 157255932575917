import Typed from "typed.js";

const typed = new Typed(".typed", {
  strings: [
    "profitably",
    "responsibly",
    "with less capital",
    "compliantly",
    "confidently",
    "securely",
    "efficiently",
    "reliably",
    "transparently",
  ],
  typeSpeed: 70,
  backSpeed: 50,
  backDelay: 2000,
  loop: true,
});
