type CreditUnion = {
  loansProduced: string;
  membersServed: string;
  interestEarned: string;
  website: string | null;
  name: string;
};

export const creditUnionData: Record<string, CreditUnion> = {
  "1": {
    loansProduced: "2.12",
    membersServed: "185",
    interestEarned: "0.18",
    name: "MORRIS SHEPPARD TEXARKANA",
    website: "http://www.morrissheppardfcu.org",
  },
  "6": {
    loansProduced: "42.48",
    membersServed: "5,076",
    interestEarned: "3.61",
    name: "THE NEW ORLEANS FIREMEN'S",
    website: "http://www.noffcu.org",
  },
  "12": {
    loansProduced: "5.91",
    membersServed: "2,406",
    interestEarned: "0.50",
    name: "FRANKLIN TRUST",
    website: "http://www.franklintrust.org",
  },
  "13": {
    loansProduced: "182.78",
    membersServed: "9,452",
    interestEarned: "15.54",
    name: "EFCU FINANCIAL",
    website: "http://www.efcuFINANCIAL.org",
  },
  "16": {
    loansProduced: "0.70",
    membersServed: "74",
    interestEarned: "0.06",
    name: "WOODMEN",
    website: "http://WWW.WOODMENFCU.ORG",
  },
  "19": {
    loansProduced: "0.44",
    membersServed: "66",
    interestEarned: "0.04",
    name: "NEW HAVEN TEACHERS",
    website: null,
  },
  "22": {
    loansProduced: "30.05",
    membersServed: "4,313",
    interestEarned: "2.55",
    name: "WATERBURY CONNECTICUT TEACHERS",
    website: "http://www.wctfcu.com",
  },
  "26": {
    loansProduced: "1.32",
    membersServed: "94",
    interestEarned: "0.11",
    name: "FARMERS",
    website: "http://FARMERSFCU.COM",
  },
  "28": {
    loansProduced: "139.09",
    membersServed: "12,980",
    interestEarned: "11.82",
    name: "CENTRIS",
    website: "http://www.centrisfcu.org",
  },
  "42": {
    loansProduced: "9.57",
    membersServed: "1,729",
    interestEarned: "0.81",
    name: "FD COMMUNITY",
    website: "http://www.fdcommunityfcu.org",
  },
  "47": {
    loansProduced: "3.33",
    membersServed: "308",
    interestEarned: "0.28",
    name: "FARGO PUBLIC SCHOOLS",
    website: "http://www.fpsfcu.com",
  },
  "48": {
    loansProduced: "5.49",
    membersServed: "637",
    interestEarned: "0.47",
    name: "EAGLE EXPRESS",
    website: "http://www.eagleexpressfcu.com",
  },
  "51": {
    loansProduced: "589.61",
    membersServed: "79,011",
    interestEarned: "50.12",
    name: "BROADVIEW",
    website: "https://www.broadviewfcu.com",
  },
  "53": {
    loansProduced: "3.46",
    membersServed: "683",
    interestEarned: "0.29",
    name: "MOTION",
    website: "http://www.motionfcu.org",
  },
  "60": {
    loansProduced: "40.22",
    membersServed: "5,447",
    interestEarned: "3.42",
    name: "LEVO",
    website: "http://www.levo.org",
  },
  "75": {
    loansProduced: "0.01",
    membersServed: "6",
    interestEarned: "0.00",
    name: "LONG ISLAND CITY POSTAL EMPLOYEES",
    website: null,
  },
  "77": {
    loansProduced: "30.93",
    membersServed: "1,830",
    interestEarned: "2.63",
    name: "SERVICE FIRST",
    website: "http://www.servicefirstfcu.org",
  },
  "79": {
    loansProduced: "84.63",
    membersServed: "6,438",
    interestEarned: "7.19",
    name: "CAMPUS",
    website: "https://www.campusfederal.org",
  },
  "82": {
    loansProduced: "36.10",
    membersServed: "3,767",
    interestEarned: "3.07",
    name: "FIRST ATLANTIC",
    website: "http://FAFCU.COM",
  },
  "90": {
    loansProduced: "1.32",
    membersServed: "164",
    interestEarned: "0.11",
    name: "POST GAZETTE",
    website: "http://WWW.POSTGAZETTEFCU.ORG",
  },
  "98": {
    loansProduced: "0.95",
    membersServed: "85",
    interestEarned: "0.08",
    name: "NIAGARA FALLS TEACHERS",
    website: "http://www.nftfcu.org",
  },
  "107": {
    loansProduced: "5.21",
    membersServed: "1,798",
    interestEarned: "0.44",
    name: "KEMBA ROANOKE",
    website: "http://WWW.KEMBAFCU.ORG",
  },
  "108": {
    loansProduced: "4.10",
    membersServed: "354",
    interestEarned: "0.35",
    name: "KEMBA CHARLESTON",
    website: "http://KEMBACHARLESTONFCU.ORG",
  },
  "110": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "ITALO-AMERICAN",
    website: "http://www.italoamericanfcu.com",
  },
  "119": {
    loansProduced: "0.95",
    membersServed: "306",
    interestEarned: "0.08",
    name: "TRIBORO POSTAL",
    website: "http://www.tpfcu.net",
  },
  "120": {
    loansProduced: "6.07",
    membersServed: "557",
    interestEarned: "0.52",
    name: "COUNTY EDUCATORS",
    website: "http://WWW.COUNTYEDFCU.ORG",
  },
  "132": {
    loansProduced: "0.05",
    membersServed: "14",
    interestEarned: "0.00",
    name: "MOUNT VERNON NY POSTAL EMPLOYEES",
    website: null,
  },
  "135": {
    loansProduced: "7.69",
    membersServed: "1,034",
    interestEarned: "0.65",
    name: "OMAHA",
    website: "www.omahafcu.org",
  },
  "138": {
    loansProduced: "6.58",
    membersServed: "585",
    interestEarned: "0.56",
    name: "VIGO COUNTY",
    website: "http://vigocountyfcu.org",
  },
  "146": {
    loansProduced: "1.03",
    membersServed: "47",
    interestEarned: "0.09",
    name: "TEL-U-WATT",
    website: "http://www.teluwatt.com",
  },
  "147": {
    loansProduced: "0.25",
    membersServed: "12",
    interestEarned: "0.02",
    name: "NORTHERN STS PWR COMPANY EMP",
    website: null,
  },
  "150": {
    loansProduced: "29.37",
    membersServed: "4,516",
    interestEarned: "2.50",
    name: "FEDCHOICE",
    website: "http://www.fedchoice.org",
  },
  "151": {
    loansProduced: "72.89",
    membersServed: "10,618",
    interestEarned: "6.20",
    name: "JUSTICE",
    website: "www.jfcu.org",
  },
  "153": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "MERIDEN POSTAL EMPLOYEES",
    website: null,
  },
  "159": {
    loansProduced: "138.26",
    membersServed: "16,107",
    interestEarned: "11.75",
    name: "THREE RIVERS",
    website: "http://www.3riversfcu.org",
  },
  "162": {
    loansProduced: "12.04",
    membersServed: "1,422",
    interestEarned: "1.02",
    name: "TREASURY DEPARTMENT",
    website: "http://www.tdfcu.org",
  },
  "167": {
    loansProduced: "37.50",
    membersServed: "4,500",
    interestEarned: "3.19",
    name: "DAKOTALAND",
    website: "http://www.dakotalandfcu.com",
  },
  "168": {
    loansProduced: "4.19",
    membersServed: "314",
    interestEarned: "0.36",
    name: "NORTHERN INDIANA",
    website: "http://www.nifedcu.org",
  },
  "169": {
    loansProduced: "0.16",
    membersServed: "34",
    interestEarned: "0.01",
    name: "GARY FIREFIGHTERS ASSOCIATION",
    website: null,
  },
  "174": {
    loansProduced: "54.31",
    membersServed: "6,754",
    interestEarned: "4.62",
    name: "LOUISIANA",
    website: "http://www.louisianafcu.org",
  },
  "176": {
    loansProduced: "101.66",
    membersServed: "15,568",
    interestEarned: "8.64",
    name: "EDUCATIONAL",
    website: "http://www.edfed.org",
  },
  "187": {
    loansProduced: "2.04",
    membersServed: "200",
    interestEarned: "0.17",
    name: "FLORIDA A & M UNIVERSITY",
    website: "http://www.famufcu.com",
  },
  "195": {
    loansProduced: "3.82",
    membersServed: "283",
    interestEarned: "0.32",
    name: "NORTHWEST ARKANSAS",
    website: "http://www.nwarkansasfcu.com",
  },
  "196": {
    loansProduced: "384.81",
    membersServed: "36,742",
    interestEarned: "32.71",
    name: "GTE",
    website: "http://www.gtefinancial.org",
  },
  "198": {
    loansProduced: "3.22",
    membersServed: "153",
    interestEarned: "0.27",
    name: "WHITING REFINERY",
    website: "http://www.wrfcu.org",
  },
  "199": {
    loansProduced: "26.14",
    membersServed: "1,994",
    interestEarned: "2.22",
    name: "MEMBERS CHOICE OF CENTRAL TEXAS",
    website: "http://www.choicefcu.com",
  },
  "200": {
    loansProduced: "0.79",
    membersServed: "78",
    interestEarned: "0.07",
    name: "A T & T EMPLOYEES PITTSBURGH",
    website: "http://www.attpghfcu.com",
  },
  "205": {
    loansProduced: "27.61",
    membersServed: "2,584",
    interestEarned: "2.35",
    name: "ALLEGENT COMMUNITY",
    website: "http://www.allegentfcu.org",
  },
  "216": {
    loansProduced: "2.92",
    membersServed: "635",
    interestEarned: "0.25",
    name: "FEDFINANCIAL",
    website: "http://www.fedfinancial.org",
  },
  "220": {
    loansProduced: "4.90",
    membersServed: "291",
    interestEarned: "0.42",
    name: "HOPE",
    website: "http://www.hopefcu.com",
  },
  "224": {
    loansProduced: "26.25",
    membersServed: "4,027",
    interestEarned: "2.23",
    name: "MAGNOLIA",
    website: "http://www.magfedcu.org",
  },
  "227": {
    loansProduced: "3,016.45",
    membersServed: "467,674",
    interestEarned: "256.40",
    name: "PENTAGON",
    website: "http://www.penfed.org",
  },
  "233": {
    loansProduced: "17.82",
    membersServed: "2,357",
    interestEarned: "1.51",
    name: "INFIRST",
    website: "http://www.infirstfcu.org",
  },
  "234": {
    loansProduced: "3.99",
    membersServed: "474",
    interestEarned: "0.34",
    name: "PITTSBURGH FIREFIGHTER'S",
    website: "http://www.pghffcu.org",
  },
  "240": {
    loansProduced: "8.29",
    membersServed: "983",
    interestEarned: "0.70",
    name: "GREATER PITTSBURGH POLICE",
    website: "http://www.pittsburghpolicefcu.com",
  },
  "242": {
    loansProduced: "7.68",
    membersServed: "905",
    interestEarned: "0.65",
    name: "GULF COAST COMMUNITY",
    website: "http://www.gulfcoastcommunityfcu.or",
  },
  "256": {
    loansProduced: "25.63",
    membersServed: "1,720",
    interestEarned: "2.18",
    name: "ENERGY ONE",
    website: "http://www.energyone.org",
  },
  "258": {
    loansProduced: "37.64",
    membersServed: "6,212",
    interestEarned: "3.20",
    name: "HOUSTON TEXAS FIRE FIGHTERS",
    website: "http://htfffcu.org",
  },
  "262": {
    loansProduced: "4.60",
    membersServed: "439",
    interestEarned: "0.39",
    name: "EP",
    website: "http://www.epfcu.org",
  },
  "266": {
    loansProduced: "17.28",
    membersServed: "1,997",
    interestEarned: "1.47",
    name: "LIBRARY OF CONGRESS",
    website: "http://WWW.LCFCU.ORG",
  },
  "274": {
    loansProduced: "25.84",
    membersServed: "1,770",
    interestEarned: "2.20",
    name: "AREA",
    website: "http://www.areafcu.org",
  },
  "277": {
    loansProduced: "0.20",
    membersServed: "38",
    interestEarned: "0.02",
    name: "CLIFTON NJ POSTAL EMPLOYEES",
    website: null,
  },
  "287": {
    loansProduced: "1.52",
    membersServed: "96",
    interestEarned: "0.13",
    name: "USNE PENITENTIARY EMPLOYEES",
    website: "http://WWW.USNEPFCU.ORG",
  },
  "288": {
    loansProduced: "1.69",
    membersServed: "176",
    interestEarned: "0.14",
    name: "FARGO VA",
    website: "http://www.fargovafcu.org",
  },
  "294": {
    loansProduced: "57.45",
    membersServed: "5,303",
    interestEarned: "4.88",
    name: "CME",
    website: "https://www.cmefcu.org",
  },
  "303": {
    loansProduced: "10.13",
    membersServed: "1,754",
    interestEarned: "0.86",
    name: "HERITAGE VALLEY",
    website: "http://heritagevalleyfcu.org",
  },
  "306": {
    loansProduced: "9.08",
    membersServed: "1,372",
    interestEarned: "0.77",
    name: "MADISON COUNTY",
    website: "http://www.madcofcu.org",
  },
  "319": {
    loansProduced: "25.81",
    membersServed: "2,884",
    interestEarned: "2.19",
    name: "DEPARTMENT OF THE INTERIOR",
    website: "http://www.interiorfcu.org",
  },
  "320": {
    loansProduced: "4.69",
    membersServed: "603",
    interestEarned: "0.40",
    name: "F R B",
    website: "http://frbfcu.org",
  },
  "339": {
    loansProduced: "0.94",
    membersServed: "154",
    interestEarned: "0.08",
    name: "MUNCIE",
    website: "http://www.munciefcu.org",
  },
  "340": {
    loansProduced: "60.27",
    membersServed: "5,254",
    interestEarned: "5.12",
    name: "RADIFI",
    website: "http://www.radificu.org",
  },
  "352": {
    loansProduced: "60.36",
    membersServed: "4,686",
    interestEarned: "5.13",
    name: "TAMPA BAY",
    website: "http://www.tampabayfederal.com",
  },
  "361": {
    loansProduced: "66.32",
    membersServed: "7,555",
    interestEarned: "5.64",
    name: "SPERO FINANCIAL",
    website: "https://www.spero.financial",
  },
  "365": {
    loansProduced: "14.98",
    membersServed: "1,511",
    interestEarned: "1.27",
    name: "GP",
    website: "www.gpfederal.org",
  },
  "367": {
    loansProduced: "1.99",
    membersServed: "451",
    interestEarned: "0.17",
    name: "PEPCO",
    website: "Pepcofcu.org",
  },
  "370": {
    loansProduced: "1.05",
    membersServed: "209",
    interestEarned: "0.09",
    name: "PPG & ASSOCIATES",
    website: "http://ppgfcu.org",
  },
  "373": {
    loansProduced: "6.31",
    membersServed: "965",
    interestEarned: "0.54",
    name: "NEW ALLIANCE",
    website: "https://www.newalliancefcu.com",
  },
  "376": {
    loansProduced: "1.55",
    membersServed: "108",
    interestEarned: "0.13",
    name: "REGIONAL MEMBERS",
    website: "http://www.rmfcu.org",
  },
  "378": {
    loansProduced: "0.65",
    membersServed: "59",
    interestEarned: "0.06",
    name: "FLORIDA CUSTOMS",
    website: "http://www.flcustomsfcu.org",
  },
  "383": {
    loansProduced: "4.38",
    membersServed: "488",
    interestEarned: "0.37",
    name: "ON THE GRID FINANCIAL",
    website: "http://www.cuonthegrid.com",
  },
  "389": {
    loansProduced: "13.14",
    membersServed: "3,511",
    interestEarned: "1.12",
    name: "CINCINNATI OHIO POLICE",
    website: "http://www.copfcu.com",
  },
  "390": {
    loansProduced: "4.74",
    membersServed: "580",
    interestEarned: "0.40",
    name: "ELLIOTT COMMUNITY",
    website: "http://elliottfcu.com",
  },
  "395": {
    loansProduced: "19.68",
    membersServed: "1,348",
    interestEarned: "1.67",
    name: "FIREFIGHTERS & COMPANY",
    website: "http://www.ffcocu.org",
  },
  "404": {
    loansProduced: "28.28",
    membersServed: "3,488",
    interestEarned: "2.40",
    name: "GREATER NEW ORLEANS",
    website: "http://www.gnofcu.com",
  },
  "422": {
    loansProduced: "1.04",
    membersServed: "185",
    interestEarned: "0.09",
    name: "PITTSBURGH",
    website: "http://www.pittsburghfcu.org",
  },
  "430": {
    loansProduced: "7.92",
    membersServed: "1,058",
    interestEarned: "0.67",
    name: "DEEPWATER INDUSTRIES",
    website: "http://www.deepwaterind.org",
  },
  "435": {
    loansProduced: "132.17",
    membersServed: "14,965",
    interestEarned: "11.23",
    name: "STATE DEPARTMENT",
    website: "http://www.sdfcu.org",
  },
  "444": {
    loansProduced: "1.65",
    membersServed: "125",
    interestEarned: "0.14",
    name: "SCA",
    website: "http://shenangochinafcu.com",
  },
  "452": {
    loansProduced: "3.93",
    membersServed: "739",
    interestEarned: "0.33",
    name: "SKYLINE FINANCIAL",
    website: "http://www.skylinefinancialfcu.com",
  },
  "464": {
    loansProduced: "6.75",
    membersServed: "1,676",
    interestEarned: "0.57",
    name: "EAGLE ONE",
    website: "http://www.eagleonefcu.org",
  },
  "473": {
    loansProduced: "12.65",
    membersServed: "742",
    interestEarned: "1.08",
    name: "RIVERTRUST",
    website: "http://www.rivertrustfcu.com",
  },
  "475": {
    loansProduced: "8.68",
    membersServed: "1,042",
    interestEarned: "0.74",
    name: "INTEGRITY",
    website: "http://www.integrityfcu.org",
  },
  "477": {
    loansProduced: "28.48",
    membersServed: "3,548",
    interestEarned: "2.42",
    name: "THE ATLANTIC",
    website: "https://web.atlfedcu.com",
  },
  "479": {
    loansProduced: "6.93",
    membersServed: "503",
    interestEarned: "0.59",
    name: "PARKVIEW COMMUNITY",
    website: "http://www.pc-fcu.org",
  },
  "484": {
    loansProduced: "1.59",
    membersServed: "226",
    interestEarned: "0.13",
    name: "LYNN FIREMENS",
    website: "http://www.lynnffcu.com",
  },
  "489": {
    loansProduced: "26.20",
    membersServed: "2,974",
    interestEarned: "2.23",
    name: "PRIMETRUST FINANCIAL",
    website: "http://www.primetrustcu.com",
  },
  "490": {
    loansProduced: "7.71",
    membersServed: "1,161",
    interestEarned: "0.66",
    name: "RAILS WEST",
    website: "http://www.railswestcu.org",
  },
  "511": {
    loansProduced: "21.59",
    membersServed: "4,614",
    interestEarned: "1.84",
    name: "BFG",
    website: "http://bfgfcu.org",
  },
  "514": {
    loansProduced: "1.47",
    membersServed: "127",
    interestEarned: "0.13",
    name: "BEN E KEITH COMPANY EMPLOYEES",
    website: "http://www.bekefcu.org",
  },
  "517": {
    loansProduced: "4.70",
    membersServed: "489",
    interestEarned: "0.40",
    name: "SEI",
    website: "http://www.seius.org",
  },
  "524": {
    loansProduced: "0.52",
    membersServed: "46",
    interestEarned: "0.04",
    name: "BOSTON CUSTOMS",
    website: "bostoncustomsfcu.org",
  },
  "528": {
    loansProduced: "69.68",
    membersServed: "5,707",
    interestEarned: "5.92",
    name: "UTAH FIRST",
    website: "http://www.utahfirst.com",
  },
  "538": {
    loansProduced: "2.45",
    membersServed: "722",
    interestEarned: "0.21",
    name: "GOVERNMENT PRINTING OFFICE",
    website: "http://www.gpofcu.org",
  },
  "566": {
    loansProduced: "297.21",
    membersServed: "23,138",
    interestEarned: "25.26",
    name: "NUVISION",
    website: "http://www.nuvisionfederal.org",
  },
  "567": {
    loansProduced: "1.09",
    membersServed: "102",
    interestEarned: "0.09",
    name: "AMBRAW",
    website: "http://ambrawfcu.com",
  },
  "577": {
    loansProduced: "4.06",
    membersServed: "310",
    interestEarned: "0.35",
    name: "MEMBER PREFERRED",
    website: "http://www.memberpreferredfcu.com",
  },
  "594": {
    loansProduced: "17.44",
    membersServed: "2,527",
    interestEarned: "1.48",
    name: "PASADENA",
    website: "http://www.pfcu.org",
  },
  "606": {
    loansProduced: "14.61",
    membersServed: "1,328",
    interestEarned: "1.24",
    name: "COMTRUST",
    website: "https://www.comtrustfcu.com",
  },
  "609": {
    loansProduced: "6.08",
    membersServed: "488",
    interestEarned: "0.52",
    name: "NY FIREFIGHTERS BRAVEST",
    website: "https://www.nybravestfcu.org/",
  },
  "613": {
    loansProduced: "6.65",
    membersServed: "989",
    interestEarned: "0.57",
    name: "EMPIRE ONE",
    website: "http://www.empireonefcu.org",
  },
  "616": {
    loansProduced: "4.49",
    membersServed: "537",
    interestEarned: "0.38",
    name: "NEW HORIZON",
    website: "http://www.newhorizonfcu.com",
  },
  "619": {
    loansProduced: "275.07",
    membersServed: "12,308",
    interestEarned: "23.38",
    name: "LAFAYETTE",
    website: "http://www.lfcu.org",
  },
  "620": {
    loansProduced: "2.39",
    membersServed: "353",
    interestEarned: "0.20",
    name: "POWER ONE",
    website: "http://poweronefcu.org",
  },
  "624": {
    loansProduced: "126.43",
    membersServed: "12,157",
    interestEarned: "10.75",
    name: "UNITED STATES SENATE",
    website: "https://www.ussfcu.org",
  },
  "640": {
    loansProduced: "0.71",
    membersServed: "55",
    interestEarned: "0.06",
    name: "MAINE MEDIA",
    website: "http://www.mainemedia.com",
  },
  "644": {
    loansProduced: "5.96",
    membersServed: "1,154",
    interestEarned: "0.51",
    name: "TRAILHEAD",
    website: "http://www.trailheadcu.org",
  },
  "648": {
    loansProduced: "0.99",
    membersServed: "604",
    interestEarned: "0.08",
    name: "HOWARD UNIVERSITY EMPLOYEES",
    website: "http://www.huefcu.org",
  },
  "649": {
    loansProduced: "7.13",
    membersServed: "2,270",
    interestEarned: "0.61",
    name: "VALLEY 1ST COMMUNITY",
    website: "http://WWW.VALLEY1ST.ORG",
  },
  "663": {
    loansProduced: "2.14",
    membersServed: "252",
    interestEarned: "0.18",
    name: "PRIORITY PLUS",
    website: "http://www.priorityplusfcu.org",
  },
  "665": {
    loansProduced: "37.39",
    membersServed: "3,752",
    interestEarned: "3.18",
    name: "PENINSULA COMMUNITY",
    website: "www.pcfcu.org",
  },
  "678": {
    loansProduced: "2.36",
    membersServed: "845",
    interestEarned: "0.20",
    name: "FAMILY 1ST OF TEXAS",
    website: "http://www.family1stfcu.org",
  },
  "680": {
    loansProduced: "1.82",
    membersServed: "256",
    interestEarned: "0.15",
    name: "ROCHESTER AREA STATE EMPLOYEES",
    website: "http://rasefcu.org",
  },
  "687": {
    loansProduced: "10.94",
    membersServed: "762",
    interestEarned: "0.93",
    name: "STAGEPOINT",
    website: "http://www.stagepointfcu.com",
  },
  "690": {
    loansProduced: "14.39",
    membersServed: "1,012",
    interestEarned: "1.22",
    name: "CHATTANOOGA AREA SCHOOLS",
    website: "http://CASFCU.COM",
  },
  "716": {
    loansProduced: "4.08",
    membersServed: "411",
    interestEarned: "0.35",
    name: "BETHLEHEM 1ST",
    website: "http://www.beth1st.org",
  },
  "722": {
    loansProduced: "3.99",
    membersServed: "404",
    interestEarned: "0.34",
    name: "FIRST BRISTOL",
    website: "http://www.firstbristol.org",
  },
  "761": {
    loansProduced: "10.55",
    membersServed: "1,447",
    interestEarned: "0.90",
    name: "SKY",
    website: "http://www.skyfcu.org",
  },
  "770": {
    loansProduced: "9.53",
    membersServed: "732",
    interestEarned: "0.81",
    name: "COMMUNITY RESOURCE",
    website: "http://www.communityresourcefcu.com",
  },
  "785": {
    loansProduced: "6.22",
    membersServed: "452",
    interestEarned: "0.53",
    name: "UNIVERSAL",
    website: "http://www.universalfcu.com",
  },
  "794": {
    loansProduced: "9.21",
    membersServed: "988",
    interestEarned: "0.78",
    name: "TRI BORO",
    website: "http://www.triboro-fcu.org",
  },
  "795": {
    loansProduced: "0.82",
    membersServed: "86",
    interestEarned: "0.07",
    name: "HUNTINGTON WEST VA FIREMENS",
    website: null,
  },
  "798": {
    loansProduced: "0.29",
    membersServed: "307",
    interestEarned: "0.02",
    name: "TRANSIT AUTHORITY DIVISION B",
    website: "http://www.divbfcu.com",
  },
  "808": {
    loansProduced: "170.53",
    membersServed: "12,124",
    interestEarned: "14.49",
    name: "U.S. EAGLE",
    website: "http://www.useagle.org",
  },
  "817": {
    loansProduced: "1.65",
    membersServed: "339",
    interestEarned: "0.14",
    name: "ROCHESTER & MONROE CO EMP",
    website: "http://www.rocmon.org",
  },
  "818": {
    loansProduced: "3.00",
    membersServed: "194",
    interestEarned: "0.26",
    name: "GOWANDA AREA",
    website: "https://gafcu.org",
  },
  "832": {
    loansProduced: "12.90",
    membersServed: "2,880",
    interestEarned: "1.10",
    name: "UPPER CUMBERLAND",
    website: "http://www.ucfcu.org",
  },
  "838": {
    loansProduced: "2.00",
    membersServed: "224",
    interestEarned: "0.17",
    name: "APS",
    website: "http://www.apsfcu.org",
  },
  "839": {
    loansProduced: "4.11",
    membersServed: "575",
    interestEarned: "0.35",
    name: "FIRSTENERGY CHOICE",
    website: "http://www.fechoice.com",
  },
  "841": {
    loansProduced: "1.02",
    membersServed: "107",
    interestEarned: "0.09",
    name: "ALLEGHENY CENTRAL",
    website: "http://www.acefcu.org",
  },
  "845": {
    loansProduced: "3.63",
    membersServed: "260",
    interestEarned: "0.31",
    name: "BUFFALO CONRAIL",
    website: "bufconfcu.com",
  },
  "850": {
    loansProduced: "16.06",
    membersServed: "1,916",
    interestEarned: "1.36",
    name: "VALLEY",
    website: "http://www.vfcu.net",
  },
  "851": {
    loansProduced: "2.82",
    membersServed: "207",
    interestEarned: "0.24",
    name: "CHELSEA EMPLOYEES",
    website: "http://www.ChelseaEFCU.org",
  },
  "854": {
    loansProduced: "23.81",
    membersServed: "3,745",
    interestEarned: "2.02",
    name: "SEASONS",
    website: "http://www.seasonsfcu.org",
  },
  "856": {
    loansProduced: "4.49",
    membersServed: "1,782",
    interestEarned: "0.38",
    name: "MOUNTAIN STAR",
    website: "http://www.mountainstarfcu.org",
  },
  "857": {
    loansProduced: "319.31",
    membersServed: "28,921",
    interestEarned: "27.14",
    name: "AFFINITY",
    website: "affinityfcu.com",
  },
  "858": {
    loansProduced: "2.29",
    membersServed: "826",
    interestEarned: "0.19",
    name: "KENMORE N. Y. TEACHERS",
    website: "http://kenteachfcu.com",
  },
  "871": {
    loansProduced: "8.21",
    membersServed: "660",
    interestEarned: "0.70",
    name: "ZELLCO",
    website: "http://zellcofcu.org",
  },
  "872": {
    loansProduced: "12.32",
    membersServed: "1,008",
    interestEarned: "1.05",
    name: "MIAMI FIREFIGHTERS",
    website: "www.mffcu.org",
  },
  "884": {
    loansProduced: "1.64",
    membersServed: "135",
    interestEarned: "0.14",
    name: "ATT-WYO CORRECTIONAL EMPLOYEES",
    website: "http://www.attwyocefcu.com",
  },
  "887": {
    loansProduced: "12.43",
    membersServed: "2,123",
    interestEarned: "1.06",
    name: "BILLINGS",
    website: "http://www.billingsfcu.org",
  },
  "888": {
    loansProduced: "0.75",
    membersServed: "99",
    interestEarned: "0.06",
    name: "COXSACKIE CORRECTIONAL EMP.",
    website: null,
  },
  "900": {
    loansProduced: "0.78",
    membersServed: "131",
    interestEarned: "0.07",
    name: "AMBRIDGE AREA",
    website: "http://www.ambridgeareafcu.com",
  },
  "907": {
    loansProduced: "1.27",
    membersServed: "170",
    interestEarned: "0.11",
    name: "MOHAWK PROGRESSIVE",
    website: "http://www.mohawkprogressivefcu.com",
  },
  "909": {
    loansProduced: "3.14",
    membersServed: "346",
    interestEarned: "0.27",
    name: "PRR SOUTH FORK",
    website: "http://www.prrsouthfork.com",
  },
  "911": {
    loansProduced: "13.49",
    membersServed: "970",
    interestEarned: "1.15",
    name: "STAR USA",
    website: "http://www.star.coop",
  },
  "916": {
    loansProduced: "0.81",
    membersServed: "71",
    interestEarned: "0.07",
    name: "ELIZABETH (N.J.) FIREMEN'S",
    website: "http://home.earthlink.net/~enjffcu",
  },
  "926": {
    loansProduced: "18.47",
    membersServed: "4,793",
    interestEarned: "1.57",
    name: "RIVER CITY",
    website: "http://www.rcityfcu.org",
  },
  "930": {
    loansProduced: "0.07",
    membersServed: "41",
    interestEarned: "0.01",
    name: "AMNH EMPLOYEES",
    website: null,
  },
  "932": {
    loansProduced: "1.49",
    membersServed: "184",
    interestEarned: "0.13",
    name: "OTEEN V. A.",
    website: "http://www.oteencreditunion.com",
  },
  "943": {
    loansProduced: "0.51",
    membersServed: "35",
    interestEarned: "0.04",
    name: "SOMERVILLE MASS FIREFIGHTERS",
    website: null,
  },
  "946": {
    loansProduced: "8.70",
    membersServed: "834",
    interestEarned: "0.74",
    name: "MUSKOGEE",
    website: "http://www.mfcuonline.org",
  },
  "948": {
    loansProduced: "473.69",
    membersServed: "50,823",
    interestEarned: "40.26",
    name: "NUSENDA",
    website: "http://www.nusenda.org",
  },
  "951": {
    loansProduced: "2.59",
    membersServed: "383",
    interestEarned: "0.22",
    name: "POSTAL FAMILY",
    website: "http://www.postalfamilyfcu.com",
  },
  "960": {
    loansProduced: "36.22",
    membersServed: "2,922",
    interestEarned: "3.08",
    name: "PACIFIC CREST",
    website: "https://www.mypcfcu.org",
  },
  "964": {
    loansProduced: "4.44",
    membersServed: "758",
    interestEarned: "0.38",
    name: "SOUTHWEST",
    website: "http://www.sfcunm.org",
  },
  "967": {
    loansProduced: "22.64",
    membersServed: "2,356",
    interestEarned: "1.92",
    name: "GOLD COAST",
    website: "http://GCFCU.ORG",
  },
  "971": {
    loansProduced: "3.38",
    membersServed: "571",
    interestEarned: "0.29",
    name: "LAW ENFORCEMENT & TECHNOLOGY",
    website: "https://WWW.UNITEDPOLICEFCU.COM",
  },
  "986": {
    loansProduced: "1.37",
    membersServed: "176",
    interestEarned: "0.12",
    name: "URE",
    website: "http://urefcu.org",
  },
  "988": {
    loansProduced: "4.73",
    membersServed: "652",
    interestEarned: "0.40",
    name: "AUBURN COMMUNITY",
    website: "http://www.auburnfcu.com",
  },
  "1000": {
    loansProduced: "22.78",
    membersServed: "1,194",
    interestEarned: "1.94",
    name: "SIERRA PACIFIC",
    website: "http://www.sierrapacificfcu.org",
  },
  "1015": {
    loansProduced: "13.17",
    membersServed: "1,566",
    interestEarned: "1.12",
    name: "NORTH JERSEY",
    website: "http://njfcu.org",
  },
  "1021": {
    loansProduced: "14.77",
    membersServed: "1,365",
    interestEarned: "1.26",
    name: "FIRST FINANCIAL",
    website: "http://www.firstffcu.com",
  },
  "1022": {
    loansProduced: "201.05",
    membersServed: "24,818",
    interestEarned: "17.09",
    name: "SOUTH CAROLINA",
    website: "http://www.scfederal.org",
  },
  "1034": {
    loansProduced: "10.25",
    membersServed: "1,399",
    interestEarned: "0.87",
    name: "OLIVE VIEW EMPLOYEES",
    website: "http://www.OliveViewFCU.com",
  },
  "1040": {
    loansProduced: "197.09",
    membersServed: "13,957",
    interestEarned: "16.75",
    name: "CAPITAL EDUCATORS",
    website: "http://www.capedcu.com",
  },
  "1049": {
    loansProduced: "4.38",
    membersServed: "627",
    interestEarned: "0.37",
    name: "MEMBERSFIRST CT",
    website: "http://MembersFirstCTFCU.com",
  },
  "1057": {
    loansProduced: "0.29",
    membersServed: "47",
    interestEarned: "0.02",
    name: "MT LEBANON",
    website: "http://www.mtlcu.org",
  },
  "1059": {
    loansProduced: "0.49",
    membersServed: "89",
    interestEarned: "0.04",
    name: "GRAND CENTRAL TERMINAL EMPLOYEES",
    website: null,
  },
  "1068": {
    loansProduced: "4.58",
    membersServed: "349",
    interestEarned: "0.39",
    name: "COMPASS FINANCIAL",
    website: "http://www.compassffcu.org",
  },
  "1074": {
    loansProduced: "70.08",
    membersServed: "6,615",
    interestEarned: "5.96",
    name: "FARMERS INSURANCE GROUP",
    website: "http://www.figfcu.com",
  },
  "1077": {
    loansProduced: "1.65",
    membersServed: "331",
    interestEarned: "0.14",
    name: "TOBACCO VALLEY TEACHERS",
    website: "http://www.tvtfcu.org",
  },
  "1080": {
    loansProduced: "2.28",
    membersServed: "193",
    interestEarned: "0.19",
    name: "DISTRICT #6",
    website: "http://www.district6fcu.com",
  },
  "1086": {
    loansProduced: "2.92",
    membersServed: "237",
    interestEarned: "0.25",
    name: "SUWANNEE RIVER",
    website: "http://www.suwanneeriverfcu.com",
  },
  "1108": {
    loansProduced: "5.89",
    membersServed: "2,622",
    interestEarned: "0.50",
    name: "CENTRAL JERSEY",
    website: "http://www.cjfcu.org",
  },
  "1111": {
    loansProduced: "0.59",
    membersServed: "68",
    interestEarned: "0.05",
    name: "LOCOMOTIVE & CONTROL EMPLOYEES",
    website: "http://locomotivecontrolefcu.com",
  },
  "1113": {
    loansProduced: "0.81",
    membersServed: "51",
    interestEarned: "0.07",
    name: "CORRECTIONAL WORKERS",
    website: "http://www.cw-fcu.com",
  },
  "1115": {
    loansProduced: "17.46",
    membersServed: "1,638",
    interestEarned: "1.48",
    name: "COASTLINE",
    website: "coastlinefcu.org",
  },
  "1117": {
    loansProduced: "42.43",
    membersServed: "4,879",
    interestEarned: "3.61",
    name: "WIDGET",
    website: "https://www.widgetfinancial.com",
  },
  "1125": {
    loansProduced: "0.41",
    membersServed: "102",
    interestEarned: "0.03",
    name: "VAN CORTLANDT COOPERATIVE",
    website: "http://www.vccfcu.com",
  },
  "1127": {
    loansProduced: "16.49",
    membersServed: "1,502",
    interestEarned: "1.40",
    name: "SALEM VA MEDICAL CENTER",
    website: "www.salemvafcu.org",
  },
  "1129": {
    loansProduced: "4.03",
    membersServed: "275",
    interestEarned: "0.34",
    name: "MOPAC EMPLOYEES",
    website: "http://www.mopaccu.com",
  },
  "1130": {
    loansProduced: "0.69",
    membersServed: "50",
    interestEarned: "0.06",
    name: "LONGVIEW",
    website: null,
  },
  "1131": {
    loansProduced: "2.52",
    membersServed: "286",
    interestEarned: "0.21",
    name: "ESSEX COUNTY TEACHERS",
    website: "http://www.ectcu.org",
  },
  "1133": {
    loansProduced: "11.90",
    membersServed: "1,081",
    interestEarned: "1.01",
    name: "TRADEMARK",
    website: "http://www.trademarkfcu.org",
  },
  "1134": {
    loansProduced: "0.23",
    membersServed: "23",
    interestEarned: "0.02",
    name: "CO-OPERATIVE",
    website: "http://WWW.COOPFEDERAL.COM",
  },
  "1148": {
    loansProduced: "46.04",
    membersServed: "3,733",
    interestEarned: "3.91",
    name: "COREPLUS",
    website: "http://www.coreplus.org",
  },
  "1157": {
    loansProduced: "2.75",
    membersServed: "205",
    interestEarned: "0.23",
    name: "BUFFALO POLICE",
    website: "http://www.buffalopolicefcu.org",
  },
  "1166": {
    loansProduced: "0.94",
    membersServed: "1,115",
    interestEarned: "0.08",
    name: "RESEARCH 1166",
    website: "http://www.1166fcu.org",
  },
  "1167": {
    loansProduced: "2.56",
    membersServed: "317",
    interestEarned: "0.22",
    name: "FORT BILLINGS",
    website: "http://www.fortbillings.org",
  },
  "1172": {
    loansProduced: "4.38",
    membersServed: "797",
    interestEarned: "0.37",
    name: "CORNER POST",
    website: "http://www.cornerpostfcu.org",
  },
  "1199": {
    loansProduced: "65.80",
    membersServed: "5,622",
    interestEarned: "5.59",
    name: "BMI",
    website: "http://www.bmifcu.org",
  },
  "1204": {
    loansProduced: "7.54",
    membersServed: "1,207",
    interestEarned: "0.64",
    name: "RANCHO",
    website: "http://www.ranchofcu.org",
  },
  "1207": {
    loansProduced: "78.53",
    membersServed: "12,461",
    interestEarned: "6.68",
    name: "LOS ANGELES",
    website: "http://www.lafcu.org",
  },
  "1236": {
    loansProduced: "1.20",
    membersServed: "140",
    interestEarned: "0.10",
    name: "CAMBRIDGE FIREFIGHTERS",
    website: "http://www.cambridgefirefcu.org",
  },
  "1239": {
    loansProduced: "2.90",
    membersServed: "335",
    interestEarned: "0.25",
    name: "PITTSBURGH CITY HALL EMPLOYEES",
    website: "pche-fcu.org",
  },
  "1245": {
    loansProduced: "5.65",
    membersServed: "734",
    interestEarned: "0.48",
    name: "PINE BLUFF COTTON BELT",
    website: "https://www.pbcbcu.org",
  },
  "1248": {
    loansProduced: "1.39",
    membersServed: "114",
    interestEarned: "0.12",
    name: "FORT SMITH TEACHERS",
    website: null,
  },
  "1250": {
    loansProduced: "0.28",
    membersServed: "101",
    interestEarned: "0.02",
    name: "HAMPTON VA",
    website: "http://www.hvafcu.org",
  },
  "1251": {
    loansProduced: "3.52",
    membersServed: "452",
    interestEarned: "0.30",
    name: "NEW CENTURY",
    website: "http://newcenturyfcu.org",
  },
  "1257": {
    loansProduced: "10.67",
    membersServed: "686",
    interestEarned: "0.91",
    name: "BATON ROUGE FIRE DEPARTMENT",
    website: "http://brfdfcu.com",
  },
  "1261": {
    loansProduced: "780.72",
    membersServed: "60,563",
    interestEarned: "66.36",
    name: "LANGLEY",
    website: "https://www.langleyfcu.org",
  },
  "1263": {
    loansProduced: "2.41",
    membersServed: "277",
    interestEarned: "0.20",
    name: "EVANSVILLE FIREFIGHTERS",
    website: "http://www.evffcu.info",
  },
  "1273": {
    loansProduced: "280.71",
    membersServed: "13,185",
    interestEarned: "23.86",
    name: "STATE FARM",
    website: "http://www.statefarmfcu.com",
  },
  "1282": {
    loansProduced: "1.14",
    membersServed: "148",
    interestEarned: "0.10",
    name: "RICHMOND HERITAGE",
    website: "http://www.richheritage.org",
  },
  "1309": {
    loansProduced: "5.23",
    membersServed: "1,283",
    interestEarned: "0.44",
    name: "CROSSPOINT",
    website: "www.crosspointfcu.org",
  },
  "1317": {
    loansProduced: "1.53",
    membersServed: "253",
    interestEarned: "0.13",
    name: "REDIFORM NIAGARA FALLS NY",
    website: "www.rediformfcu.com",
  },
  "1339": {
    loansProduced: "2.94",
    membersServed: "347",
    interestEarned: "0.25",
    name: "BAKERSFIELD CITY EMPLOYEES",
    website: "http://www.bakcityefcu.org",
  },
  "1343": {
    loansProduced: "0.51",
    membersServed: "49",
    interestEarned: "0.04",
    name: "EMPIRT 207",
    website: null,
  },
  "1344": {
    loansProduced: "2.32",
    membersServed: "191",
    interestEarned: "0.20",
    name: "NORTHERN VALLEY",
    website: "http://northernvalleyfcu.com",
  },
  "1351": {
    loansProduced: "2.22",
    membersServed: "293",
    interestEarned: "0.19",
    name: "MERCER COUNTY NJ TEACHERS",
    website: "http://www.mcnjtfcu.org",
  },
  "1352": {
    loansProduced: "26.50",
    membersServed: "1,955",
    interestEarned: "2.25",
    name: "CENTURY HERITAGE",
    website: "chfcu.org",
  },
  "1364": {
    loansProduced: "12.61",
    membersServed: "955",
    interestEarned: "1.07",
    name: "U S #1364",
    website: "http://WWW.USFEDERALCU.ORG",
  },
  "1367": {
    loansProduced: "485.74",
    membersServed: "46,879",
    interestEarned: "41.29",
    name: "UNIVERSITY",
    website: "http://www.ufcu.org",
  },
  "1368": {
    loansProduced: "7.88",
    membersServed: "892",
    interestEarned: "0.67",
    name: "PASADENA SERVICE",
    website: "http://www.mypsfcu.org",
  },
  "1397": {
    loansProduced: "1.60",
    membersServed: "482",
    interestEarned: "0.14",
    name: "EDISTO",
    website: "www.edistofederalcu.org",
  },
  "1399": {
    loansProduced: "3.06",
    membersServed: "387",
    interestEarned: "0.26",
    name: "PITNEY BOWES EMPLOYEES",
    website: "http://www.pbefcu.com",
  },
  "1401": {
    loansProduced: "13.88",
    membersServed: "1,277",
    interestEarned: "1.18",
    name: "HEREFORD TEXAS",
    website: "http://www.htfcu.com",
  },
  "1404": {
    loansProduced: "12.20",
    membersServed: "1,847",
    interestEarned: "1.04",
    name: "NASSAU FINANCIAL",
    website: "http://www.nassaufinancial.org",
  },
  "1407": {
    loansProduced: "15.97",
    membersServed: "4,920",
    interestEarned: "1.36",
    name: "DEMOCRACY",
    website: "http://www.democracyfcu.org",
  },
  "1409": {
    loansProduced: "196.45",
    membersServed: "11,101",
    interestEarned: "16.70",
    name: "RAIZ",
    website: "http://www.raiz.us",
  },
  "1410": {
    loansProduced: "71.04",
    membersServed: "4,714",
    interestEarned: "6.04",
    name: "MIDSOUTH COMMUNITY",
    website: "http://www.midsouthfcu.org",
  },
  "1427": {
    loansProduced: "88.97",
    membersServed: "7,152",
    interestEarned: "7.56",
    name: "MIDWEST AMERICA",
    website: "https://www.mwafcu.org",
  },
  "1438": {
    loansProduced: "7.92",
    membersServed: "605",
    interestEarned: "0.67",
    name: "LONG BEACH CITY EMPLOYEES",
    website: "https://www.lbcefcu.org",
  },
  "1454": {
    loansProduced: "7.29",
    membersServed: "1,316",
    interestEarned: "0.62",
    name: "MERCK EMPLOYEES",
    website: "http://WWW.MERCKCU.COM",
  },
  "1457": {
    loansProduced: "0.62",
    membersServed: "126",
    interestEarned: "0.05",
    name: "FINANS",
    website: "http://www.finansfcu.org",
  },
  "1461": {
    loansProduced: "1.83",
    membersServed: "155",
    interestEarned: "0.16",
    name: "LEOMINSTER EMPLOYEES",
    website: "http://www.leomefcu.org",
  },
  "1463": {
    loansProduced: "1.25",
    membersServed: "83",
    interestEarned: "0.11",
    name: "APPALACHIAN POWER EMPLOYEES",
    website: "http://www.appalachianpowerefcu.vir",
  },
  "1472": {
    loansProduced: "112.81",
    membersServed: "14,411",
    interestEarned: "9.59",
    name: "PALMETTO CITIZENS",
    website: "http://www.palmettocitizens.org",
  },
  "1476": {
    loansProduced: "77.37",
    membersServed: "8,141",
    interestEarned: "6.58",
    name: "ERIE",
    website: "http://www.eriefcu.org",
  },
  "1493": {
    loansProduced: "41.84",
    membersServed: "5,957",
    interestEarned: "3.56",
    name: "F & A",
    website: "http://WWW.FAFCU.ORG",
  },
  "1499": {
    loansProduced: "2.76",
    membersServed: "338",
    interestEarned: "0.23",
    name: "PUEBLO GOVERNMENT AGENCIES",
    website: "http://www.pgafcu.org",
  },
  "1509": {
    loansProduced: "22.19",
    membersServed: "2,101",
    interestEarned: "1.89",
    name: "ALCOA TENN",
    website: "https://www.atfcu.com",
  },
  "1520": {
    loansProduced: "250.44",
    membersServed: "14,914",
    interestEarned: "21.29",
    name: "TENNESSEE VALLEY",
    website: "http://tvfcu.com",
  },
  "1542": {
    loansProduced: "11.03",
    membersServed: "1,504",
    interestEarned: "0.94",
    name: "GNC COMMUNITY",
    website: "www.gncfcu.com",
  },
  "1546": {
    loansProduced: "0.06",
    membersServed: "47",
    interestEarned: "0.01",
    name: "MERCER COUNTY IMPROVEMENT AUTHORITY",
    website: null,
  },
  "1558": {
    loansProduced: "31.36",
    membersServed: "2,207",
    interestEarned: "2.67",
    name: "KERR COUNTY",
    website: "http://kerrcountyfcu.com",
  },
  "1584": {
    loansProduced: "11.57",
    membersServed: "1,332",
    interestEarned: "0.98",
    name: "MEMBERS TRUST OF THE SOUTHWEST",
    website: "www.mtfcu.coop",
  },
  "1599": {
    loansProduced: "0.27",
    membersServed: "26",
    interestEarned: "0.02",
    name: "MCDONALD COMMUNITY",
    website: null,
  },
  "1601": {
    loansProduced: "0.28",
    membersServed: "55",
    interestEarned: "0.02",
    name: "U S PIPE BESSEMER EMPLOYEES",
    website: null,
  },
  "1607": {
    loansProduced: "8.48",
    membersServed: "968",
    interestEarned: "0.72",
    name: "BIG ISLAND",
    website: "https://www.bigislandfcu.com",
  },
  "1621": {
    loansProduced: "4.64",
    membersServed: "588",
    interestEarned: "0.39",
    name: "HOMETOWN",
    website: "http://www.hometownfcu.org",
  },
  "1644": {
    loansProduced: "170.83",
    membersServed: "19,095",
    interestEarned: "14.52",
    name: "CORNING",
    website: "http://www.corningcu.org",
  },
  "1646": {
    loansProduced: "1.18",
    membersServed: "177",
    interestEarned: "0.10",
    name: "CLAIRTON WORKS",
    website: "www.clairtonworksfcu.com",
  },
  "1658": {
    loansProduced: "11.94",
    membersServed: "3,272",
    interestEarned: "1.01",
    name: "WHEATLAND",
    website: "http://www.wheatlandcu.com",
  },
  "1660": {
    loansProduced: "0.02",
    membersServed: "3",
    interestEarned: "0.00",
    name: "EATON EMPLOYEES",
    website: null,
  },
  "1665": {
    loansProduced: "0.10",
    membersServed: "16",
    interestEarned: "0.01",
    name: "NEW ORLEANS PUBLIC BELT RAILROAD",
    website: null,
  },
  "1669": {
    loansProduced: "0.83",
    membersServed: "69",
    interestEarned: "0.07",
    name: "SARCO",
    website: "http://sarcofcu.org",
  },
  "1682": {
    loansProduced: "87.00",
    membersServed: "30,642",
    interestEarned: "7.39",
    name: "LIBERTY",
    website: "https://Libertyfcu.org",
  },
  "1717": {
    loansProduced: "13.89",
    membersServed: "2,384",
    interestEarned: "1.18",
    name: "HAWAIIAN FINANCIAL",
    website: "www.hificu.com",
  },
  "1718": {
    loansProduced: "80.21",
    membersServed: "9,255",
    interestEarned: "6.82",
    name: "HAWAII STATE",
    website: "https://www.hawaiistatefcu.com",
  },
  "1719": {
    loansProduced: "29.16",
    membersServed: "4,865",
    interestEarned: "2.48",
    name: "HAWAII COMMUNITY",
    website: "http://www.hicommfcu.com",
  },
  "1725": {
    loansProduced: "4.98",
    membersServed: "611",
    interestEarned: "0.42",
    name: "MONROE TELCO",
    website: "http://www.monroetelcofcu.org",
  },
  "1726": {
    loansProduced: "92.32",
    membersServed: "5,621",
    interestEarned: "7.85",
    name: "BATON ROUGE TELCO",
    website: "http://www.brtelco.org",
  },
  "1729": {
    loansProduced: "2.74",
    membersServed: "417",
    interestEarned: "0.23",
    name: "ENFIELD COMMUNITY",
    website: "http://www.enfieldcommunityfcu.org",
  },
  "1733": {
    loansProduced: "29.84",
    membersServed: "3,446",
    interestEarned: "2.54",
    name: "HICKAM",
    website: "https://www.hickamfcu.org",
  },
  "1739": {
    loansProduced: "2.06",
    membersServed: "498",
    interestEarned: "0.18",
    name: "MILE HIGH",
    website: "http://www.milehighfcu.org",
  },
  "1750": {
    loansProduced: "6.12",
    membersServed: "759",
    interestEarned: "0.52",
    name: "COSDEN",
    website: "http://www.cosden.org",
  },
  "1759": {
    loansProduced: "30.64",
    membersServed: "4,551",
    interestEarned: "2.60",
    name: "INSPIRE",
    website: "https://www.inspirefcu.org",
  },
  "1784": {
    loansProduced: "6.83",
    membersServed: "672",
    interestEarned: "0.58",
    name: "HAWAII COUNTY EMPLOYEES",
    website: "http://www.hawaiicountyfcu.com",
  },
  "1785": {
    loansProduced: "1.21",
    membersServed: "157",
    interestEarned: "0.10",
    name: "HAWAII SCHOOLS",
    website: "http://www.hawaiischoolsfcu.org",
  },
  "1788": {
    loansProduced: "9.31",
    membersServed: "606",
    interestEarned: "0.79",
    name: "NORDSTROM",
    website: "http://www.nordcu.org",
  },
  "1792": {
    loansProduced: "30.90",
    membersServed: "2,036",
    interestEarned: "2.63",
    name: "EVOLVE",
    website: "http://WWW.evolveFCU.ORG",
  },
  "1810": {
    loansProduced: "3.07",
    membersServed: "276",
    interestEarned: "0.26",
    name: "ROME",
    website: "http://romefcu.com",
  },
  "1815": {
    loansProduced: "101.92",
    membersServed: "11,390",
    interestEarned: "8.66",
    name: "HAWAIIUSA",
    website: "www.hawaiiusafcu.com",
  },
  "1817": {
    loansProduced: "0.31",
    membersServed: "30",
    interestEarned: "0.03",
    name: "MAUI TEACHERS",
    website: "http://www.mauiteachersfcu.com",
  },
  "1821": {
    loansProduced: "1.80",
    membersServed: "293",
    interestEarned: "0.15",
    name: "DISTRICT OF COLUMBIA TEACHERS",
    website: "http://www.dctfcu.org",
  },
  "1827": {
    loansProduced: "3.81",
    membersServed: "291",
    interestEarned: "0.32",
    name: "MINT VALLEY",
    website: "http://www.mintvalleyfcu.org",
  },
  "1829": {
    loansProduced: "45.36",
    membersServed: "6,762",
    interestEarned: "3.86",
    name: "HFS",
    website: "https://SECURE.HFSFCU.ORG",
  },
  "1830": {
    loansProduced: "33.32",
    membersServed: "2,319",
    interestEarned: "2.83",
    name: "HONOLULU",
    website: "http://www.myhocu.com",
  },
  "1838": {
    loansProduced: "0.67",
    membersServed: "56",
    interestEarned: "0.06",
    name: "FORT BAYARD",
    website: "http://fortbayardfcu.org",
  },
  "1845": {
    loansProduced: "57.71",
    membersServed: "4,802",
    interestEarned: "4.91",
    name: "ALOHA PACIFIC",
    website: "https://alohapacific.com",
  },
  "1851": {
    loansProduced: "0.63",
    membersServed: "47",
    interestEarned: "0.05",
    name: "UTILITY EMPLOYEES",
    website: "uefcuonline.com",
  },
  "1866": {
    loansProduced: "16.01",
    membersServed: "1,487",
    interestEarned: "1.36",
    name: "MAUI COUNTY",
    website: "http://www.mauicountyfcu.org",
  },
  "1869": {
    loansProduced: "1.85",
    membersServed: "236",
    interestEarned: "0.16",
    name: "HAWAIIAN ELECTRIC EMPLOYEES",
    website: "http://www.hecoefcu.org",
  },
  "1870": {
    loansProduced: "8.61",
    membersServed: "938",
    interestEarned: "0.73",
    name: "HAWAII LAW ENFORCEMENT",
    website: "http://www.hlefcu.com",
  },
  "1879": {
    loansProduced: "4.85",
    membersServed: "393",
    interestEarned: "0.41",
    name: "TEXAS COMMUNITY",
    website: "https://www.texasfcu.org",
  },
  "1880": {
    loansProduced: "12.12",
    membersServed: "1,042",
    interestEarned: "1.03",
    name: "HONOLULU FIRE DEPARTMENT",
    website: "http://www.hfdfcu.org",
  },
  "1882": {
    loansProduced: "7.17",
    membersServed: "732",
    interestEarned: "0.61",
    name: "TITAN",
    website: "www.titanfcu.com",
  },
  "1888": {
    loansProduced: "7.21",
    membersServed: "910",
    interestEarned: "0.61",
    name: "ASPIRE COMMUNITY",
    website: "https://aspire.creditunion",
  },
  "1904": {
    loansProduced: "4.54",
    membersServed: "777",
    interestEarned: "0.39",
    name: "UNIVERSITY",
    website: "http://www.universityfederalcu.org",
  },
  "1909": {
    loansProduced: "2.91",
    membersServed: "442",
    interestEarned: "0.25",
    name: "SANTA BARBARA TEACHERS",
    website: "http://www.sbtfcu.org",
  },
  "1911": {
    loansProduced: "2.85",
    membersServed: "327",
    interestEarned: "0.24",
    name: "MON VALLEY COMMUNITY",
    website: "http://www.mvcfcu.com",
  },
  "1941": {
    loansProduced: "299.21",
    membersServed: "28,785",
    interestEarned: "25.43",
    name: "SHELL",
    website: "http://www.shellfcu.org",
  },
  "1956": {
    loansProduced: "0.26",
    membersServed: "46",
    interestEarned: "0.02",
    name: "OTERO COUNTY TEACHERS",
    website: "http://oterocountyteachersfcu.com",
  },
  "1961": {
    loansProduced: "22.69",
    membersServed: "2,888",
    interestEarned: "1.93",
    name: "PEARL HAWAII",
    website: "http://www.pearlhawaii.com",
  },
  "1969": {
    loansProduced: "6.45",
    membersServed: "1,052",
    interestEarned: "0.55",
    name: "EDUCATION PERSONNEL",
    website: "http://www.educationpersonnelfcu.co",
  },
  "1982": {
    loansProduced: "46.52",
    membersServed: "4,391",
    interestEarned: "3.95",
    name: "CENTRIC",
    website: "http://www.mycentric.org",
  },
  "1987": {
    loansProduced: "1.02",
    membersServed: "182",
    interestEarned: "0.09",
    name: "EWA",
    website: "http://www.ewafcu.com",
  },
  "1992": {
    loansProduced: "0.04",
    membersServed: "4",
    interestEarned: "0.00",
    name: "MCKEESPORT CONGREGATIONAL",
    website: null,
  },
  "1999": {
    loansProduced: "589.90",
    membersServed: "57,485",
    interestEarned: "50.14",
    name: "LOGIX",
    website: "http://www.logixbanking.com",
  },
  "2003": {
    loansProduced: "0.94",
    membersServed: "84",
    interestEarned: "0.08",
    name: "MCKEESPORT AREA PUBLIC SCHOOL EMP",
    website: null,
  },
  "2008": {
    loansProduced: "4.56",
    membersServed: "527",
    interestEarned: "0.39",
    name: "REMINGTON",
    website: "remingtonfcu.com",
  },
  "2021": {
    loansProduced: "1.01",
    membersServed: "100",
    interestEarned: "0.09",
    name: "TEANECK",
    website: "http://TEANECKFCU.COM",
  },
  "2024": {
    loansProduced: "26.69",
    membersServed: "2,069",
    interestEarned: "2.27",
    name: "LA FINANCIAL",
    website: "https://www.lafinancial.org",
  },
  "2046": {
    loansProduced: "2.51",
    membersServed: "272",
    interestEarned: "0.21",
    name: "PAMPA TEACHERS",
    website: "http://www.ptfcu-tx.com",
  },
  "2049": {
    loansProduced: "0.95",
    membersServed: "117",
    interestEarned: "0.08",
    name: "WAIALUA",
    website: "http://www.waialuafcu.com",
  },
  "2051": {
    loansProduced: "8.97",
    membersServed: "1,201",
    interestEarned: "0.76",
    name: "LA PORTE COMMUNITY",
    website: "http://www.laportecfcu.com",
  },
  "2056": {
    loansProduced: "1.16",
    membersServed: "154",
    interestEarned: "0.10",
    name: "SEWERAGE & WATER BOARD EMPLOYEES",
    website: "http://www.swbfcu.com",
  },
  "2065": {
    loansProduced: "1.54",
    membersServed: "162",
    interestEarned: "0.13",
    name: "LOWELL MUNICIPAL EMPLOYEES",
    website: "http://lmefcu.net",
  },
  "2068": {
    loansProduced: "3.16",
    membersServed: "503",
    interestEarned: "0.27",
    name: "SOUTHERN TEACHERS & PARENTS",
    website: "http://www.stpfcu.com",
  },
  "2098": {
    loansProduced: "0.01",
    membersServed: "9",
    interestEarned: "0.00",
    name: "ALIQUIPPA TEACHERS",
    website: null,
  },
  "2099": {
    loansProduced: "492.93",
    membersServed: "42,439",
    interestEarned: "41.90",
    name: "CITADEL",
    website: "http://www.citadelbanking.com",
  },
  "2107": {
    loansProduced: "0.06",
    membersServed: "10",
    interestEarned: "0.00",
    name: "RUTHERFORD POSTAL DISTRICT EM",
    website: null,
  },
  "2112": {
    loansProduced: "11.91",
    membersServed: "1,188",
    interestEarned: "1.01",
    name: "WICHITA FALLS TEACHERS",
    website: "http://www.wftfcu.com",
  },
  "2115": {
    loansProduced: "16.85",
    membersServed: "1,142",
    interestEarned: "1.43",
    name: "ONE SOURCE",
    website: "http://www.onesourcefcu.org",
  },
  "2127": {
    loansProduced: "0.28",
    membersServed: "79",
    interestEarned: "0.02",
    name: "WEST HUDSON TEACHERS",
    website: null,
  },
  "2131": {
    loansProduced: "63.54",
    membersServed: "4,698",
    interestEarned: "5.40",
    name: "PRIMEWAY",
    website: "https://www.primewayfcu.com",
  },
  "2141": {
    loansProduced: "9.78",
    membersServed: "2,106",
    interestEarned: "0.83",
    name: "CAMPBELL",
    website: "http://www.campbellcu.org",
  },
  "2155": {
    loansProduced: "1.18",
    membersServed: "201",
    interestEarned: "0.10",
    name: "HOBOKEN SCHOOL EMPLOYEES",
    website: "http://WWW.HSEFCU.COOP",
  },
  "2157": {
    loansProduced: "68.28",
    membersServed: "8,191",
    interestEarned: "5.80",
    name: "TUCSON",
    website: "http://www.tucsonfcu.com",
  },
  "2158": {
    loansProduced: "0.84",
    membersServed: "134",
    interestEarned: "0.07",
    name: "NATURAL RESOURCES CONSERVATION SERV",
    website: "www.nrcscreditunion.org",
  },
  "2168": {
    loansProduced: "0.80",
    membersServed: "92",
    interestEarned: "0.07",
    name: "MANVILLE AREA",
    website: "http://www.manville.org",
  },
  "2182": {
    loansProduced: "14.50",
    membersServed: "2,294",
    interestEarned: "1.23",
    name: "GREATER ALLIANCE",
    website: "http://www.greateralliance.org",
  },
  "2187": {
    loansProduced: "70.37",
    membersServed: "6,230",
    interestEarned: "5.98",
    name: "DEXSTA",
    website: "http://www.dexsta.com",
  },
  "2191": {
    loansProduced: "150.18",
    membersServed: "17,100",
    interestEarned: "12.77",
    name: "FIBRE",
    website: "http://www.fibrecu.com",
  },
  "2208": {
    loansProduced: "1.50",
    membersServed: "116",
    interestEarned: "0.13",
    name: "UTICA POLICE DEPARTMENT",
    website: null,
  },
  "2209": {
    loansProduced: "17.31",
    membersServed: "2,204",
    interestEarned: "1.47",
    name: "BUFFALO METROPOLITAN",
    website: "www.bflometrocu.org",
  },
  "2211": {
    loansProduced: "0.20",
    membersServed: "20",
    interestEarned: "0.02",
    name: "M M EMPLOYEES",
    website: null,
  },
  "2226": {
    loansProduced: "72.64",
    membersServed: "6,144",
    interestEarned: "6.17",
    name: "SUNWEST",
    website: "http://www.mysunwest.com",
  },
  "2227": {
    loansProduced: "5.83",
    membersServed: "545",
    interestEarned: "0.50",
    name: "EMPORIA STATE",
    website: "https://www.esfcu.com",
  },
  "2237": {
    loansProduced: "6.98",
    membersServed: "522",
    interestEarned: "0.59",
    name: "CALCOE",
    website: "http://www.calcoefcu.com",
  },
  "2246": {
    loansProduced: "0.72",
    membersServed: "67",
    interestEarned: "0.06",
    name: "PARLIN DUPONT EMPLOYEES",
    website: "http://WWW.PARLINDUPONTEFCU.COM",
  },
  "2252": {
    loansProduced: "5.76",
    membersServed: "679",
    interestEarned: "0.49",
    name: "UTICA GAS & ELECTRIC EMP",
    website: "http://ugefcu.com",
  },
  "2260": {
    loansProduced: "2.38",
    membersServed: "334",
    interestEarned: "0.20",
    name: "UNION PACIFIC STREAMLINER",
    website: "https://www.upcu.org",
  },
  "2263": {
    loansProduced: "2.67",
    membersServed: "365",
    interestEarned: "0.23",
    name: "FIRST NRV",
    website: "http://www.firstnrvcreditunion.com",
  },
  "2275": {
    loansProduced: "1.57",
    membersServed: "140",
    interestEarned: "0.13",
    name: "KAHUKU",
    website: "http://WWW.KAHUKUFCU.COM",
  },
  "2280": {
    loansProduced: "2.08",
    membersServed: "206",
    interestEarned: "0.18",
    name: "HAMAKUA",
    website: "https://www.hamakuafcu.org",
  },
  "2285": {
    loansProduced: "295.70",
    membersServed: "19,048",
    interestEarned: "25.13",
    name: "ADVANCIAL",
    website: "http://www.Advancial.org",
  },
  "2299": {
    loansProduced: "4.26",
    membersServed: "587",
    interestEarned: "0.36",
    name: "GSA",
    website: "http://www.gsafcu.gsa.gov",
  },
  "2301": {
    loansProduced: "9.50",
    membersServed: "680",
    interestEarned: "0.81",
    name: "WINTHROP AREA",
    website: "http://WWW.WINTHROPCREDITUNION.ORG",
  },
  "2322": {
    loansProduced: "19.18",
    membersServed: "1,803",
    interestEarned: "1.63",
    name: "TEXASGULF",
    website: "http://www.texasgulffcu.org",
  },
  "2346": {
    loansProduced: "2.89",
    membersServed: "392",
    interestEarned: "0.25",
    name: "EDUCATIONAL AND GOVERNMENTAL",
    website: "http://www.egefcu.com",
  },
  "2348": {
    loansProduced: "0.37",
    membersServed: "39",
    interestEarned: "0.03",
    name: "GAF LINDEN EMPLOYEES",
    website: "http://www.gafcreditunion.org",
  },
  "2357": {
    loansProduced: "107.87",
    membersServed: "9,602",
    interestEarned: "9.17",
    name: "HOUSTON POLICE",
    website: "http://www.hpcu.coop",
  },
  "2370": {
    loansProduced: "0.60",
    membersServed: "147",
    interestEarned: "0.05",
    name: "METROPOLITAN ''L''",
    website: "http://www.metlfcu.com",
  },
  "2374": {
    loansProduced: "0.45",
    membersServed: "129",
    interestEarned: "0.04",
    name: "HONEYWELL PHILADELPHIA DIV",
    website: "http://www.honeywellfcu.com",
  },
  "2384": {
    loansProduced: "9.20",
    membersServed: "1,382",
    interestEarned: "0.78",
    name: "UNIVERSITY OF NEBRASKA",
    website: "http://www.nufcu.org",
  },
  "2394": {
    loansProduced: "1.15",
    membersServed: "177",
    interestEarned: "0.10",
    name: "NEW BRUNSWICK POSTAL",
    website: "http://nbpostalfcu.org",
  },
  "2403": {
    loansProduced: "14.97",
    membersServed: "2,001",
    interestEarned: "1.27",
    name: "HAWAII",
    website: "http://www.hawaiifcu.org",
  },
  "2405": {
    loansProduced: "6.46",
    membersServed: "777",
    interestEarned: "0.55",
    name: "G.A.P.",
    website: "http://www.gapfcu.org",
  },
  "2414": {
    loansProduced: "0.81",
    membersServed: "115",
    interestEarned: "0.07",
    name: "ROBERTS DAIRY EMPLOYEES",
    website: "http://www.robertsdairyefcu.org",
  },
  "2440": {
    loansProduced: "31.92",
    membersServed: "3,945",
    interestEarned: "2.71",
    name: "FIRST NEW YORK",
    website: "http://www.firstnewyork.org",
  },
  "2441": {
    loansProduced: "217.98",
    membersServed: "18,750",
    interestEarned: "18.53",
    name: "AMOCO",
    website: "https://www.amocofcu.org",
  },
  "2442": {
    loansProduced: "3.94",
    membersServed: "260",
    interestEarned: "0.33",
    name: "GULF SHORE",
    website: "http://www.gulfshorefcu.org",
  },
  "2446": {
    loansProduced: "2.65",
    membersServed: "211",
    interestEarned: "0.23",
    name: "INTERNAL REVENUE",
    website: "http://www.irfcuno.com",
  },
  "2454": {
    loansProduced: "6.04",
    membersServed: "574",
    interestEarned: "0.51",
    name: "FIRST OHIO COMMUNITY",
    website: "http://www.firstohiocfcu.org",
  },
  "2460": {
    loansProduced: "0.97",
    membersServed: "127",
    interestEarned: "0.08",
    name: "PRESCOTT",
    website: "http://www.prescottfcu.org",
  },
  "2467": {
    loansProduced: "0.63",
    membersServed: "101",
    interestEarned: "0.05",
    name: "NORTHSIDE L",
    website: "http://www.nslfcu.com",
  },
  "2490": {
    loansProduced: "1.27",
    membersServed: "195",
    interestEarned: "0.11",
    name: "GREENBELT",
    website: "http://greenbeltfcu.com",
  },
  "2493": {
    loansProduced: "3.00",
    membersServed: "394",
    interestEarned: "0.26",
    name: "JERSEY CENTRAL",
    website: "http://www.jerseycentralfcu.com",
  },
  "2495": {
    loansProduced: "0.20",
    membersServed: "57",
    interestEarned: "0.02",
    name: "CHICAGO AVENUE GARAGE",
    website: null,
  },
  "2498": {
    loansProduced: "0.93",
    membersServed: "85",
    interestEarned: "0.08",
    name: "CTA-74TH STREET DEPOT",
    website: "http://74thstdfcu.com",
  },
  "2505": {
    loansProduced: "1.38",
    membersServed: "421",
    interestEarned: "0.12",
    name: "77TH STREET DEPOT",
    website: "http://www.77thstreetdepotfcu.org",
  },
  "2507": {
    loansProduced: "3.01",
    membersServed: "253",
    interestEarned: "0.26",
    name: "OMAHA PUBLIC POWER DIST EMP",
    website: "http://www.oppd-efcu.org",
  },
  "2508": {
    loansProduced: "3.47",
    membersServed: "512",
    interestEarned: "0.29",
    name: "CROSSROADS COMMUNITY",
    website: "http://www.crcfcu.com",
  },
  "2531": {
    loansProduced: "13.54",
    membersServed: "1,325",
    interestEarned: "1.15",
    name: "BELLCO",
    website: "http://www.bellcocu.org",
  },
  "2535": {
    loansProduced: "51.72",
    membersServed: "9,563",
    interestEarned: "4.40",
    name: "CANTON SCHOOL EMPLOYEES",
    website: "http://www.csefcu.com",
  },
  "2540": {
    loansProduced: "344.58",
    membersServed: "23,187",
    interestEarned: "29.29",
    name: "ABERDEEN PROVING GROUND",
    website: "https://www.apgfcu.com",
  },
  "2551": {
    loansProduced: "708.48",
    membersServed: "115,981",
    interestEarned: "60.22",
    name: "POLICE & FIRE",
    website: "http://www.pffcu.org",
  },
  "2554": {
    loansProduced: "1.81",
    membersServed: "295",
    interestEarned: "0.15",
    name: "ALCOA PITTSBURGH",
    website: "http://www.alcoapittfcu.org",
  },
  "2562": {
    loansProduced: "6.62",
    membersServed: "1,116",
    interestEarned: "0.56",
    name: "MAUI",
    website: "https://www.mauifcu.com",
  },
  "2574": {
    loansProduced: "8.56",
    membersServed: "985",
    interestEarned: "0.73",
    name: "MEMBER'S 1ST OF NJ",
    website: "http://www.membersonenj.org",
  },
  "2577": {
    loansProduced: "4.84",
    membersServed: "406",
    interestEarned: "0.41",
    name: "KAUAI TEACHERS",
    website: "http://www.kauaiteachersfcu.org",
  },
  "2583": {
    loansProduced: "0.71",
    membersServed: "76",
    interestEarned: "0.06",
    name: "SAINT NICHOLAS",
    website: "http://www.snfcu.org",
  },
  "2585": {
    loansProduced: "27.37",
    membersServed: "2,443",
    interestEarned: "2.33",
    name: "RED CROWN",
    website: "http://www.redcrown.org",
  },
  "2612": {
    loansProduced: "0.86",
    membersServed: "74",
    interestEarned: "0.07",
    name: "MULTI-SCHOOLS",
    website: "https://multischoolsfcu.org",
  },
  "2617": {
    loansProduced: "0.25",
    membersServed: "57",
    interestEarned: "0.02",
    name: "LINDEN NJ POLICE & FIREMEN",
    website: null,
  },
  "2625": {
    loansProduced: "16.50",
    membersServed: "1,125",
    interestEarned: "1.40",
    name: "PENNSYLVANIA CENTRAL",
    website: "https://www.pacentralfcu.com",
  },
  "2641": {
    loansProduced: "0.13",
    membersServed: "40",
    interestEarned: "0.01",
    name: "BAYONNE SCHOOL EMPLOYEES",
    website: null,
  },
  "2643": {
    loansProduced: "3.95",
    membersServed: "439",
    interestEarned: "0.34",
    name: "LINCOLN PUBLIC SCHOOL EMPLOYEES",
    website: "http://www.lpscu.com",
  },
  "2644": {
    loansProduced: "13.72",
    membersServed: "2,019",
    interestEarned: "1.17",
    name: "MAINE FAMILY",
    website: "http://www.mainefamilyfcu.com",
  },
  "2645": {
    loansProduced: "25.90",
    membersServed: "4,238",
    interestEarned: "2.20",
    name: "OXFORD",
    website: "www.ofcu.org",
  },
  "2657": {
    loansProduced: "0.67",
    membersServed: "49",
    interestEarned: "0.06",
    name: "W-BEE",
    website: "http://www.wbeefcu.com",
  },
  "2658": {
    loansProduced: "135.93",
    membersServed: "13,314",
    interestEarned: "11.55",
    name: "FIRST SOURCE",
    website: "http://www.fsource.org",
  },
  "2682": {
    loansProduced: "1.39",
    membersServed: "226",
    interestEarned: "0.12",
    name: "SOUTH SHOP",
    website: "http://www.southshopfcu.org",
  },
  "2705": {
    loansProduced: "3.47",
    membersServed: "197",
    interestEarned: "0.29",
    name: "HELCO",
    website: "http://www.helcofcu.org",
  },
  "2709": {
    loansProduced: "3.81",
    membersServed: "632",
    interestEarned: "0.32",
    name: "NORTHEAST FAMILY",
    website: "http://www.nefamily.coop",
  },
  "2711": {
    loansProduced: "1.08",
    membersServed: "474",
    interestEarned: "0.09",
    name: "PROFINANCE",
    website: "http://www.profinancefcu.com",
  },
  "2713": {
    loansProduced: "1.44",
    membersServed: "147",
    interestEarned: "0.12",
    name: "MCBRYDE",
    website: "http://www.mcbrydefcu.org",
  },
  "2730": {
    loansProduced: "17.11",
    membersServed: "2,415",
    interestEarned: "1.45",
    name: "INROADS",
    website: "http://www.inroadscu.org",
  },
  "2740": {
    loansProduced: "21.57",
    membersServed: "2,759",
    interestEarned: "1.83",
    name: "USX",
    website: "http://www.usxfcu.org",
  },
  "2742": {
    loansProduced: "19.88",
    membersServed: "2,015",
    interestEarned: "1.69",
    name: "PENN EAST",
    website: "http://www.penneastfcu.org",
  },
  "2744": {
    loansProduced: "30.26",
    membersServed: "2,977",
    interestEarned: "2.57",
    name: "U. S. EMPLOYEES O. C.",
    website: "http://WWW.USECREDITUNION.ORG",
  },
  "2760": {
    loansProduced: "252.55",
    membersServed: "28,994",
    interestEarned: "21.47",
    name: "JOVIA FINANCIAL",
    website: "http://www.jovia.org",
  },
  "2769": {
    loansProduced: "40.84",
    membersServed: "4,328",
    interestEarned: "3.47",
    name: "SECURITYPLUS",
    website: "http://www.securityplusfcu.org",
  },
  "2782": {
    loansProduced: "0.54",
    membersServed: "74",
    interestEarned: "0.05",
    name: "LONG ISLAND ALLIANCE",
    website: "http://www.liafcu.org",
  },
  "2791": {
    loansProduced: "0.55",
    membersServed: "84",
    interestEarned: "0.05",
    name: "TUSKEGEE",
    website: "http://www.tuskegeefcu.com",
  },
  "2792": {
    loansProduced: "0.57",
    membersServed: "85",
    interestEarned: "0.05",
    name: "NOTRE DAME COMMUNITY",
    website: "http://ndcfcu.com",
  },
  "2794": {
    loansProduced: "0.77",
    membersServed: "62",
    interestEarned: "0.07",
    name: "HAXTUN COMMUNITY",
    website: "http://haxtuncu.com",
  },
  "2796": {
    loansProduced: "1.18",
    membersServed: "196",
    interestEarned: "0.10",
    name: "COPPER & GLASS",
    website: "www.copperandglassfcu.com/",
  },
  "2800": {
    loansProduced: "0.50",
    membersServed: "46",
    interestEarned: "0.04",
    name: "MAMTA",
    website: "http://www.mamtafcu.org",
  },
  "2806": {
    loansProduced: "0.10",
    membersServed: "12",
    interestEarned: "0.01",
    name: "HARRISON TEACHERS",
    website: "http://Harrisonteachersfcu.com",
  },
  "2815": {
    loansProduced: "11.02",
    membersServed: "1,229",
    interestEarned: "0.94",
    name: "HANCOCK",
    website: "http://www.hancockfcu.com",
  },
  "2818": {
    loansProduced: "1.98",
    membersServed: "179",
    interestEarned: "0.17",
    name: "HOLYOKE COMMUNITY",
    website: "http://hcfcu.org",
  },
  "2819": {
    loansProduced: "0.99",
    membersServed: "171",
    interestEarned: "0.08",
    name: "YONKERS TEACHERS",
    website: null,
  },
  "2834": {
    loansProduced: "2.34",
    membersServed: "155",
    interestEarned: "0.20",
    name: "PENLANCO",
    website: "http://WWW.PENLANCOFCU.COM",
  },
  "2840": {
    loansProduced: "8.37",
    membersServed: "586",
    interestEarned: "0.71",
    name: "STERLING",
    website: "http://www.sterlingcreditunion.org",
  },
  "2843": {
    loansProduced: "1.40",
    membersServed: "142",
    interestEarned: "0.12",
    name: "MATSON EMPLOYEES",
    website: "http://www.matsoncreditunion.com",
  },
  "2847": {
    loansProduced: "17.12",
    membersServed: "4,258",
    interestEarned: "1.46",
    name: "ROCKY MOUNTAIN LAW ENFORCEMENT",
    website: "http://www.rmlefcu.org",
  },
  "2874": {
    loansProduced: "22.29",
    membersServed: "2,562",
    interestEarned: "1.89",
    name: "BLUESTONE",
    website: "http://www.bluestonefcu.org",
  },
  "2876": {
    loansProduced: "79.51",
    membersServed: "7,501",
    interestEarned: "6.76",
    name: "CHICAGO PATROLMEN'S",
    website: "http://www.cpdfcu.com",
  },
  "2881": {
    loansProduced: "6.78",
    membersServed: "931",
    interestEarned: "0.58",
    name: "DENVER FIRE DEPARTMENT",
    website: "http://www.dfdfcu.com",
  },
  "2892": {
    loansProduced: "0.16",
    membersServed: "40",
    interestEarned: "0.01",
    name: "PLAINFIELD POLICE & FIREMEN'S",
    website: null,
  },
  "2918": {
    loansProduced: "7.44",
    membersServed: "1,456",
    interestEarned: "0.63",
    name: "JEEP COUNTRY",
    website: "http://www.jeepcountryfcu.com",
  },
  "2932": {
    loansProduced: "0.09",
    membersServed: "26",
    interestEarned: "0.01",
    name: "TRENTON TEACHERS",
    website: null,
  },
  "2935": {
    loansProduced: "1.61",
    membersServed: "279",
    interestEarned: "0.14",
    name: "PARISH",
    website: "http://www.parishfcu.coop",
  },
  "2953": {
    loansProduced: "0.98",
    membersServed: "80",
    interestEarned: "0.08",
    name: "LANAI",
    website: "https://www.lanaifcu.org",
  },
  "2959": {
    loansProduced: "5.52",
    membersServed: "639",
    interestEarned: "0.47",
    name: "PALCO FCU",
    website: "https://www.palcofcu.org",
  },
  "3006": {
    loansProduced: "0.39",
    membersServed: "38",
    interestEarned: "0.03",
    name: "CONCORA WABASH",
    website: null,
  },
  "3012": {
    loansProduced: "1.89",
    membersServed: "344",
    interestEarned: "0.16",
    name: "CITY OF FIRSTS COMMUNITY",
    website: "http://coffcu.org",
  },
  "3025": {
    loansProduced: "277.27",
    membersServed: "39,148",
    interestEarned: "23.57",
    name: "EMPOWER",
    website: "http://www.empowerfcu.com",
  },
  "3029": {
    loansProduced: "2.29",
    membersServed: "218",
    interestEarned: "0.19",
    name: "VIRGINIA STATE UNIVERSITY",
    website: "http://www.vsufcu.org",
  },
  "3050": {
    loansProduced: "2.59",
    membersServed: "1,268",
    interestEarned: "0.22",
    name: "ONOMEA",
    website: "http://www.onomeafcu.org",
  },
  "3056": {
    loansProduced: "0.44",
    membersServed: "55",
    interestEarned: "0.04",
    name: "CAMBRIDGE MUNICIPAL EMPLOYEES",
    website: "http://www.cambridgefcu.com",
  },
  "3061": {
    loansProduced: "14.63",
    membersServed: "1,338",
    interestEarned: "1.24",
    name: "LOUISIANA USA",
    website: "https://louisianausa.org",
  },
  "3064": {
    loansProduced: "11.60",
    membersServed: "896",
    interestEarned: "0.99",
    name: "SELECT",
    website: "http://WWW.SELECTFCU.ORG",
  },
  "3080": {
    loansProduced: "6.34",
    membersServed: "537",
    interestEarned: "0.54",
    name: "SAN ANGELO",
    website: "http://WWW.SAFCU.COM",
  },
  "3085": {
    loansProduced: "1.80",
    membersServed: "141",
    interestEarned: "0.15",
    name: "BUTLER COUNTY TEACHERS",
    website: "http://www.bctfcu.net",
  },
  "3090": {
    loansProduced: "0.22",
    membersServed: "38",
    interestEarned: "0.02",
    name: "LITTLE ROCK",
    website: "http://www.littlerockfcu.com",
  },
  "3110": {
    loansProduced: "0.03",
    membersServed: "2",
    interestEarned: "0.00",
    name: "WHITE PLAINS P O EMPLOYEES",
    website: null,
  },
  "3115": {
    loansProduced: "1.01",
    membersServed: "270",
    interestEarned: "0.09",
    name: "CAMBRIDGE TEACHERS",
    website: "http://CAMBRIDGETEACHERSFCU.ORG",
  },
  "3120": {
    loansProduced: "0.95",
    membersServed: "80",
    interestEarned: "0.08",
    name: "JOHNSTOWN SCHOOL EMPLOYEES",
    website: "http://jsefcu.com",
  },
  "3126": {
    loansProduced: "1.19",
    membersServed: "90",
    interestEarned: "0.10",
    name: "SOUTHERN MIDDLESEX CO TCHRS",
    website: "http://www.myfavoritecu.com",
  },
  "3130": {
    loansProduced: "177.53",
    membersServed: "11,272",
    interestEarned: "15.09",
    name: "TRUITY",
    website: "www.truitycu.org",
  },
  "3140": {
    loansProduced: "17.24",
    membersServed: "2,371",
    interestEarned: "1.47",
    name: "TRANSPORTATION",
    website: "http://www.transfcu.com",
  },
  "3182": {
    loansProduced: "1.38",
    membersServed: "158",
    interestEarned: "0.12",
    name: "STAR TECH",
    website: "http://www.star-techfcu.com",
  },
  "3202": {
    loansProduced: "5.34",
    membersServed: "325",
    interestEarned: "0.45",
    name: "CENTRAL JERSEY POLICE & FIRE",
    website: "http://cjpolicefirefcu.org",
  },
  "3212": {
    loansProduced: "13.00",
    membersServed: "2,179",
    interestEarned: "1.11",
    name: "ANECA",
    website: "https://aneca.org",
  },
  "3218": {
    loansProduced: "15.16",
    membersServed: "1,472",
    interestEarned: "1.29",
    name: "TULARE COUNTY",
    website: "https://www.tularefcu.org",
  },
  "3226": {
    loansProduced: "0.68",
    membersServed: "172",
    interestEarned: "0.06",
    name: "ACBA FED CREDIT UNION (3226)",
    website: "http://www.acbafcu.com",
  },
  "3237": {
    loansProduced: "1.69",
    membersServed: "198",
    interestEarned: "0.14",
    name: "COLFAX POWER PLANT EMPLOYEES",
    website: "http://www.colfaxpowerplant.com",
  },
  "3251": {
    loansProduced: "0.08",
    membersServed: "13",
    interestEarned: "0.01",
    name: "GARY POLICE DEPARTMENT EMPLOYEES",
    website: null,
  },
  "3256": {
    loansProduced: "0.27",
    membersServed: "33",
    interestEarned: "0.02",
    name: "LONG BEACH TEACHERS",
    website: "http://lbeach.org",
  },
  "3264": {
    loansProduced: "6.16",
    membersServed: "1,133",
    interestEarned: "0.52",
    name: "FIRST CHOICE AMERICA COMMUNITY",
    website: "http://www.firstchoiceamericacu.org",
  },
  "3278": {
    loansProduced: "0.74",
    membersServed: "155",
    interestEarned: "0.06",
    name: "SOUTHWEST COMMUNITIES",
    website: "http://www.swcfcu.org",
  },
  "3285": {
    loansProduced: "2.81",
    membersServed: "364",
    interestEarned: "0.24",
    name: "NIAGARA REGIONAL",
    website: "http://www.niagararegionalfcu.org",
  },
  "3291": {
    loansProduced: "23.13",
    membersServed: "2,478",
    interestEarned: "1.97",
    name: "PICATINNY",
    website: "http://www.picacreditunion.com",
  },
  "3306": {
    loansProduced: "4.99",
    membersServed: "432",
    interestEarned: "0.42",
    name: "SJP",
    website: "http://sjpfcu.org",
  },
  "3313": {
    loansProduced: "1.47",
    membersServed: "147",
    interestEarned: "0.13",
    name: "UMICO",
    website: "http://www.umicofcu.net",
  },
  "3316": {
    loansProduced: "4.86",
    membersServed: "562",
    interestEarned: "0.41",
    name: "ALTONIZED COMMUNITY",
    website: "http://ALTONIZEDFCU.ORG",
  },
  "3323": {
    loansProduced: "11.52",
    membersServed: "1,981",
    interestEarned: "0.98",
    name: "TRUENERGY",
    website: "http://truenergyfcu.org",
  },
  "3337": {
    loansProduced: "0.50",
    membersServed: "55",
    interestEarned: "0.04",
    name: "WATERBURY POSTAL EMPLOYEES",
    website: "www.waterburypostalfcu.org/",
  },
  "3356": {
    loansProduced: "0.29",
    membersServed: "59",
    interestEarned: "0.02",
    name: "ELIZABETH POLICE DEPARTMENT E",
    website: null,
  },
  "3360": {
    loansProduced: "14.16",
    membersServed: "2,335",
    interestEarned: "1.20",
    name: "EMERY",
    website: "http://www.emeryfcu.org",
  },
  "3388": {
    loansProduced: "6.17",
    membersServed: "669",
    interestEarned: "0.52",
    name: "EMERALD COAST",
    website: "https://www.emeraldcoastfcu.com",
  },
  "3391": {
    loansProduced: "2.53",
    membersServed: "183",
    interestEarned: "0.21",
    name: "RICHLAND",
    website: "http://richlandfcu.com",
  },
  "3412": {
    loansProduced: "4.50",
    membersServed: "749",
    interestEarned: "0.38",
    name: "AAA",
    website: "http://www.aaafcu.com",
  },
  "3413": {
    loansProduced: "87.35",
    membersServed: "5,341",
    interestEarned: "7.42",
    name: "CHARTER OAK",
    website: "http://www.charteroak.org",
  },
  "3420": {
    loansProduced: "0.09",
    membersServed: "24",
    interestEarned: "0.01",
    name: "ATLANTIC CITY POLICE",
    website: null,
  },
  "3438": {
    loansProduced: "5.21",
    membersServed: "570",
    interestEarned: "0.44",
    name: "HUNTINGTONIZED",
    website: "http://www.huntingtonized.com",
  },
  "3439": {
    loansProduced: "52.46",
    membersServed: "2,437",
    interestEarned: "4.46",
    name: "FAIRMONT",
    website: "http://www.fairmontfcu.com",
  },
  "3447": {
    loansProduced: "0.94",
    membersServed: "167",
    interestEarned: "0.08",
    name: "ACME",
    website: "http://www.acmefcu.org",
  },
  "3454": {
    loansProduced: "353.93",
    membersServed: "22,370",
    interestEarned: "30.08",
    name: "COMMUNICATION",
    website: "http://www.comfedcu.org",
  },
  "3458": {
    loansProduced: "26.41",
    membersServed: "2,902",
    interestEarned: "2.24",
    name: "VOYAGE",
    website: "http://voyagefcu.org",
  },
  "3462": {
    loansProduced: "1.27",
    membersServed: "352",
    interestEarned: "0.11",
    name: "ST. COLMAN & AFFILIATES",
    website: "http://stcolmanaffiliatesfcu.com",
  },
  "3475": {
    loansProduced: "0.51",
    membersServed: "65",
    interestEarned: "0.04",
    name: "SOMERVILLE SCHOOL EMPLOYEES",
    website: "http://SSEFCU.ORG",
  },
  "3476": {
    loansProduced: "6.52",
    membersServed: "1,208",
    interestEarned: "0.55",
    name: "BRIDGETON ONIZED",
    website: "http://www.bofcu.com",
  },
  "3487": {
    loansProduced: "4.12",
    membersServed: "711",
    interestEarned: "0.35",
    name: "FREEDOM UNITED",
    website: "http://FREEDOMUNITEDFCU.COM",
  },
  "3526": {
    loansProduced: "16.82",
    membersServed: "1,709",
    interestEarned: "1.43",
    name: "SCHOOLS",
    website: "http://www.Schoolsfcu.org",
  },
  "3536": {
    loansProduced: "2.15",
    membersServed: "259",
    interestEarned: "0.18",
    name: "WHITEWATER REGIONAL",
    website: "http://www.wwrfcu.org",
  },
  "3541": {
    loansProduced: "4.97",
    membersServed: "887",
    interestEarned: "0.42",
    name: "BAY ATLANTIC",
    website: "www.bayatlanticfcu.org",
  },
  "3544": {
    loansProduced: "0.90",
    membersServed: "88",
    interestEarned: "0.08",
    name: "CONNECTICUT LABOR DEPT",
    website: "http://ctlaborfcu.com",
  },
  "3551": {
    loansProduced: "9.38",
    membersServed: "637",
    interestEarned: "0.80",
    name: "JAMESTOWN AREA COMMUNITY",
    website: "http://jacfcu.org",
  },
  "3558": {
    loansProduced: "0.98",
    membersServed: "101",
    interestEarned: "0.08",
    name: "SUGAR VALLEY",
    website: "sugarvalleyfcu.org",
  },
  "3571": {
    loansProduced: "14.45",
    membersServed: "1,313",
    interestEarned: "1.23",
    name: "PANTEX",
    website: "http://www.pantexfcu.com",
  },
  "3574": {
    loansProduced: "5.63",
    membersServed: "1,177",
    interestEarned: "0.48",
    name: "VALLEY ISLE COMMUNITY",
    website: "http://www.vicfcu.org",
  },
  "3575": {
    loansProduced: "0.34",
    membersServed: "44",
    interestEarned: "0.03",
    name: "BRIDGEPORT POST OFFICE",
    website: "http://www.bptpofcu.org",
  },
  "3576": {
    loansProduced: "20.43",
    membersServed: "2,719",
    interestEarned: "1.74",
    name: "PIONEER APPALACHIA",
    website: "http://www.pioneerwv.org",
  },
  "3601": {
    loansProduced: "36.11",
    membersServed: "8,013",
    interestEarned: "3.07",
    name: "NATIONAL INSTITUTES OF HEALTH",
    website: "http://www.nihfcu.org",
  },
  "3602": {
    loansProduced: "0.01",
    membersServed: "2",
    interestEarned: "0.00",
    name: "FROID",
    website: null,
  },
  "3631": {
    loansProduced: "7.93",
    membersServed: "1,148",
    interestEarned: "0.67",
    name: "THRIVE",
    website: "http://www.thrivecu.org",
  },
  "3633": {
    loansProduced: "23.95",
    membersServed: "4,365",
    interestEarned: "2.04",
    name: "PEOPLE'S ALLIANCE",
    website: "http://www.pafcu.org",
  },
  "3653": {
    loansProduced: "0.52",
    membersServed: "36",
    interestEarned: "0.04",
    name: "B E T",
    website: "http://betcreditunion.com",
  },
  "3655": {
    loansProduced: "18.07",
    membersServed: "1,448",
    interestEarned: "1.54",
    name: "TOP TIER",
    website: "http://www.toptierfcu.org",
  },
  "3683": {
    loansProduced: "1.11",
    membersServed: "108",
    interestEarned: "0.09",
    name: "CONNECTICUT POSTAL",
    website: null,
  },
  "3685": {
    loansProduced: "0.72",
    membersServed: "89",
    interestEarned: "0.06",
    name: "INTERNAL REVENUE EMPLOYEES",
    website: "http://www.irefcu_ncsc.com",
  },
  "3710": {
    loansProduced: "5.66",
    membersServed: "697",
    interestEarned: "0.48",
    name: "CITY PUBLIC SERVICE/IBEW",
    website: "http://cpsibewfcu.org",
  },
  "3711": {
    loansProduced: "9.27",
    membersServed: "1,452",
    interestEarned: "0.79",
    name: "OMEGA",
    website: "http://www.omegafcu.com",
  },
  "3720": {
    loansProduced: "3.33",
    membersServed: "490",
    interestEarned: "0.28",
    name: "TOLEDO POLICE",
    website: "http://www.tpolcu.com",
  },
  "3736": {
    loansProduced: "1.30",
    membersServed: "176",
    interestEarned: "0.11",
    name: "VICTORIA",
    website: "http://victoriafcu.org",
  },
  "3738": {
    loansProduced: "12.95",
    membersServed: "2,027",
    interestEarned: "1.10",
    name: "BENCHMARK",
    website: "https://www.benchmarkfcu.org",
  },
  "3741": {
    loansProduced: "2.03",
    membersServed: "296",
    interestEarned: "0.17",
    name: "A & S",
    website: "http://www.asfederal.org",
  },
  "3746": {
    loansProduced: "2.00",
    membersServed: "259",
    interestEarned: "0.17",
    name: "NORFOLK FIRE DEPARTMENT",
    website: "http://www.norfolkfirefcu.org",
  },
  "3748": {
    loansProduced: "4.25",
    membersServed: "482",
    interestEarned: "0.36",
    name: "TOLEDO FIRE FIGHTERS",
    website: "http://www.tfffcu.org",
  },
  "3755": {
    loansProduced: "4.35",
    membersServed: "395",
    interestEarned: "0.37",
    name: "ATLANTIC CITY ELECTRIC CO EMP",
    website: "http://www.acecefcu.org",
  },
  "3757": {
    loansProduced: "1.84",
    membersServed: "250",
    interestEarned: "0.16",
    name: "RIVERBANK",
    website: "www.riverbankfcu.com",
  },
  "3759": {
    loansProduced: "7.18",
    membersServed: "893",
    interestEarned: "0.61",
    name: "GREATER CHAUTAUQUA",
    website: "http://www.greatercfcu.com",
  },
  "3764": {
    loansProduced: "3.18",
    membersServed: "1,991",
    interestEarned: "0.27",
    name: "TRANSIT EMPLOYEES",
    website: "http://www.tefcu.org",
  },
  "3775": {
    loansProduced: "8.05",
    membersServed: "805",
    interestEarned: "0.68",
    name: "RIVERSIDE COMMUNITY",
    website: "https://www.riversidecommunityfcu.o",
  },
  "3787": {
    loansProduced: "0.15",
    membersServed: "14",
    interestEarned: "0.01",
    name: "SHAKER HEIGHTS",
    website: null,
  },
  "3790": {
    loansProduced: "0.76",
    membersServed: "66",
    interestEarned: "0.06",
    name: "SCIENCE PARK",
    website: "http://scienceparkfcu.org",
  },
  "3796": {
    loansProduced: "6.80",
    membersServed: "1,088",
    interestEarned: "0.58",
    name: "L G & W",
    website: "https://www.lgwfcu.com",
  },
  "3800": {
    loansProduced: "21.93",
    membersServed: "1,447",
    interestEarned: "1.86",
    name: "GEORGIA HERITAGE",
    website: "https://www.gaheritagefcu.org",
  },
  "3810": {
    loansProduced: "1.49",
    membersServed: "267",
    interestEarned: "0.13",
    name: "PAAC TRANSIT DIVISION",
    website: null,
  },
  "3819": {
    loansProduced: "1.62",
    membersServed: "328",
    interestEarned: "0.14",
    name: "UPPER DARBY BELLTELCO",
    website: "http://www.udbell.org",
  },
  "3828": {
    loansProduced: "9.28",
    membersServed: "932",
    interestEarned: "0.79",
    name: "TEXAS PLAINS",
    website: "http://www.texasplainsfederal.org",
  },
  "3830": {
    loansProduced: "17.62",
    membersServed: "3,372",
    interestEarned: "1.50",
    name: "MASS. INSTITUTE OF TECH.",
    website: "http://www.mitfcu.org",
  },
  "3837": {
    loansProduced: "0.53",
    membersServed: "203",
    interestEarned: "0.04",
    name: "HARMONY",
    website: "http://www.harmonyfcu.org",
  },
  "3841": {
    loansProduced: "28.59",
    membersServed: "3,138",
    interestEarned: "2.43",
    name: "GHS",
    website: "http://www.ghsfcu.com",
  },
  "3850": {
    loansProduced: "11.68",
    membersServed: "2,371",
    interestEarned: "0.99",
    name: "N C P D",
    website: "http://ncpdfcu.org",
  },
  "3853": {
    loansProduced: "10.40",
    membersServed: "1,004",
    interestEarned: "0.88",
    name: "NW PREFERRED",
    website: "http://nwpreferredfcu.com",
  },
  "3868": {
    loansProduced: "0.10",
    membersServed: "15",
    interestEarned: "0.01",
    name: "SUNFLOWER",
    website: null,
  },
  "3869": {
    loansProduced: "0.22",
    membersServed: "34",
    interestEarned: "0.02",
    name: "EAGLE",
    website: null,
  },
  "3878": {
    loansProduced: "0.48",
    membersServed: "33",
    interestEarned: "0.04",
    name: "LUBBOCK TELCO",
    website: null,
  },
  "3907": {
    loansProduced: "32.35",
    membersServed: "3,103",
    interestEarned: "2.75",
    name: "WICHITA",
    website: "http://www.wichitafcu.com",
  },
  "3938": {
    loansProduced: "13.43",
    membersServed: "1,299",
    interestEarned: "1.14",
    name: "TEXAN SKY",
    website: "http://www.texansky.com",
  },
  "3943": {
    loansProduced: "23.78",
    membersServed: "3,891",
    interestEarned: "2.02",
    name: "GAIN",
    website: "http://www.gainfcu.com",
  },
  "3945": {
    loansProduced: "10.83",
    membersServed: "2,158",
    interestEarned: "0.92",
    name: "UME",
    website: "http://www.umecreditunion.com",
  },
  "3946": {
    loansProduced: "4.31",
    membersServed: "338",
    interestEarned: "0.37",
    name: "SACRED HEART PARISH HALLETTSVILLE",
    website: "http://sacredheartcu.com",
  },
  "3947": {
    loansProduced: "7.05",
    membersServed: "739",
    interestEarned: "0.60",
    name: "QSIDE",
    website: "http://WWW.qsidefcu.org",
  },
  "3951": {
    loansProduced: "21.36",
    membersServed: "3,085",
    interestEarned: "1.82",
    name: "FIRST HARVEST",
    website: "http://www.firstharvestcu.com",
  },
  "3963": {
    loansProduced: "3.98",
    membersServed: "431",
    interestEarned: "0.34",
    name: "RIO GRANDE",
    website: "http://www.riograndefcu.org",
  },
  "3972": {
    loansProduced: "37.95",
    membersServed: "2,215",
    interestEarned: "3.23",
    name: "WESTERN VISTA",
    website: "http://www.wvista.com",
  },
  "4015": {
    loansProduced: "70.97",
    membersServed: "8,911",
    interestEarned: "6.03",
    name: "GENERATIONS COMMUNITY",
    website: "http://www.mygenfcu.org",
  },
  "4020": {
    loansProduced: "4.70",
    membersServed: "603",
    interestEarned: "0.40",
    name: "MEMBERS FINANCIAL",
    website: "http://www.membersffcu.org",
  },
  "4022": {
    loansProduced: "6.36",
    membersServed: "462",
    interestEarned: "0.54",
    name: "MODESTO'S FIRST",
    website: "http://m1fcu.org",
  },
  "4024": {
    loansProduced: "11.19",
    membersServed: "2,138",
    interestEarned: "0.95",
    name: "TRUECORE",
    website: "http://www.truecore.org",
  },
  "4025": {
    loansProduced: "0.30",
    membersServed: "78",
    interestEarned: "0.03",
    name: "HAULPAK",
    website: null,
  },
  "4035": {
    loansProduced: "279.53",
    membersServed: "23,072",
    interestEarned: "23.76",
    name: "MEMBER ONE",
    website: "http://www.memberonefcu.com",
  },
  "4037": {
    loansProduced: "2.97",
    membersServed: "340",
    interestEarned: "0.25",
    name: "HUD",
    website: "http://www.hudfcu.org",
  },
  "4038": {
    loansProduced: "22.82",
    membersServed: "2,978",
    interestEarned: "1.94",
    name: "SERVICE 1",
    website: "http://www.service1fcu.com",
  },
  "4043": {
    loansProduced: "18.05",
    membersServed: "2,121",
    interestEarned: "1.53",
    name: "GENERAL ELECTRIC EMPLOYEES",
    website: "https://www.myGECreditUnion.com",
  },
  "4060": {
    loansProduced: "52.05",
    membersServed: "2,825",
    interestEarned: "4.42",
    name: "GULF COAST",
    website: "http://www.ccgcfcu.com",
  },
  "4078": {
    loansProduced: "0.67",
    membersServed: "40",
    interestEarned: "0.06",
    name: "BIVINS",
    website: "http://www.bivinsfcu.com",
  },
  "4088": {
    loansProduced: "2.31",
    membersServed: "543",
    interestEarned: "0.20",
    name: "STANWOOD AREA",
    website: "http://www.stanwoodareafcu.org",
  },
  "4090": {
    loansProduced: "0.95",
    membersServed: "191",
    interestEarned: "0.08",
    name: "MCCOMB",
    website: "http://mccombfcu.com",
  },
  "4095": {
    loansProduced: "2.20",
    membersServed: "191",
    interestEarned: "0.19",
    name: "ALLEGHENY LUDLUM BRACKENRIDGE",
    website: "http://www.albfcu.org",
  },
  "4098": {
    loansProduced: "2.33",
    membersServed: "157",
    interestEarned: "0.20",
    name: "U.P. CONNECTION",
    website: "http://www.upconnection.org",
  },
  "4116": {
    loansProduced: "5.72",
    membersServed: "405",
    interestEarned: "0.49",
    name: "GALAXY",
    website: "http://www.galaxyfcu.com",
  },
  "4118": {
    loansProduced: "13.20",
    membersServed: "1,586",
    interestEarned: "1.12",
    name: "SEABOARD",
    website: "http://www.seaboardfcu.com",
  },
  "4120": {
    loansProduced: "1.77",
    membersServed: "338",
    interestEarned: "0.15",
    name: "RELIANCE",
    website: "http://www.reliancefcu.com",
  },
  "4122": {
    loansProduced: "23.35",
    membersServed: "1,742",
    interestEarned: "1.98",
    name: "HIGHMARK",
    website: "http://www.highmarkfcu.com",
  },
  "4127": {
    loansProduced: "0.81",
    membersServed: "111",
    interestEarned: "0.07",
    name: "ELEKTRA",
    website: null,
  },
  "4135": {
    loansProduced: "29.53",
    membersServed: "6,606",
    interestEarned: "2.51",
    name: "KEYS",
    website: "http://www.keysfcu.org",
  },
  "4142": {
    loansProduced: "378.29",
    membersServed: "31,853",
    interestEarned: "32.15",
    name: "KINECTA",
    website: "http://www.kinecta.org",
  },
  "4148": {
    loansProduced: "5.47",
    membersServed: "286",
    interestEarned: "0.46",
    name: "HIGHWAY DISTRICT 21",
    website: "http://www.highwaydistrict21fcu.com",
  },
  "4152": {
    loansProduced: "2.01",
    membersServed: "215",
    interestEarned: "0.17",
    name: "CENTRAL SOYA",
    website: "https://www.centralsoyafcu.org",
  },
  "4158": {
    loansProduced: "1.10",
    membersServed: "188",
    interestEarned: "0.09",
    name: "ERIE LACKAWANNA RAILROAD CO EMP",
    website: null,
  },
  "4163": {
    loansProduced: "0.07",
    membersServed: "5",
    interestEarned: "0.01",
    name: "FRESNO GRANGERS",
    website: null,
  },
  "4164": {
    loansProduced: "44.87",
    membersServed: "3,071",
    interestEarned: "3.81",
    name: "MONTANA",
    website: "http://www.montanacu.com",
  },
  "4170": {
    loansProduced: "0.05",
    membersServed: "5",
    interestEarned: "0.00",
    name: "ABYSSINIAN BAPTIST CHURCH",
    website: "http://abyssinianfcu.org",
  },
  "4175": {
    loansProduced: "0.81",
    membersServed: "177",
    interestEarned: "0.07",
    name: "NEWARK FIREMEN",
    website: "http://newarkfiremenfcu.org",
  },
  "4176": {
    loansProduced: "1.22",
    membersServed: "73",
    interestEarned: "0.10",
    name: "BOX BUTTE PUBLIC EMPLOYEES",
    website: "http://WWW.BOXBUTTEFCU.COM",
  },
  "4192": {
    loansProduced: "0.41",
    membersServed: "76",
    interestEarned: "0.04",
    name: "BELMONT MUNICIPAL",
    website: null,
  },
  "4195": {
    loansProduced: "1.16",
    membersServed: "110",
    interestEarned: "0.10",
    name: "FCI",
    website: "http://fcifcu.com",
  },
  "4202": {
    loansProduced: "30.86",
    membersServed: "2,471",
    interestEarned: "2.62",
    name: "WESTERN SUN",
    website: "http://www.wsfcu.com",
  },
  "4227": {
    loansProduced: "2.11",
    membersServed: "429",
    interestEarned: "0.18",
    name: "BADLANDS",
    website: "http://badlandsfcu.org",
  },
  "4233": {
    loansProduced: "2.27",
    membersServed: "378",
    interestEarned: "0.19",
    name: "CITY CO",
    website: "http://www.citycofcu.com",
  },
  "4240": {
    loansProduced: "12.67",
    membersServed: "2,345",
    interestEarned: "1.08",
    name: "CHOICE ONE COMMUNITY",
    website: "http://www.choiceone.org",
  },
  "4246": {
    loansProduced: "0.05",
    membersServed: "9",
    interestEarned: "0.00",
    name: "FAR ROCKAWAY POSTAL",
    website: null,
  },
  "4261": {
    loansProduced: "13.38",
    membersServed: "2,298",
    interestEarned: "1.14",
    name: "NORSTATE",
    website: "http://www.norstatefcu.org",
  },
  "4271": {
    loansProduced: "23.09",
    membersServed: "4,613",
    interestEarned: "1.96",
    name: "CHIEF FINANCIAL",
    website: "http://www.chiefonline.com",
  },
  "4281": {
    loansProduced: "55.51",
    membersServed: "5,794",
    interestEarned: "4.72",
    name: "ATLANTIC REGIONAL",
    website: "http://atlanticfcu.com",
  },
  "4291": {
    loansProduced: "24.15",
    membersServed: "2,950",
    interestEarned: "2.05",
    name: "PENINSULA",
    website: "http://peninsulafcu.com",
  },
  "4292": {
    loansProduced: "1.22",
    membersServed: "158",
    interestEarned: "0.10",
    name: "TEXOMA",
    website: "http://www.texomafcu.com",
  },
  "4365": {
    loansProduced: "144.37",
    membersServed: "15,451",
    interestEarned: "12.27",
    name: "BLACK HILLS",
    website: "http://www.blackhillsfcu.org",
  },
  "4366": {
    loansProduced: "1.38",
    membersServed: "167",
    interestEarned: "0.12",
    name: "F.C.I. ASHLAND",
    website: null,
  },
  "4369": {
    loansProduced: "4.29",
    membersServed: "506",
    interestEarned: "0.36",
    name: "GREATER WAYNE COMMUNITY",
    website: "http://gwcfcu.com",
  },
  "4374": {
    loansProduced: "18.40",
    membersServed: "1,492",
    interestEarned: "1.56",
    name: "SHERIDAN COMMUNITY",
    website: "www.sheridancreditunion.com",
  },
  "4393": {
    loansProduced: "0.51",
    membersServed: "40",
    interestEarned: "0.04",
    name: "SUNKIST EMPLOYEES",
    website: "http://sunkistefcu.com",
  },
  "4395": {
    loansProduced: "5.22",
    membersServed: "1,154",
    interestEarned: "0.44",
    name: "TAMPA POSTAL",
    website: "http://www.tpcu.org",
  },
  "4400": {
    loansProduced: "13.23",
    membersServed: "1,600",
    interestEarned: "1.12",
    name: "GREENVILLE HERITAGE",
    website: "http://www.greenvilleheritage.com",
  },
  "4401": {
    loansProduced: "5.90",
    membersServed: "1,938",
    interestEarned: "0.50",
    name: "SOUTHWEST MONTANA COMMUNITY",
    website: "http://www.swmcfcu.org",
  },
  "4416": {
    loansProduced: "0.03",
    membersServed: "13",
    interestEarned: "0.00",
    name: "IBERIA PARISH",
    website: null,
  },
  "4427": {
    loansProduced: "1.46",
    membersServed: "111",
    interestEarned: "0.12",
    name: "U S P L K EMPLOYEES",
    website: "http://usplkefcu.org",
  },
  "4441": {
    loansProduced: "1.23",
    membersServed: "122",
    interestEarned: "0.10",
    name: "SING SING EMPLOYEES",
    website: "http://singsingfcu.org",
  },
  "4461": {
    loansProduced: "3.97",
    membersServed: "432",
    interestEarned: "0.34",
    name: "NODA",
    website: "http://nodafcu.org",
  },
  "4472": {
    loansProduced: "0.74",
    membersServed: "181",
    interestEarned: "0.06",
    name: "MALDEN",
    website: "http://maldenfcu.com",
  },
  "4480": {
    loansProduced: "21.55",
    membersServed: "1,652",
    interestEarned: "1.83",
    name: "PALISADES",
    website: "http://www.palisadesfcu.org",
  },
  "4487": {
    loansProduced: "0.61",
    membersServed: "66",
    interestEarned: "0.05",
    name: "SOUTH BEND TRANSIT",
    website: "http://www.sbtransitfcu.net",
  },
  "4488": {
    loansProduced: "0.85",
    membersServed: "70",
    interestEarned: "0.07",
    name: "T H P",
    website: "https://bsdc.onlinecu.com/thpfcu/#/",
  },
  "4509": {
    loansProduced: "1.21",
    membersServed: "115",
    interestEarned: "0.10",
    name: "PUD",
    website: "http://www.pudfcu.com",
  },
  "4524": {
    loansProduced: "1.35",
    membersServed: "319",
    interestEarned: "0.11",
    name: "SOMERVILLE MUNICIPAL",
    website: "http://www.sommfcu.com",
  },
  "4531": {
    loansProduced: "1.80",
    membersServed: "126",
    interestEarned: "0.15",
    name: "KC UNIDOS",
    website: "http://www.kcunidosfcu.com",
  },
  "4580": {
    loansProduced: "239.89",
    membersServed: "21,992",
    interestEarned: "20.39",
    name: "THE SUMMIT",
    website: "http://WWW.SUMMITFCU.ORG",
  },
  "4584": {
    loansProduced: "209.58",
    membersServed: "14,634",
    interestEarned: "17.81",
    name: "AUSTIN TELCO",
    website: "https://www.atfcu.org",
  },
  "4611": {
    loansProduced: "1.10",
    membersServed: "170",
    interestEarned: "0.09",
    name: "NEWARK POLICE",
    website: null,
  },
  "4616": {
    loansProduced: "28.44",
    membersServed: "2,725",
    interestEarned: "2.42",
    name: "BRONCO",
    website: "http://www.broncofcu.com",
  },
  "4633": {
    loansProduced: "10.67",
    membersServed: "2,008",
    interestEarned: "0.91",
    name: "CAMINO",
    website: "http://www.caminofcu.org",
  },
  "4684": {
    loansProduced: "13.13",
    membersServed: "931",
    interestEarned: "1.12",
    name: "CONCHO EDUCATORS",
    website: "http://www.conchoeducators.org",
  },
  "4708": {
    loansProduced: "6.30",
    membersServed: "765",
    interestEarned: "0.54",
    name: "EMBARRASS VERMILLION",
    website: "http://www.evfcu.org",
  },
  "4714": {
    loansProduced: "24.15",
    membersServed: "2,369",
    interestEarned: "2.05",
    name: "UNITED SOUTHEAST",
    website: "http://usfcu.org",
  },
  "4726": {
    loansProduced: "5.00",
    membersServed: "966",
    interestEarned: "0.43",
    name: "RARITAN BAY",
    website: "http://www.rbfcu.coop",
  },
  "4731": {
    loansProduced: "7.99",
    membersServed: "956",
    interestEarned: "0.68",
    name: "METROWEST COMMUNITY",
    website: "http://www.mwcfcu.com",
  },
  "4735": {
    loansProduced: "167.85",
    membersServed: "17,231",
    interestEarned: "14.27",
    name: "BETHPAGE",
    website: "https://www.bethpagefcu.com",
  },
  "4746": {
    loansProduced: "18.90",
    membersServed: "1,310",
    interestEarned: "1.61",
    name: "NEW HAMPSHIRE",
    website: "http://www.nhfcu.org",
  },
  "4756": {
    loansProduced: "0.75",
    membersServed: "94",
    interestEarned: "0.06",
    name: "ALLEGHENY VALLEY",
    website: "http://www.alleghenyvalleyfcu.com",
  },
  "4787": {
    loansProduced: "1.59",
    membersServed: "381",
    interestEarned: "0.14",
    name: "TANDEM",
    website: "http://www.tandemfcu.com",
  },
  "4792": {
    loansProduced: "16.44",
    membersServed: "1,930",
    interestEarned: "1.40",
    name: "NET",
    website: "http://netcreditunion.com",
  },
  "4794": {
    loansProduced: "5.73",
    membersServed: "551",
    interestEarned: "0.49",
    name: "BEAVER VALLEY",
    website: "http://www.beavervalleyfcu.org",
  },
  "4799": {
    loansProduced: "2.27",
    membersServed: "195",
    interestEarned: "0.19",
    name: "PINE BELT",
    website: "http://www.pinebeltfcu.org",
  },
  "4805": {
    loansProduced: "2.32",
    membersServed: "182",
    interestEarned: "0.20",
    name: "NORTHERN NEW MEXICO SCHOOL EMPLOYEE",
    website: "http://www.nmsefcu.org",
  },
  "4809": {
    loansProduced: "3.57",
    membersServed: "251",
    interestEarned: "0.30",
    name: "PARTHENON",
    website: "http://www.parthenonfcu.org",
  },
  "4828": {
    loansProduced: "3.44",
    membersServed: "402",
    interestEarned: "0.29",
    name: "STEEL STRONG COMMUNITY",
    website: "www.steelstrongcfcu.org",
  },
  "4845": {
    loansProduced: "4.85",
    membersServed: "424",
    interestEarned: "0.41",
    name: "ALL SAINTS",
    website: "http://www.allsaintsfcu.com",
  },
  "4853": {
    loansProduced: "11.41",
    membersServed: "3,316",
    interestEarned: "0.97",
    name: "DOWNRIVER COMMUNITY",
    website: "http://www.downrivercu.com",
  },
  "4871": {
    loansProduced: "0.13",
    membersServed: "14",
    interestEarned: "0.01",
    name: "E R R L",
    website: null,
  },
  "4878": {
    loansProduced: "34.70",
    membersServed: "3,597",
    interestEarned: "2.95",
    name: "PACIFIC N W",
    website: "http://www.pnwfcu.org",
  },
  "4884": {
    loansProduced: "5.30",
    membersServed: "744",
    interestEarned: "0.45",
    name: "ST. JOSEPHS CANTON PARISH",
    website: "WWW.STJFCU.ORG",
  },
  "4894": {
    loansProduced: "0.68",
    membersServed: "184",
    interestEarned: "0.06",
    name: "FEDERAL EMPLOYEES NEWARK",
    website: "http://WWW.FENFCU.COM",
  },
  "4900": {
    loansProduced: "10.79",
    membersServed: "1,071",
    interestEarned: "0.92",
    name: "COOPERATIVE CENTER",
    website: "http://www.coopfcu.org",
  },
  "4906": {
    loansProduced: "0.58",
    membersServed: "43",
    interestEarned: "0.05",
    name: "TRENTON NJ FIREMEN",
    website: null,
  },
  "4915": {
    loansProduced: "32.97",
    membersServed: "4,433",
    interestEarned: "2.80",
    name: "A. E. A.",
    website: "http://WWW.AEAFCU.ORG",
  },
  "4936": {
    loansProduced: "4.84",
    membersServed: "2,476",
    interestEarned: "0.41",
    name: "PATENT AND TRADEMARK OFFICE",
    website: "http://www.ptofcu.org",
  },
  "4951": {
    loansProduced: "3.72",
    membersServed: "249",
    interestEarned: "0.32",
    name: "NORSTAR",
    website: "norstarfcu.com",
  },
  "4960": {
    loansProduced: "8.74",
    membersServed: "1,224",
    interestEarned: "0.74",
    name: "COTTONWOOD COMMUNITY",
    website: "http://www.ccfcu.coop",
  },
  "4963": {
    loansProduced: "13.96",
    membersServed: "3,083",
    interestEarned: "1.19",
    name: "DEPT OF LABOR",
    website: "http://www.dolfcu.org",
  },
  "4966": {
    loansProduced: "10.05",
    membersServed: "1,327",
    interestEarned: "0.85",
    name: "UNITY CATHOLIC",
    website: "http://www.unitycatholiccu.org",
  },
  "4968": {
    loansProduced: "89.58",
    membersServed: "7,723",
    interestEarned: "7.61",
    name: "INOVA",
    website: "http://www.inovafederal.org",
  },
  "4969": {
    loansProduced: "6.83",
    membersServed: "805",
    interestEarned: "0.58",
    name: "MEMBERS CHOICE WV",
    website: "http://www.memberschoicewv.com",
  },
  "4980": {
    loansProduced: "72.23",
    membersServed: "6,651",
    interestEarned: "6.14",
    name: "SESLOC",
    website: "http://www.sesloc.org",
  },
  "4982": {
    loansProduced: "4.27",
    membersServed: "427",
    interestEarned: "0.36",
    name: "BESSEMER SYSTEM",
    website: "http://bessfcu.com",
  },
  "5028": {
    loansProduced: "0.47",
    membersServed: "36",
    interestEarned: "0.04",
    name: "EQT",
    website: "http://www.eqtfcu.org",
  },
  "5029": {
    loansProduced: "153.46",
    membersServed: "16,254",
    interestEarned: "13.04",
    name: "RED RIVER EMPLOYEES",
    website: "http://www.rrcu.com",
  },
  "5045": {
    loansProduced: "0.82",
    membersServed: "219",
    interestEarned: "0.07",
    name: "CHICAGO AREA OFFICE",
    website: "http://www.chicagoareafcu.org",
  },
  "5062": {
    loansProduced: "0.05",
    membersServed: "7",
    interestEarned: "0.00",
    name: "NORTHWOOD",
    website: "http://northwoodfcu.com",
  },
  "5063": {
    loansProduced: "5.40",
    membersServed: "525",
    interestEarned: "0.46",
    name: "DANIELS-SHERIDAN",
    website: "https://www.dsfcu.net",
  },
  "5086": {
    loansProduced: "30.23",
    membersServed: "2,840",
    interestEarned: "2.57",
    name: "CSE",
    website: "http://www.csefcu.org",
  },
  "5116": {
    loansProduced: "1.42",
    membersServed: "196",
    interestEarned: "0.12",
    name: "PORT CITY",
    website: "http://www.portcityfcu.com",
  },
  "5123": {
    loansProduced: "1.43",
    membersServed: "160",
    interestEarned: "0.12",
    name: "INTERNATIONAL UAW",
    website: "http://www.iuawfcu.com",
  },
  "5127": {
    loansProduced: "0.04",
    membersServed: "4",
    interestEarned: "0.00",
    name: "CHURCH OF THE MASTER",
    website: "churchofthemasterfcu.com",
  },
  "5134": {
    loansProduced: "51.21",
    membersServed: "4,486",
    interestEarned: "4.35",
    name: "COMMONWEALTH ONE",
    website: "http://www.cofcu.org",
  },
  "5144": {
    loansProduced: "1.66",
    membersServed: "161",
    interestEarned: "0.14",
    name: "MILESTONES",
    website: "https://milestonesfcu.com/",
  },
  "5163": {
    loansProduced: "0.65",
    membersServed: "48",
    interestEarned: "0.06",
    name: "GREAT NECK SCHOOL EMPLOYEES",
    website: null,
  },
  "5168": {
    loansProduced: "49.96",
    membersServed: "7,350",
    interestEarned: "4.25",
    name: "ACFCU",
    website: "http://www.acfcu.org",
  },
  "5170": {
    loansProduced: "3.32",
    membersServed: "476",
    interestEarned: "0.28",
    name: "GRAND TRUNK BATTLE CREEK EMP",
    website: "http://www.grandtrunkcu.com",
  },
  "5227": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "ASBURY",
    website: null,
  },
  "5234": {
    loansProduced: "49.13",
    membersServed: "3,861",
    interestEarned: "4.18",
    name: "CEDAR POINT",
    website: "http://www.cpfcu.com",
  },
  "5241": {
    loansProduced: "3.78",
    membersServed: "395",
    interestEarned: "0.32",
    name: "SYCAMORE",
    website: "http://WWW.SYCAMOREFCU.COM",
  },
  "5252": {
    loansProduced: "4.13",
    membersServed: "545",
    interestEarned: "0.35",
    name: "SARATOGA'S COMMUNITY",
    website: "https://saratogafcu.org",
  },
  "5256": {
    loansProduced: "1.62",
    membersServed: "152",
    interestEarned: "0.14",
    name: "600 ATLANTIC",
    website: "http://www.600atlanticfcu.org",
  },
  "5257": {
    loansProduced: "10.11",
    membersServed: "1,400",
    interestEarned: "0.86",
    name: "KANSAS STATE UNIVERSITY",
    website: "http://www.kstatecu.com",
  },
  "5272": {
    loansProduced: "1.10",
    membersServed: "127",
    interestEarned: "0.09",
    name: "NORTHERN LIGHTS COMMUNITY",
    website: "http://www.nlightsfcu.com",
  },
  "5286": {
    loansProduced: "3.33",
    membersServed: "331",
    interestEarned: "0.28",
    name: "KELLOGG MIDWEST",
    website: "http://www.kellogg-fcu.org",
  },
  "5291": {
    loansProduced: "6.98",
    membersServed: "641",
    interestEarned: "0.59",
    name: "HASTINGS",
    website: "http://www.hastingsfcu.org",
  },
  "5318": {
    loansProduced: "6.33",
    membersServed: "479",
    interestEarned: "0.54",
    name: "TENNESSEE MEMBERS 1ST",
    website: "http://www.mymembersfirst.org",
  },
  "5321": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "CRIERS",
    website: null,
  },
  "5325": {
    loansProduced: "1.96",
    membersServed: "132",
    interestEarned: "0.17",
    name: "SWEMP",
    website: null,
  },
  "5337": {
    loansProduced: "3.72",
    membersServed: "565",
    interestEarned: "0.32",
    name: "TEXAS PEOPLE",
    website: "http://www.fwtpfcu.org",
  },
  "5345": {
    loansProduced: "0.61",
    membersServed: "59",
    interestEarned: "0.05",
    name: "MONTGOMERY VA",
    website: "http://WWW.MVAFCU.ORG",
  },
  "5359": {
    loansProduced: "2.07",
    membersServed: "301",
    interestEarned: "0.18",
    name: "SUN PACIFIC",
    website: "http://sunpacific.org",
  },
  "5366": {
    loansProduced: "6.02",
    membersServed: "772",
    interestEarned: "0.51",
    name: "DESERT VALLEYS",
    website: "http://www.desertvalleys.org",
  },
  "5370": {
    loansProduced: "29.02",
    membersServed: "3,962",
    interestEarned: "2.47",
    name: "FREMONT",
    website: "https://www.fremontfcu.com",
  },
  "5373": {
    loansProduced: "7.04",
    membersServed: "578",
    interestEarned: "0.60",
    name: "WESTEX",
    website: "http://www.westexfcu.com",
  },
  "5380": {
    loansProduced: "56.93",
    membersServed: "3,231",
    interestEarned: "4.84",
    name: "SPECTRA",
    website: "http://www.spectracu.com",
  },
  "5387": {
    loansProduced: "18.12",
    membersServed: "1,056",
    interestEarned: "1.54",
    name: "RUSSELL COUNTRY",
    website: "http://www.russellcountryfcu.org",
  },
  "5419": {
    loansProduced: "3.35",
    membersServed: "501",
    interestEarned: "0.29",
    name: "RIDGEDALE",
    website: "http://WWW.RIDGEDALEFCU.ORG",
  },
  "5422": {
    loansProduced: "7.64",
    membersServed: "920",
    interestEarned: "0.65",
    name: "CHIPHONE",
    website: "http://WWW.CHIPHONE.ORG",
  },
  "5431": {
    loansProduced: "42.41",
    membersServed: "4,325",
    interestEarned: "3.60",
    name: "FORT FINANCIAL",
    website: "http://FortFinancial.org",
  },
  "5439": {
    loansProduced: "4.12",
    membersServed: "530",
    interestEarned: "0.35",
    name: "AMHERST",
    website: "http://www.amherstcu.org",
  },
  "5465": {
    loansProduced: "4.43",
    membersServed: "633",
    interestEarned: "0.38",
    name: "FIRST EDUCATION",
    website: "https://firstedfcu.com",
  },
  "5484": {
    loansProduced: "2.18",
    membersServed: "219",
    interestEarned: "0.18",
    name: "SHELL WESTERN STATES",
    website: "https://www.swsfcu.org",
  },
  "5487": {
    loansProduced: "7.78",
    membersServed: "1,946",
    interestEarned: "0.66",
    name: "KAUAI",
    website: "http://www.kauaicreditunion.org",
  },
  "5495": {
    loansProduced: "48.47",
    membersServed: "3,183",
    interestEarned: "4.12",
    name: "GLASS CITY",
    website: "https://www.glasscityfcu.com",
  },
  "5500": {
    loansProduced: "536.66",
    membersServed: "38,734",
    interestEarned: "45.62",
    name: "NORTHWEST",
    website: "http://www.nwfcu.org",
  },
  "5510": {
    loansProduced: "3.64",
    membersServed: "320",
    interestEarned: "0.31",
    name: "CATHOLIC FAMILY",
    website: "http://www.cffcu.com",
  },
  "5524": {
    loansProduced: "0.33",
    membersServed: "25",
    interestEarned: "0.03",
    name: "CHRISTO REY",
    website: null,
  },
  "5530": {
    loansProduced: "8.87",
    membersServed: "926",
    interestEarned: "0.75",
    name: "VA DESERT PACIFIC",
    website: "http://www.vadpfcu.org",
  },
  "5531": {
    loansProduced: "1.29",
    membersServed: "175",
    interestEarned: "0.11",
    name: "MCALESTER AAP",
    website: null,
  },
  "5536": {
    loansProduced: "18,917.83",
    membersServed: "2,110,476",
    interestEarned: "1,608.02",
    name: "NAVY FEDERAL CREDIT UNION",
    website: "www.navyfcu.org",
  },
  "5539": {
    loansProduced: "0.93",
    membersServed: "141",
    interestEarned: "0.08",
    name: "PHILADELPHIA LETTER CARRIERS",
    website: "http://www.phillycreditunion.com",
  },
  "5546": {
    loansProduced: "4.26",
    membersServed: "438",
    interestEarned: "0.36",
    name: "ADIRONDACK REGIONAL",
    website: "http://www.adkcreditunion.com",
  },
  "5547": {
    loansProduced: "83.10",
    membersServed: "4,595",
    interestEarned: "7.06",
    name: "SECURITY FIRST",
    website: "https://securityfirstcu.com",
  },
  "5549": {
    loansProduced: "6.08",
    membersServed: "659",
    interestEarned: "0.52",
    name: "CLINCHFIELD",
    website: "http://www.clinchfieldcu.com",
  },
  "5554": {
    loansProduced: "2.26",
    membersServed: "530",
    interestEarned: "0.19",
    name: "MICHIGAN COLUMBUS",
    website: "http://www.mcfcu.net",
  },
  "5555": {
    loansProduced: "0.28",
    membersServed: "67",
    interestEarned: "0.02",
    name: "WACONIZED",
    website: null,
  },
  "5561": {
    loansProduced: "514.76",
    membersServed: "43,482",
    interestEarned: "43.75",
    name: "KEESLER",
    website: "http://www.kfcu.org",
  },
  "5569": {
    loansProduced: "3.34",
    membersServed: "511",
    interestEarned: "0.28",
    name: "DIXIES",
    website: "http://www.dixiefed.com",
  },
  "5571": {
    loansProduced: "19.24",
    membersServed: "2,559",
    interestEarned: "1.64",
    name: "SIGNAL FINANCIAL",
    website: "https://www.signalfinancialfcu.org",
  },
  "5579": {
    loansProduced: "20.35",
    membersServed: "1,860",
    interestEarned: "1.73",
    name: "ACCESS",
    website: "http://www.accessfcu.org",
  },
  "5582": {
    loansProduced: "74.87",
    membersServed: "11,170",
    interestEarned: "6.36",
    name: "BANK FUND STAFF",
    website: "https://bfsfcu.org/",
  },
  "5588": {
    loansProduced: "233.74",
    membersServed: "32,578",
    interestEarned: "19.87",
    name: "UNITED NATIONS",
    website: "http://www.unfcu.org",
  },
  "5589": {
    loansProduced: "0.54",
    membersServed: "143",
    interestEarned: "0.05",
    name: "KC FAIRFAX",
    website: "http://www.kcffcu.com",
  },
  "5602": {
    loansProduced: "17.19",
    membersServed: "1,602",
    interestEarned: "1.46",
    name: "TAUNTON",
    website: "http://www.tauntonfcu.com",
  },
  "5604": {
    loansProduced: "6.74",
    membersServed: "1,260",
    interestEarned: "0.57",
    name: "ROANOKE VALLEY COMMUNITY",
    website: "http://www.rvccu.org",
  },
  "5612": {
    loansProduced: "87.41",
    membersServed: "6,296",
    interestEarned: "7.43",
    name: "ALTAONE",
    website: "http://www.altaone.org",
  },
  "5621": {
    loansProduced: "538.97",
    membersServed: "47,786",
    interestEarned: "45.81",
    name: "AMERICAN HERITAGE",
    website: "http://www.americanheritagecu.org",
  },
  "5636": {
    loansProduced: "40.00",
    membersServed: "3,728",
    interestEarned: "3.40",
    name: "CHESSIE",
    website: "http://www.chessiefcu.org",
  },
  "5641": {
    loansProduced: "2.49",
    membersServed: "160",
    interestEarned: "0.21",
    name: "CENSUS",
    website: "http://www.censusfcu.com",
  },
  "5648": {
    loansProduced: "127.15",
    membersServed: "10,091",
    interestEarned: "10.81",
    name: "GULF COAST EDUCATORS",
    website: "http://www.gcefcu.org",
  },
  "5652": {
    loansProduced: "31.24",
    membersServed: "2,841",
    interestEarned: "2.66",
    name: "CASCADE COMMUNITY",
    website: "http://www.cascadecu.org",
  },
  "5655": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "UNION CONGREGATIONAL",
    website: null,
  },
  "5660": {
    loansProduced: "4.67",
    membersServed: "913",
    interestEarned: "0.40",
    name: "IRON MOUNTAIN KINGSFORD COMMUNITY",
    website: "http://www.pcbranch.com",
  },
  "5665": {
    loansProduced: "19.71",
    membersServed: "2,400",
    interestEarned: "1.67",
    name: "CITY",
    website: "http://CITYFEDERALCU.com",
  },
  "5684": {
    loansProduced: "2.81",
    membersServed: "363",
    interestEarned: "0.24",
    name: "INLAND VALLEY",
    website: "http://www.inlandvalleyfcu.com",
  },
  "5686": {
    loansProduced: "2.42",
    membersServed: "216",
    interestEarned: "0.21",
    name: "MARSHALL T & P EMPLOYEES",
    website: null,
  },
  "5713": {
    loansProduced: "2.54",
    membersServed: "263",
    interestEarned: "0.22",
    name: "NATIONAL EMPLOYEES",
    website: "http://www.nefcu.virtualcu.net",
  },
  "5718": {
    loansProduced: "36.99",
    membersServed: "3,773",
    interestEarned: "3.14",
    name: "TUCOEMAS",
    website: "http://tucoemas.org",
  },
  "5721": {
    loansProduced: "7.66",
    membersServed: "837",
    interestEarned: "0.65",
    name: "TWO HARBORS",
    website: "http://www.thfcu.org",
  },
  "5732": {
    loansProduced: "0.53",
    membersServed: "209",
    interestEarned: "0.04",
    name: "YS",
    website: "http://yscu.org",
  },
  "5736": {
    loansProduced: "1.06",
    membersServed: "130",
    interestEarned: "0.09",
    name: "UNILEVER",
    website: "http://www.unileverfcu.org",
  },
  "5741": {
    loansProduced: "1.67",
    membersServed: "237",
    interestEarned: "0.14",
    name: "CHIVAHO",
    website: "http://www.chivaho.com",
  },
  "5748": {
    loansProduced: "11.60",
    membersServed: "1,264",
    interestEarned: "0.99",
    name: "INTEGRA FIRST",
    website: "http://www.integrafirstfcu.com",
  },
  "5754": {
    loansProduced: "125.63",
    membersServed: "42,434",
    interestEarned: "10.68",
    name: "ANDREWS FEDERAL CREDIT UNION",
    website: "andrewsfcu.org",
  },
  "5773": {
    loansProduced: "8.11",
    membersServed: "641",
    interestEarned: "0.69",
    name: "HAYNES COMMUNITY",
    website: "http://www.haynesfcu.org",
  },
  "5780": {
    loansProduced: "0.50",
    membersServed: "39",
    interestEarned: "0.04",
    name: "TREASURY EMPLOYEES",
    website: null,
  },
  "5781": {
    loansProduced: "0.46",
    membersServed: "37",
    interestEarned: "0.04",
    name: "ZIEGLER",
    website: null,
  },
  "5785": {
    loansProduced: "4.33",
    membersServed: "460",
    interestEarned: "0.37",
    name: "AFFINITY ONE",
    website: "http://affinityonefcu.org",
  },
  "5797": {
    loansProduced: "323.81",
    membersServed: "20,914",
    interestEarned: "27.52",
    name: "ORNL",
    website: "http://www.ornlfcu.com",
  },
  "5811": {
    loansProduced: "3.99",
    membersServed: "336",
    interestEarned: "0.34",
    name: "KENNEDY VA EMPLOYEES",
    website: "http://www.kennedyvaefcu.org",
  },
  "5816": {
    loansProduced: "3.92",
    membersServed: "1,239",
    interestEarned: "0.33",
    name: "INTERFAITH",
    website: "http://www.interfaithfcu.org",
  },
  "5822": {
    loansProduced: "2.08",
    membersServed: "178",
    interestEarned: "0.18",
    name: "TEXARKANA TERMINAL EMPLOYEES",
    website: "http://WWW.TEXARKANATERMINALFCU.COM",
  },
  "5839": {
    loansProduced: "0.69",
    membersServed: "77",
    interestEarned: "0.06",
    name: "SOUTHEAST LOUISIANA VETERANS HEALTH",
    website: "http://www.slvhcsfcu.com",
  },
  "5845": {
    loansProduced: "1.73",
    membersServed: "244",
    interestEarned: "0.15",
    name: "ALLWEALTH",
    website: "http://www.allwealth.org",
  },
  "5851": {
    loansProduced: "218.64",
    membersServed: "19,965",
    interestEarned: "18.58",
    name: "SANDIA LABORATORY",
    website: "http://www.slfcu.org",
  },
  "5852": {
    loansProduced: "20.46",
    membersServed: "3,325",
    interestEarned: "1.74",
    name: "CENTURY",
    website: "http://cenfedcu.org",
  },
  "5856": {
    loansProduced: "26.86",
    membersServed: "4,851",
    interestEarned: "2.28",
    name: "RIVERFRONT",
    website: "http://riverfrontfcu.org",
  },
  "5866": {
    loansProduced: "22.72",
    membersServed: "2,474",
    interestEarned: "1.93",
    name: "SABINE",
    website: "http://www.sabinefcu.org",
  },
  "5867": {
    loansProduced: "3.66",
    membersServed: "1,017",
    interestEarned: "0.31",
    name: "VANTAGE TRUST",
    website: "http://WWW.VANTAGETRUSTFCU.ORG",
  },
  "5885": {
    loansProduced: "3.06",
    membersServed: "1,009",
    interestEarned: "0.26",
    name: "A.B.D.",
    website: "http://www.abdfcu.com",
  },
  "5892": {
    loansProduced: "25.32",
    membersServed: "1,781",
    interestEarned: "2.15",
    name: "TRUE NORTH",
    website: "http://www.truenorthfcu.org",
  },
  "5913": {
    loansProduced: "1,519.69",
    membersServed: "123,610",
    interestEarned: "129.17",
    name: "GLOBAL",
    website: "https://www.alaskausa.org",
  },
  "5922": {
    loansProduced: "1.19",
    membersServed: "112",
    interestEarned: "0.10",
    name: "SUBIACO",
    website: "http://subiacofcu.org",
  },
  "5924": {
    loansProduced: "67.13",
    membersServed: "4,404",
    interestEarned: "5.71",
    name: "MATANUSKA VALLEY",
    website: "http://www.mvfcu.coop",
  },
  "5928": {
    loansProduced: "10.25",
    membersServed: "2,290",
    interestEarned: "0.87",
    name: "WAYNE-WESTLAND",
    website: "http://www.wwfcu.org",
  },
  "5930": {
    loansProduced: "2.20",
    membersServed: "301",
    interestEarned: "0.19",
    name: "HEALTHPLUS",
    website: "http://www.healthplusfcu.com",
  },
  "5935": {
    loansProduced: "3.88",
    membersServed: "259",
    interestEarned: "0.33",
    name: "COWBOY COUNTRY",
    website: "http://www.cowboycountry.virtualcu.",
  },
  "5960": {
    loansProduced: "0.07",
    membersServed: "8",
    interestEarned: "0.01",
    name: "U.S.B. EMPLOYEES",
    website: null,
  },
  "5970": {
    loansProduced: "0.91",
    membersServed: "120",
    interestEarned: "0.08",
    name: "METROPOLITAN CHURCH",
    website: null,
  },
  "5974": {
    loansProduced: "11.49",
    membersServed: "1,326",
    interestEarned: "0.98",
    name: "SOUTHERN SECURITY",
    website: "http://www.southernsecurity.org",
  },
  "5982": {
    loansProduced: "15.75",
    membersServed: "1,404",
    interestEarned: "1.34",
    name: "ROUTE 31",
    website: "www.route31cu.com",
  },
  "5987": {
    loansProduced: "0.58",
    membersServed: "214",
    interestEarned: "0.05",
    name: "OCNAC #1",
    website: "http://ocnac.com",
  },
  "6008": {
    loansProduced: "1.62",
    membersServed: "119",
    interestEarned: "0.14",
    name: "SEAGOVILLE",
    website: "http://seagovillefcu.com",
  },
  "6011": {
    loansProduced: "168.06",
    membersServed: "12,614",
    interestEarned: "14.29",
    name: "SIDNEY",
    website: "http://www.sfcuonline.org",
  },
  "6012": {
    loansProduced: "6.75",
    membersServed: "807",
    interestEarned: "0.57",
    name: "LIFETIME",
    website: "http://lifetimefcu.org",
  },
  "6032": {
    loansProduced: "51.61",
    membersServed: "6,225",
    interestEarned: "4.39",
    name: "STRATA",
    website: "http://www.stratacu.org",
  },
  "6039": {
    loansProduced: "5.64",
    membersServed: "410",
    interestEarned: "0.48",
    name: "LM",
    website: "http://www.lmfcu.org",
  },
  "6048": {
    loansProduced: "1.55",
    membersServed: "141",
    interestEarned: "0.13",
    name: "SHREVEPORT POLICE",
    website: null,
  },
  "6053": {
    loansProduced: "23.73",
    membersServed: "1,405",
    interestEarned: "2.02",
    name: "CDC",
    website: "http://www.cdcfcu.com",
  },
  "6062": {
    loansProduced: "23.12",
    membersServed: "1,624",
    interestEarned: "1.97",
    name: "BEACON",
    website: "http://www.beaconfed.org",
  },
  "6070": {
    loansProduced: "1.88",
    membersServed: "330",
    interestEarned: "0.16",
    name: "TRI STATE RAIL",
    website: "http://www.tristaterailfcu.com",
  },
  "6072": {
    loansProduced: "6.68",
    membersServed: "754",
    interestEarned: "0.57",
    name: "PROSPECTORS FCU",
    website: "http://prospectorsfcu.org",
  },
  "6079": {
    loansProduced: "8.74",
    membersServed: "903",
    interestEarned: "0.74",
    name: "SELECT SEVEN",
    website: "http://www.selectsevencu.org",
  },
  "6084": {
    loansProduced: "3.07",
    membersServed: "831",
    interestEarned: "0.26",
    name: "FRONT ROYAL",
    website: "http://www.frontroyalfcu.org",
  },
  "6088": {
    loansProduced: "0.87",
    membersServed: "176",
    interestEarned: "0.07",
    name: "D C FIRE DEPARTMENT",
    website: null,
  },
  "6095": {
    loansProduced: "7.79",
    membersServed: "762",
    interestEarned: "0.66",
    name: "HERSHEY",
    website: "http://www.hersheyfcu.org",
  },
  "6102": {
    loansProduced: "6.65",
    membersServed: "1,016",
    interestEarned: "0.57",
    name: "GREAT LAKES FIRST",
    website: "http://www.glffcu.com",
  },
  "6112": {
    loansProduced: "0.70",
    membersServed: "53",
    interestEarned: "0.06",
    name: "WATERTOWN POSTAL",
    website: "http://WATERTOWNPOSTALFCU.ORG",
  },
  "6116": {
    loansProduced: "7.47",
    membersServed: "557",
    interestEarned: "0.63",
    name: "VALEX",
    website: "http://www.valexfcu.org",
  },
  "6134": {
    loansProduced: "1.44",
    membersServed: "117",
    interestEarned: "0.12",
    name: "FORT SMITH DIXIE CUP",
    website: "http://www.dixiecupfcu.com",
  },
  "6144": {
    loansProduced: "0.67",
    membersServed: "56",
    interestEarned: "0.06",
    name: "RED LAKE CO OP",
    website: "http://redlakecfcu.com",
  },
  "6150": {
    loansProduced: "1.23",
    membersServed: "90",
    interestEarned: "0.10",
    name: "DOW BUCKS COUNTY",
    website: null,
  },
  "6160": {
    loansProduced: "79.57",
    membersServed: "6,484",
    interestEarned: "6.76",
    name: "SUN EAST",
    website: "http://www.suneast.org",
  },
  "6175": {
    loansProduced: "4.57",
    membersServed: "2,771",
    interestEarned: "0.39",
    name: "OLD OCEAN",
    website: "http://www.oofcu.org",
  },
  "6178": {
    loansProduced: "228.01",
    membersServed: "19,805",
    interestEarned: "19.38",
    name: "A+",
    website: "https://www.aplusfcu.org",
  },
  "6179": {
    loansProduced: "1.42",
    membersServed: "272",
    interestEarned: "0.12",
    name: "COMMUNITY FINANCIAL SERVICES",
    website: "http://cfsfcu.com",
  },
  "6184": {
    loansProduced: "0.15",
    membersServed: "96",
    interestEarned: "0.01",
    name: "ST. ANN'S ARLINGTON",
    website: "http://www.rc.net/arlington/stann/c",
  },
  "6187": {
    loansProduced: "14.53",
    membersServed: "1,457",
    interestEarned: "1.23",
    name: "THE ONE",
    website: "http://www.onefcu.com",
  },
  "6196": {
    loansProduced: "1.55",
    membersServed: "137",
    interestEarned: "0.13",
    name: "DERRY AREA",
    website: "http://www.dafcu.com",
  },
  "6208": {
    loansProduced: "2.42",
    membersServed: "468",
    interestEarned: "0.21",
    name: "OMAHA DOUGLAS",
    website: "http://www.omahadouglasfcu.org",
  },
  "6214": {
    loansProduced: "2.80",
    membersServed: "277",
    interestEarned: "0.24",
    name: "UNITED NORTHWEST",
    website: "http://WWW.UNWFCU.ORG",
  },
  "6225": {
    loansProduced: "1.49",
    membersServed: "318",
    interestEarned: "0.13",
    name: "STEUBEN CITIZENS",
    website: "http://steubencitizensfcu.com",
  },
  "6233": {
    loansProduced: "29.42",
    membersServed: "2,925",
    interestEarned: "2.50",
    name: "WEST-AIRCOMM",
    website: "http://www.westaircomm.com",
  },
  "6237": {
    loansProduced: "0.69",
    membersServed: "26",
    interestEarned: "0.06",
    name: "ALASKA DIST ENGINEERS",
    website: "http://www.adefcu.org",
  },
  "6240": {
    loansProduced: "0.29",
    membersServed: "22",
    interestEarned: "0.02",
    name: "B I",
    website: "http://www.bifcu.org",
  },
  "6242": {
    loansProduced: "0.29",
    membersServed: "40",
    interestEarned: "0.02",
    name: "VIRGINIA TRAILWAYS",
    website: null,
  },
  "6248": {
    loansProduced: "104.60",
    membersServed: "10,259",
    interestEarned: "8.89",
    name: "SKYONE",
    website: "http://www.skyone.org",
  },
  "6269": {
    loansProduced: "11.97",
    membersServed: "1,945",
    interestEarned: "1.02",
    name: "TRI COUNTY AREA",
    website: "http://www.tcafcu.org",
  },
  "6275": {
    loansProduced: "57.32",
    membersServed: "5,335",
    interestEarned: "4.87",
    name: "ALTANA",
    website: "http://www.altanafcu.org",
  },
  "6277": {
    loansProduced: "0.76",
    membersServed: "161",
    interestEarned: "0.06",
    name: "PHB EMPLOYEES",
    website: "http://phbfcu.com",
  },
  "6288": {
    loansProduced: "243.26",
    membersServed: "24,970",
    interestEarned: "20.68",
    name: "UNITED",
    website: "https://www.unitedfcu.com",
  },
  "6304": {
    loansProduced: "2.08",
    membersServed: "236",
    interestEarned: "0.18",
    name: "NEIMAN MARCUS GROUP EMPLOYEES",
    website: "http://www.neimanmarcusfcu.com",
  },
  "6311": {
    loansProduced: "0.92",
    membersServed: "123",
    interestEarned: "0.08",
    name: "TVH",
    website: "TVHFCU.ORG",
  },
  "6317": {
    loansProduced: "3.86",
    membersServed: "446",
    interestEarned: "0.33",
    name: "WICHITA FALLS",
    website: "wffedcu.com",
  },
  "6318": {
    loansProduced: "0.67",
    membersServed: "78",
    interestEarned: "0.06",
    name: "HOUSTON BELT & TERMINAL",
    website: "http://hbtfcu.com",
  },
  "6326": {
    loansProduced: "1.19",
    membersServed: "93",
    interestEarned: "0.10",
    name: "SAN MATEO CITY EMPLOYEES",
    website: "http://smcfcu.org",
  },
  "6328": {
    loansProduced: "490.02",
    membersServed: "46,436",
    interestEarned: "41.65",
    name: "NASA",
    website: "http://www.nasafcu.com",
  },
  "6332": {
    loansProduced: "14.21",
    membersServed: "1,647",
    interestEarned: "1.21",
    name: "TRUNORTH",
    website: "http://www.trunorthcu.org",
  },
  "6339": {
    loansProduced: "5.15",
    membersServed: "711",
    interestEarned: "0.44",
    name: "BATON ROUGE CITY PARISH EMP",
    website: "http://www.brcpefcu.org",
  },
  "6353": {
    loansProduced: "5.06",
    membersServed: "375",
    interestEarned: "0.43",
    name: "WASHINGTON COUNTY TEACHERS",
    website: "http://www.wctfcu.net",
  },
  "6368": {
    loansProduced: "1.89",
    membersServed: "204",
    interestEarned: "0.16",
    name: "WACO",
    website: "http://www.wacofcu.org",
  },
  "6369": {
    loansProduced: "23.22",
    membersServed: "3,650",
    interestEarned: "1.97",
    name: "STATEWIDE",
    website: "http://www.statewidefcu.org",
  },
  "6370": {
    loansProduced: "5.86",
    membersServed: "359",
    interestEarned: "0.50",
    name: "SECURTRUST",
    website: "http://www.securtrust.org",
  },
  "6418": {
    loansProduced: "0.71",
    membersServed: "66",
    interestEarned: "0.06",
    name: "C S P EMPLOYEES",
    website: "http://www.cspempfcu.com",
  },
  "6424": {
    loansProduced: "2.65",
    membersServed: "272",
    interestEarned: "0.23",
    name: "YUMA COUNTY",
    website: "www.ycfcu.com",
  },
  "6434": {
    loansProduced: "18.00",
    membersServed: "941",
    interestEarned: "1.53",
    name: "HERITAGE USA",
    website: "http://www.heritage-usa.net",
  },
  "6443": {
    loansProduced: "0.57",
    membersServed: "59",
    interestEarned: "0.05",
    name: "BAYONNE CITY EMPLOYEES",
    website: null,
  },
  "6464": {
    loansProduced: "6.74",
    membersServed: "1,590",
    interestEarned: "0.57",
    name: "PAHO/WHO",
    website: "http://www.pahofcu.org",
  },
  "6466": {
    loansProduced: "4.52",
    membersServed: "333",
    interestEarned: "0.38",
    name: "MIDWEST LIBERTY",
    website: "http://www.midwestlibertyfcu.org",
  },
  "6472": {
    loansProduced: "1.35",
    membersServed: "96",
    interestEarned: "0.11",
    name: "YOAKUM COUNTY",
    website: null,
  },
  "6498": {
    loansProduced: "2.44",
    membersServed: "275",
    interestEarned: "0.21",
    name: "HARTFORD FIREFIGHTERS",
    website: "http://www.myhffcu.org",
  },
  "6506": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "MT GILEAD",
    website: null,
  },
  "6527": {
    loansProduced: "5.60",
    membersServed: "676",
    interestEarned: "0.48",
    name: "BRIGHTVIEW",
    website: "http://www.brightviewcu.com",
  },
  "6574": {
    loansProduced: "55.33",
    membersServed: "6,254",
    interestEarned: "4.70",
    name: "MERCK SHARP & DOHME",
    website: "http://www.msdfcu.org",
  },
  "6584": {
    loansProduced: "5.01",
    membersServed: "644",
    interestEarned: "0.43",
    name: "LES",
    website: "http://www.lesfcu.com",
  },
  "6613": {
    loansProduced: "4.66",
    membersServed: "663",
    interestEarned: "0.40",
    name: "WALLED LAKE SCHOOLS",
    website: "http://www.wlsefcu.com",
  },
  "6626": {
    loansProduced: "6.79",
    membersServed: "1,275",
    interestEarned: "0.58",
    name: "HEALTHCARE FINANCIAL",
    website: "http://www.hcffcu.org",
  },
  "6627": {
    loansProduced: "7.85",
    membersServed: "556",
    interestEarned: "0.67",
    name: "SYRACUSE FIRE DEPARTMENT EMPLOYEES",
    website: "http://www.syrfirecu.com",
  },
  "6628": {
    loansProduced: "5.93",
    membersServed: "520",
    interestEarned: "0.50",
    name: "POLICE CREDIT UNION OF CONNECTICUT",
    website: "https://pcuct.org",
  },
  "6636": {
    loansProduced: "2.04",
    membersServed: "157",
    interestEarned: "0.17",
    name: "CAREY POVERELLO",
    website: "http://www.poverellofcu.com",
  },
  "6638": {
    loansProduced: "1.70",
    membersServed: "177",
    interestEarned: "0.14",
    name: "HARRISON POLICE & FIREMEN'S",
    website: "https://WWW.HPFFCU.ORG",
  },
  "6652": {
    loansProduced: "7.13",
    membersServed: "1,371",
    interestEarned: "0.61",
    name: "ATLANTIC FINANCIAL",
    website: "http://www.affcu.org",
  },
  "6665": {
    loansProduced: "12.52",
    membersServed: "2,748",
    interestEarned: "1.06",
    name: "FINANCIAL RESOURCES",
    website: "http://WWW.MYFINANCIALRESOURCES.ORG",
  },
  "6667": {
    loansProduced: "0.18",
    membersServed: "39",
    interestEarned: "0.02",
    name: "TSU",
    website: "https://www.tnstate.edu",
  },
  "6670": {
    loansProduced: "10.97",
    membersServed: "2,974",
    interestEarned: "0.93",
    name: "CONNECTS",
    website: "http://www.connectsfcu.org",
  },
  "6672": {
    loansProduced: "0.13",
    membersServed: "12",
    interestEarned: "0.01",
    name: "U S EMPLOYEES",
    website: null,
  },
  "6674": {
    loansProduced: "4.98",
    membersServed: "392",
    interestEarned: "0.42",
    name: "HOME",
    website: "https://www.homefcu.org",
  },
  "6679": {
    loansProduced: "22.81",
    membersServed: "1,529",
    interestEarned: "1.94",
    name: "ACT 1ST",
    website: "https://www.act1stfcu.org",
  },
  "6680": {
    loansProduced: "23.44",
    membersServed: "3,851",
    interestEarned: "1.99",
    name: "AOD",
    website: "http://www.aodfcu.com",
  },
  "6681": {
    loansProduced: "23.96",
    membersServed: "2,688",
    interestEarned: "2.04",
    name: "CALTECH EMPLOYEES",
    website: "http://www.cefcu.org",
  },
  "6685": {
    loansProduced: "62.26",
    membersServed: "3,908",
    interestEarned: "5.29",
    name: "WEPCO",
    website: "http://www.wepcofcu.com",
  },
  "6687": {
    loansProduced: "2.26",
    membersServed: "157",
    interestEarned: "0.19",
    name: "T & P LONGVIEW",
    website: null,
  },
  "6689": {
    loansProduced: "1.04",
    membersServed: "113",
    interestEarned: "0.09",
    name: "LATROBE",
    website: "www.latrobefcu.com",
  },
  "6690": {
    loansProduced: "2.64",
    membersServed: "423",
    interestEarned: "0.22",
    name: "TRI-VALLEY SERVICE",
    website: "http://www.trivalleyservice.com",
  },
  "6694": {
    loansProduced: "985.19",
    membersServed: "82,879",
    interestEarned: "83.74",
    name: "MEMBERS 1ST",
    website: "http://members1st.org",
  },
  "6700": {
    loansProduced: "1.48",
    membersServed: "211",
    interestEarned: "0.13",
    name: "JERSEY CITY FIREMEN",
    website: null,
  },
  "6711": {
    loansProduced: "2.71",
    membersServed: "368",
    interestEarned: "0.23",
    name: "SANTA BARBARA COUNTY",
    website: "http://www.sbcreditunion.com",
  },
  "6722": {
    loansProduced: "8.78",
    membersServed: "1,205",
    interestEarned: "0.75",
    name: "GROVE CITY AREA",
    website: "http://www.gcafcu.org",
  },
  "6723": {
    loansProduced: "2.56",
    membersServed: "437",
    interestEarned: "0.22",
    name: "FRANKLIN JOHNSTOWN",
    website: "http://www.franklinjohnstown.com",
  },
  "6725": {
    loansProduced: "2.34",
    membersServed: "151",
    interestEarned: "0.20",
    name: "L R F D",
    website: "http://lrfdfcu.virtualcu.net",
  },
  "6726": {
    loansProduced: "1.94",
    membersServed: "138",
    interestEarned: "0.16",
    name: "TEMPLE-INLAND",
    website: "http://www.t-ifcu.com",
  },
  "6733": {
    loansProduced: "5.93",
    membersServed: "813",
    interestEarned: "0.50",
    name: "CENCAP",
    website: "http://WWW.CENCAP.COM",
  },
  "6739": {
    loansProduced: "1.71",
    membersServed: "413",
    interestEarned: "0.15",
    name: "MID-STATE",
    website: "http://www.midstatefcu.org",
  },
  "6743": {
    loansProduced: "83.05",
    membersServed: "7,939",
    interestEarned: "7.06",
    name: "ABILENE TEACHERS",
    website: "http://abileneteachersfcu.org",
  },
  "6747": {
    loansProduced: "6.62",
    membersServed: "785",
    interestEarned: "0.56",
    name: "DYNAMIC",
    website: "http://DYNAMICFCU.COM",
  },
  "6772": {
    loansProduced: "1.95",
    membersServed: "152",
    interestEarned: "0.17",
    name: "SORG BAY WEST",
    website: "http://www.sorgbaywest.com",
  },
  "6774": {
    loansProduced: "36.18",
    membersServed: "3,767",
    interestEarned: "3.08",
    name: "COOSA PINES",
    website: "http://www.coosapinesfcu.org",
  },
  "6775": {
    loansProduced: "163.48",
    membersServed: "15,740",
    interestEarned: "13.90",
    name: "Y-12",
    website: "http://www.y12fcu.org",
  },
  "6778": {
    loansProduced: "57.37",
    membersServed: "5,041",
    interestEarned: "4.88",
    name: "ENRICHMENT",
    website: "http://www.enrichmentfcu.org",
  },
  "6785": {
    loansProduced: "6.00",
    membersServed: "899",
    interestEarned: "0.51",
    name: "LESCO",
    website: "http://www.lescofcu.com",
  },
  "6832": {
    loansProduced: "22.99",
    membersServed: "2,348",
    interestEarned: "1.95",
    name: "GERBER",
    website: "http://Gerberfcu.Com",
  },
  "6844": {
    loansProduced: "8.02",
    membersServed: "951",
    interestEarned: "0.68",
    name: "TRAVERSE CATHOLIC",
    website: "http://www.tcfedcu.org",
  },
  "6853": {
    loansProduced: "29.48",
    membersServed: "2,109",
    interestEarned: "2.51",
    name: "GREAT PLAINS",
    website: "http://www.greatplainsfcu.com",
  },
  "6859": {
    loansProduced: "6.67",
    membersServed: "439",
    interestEarned: "0.57",
    name: "ARTESIAN",
    website: "http://www.artesianfcu.org",
  },
  "6870": {
    loansProduced: "11.99",
    membersServed: "1,377",
    interestEarned: "1.02",
    name: "FINANCIAL TRUST",
    website: "http://www.financialtrustfederalcre",
  },
  "6871": {
    loansProduced: "2.51",
    membersServed: "233",
    interestEarned: "0.21",
    name: "CHIROPRACTIC",
    website: "http://www.chirofcu.org",
  },
  "6878": {
    loansProduced: "7.49",
    membersServed: "1,302",
    interestEarned: "0.64",
    name: "RVA FINANCIAL",
    website: "http://www.rvafinancial.com",
  },
  "6885": {
    loansProduced: "67.80",
    membersServed: "7,407",
    interestEarned: "5.76",
    name: "PEOPLE FIRST",
    website: "https//www.peoplefirst.com",
  },
  "6898": {
    loansProduced: "2.63",
    membersServed: "281",
    interestEarned: "0.22",
    name: "ACADIAN",
    website: "http://www.acadianfcu.org",
  },
  "6918": {
    loansProduced: "32.65",
    membersServed: "2,813",
    interestEarned: "2.78",
    name: "VAPR",
    website: "http://www.vaprfcu.com",
  },
  "6920": {
    loansProduced: "3.57",
    membersServed: "290",
    interestEarned: "0.30",
    name: "NEPHI WESTERN EMPLOYEES",
    website: "http://nephicreditunion.com",
  },
  "6930": {
    loansProduced: "1.45",
    membersServed: "154",
    interestEarned: "0.12",
    name: "CADETS",
    website: "http://cfcu@cadetsfcu.com",
  },
  "6936": {
    loansProduced: "2.26",
    membersServed: "494",
    interestEarned: "0.19",
    name: "CENTRAL MAINE",
    website: "http://www.centralmainecu.com",
  },
  "6944": {
    loansProduced: "0.68",
    membersServed: "116",
    interestEarned: "0.06",
    name: "JERSEY CITY POLICE",
    website: null,
  },
  "6949": {
    loansProduced: "1.49",
    membersServed: "166",
    interestEarned: "0.13",
    name: "NORTHWESTERN",
    website: "http://northwesternfcu.com",
  },
  "6958": {
    loansProduced: "0.71",
    membersServed: "52",
    interestEarned: "0.06",
    name: "PORT WASHINGTON TEACHERS",
    website: "http://www.pwtfcu.org",
  },
  "6974": {
    loansProduced: "6.73",
    membersServed: "608",
    interestEarned: "0.57",
    name: "CONSUMERS COOPERATIVE",
    website: "www.ccfcuonline.com",
  },
  "6986": {
    loansProduced: "15.37",
    membersServed: "1,253",
    interestEarned: "1.31",
    name: "NORTHERN HILLS",
    website: "http://www.northernhillsfcu.org",
  },
  "6992": {
    loansProduced: "1.19",
    membersServed: "182",
    interestEarned: "0.10",
    name: "COTEAU VALLEY",
    website: "http://coteauvalleyfcu.com",
  },
  "7000": {
    loansProduced: "232.52",
    membersServed: "27,660",
    interestEarned: "19.76",
    name: "ABOUND",
    website: "http://www.Aboundcu.com",
  },
  "7002": {
    loansProduced: "4.59",
    membersServed: "714",
    interestEarned: "0.39",
    name: "CROSSROADS FINANCIAL",
    website: "http://www.crossroadsfcu.org",
  },
  "7020": {
    loansProduced: "2.51",
    membersServed: "215",
    interestEarned: "0.21",
    name: "M O",
    website: "http://m-ofcu.com",
  },
  "7021": {
    loansProduced: "1.16",
    membersServed: "167",
    interestEarned: "0.10",
    name: "DACOTAH",
    website: "http://www.dacotahfcu.com",
  },
  "7022": {
    loansProduced: "0.73",
    membersServed: "83",
    interestEarned: "0.06",
    name: "SPRINGFIELD POSTAL EMPLOYEES",
    website: null,
  },
  "7023": {
    loansProduced: "3.48",
    membersServed: "425",
    interestEarned: "0.30",
    name: "PORT ARTHUR COMMUNITY",
    website: "http//www.pac-fcu.com",
  },
  "7024": {
    loansProduced: "0.98",
    membersServed: "109",
    interestEarned: "0.08",
    name: "SOUTH TEXAS REGIONAL",
    website: "http://www.strfcu.com",
  },
  "7027": {
    loansProduced: "1.96",
    membersServed: "128",
    interestEarned: "0.17",
    name: "INTERNATIONALITES",
    website: "http://Internationalites.com",
  },
  "7039": {
    loansProduced: "3.94",
    membersServed: "236",
    interestEarned: "0.33",
    name: "MONROE COUNTY TEACHERS",
    website: "http://monroecountyteachersfcu.org",
  },
  "7045": {
    loansProduced: "0.19",
    membersServed: "33",
    interestEarned: "0.02",
    name: "JUDICIAL & JUSTICE",
    website: "http://jjfcu.com",
  },
  "7063": {
    loansProduced: "36.76",
    membersServed: "2,877",
    interestEarned: "3.12",
    name: "GREAT BASIN",
    website: "http://www.greatbasin.org",
  },
  "7064": {
    loansProduced: "27.39",
    membersServed: "5,576",
    interestEarned: "2.33",
    name: "SUN",
    website: "http://www.sunfederalcu.org",
  },
  "7066": {
    loansProduced: "4.25",
    membersServed: "499",
    interestEarned: "0.36",
    name: "EASTERN INDIANA",
    website: "http://www.easterninfcu.com",
  },
  "7067": {
    loansProduced: "3.62",
    membersServed: "405",
    interestEarned: "0.31",
    name: "FT RANDALL",
    website: "http://www.ftrandallfcu.com",
  },
  "7069": {
    loansProduced: "2.10",
    membersServed: "153",
    interestEarned: "0.18",
    name: "BLOOMFIELD FIRE AND POLICE",
    website: "http://bfpfcu.com",
  },
  "7072": {
    loansProduced: "4.47",
    membersServed: "236",
    interestEarned: "0.38",
    name: "ASHLAND COMMUNITY",
    website: "http://www.ashlandfcu.org",
  },
  "7088": {
    loansProduced: "3.33",
    membersServed: "646",
    interestEarned: "0.28",
    name: "CINCINNATI INTERAGENCY",
    website: "http://www.cifcu.org",
  },
  "7091": {
    loansProduced: "50.18",
    membersServed: "8,384",
    interestEarned: "4.27",
    name: "METRO CU",
    website: "https://www.metrofcu.org",
  },
  "7092": {
    loansProduced: "0.50",
    membersServed: "89",
    interestEarned: "0.04",
    name: "GALVESTON SCHOOL EMPLOYEES",
    website: "https://gsefcu-dn.financial-net.com",
  },
  "7101": {
    loansProduced: "8.24",
    membersServed: "940",
    interestEarned: "0.70",
    name: "CHENEY",
    website: "http://www.cheneyfcu.com",
  },
  "7118": {
    loansProduced: "2.24",
    membersServed: "141",
    interestEarned: "0.19",
    name: "DUNLOP EMPLOYEES",
    website: null,
  },
  "7126": {
    loansProduced: "0.13",
    membersServed: "11",
    interestEarned: "0.01",
    name: "ANN ARBOR POSTAL",
    website: null,
  },
  "7129": {
    loansProduced: "26.63",
    membersServed: "1,990",
    interestEarned: "2.26",
    name: "CHEMCEL",
    website: "http://www.chemcel.org",
  },
  "7149": {
    loansProduced: "1.60",
    membersServed: "240",
    interestEarned: "0.14",
    name: "BISON",
    website: "http://bisonfcu.com",
  },
  "7151": {
    loansProduced: "28.80",
    membersServed: "1,766",
    interestEarned: "2.45",
    name: "OKLAHOMA",
    website: "http://www.okfcu.com",
  },
  "7154": {
    loansProduced: "4.36",
    membersServed: "484",
    interestEarned: "0.37",
    name: "OK MEMBERS FIRST",
    website: "http://www.omffcu.org",
  },
  "7162": {
    loansProduced: "1.94",
    membersServed: "154",
    interestEarned: "0.17",
    name: "PEARL DISTRICT",
    website: "lcurtispdfcu@att.net",
  },
  "7172": {
    loansProduced: "0.02",
    membersServed: "5",
    interestEarned: "0.00",
    name: "ST. PHILIP'S CHURCH",
    website: null,
  },
  "7182": {
    loansProduced: "4.68",
    membersServed: "410",
    interestEarned: "0.40",
    name: "SUMMIT",
    website: "https://www.summitfcu.com",
  },
  "7184": {
    loansProduced: "15.04",
    membersServed: "3,488",
    interestEarned: "1.28",
    name: "LIBERTY SAVINGS",
    website: "https://www.lsfcu.org/",
  },
  "7210": {
    loansProduced: "0.98",
    membersServed: "81",
    interestEarned: "0.08",
    name: "HEMINGFORD COMMUNITY",
    website: "http://hemingfordcreditunion.com",
  },
  "7217": {
    loansProduced: "0.64",
    membersServed: "746",
    interestEarned: "0.05",
    name: "SELF RELIANCE NY",
    website: "http://www.selfrelianceny.org",
  },
  "7219": {
    loansProduced: "6.22",
    membersServed: "776",
    interestEarned: "0.53",
    name: "ST. FRANCIS X",
    website: "http://www.stfranciscu.org",
  },
  "7238": {
    loansProduced: "83.73",
    membersServed: "13,440",
    interestEarned: "7.12",
    name: "PHILADELPHIA",
    website: "http://www.pfcu.com",
  },
  "7244": {
    loansProduced: "24.62",
    membersServed: "1,730",
    interestEarned: "2.09",
    name: "UNITED BUSINESS & INDUSTRY",
    website: "ubifcu.com",
  },
  "7253": {
    loansProduced: "0.73",
    membersServed: "158",
    interestEarned: "0.06",
    name: "EAST BATON ROUGE TEACHERS",
    website: "http://www.ebrteachersfcu.org",
  },
  "7256": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "COMMUNITY",
    website: null,
  },
  "7264": {
    loansProduced: "11.03",
    membersServed: "1,364",
    interestEarned: "0.94",
    name: "MONEY ONE",
    website: "http://www.moneyonefcu.org",
  },
  "7289": {
    loansProduced: "1.89",
    membersServed: "189",
    interestEarned: "0.16",
    name: "THUNDERBOLT AREA",
    website: "http://tbafcu.com",
  },
  "7297": {
    loansProduced: "20.10",
    membersServed: "2,140",
    interestEarned: "1.71",
    name: "PHEPLE",
    website: "http://www.pheplefcu.org",
  },
  "7301": {
    loansProduced: "0.01",
    membersServed: "11",
    interestEarned: "0.00",
    name: "UNION",
    website: null,
  },
  "7307": {
    loansProduced: "1.30",
    membersServed: "253",
    interestEarned: "0.11",
    name: "CHEYENNE-LARAMIE COUNTY EMPLOYEES",
    website: "www.clcefcu.org",
  },
  "7316": {
    loansProduced: "60.34",
    membersServed: "9,857",
    interestEarned: "5.13",
    name: "PIMA",
    website: "http://www.pimafederal.org",
  },
  "7331": {
    loansProduced: "2.71",
    membersServed: "377",
    interestEarned: "0.23",
    name: "LOUISIANA CATHOLIC",
    website: "http://www.louisianacatholicfcu.com",
  },
  "7345": {
    loansProduced: "142.04",
    membersServed: "10,296",
    interestEarned: "12.07",
    name: "CARIBE",
    website: "www.caribefederal.com",
  },
  "7346": {
    loansProduced: "6.48",
    membersServed: "2,202",
    interestEarned: "0.55",
    name: "SELFRELIANCE",
    website: "http://www.selfreliance.com",
  },
  "7361": {
    loansProduced: "211.82",
    membersServed: "20,199",
    interestEarned: "18.00",
    name: "BLUE",
    website: "https://www.bluefcu.com",
  },
  "7376": {
    loansProduced: "1.79",
    membersServed: "316",
    interestEarned: "0.15",
    name: "FLEUR-DE-LIS",
    website: "http://www.fdlfcu.com",
  },
  "7383": {
    loansProduced: "3.50",
    membersServed: "352",
    interestEarned: "0.30",
    name: "FAMILY 1ST",
    website: "http://www.family1stfcu.com",
  },
  "7392": {
    loansProduced: "3.37",
    membersServed: "304",
    interestEarned: "0.29",
    name: "FORTRESS",
    website: "http://fortressfcu.org",
  },
  "7397": {
    loansProduced: "423.56",
    membersServed: "37,791",
    interestEarned: "36.00",
    name: "ASCEND",
    website: "https://ascend.org",
  },
  "7405": {
    loansProduced: "3.89",
    membersServed: "254",
    interestEarned: "0.33",
    name: "SEG",
    website: "http://www.segfcu.org",
  },
  "7415": {
    loansProduced: "10.93",
    membersServed: "854",
    interestEarned: "0.93",
    name: "UNITED COMMUNITY",
    website: "http://www.unitedcommunityfcu.org",
  },
  "7423": {
    loansProduced: "1.54",
    membersServed: "81",
    interestEarned: "0.13",
    name: "SCHOFIELD",
    website: "http://www.schofieldfcu.org",
  },
  "7428": {
    loansProduced: "9.60",
    membersServed: "1,605",
    interestEarned: "0.82",
    name: "STRAITS AREA",
    website: "http://www.mi-safcu.org",
  },
  "7448": {
    loansProduced: "98.94",
    membersServed: "10,535",
    interestEarned: "8.41",
    name: "1ST ADVANTAGE",
    website: "http://www.1stadvantage.org",
  },
  "7465": {
    loansProduced: "17.76",
    membersServed: "2,148",
    interestEarned: "1.51",
    name: "AWAKON",
    website: "http://www.awakonfcu.net",
  },
  "7471": {
    loansProduced: "2.59",
    membersServed: "1,658",
    interestEarned: "0.22",
    name: "MOLOKAI COMMUNITY",
    website: "http://www.molokaicommunityfcu.com",
  },
  "7473": {
    loansProduced: "0.97",
    membersServed: "84",
    interestEarned: "0.08",
    name: "ALEXANDRIA T & P",
    website: null,
  },
  "7482": {
    loansProduced: "4.97",
    membersServed: "583",
    interestEarned: "0.42",
    name: "TRANSTAR",
    website: "http://www.transtarfcu.org",
  },
  "7504": {
    loansProduced: "0.27",
    membersServed: "23",
    interestEarned: "0.02",
    name: "CONCORD",
    website: "https://concordfcu.org",
  },
  "7509": {
    loansProduced: "3.50",
    membersServed: "553",
    interestEarned: "0.30",
    name: "MUSKEGON",
    website: "http://WWW.MUSKFEDCU.COM",
  },
  "7520": {
    loansProduced: "3.88",
    membersServed: "1,301",
    interestEarned: "0.33",
    name: "GULF COAST",
    website: "http://www.gulfcoastfcu.org",
  },
  "7524": {
    loansProduced: "21.91",
    membersServed: "1,559",
    interestEarned: "1.86",
    name: "C-PLANT",
    website: "http://www.cplant.com",
  },
  "7531": {
    loansProduced: "427.10",
    membersServed: "32,974",
    interestEarned: "36.30",
    name: "HUGHES",
    website: "http://www.hughesfcu.org",
  },
  "7532": {
    loansProduced: "2.77",
    membersServed: "492",
    interestEarned: "0.24",
    name: "TUSCALOOSA V A",
    website: "http://www.tvacu.com",
  },
  "7534": {
    loansProduced: "1.78",
    membersServed: "183",
    interestEarned: "0.15",
    name: "HIALEAH MUNICIPAL EMPLOYEES",
    website: "http://www.hialeahcu.org",
  },
  "7544": {
    loansProduced: "519.02",
    membersServed: "64,504",
    interestEarned: "44.12",
    name: "REDSTONE",
    website: "https://www.redfcu.org",
  },
  "7546": {
    loansProduced: "2.04",
    membersServed: "204",
    interestEarned: "0.17",
    name: "RIDGELINE",
    website: "http://www.ridgelinecu.com",
  },
  "7552": {
    loansProduced: "25.12",
    membersServed: "3,479",
    interestEarned: "2.14",
    name: "HARRIS COUNTY",
    website: "http://www.hcfcu.com",
  },
  "7557": {
    loansProduced: "10.58",
    membersServed: "939",
    interestEarned: "0.90",
    name: "GLENDALE",
    website: "http://www.glendalefcu.org",
  },
  "7573": {
    loansProduced: "29.97",
    membersServed: "4,990",
    interestEarned: "2.55",
    name: "ARLINGTON COMMUNITY",
    website: "http://www.arlingtoncu.org",
  },
  "7576": {
    loansProduced: "4.53",
    membersServed: "486",
    interestEarned: "0.38",
    name: "CREIGHTON",
    website: "http://www.creightonfederal.org",
  },
  "7590": {
    loansProduced: "117.09",
    membersServed: "7,958",
    interestEarned: "9.95",
    name: "GREATER TEXAS",
    website: "http://www.gtfcu.org",
  },
  "7599": {
    loansProduced: "1.10",
    membersServed: "95",
    interestEarned: "0.09",
    name: "HURON AREA EDUCATION",
    website: "http://huronareaeducationfcu.com",
  },
  "7608": {
    loansProduced: "28.91",
    membersServed: "4,478",
    interestEarned: "2.46",
    name: "CBC",
    website: "http://www.cbcfcu.org",
  },
  "7610": {
    loansProduced: "4.72",
    membersServed: "404",
    interestEarned: "0.40",
    name: "SUNFLOWER COMMUNITY",
    website: "http://www.sunflowerrocks.com",
  },
  "7628": {
    loansProduced: "3.35",
    membersServed: "827",
    interestEarned: "0.28",
    name: "SOUTHEAST MICHIGAN STATE EMPLOYEES",
    website: "http://www.smsefcu.com",
  },
  "7633": {
    loansProduced: "1.52",
    membersServed: "159",
    interestEarned: "0.13",
    name: "WARREN MUNICIPAL",
    website: "http://warrenmunicipalfcu.com",
  },
  "7641": {
    loansProduced: "11.69",
    membersServed: "1,351",
    interestEarned: "0.99",
    name: "OZARK",
    website: "http://www.ozarkfcu.com",
  },
  "7644": {
    loansProduced: "4.09",
    membersServed: "404",
    interestEarned: "0.35",
    name: "GOGEBIC COUNTY",
    website: "http://www.gogebicfcu.com",
  },
  "7652": {
    loansProduced: "3.19",
    membersServed: "469",
    interestEarned: "0.27",
    name: "WESTPORT",
    website: "http://www.westport.org",
  },
  "7656": {
    loansProduced: "1.26",
    membersServed: "111",
    interestEarned: "0.11",
    name: "OLEAN TEACHERS' AND POSTAL",
    website: "http://otpfcu.com",
  },
  "7662": {
    loansProduced: "2.43",
    membersServed: "248",
    interestEarned: "0.21",
    name: "GARDEN CITY TEACHERS",
    website: "http://gctcu.org",
  },
  "7672": {
    loansProduced: "3.59",
    membersServed: "480",
    interestEarned: "0.31",
    name: "BARAGA COUNTY",
    website: "http://baragafcu.com",
  },
  "7674": {
    loansProduced: "7.37",
    membersServed: "757",
    interestEarned: "0.63",
    name: "YOUR LEGACY",
    website: "http://www.yourlegacyfcu.com",
  },
  "7677": {
    loansProduced: "0.35",
    membersServed: "51",
    interestEarned: "0.03",
    name: "W T N M ATLANTIC",
    website: null,
  },
  "7684": {
    loansProduced: "0.10",
    membersServed: "19",
    interestEarned: "0.01",
    name: "JPFCE",
    website: null,
  },
  "7688": {
    loansProduced: "101.51",
    membersServed: "7,797",
    interestEarned: "8.63",
    name: "PARTNERS 1ST",
    website: "www.partners1stcu.org",
  },
  "7700": {
    loansProduced: "0.41",
    membersServed: "41",
    interestEarned: "0.04",
    name: "ARKANSAS AM & N COLLEGE",
    website: "http://www.uapbfcu.virtualcu.net",
  },
  "7715": {
    loansProduced: "2.35",
    membersServed: "254",
    interestEarned: "0.20",
    name: "EM",
    website: "http://emfederalcreditunion.org",
  },
  "7716": {
    loansProduced: "1.34",
    membersServed: "109",
    interestEarned: "0.11",
    name: "GLENDIVE BN",
    website: "http://WWW.BNFCU.ORG",
  },
  "7717": {
    loansProduced: "10.24",
    membersServed: "3,410",
    interestEarned: "0.87",
    name: "INNER LAKES",
    website: "http://www.innerlakesfcu.org",
  },
  "7722": {
    loansProduced: "30.65",
    membersServed: "2,702",
    interestEarned: "2.61",
    name: "HOMEBASE",
    website: "https://www.homebasecu.org",
  },
  "7723": {
    loansProduced: "13.74",
    membersServed: "3,143",
    interestEarned: "1.17",
    name: "360",
    website: "https://www.360FCU.ORG",
  },
  "7724": {
    loansProduced: "3.04",
    membersServed: "241",
    interestEarned: "0.26",
    name: "JESSOP COMMUNITY",
    website: "http://www.jessopfcu.org",
  },
  "7731": {
    loansProduced: "1.46",
    membersServed: "211",
    interestEarned: "0.12",
    name: "METHODIST HOSPITAL EMPLOYEES",
    website: "http://www.mhcu.net",
  },
  "7748": {
    loansProduced: "19.15",
    membersServed: "2,034",
    interestEarned: "1.63",
    name: "CASCADE",
    website: "http://www.cascadefcu.org",
  },
  "7770": {
    loansProduced: "14.52",
    membersServed: "3,798",
    interestEarned: "1.23",
    name: "THINKWISE",
    website: "http://www.thinkwisecu.org",
  },
  "7772": {
    loansProduced: "3.41",
    membersServed: "470",
    interestEarned: "0.29",
    name: "SMART",
    website: "http://www.smartfedcu.com",
  },
  "7776": {
    loansProduced: "26.24",
    membersServed: "4,724",
    interestEarned: "2.23",
    name: "LOOKOUT",
    website: "http://www.lookoutcu.com",
  },
  "7790": {
    loansProduced: "4.21",
    membersServed: "301",
    interestEarned: "0.36",
    name: "RALEIGH COUNTY",
    website: "http://www.raleighcountyfcu.com",
  },
  "7817": {
    loansProduced: "9.38",
    membersServed: "589",
    interestEarned: "0.80",
    name: "SEA AIR",
    website: "http://www.seaairfcu.org",
  },
  "7821": {
    loansProduced: "5.01",
    membersServed: "739",
    interestEarned: "0.43",
    name: "WEST BRANCH VALLEY",
    website: "https://www.wbvfcu.org",
  },
  "7822": {
    loansProduced: "13.37",
    membersServed: "1,311",
    interestEarned: "1.14",
    name: "CANNON",
    website: "http://www.cannonfcu.org",
  },
  "7825": {
    loansProduced: "10.04",
    membersServed: "1,322",
    interestEarned: "0.85",
    name: "EXCEL",
    website: "http://WWW.EXCELFCU.ORG",
  },
  "7835": {
    loansProduced: "115.13",
    membersServed: "11,399",
    interestEarned: "9.79",
    name: "SCE",
    website: "http://www.scefcu.org",
  },
  "7840": {
    loansProduced: "571.44",
    membersServed: "51,408",
    interestEarned: "48.57",
    name: "TRULIANT",
    website: "http://WWW.TRULIANTFCU.ORG",
  },
  "7842": {
    loansProduced: "2.28",
    membersServed: "222",
    interestEarned: "0.19",
    name: "NORWIN TEACHERS",
    website: "http://www.norwinteachersfcu.org",
  },
  "7844": {
    loansProduced: "0.38",
    membersServed: "66",
    interestEarned: "0.03",
    name: "FISHER SCIENTIFIC EMPLOYEES",
    website: "http://www.fishercreditunion.org",
  },
  "7846": {
    loansProduced: "3.17",
    membersServed: "317",
    interestEarned: "0.27",
    name: "SOLUTIONS",
    website: "http://solutionscu.org",
  },
  "7870": {
    loansProduced: "14.28",
    membersServed: "1,248",
    interestEarned: "1.21",
    name: "FIRST FLIGHT",
    website: "http://www.efirstflight.com",
  },
  "7873": {
    loansProduced: "10.94",
    membersServed: "1,166",
    interestEarned: "0.93",
    name: "MARQUETTE COMMUNITY",
    website: "http://www.marquettecomm.org",
  },
  "7875": {
    loansProduced: "9.55",
    membersServed: "1,059",
    interestEarned: "0.81",
    name: "UKRAINIAN SELFRELIANCE",
    website: "http://www.ukrfcu.com",
  },
  "7877": {
    loansProduced: "0.06",
    membersServed: "11",
    interestEarned: "0.01",
    name: "FOURTH WARD",
    website: null,
  },
  "7907": {
    loansProduced: "2.99",
    membersServed: "145",
    interestEarned: "0.25",
    name: "RENO CITY EMPLOYEES",
    website: "www.rcefcu.com",
  },
  "7910": {
    loansProduced: "0.84",
    membersServed: "135",
    interestEarned: "0.07",
    name: "WEST MICHIGAN POSTAL SERVICE",
    website: "http://www.wmpsfcu.com",
  },
  "7916": {
    loansProduced: "115.03",
    membersServed: "13,615",
    interestEarned: "9.78",
    name: "NECHES",
    website: "http://www.nechesfcu.org",
  },
  "7925": {
    loansProduced: "13.48",
    membersServed: "1,233",
    interestEarned: "1.15",
    name: "GREAT ERIE",
    website: "www.greateriefcu.com",
  },
  "7927": {
    loansProduced: "2.54",
    membersServed: "105",
    interestEarned: "0.22",
    name: "LIVE LIFE",
    website: "http://livelifefcu.org",
  },
  "7955": {
    loansProduced: "10.76",
    membersServed: "3,173",
    interestEarned: "0.91",
    name: "BRAGG MUTUAL",
    website: "http://www.braggmutual.org",
  },
  "7958": {
    loansProduced: "1.63",
    membersServed: "257",
    interestEarned: "0.14",
    name: "LAKEHURST NAVAL",
    website: "http://www.LNFCU.net",
  },
  "7960": {
    loansProduced: "40.91",
    membersServed: "2,399",
    interestEarned: "3.48",
    name: "WHITE SANDS",
    website: "http://www.wsfcu.org",
  },
  "7970": {
    loansProduced: "10.19",
    membersServed: "1,220",
    interestEarned: "0.87",
    name: "ST. THOMAS",
    website: "https://stthomasfcu.com/",
  },
  "7989": {
    loansProduced: "2.49",
    membersServed: "179",
    interestEarned: "0.21",
    name: "CHRISTIANSTED",
    website: "http://www.cfcuvi.org",
  },
  "7992": {
    loansProduced: "4.36",
    membersServed: "361",
    interestEarned: "0.37",
    name: "AFL CIO EMPLOYEES",
    website: "http://www.aflcioefcu.org",
  },
  "7999": {
    loansProduced: "0.82",
    membersServed: "98",
    interestEarned: "0.07",
    name: "TELCO ROSWELL NEW MEXICO",
    website: "http://www.telcoroswell.org",
  },
  "8000": {
    loansProduced: "1.17",
    membersServed: "285",
    interestEarned: "0.10",
    name: "UKRAINIAN SELFRELIANCE MICHIGAN",
    website: "https://www.usmfcu.org",
  },
  "8001": {
    loansProduced: "0.49",
    membersServed: "52",
    interestEarned: "0.04",
    name: "MVP POSTAL AND PRINTING",
    website: "http://www.mvpfcu.org",
  },
  "8006": {
    loansProduced: "1.13",
    membersServed: "112",
    interestEarned: "0.10",
    name: "STATE AGENCIES",
    website: "http://WWW.STATEAGENCIESFCU.COM",
  },
  "8037": {
    loansProduced: "1.12",
    membersServed: "88",
    interestEarned: "0.10",
    name: "FORT PECK COMMUNITY",
    website: "http://www.fortpeckcu.com",
  },
  "8039": {
    loansProduced: "3.94",
    membersServed: "462",
    interestEarned: "0.33",
    name: "DAKOTA STAR",
    website: "http://www.dakotastar.org",
  },
  "8052": {
    loansProduced: "1.09",
    membersServed: "235",
    interestEarned: "0.09",
    name: "MISSISSIPPI DHS",
    website: "http://www.msdhsfcu.com",
  },
  "8059": {
    loansProduced: "13.56",
    membersServed: "1,108",
    interestEarned: "1.15",
    name: "LATITUDE 32",
    website: "http://www.latitude32.org",
  },
  "8069": {
    loansProduced: "1.98",
    membersServed: "197",
    interestEarned: "0.17",
    name: "FREDERIKSTED",
    website: "http://www.ffcuvi.org",
  },
  "8072": {
    loansProduced: "0.14",
    membersServed: "9",
    interestEarned: "0.01",
    name: "CHRIST THE KING PARISH",
    website: null,
  },
  "8074": {
    loansProduced: "0.87",
    membersServed: "62",
    interestEarned: "0.07",
    name: "RAPIDES GENERAL HOSPITAL EMPL",
    website: "http://WWW.RGHEFCU.COM",
  },
  "8077": {
    loansProduced: "2.94",
    membersServed: "314",
    interestEarned: "0.25",
    name: "NORTHWEST CONSUMERS",
    website: "http://www.nwconsumers.org",
  },
  "8080": {
    loansProduced: "35.52",
    membersServed: "3,111",
    interestEarned: "3.02",
    name: "MAC",
    website: "http://www.macfcu.org",
  },
  "8097": {
    loansProduced: "0.18",
    membersServed: "16",
    interestEarned: "0.02",
    name: "TRICOUNTY",
    website: null,
  },
  "8100": {
    loansProduced: "7.75",
    membersServed: "524",
    interestEarned: "0.66",
    name: "ALASKA AIR GROUP",
    website: "http://www.aagcu.org",
  },
  "8110": {
    loansProduced: "34.25",
    membersServed: "3,472",
    interestEarned: "2.91",
    name: "NEW CUMBERLAND",
    website: "http://www.ncfcuonline.org",
  },
  "8111": {
    loansProduced: "1,370.06",
    membersServed: "174,733",
    interestEarned: "116.46",
    name: "RANDOLPH-BROOKS",
    website: "http://www.rbfcu.org",
  },
  "8113": {
    loansProduced: "6.18",
    membersServed: "1,043",
    interestEarned: "0.53",
    name: "WESTEDGE",
    website: "http://www.westedgecu.org",
  },
  "8116": {
    loansProduced: "456.77",
    membersServed: "39,263",
    interestEarned: "38.83",
    name: "TEACHERS",
    website: "http://www.teachersfcu.org",
  },
  "8118": {
    loansProduced: "2.05",
    membersServed: "161",
    interestEarned: "0.17",
    name: "PATH",
    website: "https://www.pathfcu.org",
  },
  "8119": {
    loansProduced: "3.19",
    membersServed: "344",
    interestEarned: "0.27",
    name: "KENOWA COMMUNITY",
    website: "http://www.kenowacu.com",
  },
  "8129": {
    loansProduced: "3.11",
    membersServed: "447",
    interestEarned: "0.26",
    name: "MOUNTAIN WEST",
    website: "http://mountainwestfcu.com",
  },
  "8132": {
    loansProduced: "7.75",
    membersServed: "801",
    interestEarned: "0.66",
    name: "SAFEWAY",
    website: "http://safewayfcu.com",
  },
  "8141": {
    loansProduced: "1.81",
    membersServed: "165",
    interestEarned: "0.15",
    name: "MUSKEGON ST JOSEPH",
    website: null,
  },
  "8169": {
    loansProduced: "111.97",
    membersServed: "7,414",
    interestEarned: "9.52",
    name: "AIR FORCE",
    website: "https://goaffcu.com",
  },
  "8187": {
    loansProduced: "41.75",
    membersServed: "3,802",
    interestEarned: "3.55",
    name: "AMERICAN UNITED",
    website: "www.amucu.org",
  },
  "8197": {
    loansProduced: "3.24",
    membersServed: "412",
    interestEarned: "0.28",
    name: "MOUNTAIN EMPIRE",
    website: "https://www.mountainempirefcu.org",
  },
  "8212": {
    loansProduced: "8.20",
    membersServed: "1,144",
    interestEarned: "0.70",
    name: "BREAKWATER",
    website: "https://www.breakwaterfcu.org",
  },
  "8216": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "QUINDARO HOMES",
    website: null,
  },
  "8218": {
    loansProduced: "26.15",
    membersServed: "4,194",
    interestEarned: "2.22",
    name: "CENTRAL ONE",
    website: "http://www.centralfcu.com",
  },
  "8229": {
    loansProduced: "12.49",
    membersServed: "962",
    interestEarned: "1.06",
    name: "FIRST CHOICE",
    website: "https://www.firstchoicefcu.com",
  },
  "8230": {
    loansProduced: "3.69",
    membersServed: "444",
    interestEarned: "0.31",
    name: "TECHNICOLOR",
    website: "https://www.technicolorfcu.org",
  },
  "8237": {
    loansProduced: "10.73",
    membersServed: "945",
    interestEarned: "0.91",
    name: "SOUTH TEXAS",
    website: "http://www.southtexasfcu.com",
  },
  "8255": {
    loansProduced: "0.50",
    membersServed: "51",
    interestEarned: "0.04",
    name: "HOBART IND SCHOOL EMPLOYEES",
    website: null,
  },
  "8261": {
    loansProduced: "1.65",
    membersServed: "179",
    interestEarned: "0.14",
    name: "MATTEL",
    website: "http://www.mattelfcu.org",
  },
  "8290": {
    loansProduced: "32.82",
    membersServed: "3,343",
    interestEarned: "2.79",
    name: "THE PEOPLE'S",
    website: "http://www.tpfcu.com",
  },
  "8298": {
    loansProduced: "4.25",
    membersServed: "400",
    interestEarned: "0.36",
    name: "GOLDEN TRIANGLE",
    website: "http://www.gtfcu.coop",
  },
  "8299": {
    loansProduced: "15.55",
    membersServed: "1,029",
    interestEarned: "1.32",
    name: "ESTACADO",
    website: "http://WWW.ESTACADOFCU.COM",
  },
  "8313": {
    loansProduced: "1.38",
    membersServed: "272",
    interestEarned: "0.12",
    name: "COMMONROOTS",
    website: "https://commonrootsfcu.com",
  },
  "8317": {
    loansProduced: "3.98",
    membersServed: "625",
    interestEarned: "0.34",
    name: "CUTTING EDGE",
    website: "http://www.cuttingedgefcu.org",
  },
  "8333": {
    loansProduced: "322.56",
    membersServed: "45,730",
    interestEarned: "27.42",
    name: "TOWER",
    website: "http://towerfcu.org",
  },
  "8339": {
    loansProduced: "0.40",
    membersServed: "52",
    interestEarned: "0.03",
    name: "PATERSON POLICE",
    website: "http://www.patersonpolicefcu.com",
  },
  "8344": {
    loansProduced: "7.84",
    membersServed: "1,447",
    interestEarned: "0.67",
    name: "KBR HERITAGE",
    website: "http://WWW.KBRHFCU.ORG",
  },
  "8349": {
    loansProduced: "5.16",
    membersServed: "876",
    interestEarned: "0.44",
    name: "SHORE TO SHORE COMMUNITY",
    website: "http://www.shore2shorecu.com",
  },
  "8367": {
    loansProduced: "7.01",
    membersServed: "865",
    interestEarned: "0.60",
    name: "GARDINER",
    website: "gardinerfcu.org",
  },
  "8413": {
    loansProduced: "2.75",
    membersServed: "433",
    interestEarned: "0.23",
    name: "PORT ARTHUR TEACHERS",
    website: "http://www.patfcu.org",
  },
  "8418": {
    loansProduced: "6.06",
    membersServed: "417",
    interestEarned: "0.51",
    name: "STRIP STEEL COMMUNITY",
    website: "http://STRIPSTEELCFCU.COM",
  },
  "8428": {
    loansProduced: "17.08",
    membersServed: "1,721",
    interestEarned: "1.45",
    name: "ADVANTAGE PLUS",
    website: "http://www.advantagepluscreditunion",
  },
  "8433": {
    loansProduced: "4.43",
    membersServed: "606",
    interestEarned: "0.38",
    name: "MERIDIAN MUTUAL",
    website: "http://www.meridianmutualfcu.com",
  },
  "8435": {
    loansProduced: "4.85",
    membersServed: "1,220",
    interestEarned: "0.41",
    name: "KO`OLAU",
    website: "http://www.koolaufcu.org",
  },
  "8442": {
    loansProduced: "5.70",
    membersServed: "1,074",
    interestEarned: "0.48",
    name: "MY PENSACOLA",
    website: "www.MyPensacolaCU.com",
  },
  "8445": {
    loansProduced: "13.01",
    membersServed: "1,923",
    interestEarned: "1.11",
    name: "JACKSON AREA",
    website: "http://www.jacksonareafcu.com",
  },
  "8474": {
    loansProduced: "2.10",
    membersServed: "204",
    interestEarned: "0.18",
    name: "WILLOW ISLAND",
    website: "http://wifcu.com",
  },
  "8475": {
    loansProduced: "56.89",
    membersServed: "4,463",
    interestEarned: "4.84",
    name: "OTERO",
    website: "https://www.oterofcu.org",
  },
  "8486": {
    loansProduced: "47.82",
    membersServed: "6,769",
    interestEarned: "4.06",
    name: "TOWN & COUNTRY",
    website: "https://www.tcfcu.com",
  },
  "8512": {
    loansProduced: "8.49",
    membersServed: "2,236",
    interestEarned: "0.72",
    name: "A C M G",
    website: "http://www.acmgfcu.org",
  },
  "8523": {
    loansProduced: "14.69",
    membersServed: "7,010",
    interestEarned: "1.25",
    name: "CACL",
    website: "http://www.caclfcu.org",
  },
  "8530": {
    loansProduced: "6.43",
    membersServed: "867",
    interestEarned: "0.55",
    name: "KRAFTMAN",
    website: "https://kraftmanfcu.com",
  },
  "8540": {
    loansProduced: "2.48",
    membersServed: "299",
    interestEarned: "0.21",
    name: "R-S BELLCO",
    website: "http://www.rsbellcofcu.com",
  },
  "8548": {
    loansProduced: "32.02",
    membersServed: "3,048",
    interestEarned: "2.72",
    name: "ENCOMPASS",
    website: "http://www.encompasscu.org",
  },
  "8549": {
    loansProduced: "2.93",
    membersServed: "558",
    interestEarned: "0.25",
    name: "CECIL COUNTY SCHOOL EMPLOYEES",
    website: "http://www.ccsefcu.org",
  },
  "8550": {
    loansProduced: "63.15",
    membersServed: "5,027",
    interestEarned: "5.37",
    name: "FREEDOM OF MARYLAND",
    website: "http://www.freedomfcu.org",
  },
  "8554": {
    loansProduced: "66.98",
    membersServed: "13,966",
    interestEarned: "5.69",
    name: "FIRST FINANCIAL OF MARYLAND",
    website: "http://www.firstfinancial.org",
  },
  "8585": {
    loansProduced: "60.00",
    membersServed: "4,827",
    interestEarned: "5.10",
    name: "GENCO",
    website: "http://gencofcu.org",
  },
  "8588": {
    loansProduced: "2.38",
    membersServed: "253",
    interestEarned: "0.20",
    name: "COMMUNITY 1ST",
    website: "http://www.c1stfcu.org",
  },
  "8593": {
    loansProduced: "1.09",
    membersServed: "87",
    interestEarned: "0.09",
    name: "WATER'S EDGE",
    website: "http://WATERSEDGEFCU.COM",
  },
  "8609": {
    loansProduced: "3.94",
    membersServed: "549",
    interestEarned: "0.33",
    name: "EDU",
    website: "http://www.edufcu.org",
  },
  "8611": {
    loansProduced: "2.17",
    membersServed: "334",
    interestEarned: "0.18",
    name: "CAPSTONE",
    website: "https://www.capstonefcu.coop",
  },
  "8616": {
    loansProduced: "0.22",
    membersServed: "41",
    interestEarned: "0.02",
    name: "ST. ANTHONY OF PADUA",
    website: "http://www.stanthonyofpaduafcu.com",
  },
  "8618": {
    loansProduced: "0.95",
    membersServed: "123",
    interestEarned: "0.08",
    name: "WILLIAMSVILLE",
    website: "http://www.williamsvillefcu.com",
  },
  "8625": {
    loansProduced: "1.00",
    membersServed: "77",
    interestEarned: "0.08",
    name: "ERIE FIREFIGHTERS",
    website: "http://www.eriefirefightersfcu.com",
  },
  "8626": {
    loansProduced: "0.41",
    membersServed: "31",
    interestEarned: "0.03",
    name: "ERIE POLICE",
    website: null,
  },
  "8642": {
    loansProduced: "3.91",
    membersServed: "433",
    interestEarned: "0.33",
    name: "HOWARD COUNTY EDUCATION",
    website: "http://www.hctafcu.org",
  },
  "8654": {
    loansProduced: "1.99",
    membersServed: "223",
    interestEarned: "0.17",
    name: "TIPPECANOE",
    website: "www.TippecanoeFCU.com",
  },
  "8687": {
    loansProduced: "2.35",
    membersServed: "246",
    interestEarned: "0.20",
    name: "NORTH EAST WELCH",
    website: "http://www.newelchfcu.com",
  },
  "8693": {
    loansProduced: "22.97",
    membersServed: "2,482",
    interestEarned: "1.95",
    name: "NIAGARA'S CHOICE",
    website: "http://www.niagaraschoice.org",
  },
  "8694": {
    loansProduced: "48.98",
    membersServed: "3,320",
    interestEarned: "4.16",
    name: "SINGING RIVER",
    website: "http://WWW.SRFCU.ORG",
  },
  "8701": {
    loansProduced: "3.21",
    membersServed: "544",
    interestEarned: "0.27",
    name: "ELECTRICAL",
    website: "http://www.electricalfcu.org",
  },
  "8709": {
    loansProduced: "1.68",
    membersServed: "241",
    interestEarned: "0.14",
    name: "LAKESHORE",
    website: "http://www.lakeshorefcu.com",
  },
  "8715": {
    loansProduced: "48.80",
    membersServed: "3,613",
    interestEarned: "4.15",
    name: "1ST COMMUNITY",
    website: "http://www.1cfcu.org",
  },
  "8725": {
    loansProduced: "17.02",
    membersServed: "1,405",
    interestEarned: "1.45",
    name: "COLORAMO",
    website: "http://www.Coloramo.org",
  },
  "8733": {
    loansProduced: "10.09",
    membersServed: "1,302",
    interestEarned: "0.86",
    name: "SUNLIGHT",
    website: "https://www.sunlightfcu.com",
  },
  "8749": {
    loansProduced: "1.92",
    membersServed: "235",
    interestEarned: "0.16",
    name: "SNAKE RIVER",
    website: "http://snakeriverfcu.com",
  },
  "8752": {
    loansProduced: "5.36",
    membersServed: "482",
    interestEarned: "0.46",
    name: "SIOUX VALLEY COOP",
    website: "http://www.siouxvalleyco-opfcu.com",
  },
  "8774": {
    loansProduced: "5.60",
    membersServed: "1,624",
    interestEarned: "0.48",
    name: "MCKESSON & HEALTHCARE PROVIDERS",
    website: "www.mahpfcu.org",
  },
  "8791": {
    loansProduced: "3.66",
    membersServed: "410",
    interestEarned: "0.31",
    name: "METRO COMMUNITY",
    website: "www.metrocommunityfcu.com",
  },
  "8797": {
    loansProduced: "44.84",
    membersServed: "5,541",
    interestEarned: "3.81",
    name: "WRIGHT PATMAN CONGRESSIONAL",
    website: "http://www.congressionalfcu.org",
  },
  "8801": {
    loansProduced: "3.89",
    membersServed: "454",
    interestEarned: "0.33",
    name: "AVANTI",
    website: "http://www.avantifcu.net",
  },
  "8812": {
    loansProduced: "6.11",
    membersServed: "584",
    interestEarned: "0.52",
    name: "RIVER VALLEY COMMUNITY",
    website: "http://www.myrvcu.com",
  },
  "8822": {
    loansProduced: "37.75",
    membersServed: "3,765",
    interestEarned: "3.21",
    name: "DOVER-PHILA",
    website: "http://www.dpfcu.org",
  },
  "8825": {
    loansProduced: "2.36",
    membersServed: "325",
    interestEarned: "0.20",
    name: "ALLEG-KISKI POSTAL",
    website: "www.akpostalfcu.org",
  },
  "8828": {
    loansProduced: "4.17",
    membersServed: "503",
    interestEarned: "0.35",
    name: "NORFOLK COMMUNITY",
    website: "http://www.norfolkcommunityfcu.org",
  },
  "8831": {
    loansProduced: "2.79",
    membersServed: "410",
    interestEarned: "0.24",
    name: "BLAIR COUNTY",
    website: "http://www.blairctyfcu.org",
  },
  "8840": {
    loansProduced: "2.10",
    membersServed: "228",
    interestEarned: "0.18",
    name: "ONE SOUTH",
    website: "http://WWW.OLDSOUTHFCU.COM",
  },
  "8854": {
    loansProduced: "7.47",
    membersServed: "3,524",
    interestEarned: "0.64",
    name: "UKRAINIAN",
    website: "https://ukrainianfcu.org",
  },
  "8858": {
    loansProduced: "0.71",
    membersServed: "132",
    interestEarned: "0.06",
    name: "PAMCEL COMMUNITY",
    website: "http://www.pamcelfcu.com",
  },
  "8881": {
    loansProduced: "11.38",
    membersServed: "806",
    interestEarned: "0.97",
    name: "FERGUS",
    website: "https://www.fergusfcu.com",
  },
  "8885": {
    loansProduced: "4.38",
    membersServed: "1,532",
    interestEarned: "0.37",
    name: "TRI STATE AREA",
    website: "http://www.tsafcu.com",
  },
  "8890": {
    loansProduced: "3.71",
    membersServed: "203",
    interestEarned: "0.32",
    name: "DEPARTMENT OF PUBLIC SAFETY",
    website: "http://www.dpsfcu.org",
  },
  "8903": {
    loansProduced: "1.64",
    membersServed: "139",
    interestEarned: "0.14",
    name: "NEW CASTLE BELLCO",
    website: "http://WWW.NEWCASTLEBELLCO.COM",
  },
  "8905": {
    loansProduced: "39.87",
    membersServed: "4,483",
    interestEarned: "3.39",
    name: "SUNBELT",
    website: "www.sunbeltfcu.org",
  },
  "8913": {
    loansProduced: "1.33",
    membersServed: "109",
    interestEarned: "0.11",
    name: "ERIE CITY EMPLOYEES",
    website: null,
  },
  "8915": {
    loansProduced: "1.10",
    membersServed: "148",
    interestEarned: "0.09",
    name: "ST. MICHAELS FALL RIVER",
    website: "http://www.stmichaelsfcu.com",
  },
  "8920": {
    loansProduced: "1.15",
    membersServed: "70",
    interestEarned: "0.10",
    name: "PAN AMOCO",
    website: "http://panamocofcu.com",
  },
  "8921": {
    loansProduced: "7.40",
    membersServed: "811",
    interestEarned: "0.63",
    name: "WILLIS KNIGHTON",
    website: "http://www.wkfcu.org",
  },
  "8922": {
    loansProduced: "2.61",
    membersServed: "519",
    interestEarned: "0.22",
    name: "OUTREACH",
    website: "http://www.outreachcu.com",
  },
  "8925": {
    loansProduced: "5.64",
    membersServed: "575",
    interestEarned: "0.48",
    name: "ADVANTAGEPLUS OF INDIANA",
    website: "http://www.apifcu.com",
  },
  "8936": {
    loansProduced: "15.19",
    membersServed: "1,591",
    interestEarned: "1.29",
    name: "SEBASTICOOK VALLEY",
    website: "http://svfcume.com",
  },
  "8940": {
    loansProduced: "0.54",
    membersServed: "76",
    interestEarned: "0.05",
    name: "MODERN EMPLOYEES",
    website: "http://www.modernefcu.net",
  },
  "8943": {
    loansProduced: "1.65",
    membersServed: "104",
    interestEarned: "0.14",
    name: "MINOT AREA SCHOOLS",
    website: "http://www.masfcu.org",
  },
  "8944": {
    loansProduced: "0.68",
    membersServed: "48",
    interestEarned: "0.06",
    name: "HOLY FAMILY PARMA",
    website: "http://www.holyfamilycreditunion.co",
  },
  "8962": {
    loansProduced: "0.54",
    membersServed: "41",
    interestEarned: "0.05",
    name: "VELMA",
    website: null,
  },
  "8967": {
    loansProduced: "0.73",
    membersServed: "53",
    interestEarned: "0.06",
    name: "DAVISON EMPLOYEES",
    website: "davisonfcu.com",
  },
  "8975": {
    loansProduced: "6.76",
    membersServed: "920",
    interestEarned: "0.57",
    name: "ALCO",
    website: "http://alcofcu.com",
  },
  "8987": {
    loansProduced: "1.69",
    membersServed: "113",
    interestEarned: "0.14",
    name: "ARKANSAS HEALTH CENTER",
    website: "http://www.ahcfcu.com",
  },
  "8988": {
    loansProduced: "5.23",
    membersServed: "691",
    interestEarned: "0.44",
    name: "OWENSBORO",
    website: "http://www.ofcuonline.com",
  },
  "8993": {
    loansProduced: "0.46",
    membersServed: "76",
    interestEarned: "0.04",
    name: "PUBLIC SERVICE PLAZA",
    website: "http://PSPLAZAFCU.com",
  },
  "9000": {
    loansProduced: "0.75",
    membersServed: "94",
    interestEarned: "0.06",
    name: "NICKEL STEEL",
    website: "https://www.nickelsteelfcu.com",
  },
  "9003": {
    loansProduced: "27.44",
    membersServed: "3,685",
    interestEarned: "2.33",
    name: "MARKET USA",
    website: "http://www.marketusafcu.com",
  },
  "9007": {
    loansProduced: "214.21",
    membersServed: "21,290",
    interestEarned: "18.21",
    name: "CLEARVIEW",
    website: "http://www.clearviewfcu.org",
  },
  "9042": {
    loansProduced: "1.52",
    membersServed: "271",
    interestEarned: "0.13",
    name: "WESTMORELAND WATER",
    website: "www.wwcu.org",
  },
  "9044": {
    loansProduced: "0.08",
    membersServed: "5",
    interestEarned: "0.01",
    name: "COLUMBUS CLUB",
    website: null,
  },
  "9071": {
    loansProduced: "41.56",
    membersServed: "4,634",
    interestEarned: "3.53",
    name: "DIRECT",
    website: "http://www.direct.com",
  },
  "9075": {
    loansProduced: "0.92",
    membersServed: "72",
    interestEarned: "0.08",
    name: "HOBOKEN N J POLICE",
    website: null,
  },
  "9082": {
    loansProduced: "0.26",
    membersServed: "41",
    interestEarned: "0.02",
    name: "PUBLIC SERVICE H C EMPLOYEES",
    website: null,
  },
  "9089": {
    loansProduced: "79.24",
    membersServed: "6,588",
    interestEarned: "6.74",
    name: "UNIWYO",
    website: "http://www.uniwyo.com",
  },
  "9090": {
    loansProduced: "3.15",
    membersServed: "381",
    interestEarned: "0.27",
    name: "FIRST OKLAHOMA",
    website: "http://www.firstokfcu.org",
  },
  "9093": {
    loansProduced: "7.18",
    membersServed: "443",
    interestEarned: "0.61",
    name: "ROSWELL COMMUNITY",
    website: "http://www.roswellcu.org",
  },
  "9094": {
    loansProduced: "52.36",
    membersServed: "4,357",
    interestEarned: "4.45",
    name: "WYHY",
    website: "http://www.wyhy.org",
  },
  "9095": {
    loansProduced: "137.97",
    membersServed: "17,609",
    interestEarned: "11.73",
    name: "HANSCOM",
    website: "http://www.hfcu.org",
  },
  "9097": {
    loansProduced: "2.46",
    membersServed: "381",
    interestEarned: "0.21",
    name: "ONE VISION",
    website: "http://www.onevisionfcu.org",
  },
  "9099": {
    loansProduced: "44.84",
    membersServed: "2,883",
    interestEarned: "3.81",
    name: "PINAL COUNTY",
    website: "http://www.pinalcountyfcu.com",
  },
  "9102": {
    loansProduced: "0.39",
    membersServed: "29",
    interestEarned: "0.03",
    name: "F.F.E.",
    website: "http://FFE-FCU.org",
  },
  "9105": {
    loansProduced: "2.71",
    membersServed: "174",
    interestEarned: "0.23",
    name: "SOUTHERNMOST",
    website: "http://www.smfcu.net",
  },
  "9106": {
    loansProduced: "29.19",
    membersServed: "3,968",
    interestEarned: "2.48",
    name: "CLASS ACT",
    website: "http://www.classact.org",
  },
  "9107": {
    loansProduced: "24.44",
    membersServed: "2,441",
    interestEarned: "2.08",
    name: "SOUTHERN CHAUTAUQUA",
    website: "http://www.665-7000.com",
  },
  "9109": {
    loansProduced: "18.74",
    membersServed: "2,575",
    interestEarned: "1.59",
    name: "FAMILIES AND SCHOOLS TOGETHER",
    website: "www.fastcu.com",
  },
  "9115": {
    loansProduced: "2.49",
    membersServed: "248",
    interestEarned: "0.21",
    name: "HOTEL AND TRAVEL INDUSTRY",
    website: "http://www.htifcu.com",
  },
  "9119": {
    loansProduced: "59.92",
    membersServed: "8,630",
    interestEarned: "5.09",
    name: "MERCED SCHOOL EMPLOYEES",
    website: "http://mercedschoolcu.org",
  },
  "9145": {
    loansProduced: "3.04",
    membersServed: "825",
    interestEarned: "0.26",
    name: "PUGET SOUND REFINERY",
    website: "http://psrfcu.org",
  },
  "9146": {
    loansProduced: "3.07",
    membersServed: "182",
    interestEarned: "0.26",
    name: "B-M S",
    website: "http://bmsfcu.org",
  },
  "9148": {
    loansProduced: "7.30",
    membersServed: "632",
    interestEarned: "0.62",
    name: "PATHFINDER",
    website: "http://www.pathfinderfcu.com",
  },
  "9160": {
    loansProduced: "55.55",
    membersServed: "5,634",
    interestEarned: "4.72",
    name: "MERIDIAN TRUST",
    website: "http://www.mymeridiantrust.com",
  },
  "9163": {
    loansProduced: "21.40",
    membersServed: "2,203",
    interestEarned: "1.82",
    name: "ELEVATE",
    website: "http://www.elevatecu.com",
  },
  "9164": {
    loansProduced: "61.41",
    membersServed: "7,658",
    interestEarned: "5.22",
    name: "CARTER",
    website: "http://www.cartercu.org",
  },
  "9189": {
    loansProduced: "6.35",
    membersServed: "2,090",
    interestEarned: "0.54",
    name: "TOLEDO METRO",
    website: "http://www.toledometro.com",
  },
  "9194": {
    loansProduced: "2.16",
    membersServed: "341",
    interestEarned: "0.18",
    name: "HEARTLAND AREA",
    website: "http://www.heartlandfcu.org",
  },
  "9208": {
    loansProduced: "9.87",
    membersServed: "1,539",
    interestEarned: "0.84",
    name: "OHIO CATHOLIC",
    website: "http://www.ohiocatholicfcu.com",
  },
  "9213": {
    loansProduced: "2.41",
    membersServed: "341",
    interestEarned: "0.20",
    name: "CENTRAL KEYSTONE",
    website: "http://www.ckfcu.org",
  },
  "9214": {
    loansProduced: "8.83",
    membersServed: "625",
    interestEarned: "0.75",
    name: "SOUTHERN",
    website: "http://www.southernfederalcu.org",
  },
  "9219": {
    loansProduced: "18.21",
    membersServed: "1,524",
    interestEarned: "1.55",
    name: "TRAILNORTH",
    website: "http://www.tfcunow.com",
  },
  "9229": {
    loansProduced: "187.62",
    membersServed: "13,348",
    interestEarned: "15.95",
    name: "NEIGHBORS",
    website: "http://www.neighborsfcu.org",
  },
  "9230": {
    loansProduced: "70.77",
    membersServed: "12,112",
    interestEarned: "6.02",
    name: "CERTIFIED",
    website: "http://www.certifiedfed.com",
  },
  "9237": {
    loansProduced: "4.03",
    membersServed: "720",
    interestEarned: "0.34",
    name: "RUTGERS",
    website: "http://www.rutgersfcu.org",
  },
  "9246": {
    loansProduced: "1.57",
    membersServed: "159",
    interestEarned: "0.13",
    name: "PASADENA MUNICIPAL",
    website: null,
  },
  "9247": {
    loansProduced: "92.55",
    membersServed: "8,050",
    interestEarned: "7.87",
    name: "PIONEER",
    website: "http://www.pioneerfcu.org",
  },
  "9248": {
    loansProduced: "0.73",
    membersServed: "64",
    interestEarned: "0.06",
    name: "UNITED MEMBERS",
    website: "http://www.unitedmembersfcu.com",
  },
  "9260": {
    loansProduced: "32.94",
    membersServed: "5,896",
    interestEarned: "2.80",
    name: "L & N",
    website: "http://www.lnfcu.com",
  },
  "9287": {
    loansProduced: "2.54",
    membersServed: "509",
    interestEarned: "0.22",
    name: "MIAMI",
    website: "http://www.miamifcu.org",
  },
  "9288": {
    loansProduced: "4.22",
    membersServed: "711",
    interestEarned: "0.36",
    name: "PORTLAND LOCAL NO 8",
    website: "http://www.pdx8fcu.com",
  },
  "9289": {
    loansProduced: "4.84",
    membersServed: "683",
    interestEarned: "0.41",
    name: "LOWER COLUMBIA LONGSHOREMEN",
    website: "http://www.lclfcu.org",
  },
  "9292": {
    loansProduced: "22.04",
    membersServed: "2,329",
    interestEarned: "1.87",
    name: "CONSOLIDATED",
    website: "https://www.consolidatedccu.com",
  },
  "9293": {
    loansProduced: "32.34",
    membersServed: "2,816",
    interestEarned: "2.75",
    name: "YOLO",
    website: "www.yolofcu.org",
  },
  "9296": {
    loansProduced: "48.73",
    membersServed: "6,858",
    interestEarned: "4.14",
    name: "SUN COMMUNITY",
    website: "https://www.suncommunity.com",
  },
  "9300": {
    loansProduced: "32.61",
    membersServed: "3,620",
    interestEarned: "2.77",
    name: "BOSSIER",
    website: "http://www.bossierfcu.org",
  },
  "9310": {
    loansProduced: "0.68",
    membersServed: "36",
    interestEarned: "0.06",
    name: "DELTA REFINING CO EMP",
    website: null,
  },
  "9327": {
    loansProduced: "8.17",
    membersServed: "813",
    interestEarned: "0.69",
    name: "CASCO",
    website: "http://www.cascofcu.com",
  },
  "9335": {
    loansProduced: "15.96",
    membersServed: "954",
    interestEarned: "1.36",
    name: "OU",
    website: "https://www.oufcu.com",
  },
  "9348": {
    loansProduced: "8.93",
    membersServed: "1,299",
    interestEarned: "0.76",
    name: "KATAHDIN",
    website: "http://www.katahdinfcu.org",
  },
  "9349": {
    loansProduced: "14.66",
    membersServed: "2,111",
    interestEarned: "1.25",
    name: "OTIS",
    website: "http://www.otisfcu.coop",
  },
  "9358": {
    loansProduced: "3.49",
    membersServed: "649",
    interestEarned: "0.30",
    name: "NEW YORK TIMES EMPLOYEES",
    website: "http://www.nytimescu.org",
  },
  "9370": {
    loansProduced: "3.78",
    membersServed: "369",
    interestEarned: "0.32",
    name: "EVERYONE'S",
    website: "http://everyonesfcu.com",
  },
  "9373": {
    loansProduced: "25.19",
    membersServed: "3,844",
    interestEarned: "2.14",
    name: "AMERICA'S CREDIT UNION, A",
    website: "http://www.youracu.org",
  },
  "9376": {
    loansProduced: "2.17",
    membersServed: "155",
    interestEarned: "0.18",
    name: "SOUTH COAST ILWU",
    website: "http://southcoastilwufcu.com",
  },
  "9381": {
    loansProduced: "45.52",
    membersServed: "4,940",
    interestEarned: "3.87",
    name: "GATHER",
    website: "http://www.gatherfcu.org",
  },
  "9384": {
    loansProduced: "0.07",
    membersServed: "9",
    interestEarned: "0.01",
    name: "WASHINGTON TYPOGRAPHIC",
    website: null,
  },
  "9398": {
    loansProduced: "5.45",
    membersServed: "403",
    interestEarned: "0.46",
    name: "LU 354 I B E W",
    website: "http://www.ibewfcu.org",
  },
  "9408": {
    loansProduced: "3.95",
    membersServed: "621",
    interestEarned: "0.34",
    name: "SAVANNAH SCHOOLS",
    website: "http://www.ssfcuonline.org",
  },
  "9411": {
    loansProduced: "1.76",
    membersServed: "243",
    interestEarned: "0.15",
    name: "HAMILTON HORIZONS",
    website: "http://www.hamiltonhorizons.org",
  },
  "9413": {
    loansProduced: "4.92",
    membersServed: "345",
    interestEarned: "0.42",
    name: "MERCER COUNTY W VA TEACHERS",
    website: "http://www.mctfcu.virtualcu.net",
  },
  "9441": {
    loansProduced: "0.88",
    membersServed: "151",
    interestEarned: "0.08",
    name: "METRO NORTH",
    website: "http://www.metronorthfcu.com",
  },
  "9457": {
    loansProduced: "0.73",
    membersServed: "119",
    interestEarned: "0.06",
    name: "E M O T",
    website: null,
  },
  "9469": {
    loansProduced: "4.42",
    membersServed: "761",
    interestEarned: "0.38",
    name: "MARISOL",
    website: "https://www.marisolcu.org",
  },
  "9475": {
    loansProduced: "74.23",
    membersServed: "5,707",
    interestEarned: "6.31",
    name: "APL",
    website: "http://www.aplfcu.org",
  },
  "9476": {
    loansProduced: "1.30",
    membersServed: "197",
    interestEarned: "0.11",
    name: "WEST PENN P&P",
    website: "http://WWW.WESTPPFCU.COM",
  },
  "9479": {
    loansProduced: "1.76",
    membersServed: "341",
    interestEarned: "0.15",
    name: "VA PITTSBURGH EMPLOYEES",
    website: "http://www.vapghefcu.org",
  },
  "9483": {
    loansProduced: "14.56",
    membersServed: "1,259",
    interestEarned: "1.24",
    name: "IBEW & UNITED WORKERS",
    website: "http://www.ibewuwfcu.com",
  },
  "9486": {
    loansProduced: "4.31",
    membersServed: "513",
    interestEarned: "0.37",
    name: "NEXT STEP",
    website: "http://www.nextstepfcu.org",
  },
  "9496": {
    loansProduced: "0.27",
    membersServed: "29",
    interestEarned: "0.02",
    name: "SP TRAINMEN",
    website: null,
  },
  "9497": {
    loansProduced: "0.56",
    membersServed: "41",
    interestEarned: "0.05",
    name: "SHAWNEE TVA EMPLOYEES",
    website: "http://www.stvafcu.virtualcu.net",
  },
  "9500": {
    loansProduced: "26.37",
    membersServed: "2,510",
    interestEarned: "2.24",
    name: "CUMBERLAND COUNTY",
    website: "http://www.myccfcu.com",
  },
  "9501": {
    loansProduced: "0.27",
    membersServed: "16",
    interestEarned: "0.02",
    name: "GUERNSEY COMMUNITY",
    website: null,
  },
  "9517": {
    loansProduced: "1.71",
    membersServed: "344",
    interestEarned: "0.15",
    name: "CHEN-DEL-O",
    website: "http://www.chendelo.org",
  },
  "9518": {
    loansProduced: "3.95",
    membersServed: "549",
    interestEarned: "0.34",
    name: "EASTMILL",
    website: "http://www.eastmillfcu.org",
  },
  "9522": {
    loansProduced: "16.70",
    membersServed: "1,887",
    interestEarned: "1.42",
    name: "MIDWEST COMMUNITY",
    website: "http://midwestcommunity.org",
  },
  "9527": {
    loansProduced: "0.01",
    membersServed: "5",
    interestEarned: "0.00",
    name: "F A B CHURCH",
    website: "www.fabfcu.com",
  },
  "9532": {
    loansProduced: "2.97",
    membersServed: "239",
    interestEarned: "0.25",
    name: "WESTCHESTER SCHOOLS'",
    website: "http://portchesterteachersfcu.org",
  },
  "9537": {
    loansProduced: "5.59",
    membersServed: "909",
    interestEarned: "0.48",
    name: "GREATER NIAGARA",
    website: "https://www.greaterniagarafcu.com",
  },
  "9554": {
    loansProduced: "0.25",
    membersServed: "42",
    interestEarned: "0.02",
    name: "COUNCILL",
    website: null,
  },
  "9555": {
    loansProduced: "0.45",
    membersServed: "99",
    interestEarned: "0.04",
    name: "NEW JERSEY COMMUNITY",
    website: "http://WWW.NEWJERSEYCOMMUNITYFCU.CO",
  },
  "9556": {
    loansProduced: "9.56",
    membersServed: "1,722",
    interestEarned: "0.81",
    name: "CLEARPATH",
    website: "http://www.clearpathfcu.org",
  },
  "9566": {
    loansProduced: "10.09",
    membersServed: "550",
    interestEarned: "0.86",
    name: "EDDY",
    website: "http://www.eddyfederalcu.com",
  },
  "9582": {
    loansProduced: "2.91",
    membersServed: "318",
    interestEarned: "0.25",
    name: "BRIDGEPORT POLICE",
    website: "http://www.mybpfcu.org",
  },
  "9589": {
    loansProduced: "245.73",
    membersServed: "17,994",
    interestEarned: "20.89",
    name: "BARKSDALE",
    website: "http://www.bfcu.org",
  },
  "9590": {
    loansProduced: "1.62",
    membersServed: "98",
    interestEarned: "0.14",
    name: "ESPEECO",
    website: "http://www.espeeco.org",
  },
  "9604": {
    loansProduced: "67.16",
    membersServed: "5,020",
    interestEarned: "5.71",
    name: "FIRST HERITAGE",
    website: "www.fhfcu.org",
  },
  "9607": {
    loansProduced: "15.14",
    membersServed: "2,617",
    interestEarned: "1.29",
    name: "APCI",
    website: "https://www.apcifcu.org",
  },
  "9613": {
    loansProduced: "0.01",
    membersServed: "5",
    interestEarned: "0.00",
    name: "SARGENT",
    website: null,
  },
  "9622": {
    loansProduced: "3.74",
    membersServed: "656",
    interestEarned: "0.32",
    name: "FORGE",
    website: "http://www.forgecu.org",
  },
  "9639": {
    loansProduced: "7.11",
    membersServed: "1,022",
    interestEarned: "0.60",
    name: "F A A",
    website: "http://www.faafcu.org",
  },
  "9643": {
    loansProduced: "20.98",
    membersServed: "2,873",
    interestEarned: "1.78",
    name: "MERITUS",
    website: "http://www.merituscu.net",
  },
  "9644": {
    loansProduced: "5.57",
    membersServed: "299",
    interestEarned: "0.47",
    name: "CIT-CO",
    website: "http://www.citcofcu.com",
  },
  "9662": {
    loansProduced: "7.07",
    membersServed: "849",
    interestEarned: "0.60",
    name: "ROCKET CITY",
    website: "http://www.rocketcityfcu.org",
  },
  "9676": {
    loansProduced: "1.32",
    membersServed: "82",
    interestEarned: "0.11",
    name: "LINTON",
    website: null,
  },
  "9681": {
    loansProduced: "4.17",
    membersServed: "419",
    interestEarned: "0.35",
    name: "MORRISONS COVE 1ST",
    website: "http://www.mcove1stfcu.com",
  },
  "9715": {
    loansProduced: "4.31",
    membersServed: "539",
    interestEarned: "0.37",
    name: "EMPLOYEES",
    website: "http://www.employeesfed.com",
  },
  "9723": {
    loansProduced: "1.42",
    membersServed: "166",
    interestEarned: "0.12",
    name: "N.J.T. EMPLOYEES",
    website: null,
  },
  "9759": {
    loansProduced: "97.76",
    membersServed: "9,095",
    interestEarned: "8.31",
    name: "FORTERA",
    website: "http://forteracu.com",
  },
  "9776": {
    loansProduced: "1.10",
    membersServed: "223",
    interestEarned: "0.09",
    name: "N.F.G. #2",
    website: "http://www.nfg2.com",
  },
  "9777": {
    loansProduced: "2.03",
    membersServed: "230",
    interestEarned: "0.17",
    name: "EWEB EMPLOYEES",
    website: "http://www.myewebcu.org/",
  },
  "9778": {
    loansProduced: "1.41",
    membersServed: "74",
    interestEarned: "0.12",
    name: "REDLANDS CITY EMPLOYEES",
    website: "http://redlandsfcu.org",
  },
  "9788": {
    loansProduced: "182.57",
    membersServed: "15,098",
    interestEarned: "15.52",
    name: "EGLIN",
    website: "https://www.eglinfcu.org",
  },
  "9799": {
    loansProduced: "4.07",
    membersServed: "391",
    interestEarned: "0.35",
    name: "ROCKET",
    website: "http://www.rocketfcu.com",
  },
  "9805": {
    loansProduced: "1.15",
    membersServed: "100",
    interestEarned: "0.10",
    name: "ARLINGTON MUNICIPAL",
    website: "http://www.arlfcu.org",
  },
  "9807": {
    loansProduced: "0.57",
    membersServed: "37",
    interestEarned: "0.05",
    name: "MT CARMEL CHURCH",
    website: null,
  },
  "9810": {
    loansProduced: "6.07",
    membersServed: "574",
    interestEarned: "0.52",
    name: "FIDELIS CATHOLIC",
    website: "http://www.fideliscu.org",
  },
  "9812": {
    loansProduced: "2.43",
    membersServed: "350",
    interestEarned: "0.21",
    name: "NORTH TOWNS",
    website: "http://www.northtownsfcu.com",
  },
  "9822": {
    loansProduced: "1.34",
    membersServed: "217",
    interestEarned: "0.11",
    name: "SANTO CHRISTO",
    website: "http://santochristocu.com",
  },
  "9823": {
    loansProduced: "1.07",
    membersServed: "158",
    interestEarned: "0.09",
    name: "BEVERLY HILLS CITY EMPLOYEES",
    website: "http://www.bhcefcu.org",
  },
  "9843": {
    loansProduced: "1.71",
    membersServed: "160",
    interestEarned: "0.15",
    name: "FRIONA TEXAS",
    website: "https://www.ea.financial-net.com/vb",
  },
  "9871": {
    loansProduced: "15.48",
    membersServed: "1,365",
    interestEarned: "1.32",
    name: "SAINT LAWRENCE",
    website: "http://www.stlawrfcu.com",
  },
  "9873": {
    loansProduced: "3.60",
    membersServed: "256",
    interestEarned: "0.31",
    name: "KNOXVILLE FIREFIGHTERS",
    website: "http://www.knoxfirefcu.com",
  },
  "9877": {
    loansProduced: "2.05",
    membersServed: "336",
    interestEarned: "0.17",
    name: "EMERALD EMPIRE",
    website: "http://www.youreecu.org",
  },
  "9880": {
    loansProduced: "1.83",
    membersServed: "176",
    interestEarned: "0.16",
    name: "ANDERSON COUNTY",
    website: "http://www.ancfcu.com",
  },
  "9888": {
    loansProduced: "1.19",
    membersServed: "121",
    interestEarned: "0.10",
    name: "HB TELCO",
    website: "hbtelcofcu.com",
  },
  "9893": {
    loansProduced: "0.79",
    membersServed: "119",
    interestEarned: "0.07",
    name: "BEE",
    website: "www.beefcu.org",
  },
  "9897": {
    loansProduced: "5.62",
    membersServed: "752",
    interestEarned: "0.48",
    name: "SOUTHEASTERN ARIZONA",
    website: "http://www.seafcu.com",
  },
  "9907": {
    loansProduced: "0.85",
    membersServed: "65",
    interestEarned: "0.07",
    name: "DAKOTA RAIL LINE",
    website: null,
  },
  "9915": {
    loansProduced: "140.36",
    membersServed: "8,725",
    interestEarned: "11.93",
    name: "MCCOY",
    website: "http://www.mccoyfcu.org",
  },
  "9919": {
    loansProduced: "7.01",
    membersServed: "636",
    interestEarned: "0.60",
    name: "CAPITAL AREA",
    website: "https://WWW.CAPITALAREAFCU.ORG",
  },
  "9923": {
    loansProduced: "40.81",
    membersServed: "2,666",
    interestEarned: "3.47",
    name: "SOLIDARITY COMMUNITY",
    website: "https://www.solfcu.org/",
  },
  "9924": {
    loansProduced: "4.34",
    membersServed: "328",
    interestEarned: "0.37",
    name: "WAILUKU",
    website: "http://www.wailukufcu.com",
  },
  "9931": {
    loansProduced: "11.44",
    membersServed: "713",
    interestEarned: "0.97",
    name: "BAYOU",
    website: "http://www.bayoufcu.org",
  },
  "9943": {
    loansProduced: "15.26",
    membersServed: "1,534",
    interestEarned: "1.30",
    name: "KSW",
    website: "https://www.kswfcu.org",
  },
  "9951": {
    loansProduced: "2.63",
    membersServed: "3,216",
    interestEarned: "0.22",
    name: "ARIZONA COPPER",
    website: "http://www.rayfcu.org",
  },
  "9952": {
    loansProduced: "0.14",
    membersServed: "22",
    interestEarned: "0.01",
    name: "I R E B",
    website: null,
  },
  "9971": {
    loansProduced: "35.46",
    membersServed: "1,917",
    interestEarned: "3.01",
    name: "SANTA FE",
    website: "http://www.santafefcu.com",
  },
  "9974": {
    loansProduced: "0.33",
    membersServed: "82",
    interestEarned: "0.03",
    name: "SHELBY/BOLIVAR COUNTY",
    website: "www.shelbycu.org",
  },
  "9976": {
    loansProduced: "485.96",
    membersServed: "32,813",
    interestEarned: "41.31",
    name: "GROW FINANCIAL",
    website: "http://www.growfinancial.org",
  },
  "9986": {
    loansProduced: "87.62",
    membersServed: "8,895",
    interestEarned: "7.45",
    name: "REV",
    website: "http://www.revfcu.com",
  },
  "9988": {
    loansProduced: "270.81",
    membersServed: "23,339",
    interestEarned: "23.02",
    name: "SAFE",
    website: "https://www.safefed.org",
  },
  "9994": {
    loansProduced: "4.57",
    membersServed: "347",
    interestEarned: "0.39",
    name: "HEALTHNET",
    website: "http://www.healthnetfcu.org",
  },
  "10011": {
    loansProduced: "0.04",
    membersServed: "14",
    interestEarned: "0.00",
    name: "MUNICIPAL EMPLOYEES",
    website: null,
  },
  "10015": {
    loansProduced: "4.49",
    membersServed: "638",
    interestEarned: "0.38",
    name: "ALLENTOWN",
    website: "http://www.allentownfcu.com",
  },
  "10023": {
    loansProduced: "79.57",
    membersServed: "7,461",
    interestEarned: "6.76",
    name: "ISLAND",
    website: "http://www.islandfcu.com",
  },
  "10047": {
    loansProduced: "0.77",
    membersServed: "51",
    interestEarned: "0.07",
    name: "WABELLCO",
    website: "http://www.wabfcu.org",
  },
  "10065": {
    loansProduced: "3.29",
    membersServed: "359",
    interestEarned: "0.28",
    name: "MYCOM",
    website: "http://www.mycomcu.org",
  },
  "10083": {
    loansProduced: "6.30",
    membersServed: "706",
    interestEarned: "0.54",
    name: "LIMESTONE",
    website: "http://WWW.LIMESTONEFEDERAL.COM",
  },
  "10099": {
    loansProduced: "1.22",
    membersServed: "121",
    interestEarned: "0.10",
    name: "TORCH LAKE",
    website: "http://www.torchlakefederal.com",
  },
  "10100": {
    loansProduced: "25.40",
    membersServed: "3,339",
    interestEarned: "2.16",
    name: "TRUCHOICE",
    website: "http://WWW.TRUFCU.COM",
  },
  "10141": {
    loansProduced: "1.35",
    membersServed: "86",
    interestEarned: "0.12",
    name: "DERTOWN SCHOOL",
    website: null,
  },
  "10158": {
    loansProduced: "4.88",
    membersServed: "326",
    interestEarned: "0.42",
    name: "OK",
    website: "http://www.okfcu.org",
  },
  "10164": {
    loansProduced: "8.31",
    membersServed: "1,715",
    interestEarned: "0.71",
    name: "HEART OF LOUISIANA",
    website: "http://www.heartcu.org",
  },
  "10170": {
    loansProduced: "33.41",
    membersServed: "1,294",
    interestEarned: "2.84",
    name: "CITIZENS",
    website: "http://www.citizensfcu.com",
  },
  "10174": {
    loansProduced: "185.28",
    membersServed: "14,175",
    interestEarned: "15.75",
    name: "FIRSTLIGHT",
    website: "http://www.firstlightfcu.org",
  },
  "10177": {
    loansProduced: "0.19",
    membersServed: "26",
    interestEarned: "0.02",
    name: "TAYCO EMPLOYEES",
    website: null,
  },
  "10180": {
    loansProduced: "0.68",
    membersServed: "59",
    interestEarned: "0.06",
    name: "FORT MORGAN SCHOOLS",
    website: null,
  },
  "10181": {
    loansProduced: "53.75",
    membersServed: "4,472",
    interestEarned: "4.57",
    name: "OUACHITA VALLEY",
    website: "http://ouachitavalleyfcu.org",
  },
  "10184": {
    loansProduced: "3.95",
    membersServed: "711",
    interestEarned: "0.34",
    name: "FRIENDLY",
    website: "http://www.friendlyfcu.org",
  },
  "10220": {
    loansProduced: "0.47",
    membersServed: "718",
    interestEarned: "0.04",
    name: "FOCUS FIRST",
    website: "http://www.focusfirstfcu.org",
  },
  "10221": {
    loansProduced: "3.03",
    membersServed: "259",
    interestEarned: "0.26",
    name: "GREAT FALLS REGIONAL",
    website: "http://www.greatfallsfcu.com",
  },
  "10223": {
    loansProduced: "18.08",
    membersServed: "4,271",
    interestEarned: "1.54",
    name: "EVERENCE",
    website: "http://www.everence.com",
  },
  "10225": {
    loansProduced: "0.19",
    membersServed: "12",
    interestEarned: "0.02",
    name: "BECCO BUFFALO",
    website: null,
  },
  "10241": {
    loansProduced: "13.74",
    membersServed: "1,247",
    interestEarned: "1.17",
    name: "MAPLE",
    website: "http://www.maplefcu.net",
  },
  "10243": {
    loansProduced: "3.98",
    membersServed: "369",
    interestEarned: "0.34",
    name: "OAHE",
    website: "http://www.oahefcu.coop",
  },
  "10253": {
    loansProduced: "0.16",
    membersServed: "19",
    interestEarned: "0.01",
    name: "AKRON",
    website: null,
  },
  "10257": {
    loansProduced: "7.23",
    membersServed: "1,059",
    interestEarned: "0.61",
    name: "SENTINEL",
    website: "http://www.sentinelfcu.org",
  },
  "10262": {
    loansProduced: "8.63",
    membersServed: "923",
    interestEarned: "0.73",
    name: "ALTAMAHA",
    website: "http://www.altamaha.org",
  },
  "10264": {
    loansProduced: "0.17",
    membersServed: "14",
    interestEarned: "0.01",
    name: "VALLEY WIDE OF PA",
    website: "https://www.valleywideofpa.com",
  },
  "10269": {
    loansProduced: "2.94",
    membersServed: "241",
    interestEarned: "0.25",
    name: "CHEROKEE COUNTY TEACHERS",
    website: "http://WWW.CCTEACHERS.COM",
  },
  "10274": {
    loansProduced: "2.87",
    membersServed: "310",
    interestEarned: "0.24",
    name: "W B H EMPLOYEES",
    website: "http://www.wbhfcu.com",
  },
  "10283": {
    loansProduced: "23.40",
    membersServed: "1,795",
    interestEarned: "1.99",
    name: "THE FOCUS",
    website: "http://www.focusok.com",
  },
  "10301": {
    loansProduced: "3.34",
    membersServed: "267",
    interestEarned: "0.28",
    name: "ROMNEY",
    website: "http://www.romneyfcu.com",
  },
  "10308": {
    loansProduced: "0.12",
    membersServed: "15",
    interestEarned: "0.01",
    name: "ALTON MUNICIPAL EMPLOYEES",
    website: null,
  },
  "10319": {
    loansProduced: "1.19",
    membersServed: "120",
    interestEarned: "0.10",
    name: "FIRST POINT",
    website: "http://firstpointfcu.com",
  },
  "10323": {
    loansProduced: "2.78",
    membersServed: "617",
    interestEarned: "0.24",
    name: "I B E W 26",
    website: "http://www.ibew26fcu.com",
  },
  "10324": {
    loansProduced: "1.60",
    membersServed: "78",
    interestEarned: "0.14",
    name: "JACKSON COUNTY",
    website: "http://www.jacksoncountyfcu.com",
  },
  "10327": {
    loansProduced: "1.30",
    membersServed: "191",
    interestEarned: "0.11",
    name: "VICTORIA TEACHERS",
    website: "http://www.myvtfcu.com",
  },
  "10335": {
    loansProduced: "12.89",
    membersServed: "1,322",
    interestEarned: "1.10",
    name: "COCONINO",
    website: "http://www.coconinofcu.org",
  },
  "10351": {
    loansProduced: "11.50",
    membersServed: "1,245",
    interestEarned: "0.98",
    name: "LA LOMA",
    website: "http://www.llfcu.org",
  },
  "10353": {
    loansProduced: "3.91",
    membersServed: "300",
    interestEarned: "0.33",
    name: "ACCESS OF LOUISIANA",
    website: "http://www.access.coop",
  },
  "10362": {
    loansProduced: "5.17",
    membersServed: "458",
    interestEarned: "0.44",
    name: "NORTHERN REDWOOD",
    website: "https://www.northernredwoodfcu.org",
  },
  "10375": {
    loansProduced: "21.27",
    membersServed: "2,022",
    interestEarned: "1.81",
    name: "1ST LIBERTY",
    website: "http://www.1stliberty.org",
  },
  "10381": {
    loansProduced: "4.21",
    membersServed: "372",
    interestEarned: "0.36",
    name: "FERKO MD",
    website: "http://www.ferkomfcu.com",
  },
  "10393": {
    loansProduced: "0.65",
    membersServed: "57",
    interestEarned: "0.06",
    name: "ELECTRICAL WORKERS LOCAL 130",
    website: "https://ibewlu130.com/local-130-fed",
  },
  "10395": {
    loansProduced: "20.35",
    membersServed: "1,421",
    interestEarned: "1.73",
    name: "KINGS",
    website: "http://kingsfcu.com",
  },
  "10399": {
    loansProduced: "1.08",
    membersServed: "204",
    interestEarned: "0.09",
    name: "KAHULUI",
    website: "http://www.kahuluifcu.com",
  },
  "10424": {
    loansProduced: "14.59",
    membersServed: "1,280",
    interestEarned: "1.24",
    name: "LAS COLINAS",
    website: "http://www.lascolinasfcu.com",
  },
  "10427": {
    loansProduced: "0.26",
    membersServed: "69",
    interestEarned: "0.02",
    name: "MCKEESPORT BELL",
    website: null,
  },
  "10433": {
    loansProduced: "6.03",
    membersServed: "455",
    interestEarned: "0.51",
    name: "BOPTI",
    website: "http://www.boptifcu.com",
  },
  "10442": {
    loansProduced: "4.76",
    membersServed: "486",
    interestEarned: "0.40",
    name: "UNITED TRADES",
    website: "http://www.unitedtradesfcu.org",
  },
  "10449": {
    loansProduced: "0.61",
    membersServed: "85",
    interestEarned: "0.05",
    name: "CABWAY TELCO",
    website: "http://www.cabwaytelcofcu.org",
  },
  "10461": {
    loansProduced: "1.70",
    membersServed: "240",
    interestEarned: "0.14",
    name: "MCLENNAN COUNTY EMPLOYEES",
    website: "http://www.mclennancountyefcu.com",
  },
  "10465": {
    loansProduced: "18.82",
    membersServed: "2,363",
    interestEarned: "1.60",
    name: "UNIVERSITY OF HAWAII",
    website: "http://www.uhfcu.com",
  },
  "10481": {
    loansProduced: "2.73",
    membersServed: "265",
    interestEarned: "0.23",
    name: "MCMURREY",
    website: "http://www.mcmurreyfcu.com",
  },
  "10502": {
    loansProduced: "0.85",
    membersServed: "114",
    interestEarned: "0.07",
    name: "YANTIS",
    website: "http://www.yantisfcu.com",
  },
  "10515": {
    loansProduced: "0.56",
    membersServed: "116",
    interestEarned: "0.05",
    name: "NEW ORLEANS CLERK & CHECKERS",
    website: "http://www.facebook.com/noccfcu",
  },
  "10520": {
    loansProduced: "1.51",
    membersServed: "128",
    interestEarned: "0.13",
    name: "ALDERSON FCI",
    website: null,
  },
  "10551": {
    loansProduced: "1.35",
    membersServed: "124",
    interestEarned: "0.12",
    name: "SCURRY COUNTY SCHOOL",
    website: "http://scurrycountyschoolfcu.com",
  },
  "10572": {
    loansProduced: "26.03",
    membersServed: "1,721",
    interestEarned: "2.21",
    name: "FITZSIMONS",
    website: "http://www.fitzsimonscu.com",
  },
  "10577": {
    loansProduced: "5.76",
    membersServed: "494",
    interestEarned: "0.49",
    name: "BIG SPRING EDUCATION EMPLOYEES",
    website: "http://WWW.BSTCU.COM",
  },
  "10580": {
    loansProduced: "1.61",
    membersServed: "116",
    interestEarned: "0.14",
    name: "U-1ST COMMUNITY",
    website: "http://www.u1stcommfcu.net",
  },
  "10585": {
    loansProduced: "17.86",
    membersServed: "2,799",
    interestEarned: "1.52",
    name: "LINCONE",
    website: "http://www.linconefcu.org",
  },
  "10600": {
    loansProduced: "102.54",
    membersServed: "8,189",
    interestEarned: "8.72",
    name: "AIR ACADEMY",
    website: "http://www.aafcu.com",
  },
  "10613": {
    loansProduced: "1.25",
    membersServed: "167",
    interestEarned: "0.11",
    name: "NIAGARA FALLS A F",
    website: "http://www.niagarafallsafcu.com",
  },
  "10614": {
    loansProduced: "0.36",
    membersServed: "41",
    interestEarned: "0.03",
    name: "KEARNY MUNICIPAL EMPLOYEES",
    website: null,
  },
  "10623": {
    loansProduced: "1.17",
    membersServed: "172",
    interestEarned: "0.10",
    name: "WELLESLEY MUNICIPAL EMPLOYEES",
    website: "http://www.wellesleymunefcu.com",
  },
  "10636": {
    loansProduced: "0.09",
    membersServed: "39",
    interestEarned: "0.01",
    name: "BRUNSWICK COUNTY TEACHERS",
    website: null,
  },
  "10640": {
    loansProduced: "4.65",
    membersServed: "454",
    interestEarned: "0.40",
    name: "CUSA",
    website: "http://www.cusafcu.com",
  },
  "10648": {
    loansProduced: "0.10",
    membersServed: "5",
    interestEarned: "0.01",
    name: "MARYKNOLL OF L A",
    website: null,
  },
  "10666": {
    loansProduced: "4.61",
    membersServed: "310",
    interestEarned: "0.39",
    name: "GRAND HERITAGE",
    website: "http://ghfcu.com",
  },
  "10671": {
    loansProduced: "7.00",
    membersServed: "683",
    interestEarned: "0.59",
    name: "GREATER PITTSBURGH",
    website: "http://www.greaterpittsburghfcu.org",
  },
  "10676": {
    loansProduced: "2.65",
    membersServed: "237",
    interestEarned: "0.22",
    name: "KNOX COUNTY TEACHERS",
    website: "http://www.kctfcu.org",
  },
  "10684": {
    loansProduced: "17.83",
    membersServed: "1,253",
    interestEarned: "1.52",
    name: "MERIDIA COMMUNITY",
    website: "http://www.meridiacu.com",
  },
  "10687": {
    loansProduced: "15.09",
    membersServed: "1,635",
    interestEarned: "1.28",
    name: "HORIZON",
    website: "https://HORIZONFCU.ORG",
  },
  "10690": {
    loansProduced: "1.43",
    membersServed: "195",
    interestEarned: "0.12",
    name: "FAYETTE FEDERAL EMPLOYEES",
    website: "http://www.ffefcu.org",
  },
  "10695": {
    loansProduced: "3.98",
    membersServed: "231",
    interestEarned: "0.34",
    name: "MORGANTOWN A E S",
    website: "http://www.aesfcu.com",
  },
  "10696": {
    loansProduced: "136.58",
    membersServed: "13,017",
    interestEarned: "11.61",
    name: "EDUCATIONAL SYSTEMS",
    website: "http://www.esfcu.org",
  },
  "10699": {
    loansProduced: "20.81",
    membersServed: "2,353",
    interestEarned: "1.77",
    name: "SAN ANTONIO CITIZENS",
    website: "http://www.sacfcu.org",
  },
  "10704": {
    loansProduced: "1.97",
    membersServed: "140",
    interestEarned: "0.17",
    name: "P C",
    website: "http://www.pcfederalcu.org",
  },
  "10706": {
    loansProduced: "17.32",
    membersServed: "1,186",
    interestEarned: "1.47",
    name: "COMMUNITIES OF ABILENE",
    website: "http://www.coafcu.org",
  },
  "10709": {
    loansProduced: "258.23",
    membersServed: "24,667",
    interestEarned: "21.95",
    name: "UTAH COMMUNITY",
    website: "https://www.uccu.com",
  },
  "10720": {
    loansProduced: "0.89",
    membersServed: "148",
    interestEarned: "0.08",
    name: "NORTH PENN",
    website: "http://northpennfcu.org",
  },
  "10728": {
    loansProduced: "0.79",
    membersServed: "63",
    interestEarned: "0.07",
    name: "LEWISTON PORTER",
    website: "http://www.lewistonporterfcu.com",
  },
  "10729": {
    loansProduced: "0.48",
    membersServed: "58",
    interestEarned: "0.04",
    name: "THE NEW HAVEN POLICE AND MUNICIPAL",
    website: "www.nhpdfcu.com",
  },
  "10739": {
    loansProduced: "1.19",
    membersServed: "79",
    interestEarned: "0.10",
    name: "ANDREWS SCHOOL",
    website: null,
  },
  "10741": {
    loansProduced: "0.16",
    membersServed: "20",
    interestEarned: "0.01",
    name: "PUBLIC SERVICE E.D. TRENTON",
    website: null,
  },
  "10746": {
    loansProduced: "0.34",
    membersServed: "26",
    interestEarned: "0.03",
    name: "UPSTATE TELCO",
    website: "http://www.upstatetelcofcu.org",
  },
  "10754": {
    loansProduced: "131.40",
    membersServed: "17,161",
    interestEarned: "11.17",
    name: "TYNDALL",
    website: "http://www.tyndall.org",
  },
  "10756": {
    loansProduced: "2.04",
    membersServed: "217",
    interestEarned: "0.17",
    name: "FIRST LINCOLN",
    website: "http://www.firstlincoln.org",
  },
  "10763": {
    loansProduced: "1.47",
    membersServed: "152",
    interestEarned: "0.13",
    name: "ELECTRICAL WORKERS NO 22",
    website: "http://www.ew22fcu.org",
  },
  "10772": {
    loansProduced: "2.84",
    membersServed: "208",
    interestEarned: "0.24",
    name: "LAKES",
    website: "http://www.lakesfcu.com",
  },
  "10773": {
    loansProduced: "0.13",
    membersServed: "18",
    interestEarned: "0.01",
    name: "STRATTON AIR NATIONAL GUARD",
    website: null,
  },
  "10778": {
    loansProduced: "0.16",
    membersServed: "23",
    interestEarned: "0.01",
    name: "H A L E",
    website: null,
  },
  "10779": {
    loansProduced: "23.34",
    membersServed: "2,103",
    interestEarned: "1.98",
    name: "LINN - CO",
    website: "http://www.linncofcu.org",
  },
  "10794": {
    loansProduced: "51.07",
    membersServed: "3,642",
    interestEarned: "4.34",
    name: "THE COUNTY",
    website: "http://www.countyfcu.org",
  },
  "10799": {
    loansProduced: "1.76",
    membersServed: "283",
    interestEarned: "0.15",
    name: "LONGSHOREMEN'S LOCAL 4",
    website: "http://www.lshore4cu.com",
  },
  "10800": {
    loansProduced: "6.26",
    membersServed: "1,094",
    interestEarned: "0.53",
    name: "THE HEALTH & EDUCATION",
    website: "http://www.thefcu.com",
  },
  "10803": {
    loansProduced: "0.04",
    membersServed: "3",
    interestEarned: "0.00",
    name: "ISRAEL MEMORIAL A M E",
    website: null,
  },
  "10832": {
    loansProduced: "46.99",
    membersServed: "3,476",
    interestEarned: "3.99",
    name: "FORT SILL",
    website: "http://www.fsfcu.com",
  },
  "10857": {
    loansProduced: "0.74",
    membersServed: "64",
    interestEarned: "0.06",
    name: "DEER LODGE COUNTY SCHOOL EMP",
    website: null,
  },
  "10859": {
    loansProduced: "0.82",
    membersServed: "80",
    interestEarned: "0.07",
    name: "W T COMMUNITY",
    website: null,
  },
  "10865": {
    loansProduced: "0.71",
    membersServed: "91",
    interestEarned: "0.06",
    name: "GREATER WATERBURY HEALTHCARE",
    website: "http://www.gwhfcu.com",
  },
  "10866": {
    loansProduced: "101.13",
    membersServed: "7,523",
    interestEarned: "8.60",
    name: "KINETIC",
    website: "http://kineticCU.com",
  },
  "10880": {
    loansProduced: "13.23",
    membersServed: "1,172",
    interestEarned: "1.12",
    name: "PINELLAS",
    website: "http://www.pinellasfcu.org",
  },
  "10882": {
    loansProduced: "0.56",
    membersServed: "46",
    interestEarned: "0.05",
    name: "LOCAL UNION 1186 IBEW",
    website: null,
  },
  "10898": {
    loansProduced: "16.44",
    membersServed: "2,061",
    interestEarned: "1.40",
    name: "MIDCOAST",
    website: "https://midcoastfcu.me",
  },
  "10904": {
    loansProduced: "9.59",
    membersServed: "745",
    interestEarned: "0.82",
    name: "GRANCO",
    website: "http://www.granco.org",
  },
  "10913": {
    loansProduced: "8.24",
    membersServed: "1,827",
    interestEarned: "0.70",
    name: "SOUTHWEST RESEARCH CENTER",
    website: "https://www.swrcfcu.com",
  },
  "10920": {
    loansProduced: "326.13",
    membersServed: "22,802",
    interestEarned: "27.72",
    name: "ARKANSAS",
    website: "http://www.afcu.org",
  },
  "10933": {
    loansProduced: "57.89",
    membersServed: "5,468",
    interestEarned: "4.92",
    name: "SERVU",
    website: "http://www.servucu.com",
  },
  "10938": {
    loansProduced: "6.14",
    membersServed: "541",
    interestEarned: "0.52",
    name: "HAWAII FIRST",
    website: "http://www.hawaiifirstfcu.com",
  },
  "10939": {
    loansProduced: "25.31",
    membersServed: "2,210",
    interestEarned: "2.15",
    name: "BANGOR",
    website: "http://www.bangorfederal.com",
  },
  "10954": {
    loansProduced: "1.85",
    membersServed: "599",
    interestEarned: "0.16",
    name: "METHUEN",
    website: "http://methuencreditunion.com",
  },
  "10958": {
    loansProduced: "12.08",
    membersServed: "1,285",
    interestEarned: "1.03",
    name: "FIRST EAGLE",
    website: "http://www.firsteaglefcu.org",
  },
  "10973": {
    loansProduced: "12.91",
    membersServed: "1,297",
    interestEarned: "1.10",
    name: "UFCW COMMUNITY",
    website: "www.ufcwpa.org",
  },
  "10994": {
    loansProduced: "17.05",
    membersServed: "2,319",
    interestEarned: "1.45",
    name: "NAFT",
    website: "http://www.naftfcu.coop",
  },
  "11005": {
    loansProduced: "4.38",
    membersServed: "437",
    interestEarned: "0.37",
    name: "ALLIED HEALTHCARE",
    website: "http://www.ahfcu.org",
  },
  "11011": {
    loansProduced: "14.84",
    membersServed: "1,045",
    interestEarned: "1.26",
    name: "LAREDO",
    website: "http://www.laredofcu.org",
  },
  "11014": {
    loansProduced: "2.19",
    membersServed: "151",
    interestEarned: "0.19",
    name: "EAST ORANGE FIREMENS",
    website: "eoffcu.com",
  },
  "11030": {
    loansProduced: "1.91",
    membersServed: "339",
    interestEarned: "0.16",
    name: "GRASSLANDS",
    website: "http://www.grasslandscu.com",
  },
  "11032": {
    loansProduced: "10.56",
    membersServed: "501",
    interestEarned: "0.90",
    name: "HEART O' TEXAS",
    website: "http://www.hotfcu.org",
  },
  "11045": {
    loansProduced: "1.77",
    membersServed: "138",
    interestEarned: "0.15",
    name: "PBA",
    website: "http://www.pbafcu.com",
  },
  "11056": {
    loansProduced: "1.58",
    membersServed: "122",
    interestEarned: "0.13",
    name: "NORTHEAST ALABAMA POSTAL",
    website: "http://WWW.NEAPFCU.COM",
  },
  "11057": {
    loansProduced: "24.62",
    membersServed: "2,535",
    interestEarned: "2.09",
    name: "FIRST FAMILY",
    website: "http://www.firstfamilyfcu.com",
  },
  "11065": {
    loansProduced: "1,438.21",
    membersServed: "126,480",
    interestEarned: "122.25",
    name: "SECURITY SERVICE",
    website: "http://www.ssfcu.org",
  },
  "11067": {
    loansProduced: "11.78",
    membersServed: "845",
    interestEarned: "1.00",
    name: "4U",
    website: "http://www.4ucu.org",
  },
  "11099": {
    loansProduced: "5.78",
    membersServed: "949",
    interestEarned: "0.49",
    name: "EAST COUNTY SCHOOLS",
    website: "http://www.eastcountyschools.org",
  },
  "11111": {
    loansProduced: "61.34",
    membersServed: "6,485",
    interestEarned: "5.21",
    name: "FREEDOM FIRST",
    website: "http://www.freedomfirst.com",
  },
  "11123": {
    loansProduced: "0.08",
    membersServed: "9",
    interestEarned: "0.01",
    name: "S R I EMPLOYEES",
    website: null,
  },
  "11144": {
    loansProduced: "36.45",
    membersServed: "3,500",
    interestEarned: "3.10",
    name: "DIRIGO",
    website: "http://www.dirigofcu.com",
  },
  "11146": {
    loansProduced: "0.46",
    membersServed: "41",
    interestEarned: "0.04",
    name: "MUSKEGON PATTERNMAKERS",
    website: null,
  },
  "11152": {
    loansProduced: "2.02",
    membersServed: "489",
    interestEarned: "0.17",
    name: "ETMA",
    website: "https://www.etmafcu.net",
  },
  "11154": {
    loansProduced: "13.06",
    membersServed: "1,626",
    interestEarned: "1.11",
    name: "BLUE COAST",
    website: "www.myBCFCU.com",
  },
  "11157": {
    loansProduced: "3.10",
    membersServed: "320",
    interestEarned: "0.26",
    name: "UTICA DISTRICT TELEPHONE EMPLOYEES",
    website: "WWW.UTELFCU.NET",
  },
  "11162": {
    loansProduced: "23.96",
    membersServed: "2,935",
    interestEarned: "2.04",
    name: "CANOPY",
    website: "http://www.canopycu.com",
  },
  "11164": {
    loansProduced: "1.34",
    membersServed: "122",
    interestEarned: "0.11",
    name: "COASTAL BEND P O",
    website: null,
  },
  "11168": {
    loansProduced: "4.21",
    membersServed: "453",
    interestEarned: "0.36",
    name: "AUDUBON",
    website: "http://audubonfcu.net",
  },
  "11170": {
    loansProduced: "19.43",
    membersServed: "1,786",
    interestEarned: "1.65",
    name: "FIRST SERVICE",
    website: "http://firstcu.com",
  },
  "11172": {
    loansProduced: "2.46",
    membersServed: "440",
    interestEarned: "0.21",
    name: "COMMUNITY CONNECT",
    website: "http://www.communityconnectfcu.com",
  },
  "11242": {
    loansProduced: "3.18",
    membersServed: "1,086",
    interestEarned: "0.27",
    name: "PINPOINT",
    website: "https://www.pinpointfcu.org",
  },
  "11253": {
    loansProduced: "21.03",
    membersServed: "2,628",
    interestEarned: "1.79",
    name: "NEW DIMENSIONS",
    website: "https://newdimensionsfcu.com",
  },
  "11254": {
    loansProduced: "7.86",
    membersServed: "1,049",
    interestEarned: "0.67",
    name: "CELCO COMMUNITY",
    website: "http://www.celcofcu.org",
  },
  "11273": {
    loansProduced: "11.42",
    membersServed: "962",
    interestEarned: "0.97",
    name: "STERLING UNITED",
    website: "http://www.sufcu.org",
  },
  "11285": {
    loansProduced: "0.66",
    membersServed: "67",
    interestEarned: "0.06",
    name: "CORNING GLASS WORKS HARRODSBG",
    website: null,
  },
  "11293": {
    loansProduced: "28.79",
    membersServed: "3,175",
    interestEarned: "2.45",
    name: "VACATIONLAND",
    website: "https://vlfcu.org",
  },
  "11299": {
    loansProduced: "0.61",
    membersServed: "49",
    interestEarned: "0.05",
    name: "C A S E",
    website: "http://www.casefcu.org",
  },
  "11300": {
    loansProduced: "57.73",
    membersServed: "7,367",
    interestEarned: "4.91",
    name: "CLEARWATER",
    website: "http://www.clearwatercreditunion.or",
  },
  "11304": {
    loansProduced: "8.71",
    membersServed: "1,061",
    interestEarned: "0.74",
    name: "ARC",
    website: "http://www.arcfcu.org",
  },
  "11307": {
    loansProduced: "281.69",
    membersServed: "38,354",
    interestEarned: "23.94",
    name: "APPLE",
    website: "http://www.applefcu.org",
  },
  "11316": {
    loansProduced: "212.37",
    membersServed: "11,711",
    interestEarned: "18.05",
    name: "SANDIA AREA",
    website: "http://www.sandia.org",
  },
  "11323": {
    loansProduced: "1.64",
    membersServed: "174",
    interestEarned: "0.14",
    name: "PACE",
    website: "http://www.pacefederal.com",
  },
  "11331": {
    loansProduced: "0.78",
    membersServed: "44",
    interestEarned: "0.07",
    name: "M D U EMPLOYEES",
    website: "http://www.mdufcu.com",
  },
  "11340": {
    loansProduced: "0.15",
    membersServed: "11",
    interestEarned: "0.01",
    name: "ST. MICHAELS",
    website: null,
  },
  "11347": {
    loansProduced: "1.11",
    membersServed: "92",
    interestEarned: "0.09",
    name: "WILMINGTON POLICE & FIRE",
    website: "http://www.wpffcu.org",
  },
  "11353": {
    loansProduced: "5.67",
    membersServed: "442",
    interestEarned: "0.48",
    name: "EAST RIVER",
    website: "http://www.eastriverfcu.com",
  },
  "11364": {
    loansProduced: "2.08",
    membersServed: "221",
    interestEarned: "0.18",
    name: "THE FAMILY FIRST",
    website: "http://www.familyfirstfcu.org",
  },
  "11374": {
    loansProduced: "2.84",
    membersServed: "437",
    interestEarned: "0.24",
    name: "C-B-W SCHOOLS",
    website: "http://www.cbwsfcu.org",
  },
  "11377": {
    loansProduced: "0.76",
    membersServed: "66",
    interestEarned: "0.06",
    name: "SWEETWATER REGIONAL",
    website: "http://www.sweetwatercredit.com",
  },
  "11380": {
    loansProduced: "0.06",
    membersServed: "8",
    interestEarned: "0.01",
    name: "FIDELIS",
    website: "www.fidelisfcu.org",
  },
  "11391": {
    loansProduced: "1.08",
    membersServed: "199",
    interestEarned: "0.09",
    name: "KINZUA",
    website: "http://www.kinzuafcu.com",
  },
  "11400": {
    loansProduced: "0.57",
    membersServed: "46",
    interestEarned: "0.05",
    name: "GOLDMARK",
    website: "http://www.goldmarkfcu.com",
  },
  "11402": {
    loansProduced: "7.86",
    membersServed: "786",
    interestEarned: "0.67",
    name: "GREAT MEADOW",
    website: "http://www.greatmeadowfcu.org",
  },
  "11409": {
    loansProduced: "1.85",
    membersServed: "326",
    interestEarned: "0.16",
    name: "LITHIUM",
    website: "http://www.lithiumfcu.org",
  },
  "11440": {
    loansProduced: "0.64",
    membersServed: "73",
    interestEarned: "0.05",
    name: "BUTLER HERITAGE",
    website: "http://www.butlerheritagefcu.org",
  },
  "11445": {
    loansProduced: "3.21",
    membersServed: "294",
    interestEarned: "0.27",
    name: "BREWSTER",
    website: "http://www.brewsterfcu.org",
  },
  "11452": {
    loansProduced: "37.96",
    membersServed: "4,028",
    interestEarned: "3.23",
    name: "CY-FAIR",
    website: "http://www.cyfairfcu.org",
  },
  "11458": {
    loansProduced: "1.06",
    membersServed: "185",
    interestEarned: "0.09",
    name: "MOORE COUNTY SCHOOLS",
    website: "http://mcsfcu.com",
  },
  "11459": {
    loansProduced: "1.06",
    membersServed: "74",
    interestEarned: "0.09",
    name: "WINSLOW SCHOOL EMPLOYEES",
    website: null,
  },
  "11470": {
    loansProduced: "2.53",
    membersServed: "242",
    interestEarned: "0.21",
    name: "SWEET HOME",
    website: "http://WWW.SWEETHOMEFCU.ORG",
  },
  "11477": {
    loansProduced: "1.96",
    membersServed: "250",
    interestEarned: "0.17",
    name: "UNIVERSAL COOP",
    website: "http://www.universalcoopfcu.com",
  },
  "11520": {
    loansProduced: "11.89",
    membersServed: "1,963",
    interestEarned: "1.01",
    name: "ASCENT",
    website: "http://www.ascentcu.com",
  },
  "11524": {
    loansProduced: "1.34",
    membersServed: "98",
    interestEarned: "0.11",
    name: "IRS BUFFALO",
    website: "http://www.irsbflofcu.org",
  },
  "11526": {
    loansProduced: "7.70",
    membersServed: "723",
    interestEarned: "0.65",
    name: "DAKOTA PLAINS",
    website: "http://dakotaplainsfcu.com",
  },
  "11533": {
    loansProduced: "5.71",
    membersServed: "296",
    interestEarned: "0.49",
    name: "KNOXVILLE LAW ENFORCEMENT",
    website: "http://www.klefcu.com",
  },
  "11548": {
    loansProduced: "5.70",
    membersServed: "712",
    interestEarned: "0.48",
    name: "COLUMBINE",
    website: "http://www.columbinefcu.org",
  },
  "11550": {
    loansProduced: "0.74",
    membersServed: "67",
    interestEarned: "0.06",
    name: "MARYVALE SCHOOLS",
    website: "http://maryvaleschoolsfcu.com",
  },
  "11553": {
    loansProduced: "1.71",
    membersServed: "222",
    interestEarned: "0.15",
    name: "KAMEHAMEHA",
    website: "https://www.kamehamehafcu.org",
  },
  "11554": {
    loansProduced: "90.57",
    membersServed: "8,184",
    interestEarned: "7.70",
    name: "FAMILY TRUST",
    website: "http://www.familytrust.org",
  },
  "11567": {
    loansProduced: "0.01",
    membersServed: "7",
    interestEarned: "0.00",
    name: "BRISTOL VA SCHOOL SYSTEM",
    website: null,
  },
  "11576": {
    loansProduced: "4.89",
    membersServed: "506",
    interestEarned: "0.42",
    name: "TEXHILLCO SCHOOL EMPLOYEES",
    website: "https://Texhillco.org",
  },
  "11604": {
    loansProduced: "2.79",
    membersServed: "195",
    interestEarned: "0.24",
    name: "EAST ORANGE VA HOSPITAL",
    website: "http://www.eovahfcu.com",
  },
  "11640": {
    loansProduced: "3.80",
    membersServed: "526",
    interestEarned: "0.32",
    name: "BOULEVARD",
    website: "http://www.blvdfcu.com",
  },
  "11643": {
    loansProduced: "1.93",
    membersServed: "264",
    interestEarned: "0.16",
    name: "FREEDOM 1ST",
    website: "http://www.freedom1stfcu.org",
  },
  "11652": {
    loansProduced: "4.73",
    membersServed: "470",
    interestEarned: "0.40",
    name: "GOLDEN VALLEY",
    website: "http://www.goldenvalleyfcu.org",
  },
  "11658": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "AVENUE BAPTIST BROTHERHOOD",
    website: null,
  },
  "11663": {
    loansProduced: "9.99",
    membersServed: "1,182",
    interestEarned: "0.85",
    name: "GOOD NEIGHBORS",
    website: "http://www.goodneighborscu.com",
  },
  "11670": {
    loansProduced: "14.09",
    membersServed: "997",
    interestEarned: "1.20",
    name: "TEXOMA EDUCATORS",
    website: "http://www.texomaeducators.com",
  },
  "11671": {
    loansProduced: "1.28",
    membersServed: "174",
    interestEarned: "0.11",
    name: "T.E.S. REGIONAL HEALTHCARE",
    website: "http://www.tesfcu.org",
  },
  "11674": {
    loansProduced: "61.30",
    membersServed: "5,948",
    interestEarned: "5.21",
    name: "FIRELANDS",
    website: "http://www.firelandsfcu.org",
  },
  "11678": {
    loansProduced: "8.13",
    membersServed: "693",
    interestEarned: "0.69",
    name: "TIMBERLINE",
    website: "http://www.timberlinefcu.org",
  },
  "11680": {
    loansProduced: "6.37",
    membersServed: "711",
    interestEarned: "0.54",
    name: "BANNER",
    website: "http://bannerfcu.org",
  },
  "11685": {
    loansProduced: "12.44",
    membersServed: "1,368",
    interestEarned: "1.06",
    name: "ARKANSAS SUPERIOR",
    website: "http://www.asfcu.coop",
  },
  "11689": {
    loansProduced: "2.90",
    membersServed: "331",
    interestEarned: "0.25",
    name: "WYROPE WILLIAMSPORT",
    website: "http://www.wyrope.org",
  },
  "11718": {
    loansProduced: "6.61",
    membersServed: "584",
    interestEarned: "0.56",
    name: "GRAND JUNCTION",
    website: "http://www.grandjunctionfcu.org",
  },
  "11721": {
    loansProduced: "6.73",
    membersServed: "281",
    interestEarned: "0.57",
    name: "SAN PATRICIO COUNTY TEACHERS",
    website: "http://www.sanpatcu.com",
  },
  "11723": {
    loansProduced: "39.63",
    membersServed: "6,006",
    interestEarned: "3.37",
    name: "CORNERSTONE COMMUNITY",
    website: "http://CornerstoneCommunityFCU.org",
  },
  "11744": {
    loansProduced: "2.77",
    membersServed: "292",
    interestEarned: "0.24",
    name: "AUGUSTA COUNTY",
    website: "http://AUGUSTACOUNTYFCU.ORG",
  },
  "11746": {
    loansProduced: "7.61",
    membersServed: "1,121",
    interestEarned: "0.65",
    name: "BROWARD HEALTHCARE",
    website: "http://www.bhcfcu.org",
  },
  "11761": {
    loansProduced: "0.46",
    membersServed: "46",
    interestEarned: "0.04",
    name: "PECO",
    website: null,
  },
  "11779": {
    loansProduced: "4.68",
    membersServed: "415",
    interestEarned: "0.40",
    name: "PUBLIC SERVICE",
    website: "www.psfcunj.com",
  },
  "11780": {
    loansProduced: "19.88",
    membersServed: "2,042",
    interestEarned: "1.69",
    name: "SANTA ROSA COUNTY",
    website: "http://www.srcfcu.org",
  },
  "11782": {
    loansProduced: "2.71",
    membersServed: "290",
    interestEarned: "0.23",
    name: "BAYOU COMMUNITY",
    website: "http://www.bayoucfcu.com",
  },
  "11783": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "S I PHILADELPHIA",
    website: null,
  },
  "11786": {
    loansProduced: "8.68",
    membersServed: "539",
    interestEarned: "0.74",
    name: "HURRICANE CREEK",
    website: "http://www.hurricanecreekfcu.org",
  },
  "11793": {
    loansProduced: "59.20",
    membersServed: "5,959",
    interestEarned: "5.03",
    name: "EMBOLD",
    website: "www.emboldcu.org",
  },
  "11824": {
    loansProduced: "10.15",
    membersServed: "486",
    interestEarned: "0.86",
    name: "CHURCHILL COUNTY",
    website: "https://www.myccfcu.org",
  },
  "11836": {
    loansProduced: "85.77",
    membersServed: "7,797",
    interestEarned: "7.29",
    name: "BAYER HERITAGE",
    website: "http://bayerhfcu.com",
  },
  "11867": {
    loansProduced: "10.70",
    membersServed: "657",
    interestEarned: "0.91",
    name: "A.C.P.E.",
    website: "http://www.acpefcu.com",
  },
  "11870": {
    loansProduced: "21.61",
    membersServed: "3,710",
    interestEarned: "1.84",
    name: "DOWNEY",
    website: "http://www.downeyfcu.org",
  },
  "11872": {
    loansProduced: "4.94",
    membersServed: "794",
    interestEarned: "0.42",
    name: "SRI",
    website: "http://SRIFCU.ORG",
  },
  "11891": {
    loansProduced: "14.22",
    membersServed: "1,303",
    interestEarned: "1.21",
    name: "MERCER COUNTY COMMUNITY",
    website: "http://www.mercomfcu.org",
  },
  "11900": {
    loansProduced: "0.22",
    membersServed: "29",
    interestEarned: "0.02",
    name: "MOJAVE PLANT EMPLOYEES",
    website: null,
  },
  "11902": {
    loansProduced: "5.26",
    membersServed: "464",
    interestEarned: "0.45",
    name: "PATTERSON",
    website: "http://www.pfcuonline.org",
  },
  "11905": {
    loansProduced: "4.35",
    membersServed: "807",
    interestEarned: "0.37",
    name: "CASTPARTS EMPLOYEES",
    website: "http://www.castpartsfcu.org",
  },
  "11906": {
    loansProduced: "2.39",
    membersServed: "254",
    interestEarned: "0.20",
    name: "ALLIED PLASTICS",
    website: "http://apfcu.net",
  },
  "11913": {
    loansProduced: "10.89",
    membersServed: "2,570",
    interestEarned: "0.93",
    name: "ALCOA COMMUNITY",
    website: "http://www.alcoacommunityfcu.org",
  },
  "11917": {
    loansProduced: "0.97",
    membersServed: "354",
    interestEarned: "0.08",
    name: "AIRCO",
    website: "http://www.aircofcu.org",
  },
  "11927": {
    loansProduced: "10.81",
    membersServed: "1,607",
    interestEarned: "0.92",
    name: "COASTAL COMMUNITY",
    website: "www.coastalcommunityfcu.org",
  },
  "11942": {
    loansProduced: "10.52",
    membersServed: "1,689",
    interestEarned: "0.89",
    name: "ST. TAMMANY",
    website: "http://www.sttammanyfederalcu.org",
  },
  "11943": {
    loansProduced: "4.66",
    membersServed: "618",
    interestEarned: "0.40",
    name: "BEFIT FINANCIAL",
    website: "http://www.bffcu.org",
  },
  "11944": {
    loansProduced: "18.42",
    membersServed: "734",
    interestEarned: "1.57",
    name: "KAIPERM",
    website: "http://www.kaipermcu.org",
  },
  "11949": {
    loansProduced: "2.31",
    membersServed: "1,003",
    interestEarned: "0.20",
    name: "IBEW 76",
    website: "http://ibew76fcu.org",
  },
  "11950": {
    loansProduced: "2.97",
    membersServed: "232",
    interestEarned: "0.25",
    name: "COVINGTON SCHOOLS",
    website: null,
  },
  "11953": {
    loansProduced: "5.20",
    membersServed: "352",
    interestEarned: "0.44",
    name: "PARADISE VALLEY",
    website: "http://www.paradisevalleyfcu.org",
  },
  "11962": {
    loansProduced: "15.97",
    membersServed: "1,169",
    interestEarned: "1.36",
    name: "R G",
    website: "http://RGFCU.COM",
  },
  "11972": {
    loansProduced: "0.79",
    membersServed: "149",
    interestEarned: "0.07",
    name: "YOUR CHOICE",
    website: "http://www.yourchoicefcu.com",
  },
  "11975": {
    loansProduced: "2.63",
    membersServed: "288",
    interestEarned: "0.22",
    name: "ANG",
    website: "http://www.angfcu.org",
  },
  "11979": {
    loansProduced: "30.07",
    membersServed: "3,561",
    interestEarned: "2.56",
    name: "NORTHLAND AREA",
    website: "http://www.northlandcu.com",
  },
  "11986": {
    loansProduced: "0.11",
    membersServed: "8",
    interestEarned: "0.01",
    name: "HIGH STREET BAPTIST CHURCH",
    website: null,
  },
  "11992": {
    loansProduced: "0.10",
    membersServed: "8",
    interestEarned: "0.01",
    name: "ST. PASCHAL BAYLONS",
    website: null,
  },
  "11995": {
    loansProduced: "0.71",
    membersServed: "127",
    interestEarned: "0.06",
    name: "GREATER CENTRAL TEXAS",
    website: "http://www.gctfcu.net",
  },
  "11996": {
    loansProduced: "1.89",
    membersServed: "244",
    interestEarned: "0.16",
    name: "COLQUITT COUNTY TEACHER",
    website: null,
  },
  "12000": {
    loansProduced: "10.83",
    membersServed: "604",
    interestEarned: "0.92",
    name: "MOHAVE COMMUNITY",
    website: "http://mohavecommunityfcu.com",
  },
  "12004": {
    loansProduced: "5.73",
    membersServed: "413",
    interestEarned: "0.49",
    name: "NORTH CAROLINA COMMUNITY",
    website: "http://www.nccfcu.org",
  },
  "12012": {
    loansProduced: "5.54",
    membersServed: "797",
    interestEarned: "0.47",
    name: "OLD WEST",
    website: "http://oldwestfcu.com",
  },
  "12028": {
    loansProduced: "1.08",
    membersServed: "75",
    interestEarned: "0.09",
    name: "DENOCOS",
    website: "www.denocoscu.com",
  },
  "12029": {
    loansProduced: "2.08",
    membersServed: "372",
    interestEarned: "0.18",
    name: "UNITED CATHOLICS",
    website: "http://www.unitedcatholicsfcu.org",
  },
  "12033": {
    loansProduced: "2.67",
    membersServed: "389",
    interestEarned: "0.23",
    name: "PANHANDLE",
    website: "http://www.panhandlefcu.com",
  },
  "12067": {
    loansProduced: "148.80",
    membersServed: "12,956",
    interestEarned: "12.65",
    name: "BAY",
    website: "http://www.bayfed.com",
  },
  "12068": {
    loansProduced: "6.55",
    membersServed: "525",
    interestEarned: "0.56",
    name: "WALKER COUNTY",
    website: "http://www.walkercountyfcu.com",
  },
  "12082": {
    loansProduced: "9.99",
    membersServed: "797",
    interestEarned: "0.85",
    name: "RED RIVER",
    website: "http://www.redriverfcu.com",
  },
  "12084": {
    loansProduced: "57.09",
    membersServed: "5,277",
    interestEarned: "4.85",
    name: "MID OREGON",
    website: "http://www.midoregon.com",
  },
  "12143": {
    loansProduced: "18.64",
    membersServed: "1,061",
    interestEarned: "1.58",
    name: "ONE COMMUNITY",
    website: "http://www.onecommunityfcu.net",
  },
  "12152": {
    loansProduced: "2.83",
    membersServed: "197",
    interestEarned: "0.24",
    name: "CLIFTY CREEK EMPLOYEES",
    website: "http://bsdc.onlinecu.com/cliftycree",
  },
  "12153": {
    loansProduced: "15.53",
    membersServed: "1,795",
    interestEarned: "1.32",
    name: "AFENA",
    website: "https://www.afenafcu.org",
  },
  "12164": {
    loansProduced: "48.28",
    membersServed: "4,207",
    interestEarned: "4.10",
    name: "AERO",
    website: "http://www.aerofed.net",
  },
  "12170": {
    loansProduced: "1.39",
    membersServed: "118",
    interestEarned: "0.12",
    name: "LE ROY",
    website: "http://www.leroyfcu.org",
  },
  "12175": {
    loansProduced: "2.06",
    membersServed: "161",
    interestEarned: "0.18",
    name: "P S E",
    website: null,
  },
  "12181": {
    loansProduced: "15.42",
    membersServed: "1,455",
    interestEarned: "1.31",
    name: "WELLSPRING",
    website: "http://www.wellspringcu.com",
  },
  "12185": {
    loansProduced: "2.09",
    membersServed: "284",
    interestEarned: "0.18",
    name: "THIOKOL ELKTON",
    website: "http://www.thiokolfcu.org",
  },
  "12199": {
    loansProduced: "139.24",
    membersServed: "11,272",
    interestEarned: "11.84",
    name: "KIRTLAND",
    website: "www.kirtlandcu.org",
  },
  "12219": {
    loansProduced: "18.68",
    membersServed: "1,307",
    interestEarned: "1.59",
    name: "USSCO JOHNSTOWN",
    website: "http://www.usscofcu.org",
  },
  "12225": {
    loansProduced: "0.79",
    membersServed: "85",
    interestEarned: "0.07",
    name: "RAPIDES",
    website: "http://www.rapidesfederalcu.com",
  },
  "12227": {
    loansProduced: "0.56",
    membersServed: "66",
    interestEarned: "0.05",
    name: "PASSAIC POLICE",
    website: null,
  },
  "12238": {
    loansProduced: "2.98",
    membersServed: "2,086",
    interestEarned: "0.25",
    name: "FINANCIAL ACCESS",
    website: "http://www.FinancialAccessfcu.org",
  },
  "12259": {
    loansProduced: "0.79",
    membersServed: "77",
    interestEarned: "0.07",
    name: "SHERWIN",
    website: "http://www.sherwinfcu.org",
  },
  "12266": {
    loansProduced: "2.54",
    membersServed: "249",
    interestEarned: "0.22",
    name: "ELLIS COUNTY TEACHERS AND EMPLOYEES",
    website: "http://ectefcu.net",
  },
  "12271": {
    loansProduced: "14.27",
    membersServed: "1,349",
    interestEarned: "1.21",
    name: "SOUTHWEST OKLAHOMA",
    website: "http://www.swofcu.com",
  },
  "12274": {
    loansProduced: "0.69",
    membersServed: "49",
    interestEarned: "0.06",
    name: "PLAINS",
    website: "http://www.plainsfcu.com",
  },
  "12289": {
    loansProduced: "0.16",
    membersServed: "43",
    interestEarned: "0.01",
    name: "LITTLEFIELD SCHOOL EMPLOYEES",
    website: null,
  },
  "12290": {
    loansProduced: "1.45",
    membersServed: "140",
    interestEarned: "0.12",
    name: "VALDOSTA TEACHERS",
    website: "http://www.valdostateachersfcu.org",
  },
  "12291": {
    loansProduced: "3.13",
    membersServed: "301",
    interestEarned: "0.27",
    name: "GEORGIA POWER VALDOSTA",
    website: "http://www.gpvfcu.com",
  },
  "12292": {
    loansProduced: "2.38",
    membersServed: "232",
    interestEarned: "0.20",
    name: "BARTON PLANT EMPLOYEES",
    website: "http://www.bartonfcu.com",
  },
  "12305": {
    loansProduced: "5.88",
    membersServed: "534",
    interestEarned: "0.50",
    name: "FAIRFIELD",
    website: "http://www.fairfieldcreditunion.org",
  },
  "12310": {
    loansProduced: "0.85",
    membersServed: "65",
    interestEarned: "0.07",
    name: "FAYETTE",
    website: "http://fayettefcu.virtualcu.net",
  },
  "12319": {
    loansProduced: "7.71",
    membersServed: "792",
    interestEarned: "0.66",
    name: "OMAHA POLICE",
    website: "http://opfcu.net",
  },
  "12330": {
    loansProduced: "9.87",
    membersServed: "553",
    interestEarned: "0.84",
    name: "WYMAR",
    website: "http://www.wymarfcu.com",
  },
  "12333": {
    loansProduced: "0.90",
    membersServed: "83",
    interestEarned: "0.08",
    name: "U. F. C. W. LOCAL #72",
    website: "http://www.ufcwlocal72fcu.com",
  },
  "12334": {
    loansProduced: "7.28",
    membersServed: "1,239",
    interestEarned: "0.62",
    name: "NESC",
    website: "http://www.nescfcu.org",
  },
  "12351": {
    loansProduced: "3.14",
    membersServed: "582",
    interestEarned: "0.27",
    name: "PORT CONNEAUT",
    website: "http://www.portconnfcu.com",
  },
  "12354": {
    loansProduced: "3.38",
    membersServed: "342",
    interestEarned: "0.29",
    name: "WYO CENTRAL",
    website: "http://www.wyocentral.org",
  },
  "12356": {
    loansProduced: "0.76",
    membersServed: "150",
    interestEarned: "0.06",
    name: "IBERVILLE",
    website: "http://www.ibervillefcu.org",
  },
  "12361": {
    loansProduced: "42.82",
    membersServed: "4,278",
    interestEarned: "3.64",
    name: "CAROLINA TRUST",
    website: "http://carolinatrust.org",
  },
  "12365": {
    loansProduced: "1.40",
    membersServed: "104",
    interestEarned: "0.12",
    name: "REFUGIO COUNTY",
    website: "http://www.refugiocountyfcu.com",
  },
  "12375": {
    loansProduced: "0.11",
    membersServed: "19",
    interestEarned: "0.01",
    name: "NORWALK POSTAL EMPLOYEES",
    website: null,
  },
  "12383": {
    loansProduced: "1.83",
    membersServed: "206",
    interestEarned: "0.16",
    name: "EAST ALABAMA COMMUNITY",
    website: "http://www.eamcfcu.com",
  },
  "12425": {
    loansProduced: "41.82",
    membersServed: "5,323",
    interestEarned: "3.55",
    name: "FOOTHILL",
    website: "http://www.foothillcu.org",
  },
  "12438": {
    loansProduced: "7.50",
    membersServed: "1,105",
    interestEarned: "0.64",
    name: "ARMSTRONG ASSOCIATES",
    website: "http://www.aafcu4u.coop",
  },
  "12440": {
    loansProduced: "0.44",
    membersServed: "44",
    interestEarned: "0.04",
    name: "MASTERS, MATES & PILOTS (MM&P)",
    website: "http://creditunion.bridgedeck.org",
  },
  "12443": {
    loansProduced: "86.90",
    membersServed: "6,088",
    interestEarned: "7.39",
    name: "DOVER",
    website: "http://doverfcu.com",
  },
  "12458": {
    loansProduced: "49.25",
    membersServed: "5,271",
    interestEarned: "4.19",
    name: "VELOCITY COMMUNITY",
    website: "http://www.velocitycommunity.org",
  },
  "12470": {
    loansProduced: "9.88",
    membersServed: "890",
    interestEarned: "0.84",
    name: "MOUNTAIN HERITAGE",
    website: "http://www.mhfcu.net",
  },
  "12472": {
    loansProduced: "3.77",
    membersServed: "306",
    interestEarned: "0.32",
    name: "LAREDO FIRE DEPARTMENT",
    website: "http://WWW.laredofirecreditunion.co",
  },
  "12473": {
    loansProduced: "25.09",
    membersServed: "2,085",
    interestEarned: "2.13",
    name: "DANNEMORA",
    website: "http://dfcu.net",
  },
  "12478": {
    loansProduced: "5.62",
    membersServed: "382",
    interestEarned: "0.48",
    name: "ELECTRICAL WORKERS NO 558",
    website: "http://www.ew558fcu.com",
  },
  "12505": {
    loansProduced: "0.29",
    membersServed: "25",
    interestEarned: "0.02",
    name: "FORT LIGONIER",
    website: "http://www.fortligonierfcu.com",
  },
  "12508": {
    loansProduced: "1.24",
    membersServed: "77",
    interestEarned: "0.11",
    name: "SANTA MARIA ASSOCIATED EMPLOYEES",
    website: "http://smaefcu.org",
  },
  "12527": {
    loansProduced: "0.03",
    membersServed: "17",
    interestEarned: "0.00",
    name: "OUACHITA VALLEY HEALTH SYSTEM",
    website: null,
  },
  "12543": {
    loansProduced: "6.98",
    membersServed: "623",
    interestEarned: "0.59",
    name: "TORRANCE COMMUNITY",
    website: "http://www.torranceccu.org",
  },
  "12546": {
    loansProduced: "0.40",
    membersServed: "63",
    interestEarned: "0.03",
    name: "MEDICAL EMPLOYEES OF STATEN ISLAND",
    website: null,
  },
  "12570": {
    loansProduced: "4.89",
    membersServed: "684",
    interestEarned: "0.42",
    name: "UNIVERSITY OF LOUISIANA",
    website: "http://ulfcu.com",
  },
  "12590": {
    loansProduced: "1.63",
    membersServed: "223",
    interestEarned: "0.14",
    name: "LANECO",
    website: "http://www.laneco.org",
  },
  "12601": {
    loansProduced: "6.66",
    membersServed: "740",
    interestEarned: "0.57",
    name: "PINE",
    website: "http://www.pinefcu.com",
  },
  "12604": {
    loansProduced: "6.85",
    membersServed: "987",
    interestEarned: "0.58",
    name: "FRANKLIN FIRST",
    website: "http://www.franklinfirst.org",
  },
  "12608": {
    loansProduced: "0.12",
    membersServed: "22",
    interestEarned: "0.01",
    name: "OLATHE",
    website: null,
  },
  "12613": {
    loansProduced: "0.13",
    membersServed: "7",
    interestEarned: "0.01",
    name: "GLOVER",
    website: null,
  },
  "12616": {
    loansProduced: "2.09",
    membersServed: "455",
    interestEarned: "0.18",
    name: "ERIE COMMUNITY",
    website: "http://www.eriecommunityfcu.org",
  },
  "12619": {
    loansProduced: "0.24",
    membersServed: "18",
    interestEarned: "0.02",
    name: "PUBLIC SERVICE EDWARDSPT PL E",
    website: "https://psepefcu.cmycu.com/",
  },
  "12624": {
    loansProduced: "3.75",
    membersServed: "349",
    interestEarned: "0.32",
    name: "ROME TEACHERS",
    website: "http://www.rtfcu.com",
  },
  "12643": {
    loansProduced: "8.31",
    membersServed: "1,696",
    interestEarned: "0.71",
    name: "CORRY",
    website: "http://www.corryfcu.org",
  },
  "12648": {
    loansProduced: "2.83",
    membersServed: "284",
    interestEarned: "0.24",
    name: "BLUEGRASS COMMUNITY",
    website: "http://www.bluegrasscreditunion.com",
  },
  "12654": {
    loansProduced: "5.31",
    membersServed: "531",
    interestEarned: "0.45",
    name: "SRU",
    website: "http://www.srufcu.org",
  },
  "12666": {
    loansProduced: "2.46",
    membersServed: "451",
    interestEarned: "0.21",
    name: "MILLS42",
    website: "http://www.mills42fcu.com",
  },
  "12671": {
    loansProduced: "1.77",
    membersServed: "110",
    interestEarned: "0.15",
    name: "COOPERS CAVE",
    website: "http://cooperscavefcu.com",
  },
  "12673": {
    loansProduced: "2.22",
    membersServed: "137",
    interestEarned: "0.19",
    name: "UNITED HOSPITAL CENTER",
    website: "http://www.uhcfcu.virtualcu.net",
  },
  "12679": {
    loansProduced: "16.32",
    membersServed: "2,642",
    interestEarned: "1.39",
    name: "ABCO",
    website: "http://www.abcofcu.org",
  },
  "12686": {
    loansProduced: "2.14",
    membersServed: "154",
    interestEarned: "0.18",
    name: "PAHRANAGAT VALLEY",
    website: "http://www.pvfcu.net",
  },
  "12697": {
    loansProduced: "84.19",
    membersServed: "5,774",
    interestEarned: "7.16",
    name: "COMPLEX COMMUNITY",
    website: "http://www.comcfcu.com/",
  },
  "12729": {
    loansProduced: "10.16",
    membersServed: "1,264",
    interestEarned: "0.86",
    name: "PENN STATE",
    website: "https://pennstatefederal.com",
  },
  "12735": {
    loansProduced: "0.55",
    membersServed: "124",
    interestEarned: "0.05",
    name: "W B R T",
    website: "http://www.wbrtfcu.com",
  },
  "12745": {
    loansProduced: "4.15",
    membersServed: "290",
    interestEarned: "0.35",
    name: "LOCAL 697",
    website: "https://www.697fcu.org",
  },
  "12748": {
    loansProduced: "0.22",
    membersServed: "29",
    interestEarned: "0.02",
    name: "XAVIER UNIVERSITY",
    website: "http://www.xula.edu",
  },
  "12752": {
    loansProduced: "6.45",
    membersServed: "723",
    interestEarned: "0.55",
    name: "AMERICO",
    website: "http://www.americofcu.com",
  },
  "12765": {
    loansProduced: "80.24",
    membersServed: "9,281",
    interestEarned: "6.82",
    name: "FIRST COMMONWEALTH",
    website: "http://www.firstcomcu.org",
  },
  "12771": {
    loansProduced: "0.62",
    membersServed: "144",
    interestEarned: "0.05",
    name: "PUERTO RICO EMPLOYEE GROUPS",
    website: "http://premployeesfcu.com",
  },
  "12794": {
    loansProduced: "0.68",
    membersServed: "52",
    interestEarned: "0.06",
    name: "POWELL SCHOOLS",
    website: "https://psfcupowell.com/",
  },
  "12805": {
    loansProduced: "49.10",
    membersServed: "5,298",
    interestEarned: "4.17",
    name: "MOCSE",
    website: "http://www.mocse.org",
  },
  "12808": {
    loansProduced: "23.89",
    membersServed: "1,965",
    interestEarned: "2.03",
    name: "SUCCESS",
    website: "https://www.successcreditunion.com",
  },
  "12813": {
    loansProduced: "6.37",
    membersServed: "1,058",
    interestEarned: "0.54",
    name: "BAPTIST HEALTH",
    website: "http://www.baptist-healthfcu.com",
  },
  "12814": {
    loansProduced: "15.61",
    membersServed: "1,404",
    interestEarned: "1.33",
    name: "SEA WEST COAST GUARD",
    website: "http://www.seawest.coop",
  },
  "12826": {
    loansProduced: "0.73",
    membersServed: "59",
    interestEarned: "0.06",
    name: "BASF CHATTANOOGA",
    website: "http://www.basfchattafcu.com",
  },
  "12833": {
    loansProduced: "1.12",
    membersServed: "209",
    interestEarned: "0.09",
    name: "LEXINGTON AVENUE",
    website: "http://www.lexfcu.org",
  },
  "12837": {
    loansProduced: "0.74",
    membersServed: "119",
    interestEarned: "0.06",
    name: "MARVEL CITY",
    website: "http://www.marvelcityfcu.com",
  },
  "12847": {
    loansProduced: "1.73",
    membersServed: "781",
    interestEarned: "0.15",
    name: "COMMUNITY HEALTHCARE",
    website: "http://www.Commhealthcu.org",
  },
  "12852": {
    loansProduced: "5.84",
    membersServed: "370",
    interestEarned: "0.50",
    name: "GREEN RIVER AREA",
    website: "https://www.greenriverafcu.com",
  },
  "12858": {
    loansProduced: "60.48",
    membersServed: "3,669",
    interestEarned: "5.14",
    name: "TEXAS TECH",
    website: "https://www.texastechfcu.org",
  },
  "12859": {
    loansProduced: "0.48",
    membersServed: "31",
    interestEarned: "0.04",
    name: "FAITH COOPERATIVE",
    website: "http://www.faithcfcu.com/",
  },
  "12873": {
    loansProduced: "1.66",
    membersServed: "166",
    interestEarned: "0.14",
    name: "PLATTSBURGH CITY SCHOOL DISTRICT",
    website: "http://www.plattscsdfcu.org",
  },
  "12877": {
    loansProduced: "8.30",
    membersServed: "1,142",
    interestEarned: "0.71",
    name: "SECURED ADVANTAGE",
    website: "http://www.securedadvantagefcu.com",
  },
  "12890": {
    loansProduced: "5.12",
    membersServed: "734",
    interestEarned: "0.44",
    name: "RIVERWAYS",
    website: "http://www.riverwaysfcu.com",
  },
  "12892": {
    loansProduced: "4.64",
    membersServed: "782",
    interestEarned: "0.39",
    name: "BCM",
    website: "http://www.bcmfcu.com",
  },
  "12898": {
    loansProduced: "2.18",
    membersServed: "187",
    interestEarned: "0.19",
    name: "FONTANA",
    website: "http://www.fontanafcu.org",
  },
  "12900": {
    loansProduced: "0.75",
    membersServed: "67",
    interestEarned: "0.06",
    name: "KENNAFORD",
    website: null,
  },
  "12906": {
    loansProduced: "0.83",
    membersServed: "126",
    interestEarned: "0.07",
    name: "BLUE WATER",
    website: "http://bluewaterfcu.com",
  },
  "12912": {
    loansProduced: "1.36",
    membersServed: "97",
    interestEarned: "0.12",
    name: "CIRCUIT",
    website: "http://WWW.CHEEKTOWAGACOMMUNITYFCU.",
  },
  "12922": {
    loansProduced: "2.30",
    membersServed: "151",
    interestEarned: "0.20",
    name: "CHARLESTON",
    website: "http://cfcuwv.virtualcu.net",
  },
  "12935": {
    loansProduced: "9.36",
    membersServed: "1,183",
    interestEarned: "0.80",
    name: "DISCOVERY",
    website: "http://www.discoveryfcu.org",
  },
  "12938": {
    loansProduced: "5.19",
    membersServed: "263",
    interestEarned: "0.44",
    name: "WV NATIONAL GUARD",
    website: "http://wvngfcu.com",
  },
  "12944": {
    loansProduced: "1.49",
    membersServed: "194",
    interestEarned: "0.13",
    name: "ST. BERNARD PARISH SCHOOL BOARD EMP",
    website: null,
  },
  "12956": {
    loansProduced: "1.54",
    membersServed: "179",
    interestEarned: "0.13",
    name: "PEOPLES TRANSPORT",
    website: "http://Peoplestransportfcu.com",
  },
  "12957": {
    loansProduced: "26.10",
    membersServed: "3,695",
    interestEarned: "2.22",
    name: "TCT",
    website: "https://www.tctfcu.org",
  },
  "12963": {
    loansProduced: "1.36",
    membersServed: "107",
    interestEarned: "0.12",
    name: "N.H. COMMUNITY",
    website: "http://www.nhcommunityfcu.org",
  },
  "12977": {
    loansProduced: "109.44",
    membersServed: "11,576",
    interestEarned: "9.30",
    name: "MARINE",
    website: "http://marinefederalhb.org",
  },
  "12980": {
    loansProduced: "4.17",
    membersServed: "415",
    interestEarned: "0.35",
    name: "AFFINITY FIRST",
    website: "http://www.affinityfcund.com",
  },
  "13028": {
    loansProduced: "2.59",
    membersServed: "697",
    interestEarned: "0.22",
    name: "167TH TFR",
    website: "http://www.167tfrfcu.com",
  },
  "13035": {
    loansProduced: "2.52",
    membersServed: "489",
    interestEarned: "0.21",
    name: "WASHINGTON AREA TEACHERS",
    website: "http://www.watfcu.org",
  },
  "13044": {
    loansProduced: "3.61",
    membersServed: "1,152",
    interestEarned: "0.31",
    name: "ONTARIO PUBLIC EMPLOYEES",
    website: "http://www.opefcu.org",
  },
  "13046": {
    loansProduced: "0.09",
    membersServed: "17",
    interestEarned: "0.01",
    name: "SPRINGDALE P. P. G.",
    website: null,
  },
  "13062": {
    loansProduced: "0.16",
    membersServed: "32",
    interestEarned: "0.01",
    name: "B. P. S.",
    website: null,
  },
  "13067": {
    loansProduced: "3.20",
    membersServed: "326",
    interestEarned: "0.27",
    name: "RIVERFORK",
    website: "http://riverforkfcu.com",
  },
  "13102": {
    loansProduced: "1.37",
    membersServed: "266",
    interestEarned: "0.12",
    name: "ONEIDA COUNTY",
    website: "http://www.oneidacfcu.org",
  },
  "13103": {
    loansProduced: "1.69",
    membersServed: "144",
    interestEarned: "0.14",
    name: "PALACE CITY",
    website: "http://www.palacecitycu.org",
  },
  "13107": {
    loansProduced: "2.22",
    membersServed: "278",
    interestEarned: "0.19",
    name: "UKRAINIAN SELFRELIANCE NEW ENGLAND",
    website: "http://WWW.USNEFCU.COM",
  },
  "13115": {
    loansProduced: "1.65",
    membersServed: "122",
    interestEarned: "0.14",
    name: "FASSON EMPLOYEES",
    website: "http://www.fassoncreditunion.com",
  },
  "13121": {
    loansProduced: "15.11",
    membersServed: "1,469",
    interestEarned: "1.28",
    name: "TIDEMARK",
    website: "http://www.tidemarkfcu.org",
  },
  "13131": {
    loansProduced: "11.39",
    membersServed: "2,039",
    interestEarned: "0.97",
    name: "MISSION CITY",
    website: "http://www.missioncityfcu.org",
  },
  "13132": {
    loansProduced: "2.83",
    membersServed: "230",
    interestEarned: "0.24",
    name: "U. S. I.",
    website: "http://usifcu.com",
  },
  "13161": {
    loansProduced: "1.19",
    membersServed: "121",
    interestEarned: "0.10",
    name: "RIO BLANCO SCHOOLS",
    website: "http://www.rioblancoschoolsfcu.org",
  },
  "13167": {
    loansProduced: "4.16",
    membersServed: "611",
    interestEarned: "0.35",
    name: "FLORENCE",
    website: "http://ffcuonline.com",
  },
  "13180": {
    loansProduced: "0.36",
    membersServed: "41",
    interestEarned: "0.03",
    name: "HEMPFIELD AREA",
    website: "hempfieldareafcu@gmail.com",
  },
  "13186": {
    loansProduced: "3.17",
    membersServed: "234",
    interestEarned: "0.27",
    name: "ED-MED",
    website: "http://EDMEDFCU.COM",
  },
  "13190": {
    loansProduced: "8.44",
    membersServed: "1,016",
    interestEarned: "0.72",
    name: "LISBON COMMUNITY",
    website: "http://www.lisboncu.org",
  },
  "13224": {
    loansProduced: "0.32",
    membersServed: "22",
    interestEarned: "0.03",
    name: "NORTH SANPETE",
    website: null,
  },
  "13233": {
    loansProduced: "5.40",
    membersServed: "495",
    interestEarned: "0.46",
    name: "FEDTRUST",
    website: "http://www.fedtrustfcu.com",
  },
  "13242": {
    loansProduced: "537.50",
    membersServed: "39,908",
    interestEarned: "45.69",
    name: "CHARTWAY",
    website: "https://www.chartway.com",
  },
  "13248": {
    loansProduced: "0.54",
    membersServed: "74",
    interestEarned: "0.05",
    name: "S H P E",
    website: "http://sshpecu.org",
  },
  "13254": {
    loansProduced: "6.19",
    membersServed: "503",
    interestEarned: "0.53",
    name: "CORRECTIONS",
    website: "http://www.CORFED.com",
  },
  "13261": {
    loansProduced: "2.19",
    membersServed: "317",
    interestEarned: "0.19",
    name: "ANTIOCH COMMUNITY",
    website: "http://www.antiochcommfcu.org",
  },
  "13265": {
    loansProduced: "7.83",
    membersServed: "505",
    interestEarned: "0.67",
    name: "MOKELUMNE",
    website: "http://www.mokelumnefcu.com",
  },
  "13271": {
    loansProduced: "17.02",
    membersServed: "2,516",
    interestEarned: "1.45",
    name: "JEFFERSON PARISH EMPLOYEES",
    website: "http://www.jpefcu.org",
  },
  "13274": {
    loansProduced: "0.34",
    membersServed: "40",
    interestEarned: "0.03",
    name: "NORTH ADAMS M E",
    website: "http://Namefed.webs.com",
  },
  "13279": {
    loansProduced: "5.19",
    membersServed: "635",
    interestEarned: "0.44",
    name: "SOUTH BEND FIREFIGHTERS",
    website: "http://sbfcu.org",
  },
  "13290": {
    loansProduced: "19.33",
    membersServed: "984",
    interestEarned: "1.64",
    name: "FIRST WATCH",
    website: "www.myfwcu.org",
  },
  "13300": {
    loansProduced: "0.79",
    membersServed: "152",
    interestEarned: "0.07",
    name: "SCHOOL DISTRICT 3",
    website: "http://www.sd3fcu.org",
  },
  "13305": {
    loansProduced: "2.09",
    membersServed: "381",
    interestEarned: "0.18",
    name: "LAWRENCE MEMORIAL HOSPITAL EMPLOYEE",
    website: "http://www.lmhospcu.com",
  },
  "13321": {
    loansProduced: "4.87",
    membersServed: "548",
    interestEarned: "0.41",
    name: "RAVENSWOOD",
    website: "http://www.rfcu.net",
  },
  "13329": {
    loansProduced: "2.15",
    membersServed: "191",
    interestEarned: "0.18",
    name: "BALDWIN COUNTY",
    website: "http://www.baldwincountyfcu.com",
  },
  "13334": {
    loansProduced: "10.33",
    membersServed: "1,370",
    interestEarned: "0.88",
    name: "TRIANGLE",
    website: "http://www.trianglefcu.com",
  },
  "13345": {
    loansProduced: "30.96",
    membersServed: "3,050",
    interestEarned: "2.63",
    name: "FIRST PEOPLES COMMUNITY",
    website: "http://www.firstpeoples.com",
  },
  "13355": {
    loansProduced: "2.18",
    membersServed: "385",
    interestEarned: "0.19",
    name: "FRANKFORT COMMUNITY",
    website: "http://frankfortcu.org",
  },
  "13367": {
    loansProduced: "0.26",
    membersServed: "21",
    interestEarned: "0.02",
    name: "AKRON SCHOOL EMPLOYEES",
    website: "http://www.asefcu.net",
  },
  "13391": {
    loansProduced: "2.26",
    membersServed: "269",
    interestEarned: "0.19",
    name: "FLAGSHIP COMMUNITY",
    website: "www.flagship.org",
  },
  "13392": {
    loansProduced: "101.91",
    membersServed: "11,060",
    interestEarned: "8.66",
    name: "STANFORD",
    website: "https://www.sfcu.org",
  },
  "13402": {
    loansProduced: "3.22",
    membersServed: "341",
    interestEarned: "0.27",
    name: "ESCONDIDO",
    website: "http://www.escondidofcu.com",
  },
  "13416": {
    loansProduced: "3.51",
    membersServed: "453",
    interestEarned: "0.30",
    name: "SUNSET SCIENCE PARK",
    website: "http://www.sspfcu.com",
  },
  "13426": {
    loansProduced: "1.26",
    membersServed: "105",
    interestEarned: "0.11",
    name: "CITY OF CLARKSBURG",
    website: null,
  },
  "13432": {
    loansProduced: "8.25",
    membersServed: "818",
    interestEarned: "0.70",
    name: "STATE HIGHWAY PATROL",
    website: "http://www.patrolcu.com",
  },
  "13439": {
    loansProduced: "0.45",
    membersServed: "196",
    interestEarned: "0.04",
    name: "NOVA UA",
    website: "http://www.novafcu.com",
  },
  "13456": {
    loansProduced: "3.01",
    membersServed: "1,843",
    interestEarned: "0.26",
    name: "METHODIST HEALTHCARE",
    website: "http://www.methodistcu.org",
  },
  "13458": {
    loansProduced: "7.00",
    membersServed: "1,531",
    interestEarned: "0.59",
    name: "NE PA COMMUNITY",
    website: "http://www.nepafcu.org",
  },
  "13469": {
    loansProduced: "1.61",
    membersServed: "139",
    interestEarned: "0.14",
    name: "PENNTECH EMPLOYEES",
    website: "http://penntechfcu.com",
  },
  "13472": {
    loansProduced: "1.56",
    membersServed: "300",
    interestEarned: "0.13",
    name: "BERKELEY COMMUNITY",
    website: "http://www.berkcofcu.com",
  },
  "13476": {
    loansProduced: "16.40",
    membersServed: "2,380",
    interestEarned: "1.39",
    name: "CAROLINA FOOTHILLS",
    website: "http://www.carolinafoothillsfcu.coo",
  },
  "13481": {
    loansProduced: "0.45",
    membersServed: "41",
    interestEarned: "0.04",
    name: "WESTERN SPRINGS",
    website: "http://www.wsfcunion.com",
  },
  "13492": {
    loansProduced: "4.08",
    membersServed: "1,216",
    interestEarned: "0.35",
    name: "VISION FINANCIAL",
    website: "http://www.vffcu.org",
  },
  "13495": {
    loansProduced: "7.06",
    membersServed: "2,286",
    interestEarned: "0.60",
    name: "COUNTY SCHOOLS",
    website: "http://csfcu.org",
  },
  "13503": {
    loansProduced: "0.67",
    membersServed: "127",
    interestEarned: "0.06",
    name: "CAL-ED",
    website: "http://www.cal-ed.com",
  },
  "13516": {
    loansProduced: "0.90",
    membersServed: "87",
    interestEarned: "0.08",
    name: "LOCOGA",
    website: "http://www.locogafcu.com",
  },
  "13519": {
    loansProduced: "0.19",
    membersServed: "54",
    interestEarned: "0.02",
    name: "UNITED INVESTORS",
    website: null,
  },
  "13526": {
    loansProduced: "1.57",
    membersServed: "189",
    interestEarned: "0.13",
    name: "EMPLOYEES CHOICE",
    website: "http://www.employeeschoice.org",
  },
  "13533": {
    loansProduced: "0.16",
    membersServed: "43",
    interestEarned: "0.01",
    name: "CTA SOUTH",
    website: null,
  },
  "13534": {
    loansProduced: "15.29",
    membersServed: "1,432",
    interestEarned: "1.30",
    name: "OKALOOSA COUNTY TEACHERS",
    website: "http://okaloosafcu.org",
  },
  "13544": {
    loansProduced: "0.93",
    membersServed: "101",
    interestEarned: "0.08",
    name: "POLY SCIENTIFIC EMPLOYEES",
    website: null,
  },
  "13559": {
    loansProduced: "0.07",
    membersServed: "35",
    interestEarned: "0.01",
    name: "NOTEWORTHY",
    website: "http://www.noteworthyfcu.com",
  },
  "13583": {
    loansProduced: "15.78",
    membersServed: "845",
    interestEarned: "1.34",
    name: "SPIRIT OF ALASKA",
    website: "http://www.spiritofak.com",
  },
  "13591": {
    loansProduced: "11.09",
    membersServed: "611",
    interestEarned: "0.94",
    name: "CHEROKEE COUNTY",
    website: "http://www.cherokeecountyfcu.com",
  },
  "13599": {
    loansProduced: "0.35",
    membersServed: "18",
    interestEarned: "0.03",
    name: "FLEXPAK",
    website: null,
  },
  "13601": {
    loansProduced: "8.64",
    membersServed: "1,070",
    interestEarned: "0.73",
    name: "SHREWSBURY",
    website: "http://WWW.SHREWSBURYCU.COM",
  },
  "13602": {
    loansProduced: "3.50",
    membersServed: "373",
    interestEarned: "0.30",
    name: "MANCHESTER MUNICIPAL",
    website: "http://www.mmfcu.net",
  },
  "13605": {
    loansProduced: "108.98",
    membersServed: "9,291",
    interestEarned: "9.26",
    name: "ABNB",
    website: "http://www.abnbfcu.org",
  },
  "13616": {
    loansProduced: "14.30",
    membersServed: "1,132",
    interestEarned: "1.22",
    name: "INDIANA STATE UNIVERSITY",
    website: "http://www.isucreditunion.com",
  },
  "13634": {
    loansProduced: "0.73",
    membersServed: "87",
    interestEarned: "0.06",
    name: "KELLOGG MEMPHIS EMPLOYEES",
    website: null,
  },
  "13649": {
    loansProduced: "21.46",
    membersServed: "1,739",
    interestEarned: "1.82",
    name: "IRONWORKERS USA",
    website: "http://www.ironworkersfcu.org",
  },
  "13654": {
    loansProduced: "0.18",
    membersServed: "18",
    interestEarned: "0.02",
    name: "MT TAYLOR",
    website: null,
  },
  "13655": {
    loansProduced: "1.43",
    membersServed: "116",
    interestEarned: "0.12",
    name: "ALBA-GOLDEN",
    website: "http://www.albagoldenfcu.com",
  },
  "13659": {
    loansProduced: "7.81",
    membersServed: "733",
    interestEarned: "0.66",
    name: "UNIVERSITY OF SOUTH ALABAMA",
    website: "http://www.usafedcu.com",
  },
  "13672": {
    loansProduced: "0.89",
    membersServed: "93",
    interestEarned: "0.08",
    name: "VICTORIA CITY-COUNTY EMPLOYEES",
    website: "http://vccefcu.org",
  },
  "13682": {
    loansProduced: "11.26",
    membersServed: "1,069",
    interestEarned: "0.96",
    name: "BREWER",
    website: "http://brewerfcu.org",
  },
  "13687": {
    loansProduced: "2.84",
    membersServed: "304",
    interestEarned: "0.24",
    name: "FELICIANA",
    website: "http://www.felicianafcu.com",
  },
  "13690": {
    loansProduced: "68.13",
    membersServed: "5,477",
    interestEarned: "5.79",
    name: "FORT BRAGG",
    website: "http://www.myfortlibertyfcu.org",
  },
  "13704": {
    loansProduced: "3.05",
    membersServed: "772",
    interestEarned: "0.26",
    name: "FAITH CONNECTION",
    website: "http://www.unitedmethodistcu.com",
  },
  "13729": {
    loansProduced: "2.41",
    membersServed: "140",
    interestEarned: "0.21",
    name: "ENERGY PEOPLE",
    website: "http://energypeoplefcu.com",
  },
  "13733": {
    loansProduced: "4.56",
    membersServed: "490",
    interestEarned: "0.39",
    name: "GLASS CAP",
    website: "http://www.glasscapfcu.com",
  },
  "13737": {
    loansProduced: "15.80",
    membersServed: "1,919",
    interestEarned: "1.34",
    name: "ARKANSAS BEST",
    website: "http://www.abfcu.org",
  },
  "13741": {
    loansProduced: "29.69",
    membersServed: "2,974",
    interestEarned: "2.52",
    name: "BEEHIVE",
    website: "http://www.beehive.org",
  },
  "13760": {
    loansProduced: "4.65",
    membersServed: "375",
    interestEarned: "0.40",
    name: "PENNSTAR",
    website: "http://pennstarfederal.com",
  },
  "13762": {
    loansProduced: "7.35",
    membersServed: "956",
    interestEarned: "0.62",
    name: "DIAMOND LAKES",
    website: "http://www.diamondlakesfcu.org",
  },
  "13763": {
    loansProduced: "2.02",
    membersServed: "157",
    interestEarned: "0.17",
    name: "RIVER REGION",
    website: "http://riverregionfcu.org",
  },
  "13766": {
    loansProduced: "1.78",
    membersServed: "283",
    interestEarned: "0.15",
    name: "M. C. T.",
    website: "http://mct-fcu.org",
  },
  "13777": {
    loansProduced: "0.54",
    membersServed: "187",
    interestEarned: "0.05",
    name: "LEFORS",
    website: null,
  },
  "13790": {
    loansProduced: "3.28",
    membersServed: "366",
    interestEarned: "0.28",
    name: "DESERTVIEW",
    website: "http://www.dview.org",
  },
  "13791": {
    loansProduced: "8.09",
    membersServed: "911",
    interestEarned: "0.69",
    name: "DELAWARE STATE POLICE",
    website: "https://www.dspfcu.com",
  },
  "13794": {
    loansProduced: "10.58",
    membersServed: "1,026",
    interestEarned: "0.90",
    name: "MEDINA COUNTY",
    website: "http://www.medfed.com",
  },
  "13800": {
    loansProduced: "16.86",
    membersServed: "910",
    interestEarned: "1.43",
    name: "ELKO",
    website: "http://www.elkofcu.org",
  },
  "13802": {
    loansProduced: "1.29",
    membersServed: "198",
    interestEarned: "0.11",
    name: "K G C",
    website: "http://kgcfcu.com",
  },
  "13814": {
    loansProduced: "1.32",
    membersServed: "114",
    interestEarned: "0.11",
    name: "ACUSHNET",
    website: "http://www.acushnetfcu.com",
  },
  "13821": {
    loansProduced: "0.08",
    membersServed: "8",
    interestEarned: "0.01",
    name: "B V M S N",
    website: null,
  },
  "13823": {
    loansProduced: "1.19",
    membersServed: "142",
    interestEarned: "0.10",
    name: "BRADFORD AREA",
    website: "http://bradfordareafcu.org",
  },
  "13828": {
    loansProduced: "142.58",
    membersServed: "12,779",
    interestEarned: "12.12",
    name: "ALLSOUTH",
    website: "https://www.allsouth.org",
  },
  "13833": {
    loansProduced: "1.20",
    membersServed: "146",
    interestEarned: "0.10",
    name: "C U P",
    website: "https://cupfcu.com",
  },
  "13842": {
    loansProduced: "3.73",
    membersServed: "282",
    interestEarned: "0.32",
    name: "WESTMINSTER",
    website: "http://www.wfcu.com",
  },
  "13852": {
    loansProduced: "7.82",
    membersServed: "1,361",
    interestEarned: "0.66",
    name: "THE BRIDGEWAY",
    website: "http://www.bridgewaycu.org",
  },
  "13857": {
    loansProduced: "1.01",
    membersServed: "57",
    interestEarned: "0.09",
    name: "LONGSHORE",
    website: "http://www.longshorefcu.com",
  },
  "13873": {
    loansProduced: "2.13",
    membersServed: "169",
    interestEarned: "0.18",
    name: "BROWNFIELD",
    website: "http://brownfieldfcu.com",
  },
  "13885": {
    loansProduced: "6.78",
    membersServed: "854",
    interestEarned: "0.58",
    name: "L C E",
    website: "http://www.lcefcu.org",
  },
  "13898": {
    loansProduced: "28.27",
    membersServed: "1,733",
    interestEarned: "2.40",
    name: "ENDURANCE",
    website: "http://www.endurancefcu.org",
  },
  "13902": {
    loansProduced: "13.66",
    membersServed: "6,371",
    interestEarned: "1.16",
    name: "FICARE",
    website: "http://www.ficarefcu.org",
  },
  "13919": {
    loansProduced: "79.90",
    membersServed: "10,193",
    interestEarned: "6.79",
    name: "DEL-ONE",
    website: "http://www.del-one.org",
  },
  "13926": {
    loansProduced: "4.74",
    membersServed: "658",
    interestEarned: "0.40",
    name: "TEWKSBURY",
    website: "http://www.tewksburyfcu.com",
  },
  "13933": {
    loansProduced: "0.82",
    membersServed: "144",
    interestEarned: "0.07",
    name: "SAINT VINCENT ERIE",
    website: "http://www.svefcu.com",
  },
  "13938": {
    loansProduced: "0.45",
    membersServed: "75",
    interestEarned: "0.04",
    name: "CROUSE HINDS EMPLOYEES",
    website: null,
  },
  "13939": {
    loansProduced: "37.78",
    membersServed: "3,004",
    interestEarned: "3.21",
    name: "PUERTO RICO",
    website: "http://www.prfedcu.com",
  },
  "13940": {
    loansProduced: "4.19",
    membersServed: "367",
    interestEarned: "0.36",
    name: "BUSINESS AND INDUSTRIAL",
    website: "http://www.busindcu.com",
  },
  "13941": {
    loansProduced: "1.34",
    membersServed: "67",
    interestEarned: "0.11",
    name: "SM",
    website: "http://WWW.SMFEDERAL.ORG",
  },
  "13944": {
    loansProduced: "6.90",
    membersServed: "2,501",
    interestEarned: "0.59",
    name: "RIEGEL",
    website: "http://www.riegelfcu.org",
  },
  "13959": {
    loansProduced: "6.95",
    membersServed: "949",
    interestEarned: "0.59",
    name: "MIDWEST CARPENTERS & MILLWRIGHTS",
    website: "http://www.mcmfcu.com",
  },
  "13965": {
    loansProduced: "0.62",
    membersServed: "56",
    interestEarned: "0.05",
    name: "OREM CITY EMPLOYEES",
    website: "http://cu.orem.org",
  },
  "13966": {
    loansProduced: "2.80",
    membersServed: "251",
    interestEarned: "0.24",
    name: "CHADRON",
    website: "http://www.chadronfcu.org",
  },
  "13974": {
    loansProduced: "1.53",
    membersServed: "128",
    interestEarned: "0.13",
    name: "GESB SHEET METAL WORKERS",
    website: "http://www.gesbfcu.org",
  },
  "14003": {
    loansProduced: "6.69",
    membersServed: "770",
    interestEarned: "0.57",
    name: "TORRINGTON MUNICIPAL AND TEACHERS",
    website: "http://tmtfcu.org",
  },
  "14011": {
    loansProduced: "0.31",
    membersServed: "42",
    interestEarned: "0.03",
    name: "BERRIEN TEACHERS",
    website: null,
  },
  "14015": {
    loansProduced: "3.09",
    membersServed: "409",
    interestEarned: "0.26",
    name: "GARDEN STATE",
    website: "http://GARDENSTATEFCU.ORG",
  },
  "14016": {
    loansProduced: "1.82",
    membersServed: "109",
    interestEarned: "0.15",
    name: "INLAND",
    website: "http://www.inlandfederal.org",
  },
  "14018": {
    loansProduced: "2.88",
    membersServed: "281",
    interestEarned: "0.24",
    name: "VISIONARY",
    website: "http://www.visionaryfcu.org",
  },
  "14026": {
    loansProduced: "0.98",
    membersServed: "137",
    interestEarned: "0.08",
    name: "FEDMONT",
    website: "www.FedmontFCU.com",
  },
  "14030": {
    loansProduced: "1.60",
    membersServed: "193",
    interestEarned: "0.14",
    name: "IBEW 317",
    website: "ibew317fcu.com",
  },
  "14040": {
    loansProduced: "1.75",
    membersServed: "260",
    interestEarned: "0.15",
    name: "NIAGARA-WHEATFIELD",
    website: "http://www.nwfcuanytime.com",
  },
  "14052": {
    loansProduced: "2.89",
    membersServed: "244",
    interestEarned: "0.25",
    name: "LIBERTY COUNTY TEACHERS",
    website: "http://www.libertycu.com",
  },
  "14058": {
    loansProduced: "0.02",
    membersServed: "10",
    interestEarned: "0.00",
    name: "ST. MARTIN DE PORRES PARISH",
    website: null,
  },
  "14066": {
    loansProduced: "1.58",
    membersServed: "124",
    interestEarned: "0.13",
    name: "FIRESTONE",
    website: "http://www.fofcu.com",
  },
  "14074": {
    loansProduced: "14.69",
    membersServed: "1,105",
    interestEarned: "1.25",
    name: "CITYMARK",
    website: "http://www.citymark.org",
  },
  "14081": {
    loansProduced: "0.42",
    membersServed: "41",
    interestEarned: "0.04",
    name: "PURITY DAIRIES EMPLOYEES",
    website: null,
  },
  "14091": {
    loansProduced: "92.09",
    membersServed: "6,415",
    interestEarned: "7.83",
    name: "HOUSTON",
    website: "http://www.houstonfcu.org",
  },
  "14098": {
    loansProduced: "7.54",
    membersServed: "949",
    interestEarned: "0.64",
    name: "COMMUNITY FOCUS",
    website: "http://www.communityfocusfcu.org",
  },
  "14120": {
    loansProduced: "20.06",
    membersServed: "2,045",
    interestEarned: "1.71",
    name: "STARK",
    website: "http://www.starkcu.org",
  },
  "14135": {
    loansProduced: "14.59",
    membersServed: "1,934",
    interestEarned: "1.24",
    name: "DIAMOND VALLEY",
    website: "http://www.diamondvalleyfcu.org",
  },
  "14163": {
    loansProduced: "3.43",
    membersServed: "382",
    interestEarned: "0.29",
    name: "FIRST COUNTY",
    website: "http://www.firstcountyfcu.org",
  },
  "14166": {
    loansProduced: "1.27",
    membersServed: "138",
    interestEarned: "0.11",
    name: "COCHRAN COUNTY SCHOOLS",
    website: "http://www.cochrancountyschoolsfcu.",
  },
  "14170": {
    loansProduced: "2.25",
    membersServed: "809",
    interestEarned: "0.19",
    name: "NORTHEAST TEXAS TEACHERS",
    website: "http://nettfcu.com",
  },
  "14172": {
    loansProduced: "0.61",
    membersServed: "46",
    interestEarned: "0.05",
    name: "METREX",
    website: null,
  },
  "14176": {
    loansProduced: "16.82",
    membersServed: "1,776",
    interestEarned: "1.43",
    name: "IDB GLOBAL",
    website: "http://www.idbglobalfcu.org",
  },
  "14180": {
    loansProduced: "1.32",
    membersServed: "252",
    interestEarned: "0.11",
    name: "CENTRAL SUSQUEHANNA COMMUNITY",
    website: "http://cscfederal.org",
  },
  "14185": {
    loansProduced: "0.37",
    membersServed: "19",
    interestEarned: "0.03",
    name: "KRAFTSMAN",
    website: null,
  },
  "14186": {
    loansProduced: "3.42",
    membersServed: "260",
    interestEarned: "0.29",
    name: "WALTON COUNTY TEACHERS",
    website: "http://waltoncountyteachersfcu.com",
  },
  "14191": {
    loansProduced: "1.47",
    membersServed: "95",
    interestEarned: "0.13",
    name: "DANVERS MUNICIPAL",
    website: "http://wwwdanversmfcu.com",
  },
  "14193": {
    loansProduced: "0.05",
    membersServed: "18",
    interestEarned: "0.00",
    name: "FORREST COUNTY TEACHERS",
    website: null,
  },
  "14196": {
    loansProduced: "3.85",
    membersServed: "330",
    interestEarned: "0.33",
    name: "TEAMSTERS COUNCIL #37",
    website: "http://www.tcu37.com",
  },
  "14226": {
    loansProduced: "1.20",
    membersServed: "93",
    interestEarned: "0.10",
    name: "3RD DISTRICT HIGHWAY",
    website: null,
  },
  "14247": {
    loansProduced: "1.04",
    membersServed: "90",
    interestEarned: "0.09",
    name: "I B E W LOCAL 56",
    website: "https://ibew56fcu.org",
  },
  "14257": {
    loansProduced: "1.61",
    membersServed: "130",
    interestEarned: "0.14",
    name: "WEE",
    website: "http://www.weefederal.org",
  },
  "14260": {
    loansProduced: "2.53",
    membersServed: "162",
    interestEarned: "0.21",
    name: "MINUTEMAN",
    website: "http://www.minutemanfcu.org",
  },
  "14283": {
    loansProduced: "1.23",
    membersServed: "255",
    interestEarned: "0.10",
    name: "LOCAL 520 U A",
    website: "http://local520fcu.org",
  },
  "14303": {
    loansProduced: "1.44",
    membersServed: "123",
    interestEarned: "0.12",
    name: "H M S A EMPLOYEES",
    website: "https://www.hmsaefcu.org",
  },
  "14329": {
    loansProduced: "0.59",
    membersServed: "148",
    interestEarned: "0.05",
    name: "UNION COUNTY EMPLOYEES",
    website: "http://unioncountycreditunion.com",
  },
  "14333": {
    loansProduced: "2.01",
    membersServed: "155",
    interestEarned: "0.17",
    name: "TENNESSEE RIVER",
    website: null,
  },
  "14346": {
    loansProduced: "1.22",
    membersServed: "246",
    interestEarned: "0.10",
    name: "FIRST AREA",
    website: "https://www.firstareafcu.com",
  },
  "14347": {
    loansProduced: "0.42",
    membersServed: "60",
    interestEarned: "0.04",
    name: "BEDCO HOSPITAL",
    website: null,
  },
  "14369": {
    loansProduced: "4.23",
    membersServed: "562",
    interestEarned: "0.36",
    name: "THE DISTRICT",
    website: "http://www.districtcreditunion.com",
  },
  "14375": {
    loansProduced: "0.84",
    membersServed: "81",
    interestEarned: "0.07",
    name: "DISTRICT 62 HIGHWAY",
    website: null,
  },
  "14376": {
    loansProduced: "0.92",
    membersServed: "44",
    interestEarned: "0.08",
    name: "LOUISIANA MACHINERY EMPLOYEES",
    website: null,
  },
  "14384": {
    loansProduced: "335.35",
    membersServed: "37,168",
    interestEarned: "28.50",
    name: "MISSION",
    website: "http://www.MissionFed.com",
  },
  "14388": {
    loansProduced: "4.72",
    membersServed: "1,301",
    interestEarned: "0.40",
    name: "HARTFORD",
    website: "http://www.hartfordfcu.com",
  },
  "14391": {
    loansProduced: "22.50",
    membersServed: "2,654",
    interestEarned: "1.91",
    name: "BAPTIST HEALTH SOUTH FLORIDA",
    website: "http://www.bhsffcu.org",
  },
  "14402": {
    loansProduced: "2.38",
    membersServed: "238",
    interestEarned: "0.20",
    name: "IBEW/SJ CASCADE",
    website: "http://ibewsjcu.org",
  },
  "14408": {
    loansProduced: "0.78",
    membersServed: "83",
    interestEarned: "0.07",
    name: "MIDWAY",
    website: "http://www.midwayfcu.org",
  },
  "14409": {
    loansProduced: "0.94",
    membersServed: "240",
    interestEarned: "0.08",
    name: "DIABLO VALLEY",
    website: "http://www.diablovalleyfcu.org",
  },
  "14415": {
    loansProduced: "0.67",
    membersServed: "50",
    interestEarned: "0.06",
    name: "SONOMA",
    website: "http://www.sonomafed.com",
  },
  "14421": {
    loansProduced: "0.47",
    membersServed: "89",
    interestEarned: "0.04",
    name: "SOUTH LOUISIANA HIGHWAY",
    website: null,
  },
  "14425": {
    loansProduced: "9.75",
    membersServed: "1,160",
    interestEarned: "0.83",
    name: "INGERSOLL-RAND",
    website: "http://ingersollrandfcu.com",
  },
  "14436": {
    loansProduced: "2.63",
    membersServed: "413",
    interestEarned: "0.22",
    name: "OSWEGO TEACHERS EMPLOYEES",
    website: "http://oswegoteacherscu.org",
  },
  "14449": {
    loansProduced: "1.20",
    membersServed: "185",
    interestEarned: "0.10",
    name: "WAYNE COUNTY",
    website: "http://www.waynecountyfcu.org",
  },
  "14455": {
    loansProduced: "21.41",
    membersServed: "1,784",
    interestEarned: "1.82",
    name: "CENTRAL COAST",
    website: "http://www.centcoastfcu.com",
  },
  "14462": {
    loansProduced: "6.62",
    membersServed: "1,057",
    interestEarned: "0.56",
    name: "ONTARIO SHORES",
    website: "http://www.osfcu.com",
  },
  "14469": {
    loansProduced: "0.08",
    membersServed: "21",
    interestEarned: "0.01",
    name: "MAHONING VALLEY",
    website: "http://www.mahoningvalleyfcu.com",
  },
  "14499": {
    loansProduced: "4.09",
    membersServed: "545",
    interestEarned: "0.35",
    name: "CAL STATE L.A.",
    website: "http://www.calstatela-fcu.org",
  },
  "14505": {
    loansProduced: "0.71",
    membersServed: "70",
    interestEarned: "0.06",
    name: "GRIFFITH INSTITUTE EMPLOYEES",
    website: "http://www.springvillefcu.org",
  },
  "14512": {
    loansProduced: "1.21",
    membersServed: "70",
    interestEarned: "0.10",
    name: "PORT TERMINAL",
    website: "http://www.portterminalfcu.org",
  },
  "14522": {
    loansProduced: "2.67",
    membersServed: "198",
    interestEarned: "0.23",
    name: "SPECIAL METALS",
    website: "http://www.specialmetalsfcu.com",
  },
  "14537": {
    loansProduced: "0.28",
    membersServed: "38",
    interestEarned: "0.02",
    name: "JAMES WARD, JR.",
    website: null,
  },
  "14542": {
    loansProduced: "13.61",
    membersServed: "2,740",
    interestEarned: "1.16",
    name: "ONTARIO MONTCLAIR SCHOOL EMPLOYEES",
    website: "http://www.omsefcu.org",
  },
  "14545": {
    loansProduced: "22.83",
    membersServed: "1,506",
    interestEarned: "1.94",
    name: "PACIFIC CASCADE",
    website: "http://wesaveyou.com",
  },
  "14562": {
    loansProduced: "3.94",
    membersServed: "543",
    interestEarned: "0.34",
    name: "EVANSVILLE",
    website: "http://www.evansvillefcu.org",
  },
  "14565": {
    loansProduced: "48.64",
    membersServed: "6,434",
    interestEarned: "4.13",
    name: "MAINE SAVINGS",
    website: "http://www.mainesavings.com",
  },
  "14568": {
    loansProduced: "130.59",
    membersServed: "9,683",
    interestEarned: "11.10",
    name: "LA CAPITOL",
    website: "http://www.lacapfcu.org",
  },
  "14571": {
    loansProduced: "3.02",
    membersServed: "255",
    interestEarned: "0.26",
    name: "LOGAN MEDICAL",
    website: "http://www.loganmedicalfcu.org",
  },
  "14584": {
    loansProduced: "3.19",
    membersServed: "464",
    interestEarned: "0.27",
    name: "LAKEVIEW",
    website: "http://Lakeviewfcu.com",
  },
  "14610": {
    loansProduced: "0.09",
    membersServed: "13",
    interestEarned: "0.01",
    name: "MORNING STAR",
    website: null,
  },
  "14611": {
    loansProduced: "8.88",
    membersServed: "809",
    interestEarned: "0.75",
    name: "WVU EMPLOYEES",
    website: "http://wvucu.com",
  },
  "14617": {
    loansProduced: "0.11",
    membersServed: "11",
    interestEarned: "0.01",
    name: "LONZA",
    website: null,
  },
  "14623": {
    loansProduced: "2.51",
    membersServed: "451",
    interestEarned: "0.21",
    name: "LYNCHBURG MUNICIPAL EMPLOYEES",
    website: "http://lmefcu.org",
  },
  "14634": {
    loansProduced: "2.49",
    membersServed: "258",
    interestEarned: "0.21",
    name: "I B E W 175",
    website: null,
  },
  "14650": {
    loansProduced: "4.44",
    membersServed: "490",
    interestEarned: "0.38",
    name: "PARISHIONERS",
    website: "http://www.parishionersfcu.org",
  },
  "14651": {
    loansProduced: "1.37",
    membersServed: "166",
    interestEarned: "0.12",
    name: "DISTRICT 08",
    website: "http://www.district08fcu.org",
  },
  "14656": {
    loansProduced: "1.00",
    membersServed: "113",
    interestEarned: "0.09",
    name: "LOCAL 24 EMPLOYEES",
    website: "www.local24efcu.com",
  },
  "14657": {
    loansProduced: "0.07",
    membersServed: "7",
    interestEarned: "0.01",
    name: "VALLEY WIDE",
    website: null,
  },
  "14668": {
    loansProduced: "4.38",
    membersServed: "1,036",
    interestEarned: "0.37",
    name: "HIGH PEAKS",
    website: "http://www.highpeaksfcu.com",
  },
  "14670": {
    loansProduced: "5.14",
    membersServed: "313",
    interestEarned: "0.44",
    name: "H E A",
    website: "http://www.heafcu.org",
  },
  "14676": {
    loansProduced: "64.90",
    membersServed: "6,488",
    interestEarned: "5.52",
    name: "PEACH STATE",
    website: "http://www.peachstatefcu.org",
  },
  "14677": {
    loansProduced: "4.27",
    membersServed: "426",
    interestEarned: "0.36",
    name: "CLEVELAND-BRADLEY CTY TEACHER",
    website: "http://www.cbctfcu.com",
  },
  "14692": {
    loansProduced: "57.56",
    membersServed: "8,377",
    interestEarned: "4.89",
    name: "ONPATH",
    website: "https://beonpath.org",
  },
  "14708": {
    loansProduced: "0.86",
    membersServed: "61",
    interestEarned: "0.07",
    name: "MACHINISTS - BOILERMAKERS",
    website: "http://www.mbfcu.com",
  },
  "14712": {
    loansProduced: "0.18",
    membersServed: "15",
    interestEarned: "0.02",
    name: "IBEW 141",
    website: null,
  },
  "14723": {
    loansProduced: "7.43",
    membersServed: "867",
    interestEarned: "0.63",
    name: "AEROSPACE",
    website: "http://www.aerofcu.org",
  },
  "14725": {
    loansProduced: "2.70",
    membersServed: "187",
    interestEarned: "0.23",
    name: "CHATTANOOGA FIRST",
    website: "http://www.chattfirst.org",
  },
  "14733": {
    loansProduced: "13.05",
    membersServed: "1,420",
    interestEarned: "1.11",
    name: "SHELBY COUNTY",
    website: "http://shelbycountycu.com",
  },
  "14734": {
    loansProduced: "7.99",
    membersServed: "980",
    interestEarned: "0.68",
    name: "LA JOYA AREA",
    website: "http://WWW.LAJOYACREDITUNION.COOP",
  },
  "14750": {
    loansProduced: "1.67",
    membersServed: "139",
    interestEarned: "0.14",
    name: "LEXINGTON MA",
    website: "http://www.lexmafcu.com",
  },
  "14754": {
    loansProduced: "24.53",
    membersServed: "3,249",
    interestEarned: "2.09",
    name: "TOPSIDE",
    website: "http://topsidefcu.org",
  },
  "14758": {
    loansProduced: "0.91",
    membersServed: "103",
    interestEarned: "0.08",
    name: "QUAY SCHOOLS",
    website: null,
  },
  "14762": {
    loansProduced: "268.56",
    membersServed: "20,816",
    interestEarned: "22.83",
    name: "JSC",
    website: "http://www.wellbyfinancial.com",
  },
  "14775": {
    loansProduced: "7.98",
    membersServed: "909",
    interestEarned: "0.68",
    name: "FRICK FINANCIAL",
    website: "http://www.frickfin.org",
  },
  "14786": {
    loansProduced: "3.12",
    membersServed: "434",
    interestEarned: "0.27",
    name: "NORTHWEST ADVENTIST",
    website: "http://www.mynwcu.com",
  },
  "14792": {
    loansProduced: "0.17",
    membersServed: "15",
    interestEarned: "0.01",
    name: "JAY BEE EMPLOYEES",
    website: null,
  },
  "14805": {
    loansProduced: "8.64",
    membersServed: "669",
    interestEarned: "0.73",
    name: "MISSISSIPPI NATIONAL GUARD",
    website: "https://www.msng.org",
  },
  "14815": {
    loansProduced: "0.30",
    membersServed: "102",
    interestEarned: "0.03",
    name: "LOCAL 355 MD",
    website: null,
  },
  "14824": {
    loansProduced: "0.71",
    membersServed: "153",
    interestEarned: "0.06",
    name: "MEMORIAL",
    website: "https://memorialfcu.org",
  },
  "14829": {
    loansProduced: "0.45",
    membersServed: "82",
    interestEarned: "0.04",
    name: "TEAM FIRST",
    website: "http://WWW.TEAMFIRSTFCU.ORG",
  },
  "14830": {
    loansProduced: "13.00",
    membersServed: "1,602",
    interestEarned: "1.11",
    name: "EDWARDS",
    website: "https://edwardsfcu.org/",
  },
  "14832": {
    loansProduced: "1.51",
    membersServed: "86",
    interestEarned: "0.13",
    name: "WATSONVILLE HOSPITAL",
    website: "http://www.wahfcu.com",
  },
  "14845": {
    loansProduced: "1.11",
    membersServed: "178",
    interestEarned: "0.09",
    name: "RAH",
    website: "http://www.rahfcu.org",
  },
  "14847": {
    loansProduced: "0.31",
    membersServed: "33",
    interestEarned: "0.03",
    name: "LINCOLN SUDBURY TOWN EMPLOYEE",
    website: "http://sudbury.ma.us",
  },
  "14850": {
    loansProduced: "2.88",
    membersServed: "454",
    interestEarned: "0.25",
    name: "PLYMOUTH COUNTY TEACHERS",
    website: "http://www.pctfcu.org",
  },
  "14865": {
    loansProduced: "1.21",
    membersServed: "302",
    interestEarned: "0.10",
    name: "BURLINGTON MUNICIPAL EMPLOYEES",
    website: null,
  },
  "14874": {
    loansProduced: "9.92",
    membersServed: "1,411",
    interestEarned: "0.84",
    name: "EXPLORERS",
    website: "http://www.explorerscu.com",
  },
  "14879": {
    loansProduced: "1.05",
    membersServed: "93",
    interestEarned: "0.09",
    name: "A.B.",
    website: "http://www.abfederalcu.com",
  },
  "14884": {
    loansProduced: "4.68",
    membersServed: "511",
    interestEarned: "0.40",
    name: "SPENCERPORT",
    website: "http://www.spnfcu.org",
  },
  "14898": {
    loansProduced: "0.36",
    membersServed: "61",
    interestEarned: "0.03",
    name: "INLAND MOTOR EMPLOYEES",
    website: null,
  },
  "14917": {
    loansProduced: "0.53",
    membersServed: "38",
    interestEarned: "0.05",
    name: "GIBBONS AND REED EMPLOYEES",
    website: "http://grcreditunion.com",
  },
  "14934": {
    loansProduced: "9.69",
    membersServed: "1,274",
    interestEarned: "0.82",
    name: "SMW 104",
    website: "http://www.smw104fcu.org",
  },
  "14965": {
    loansProduced: "2.37",
    membersServed: "174",
    interestEarned: "0.20",
    name: "NORTHEAST NEBRASKA",
    website: "https://www.nnfcu.com",
  },
  "14972": {
    loansProduced: "1.19",
    membersServed: "74",
    interestEarned: "0.10",
    name: "NORMAL CITY EMPLOYEES",
    website: "http://www.ncefcu.com",
  },
  "15000": {
    loansProduced: "5.08",
    membersServed: "946",
    interestEarned: "0.43",
    name: "O.A.S. STAFF",
    website: "http://www.oasfcu.org",
  },
  "15001": {
    loansProduced: "0.22",
    membersServed: "29",
    interestEarned: "0.02",
    name: "SALT EMPLOYEES",
    website: null,
  },
  "15007": {
    loansProduced: "4.46",
    membersServed: "492",
    interestEarned: "0.38",
    name: "MIDWEST FAMILY",
    website: "https://www.mwffcu.org",
  },
  "15009": {
    loansProduced: "0.73",
    membersServed: "64",
    interestEarned: "0.06",
    name: "MORGAN CITY",
    website: null,
  },
  "15015": {
    loansProduced: "7.53",
    membersServed: "683",
    interestEarned: "0.64",
    name: "FOOTHILLS",
    website: "http://foothillsfcu.org",
  },
  "15016": {
    loansProduced: "0.92",
    membersServed: "115",
    interestEarned: "0.08",
    name: "WAKEFERN",
    website: "https://wakefernfcu.org/",
  },
  "15021": {
    loansProduced: "3.21",
    membersServed: "548",
    interestEarned: "0.27",
    name: "MORTON LANE",
    website: "http://www.mortonlanedirect.com",
  },
  "15028": {
    loansProduced: "0.68",
    membersServed: "137",
    interestEarned: "0.06",
    name: "WUFFACE",
    website: "wufface.com",
  },
  "15049": {
    loansProduced: "2.40",
    membersServed: "426",
    interestEarned: "0.20",
    name: "UNION FIDELITY",
    website: "http://unionfidelityfcu.com",
  },
  "15051": {
    loansProduced: "14.59",
    membersServed: "878",
    interestEarned: "1.24",
    name: "COMMUNITY FIRST GUAM",
    website: "http://www.cfirstguam.com",
  },
  "15062": {
    loansProduced: "2.56",
    membersServed: "163",
    interestEarned: "0.22",
    name: "ALDERSGATE",
    website: "http://igrc.org",
  },
  "15063": {
    loansProduced: "0.28",
    membersServed: "23",
    interestEarned: "0.02",
    name: "NEW KENSINGTON MUNICIPAL",
    website: null,
  },
  "15067": {
    loansProduced: "0.45",
    membersServed: "114",
    interestEarned: "0.04",
    name: "TRANSFIGURATION PARISH",
    website: "http://transfigurationfcu.org",
  },
  "15072": {
    loansProduced: "18.04",
    membersServed: "2,127",
    interestEarned: "1.53",
    name: "REGIONAL",
    website: "http://www.regionalfcu.org",
  },
  "15073": {
    loansProduced: "0.87",
    membersServed: "87",
    interestEarned: "0.07",
    name: "DEDHAM TOWN EMPLOYEES",
    website: "https://www.dedhamcu.org",
  },
  "15080": {
    loansProduced: "8.11",
    membersServed: "1,384",
    interestEarned: "0.69",
    name: "ROCKLAND EMPLOYEES",
    website: "www.refcu.org",
  },
  "15089": {
    loansProduced: "0.11",
    membersServed: "22",
    interestEarned: "0.01",
    name: "S T S P",
    website: null,
  },
  "15095": {
    loansProduced: "7.83",
    membersServed: "933",
    interestEarned: "0.67",
    name: "PINNACLE",
    website: "https://www.pinnaclefcu.com",
  },
  "15108": {
    loansProduced: "4.84",
    membersServed: "774",
    interestEarned: "0.41",
    name: "MUNA",
    website: "http://www.munafederal.com",
  },
  "15139": {
    loansProduced: "1.03",
    membersServed: "158",
    interestEarned: "0.09",
    name: "BERGEN DIVISION",
    website: null,
  },
  "15143": {
    loansProduced: "13.39",
    membersServed: "2,095",
    interestEarned: "1.14",
    name: "CHOCOLATE BAYOU COMMUNITY",
    website: "http://www.chocolatebayou.org",
  },
  "15146": {
    loansProduced: "38.58",
    membersServed: "3,878",
    interestEarned: "3.28",
    name: "DUGOOD",
    website: "http://www.dugood.org",
  },
  "15159": {
    loansProduced: "10.81",
    membersServed: "985",
    interestEarned: "0.92",
    name: "FRANKLIN-SOMERSET",
    website: "http://www.f-sfcu.com",
  },
  "15174": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "LEE",
    website: "http://Leefcu.com",
  },
  "15175": {
    loansProduced: "16.17",
    membersServed: "1,408",
    interestEarned: "1.37",
    name: "T M H",
    website: "http://www.tmhfcu.org",
  },
  "15185": {
    loansProduced: "3.09",
    membersServed: "586",
    interestEarned: "0.26",
    name: "COMMUNITY POWERED",
    website: "https://cpwrfcu.org",
  },
  "15209": {
    loansProduced: "19.65",
    membersServed: "3,016",
    interestEarned: "1.67",
    name: "CALL",
    website: "http://www.callfcu.org",
  },
  "15211": {
    loansProduced: "1.22",
    membersServed: "101",
    interestEarned: "0.10",
    name: "DISTRICT 58",
    website: null,
  },
  "15222": {
    loansProduced: "46.36",
    membersServed: "3,609",
    interestEarned: "3.94",
    name: "COAST360",
    website: "http://www.coast360fcu.com",
  },
  "15230": {
    loansProduced: "0.72",
    membersServed: "56",
    interestEarned: "0.06",
    name: "MOFFAT COUNTY SCHOOLS",
    website: null,
  },
  "15234": {
    loansProduced: "0.47",
    membersServed: "137",
    interestEarned: "0.04",
    name: "CHESWICK ATOMIC DIVISION",
    website: "http://cadfcu.com",
  },
  "15240": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "RESURRECTION LUTHERAN",
    website: null,
  },
  "15248": {
    loansProduced: "9.52",
    membersServed: "927",
    interestEarned: "0.81",
    name: "LAMPCO",
    website: "http://www.lampco.com",
  },
  "15263": {
    loansProduced: "0.13",
    membersServed: "16",
    interestEarned: "0.01",
    name: "KENNAMETAL ORWELL EMPLOYEES",
    website: null,
  },
  "15265": {
    loansProduced: "1.08",
    membersServed: "86",
    interestEarned: "0.09",
    name: "WARE COUNTY SCHOOL EMPLOYEES",
    website: "http://www.warecountyschoolemployee",
  },
  "15296": {
    loansProduced: "0.15",
    membersServed: "21",
    interestEarned: "0.01",
    name: "MANCHESTER",
    website: "http://www.manchester.ma.us",
  },
  "15297": {
    loansProduced: "1.68",
    membersServed: "175",
    interestEarned: "0.14",
    name: "HALE COUNTY TEACHERS",
    website: null,
  },
  "15304": {
    loansProduced: "4.63",
    membersServed: "573",
    interestEarned: "0.39",
    name: "GRACO",
    website: "http://www.gracofcu.org",
  },
  "15307": {
    loansProduced: "8.98",
    membersServed: "820",
    interestEarned: "0.76",
    name: "HOPEWELL",
    website: "http://www.hopewellfcu.org",
  },
  "15317": {
    loansProduced: "18.89",
    membersServed: "2,173",
    interestEarned: "1.61",
    name: "SAG-AFTRA",
    website: "http://www.sagaftrafcu.org",
  },
  "15324": {
    loansProduced: "1.45",
    membersServed: "97",
    interestEarned: "0.12",
    name: "WESTSIDE COMMUNITY",
    website: "http://www.wscfcu.com",
  },
  "15327": {
    loansProduced: "16.31",
    membersServed: "2,477",
    interestEarned: "1.39",
    name: "SOUTHWEST FINANCIAL",
    website: "http://swfinancial.org",
  },
  "15328": {
    loansProduced: "10.70",
    membersServed: "1,091",
    interestEarned: "0.91",
    name: "K V",
    website: "http://www.kvfcu.org",
  },
  "15358": {
    loansProduced: "9.77",
    membersServed: "1,983",
    interestEarned: "0.83",
    name: "ACTORS",
    website: "https://actorsfcu.com",
  },
  "15370": {
    loansProduced: "0.27",
    membersServed: "115",
    interestEarned: "0.02",
    name: "SAKER SHOP RITE",
    website: null,
  },
  "15375": {
    loansProduced: "1.59",
    membersServed: "262",
    interestEarned: "0.13",
    name: "WOLF POINT",
    website: "http://wpfcu.com",
  },
  "15377": {
    loansProduced: "3.65",
    membersServed: "232",
    interestEarned: "0.31",
    name: "MERIDIAN MISSISSIPPI ANG",
    website: "http://www.meridianangfcu.org",
  },
  "15386": {
    loansProduced: "2.02",
    membersServed: "297",
    interestEarned: "0.17",
    name: "WESTAR",
    website: "http://www.westarfcu.com",
  },
  "15390": {
    loansProduced: "0.31",
    membersServed: "39",
    interestEarned: "0.03",
    name: "MAUMEE EDUCATORS",
    website: "http://www.maumeeeducators.com",
  },
  "15393": {
    loansProduced: "1.99",
    membersServed: "352",
    interestEarned: "0.17",
    name: "STONEHAM MUNICIPAL EMPLOYEES",
    website: "http://www.smefcu.org",
  },
  "15394": {
    loansProduced: "43.76",
    membersServed: "5,727",
    interestEarned: "3.72",
    name: "BALTIMORE COUNTY EMPLOYEES",
    website: "http://www.bcefcu.com",
  },
  "15426": {
    loansProduced: "2.58",
    membersServed: "554",
    interestEarned: "0.22",
    name: "AMERICAN SPIRIT",
    website: "http://www.americanspirit.org",
  },
  "15433": {
    loansProduced: "1.57",
    membersServed: "320",
    interestEarned: "0.13",
    name: "MEMPHIS MUNICIPAL EMPLOYEES",
    website: "http://www.mmefcu.org",
  },
  "15435": {
    loansProduced: "0.24",
    membersServed: "27",
    interestEarned: "0.02",
    name: "PORTER",
    website: "http://porterfcu.org",
  },
  "15438": {
    loansProduced: "6.90",
    membersServed: "1,084",
    interestEarned: "0.59",
    name: "TOPMARK",
    website: "www.topmarkfcu.com",
  },
  "15451": {
    loansProduced: "0.44",
    membersServed: "41",
    interestEarned: "0.04",
    name: "SOLON/CHAGRIN FALLS",
    website: "http://www.soloncu.com",
  },
  "15454": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "SHILOH ENGLEWOOD",
    website: null,
  },
  "15459": {
    loansProduced: "0.12",
    membersServed: "3",
    interestEarned: "0.01",
    name: "PENN SOUTH COOPERATIVE",
    website: "https://www.pennsouthcreditunion.or",
  },
  "15467": {
    loansProduced: "14.86",
    membersServed: "1,088",
    interestEarned: "1.26",
    name: "KELLY COMMUNITY",
    website: "http://www.kellycommunity.org",
  },
  "15483": {
    loansProduced: "11.10",
    membersServed: "1,305",
    interestEarned: "0.94",
    name: "JERSEY SHORE",
    website: "http://www.jerseyshorefcu.org",
  },
  "15513": {
    loansProduced: "0.51",
    membersServed: "62",
    interestEarned: "0.04",
    name: "FORT DIX",
    website: "http://www.ftdixfcu.com",
  },
  "15516": {
    loansProduced: "3.38",
    membersServed: "416",
    interestEarned: "0.29",
    name: "MOUNTAIN VALLEY",
    website: "www.mountainvalleyfcu.com",
  },
  "15522": {
    loansProduced: "0.78",
    membersServed: "62",
    interestEarned: "0.07",
    name: "LOCAL 265 IBEW",
    website: "https://www.itsme247.com/886/authentication/username",
  },
  "15523": {
    loansProduced: "11.12",
    membersServed: "1,388",
    interestEarned: "0.95",
    name: "TONGASS",
    website: "http://www.tongassfcu.com",
  },
  "15532": {
    loansProduced: "2.02",
    membersServed: "286",
    interestEarned: "0.17",
    name: "PAGODA",
    website: "http://www.pagodafcu.org",
  },
  "15536": {
    loansProduced: "1.35",
    membersServed: "244",
    interestEarned: "0.11",
    name: "VA BEACH POSTAL",
    website: "http://VBPFCU.ORG",
  },
  "15539": {
    loansProduced: "4.01",
    membersServed: "657",
    interestEarned: "0.34",
    name: "TOWN OF HEMPSTEAD EMPLOYEES",
    website: "http://www.tohefcu.org",
  },
  "15563": {
    loansProduced: "7.14",
    membersServed: "709",
    interestEarned: "0.61",
    name: "BAYCEL",
    website: "http://www.baycel.org",
  },
  "15565": {
    loansProduced: "0.75",
    membersServed: "82",
    interestEarned: "0.06",
    name: "BELTON",
    website: "http://beltonfcu.com",
  },
  "15588": {
    loansProduced: "0.08",
    membersServed: "13",
    interestEarned: "0.01",
    name: "G G W",
    website: null,
  },
  "15589": {
    loansProduced: "1.97",
    membersServed: "255",
    interestEarned: "0.17",
    name: "CADDO PARISH TEACHERS",
    website: "http://www.CPTFCU.ORG",
  },
  "15591": {
    loansProduced: "0.35",
    membersServed: "125",
    interestEarned: "0.03",
    name: "SCRANTON TIMES DOWNTOWN",
    website: "http://www.scrantontimesefcu.com",
  },
  "15602": {
    loansProduced: "8.46",
    membersServed: "1,109",
    interestEarned: "0.72",
    name: "CENLA",
    website: "http://www.cenlafcu.org",
  },
  "15614": {
    loansProduced: "0.36",
    membersServed: "30",
    interestEarned: "0.03",
    name: "ALBION SCHOOL EMPLOYEES",
    website: null,
  },
  "15616": {
    loansProduced: "0.12",
    membersServed: "20",
    interestEarned: "0.01",
    name: "ARNOLD BAKERS EMPLOYEES",
    website: null,
  },
  "15618": {
    loansProduced: "1.98",
    membersServed: "119",
    interestEarned: "0.17",
    name: "AFGM ENTERPRISES",
    website: "http://AFGMENTFCU.NET",
  },
  "15619": {
    loansProduced: "18.93",
    membersServed: "1,719",
    interestEarned: "1.61",
    name: "ACADIA",
    website: "http://www.acadiafcu.org",
  },
  "15650": {
    loansProduced: "11.95",
    membersServed: "902",
    interestEarned: "1.02",
    name: "H E B",
    website: "https://www.hebfcu.org",
  },
  "15652": {
    loansProduced: "0.76",
    membersServed: "198",
    interestEarned: "0.06",
    name: "U F C W LOCAL 1776",
    website: "http://www.1776creditunion.org",
  },
  "15659": {
    loansProduced: "4.52",
    membersServed: "398",
    interestEarned: "0.38",
    name: "MARIN COUNTY",
    website: "www.marincu.org",
  },
  "15669": {
    loansProduced: "5.49",
    membersServed: "543",
    interestEarned: "0.47",
    name: "MOOG EMPLOYEES",
    website: "http://www.moogfcu.com",
  },
  "15672": {
    loansProduced: "64.58",
    membersServed: "7,780",
    interestEarned: "5.49",
    name: "SEA COMM",
    website: "http://www.seacomm.org",
  },
  "15673": {
    loansProduced: "0.09",
    membersServed: "6",
    interestEarned: "0.01",
    name: "ISRAEL METHCOMM",
    website: "imfcu.com/",
  },
  "15732": {
    loansProduced: "345.87",
    membersServed: "39,172",
    interestEarned: "29.40",
    name: "UNIFY FINANCIAL",
    website: "http://www.unifyfcu.com",
  },
  "15741": {
    loansProduced: "53.81",
    membersServed: "3,147",
    interestEarned: "4.57",
    name: "TRONA VALLEY COMMUNITY",
    website: "http://www.tronavalley.com",
  },
  "15752": {
    loansProduced: "0.35",
    membersServed: "28",
    interestEarned: "0.03",
    name: "NUCOR EMPLOYEES",
    website: null,
  },
  "15757": {
    loansProduced: "0.09",
    membersServed: "9",
    interestEarned: "0.01",
    name: "MT ZION INDIANAPOLIS",
    website: "http://www.mzbchurch.org/credit_uni",
  },
  "15766": {
    loansProduced: "121.35",
    membersServed: "11,420",
    interestEarned: "10.31",
    name: "MID-HUDSON VALLEY",
    website: "http://mhvfcu.com",
  },
  "15779": {
    loansProduced: "0.04",
    membersServed: "8",
    interestEarned: "0.00",
    name: "LATVIAN HERITAGE",
    website: "http://www.latvianheritage.org",
  },
  "15784": {
    loansProduced: "4.06",
    membersServed: "293",
    interestEarned: "0.35",
    name: "NOVO",
    website: "http://www.novocu.org",
  },
  "15788": {
    loansProduced: "5.31",
    membersServed: "434",
    interestEarned: "0.45",
    name: "COMMON CENTS",
    website: "http://www.commoncentscu.com",
  },
  "15790": {
    loansProduced: "1.28",
    membersServed: "261",
    interestEarned: "0.11",
    name: "DELAWARE ALLIANCE",
    website: "http://www.all4youfcu.org",
  },
  "15797": {
    loansProduced: "0.54",
    membersServed: "118",
    interestEarned: "0.05",
    name: "IRON WORKERS",
    website: "http://iwfcu.com",
  },
  "15815": {
    loansProduced: "9.70",
    membersServed: "850",
    interestEarned: "0.82",
    name: "SOUTHLAND",
    website: "http://www.southlandfcu.com",
  },
  "15817": {
    loansProduced: "0.16",
    membersServed: "23",
    interestEarned: "0.01",
    name: "PILGRIM CUCC",
    website: null,
  },
  "15847": {
    loansProduced: "1.06",
    membersServed: "244",
    interestEarned: "0.09",
    name: "U-HAUL",
    website: "http://www.uhaulfedcu.com",
  },
  "15848": {
    loansProduced: "1.08",
    membersServed: "149",
    interestEarned: "0.09",
    name: "CBI",
    website: "https://cbifcu.org",
  },
  "15874": {
    loansProduced: "2.48",
    membersServed: "398",
    interestEarned: "0.21",
    name: "MEMBERS TRUST",
    website: "http://www.memberstrust.org",
  },
  "15882": {
    loansProduced: "16.71",
    membersServed: "3,271",
    interestEarned: "1.42",
    name: "DESCO",
    website: "http://www.descofcu.org",
  },
  "15928": {
    loansProduced: "0.64",
    membersServed: "50",
    interestEarned: "0.05",
    name: "TROY",
    website: null,
  },
  "15935": {
    loansProduced: "1.76",
    membersServed: "144",
    interestEarned: "0.15",
    name: "CHA-TEL",
    website: "http://www.chatelfcu.com",
  },
  "15938": {
    loansProduced: "0.67",
    membersServed: "55",
    interestEarned: "0.06",
    name: "SIXTH AVENUE BAPTIST",
    website: "http://www.sixthavebaptistfcu.org",
  },
  "16009": {
    loansProduced: "17.25",
    membersServed: "1,500",
    interestEarned: "1.47",
    name: "ATLANTIC CITY",
    website: "http://www.atlanticcity.coop",
  },
  "16011": {
    loansProduced: "0.98",
    membersServed: "238",
    interestEarned: "0.08",
    name: "ATTLEBORO M E",
    website: "http://WWW.AttleboroFCU.org",
  },
  "16014": {
    loansProduced: "0.67",
    membersServed: "170",
    interestEarned: "0.06",
    name: "COFFEE COUNTY TEACHERS",
    website: null,
  },
  "16016": {
    loansProduced: "0.90",
    membersServed: "94",
    interestEarned: "0.08",
    name: "PAWTUCKET MUNICIPAL EMPLOYEES",
    website: null,
  },
  "16030": {
    loansProduced: "14.63",
    membersServed: "2,270",
    interestEarned: "1.24",
    name: "CHAFFEY",
    website: "http://chaffey.com",
  },
  "16042": {
    loansProduced: "10.33",
    membersServed: "1,327",
    interestEarned: "0.88",
    name: "WESTERN NEW YORK",
    website: "http://www.wnyfcu.com",
  },
  "16061": {
    loansProduced: "1.19",
    membersServed: "118",
    interestEarned: "0.10",
    name: "CITY OF DEER PARK",
    website: "http://WWW.DPEFCU.ORG",
  },
  "16063": {
    loansProduced: "1.50",
    membersServed: "198",
    interestEarned: "0.13",
    name: "BVA",
    website: "http://www.bvafcu.org",
  },
  "16065": {
    loansProduced: "2.82",
    membersServed: "241",
    interestEarned: "0.24",
    name: "HOWARD COUNTY SCHOOL EM",
    website: "https://www.hcsefcu.org",
  },
  "16067": {
    loansProduced: "1.15",
    membersServed: "370",
    interestEarned: "0.10",
    name: "THE ANDOVERS",
    website: "http://www.andoverfcu.com",
  },
  "16085": {
    loansProduced: "0.26",
    membersServed: "42",
    interestEarned: "0.02",
    name: "FLINT",
    website: null,
  },
  "16096": {
    loansProduced: "1.45",
    membersServed: "193",
    interestEarned: "0.12",
    name: "AFLAC",
    website: "http://aflacfcu.net",
  },
  "16113": {
    loansProduced: "18.87",
    membersServed: "1,631",
    interestEarned: "1.60",
    name: "HERITAGE GROVE",
    website: "http://www.ourgrovecu.com",
  },
  "16126": {
    loansProduced: "0.03",
    membersServed: "22",
    interestEarned: "0.00",
    name: "GARY MUNICIPAL EMPLOYEES",
    website: null,
  },
  "16168": {
    loansProduced: "23.36",
    membersServed: "3,057",
    interestEarned: "1.99",
    name: "GEOVISTA",
    website: "http://www.geovistacu.com",
  },
  "16174": {
    loansProduced: "4.56",
    membersServed: "617",
    interestEarned: "0.39",
    name: "UNIVERSITY OF TOLEDO",
    website: "http://www.uoftfcu.com",
  },
  "16176": {
    loansProduced: "1.97",
    membersServed: "357",
    interestEarned: "0.17",
    name: "CANANDAIGUA",
    website: "https://www.canandaiguafcu.com",
  },
  "16178": {
    loansProduced: "8.95",
    membersServed: "806",
    interestEarned: "0.76",
    name: "WATERFRONT",
    website: "http://www.waterfrontfcu.org",
  },
  "16190": {
    loansProduced: "2.60",
    membersServed: "207",
    interestEarned: "0.22",
    name: "RIM COUNTRY",
    website: "http://www.rimcountryfcu.com",
  },
  "16207": {
    loansProduced: "6.19",
    membersServed: "420",
    interestEarned: "0.53",
    name: "STATE CS EMPLOYEES",
    website: "http://www.statecs.org",
  },
  "16209": {
    loansProduced: "1.44",
    membersServed: "1,286",
    interestEarned: "0.12",
    name: "LATROBE AREA HOSPITAL",
    website: "http://www.lahcreditunion.com",
  },
  "16213": {
    loansProduced: "14.55",
    membersServed: "1,451",
    interestEarned: "1.24",
    name: "FINANCIAL BUILDERS",
    website: "http://www.Financialbuilders.org",
  },
  "16218": {
    loansProduced: "14.84",
    membersServed: "1,780",
    interestEarned: "1.26",
    name: "XCEL",
    website: "http://www.xcelfcu.org",
  },
  "16236": {
    loansProduced: "2.43",
    membersServed: "413",
    interestEarned: "0.21",
    name: "GATES CHILI",
    website: "https://gateschilifcu.org",
  },
  "16248": {
    loansProduced: "5.14",
    membersServed: "626",
    interestEarned: "0.44",
    name: "NORTH MEMORIAL",
    website: "http://www.northmemorialfcu.com",
  },
  "16256": {
    loansProduced: "0.17",
    membersServed: "60",
    interestEarned: "0.01",
    name: "WASHINGTON EDUCATIONAL ASSOC",
    website: null,
  },
  "16264": {
    loansProduced: "2.32",
    membersServed: "129",
    interestEarned: "0.20",
    name: "DEVILS SLIDE",
    website: "http://devilsslidefcu.org",
  },
  "16268": {
    loansProduced: "4.71",
    membersServed: "607",
    interestEarned: "0.40",
    name: "W S S C",
    website: "www.wsscfcu.org",
  },
  "16271": {
    loansProduced: "0.63",
    membersServed: "104",
    interestEarned: "0.05",
    name: "BAKER HUGHES",
    website: "http://bhfcu.org",
  },
  "16273": {
    loansProduced: "5.35",
    membersServed: "541",
    interestEarned: "0.45",
    name: "MONTANA HEALTH",
    website: "http://www.MontanaHealthFCU.org",
  },
  "16278": {
    loansProduced: "1.15",
    membersServed: "72",
    interestEarned: "0.10",
    name: "SCHENECTADY COUNTY EMPLOYEES",
    website: "http://schcofcu.org",
  },
  "16327": {
    loansProduced: "2.07",
    membersServed: "235",
    interestEarned: "0.18",
    name: "MISSISSIPPI HIGHWAY SAFTY PAT",
    website: "http://www.mhspfcu.com",
  },
  "16351": {
    loansProduced: "4.15",
    membersServed: "425",
    interestEarned: "0.35",
    name: "LUFKIN",
    website: "http://www.lufkinfcu.com",
  },
  "16360": {
    loansProduced: "2.46",
    membersServed: "634",
    interestEarned: "0.21",
    name: "SUMA",
    website: "http://www.sumafcu.org",
  },
  "16364": {
    loansProduced: "1.47",
    membersServed: "329",
    interestEarned: "0.13",
    name: "PROVIDENT",
    website: "http://www.providentfcu.com",
  },
  "16367": {
    loansProduced: "1.80",
    membersServed: "218",
    interestEarned: "0.15",
    name: "COMANCHE COUNTY",
    website: "http://www.comanchecountyfcu.com",
  },
  "16373": {
    loansProduced: "4.27",
    membersServed: "427",
    interestEarned: "0.36",
    name: "WINDTHORST",
    website: "http://www.windthorstfcu.org",
  },
  "16383": {
    loansProduced: "0.02",
    membersServed: "2",
    interestEarned: "0.00",
    name: "NEW ENGLAND LEE",
    website: null,
  },
  "16400": {
    loansProduced: "27.95",
    membersServed: "3,079",
    interestEarned: "2.38",
    name: "AGRICULTURE",
    website: "http://www.agriculturefcu.org",
  },
  "16401": {
    loansProduced: "13.81",
    membersServed: "1,516",
    interestEarned: "1.17",
    name: "POLICE",
    website: "http://www.policefcu.com",
  },
  "16402": {
    loansProduced: "28.43",
    membersServed: "2,417",
    interestEarned: "2.42",
    name: "U S POSTAL SERVICE",
    website: "http://www.uspsfcu.org",
  },
  "16408": {
    loansProduced: "18.50",
    membersServed: "2,207",
    interestEarned: "1.57",
    name: "NYMEO",
    website: "http://www.nymeo.org",
  },
  "16410": {
    loansProduced: "80.15",
    membersServed: "6,452",
    interestEarned: "6.81",
    name: "DEPARTMENT OF COMMERCE",
    website: "http://www.docfcu.org",
  },
  "16411": {
    loansProduced: "8.15",
    membersServed: "1,415",
    interestEarned: "0.69",
    name: "DC",
    website: "http://www.dccreditunion.coop",
  },
  "16416": {
    loansProduced: "5.23",
    membersServed: "1,207",
    interestEarned: "0.44",
    name: "ADVANTAGE FINANCIAL",
    website: "https://www.advfcu.org",
  },
  "16429": {
    loansProduced: "0.99",
    membersServed: "157",
    interestEarned: "0.08",
    name: "LOCAL 20 IBEW",
    website: "http://local20ibewfcu.com",
  },
  "16435": {
    loansProduced: "1.98",
    membersServed: "222",
    interestEarned: "0.17",
    name: "FIRST NESHOBA",
    website: "http://fnfcu.org",
  },
  "16437": {
    loansProduced: "2.48",
    membersServed: "178",
    interestEarned: "0.21",
    name: "JOLIET MUNICIPAL EMPLOYEES",
    website: "http://www.jolietmunicipal.com",
  },
  "16444": {
    loansProduced: "1.86",
    membersServed: "295",
    interestEarned: "0.16",
    name: "RUSHMORE ELECTRIC",
    website: "http://www.refcu.coop",
  },
  "16449": {
    loansProduced: "0.93",
    membersServed: "109",
    interestEarned: "0.08",
    name: "NORTHEAST MISSISSIPPI",
    website: null,
  },
  "16453": {
    loansProduced: "0.54",
    membersServed: "82",
    interestEarned: "0.05",
    name: "SCHOOL DISTRICT 218 EMPLOYEES",
    website: null,
  },
  "16476": {
    loansProduced: "2.29",
    membersServed: "537",
    interestEarned: "0.19",
    name: "NUVISTA",
    website: "http://www.nuvista.org",
  },
  "16479": {
    loansProduced: "11.13",
    membersServed: "1,285",
    interestEarned: "0.95",
    name: "FOUR SEASONS",
    website: "http://www.fourseasonsfcu.com",
  },
  "16500": {
    loansProduced: "10.77",
    membersServed: "1,300",
    interestEarned: "0.92",
    name: "ACIPCO",
    website: "http://www.acipcofcu.org",
  },
  "16520": {
    loansProduced: "0.21",
    membersServed: "12",
    interestEarned: "0.02",
    name: "TVA MID-SOUTH",
    website: null,
  },
  "16525": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "MOUNT CARMEL BAPTIST",
    website: null,
  },
  "16547": {
    loansProduced: "0.03",
    membersServed: "1",
    interestEarned: "0.00",
    name: "SAN FRANCISCO LEE",
    website: "http://WWW.LEECU.COM",
  },
  "16556": {
    loansProduced: "7.03",
    membersServed: "383",
    interestEarned: "0.60",
    name: "SOLUTIONS FIRST",
    website: "http://www.solutionsfirstcu.com",
  },
  "16570": {
    loansProduced: "0.08",
    membersServed: "3",
    interestEarned: "0.01",
    name: "LOS ANGELES LEE",
    website: null,
  },
  "16571": {
    loansProduced: "2.00",
    membersServed: "582",
    interestEarned: "0.17",
    name: "L.A. HEALTHCARE",
    website: "http://www.LAHFCU.ORG",
  },
  "16574": {
    loansProduced: "163.93",
    membersServed: "12,161",
    interestEarned: "13.93",
    name: "PATRIOT",
    website: "http://www.patriotfcu.org",
  },
  "16577": {
    loansProduced: "3.66",
    membersServed: "312",
    interestEarned: "0.31",
    name: "UNITED POLES",
    website: "WWW.UNITEDPOLESFCU.COM",
  },
  "16584": {
    loansProduced: "1.20",
    membersServed: "139",
    interestEarned: "0.10",
    name: "FAIRLEIGH DICKINSON UNIVERSITY",
    website: "http://www.fdufcu.org",
  },
  "16605": {
    loansProduced: "5.47",
    membersServed: "577",
    interestEarned: "0.47",
    name: "HOPEWELL CHEMICAL",
    website: "http://www.hopechemfcu.org",
  },
  "16614": {
    loansProduced: "0.01",
    membersServed: "4",
    interestEarned: "0.00",
    name: "NATCO EMPLOYEES",
    website: null,
  },
  "16619": {
    loansProduced: "2.53",
    membersServed: "1,795",
    interestEarned: "0.21",
    name: "FINANCIAL PARTNERS",
    website: "http://www.financialpartnersfcu.org",
  },
  "16626": {
    loansProduced: "83.77",
    membersServed: "8,837",
    interestEarned: "7.12",
    name: "HERITAGE",
    website: "http://www.heritagefederal.org",
  },
  "16636": {
    loansProduced: "0.64",
    membersServed: "47",
    interestEarned: "0.05",
    name: "PENN HILLS MUNICIPAL",
    website: null,
  },
  "16655": {
    loansProduced: "2.63",
    membersServed: "285",
    interestEarned: "0.22",
    name: "S C I",
    website: "http://www.scifcu.com",
  },
  "16657": {
    loansProduced: "9.34",
    membersServed: "1,439",
    interestEarned: "0.79",
    name: "LANCO",
    website: "http://www.lancofcu.com",
  },
  "16672": {
    loansProduced: "2.33",
    membersServed: "200",
    interestEarned: "0.20",
    name: "COBBLESTONE COUNTRY",
    website: "http://www.cobblestonecountryfcu.co",
  },
  "16682": {
    loansProduced: "0.74",
    membersServed: "148",
    interestEarned: "0.06",
    name: "S T O F F E",
    website: null,
  },
  "16699": {
    loansProduced: "1.47",
    membersServed: "123",
    interestEarned: "0.12",
    name: "BILOXI TEACHERS",
    website: "https://www.biloxischools.net/domai",
  },
  "16702": {
    loansProduced: "1.33",
    membersServed: "271",
    interestEarned: "0.11",
    name: "YOUR HOMETOWN",
    website: "http://www.yourhometownfcu.org",
  },
  "16706": {
    loansProduced: "28.34",
    membersServed: "1,901",
    interestEarned: "2.41",
    name: "N A E",
    website: "http://www.naefcu.org",
  },
  "16728": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "PINN MEMORIAL",
    website: null,
  },
  "16732": {
    loansProduced: "1.89",
    membersServed: "219",
    interestEarned: "0.16",
    name: "WEATHERHEAD C C",
    website: "http://www.weatherheadfcu.com",
  },
  "16746": {
    loansProduced: "0.55",
    membersServed: "50",
    interestEarned: "0.05",
    name: "LEHIGH COUNTY EMPLOYEES",
    website: "http://www.lehighcountycu.com",
  },
  "16754": {
    loansProduced: "2.30",
    membersServed: "483",
    interestEarned: "0.20",
    name: "FOUR CORNERS",
    website: "http://fourcornersfcu.org",
  },
  "16755": {
    loansProduced: "1.01",
    membersServed: "86",
    interestEarned: "0.09",
    name: "SSMOK EMPLOYEES",
    website: "http://www.SSMOKEFCU.COM",
  },
  "16756": {
    loansProduced: "1.04",
    membersServed: "179",
    interestEarned: "0.09",
    name: "VALLEY ONE COMMUNITY",
    website: "http://valleyonecfcu.com",
  },
  "16769": {
    loansProduced: "0.15",
    membersServed: "34",
    interestEarned: "0.01",
    name: "DELAWARE RIVER & BAY AUTH EMP",
    website: null,
  },
  "16770": {
    loansProduced: "0.49",
    membersServed: "43",
    interestEarned: "0.04",
    name: "MUW EMPLOYEES",
    website: "http://www.muwfederalcreditunion.com",
  },
  "16773": {
    loansProduced: "7.47",
    membersServed: "847",
    interestEarned: "0.63",
    name: "COPPER BASIN",
    website: "http://www.cbfcreditunion.com",
  },
  "16785": {
    loansProduced: "2.77",
    membersServed: "378",
    interestEarned: "0.24",
    name: "ADVANCED FINANCIAL",
    website: "http://www.advfinfcu.com",
  },
  "16790": {
    loansProduced: "0.04",
    membersServed: "6",
    interestEarned: "0.00",
    name: "UNION BAPTIST GREENBURGH",
    website: null,
  },
  "16813": {
    loansProduced: "6.28",
    membersServed: "656",
    interestEarned: "0.53",
    name: "CAPROCK",
    website: "http://WWW.CAPROCKCU.COM",
  },
  "16858": {
    loansProduced: "0.11",
    membersServed: "17",
    interestEarned: "0.01",
    name: "NEW PILGRIM",
    website: "http://www.npfcu.com",
  },
  "16893": {
    loansProduced: "1.83",
    membersServed: "197",
    interestEarned: "0.16",
    name: "NEWRIZONS",
    website: "http://www.newrizonsfcu.com",
  },
  "16933": {
    loansProduced: "3.63",
    membersServed: "591",
    interestEarned: "0.31",
    name: "PARKS HERITAGE",
    website: "http://www.parksheritagefcu.org",
  },
  "16946": {
    loansProduced: "10.30",
    membersServed: "744",
    interestEarned: "0.88",
    name: "UARK",
    website: "http://www.uarkfcu.com",
  },
  "16947": {
    loansProduced: "2.12",
    membersServed: "354",
    interestEarned: "0.18",
    name: "K I T",
    website: "http://www.kitfcu.org",
  },
  "16951": {
    loansProduced: "2.40",
    membersServed: "159",
    interestEarned: "0.20",
    name: "CONSUMERS",
    website: "http://CONSUMERSFCU.ORG",
  },
  "16954": {
    loansProduced: "1.38",
    membersServed: "209",
    interestEarned: "0.12",
    name: "FOX",
    website: "https://www.foxcredit.org",
  },
  "16979": {
    loansProduced: "0.22",
    membersServed: "15",
    interestEarned: "0.02",
    name: "LOCAL 606 ELECTRICAL WORKERS",
    website: "http://www.local606fcu.org",
  },
  "16989": {
    loansProduced: "1.20",
    membersServed: "114",
    interestEarned: "0.10",
    name: "SOUTH CHARLESTON EMPLOYEES",
    website: null,
  },
  "16993": {
    loansProduced: "16.16",
    membersServed: "2,731",
    interestEarned: "1.37",
    name: "PEOPLES",
    website: "http://www.Peoplesfcu.com",
  },
  "17012": {
    loansProduced: "54.17",
    membersServed: "6,163",
    interestEarned: "4.60",
    name: "PROFED",
    website: "http://www.profedcu.org",
  },
  "17014": {
    loansProduced: "0.56",
    membersServed: "53",
    interestEarned: "0.05",
    name: "PENN-TRAFFORD SCHOOL EMP.",
    website: null,
  },
  "17017": {
    loansProduced: "25.29",
    membersServed: "2,107",
    interestEarned: "2.15",
    name: "COCA-COLA",
    website: "http://creditunion.coca-cola.com",
  },
  "17023": {
    loansProduced: "0.21",
    membersServed: "21",
    interestEarned: "0.02",
    name: "DISTRICT 123",
    website: "http://d123.virtualcu.net",
  },
  "17040": {
    loansProduced: "6.23",
    membersServed: "854",
    interestEarned: "0.53",
    name: "BAR-CONS",
    website: "http://www.barcons.org",
  },
  "17057": {
    loansProduced: "9.07",
    membersServed: "706",
    interestEarned: "0.77",
    name: "WEST VIRGINIA",
    website: "http://www.wvfcu.org",
  },
  "17067": {
    loansProduced: "0.32",
    membersServed: "25",
    interestEarned: "0.03",
    name: "OUR MOTHER OF MERCY PARISH HOUSTON",
    website: "http://www.omomfcu.org",
  },
  "17100": {
    loansProduced: "11.12",
    membersServed: "1,054",
    interestEarned: "0.95",
    name: "ALLIED",
    website: "https://www.alliedfcu.com/",
  },
  "17105": {
    loansProduced: "0.16",
    membersServed: "12",
    interestEarned: "0.01",
    name: "PEAR ORCHARD",
    website: null,
  },
  "17107": {
    loansProduced: "0.22",
    membersServed: "319",
    interestEarned: "0.02",
    name: "UKRAINIAN NATIONAL",
    website: "http://www.ukrnatfcu.org",
  },
  "17112": {
    loansProduced: "1.17",
    membersServed: "80",
    interestEarned: "0.10",
    name: "NORWOOD TOWN EMPLOYEES",
    website: "http://norwoodcreditunion.com",
  },
  "17117": {
    loansProduced: "72.70",
    membersServed: "7,673",
    interestEarned: "6.18",
    name: "R.I.A.",
    website: "http://riafcu.com",
  },
  "17126": {
    loansProduced: "0.05",
    membersServed: "11",
    interestEarned: "0.00",
    name: "EAGLE CAN EMPLOYEES",
    website: null,
  },
  "17127": {
    loansProduced: "22.88",
    membersServed: "4,602",
    interestEarned: "1.94",
    name: "TRUGROCER",
    website: "http://www.trugrocer.com",
  },
  "17136": {
    loansProduced: "0.18",
    membersServed: "14",
    interestEarned: "0.02",
    name: "MT PLEASANT AREA SCHOOL EMP",
    website: null,
  },
  "17154": {
    loansProduced: "0.83",
    membersServed: "80",
    interestEarned: "0.07",
    name: "SEMINOLE PUBLIC SCHOOLS",
    website: null,
  },
  "17218": {
    loansProduced: "1.81",
    membersServed: "141",
    interestEarned: "0.15",
    name: "INTEGRIS",
    website: "http://www.integrisfcu.org",
  },
  "17224": {
    loansProduced: "14.63",
    membersServed: "1,304",
    interestEarned: "1.24",
    name: "ASPEN",
    website: "www.aspenfcu.org",
  },
  "17232": {
    loansProduced: "5.25",
    membersServed: "421",
    interestEarned: "0.45",
    name: "LASSEN COUNTY",
    website: "http://www.lassencreditunion.com",
  },
  "17243": {
    loansProduced: "0.06",
    membersServed: "3",
    interestEarned: "0.01",
    name: "ASSUMPTION BEAUMONT",
    website: null,
  },
  "17250": {
    loansProduced: "0.25",
    membersServed: "40",
    interestEarned: "0.02",
    name: "CO-NE",
    website: "http://www.co-nefcu.net",
  },
  "17253": {
    loansProduced: "1.59",
    membersServed: "176",
    interestEarned: "0.14",
    name: "ILA 1351",
    website: "http://www.ila1351fcu.com",
  },
  "17254": {
    loansProduced: "9.44",
    membersServed: "950",
    interestEarned: "0.80",
    name: "FANNIN",
    website: "www.fannincu.com",
  },
  "17269": {
    loansProduced: "0.00",
    membersServed: "3",
    interestEarned: "0.00",
    name: "HOLY TRINITY BAPTIST",
    website: null,
  },
  "17311": {
    loansProduced: "0.25",
    membersServed: "61",
    interestEarned: "0.02",
    name: "DEMOPOLIS",
    website: "http://demopolisfcu.com",
  },
  "17323": {
    loansProduced: "1.71",
    membersServed: "296",
    interestEarned: "0.15",
    name: "LUZERNE COUNTY",
    website: "http://www.luzernecountyfcu.virtual",
  },
  "17327": {
    loansProduced: "0.73",
    membersServed: "93",
    interestEarned: "0.06",
    name: "LAN-FAIR",
    website: "http://WWW.LANFAIRFCU.COM",
  },
  "17331": {
    loansProduced: "0.64",
    membersServed: "60",
    interestEarned: "0.05",
    name: "S AND J SCHOOL EMPLOYEES",
    website: null,
  },
  "17335": {
    loansProduced: "4.87",
    membersServed: "789",
    interestEarned: "0.41",
    name: "ILLINOIS STATE POLICE",
    website: "http://www.ispfcu.org",
  },
  "17339": {
    loansProduced: "0.96",
    membersServed: "99",
    interestEarned: "0.08",
    name: "A H E PLANT 3",
    website: "www.aheplant3fcu.com",
  },
  "17361": {
    loansProduced: "0.05",
    membersServed: "2",
    interestEarned: "0.00",
    name: "MUSICIANS",
    website: "http://www.musiciansdfw.org",
  },
  "17362": {
    loansProduced: "12.01",
    membersServed: "1,004",
    interestEarned: "1.02",
    name: "LINCOLN MAINE",
    website: "http://WWW.LINCOLNMAINEFCU.COM",
  },
  "17371": {
    loansProduced: "0.38",
    membersServed: "60",
    interestEarned: "0.03",
    name: "SMMH",
    website: null,
  },
  "17372": {
    loansProduced: "2.62",
    membersServed: "260",
    interestEarned: "0.22",
    name: "LOCKPORT SCHOOLS",
    website: "https://www.lscfcu.org",
  },
  "17389": {
    loansProduced: "10.84",
    membersServed: "1,605",
    interestEarned: "0.92",
    name: "ALLEGIUS",
    website: "http://allegius.org",
  },
  "17395": {
    loansProduced: "1.78",
    membersServed: "206",
    interestEarned: "0.15",
    name: "IRCO COMMUNITY",
    website: "www.ircocu.com",
  },
  "17396": {
    loansProduced: "0.58",
    membersServed: "175",
    interestEarned: "0.05",
    name: "CONCORDIA PARISH SCHOOL EMP",
    website: null,
  },
  "17398": {
    loansProduced: "10.93",
    membersServed: "1,796",
    interestEarned: "0.93",
    name: "MAINE HIGHLANDS",
    website: "http://www.mhfcu.com",
  },
  "17411": {
    loansProduced: "3.70",
    membersServed: "277",
    interestEarned: "0.31",
    name: "DIVERSIFIED GENERAL",
    website: "http://www.yourdgfcu.org",
  },
  "17415": {
    loansProduced: "4.37",
    membersServed: "512",
    interestEarned: "0.37",
    name: "BOURNS EMPLOYEES",
    website: "http://www.bournsfcu.org",
  },
  "17429": {
    loansProduced: "235.69",
    membersServed: "39,935",
    interestEarned: "20.03",
    name: "VISIONS",
    website: "www.visionsfcu.org",
  },
  "17436": {
    loansProduced: "1.28",
    membersServed: "99",
    interestEarned: "0.11",
    name: "MAINE SOLUTIONS",
    website: "http://www.mesolutionsfcu.org",
  },
  "17437": {
    loansProduced: "0.51",
    membersServed: "51",
    interestEarned: "0.04",
    name: "STOUGHTON TOWN EMPLOYEES",
    website: null,
  },
  "17449": {
    loansProduced: "2.14",
    membersServed: "368",
    interestEarned: "0.18",
    name: "WAYNE COUNTY COMMUNITY",
    website: "http://www.wccfcu.com",
  },
  "17454": {
    loansProduced: "0.27",
    membersServed: "21",
    interestEarned: "0.02",
    name: "RICHMOND LIGHT EMPLOYEES",
    website: null,
  },
  "17464": {
    loansProduced: "9.48",
    membersServed: "1,766",
    interestEarned: "0.81",
    name: "PEOPLES ADVANTAGE",
    website: "http://peoplesadvfcu.org",
  },
  "17472": {
    loansProduced: "3.67",
    membersServed: "1,146",
    interestEarned: "0.31",
    name: "CHANGING SEASONS",
    website: "http://www.changingseasonsfcu.com",
  },
  "17550": {
    loansProduced: "0.99",
    membersServed: "99",
    interestEarned: "0.08",
    name: "YTR COMMUNITY",
    website: "http://yorkvillefcu.org",
  },
  "17555": {
    loansProduced: "2.85",
    membersServed: "1,286",
    interestEarned: "0.24",
    name: "STEEL VALLEY",
    website: "http://www.steelvalleyfcu.org",
  },
  "17569": {
    loansProduced: "1.99",
    membersServed: "322",
    interestEarned: "0.17",
    name: "H. E. TELEPHONE",
    website: "https://hetelfcu.org",
  },
  "17587": {
    loansProduced: "184.16",
    membersServed: "14,369",
    interestEarned: "15.65",
    name: "USALLIANCE",
    website: "http://WWW.USAlliance.org",
  },
  "17588": {
    loansProduced: "0.14",
    membersServed: "35",
    interestEarned: "0.01",
    name: "BATTERY EMPLOYEES",
    website: null,
  },
  "17623": {
    loansProduced: "97.20",
    membersServed: "16,395",
    interestEarned: "8.26",
    name: "HONDA",
    website: "http://www.hondafcu.org",
  },
  "17630": {
    loansProduced: "4.80",
    membersServed: "505",
    interestEarned: "0.41",
    name: "VANTAGE POINT",
    website: "http://VANTAGEPOINTFCU.ORG",
  },
  "17636": {
    loansProduced: "9.29",
    membersServed: "1,239",
    interestEarned: "0.79",
    name: "SOUTH CAROLINA NATIONAL GUARD",
    website: "http://WWW.SCNATIONALGUARDFCU.ORG",
  },
  "17642": {
    loansProduced: "244.55",
    membersServed: "21,765",
    interestEarned: "20.79",
    name: "ALL IN",
    website: "http://www.allincu.com",
  },
  "17665": {
    loansProduced: "6.43",
    membersServed: "830",
    interestEarned: "0.55",
    name: "COMPASS",
    website: "http://www.compassfcu.com",
  },
  "17675": {
    loansProduced: "0.29",
    membersServed: "24",
    interestEarned: "0.02",
    name: "G E A EMPLOYEES",
    website: null,
  },
  "17679": {
    loansProduced: "1.19",
    membersServed: "133",
    interestEarned: "0.10",
    name: "HEALTH ALLIANCE",
    website: "http://www.myhacu.org",
  },
  "17681": {
    loansProduced: "0.56",
    membersServed: "82",
    interestEarned: "0.05",
    name: "TOMBIGBEE",
    website: "http://www.tombigb.virtualcu.net",
  },
  "17687": {
    loansProduced: "1.47",
    membersServed: "237",
    interestEarned: "0.12",
    name: "NEW CASTLE COUNTY DELAWARE EM",
    website: "http://www.nccdefcu.com",
  },
  "17691": {
    loansProduced: "0.01",
    membersServed: "5",
    interestEarned: "0.00",
    name: "PMI EMPLOYEES",
    website: null,
  },
  "17697": {
    loansProduced: "0.31",
    membersServed: "44",
    interestEarned: "0.03",
    name: "AVOYELLES PARISH SCH BRD EMP",
    website: "http://WWW.ITSME247.COM/855",
  },
  "17715": {
    loansProduced: "0.11",
    membersServed: "54",
    interestEarned: "0.01",
    name: "CITIZENS CHOICE",
    website: "http://www.citizenschoicefcu.org",
  },
  "17733": {
    loansProduced: "1.66",
    membersServed: "269",
    interestEarned: "0.14",
    name: "SYLVANIA AREA",
    website: "http://www.sylvaniaareafcu.com",
  },
  "17752": {
    loansProduced: "3.54",
    membersServed: "270",
    interestEarned: "0.30",
    name: "NORTHWEST MUNICIPAL",
    website: "http://www.mynmfcu.com",
  },
  "17793": {
    loansProduced: "1.06",
    membersServed: "79",
    interestEarned: "0.09",
    name: "N G M EMPLOYEES",
    website: null,
  },
  "17831": {
    loansProduced: "1.87",
    membersServed: "160",
    interestEarned: "0.16",
    name: "SELF MEMORIAL HOSPITAL",
    website: "http://www.selfmemorialfcu.net",
  },
  "17841": {
    loansProduced: "0.88",
    membersServed: "67",
    interestEarned: "0.07",
    name: "VALLEY AGRICULTURAL",
    website: "http://www.valleyagfcu.org",
  },
  "17847": {
    loansProduced: "3.17",
    membersServed: "2,734",
    interestEarned: "0.27",
    name: "COMMON TRUST",
    website: "http://www.commontrustfcu.org",
  },
  "17849": {
    loansProduced: "0.67",
    membersServed: "103",
    interestEarned: "0.06",
    name: "ARKANSAS KRAFT EMPLOYEES",
    website: null,
  },
  "17861": {
    loansProduced: "4.64",
    membersServed: "292",
    interestEarned: "0.39",
    name: "BEAUREGARD SCHOOL EMPLOYEES",
    website: null,
  },
  "17865": {
    loansProduced: "0.18",
    membersServed: "13",
    interestEarned: "0.02",
    name: "M & C MENLO PARK",
    website: "http://www.mcmenlopark.com",
  },
  "17878": {
    loansProduced: "2.32",
    membersServed: "307",
    interestEarned: "0.20",
    name: "LAKE SHORE",
    website: "http://Lakeshore-FCU.Net",
  },
  "17885": {
    loansProduced: "2.07",
    membersServed: "536",
    interestEarned: "0.18",
    name: "TRANSIT WORKERS",
    website: "http://www.twfcu.org",
  },
  "17891": {
    loansProduced: "1.32",
    membersServed: "253",
    interestEarned: "0.11",
    name: "SOUTH TOWNS COMMUNITY",
    website: "http://www.stownsfcu.com",
  },
  "17914": {
    loansProduced: "63.54",
    membersServed: "5,786",
    interestEarned: "5.40",
    name: "SUFFOLK",
    website: "www.suffolkcu.org",
  },
  "17953": {
    loansProduced: "1.50",
    membersServed: "155",
    interestEarned: "0.13",
    name: "TEXSTAR",
    website: "http://www.texstarfcu.com",
  },
  "17955": {
    loansProduced: "6.10",
    membersServed: "500",
    interestEarned: "0.52",
    name: "AUSTIN",
    website: "http://www.austinfcu.com",
  },
  "17969": {
    loansProduced: "0.20",
    membersServed: "25",
    interestEarned: "0.02",
    name: "TERMINALS",
    website: null,
  },
  "17990": {
    loansProduced: "34.71",
    membersServed: "3,527",
    interestEarned: "2.95",
    name: "WAUNA",
    website: "http://www.waunafcu.org",
  },
  "17999": {
    loansProduced: "24.43",
    membersServed: "4,057",
    interestEarned: "2.08",
    name: "TRIUS",
    website: "http://triusfcu.com",
  },
  "18013": {
    loansProduced: "0.21",
    membersServed: "22",
    interestEarned: "0.02",
    name: "PLUMBERS 55",
    website: null,
  },
  "18022": {
    loansProduced: "22.41",
    membersServed: "3,015",
    interestEarned: "1.90",
    name: "FINGER LAKES",
    website: "http://www.flfcu.org",
  },
  "18067": {
    loansProduced: "3.11",
    membersServed: "346",
    interestEarned: "0.26",
    name: "MANISTIQUE",
    website: "http://manistiquefederal.com",
  },
  "18068": {
    loansProduced: "1.90",
    membersServed: "1,536",
    interestEarned: "0.16",
    name: "MT. RAINIER",
    website: "http://www.mtrainierfcu.com",
  },
  "18090": {
    loansProduced: "9.09",
    membersServed: "895",
    interestEarned: "0.77",
    name: "ST. PIUS X CHURCH",
    website: "http://www.spxfcu.org",
  },
  "18142": {
    loansProduced: "0.25",
    membersServed: "31",
    interestEarned: "0.02",
    name: "CITY OF FAIRMONT",
    website: null,
  },
  "18144": {
    loansProduced: "0.58",
    membersServed: "81",
    interestEarned: "0.05",
    name: "MONTOURSVILLE AREA",
    website: "https://www.netit.financial-net.com",
  },
  "18150": {
    loansProduced: "4.08",
    membersServed: "386",
    interestEarned: "0.35",
    name: "CAMP SHELBY",
    website: "www.campshelbycreditunion.us",
  },
  "18172": {
    loansProduced: "3.24",
    membersServed: "483",
    interestEarned: "0.28",
    name: "CHEMCO",
    website: "http://WWW.CHEMCOFCU.COM",
  },
  "18175": {
    loansProduced: "44.05",
    membersServed: "4,154",
    interestEarned: "3.74",
    name: "HENRICO",
    website: "http://www.henricofcu.org",
  },
  "18181": {
    loansProduced: "39.74",
    membersServed: "5,188",
    interestEarned: "3.38",
    name: "UMASSFIVE COLLEGE",
    website: "http://www.umassfive.coop",
  },
  "18192": {
    loansProduced: "1.78",
    membersServed: "134",
    interestEarned: "0.15",
    name: "MEAD COATED BOARD",
    website: null,
  },
  "18195": {
    loansProduced: "21.39",
    membersServed: "1,976",
    interestEarned: "1.82",
    name: "UNITED TELETECH FINANCIAL",
    website: "http://www.utfinancial.org",
  },
  "18204": {
    loansProduced: "19.96",
    membersServed: "1,907",
    interestEarned: "1.70",
    name: "NORTHERN SKIES",
    website: "http://www.nsfcu.org",
  },
  "18218": {
    loansProduced: "0.07",
    membersServed: "5",
    interestEarned: "0.01",
    name: "TEXAS LEE",
    website: "http://www.texasleefcu.net",
  },
  "18234": {
    loansProduced: "0.28",
    membersServed: "21",
    interestEarned: "0.02",
    name: "STEAMFITTERS PHILA",
    website: null,
  },
  "18238": {
    loansProduced: "1.23",
    membersServed: "208",
    interestEarned: "0.10",
    name: "NYM",
    website: "http://www.nymfcu.org",
  },
  "18242": {
    loansProduced: "8.20",
    membersServed: "848",
    interestEarned: "0.70",
    name: "SUSQUEHANNA VALLEY",
    website: "https://www.svfcu.org",
  },
  "18254": {
    loansProduced: "1.41",
    membersServed: "82",
    interestEarned: "0.12",
    name: "HARRISON COUNTY",
    website: "http://harrisoncountyfcu.net",
  },
  "18271": {
    loansProduced: "23.90",
    membersServed: "2,757",
    interestEarned: "2.03",
    name: "PRINCE GEORGE'S COMMUNITY",
    website: "http://www.princegeorgescfcu.org",
  },
  "18273": {
    loansProduced: "6.16",
    membersServed: "1,169",
    interestEarned: "0.52",
    name: "AMERICAN BROADCAST EMPLOYEES",
    website: "http://www.abefcu.org",
  },
  "18297": {
    loansProduced: "689.76",
    membersServed: "43,599",
    interestEarned: "58.63",
    name: "COASTAL",
    website: "http://www.coastal24.com",
  },
  "18301": {
    loansProduced: "1.01",
    membersServed: "80",
    interestEarned: "0.09",
    name: "VASCO",
    website: "http://www.vascofcu.com",
  },
  "18312": {
    loansProduced: "1.26",
    membersServed: "91",
    interestEarned: "0.11",
    name: "GIBBS ALUMINUM",
    website: "http://www.gibbsafcu.com",
  },
  "18336": {
    loansProduced: "173.42",
    membersServed: "12,365",
    interestEarned: "14.74",
    name: "COMMUNITYWIDE",
    website: "http://www.comwide.com",
  },
  "18343": {
    loansProduced: "0.24",
    membersServed: "36",
    interestEarned: "0.02",
    name: "USEM MENA",
    website: "http://usem.virtualcu.net/",
  },
  "18353": {
    loansProduced: "1.94",
    membersServed: "119",
    interestEarned: "0.16",
    name: "PRIORITY",
    website: "http://www.priorityfederal.us",
  },
  "18393": {
    loansProduced: "6.94",
    membersServed: "776",
    interestEarned: "0.59",
    name: "POLICEMEN'S",
    website: "http://WWW.POLICEMENSFCU.ORG",
  },
  "18405": {
    loansProduced: "0.70",
    membersServed: "69",
    interestEarned: "0.06",
    name: "CHULA VISTA CITY EMPLOYEES",
    website: null,
  },
  "18409": {
    loansProduced: "1.14",
    membersServed: "205",
    interestEarned: "0.10",
    name: "ALLIANCE NIAGARA",
    website: "http://www.allianceniagara.com",
  },
  "18422": {
    loansProduced: "0.07",
    membersServed: "4",
    interestEarned: "0.01",
    name: "LATVIAN",
    website: null,
  },
  "18423": {
    loansProduced: "0.71",
    membersServed: "108",
    interestEarned: "0.06",
    name: "S. C. H. D. DIST #7",
    website: null,
  },
  "18446": {
    loansProduced: "0.15",
    membersServed: "18",
    interestEarned: "0.01",
    name: "ASBESTOS WORKERS LOCAL 14",
    website: null,
  },
  "18454": {
    loansProduced: "0.57",
    membersServed: "54",
    interestEarned: "0.05",
    name: "VALLEY HILLS",
    website: "http://vhfcu.org",
  },
  "18462": {
    loansProduced: "0.46",
    membersServed: "59",
    interestEarned: "0.04",
    name: "T E A",
    website: "http://www.teafcu.org",
  },
  "18473": {
    loansProduced: "18.91",
    membersServed: "1,750",
    interestEarned: "1.61",
    name: "NORTH SHORE",
    website: "https://www.northshorefcu.org",
  },
  "18474": {
    loansProduced: "1.79",
    membersServed: "199",
    interestEarned: "0.15",
    name: "COMBINED",
    website: "http://www.combinedfcu.org",
  },
  "18515": {
    loansProduced: "7.74",
    membersServed: "789",
    interestEarned: "0.66",
    name: "FERGUSON",
    website: "http://fergusonfcu.org",
  },
  "18528": {
    loansProduced: "0.02",
    membersServed: "6",
    interestEarned: "0.00",
    name: "SAINT JOHN A M E",
    website: null,
  },
  "18550": {
    loansProduced: "18.08",
    membersServed: "2,410",
    interestEarned: "1.54",
    name: "R T P",
    website: "http://www.rtpfcu.org",
  },
  "18559": {
    loansProduced: "2.58",
    membersServed: "471",
    interestEarned: "0.22",
    name: "STARR COUNTY TEACHERS",
    website: "http://www.starrfcu.com",
  },
  "18562": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "MT ZION WOODLAWN",
    website: null,
  },
  "18574": {
    loansProduced: "6.20",
    membersServed: "1,156",
    interestEarned: "0.53",
    name: "AURORA",
    website: "http://www.auroracu.com",
  },
  "18582": {
    loansProduced: "0.57",
    membersServed: "57",
    interestEarned: "0.05",
    name: "FRANKLIN REGIONAL SCHOOLS",
    website: "http://www.frsfcu.com",
  },
  "18616": {
    loansProduced: "8.74",
    membersServed: "2,035",
    interestEarned: "0.74",
    name: "DUKE UNIVERSITY",
    website: "http://www.dukefcu.org",
  },
  "18623": {
    loansProduced: "5.06",
    membersServed: "636",
    interestEarned: "0.43",
    name: "CALCOM",
    website: "http://www.calcomcu.org",
  },
  "18628": {
    loansProduced: "0.38",
    membersServed: "83",
    interestEarned: "0.03",
    name: "MIDDLESEX COUNTY N J EMP",
    website: "http://mccunj.cuwebservices.com",
  },
  "18635": {
    loansProduced: "0.66",
    membersServed: "286",
    interestEarned: "0.06",
    name: "PORT WASHINGTON",
    website: "http://www.pwfcu.org",
  },
  "18649": {
    loansProduced: "4.32",
    membersServed: "582",
    interestEarned: "0.37",
    name: "EVER $ GREEN",
    website: "http://www.evergreenfcu.com",
  },
  "18651": {
    loansProduced: "1.41",
    membersServed: "113",
    interestEarned: "0.12",
    name: "ST. JOSEPH MEDICAL CENTER MD",
    website: "http://www.sjmcfcu.org",
  },
  "18708": {
    loansProduced: "267.53",
    membersServed: "38,112",
    interestEarned: "22.74",
    name: "PARTNERS",
    website: "http://www.partnersfcu.org",
  },
  "18710": {
    loansProduced: "40.58",
    membersServed: "4,077",
    interestEarned: "3.45",
    name: "GARDEN SAVINGS",
    website: "http://Gardensavingsfcu.org",
  },
  "18716": {
    loansProduced: "9.41",
    membersServed: "2,341",
    interestEarned: "0.80",
    name: "MID-ATLANTIC",
    website: "http://www.mafcu.org",
  },
  "18749": {
    loansProduced: "6.03",
    membersServed: "653",
    interestEarned: "0.51",
    name: "705",
    website: "http://www.705fcu.com",
  },
  "18759": {
    loansProduced: "0.11",
    membersServed: "30",
    interestEarned: "0.01",
    name: "OCEAN COUNTY EMPLOYEES",
    website: null,
  },
  "18765": {
    loansProduced: "4.75",
    membersServed: "770",
    interestEarned: "0.40",
    name: "LOUVIERS",
    website: "http://www.louviers.com",
  },
  "18775": {
    loansProduced: "0.33",
    membersServed: "35",
    interestEarned: "0.03",
    name: "GREATER LATROBE SCHOOLS",
    website: null,
  },
  "18800": {
    loansProduced: "3.53",
    membersServed: "608",
    interestEarned: "0.30",
    name: "GREATER WOODLAWN",
    website: "http://GRWFCU.COM",
  },
  "18814": {
    loansProduced: "0.88",
    membersServed: "107",
    interestEarned: "0.08",
    name: "GARLAND COUNTY EDUCATORS",
    website: "http://www.gcefcuhotsprings.org",
  },
  "18816": {
    loansProduced: "0.93",
    membersServed: "68",
    interestEarned: "0.08",
    name: "S.T.E.C.",
    website: null,
  },
  "18823": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "STS PETER & PAUL",
    website: null,
  },
  "18857": {
    loansProduced: "0.59",
    membersServed: "109",
    interestEarned: "0.05",
    name: "OUR SUNDAY VISITOR EMPLOYEES",
    website: "http://www.oursundayvisitorefcu.com",
  },
  "18858": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "SPC BROOKLYN",
    website: null,
  },
  "18861": {
    loansProduced: "0.10",
    membersServed: "7",
    interestEarned: "0.01",
    name: "SYMPHONY",
    website: null,
  },
  "18868": {
    loansProduced: "16.34",
    membersServed: "1,323",
    interestEarned: "1.39",
    name: "BULL DOG",
    website: "http://bdfcu.com",
  },
  "18882": {
    loansProduced: "0.05",
    membersServed: "8",
    interestEarned: "0.00",
    name: "C T A F C",
    website: null,
  },
  "18917": {
    loansProduced: "20.91",
    membersServed: "3,086",
    interestEarned: "1.78",
    name: "SKYPOINT",
    website: "http://www.skypointfcu.com",
  },
  "18935": {
    loansProduced: "34.24",
    membersServed: "2,369",
    interestEarned: "2.91",
    name: "CAL-COM",
    website: "http://www.calcomfcu.org",
  },
  "18948": {
    loansProduced: "1.93",
    membersServed: "334",
    interestEarned: "0.16",
    name: "MNCPPC",
    website: "http://www.mncppcfcu.com",
  },
  "18950": {
    loansProduced: "0.01",
    membersServed: "6",
    interestEarned: "0.00",
    name: "MCDOWELL COUNTY",
    website: null,
  },
  "18962": {
    loansProduced: "2.66",
    membersServed: "220",
    interestEarned: "0.23",
    name: "CALCASIEU PARISH EMPLOYEES",
    website: "http://www.cpefcu.com",
  },
  "18964": {
    loansProduced: "2.06",
    membersServed: "175",
    interestEarned: "0.18",
    name: "BITTERROOT COMMUNITY",
    website: "http://www.bitterrootcommunityfcu.c",
  },
  "18965": {
    loansProduced: "26.50",
    membersServed: "1,501",
    interestEarned: "2.25",
    name: "OKLAHOMA COMMUNITY",
    website: "http://www.okcommunitycu.com",
  },
  "19017": {
    loansProduced: "0.70",
    membersServed: "77",
    interestEarned: "0.06",
    name: "ST. PATS EMPLOYEES",
    website: "http://www.StPatsCU.com",
  },
  "19027": {
    loansProduced: "1.79",
    membersServed: "139",
    interestEarned: "0.15",
    name: "CATTARAUGUS COUNTY EMPLOYEES",
    website: "http://www.cattcountyefcu.com",
  },
  "19045": {
    loansProduced: "0.87",
    membersServed: "82",
    interestEarned: "0.07",
    name: "NEWELL",
    website: "NEWELLFCU.ORG",
  },
  "19047": {
    loansProduced: "1.83",
    membersServed: "131",
    interestEarned: "0.16",
    name: "EMERALD CREDIT ASSOCIATION",
    website: "http://www.emeraldcu.com",
  },
  "19085": {
    loansProduced: "12.71",
    membersServed: "1,811",
    interestEarned: "1.08",
    name: "PITTSFORD",
    website: "http://www.pittsfordfcu.org",
  },
  "19116": {
    loansProduced: "28.48",
    membersServed: "4,954",
    interestEarned: "2.42",
    name: "GREENVILLE",
    website: "http://greenvillefcu.com",
  },
  "19131": {
    loansProduced: "0.36",
    membersServed: "57",
    interestEarned: "0.03",
    name: "A L E C",
    website: "http://www.alecfcu.com",
  },
  "19158": {
    loansProduced: "0.13",
    membersServed: "19",
    interestEarned: "0.01",
    name: "JOY EMPLOYEES",
    website: null,
  },
  "19171": {
    loansProduced: "3.85",
    membersServed: "197",
    interestEarned: "0.33",
    name: "NORTHEAST PANHANDLE TEACHERS",
    website: "http://www.nptfcu.com",
  },
  "19185": {
    loansProduced: "9.69",
    membersServed: "796",
    interestEarned: "0.82",
    name: "SEAPORT",
    website: "http://www.seaportfcu.org",
  },
  "19203": {
    loansProduced: "2.36",
    membersServed: "211",
    interestEarned: "0.20",
    name: "GREENWICH MUNICIPAL EMPLOYEES",
    website: "http://www.gmefcu.com",
  },
  "19216": {
    loansProduced: "3.58",
    membersServed: "1,689",
    interestEarned: "0.30",
    name: "N Y TEAM",
    website: "http://www.nyteamfcu.org",
  },
  "19228": {
    loansProduced: "21.04",
    membersServed: "1,381",
    interestEarned: "1.79",
    name: "CROSS VALLEY",
    website: "http://www.crossvalleyfcu.org",
  },
  "19249": {
    loansProduced: "1.36",
    membersServed: "204",
    interestEarned: "0.12",
    name: "ACADIANA MEDICAL",
    website: "http://www.myamfcu.com",
  },
  "19253": {
    loansProduced: "0.18",
    membersServed: "26",
    interestEarned: "0.02",
    name: "ISSAQUENA COUNTY",
    website: null,
  },
  "19263": {
    loansProduced: "110.93",
    membersServed: "18,394",
    interestEarned: "9.43",
    name: "NEW ENGLAND",
    website: "http://www.nefcu.com",
  },
  "19269": {
    loansProduced: "14.65",
    membersServed: "1,764",
    interestEarned: "1.24",
    name: "OCEAN FINANCIAL",
    website: "http://oceanfinancial.org",
  },
  "19280": {
    loansProduced: "1.08",
    membersServed: "106",
    interestEarned: "0.09",
    name: "EASTERN KENTUCKY",
    website: "http://www.ekyfcu.com",
  },
  "19285": {
    loansProduced: "0.17",
    membersServed: "21",
    interestEarned: "0.01",
    name: "NORTHERN MONTANA HOSPITAL",
    website: null,
  },
  "19295": {
    loansProduced: "1.16",
    membersServed: "81",
    interestEarned: "0.10",
    name: "LIVINGSTON PARISH",
    website: "http://WWW.LPFCU.ORG",
  },
  "19304": {
    loansProduced: "11.30",
    membersServed: "2,122",
    interestEarned: "0.96",
    name: "FAMILY FIRST OF NY",
    website: "http://www.familyfirstny.com",
  },
  "19307": {
    loansProduced: "51.16",
    membersServed: "9,411",
    interestEarned: "4.35",
    name: "PURDUE",
    website: "https://www.purduefed.com",
  },
  "19319": {
    loansProduced: "1.90",
    membersServed: "198",
    interestEarned: "0.16",
    name: "JEFFERSON COMMUNITY",
    website: "http://jcfcu.com",
  },
  "19320": {
    loansProduced: "7.44",
    membersServed: "699",
    interestEarned: "0.63",
    name: "GP LOUISIANA",
    website: "http://www.gplafcu.com",
  },
  "19355": {
    loansProduced: "12.15",
    membersServed: "891",
    interestEarned: "1.03",
    name: "SPACE AGE TULSA",
    website: "http://www.spaceagetulsafcu.com",
  },
  "19390": {
    loansProduced: "0.56",
    membersServed: "82",
    interestEarned: "0.05",
    name: "DEL MET",
    website: "http://www.delmetfcu.com",
  },
  "19401": {
    loansProduced: "35.62",
    membersServed: "4,559",
    interestEarned: "3.03",
    name: "TEG",
    website: "http://TEGFCU.COM",
  },
  "19416": {
    loansProduced: "0.27",
    membersServed: "50",
    interestEarned: "0.02",
    name: "GLAMORGAN EMPLOYEES",
    website: null,
  },
  "19429": {
    loansProduced: "1.72",
    membersServed: "212",
    interestEarned: "0.15",
    name: "YORK EDUCATIONAL",
    website: "http://www.yefcu.org",
  },
  "19430": {
    loansProduced: "0.45",
    membersServed: "73",
    interestEarned: "0.04",
    name: "TEAMSTERS LOCAL 92",
    website: "http://teamsters92fcu.com",
  },
  "19432": {
    loansProduced: "0.73",
    membersServed: "61",
    interestEarned: "0.06",
    name: "FO ME BO CO",
    website: null,
  },
  "19433": {
    loansProduced: "2.78",
    membersServed: "237",
    interestEarned: "0.24",
    name: "SOFTITE COMMUNITY",
    website: "http://softitecfcu.com",
  },
  "19440": {
    loansProduced: "15.55",
    membersServed: "1,656",
    interestEarned: "1.32",
    name: "MINNESOTA VALLEY",
    website: "http://www.mnvalleyfcu.coop",
  },
  "19446": {
    loansProduced: "0.69",
    membersServed: "68",
    interestEarned: "0.06",
    name: "MIDDLESEX-ESSEX POSTAL EMPLOYEES",
    website: null,
  },
  "19448": {
    loansProduced: "57.48",
    membersServed: "3,379",
    interestEarned: "4.89",
    name: "U. T.",
    website: "http://www.utfcu.org",
  },
  "19454": {
    loansProduced: "0.63",
    membersServed: "138",
    interestEarned: "0.05",
    name: "NJ GATEWAY",
    website: "www.njgateway.org",
  },
  "19501": {
    loansProduced: "0.44",
    membersServed: "74",
    interestEarned: "0.04",
    name: "PACE RESOURCES",
    website: "http://WWW.PACERESOURCESFCU.VIRTUAL",
  },
  "19508": {
    loansProduced: "10.13",
    membersServed: "1,488",
    interestEarned: "0.86",
    name: "QUEST",
    website: "http://www.questfcu.com",
  },
  "19510": {
    loansProduced: "36.14",
    membersServed: "3,952",
    interestEarned: "3.07",
    name: "LEBANON",
    website: "https://lebanonfcu.org",
  },
  "19514": {
    loansProduced: "1.86",
    membersServed: "134",
    interestEarned: "0.16",
    name: "HEMA",
    website: "http://hemafcu.org",
  },
  "19522": {
    loansProduced: "14.14",
    membersServed: "1,773",
    interestEarned: "1.20",
    name: "PROVIDENCE",
    website: "http://www.providencecu.org",
  },
  "19538": {
    loansProduced: "1.66",
    membersServed: "190",
    interestEarned: "0.14",
    name: "AMPOT",
    website: "http://ampotfcu.org",
  },
  "19541": {
    loansProduced: "7.69",
    membersServed: "748",
    interestEarned: "0.65",
    name: "PARK VIEW",
    website: "http://www.pvfcu.org",
  },
  "19552": {
    loansProduced: "12.77",
    membersServed: "1,131",
    interestEarned: "1.09",
    name: "LANIER",
    website: "http://www.LanierFCU.org",
  },
  "19567": {
    loansProduced: "0.98",
    membersServed: "323",
    interestEarned: "0.08",
    name: "THE FLORIST",
    website: "http://www.thefloristfcu.org",
  },
  "19593": {
    loansProduced: "4.75",
    membersServed: "363",
    interestEarned: "0.40",
    name: "SAINT FRANCIS EMPLOYEES",
    website: "http://www.sffcutulsa.org",
  },
  "19605": {
    loansProduced: "1.49",
    membersServed: "220",
    interestEarned: "0.13",
    name: "FINANCIAL EDUCATORS",
    website: "http://www.financialedufcu.com",
  },
  "19608": {
    loansProduced: "2.62",
    membersServed: "305",
    interestEarned: "0.22",
    name: "TRI-CITIES COMMUNITY",
    website: "http://www.tri-cu.com",
  },
  "19619": {
    loansProduced: "0.12",
    membersServed: "9",
    interestEarned: "0.01",
    name: "TRINITY BAPTIST CHURCH",
    website: null,
  },
  "19655": {
    loansProduced: "17.38",
    membersServed: "1,730",
    interestEarned: "1.48",
    name: "PRIORITY FIRST",
    website: "http://www.priorityfirstfcu.org",
  },
  "19665": {
    loansProduced: "0.02",
    membersServed: "6",
    interestEarned: "0.00",
    name: "CURTIS",
    website: null,
  },
  "19668": {
    loansProduced: "2.81",
    membersServed: "581",
    interestEarned: "0.24",
    name: "FIVE STAR OF MARYLAND",
    website: "http://www.fivestarfcu.org",
  },
  "19678": {
    loansProduced: "2.76",
    membersServed: "1,068",
    interestEarned: "0.23",
    name: "MIAMI UNIVERSITY COMMUNITY",
    website: "http://www.muccu.org",
  },
  "19700": {
    loansProduced: "7.84",
    membersServed: "759",
    interestEarned: "0.67",
    name: "MIDDLE TENNESSEE",
    website: "http://www.midtenfcu.com",
  },
  "19708": {
    loansProduced: "2.97",
    membersServed: "270",
    interestEarned: "0.25",
    name: "NORTHERN ILLINOIS",
    website: "http://mynifcu.com",
  },
  "19723": {
    loansProduced: "1.91",
    membersServed: "148",
    interestEarned: "0.16",
    name: "LOCAL 50 PLUMBERS & STEAMFTRS",
    website: null,
  },
  "19732": {
    loansProduced: "6.01",
    membersServed: "608",
    interestEarned: "0.51",
    name: "RAVALLI COUNTY",
    website: "http://ravallicountyfcu.org",
  },
  "19739": {
    loansProduced: "1.18",
    membersServed: "153",
    interestEarned: "0.10",
    name: "POLAM",
    website: "http://WWW.POLAM.ORG",
  },
  "19741": {
    loansProduced: "1.88",
    membersServed: "171",
    interestEarned: "0.16",
    name: "FANNIN COUNTY TEACHERS",
    website: "http://fanninteachersfcu.com",
  },
  "19745": {
    loansProduced: "0.61",
    membersServed: "132",
    interestEarned: "0.05",
    name: "ARMSTRONG CO. FEDERAL EMP.",
    website: null,
  },
  "19775": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "GREATER CENTENNIAL",
    website: "https://greatercentennialfcu.com",
  },
  "19788": {
    loansProduced: "11.10",
    membersServed: "1,111",
    interestEarned: "0.94",
    name: "CAROLINA",
    website: "http://www.carolinafcu.org",
  },
  "19826": {
    loansProduced: "0.03",
    membersServed: "8",
    interestEarned: "0.00",
    name: "SHAW UNIVERSITY",
    website: null,
  },
  "19853": {
    loansProduced: "12.50",
    membersServed: "1,123",
    interestEarned: "1.06",
    name: "ADVANZ",
    website: "http://www.advanzcu.org",
  },
  "19867": {
    loansProduced: "50.79",
    membersServed: "5,795",
    interestEarned: "4.32",
    name: "URW COMMUNITY",
    website: "http://www.urwfcu.org",
  },
  "19879": {
    loansProduced: "5.75",
    membersServed: "931",
    interestEarned: "0.49",
    name: "COVE",
    website: "http://www.covefcu.com",
  },
  "19881": {
    loansProduced: "0.52",
    membersServed: "121",
    interestEarned: "0.04",
    name: "OHIO COUNTY PUBLIC SCHOOLS",
    website: "http://www.ocpsfcu.virtualcu.net",
  },
  "19890": {
    loansProduced: "3.70",
    membersServed: "283",
    interestEarned: "0.31",
    name: "EASTERN PANHANDLE",
    website: "https://www.easternpanhandlefcu.com",
  },
  "19896": {
    loansProduced: "2.60",
    membersServed: "291",
    interestEarned: "0.22",
    name: "BUTTE COMMUNITY",
    website: "http://www.buttecommunityfcu.com",
  },
  "19926": {
    loansProduced: "4.88",
    membersServed: "423",
    interestEarned: "0.41",
    name: "ROCKDALE",
    website: "http://www.rockdalefcu.org",
  },
  "19960": {
    loansProduced: "10.38",
    membersServed: "694",
    interestEarned: "0.88",
    name: "PROCTOR",
    website: "http://www.proctorfcu.com",
  },
  "19973": {
    loansProduced: "1.43",
    membersServed: "151",
    interestEarned: "0.12",
    name: "SUMMIT HAMPTON ROADS",
    website: "http://www.shrfcu.com",
  },
  "19976": {
    loansProduced: "912.78",
    membersServed: "108,492",
    interestEarned: "77.59",
    name: "FIRST TECHNOLOGY",
    website: "www.firsttechfed.com",
  },
  "19983": {
    loansProduced: "1.57",
    membersServed: "149",
    interestEarned: "0.13",
    name: "ILA 28",
    website: null,
  },
  "19985": {
    loansProduced: "0.49",
    membersServed: "66",
    interestEarned: "0.04",
    name: "ARABI SUGAR WORKERS",
    website: null,
  },
  "20002": {
    loansProduced: "6.85",
    membersServed: "628",
    interestEarned: "0.58",
    name: "LA TERRE",
    website: "http://www.laterrefcu.org",
  },
  "20004": {
    loansProduced: "0.10",
    membersServed: "34",
    interestEarned: "0.01",
    name: "IBEW LOCAL UNION 80",
    website: null,
  },
  "20007": {
    loansProduced: "10.72",
    membersServed: "2,685",
    interestEarned: "0.91",
    name: "HEALTH ADVANTAGE",
    website: "http://healthadvantagecu.com",
  },
  "20013": {
    loansProduced: "1.10",
    membersServed: "184",
    interestEarned: "0.09",
    name: "BROCKPORT",
    website: "http://www.brockportfcu.org",
  },
  "20015": {
    loansProduced: "1.88",
    membersServed: "245",
    interestEarned: "0.16",
    name: "ENCENTUS",
    website: "http://www.encentusfcu.org",
  },
  "20041": {
    loansProduced: "19.89",
    membersServed: "1,760",
    interestEarned: "1.69",
    name: "AMERICHOICE",
    website: "http://www.americhoice.org",
  },
  "20042": {
    loansProduced: "133.73",
    membersServed: "12,827",
    interestEarned: "11.37",
    name: "FRANKLIN MINT",
    website: "http://www.fmfcu.org",
  },
  "20053": {
    loansProduced: "16.16",
    membersServed: "1,686",
    interestEarned: "1.37",
    name: "MOUNTAIN LAUREL",
    website: "http://www.mlfcu.net",
  },
  "20060": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "N.U.L.",
    website: null,
  },
  "20061": {
    loansProduced: "24.64",
    membersServed: "4,707",
    interestEarned: "2.09",
    name: "SIGNATURE",
    website: "http://www.signaturefcu.org",
  },
  "20063": {
    loansProduced: "4.31",
    membersServed: "433",
    interestEarned: "0.37",
    name: "MATERION",
    website: "http://www.materionfcu.com",
  },
  "20114": {
    loansProduced: "2.53",
    membersServed: "227",
    interestEarned: "0.22",
    name: "TWO RIVERS",
    website: "http://www.tworiversfcu.com",
  },
  "20147": {
    loansProduced: "0.91",
    membersServed: "98",
    interestEarned: "0.08",
    name: "TEAM FINANCIAL",
    website: "http://www.teamffcu.org",
  },
  "20157": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "ST. THOMAS MORE",
    website: null,
  },
  "20186": {
    loansProduced: "4.48",
    membersServed: "481",
    interestEarned: "0.38",
    name: "CHILDREN'S MEDICAL CENTER",
    website: "www.cmcfcu.org",
  },
  "20194": {
    loansProduced: "111.86",
    membersServed: "7,894",
    interestEarned: "9.51",
    name: "MID MINNESOTA",
    website: "http://www.mmfcu.org",
  },
  "20204": {
    loansProduced: "0.85",
    membersServed: "69",
    interestEarned: "0.07",
    name: "KRATON BELPRE",
    website: "http://www.kbfcu.org",
  },
  "20207": {
    loansProduced: "2.81",
    membersServed: "908",
    interestEarned: "0.24",
    name: "PENN",
    website: "http://www.penncu.org",
  },
  "20222": {
    loansProduced: "1.94",
    membersServed: "220",
    interestEarned: "0.16",
    name: "EIGHT",
    website: "http://eightfederalcu.org",
  },
  "20228": {
    loansProduced: "1.06",
    membersServed: "56",
    interestEarned: "0.09",
    name: "MONTELL",
    website: null,
  },
  "20258": {
    loansProduced: "53.08",
    membersServed: "7,920",
    interestEarned: "4.51",
    name: "RELIANT COMMUNITY",
    website: "http://Reliantcu.com",
  },
  "20267": {
    loansProduced: "0.99",
    membersServed: "138",
    interestEarned: "0.08",
    name: "FRIO COUNTY",
    website: null,
  },
  "20279": {
    loansProduced: "1.07",
    membersServed: "88",
    interestEarned: "0.09",
    name: "SISTER'S HOSPITAL EMPLOYEES",
    website: "http://www.shefcu.org",
  },
  "20290": {
    loansProduced: "2.97",
    membersServed: "339",
    interestEarned: "0.25",
    name: "TWIN RIVERS",
    website: "http://www.twinriversfcu.org",
  },
  "20292": {
    loansProduced: "0.50",
    membersServed: "46",
    interestEarned: "0.04",
    name: "SLOAN PUBLIC SCHOOLS",
    website: null,
  },
  "20302": {
    loansProduced: "2.51",
    membersServed: "685",
    interestEarned: "0.21",
    name: "HARVEST",
    website: "http://www.harvestfcu.org",
  },
  "20303": {
    loansProduced: "0.63",
    membersServed: "54",
    interestEarned: "0.05",
    name: "FARMERS BRANCH CITY EMPLOYEES",
    website: "https://fbcefcu.virtualcu.net/",
  },
  "20324": {
    loansProduced: "4.97",
    membersServed: "405",
    interestEarned: "0.42",
    name: "ALLOY",
    website: "http://www.alloyfcu.com",
  },
  "20336": {
    loansProduced: "2.06",
    membersServed: "325",
    interestEarned: "0.18",
    name: "DIVISION 726",
    website: "http://div726fcu.com",
  },
  "20341": {
    loansProduced: "0.31",
    membersServed: "92",
    interestEarned: "0.03",
    name: "LUFTHANSA EMP.",
    website: "http://www.lhcu.com",
  },
  "20354": {
    loansProduced: "0.88",
    membersServed: "73",
    interestEarned: "0.07",
    name: "HILL DISTRICT",
    website: "http://www.hilldistrictfcu.org",
  },
  "20382": {
    loansProduced: "2.43",
    membersServed: "549",
    interestEarned: "0.21",
    name: "GREATER METRO",
    website: "http://www.greatermetrofcu.org",
  },
  "20391": {
    loansProduced: "0.12",
    membersServed: "11",
    interestEarned: "0.01",
    name: "G. P. M.",
    website: null,
  },
  "20392": {
    loansProduced: "0.99",
    membersServed: "186",
    interestEarned: "0.08",
    name: "BROWNSVILLE CITY EMPLOYEES",
    website: "http://brownsvillecityfcu.com/",
  },
  "20394": {
    loansProduced: "16.68",
    membersServed: "1,797",
    interestEarned: "1.42",
    name: "BEACH MUNICIPAL",
    website: "http://www.beachmunicipal.org",
  },
  "20410": {
    loansProduced: "4.06",
    membersServed: "452",
    interestEarned: "0.35",
    name: "NORTHSIDE",
    website: "http://www.northsidefcu.com",
  },
  "20415": {
    loansProduced: "0.36",
    membersServed: "36",
    interestEarned: "0.03",
    name: "PINEY HILLS FEDERAL",
    website: "http://www.pineyhillsfcu.org",
  },
  "20417": {
    loansProduced: "7.27",
    membersServed: "383",
    interestEarned: "0.62",
    name: "FIRST STREET",
    website: "https://www.1stfcu.com/",
  },
  "20419": {
    loansProduced: "0.16",
    membersServed: "23",
    interestEarned: "0.01",
    name: "BYKOTA",
    website: null,
  },
  "20438": {
    loansProduced: "2.25",
    membersServed: "288",
    interestEarned: "0.19",
    name: "ARH",
    website: "http://www.arhfcu.org",
  },
  "20448": {
    loansProduced: "24.01",
    membersServed: "3,592",
    interestEarned: "2.04",
    name: "PROPONENT",
    website: "http://www.profcu.org",
  },
  "20463": {
    loansProduced: "0.05",
    membersServed: "3",
    interestEarned: "0.00",
    name: "N. J. LATVIAN",
    website: "http://njlatviancreditunion.com",
  },
  "20469": {
    loansProduced: "1.88",
    membersServed: "137",
    interestEarned: "0.16",
    name: "I.B.E.W. LU 66",
    website: "https://www.MyCU66.org",
  },
  "20496": {
    loansProduced: "11.03",
    membersServed: "1,303",
    interestEarned: "0.94",
    name: "SECNY",
    website: "http://www.secny.org",
  },
  "20509": {
    loansProduced: "2.52",
    membersServed: "282",
    interestEarned: "0.21",
    name: "COWETA CITIES & COUNTY EMPLOYEES",
    website: "http://www.cccefcu.org",
  },
  "20513": {
    loansProduced: "0.52",
    membersServed: "44",
    interestEarned: "0.04",
    name: "ALCOA MUNICIPAL EMPLOYEES",
    website: null,
  },
  "20516": {
    loansProduced: "3.68",
    membersServed: "868",
    interestEarned: "0.31",
    name: "KAIPERM NORTHWEST",
    website: "http://www.kaipermnw.org",
  },
  "20529": {
    loansProduced: "0.28",
    membersServed: "26",
    interestEarned: "0.02",
    name: "G. C. A.",
    website: null,
  },
  "20543": {
    loansProduced: "0.67",
    membersServed: "59",
    interestEarned: "0.06",
    name: "CAPITAL AREA REALTORS",
    website: "https://www.carfcu.org",
  },
  "20547": {
    loansProduced: "0.31",
    membersServed: "38",
    interestEarned: "0.03",
    name: "UPSTATE MILK EMPLOYEES",
    website: null,
  },
  "20550": {
    loansProduced: "0.05",
    membersServed: "27",
    interestEarned: "0.00",
    name: "TEAMSTERS LOCAL UNION #270",
    website: null,
  },
  "20558": {
    loansProduced: "18.67",
    membersServed: "2,313",
    interestEarned: "1.59",
    name: "CHROME",
    website: "https://www.chromefcu.org",
  },
  "20570": {
    loansProduced: "0.83",
    membersServed: "59",
    interestEarned: "0.07",
    name: "CHAMPION",
    website: "http://www.championfcu.com",
  },
  "20581": {
    loansProduced: "1.37",
    membersServed: "76",
    interestEarned: "0.12",
    name: "J.C.T.",
    website: "http://www.jacksoncountyteachers.co",
  },
  "20585": {
    loansProduced: "0.24",
    membersServed: "27",
    interestEarned: "0.02",
    name: "B.S. AND C.P. HOSPITALS EMPLOYEES",
    website: null,
  },
  "20591": {
    loansProduced: "0.04",
    membersServed: "17",
    interestEarned: "0.00",
    name: "CENTERVILLE CLINICS EMPLOYEES",
    website: null,
  },
  "20595": {
    loansProduced: "0.87",
    membersServed: "150",
    interestEarned: "0.07",
    name: "MSD",
    website: "https://louisvillemsd.org/msd-feder",
  },
  "20600": {
    loansProduced: "2.55",
    membersServed: "334",
    interestEarned: "0.22",
    name: "LUSO",
    website: "http://www.lusofederal.com",
  },
  "20610": {
    loansProduced: "1.90",
    membersServed: "170",
    interestEarned: "0.16",
    name: "WALKER COUNTY EDUCATORS",
    website: "http://www.WalkerCountyEducatorsFCU",
  },
  "20613": {
    loansProduced: "17.64",
    membersServed: "1,401",
    interestEarned: "1.50",
    name: "UFIRST",
    website: "http://www.ufirstfcu.net",
  },
  "20622": {
    loansProduced: "2.09",
    membersServed: "234",
    interestEarned: "0.18",
    name: "MINERVA AREA",
    website: "http://www.minervacreditunion.com",
  },
  "20623": {
    loansProduced: "45.96",
    membersServed: "7,325",
    interestEarned: "3.91",
    name: "JOHNS HOPKINS",
    website: "http://www.jhfcu.org",
  },
  "20629": {
    loansProduced: "1.35",
    membersServed: "96",
    interestEarned: "0.12",
    name: "ST. VINCENT'S MEDICAL CENTER",
    website: "http://www.svmcfcu.org",
  },
  "20641": {
    loansProduced: "9.65",
    membersServed: "734",
    interestEarned: "0.82",
    name: "LAKE CHEM COMMUNITY",
    website: "http://www.lakechemfcu.org",
  },
  "20656": {
    loansProduced: "0.30",
    membersServed: "35",
    interestEarned: "0.03",
    name: "CHOPTANK ELECTRIC COOP EMPLOY",
    website: null,
  },
  "20661": {
    loansProduced: "1.14",
    membersServed: "80",
    interestEarned: "0.10",
    name: "DOY",
    website: "http://doyfcu.com",
  },
  "20662": {
    loansProduced: "1.29",
    membersServed: "205",
    interestEarned: "0.11",
    name: "ENLIGHTEN",
    website: "http://www.enlightenfcu.org",
  },
  "20684": {
    loansProduced: "7.65",
    membersServed: "910",
    interestEarned: "0.65",
    name: "DAVIESS COUNTY TEACHERS",
    website: "http://www.dctfcu.com",
  },
  "20691": {
    loansProduced: "2.56",
    membersServed: "490",
    interestEarned: "0.22",
    name: "HIGHWAY",
    website: "http://highwayfcu.org",
  },
  "20719": {
    loansProduced: "1.23",
    membersServed: "154",
    interestEarned: "0.10",
    name: "BALTIMORE WASHINGTON",
    website: "http://www.baltimorewashingtonfcu.o",
  },
  "20720": {
    loansProduced: "0.79",
    membersServed: "70",
    interestEarned: "0.07",
    name: "L. A. MISSION",
    website: "lamissionfcu.org/",
  },
  "20727": {
    loansProduced: "35.34",
    membersServed: "6,803",
    interestEarned: "3.00",
    name: "CAMPCO",
    website: "http://www.campcofcu.com",
  },
  "20749": {
    loansProduced: "5.40",
    membersServed: "568",
    interestEarned: "0.46",
    name: "WESLA",
    website: "http://www.wesla.org",
  },
  "20773": {
    loansProduced: "1.67",
    membersServed: "188",
    interestEarned: "0.14",
    name: "LOCAL 1233",
    website: "www.local1233fcu.org",
  },
  "20793": {
    loansProduced: "3.44",
    membersServed: "1,323",
    interestEarned: "0.29",
    name: "FINANCIAL HEALTH",
    website: "http://financialhealthfcu.org",
  },
  "20805": {
    loansProduced: "3.14",
    membersServed: "382",
    interestEarned: "0.27",
    name: "DUBOIS-PIKE",
    website: "http://www.duboispike.org",
  },
  "20823": {
    loansProduced: "6.30",
    membersServed: "676",
    interestEarned: "0.54",
    name: "MOHAWK VALLEY",
    website: "http://www.mohawkvalleyfcu.org",
  },
  "20824": {
    loansProduced: "3.89",
    membersServed: "794",
    interestEarned: "0.33",
    name: "HOPESOUTH",
    website: "http://www.hopesouth.org",
  },
  "20839": {
    loansProduced: "0.03",
    membersServed: "8",
    interestEarned: "0.00",
    name: "MORNING STAR BAPTIST",
    website: null,
  },
  "20842": {
    loansProduced: "5.79",
    membersServed: "711",
    interestEarned: "0.49",
    name: "UNO",
    website: "http://WWW.UNOFCU.ORG",
  },
  "20857": {
    loansProduced: "2.09",
    membersServed: "146",
    interestEarned: "0.18",
    name: "METRO REALTORS",
    website: "https://www.mrfcu.com",
  },
  "20861": {
    loansProduced: "11.55",
    membersServed: "1,531",
    interestEarned: "0.98",
    name: "P & G MEHOOPANY EMPLOYEES",
    website: "http://www.pgmfcu.org",
  },
  "20874": {
    loansProduced: "0.52",
    membersServed: "44",
    interestEarned: "0.04",
    name: "BULAB EMPLOYEES",
    website: null,
  },
  "20885": {
    loansProduced: "1.89",
    membersServed: "337",
    interestEarned: "0.16",
    name: "MEDISYS EMPLOYEES",
    website: "http://WWW.MEDISYSEFCU.ORG",
  },
  "20890": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "STEPHENS COUNTY COMMUNITY",
    website: null,
  },
  "20891": {
    loansProduced: "1.37",
    membersServed: "245",
    interestEarned: "0.12",
    name: "KRAFTCOR",
    website: "http://www.kraftcorfcu.org",
  },
  "20921": {
    loansProduced: "3.52",
    membersServed: "506",
    interestEarned: "0.30",
    name: "MERHO",
    website: "http://www.merhofcu.org",
  },
  "20943": {
    loansProduced: "19.61",
    membersServed: "3,446",
    interestEarned: "1.67",
    name: "JOURNEY",
    website: "http://www.journeyfcu.org",
  },
  "20951": {
    loansProduced: "0.63",
    membersServed: "48",
    interestEarned: "0.05",
    name: "OPC",
    website: null,
  },
  "20957": {
    loansProduced: "0.60",
    membersServed: "83",
    interestEarned: "0.05",
    name: "TEAMSTERS LOCAL 697",
    website: "http://teamsters697creditunion.com",
  },
  "20974": {
    loansProduced: "5.30",
    membersServed: "1,415",
    interestEarned: "0.45",
    name: "B.O.N.D. COMMUNITY",
    website: "http://www.bondcu.com",
  },
  "20990": {
    loansProduced: "4.35",
    membersServed: "340",
    interestEarned: "0.37",
    name: "ALCON EMPLOYEES",
    website: "https://www.alconefcu.org",
  },
  "21009": {
    loansProduced: "7.32",
    membersServed: "1,146",
    interestEarned: "0.62",
    name: "PRINCETON",
    website: "http://www.princetonfcu.org",
  },
  "21012": {
    loansProduced: "10.01",
    membersServed: "1,232",
    interestEarned: "0.85",
    name: "FIRST CHOICE FINANCIAL",
    website: "http://www.fcfinancialcu.org",
  },
  "21020": {
    loansProduced: "0.73",
    membersServed: "66",
    interestEarned: "0.06",
    name: "ROCK COMMUNITY",
    website: "http://www.rockcommfcu.com",
  },
  "21026": {
    loansProduced: "7.50",
    membersServed: "753",
    interestEarned: "0.64",
    name: "TRUNORTHERN",
    website: "http://www.trunorthernfcu.org",
  },
  "21029": {
    loansProduced: "0.47",
    membersServed: "47",
    interestEarned: "0.04",
    name: "PORT OF HOUSTON WAREHOUSE",
    website: "http://www.phwfcu.org",
  },
  "21057": {
    loansProduced: "1.38",
    membersServed: "123",
    interestEarned: "0.12",
    name: "C C M H",
    website: "https://www.ccmhfcu.org",
  },
  "21070": {
    loansProduced: "2.00",
    membersServed: "696",
    interestEarned: "0.17",
    name: "DOWAGIAC AREA",
    website: "https://www.dafcu.net",
  },
  "21073": {
    loansProduced: "9.17",
    membersServed: "1,278",
    interestEarned: "0.78",
    name: "1ST MISSISSIPPI",
    website: "https://www.1stms.com",
  },
  "21083": {
    loansProduced: "28.52",
    membersServed: "2,806",
    interestEarned: "2.42",
    name: "OLEAN AREA",
    website: "http://oleanareafcu.org",
  },
  "21100": {
    loansProduced: "2.79",
    membersServed: "347",
    interestEarned: "0.24",
    name: "UNION TRADES",
    website: "http://uniontradesfcu.com",
  },
  "21107": {
    loansProduced: "1.44",
    membersServed: "165",
    interestEarned: "0.12",
    name: "PUTNAM SCHOOL EMPLOYEES",
    website: "http://www.psewv.com",
  },
  "21109": {
    loansProduced: "1.53",
    membersServed: "133",
    interestEarned: "0.13",
    name: "L'OREAL USA",
    website: "http://www.lorealusafcu.com",
  },
  "21111": {
    loansProduced: "0.41",
    membersServed: "59",
    interestEarned: "0.04",
    name: "SWEDISH AMERICAN",
    website: null,
  },
  "21115": {
    loansProduced: "1.81",
    membersServed: "193",
    interestEarned: "0.15",
    name: "HANCOCK SCHOOL EMP.",
    website: "http://www.hsefcu.com",
  },
  "21126": {
    loansProduced: "1.19",
    membersServed: "98",
    interestEarned: "0.10",
    name: "REGIONAL MEDICAL CTR HOPKINS CO EMP",
    website: "http://www.rmcefcu.virtualcu.net",
  },
  "21140": {
    loansProduced: "1.63",
    membersServed: "143",
    interestEarned: "0.14",
    name: "I. U. 7",
    website: "iu7federalcreditunion.org",
  },
  "21157": {
    loansProduced: "1.45",
    membersServed: "454",
    interestEarned: "0.12",
    name: "CINCINNATI HEALTHCARE ASSOCIATES",
    website: "http://www.healfcu.org",
  },
  "21161": {
    loansProduced: "1.55",
    membersServed: "168",
    interestEarned: "0.13",
    name: "ALLEGHENY HEALTH SERVICES EMPLOYEES",
    website: "https://www.ahsfcu.com",
  },
  "21167": {
    loansProduced: "1.11",
    membersServed: "94",
    interestEarned: "0.09",
    name: "DELMARVA POWER SOUTHERN DIVISION",
    website: "http://www.delmarvacreditunion.com",
  },
  "21190": {
    loansProduced: "1.04",
    membersServed: "221",
    interestEarned: "0.09",
    name: "ASA",
    website: "http://www.asafcu.com",
  },
  "21202": {
    loansProduced: "1.92",
    membersServed: "237",
    interestEarned: "0.16",
    name: "SOUTH ATLANTIC",
    website: "http://WWW.SAFCU1.ORG",
  },
  "21204": {
    loansProduced: "0.88",
    membersServed: "139",
    interestEarned: "0.07",
    name: "LEGACY FINANCIAL",
    website: "http://www.legacyfinancialfcu.org",
  },
  "21226": {
    loansProduced: "8.03",
    membersServed: "1,051",
    interestEarned: "0.68",
    name: "PROMEDICA",
    website: "http://www.promedicafcu.com",
  },
  "21231": {
    loansProduced: "0.13",
    membersServed: "44",
    interestEarned: "0.01",
    name: "SCF WESTCHESTER N. Y. EMPLOYEES",
    website: null,
  },
  "21240": {
    loansProduced: "0.51",
    membersServed: "52",
    interestEarned: "0.04",
    name: "POCONO MEDICAL CENTER",
    website: "http://pmcfcu.org",
  },
  "21244": {
    loansProduced: "4.00",
    membersServed: "1,471",
    interestEarned: "0.34",
    name: "UNION BUILDING TRADES",
    website: "http://www.ubtfcu.org",
  },
  "21268": {
    loansProduced: "2.38",
    membersServed: "266",
    interestEarned: "0.20",
    name: "DILLARD'S",
    website: "http://www.mydfcu.com",
  },
  "21279": {
    loansProduced: "1.74",
    membersServed: "356",
    interestEarned: "0.15",
    name: "LIVING IN FULFILLMENT EVERYDAY",
    website: "http://www.lifefcu.com",
  },
  "21294": {
    loansProduced: "2.09",
    membersServed: "315",
    interestEarned: "0.18",
    name: "FINGER LAKES HEALTH CARE",
    website: "http://WWW.FLHC.COM",
  },
  "21305": {
    loansProduced: "4.47",
    membersServed: "463",
    interestEarned: "0.38",
    name: "THE UNITED",
    website: "http://www.tufcu.org",
  },
  "21319": {
    loansProduced: "1.23",
    membersServed: "176",
    interestEarned: "0.10",
    name: "STRATEGIC",
    website: "http://www.strategicfcu.org",
  },
  "21326": {
    loansProduced: "5.53",
    membersServed: "932",
    interestEarned: "0.47",
    name: "METRO",
    website: "http://www.mcu.org",
  },
  "21339": {
    loansProduced: "3.65",
    membersServed: "380",
    interestEarned: "0.31",
    name: "NORTHERN KENTUCKY",
    website: "http://www.nkfcu.org",
  },
  "21345": {
    loansProduced: "0.15",
    membersServed: "10",
    interestEarned: "0.01",
    name: "WYOMING AREA",
    website: "http://www.wyomingareafcu.com",
  },
  "21354": {
    loansProduced: "6.61",
    membersServed: "1,655",
    interestEarned: "0.56",
    name: "VERITAS",
    website: "http://www.veritasfcu.org",
  },
  "21355": {
    loansProduced: "0.06",
    membersServed: "15",
    interestEarned: "0.00",
    name: "ST. JOHN UNITED",
    website: "http://www.stjohnunitedfcu.com",
  },
  "21367": {
    loansProduced: "0.36",
    membersServed: "58",
    interestEarned: "0.03",
    name: "PLANTERS",
    website: null,
  },
  "21368": {
    loansProduced: "0.61",
    membersServed: "50",
    interestEarned: "0.05",
    name: "VIDOR TEACHERS",
    website: null,
  },
  "21383": {
    loansProduced: "6.36",
    membersServed: "876",
    interestEarned: "0.54",
    name: "DOMINO",
    website: "http://www.dominofcu.com",
  },
  "21393": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "DELANCEY STREET",
    website: null,
  },
  "21413": {
    loansProduced: "15.69",
    membersServed: "1,548",
    interestEarned: "1.33",
    name: "TIMBERLAND",
    website: "https://www.timberlandfcu.org",
  },
  "21440": {
    loansProduced: "0.02",
    membersServed: "3",
    interestEarned: "0.00",
    name: "MESSIAH BAPTIST CHURCH",
    website: "http://www.mbcfcu.org",
  },
  "21458": {
    loansProduced: "0.97",
    membersServed: "109",
    interestEarned: "0.08",
    name: "BACK MOUNTAIN",
    website: "http://backmtnfcu.com",
  },
  "21499": {
    loansProduced: "1.41",
    membersServed: "95",
    interestEarned: "0.12",
    name: "BERKELEY COUNTY PUBLIC SCHOOLS",
    website: "http://www.bcpsfcu.com",
  },
  "21502": {
    loansProduced: "2.65",
    membersServed: "458",
    interestEarned: "0.23",
    name: "POLAM",
    website: "http://www.polamfcu.com",
  },
  "21507": {
    loansProduced: "1.15",
    membersServed: "442",
    interestEarned: "0.10",
    name: "NEW ENGLAND TEAMSTERS",
    website: "http://www.netfcu.org",
  },
  "21508": {
    loansProduced: "2.16",
    membersServed: "1,894",
    interestEarned: "0.18",
    name: "ANMED",
    website: "http://www.anmedhealthfcu.com",
  },
  "21509": {
    loansProduced: "1.55",
    membersServed: "104",
    interestEarned: "0.13",
    name: "P.V.H.M.C.",
    website: null,
  },
  "21530": {
    loansProduced: "0.34",
    membersServed: "48",
    interestEarned: "0.03",
    name: "UKRAINIAN SELFRELIANCE OF WEST. PA.",
    website: "http://www.samopomich.com",
  },
  "21532": {
    loansProduced: "5.89",
    membersServed: "950",
    interestEarned: "0.50",
    name: "U.P.S. EMPLOYEES",
    website: "http://WWW.UPSCREDITUNION.ORG",
  },
  "21556": {
    loansProduced: "1.70",
    membersServed: "230",
    interestEarned: "0.14",
    name: "PENNSYLVANIA-AMERICAN WATER",
    website: "http://www.pawfcu.com",
  },
  "21575": {
    loansProduced: "0.88",
    membersServed: "84",
    interestEarned: "0.07",
    name: "BEKA",
    website: "http://bekafcu.com",
  },
  "21586": {
    loansProduced: "3.39",
    membersServed: "451",
    interestEarned: "0.29",
    name: "U. H. S. EMPLOYEES",
    website: "http://www.cuonlineuhs.org",
  },
  "21593": {
    loansProduced: "16.52",
    membersServed: "1,909",
    interestEarned: "1.40",
    name: "FIRE POLICE CITY COUNTY",
    website: "http://www.fpccfcu.org",
  },
  "21606": {
    loansProduced: "1.46",
    membersServed: "158",
    interestEarned: "0.12",
    name: "MORROW COUNTY",
    website: "http://morrowcountyfcu.com",
  },
  "21609": {
    loansProduced: "3.48",
    membersServed: "460",
    interestEarned: "0.30",
    name: "NORTHWESTERN ENERGY EMPLOYEES",
    website: "http://nweefcu.org",
  },
  "21614": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "FIRST BAPTIST CHURCH (STRATFORD)",
    website: null,
  },
  "21615": {
    loansProduced: "0.77",
    membersServed: "98",
    interestEarned: "0.07",
    name: "SABINE SCHOOL EMPLOYEES",
    website: null,
  },
  "21628": {
    loansProduced: "8.61",
    membersServed: "1,102",
    interestEarned: "0.73",
    name: "GREENWOOD MUNICIPAL",
    website: "http://www.gm-fcu.org",
  },
  "21630": {
    loansProduced: "0.67",
    membersServed: "148",
    interestEarned: "0.06",
    name: "NORTHEASTERN OPERATING ENGINEERS",
    website: "http://www.noefcuny.org",
  },
  "21639": {
    loansProduced: "2.74",
    membersServed: "272",
    interestEarned: "0.23",
    name: "TEAM AND WHEEL",
    website: "http://www.team-wheelfcu.org",
  },
  "21644": {
    loansProduced: "4.11",
    membersServed: "494",
    interestEarned: "0.35",
    name: "TORO EMPLOYEES",
    website: "http://www.torofcu.org",
  },
  "21658": {
    loansProduced: "9.75",
    membersServed: "1,153",
    interestEarned: "0.83",
    name: "GENESEE VALLEY",
    website: "http://www.geneseevalleyfcu.com",
  },
  "21669": {
    loansProduced: "41.41",
    membersServed: "3,242",
    interestEarned: "3.52",
    name: "HOME TOWN",
    website: "http://www.hometowncu.coop",
  },
  "21683": {
    loansProduced: "0.01",
    membersServed: "7",
    interestEarned: "0.00",
    name: "WEST SIDE BAPTIST CHURCH",
    website: null,
  },
  "21686": {
    loansProduced: "12.81",
    membersServed: "1,670",
    interestEarned: "1.09",
    name: "PARDA",
    website: "http://www.parda.com",
  },
  "21694": {
    loansProduced: "3.39",
    membersServed: "762",
    interestEarned: "0.29",
    name: "ULSTER",
    website: "http://www.ulsfedcu.org",
  },
  "21710": {
    loansProduced: "0.78",
    membersServed: "53",
    interestEarned: "0.07",
    name: "GOODYEAR SAN ANGELO",
    website: "http://www.gysafcu.com",
  },
  "21713": {
    loansProduced: "2.54",
    membersServed: "224",
    interestEarned: "0.22",
    name: "MARSHALL COUNTY",
    website: "http://www.marshallcountyfcu.com",
  },
  "21737": {
    loansProduced: "3.90",
    membersServed: "418",
    interestEarned: "0.33",
    name: "ATLANTIC HEALTH EMPLOYEES",
    website: "http://www.ahefcu.org",
  },
  "21751": {
    loansProduced: "47.62",
    membersServed: "6,031",
    interestEarned: "4.05",
    name: "FEDEX EMPLOYEES CREDIT ASSOCIATION",
    website: "fecca.com",
  },
  "21774": {
    loansProduced: "2.32",
    membersServed: "290",
    interestEarned: "0.20",
    name: "GHA",
    website: "https://www.ghafcu.org",
  },
  "21782": {
    loansProduced: "1.58",
    membersServed: "96",
    interestEarned: "0.13",
    name: "I.B.E.W. - LOCAL NO. 5",
    website: "http://ibew5.virtualcu.net",
  },
  "21787": {
    loansProduced: "0.07",
    membersServed: "12",
    interestEarned: "0.01",
    name: "M G EMPLOYEES",
    website: null,
  },
  "21788": {
    loansProduced: "5.10",
    membersServed: "411",
    interestEarned: "0.43",
    name: "VALWOOD PARK",
    website: "http://www.valwoodpark.com",
  },
  "21794": {
    loansProduced: "4.04",
    membersServed: "396",
    interestEarned: "0.34",
    name: "THE INFIRMARY",
    website: "http://www.theinfirmaryfcu.com",
  },
  "21798": {
    loansProduced: "0.58",
    membersServed: "106",
    interestEarned: "0.05",
    name: "CONSTRUCTION",
    website: "http://cfcuonline.com",
  },
  "21831": {
    loansProduced: "0.67",
    membersServed: "111",
    interestEarned: "0.06",
    name: "TRANSIT",
    website: "http://WWW.ATU1181.ORG",
  },
  "21845": {
    loansProduced: "1.01",
    membersServed: "164",
    interestEarned: "0.09",
    name: "BLACKHAWK",
    website: "http://www.bfcu.net",
  },
  "21851": {
    loansProduced: "4.33",
    membersServed: "561",
    interestEarned: "0.37",
    name: "SUNY FREDONIA",
    website: "http://WWW.SUNYFREDONIAFCU.COM",
  },
  "21853": {
    loansProduced: "4.11",
    membersServed: "387",
    interestEarned: "0.35",
    name: "LABOR MANAGEMENT",
    website: "http://www.lmfcu.net",
  },
  "21873": {
    loansProduced: "6.60",
    membersServed: "754",
    interestEarned: "0.56",
    name: "COMMODORE PERRY",
    website: "http://www.commodoreperryfcu.com",
  },
  "21875": {
    loansProduced: "1.10",
    membersServed: "81",
    interestEarned: "0.09",
    name: "TRI-COUNTY",
    website: "http://www.tricountyfederalcreditun",
  },
  "21879": {
    loansProduced: "1.18",
    membersServed: "106",
    interestEarned: "0.10",
    name: "P.H.I.",
    website: "http://www.phifcu.com",
  },
  "21904": {
    loansProduced: "0.70",
    membersServed: "233",
    interestEarned: "0.06",
    name: "EMPLOYEES UNITED",
    website: "www.employeesunited.org",
  },
  "21927": {
    loansProduced: "15.47",
    membersServed: "2,894",
    interestEarned: "1.31",
    name: "OSWEGO COUNTY",
    website: "http://www.oswegofcu.org",
  },
  "21930": {
    loansProduced: "35.85",
    membersServed: "5,188",
    interestEarned: "3.05",
    name: "CINFED",
    website: "http://www.cinfed.org",
  },
  "21956": {
    loansProduced: "0.59",
    membersServed: "79",
    interestEarned: "0.05",
    name: "U.F.C.W. LOCAL 23",
    website: "http://ufcw23fcu.org",
  },
  "21968": {
    loansProduced: "1.20",
    membersServed: "93",
    interestEarned: "0.10",
    name: "LOUISIANA BAPTIST",
    website: "http://www.labaptistfcu.com",
  },
  "21971": {
    loansProduced: "39.49",
    membersServed: "7,976",
    interestEarned: "3.36",
    name: "CPM",
    website: "http://www.cpmfed.com",
  },
  "21997": {
    loansProduced: "0.70",
    membersServed: "55",
    interestEarned: "0.06",
    name: "READING MASS. TOWN EMPLOYEES",
    website: "https://readingcreditunion.com",
  },
  "22005": {
    loansProduced: "4.43",
    membersServed: "362",
    interestEarned: "0.38",
    name: "ENGAGE",
    website: "http://www.engagefcu.org",
  },
  "22007": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "WAYLAND TEMPLE BAPTIST",
    website: null,
  },
  "22028": {
    loansProduced: "1.86",
    membersServed: "231",
    interestEarned: "0.16",
    name: "VAH LYONS EMPLOYEES",
    website: null,
  },
  "22032": {
    loansProduced: "1.20",
    membersServed: "360",
    interestEarned: "0.10",
    name: "ENTERTAINMENT INDUSTRIES",
    website: "http://www.eicu.org",
  },
  "22049": {
    loansProduced: "0.92",
    membersServed: "128",
    interestEarned: "0.08",
    name: "PORT OF HAMPTON ROADS ILA",
    website: "http://WWW.ILAFCU.ORG",
  },
  "22070": {
    loansProduced: "0.33",
    membersServed: "33",
    interestEarned: "0.03",
    name: "NORTH ALABAMA PAPERMAKERS",
    website: null,
  },
  "22083": {
    loansProduced: "0.88",
    membersServed: "124",
    interestEarned: "0.08",
    name: "E L C O",
    website: null,
  },
  "22114": {
    loansProduced: "4.19",
    membersServed: "541",
    interestEarned: "0.36",
    name: "CIRCLE",
    website: "http://www.circlefcu.org",
  },
  "22119": {
    loansProduced: "5.79",
    membersServed: "1,170",
    interestEarned: "0.49",
    name: "AMERICAN PARTNERS",
    website: "http://www.apfcu.com",
  },
  "22128": {
    loansProduced: "70.14",
    membersServed: "6,349",
    interestEarned: "5.96",
    name: "SERVICE 1ST",
    website: "http://www.service1.org",
  },
  "22131": {
    loansProduced: "0.10",
    membersServed: "28",
    interestEarned: "0.01",
    name: "FOGCE",
    website: null,
  },
  "22132": {
    loansProduced: "2.99",
    membersServed: "168",
    interestEarned: "0.25",
    name: "FARM CREDIT EMPLOYEES",
    website: "http://www.fcefcu.com",
  },
  "22151": {
    loansProduced: "0.02",
    membersServed: "5",
    interestEarned: "0.00",
    name: "CLEVELAND CHURCH OF CHRIST",
    website: null,
  },
  "22157": {
    loansProduced: "88.09",
    membersServed: "8,789",
    interestEarned: "7.49",
    name: "SOUTHWEST AIRLINES",
    website: "http://www.swacu.org",
  },
  "22159": {
    loansProduced: "3.16",
    membersServed: "580",
    interestEarned: "0.27",
    name: "CORAL COMMUNITY",
    website: "http://www.coralfcu.org",
  },
  "22167": {
    loansProduced: "12.86",
    membersServed: "2,314",
    interestEarned: "1.09",
    name: "TONAWANDA VALLEY",
    website: "http://tvfcubatavia.com",
  },
  "22174": {
    loansProduced: "1.73",
    membersServed: "239",
    interestEarned: "0.15",
    name: "CMC-FCPI EMPLOYEES",
    website: "http://cmcfcpi.com",
  },
  "22195": {
    loansProduced: "2.05",
    membersServed: "123",
    interestEarned: "0.17",
    name: "CVPH EMPLOYEES",
    website: "http://www.cvphemployeesfcu.com",
  },
  "22213": {
    loansProduced: "4.55",
    membersServed: "567",
    interestEarned: "0.39",
    name: "HEALTH CARE PROFESSIONALS",
    website: "http://www.hcpfcu.org",
  },
  "22219": {
    loansProduced: "0.15",
    membersServed: "47",
    interestEarned: "0.01",
    name: "U B C SOUTHERN COUNCIL INDUSTRIA WO",
    website: null,
  },
  "22220": {
    loansProduced: "2.88",
    membersServed: "208",
    interestEarned: "0.24",
    name: "KALEIDA HEALTH",
    website: "http://www.kaleidahealthfcu.org",
  },
  "22226": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "FIRST BAPTIST CHURCH",
    website: "http://www.firstbaptistfcu.com",
  },
  "22241": {
    loansProduced: "19.69",
    membersServed: "1,689",
    interestEarned: "1.67",
    name: "LONG REACH",
    website: "http://www.lrefcu.org",
  },
  "22280": {
    loansProduced: "2.81",
    membersServed: "501",
    interestEarned: "0.24",
    name: "POLISH-AMERICAN",
    website: "http://www.mypafcu.com",
  },
  "22307": {
    loansProduced: "14.93",
    membersServed: "2,246",
    interestEarned: "1.27",
    name: "CENTRAL VIRGINIA",
    website: "http://www.cvfcu.com",
  },
  "22314": {
    loansProduced: "0.62",
    membersServed: "229",
    interestEarned: "0.05",
    name: "CHOCTAW",
    website: "http://choctawfcu.org",
  },
  "22322": {
    loansProduced: "0.92",
    membersServed: "126",
    interestEarned: "0.08",
    name: "CRCH EMPLOYEES",
    website: "http://www.crchcreditunion.com",
  },
  "22323": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "JOHN WESLEY AME ZION CHURCH",
    website: null,
  },
  "22337": {
    loansProduced: "1.13",
    membersServed: "148",
    interestEarned: "0.10",
    name: "MARTINSBURG V.A. CENTER",
    website: "http://martinsburgfcu.com",
  },
  "22340": {
    loansProduced: "0.52",
    membersServed: "53",
    interestEarned: "0.04",
    name: "SUNCOMP EMPLOYEES",
    website: "http://suncompefcu.virtualcu.net",
  },
  "22362": {
    loansProduced: "2.08",
    membersServed: "226",
    interestEarned: "0.18",
    name: "MERCY",
    website: "https://mercyfcu.org",
  },
  "22369": {
    loansProduced: "5.69",
    membersServed: "824",
    interestEarned: "0.48",
    name: "TOTAL CHOICE",
    website: "http://www.totalchoicefcu.com",
  },
  "22400": {
    loansProduced: "1.03",
    membersServed: "114",
    interestEarned: "0.09",
    name: "BROSNAN YARD",
    website: null,
  },
  "22417": {
    loansProduced: "0.03",
    membersServed: "46",
    interestEarned: "0.00",
    name: "ST. MARY PARISH SCHOOL EMP.",
    website: null,
  },
  "22426": {
    loansProduced: "2.04",
    membersServed: "394",
    interestEarned: "0.17",
    name: "SOUTHCOAST",
    website: "http://www.southcoastcu.org",
  },
  "22428": {
    loansProduced: "0.82",
    membersServed: "83",
    interestEarned: "0.07",
    name: "MARION COUNTY SCHOOL EMPLOYEES",
    website: "http://www.schoolcu.virtualcu.net",
  },
  "22447": {
    loansProduced: "1.38",
    membersServed: "179",
    interestEarned: "0.12",
    name: "LOCAL 41 I.B.E.W.",
    website: "http://WWW.LOCAL41FCU.COM",
  },
  "22449": {
    loansProduced: "0.46",
    membersServed: "30",
    interestEarned: "0.04",
    name: "NESTLE (FREEHOLD) EMPLOYEES",
    website: null,
  },
  "22456": {
    loansProduced: "2.56",
    membersServed: "210",
    interestEarned: "0.22",
    name: "THE HEART CENTER",
    website: "http://theheartcentercu.com",
  },
  "22458": {
    loansProduced: "132.26",
    membersServed: "10,101",
    interestEarned: "11.24",
    name: "SHARONVIEW",
    website: "http://www.sharonview.org",
  },
  "22464": {
    loansProduced: "1.96",
    membersServed: "438",
    interestEarned: "0.17",
    name: "TONAWANDA COMMUNITY",
    website: "http://Toncomfcu.com",
  },
  "22468": {
    loansProduced: "120.90",
    membersServed: "12,062",
    interestEarned: "10.28",
    name: "ELEMENTS FINANCIAL",
    website: "http://www.elements.org",
  },
  "22478": {
    loansProduced: "2.60",
    membersServed: "663",
    interestEarned: "0.22",
    name: "KUE",
    website: "http://www.kuefcu.com",
  },
  "22520": {
    loansProduced: "26.81",
    membersServed: "4,404",
    interestEarned: "2.28",
    name: "MTC",
    website: "http://www.mtcfederal.com",
  },
  "22522": {
    loansProduced: "0.26",
    membersServed: "23",
    interestEarned: "0.02",
    name: "S. T. P. EMPLOYEES",
    website: "http://www.smalltubeproducts.com",
  },
  "22527": {
    loansProduced: "0.67",
    membersServed: "80",
    interestEarned: "0.06",
    name: "WEST MONROE",
    website: null,
  },
  "22530": {
    loansProduced: "3.79",
    membersServed: "441",
    interestEarned: "0.32",
    name: "PEE DEE",
    website: "http://www.pdfcu.org",
  },
  "22560": {
    loansProduced: "0.57",
    membersServed: "79",
    interestEarned: "0.05",
    name: "STAR CITY",
    website: null,
  },
  "22566": {
    loansProduced: "3.34",
    membersServed: "298",
    interestEarned: "0.28",
    name: "MONEY",
    website: "https://www.moneyfcu.org",
  },
  "22581": {
    loansProduced: "0.13",
    membersServed: "30",
    interestEarned: "0.01",
    name: "TOTAL COMMUNITY ACTION",
    website: "http://www.tca-nola.org/creditunion",
  },
  "22592": {
    loansProduced: "58.23",
    membersServed: "8,421",
    interestEarned: "4.95",
    name: "POLISH & SLAVIC",
    website: "www.psfcu.com",
  },
  "22625": {
    loansProduced: "12.62",
    membersServed: "926",
    interestEarned: "1.07",
    name: "LEATHERSTOCKING REGION",
    website: "http://www.lsrfcu.org",
  },
  "22637": {
    loansProduced: "55.30",
    membersServed: "6,393",
    interestEarned: "4.70",
    name: "ARDENT",
    website: "http://www.ardentcu.org",
  },
  "22652": {
    loansProduced: "0.05",
    membersServed: "5",
    interestEarned: "0.00",
    name: "CAPITAL AREA TAIWANESE",
    website: null,
  },
  "22657": {
    loansProduced: "3.95",
    membersServed: "291",
    interestEarned: "0.34",
    name: "CAMC",
    website: "http://www.camcfcu.org",
  },
  "22681": {
    loansProduced: "1.43",
    membersServed: "130",
    interestEarned: "0.12",
    name: "TWIN OAKS",
    website: "http://www.twinoaksfcu.com",
  },
  "22688": {
    loansProduced: "1.55",
    membersServed: "194",
    interestEarned: "0.13",
    name: "OHIO VALLEY",
    website: "http://www.ovfcu.org",
  },
  "22695": {
    loansProduced: "0.64",
    membersServed: "68",
    interestEarned: "0.05",
    name: "GREAT HORIZONS",
    website: "http://greathorizonsfcu.org",
  },
  "22700": {
    loansProduced: "0.38",
    membersServed: "19",
    interestEarned: "0.03",
    name: "KOREAN CATHOLIC",
    website: null,
  },
  "22704": {
    loansProduced: "0.91",
    membersServed: "173",
    interestEarned: "0.08",
    name: "INDIANA LAKES",
    website: "http://www.ilfcu.org",
  },
  "22719": {
    loansProduced: "2.02",
    membersServed: "206",
    interestEarned: "0.17",
    name: "CONSUMER'S",
    website: "http://consumersfcu.coop",
  },
  "22723": {
    loansProduced: "0.76",
    membersServed: "61",
    interestEarned: "0.06",
    name: "PAR-DEL EMP.",
    website: "http://www.creducomp.com",
  },
  "22740": {
    loansProduced: "1.26",
    membersServed: "322",
    interestEarned: "0.11",
    name: "HSM",
    website: "http://www.hsmfcu.org",
  },
  "22747": {
    loansProduced: "5.03",
    membersServed: "703",
    interestEarned: "0.43",
    name: "THE LOCAL",
    website: "http://www.thelocalfcu.com",
  },
  "22754": {
    loansProduced: "4.12",
    membersServed: "428",
    interestEarned: "0.35",
    name: "C C S E",
    website: "http://www.ccseonline.com",
  },
  "22769": {
    loansProduced: "26.92",
    membersServed: "3,333",
    interestEarned: "2.29",
    name: "QUORUM",
    website: "http://quorumfcu.org",
  },
  "22785": {
    loansProduced: "0.06",
    membersServed: "19",
    interestEarned: "0.00",
    name: "RICHLAND PARISH SCHOOLS",
    website: "http://www.richland.k12.la.us/credi",
  },
  "22883": {
    loansProduced: "1.38",
    membersServed: "178",
    interestEarned: "0.12",
    name: "ALLEGANY FIRST",
    website: "http://www.alleganyfirstfcu.com",
  },
  "22931": {
    loansProduced: "36.73",
    membersServed: "5,441",
    interestEarned: "3.12",
    name: "G.P.O.",
    website: "http://www.gpofcu.com",
  },
  "22953": {
    loansProduced: "0.41",
    membersServed: "48",
    interestEarned: "0.03",
    name: "AVH",
    website: "http://www.avhfcu.com",
  },
  "22962": {
    loansProduced: "1.17",
    membersServed: "126",
    interestEarned: "0.10",
    name: "TOMPKINS EMPLOYEES",
    website: "http://www.tefcu.com",
  },
  "22972": {
    loansProduced: "1.97",
    membersServed: "287",
    interestEarned: "0.17",
    name: "CROUSE",
    website: "http://www.crousefcu.com",
  },
  "22978": {
    loansProduced: "2.30",
    membersServed: "186",
    interestEarned: "0.20",
    name: "MSU",
    website: "www.msu-fcu.org",
  },
  "23004": {
    loansProduced: "49.27",
    membersServed: "5,322",
    interestEarned: "4.19",
    name: "POPA",
    website: "http://www.popafcu.org",
  },
  "23015": {
    loansProduced: "2.21",
    membersServed: "151",
    interestEarned: "0.19",
    name: "PRESTON",
    website: "https://prestonfcu.com/",
  },
  "23017": {
    loansProduced: "9.43",
    membersServed: "1,109",
    interestEarned: "0.80",
    name: "XPLORE",
    website: "http://www.xplorefcu.com",
  },
  "23020": {
    loansProduced: "103.93",
    membersServed: "14,983",
    interestEarned: "8.83",
    name: "PUBLIX EMPLOYEES",
    website: "http://www.pefcu.com",
  },
  "23037": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "WHITE ROCK",
    website: null,
  },
  "23052": {
    loansProduced: "0.84",
    membersServed: "90",
    interestEarned: "0.07",
    name: "HMC (NJ)",
    website: "http://www.hmcfederalcreditunion.or",
  },
  "23060": {
    loansProduced: "1.77",
    membersServed: "120",
    interestEarned: "0.15",
    name: "SEVIER COUNTY SCHOOLS",
    website: "http://www.scscreditunion.org",
  },
  "23070": {
    loansProduced: "3.95",
    membersServed: "379",
    interestEarned: "0.34",
    name: "MOREHEAD COMMUNITY",
    website: "http://moreheadcommunity.com",
  },
  "23109": {
    loansProduced: "1.62",
    membersServed: "90",
    interestEarned: "0.14",
    name: "MOLEX EMPLOYEES",
    website: "http://molexfcu.com",
  },
  "23125": {
    loansProduced: "0.21",
    membersServed: "63",
    interestEarned: "0.02",
    name: "PENINSULA GEN HOSP&MED CENTR EMPLS",
    website: null,
  },
  "23144": {
    loansProduced: "2.61",
    membersServed: "341",
    interestEarned: "0.22",
    name: "LOCAL 804",
    website: "http://www.cu804.org",
  },
  "23156": {
    loansProduced: "1.88",
    membersServed: "290",
    interestEarned: "0.16",
    name: "GENERATIONS",
    website: "http://generationslaportefederalcre",
  },
  "23162": {
    loansProduced: "8.58",
    membersServed: "965",
    interestEarned: "0.73",
    name: "ELEMENT",
    website: "http://www.elementfcu.org",
  },
  "23170": {
    loansProduced: "1.39",
    membersServed: "131",
    interestEarned: "0.12",
    name: "ARKANSAS EDUCATION ASSOCIATION",
    website: "http://aeacu.org",
  },
  "23174": {
    loansProduced: "1.50",
    membersServed: "137",
    interestEarned: "0.13",
    name: "U.A.L.U. 354",
    website: null,
  },
  "23177": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "SOUTHERN BAPTIST CHURCH OF NEW YORK",
    website: null,
  },
  "23184": {
    loansProduced: "0.29",
    membersServed: "29",
    interestEarned: "0.02",
    name: "TEXAS ASSOCIATIONS OF PROFESSIONALS",
    website: "http://www.tapfcu.coop",
  },
  "23198": {
    loansProduced: "2.66",
    membersServed: "252",
    interestEarned: "0.23",
    name: "TRI-LAKES",
    website: "http://www.trilakesfcu.com",
  },
  "23245": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "TRINITY U.C.C.",
    website: null,
  },
  "23276": {
    loansProduced: "2.10",
    membersServed: "152",
    interestEarned: "0.18",
    name: "MARBLEHEAD MUNICIPAL",
    website: "www.marbleheadcu.com",
  },
  "23279": {
    loansProduced: "668.08",
    membersServed: "66,751",
    interestEarned: "56.79",
    name: "MICHIGAN STATE UNIVERSITY",
    website: "http://www.msufcu.org",
  },
  "23283": {
    loansProduced: "7.80",
    membersServed: "1,194",
    interestEarned: "0.66",
    name: "ALTERNATIVES",
    website: "http://www.alternatives.org",
  },
  "23287": {
    loansProduced: "1.27",
    membersServed: "79",
    interestEarned: "0.11",
    name: "VULCRAFT EMPLOYEES",
    website: "http://vulcraftfcu.com",
  },
  "23292": {
    loansProduced: "1.46",
    membersServed: "112",
    interestEarned: "0.12",
    name: "GALLUP",
    website: "http://www.gallupfcu.com",
  },
  "23294": {
    loansProduced: "0.10",
    membersServed: "44",
    interestEarned: "0.01",
    name: "VITELCO EMPLOYEES",
    website: "http://www.vitelcocu.com",
  },
  "23309": {
    loansProduced: "0.92",
    membersServed: "755",
    interestEarned: "0.08",
    name: "SISSETON-WAHPETON",
    website: "http://www.sisseton-wahpetonfcu.com",
  },
  "23327": {
    loansProduced: "0.72",
    membersServed: "75",
    interestEarned: "0.06",
    name: "SOUTHERN GAS",
    website: "http://www.SouthernGasFCU.com",
  },
  "23355": {
    loansProduced: "2.48",
    membersServed: "176",
    interestEarned: "0.21",
    name: "SHELTER INSURANCE",
    website: "http://www.shelterfcu.com",
  },
  "23361": {
    loansProduced: "2.74",
    membersServed: "441",
    interestEarned: "0.23",
    name: "ELECTRIC COOPERATIVES",
    website: "http://www.ElectricCoopFCU.org",
  },
  "23371": {
    loansProduced: "8.06",
    membersServed: "655",
    interestEarned: "0.68",
    name: "HEALTHCARE PLUS",
    website: "www.hcpfcu.coop",
  },
  "23376": {
    loansProduced: "2.01",
    membersServed: "200",
    interestEarned: "0.17",
    name: "DOE RUN",
    website: "http://www.doerunfcu.com",
  },
  "23386": {
    loansProduced: "8.64",
    membersServed: "3,133",
    interestEarned: "0.73",
    name: "ACCLAIM",
    website: "http://www.AcclaimFCU.org",
  },
  "23394": {
    loansProduced: "7.55",
    membersServed: "1,084",
    interestEarned: "0.64",
    name: "GUTHRIE COMMUNITY",
    website: "http://www.guthriefcu.org",
  },
  "23421": {
    loansProduced: "1.86",
    membersServed: "318",
    interestEarned: "0.16",
    name: "LOYOLA UNIVERSITY EMPLOYEES",
    website: "http://www.luefcu.org",
  },
  "23425": {
    loansProduced: "1.62",
    membersServed: "247",
    interestEarned: "0.14",
    name: "ACADEMIC",
    website: "http://www.academicfcu.org",
  },
  "23467": {
    loansProduced: "0.60",
    membersServed: "82",
    interestEarned: "0.05",
    name: "VALLEY PRIDE",
    website: "https://www.itsme247.com/408",
  },
  "23494": {
    loansProduced: "40.42",
    membersServed: "5,453",
    interestEarned: "3.44",
    name: "CAROLINAS TELCO",
    website: "http://www.ctelco.org",
  },
  "23503": {
    loansProduced: "0.29",
    membersServed: "136",
    interestEarned: "0.02",
    name: "KOREAN AMERICAN CATHOLICS",
    website: "http://www.kacfcu.org",
  },
  "23521": {
    loansProduced: "1,576.70",
    membersServed: "154,667",
    interestEarned: "134.02",
    name: "DIGITAL",
    website: "http://www.dcu.org",
  },
  "23525": {
    loansProduced: "0.09",
    membersServed: "7",
    interestEarned: "0.01",
    name: "ST. LUKE'S COMMUNITY",
    website: null,
  },
  "23540": {
    loansProduced: "3.40",
    membersServed: "373",
    interestEarned: "0.29",
    name: "TULANE/LOYOLA",
    website: "http://www.tulane-loyolafcu.com",
  },
  "23545": {
    loansProduced: "3.92",
    membersServed: "378",
    interestEarned: "0.33",
    name: "CHHE",
    website: "http://www.chhe.org",
  },
  "23556": {
    loansProduced: "0.24",
    membersServed: "52",
    interestEarned: "0.02",
    name: "PAPER CONVERTERS LOCAL 286/1034",
    website: null,
  },
  "23582": {
    loansProduced: "2.53",
    membersServed: "431",
    interestEarned: "0.21",
    name: "BAYOU CITY",
    website: "http://www.bcfcu.com",
  },
  "23605": {
    loansProduced: "36.49",
    membersServed: "2,457",
    interestEarned: "3.10",
    name: "SYNERGY",
    website: "http://www.synergyfcu.org",
  },
  "23615": {
    loansProduced: "0.01",
    membersServed: "8",
    interestEarned: "0.00",
    name: "HEARD A.M.E.",
    website: null,
  },
  "23620": {
    loansProduced: "3.83",
    membersServed: "407",
    interestEarned: "0.33",
    name: "GEISMAR COMPLEX",
    website: "http://www.gcfcu.net",
  },
  "23627": {
    loansProduced: "0.18",
    membersServed: "22",
    interestEarned: "0.02",
    name: "M.O.S.E.S.",
    website: "https://moses-ma.org/moses-federal-credit-union/",
  },
  "23630": {
    loansProduced: "0.35",
    membersServed: "31",
    interestEarned: "0.03",
    name: "B. M. H.",
    website: null,
  },
  "23658": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "PAUL QUINN",
    website: "http://PaulQuinnFCU.org",
  },
  "23678": {
    loansProduced: "0.32",
    membersServed: "37",
    interestEarned: "0.03",
    name: "GOYA FOODS EMPLOYEES",
    website: null,
  },
  "23700": {
    loansProduced: "3.50",
    membersServed: "245",
    interestEarned: "0.30",
    name: "O AND R UTILITIES EMPLOYEES",
    website: "http://www.orutilfcu.org",
  },
  "23709": {
    loansProduced: "15.82",
    membersServed: "2,247",
    interestEarned: "1.35",
    name: "JM ASSOCIATES",
    website: "https://www.jmafcu.org",
  },
  "23717": {
    loansProduced: "1.58",
    membersServed: "183",
    interestEarned: "0.13",
    name: "TOWN OF CHEEKTOWAGA",
    website: "http://WWW.TOWNOFCHEEKTOWAGAFCU.COM",
  },
  "23722": {
    loansProduced: "2.51",
    membersServed: "164",
    interestEarned: "0.21",
    name: "RALEIGH CO. EDUCATORS",
    website: "http://www.raleighcountyefcu.com",
  },
  "23725": {
    loansProduced: "0.20",
    membersServed: "14",
    interestEarned: "0.02",
    name: "B. BRAUN",
    website: null,
  },
  "23753": {
    loansProduced: "1.27",
    membersServed: "175",
    interestEarned: "0.11",
    name: "ALTOONA AREA EMPLOYEES",
    website: "http://ALTOONAFCU.COM",
  },
  "23760": {
    loansProduced: "0.10",
    membersServed: "98",
    interestEarned: "0.01",
    name: "HALIFAX COUNTY COMMUNITY",
    website: null,
  },
  "23780": {
    loansProduced: "0.02",
    membersServed: "6",
    interestEarned: "0.00",
    name: "NORTHEAST COMMUNITY",
    website: "http://www.necfcu.org",
  },
  "23803": {
    loansProduced: "0.36",
    membersServed: "237",
    interestEarned: "0.03",
    name: "TAUPA LITHUANIAN",
    website: "https://taupaboston.com",
  },
  "23807": {
    loansProduced: "1.36",
    membersServed: "74",
    interestEarned: "0.12",
    name: "I.L.A. LOCAL 1235",
    website: "http://www.ila1235fcu.com",
  },
  "23811": {
    loansProduced: "1.29",
    membersServed: "106",
    interestEarned: "0.11",
    name: "MID-ISLAND",
    website: "http://www.midislandfcu.org",
  },
  "23848": {
    loansProduced: "1.85",
    membersServed: "419",
    interestEarned: "0.16",
    name: "GENESEE CO-OP",
    website: "http://WWW.GENESEE.COOP",
  },
  "23865": {
    loansProduced: "1.36",
    membersServed: "415",
    interestEarned: "0.12",
    name: "SYRACUSE COOPERATIVE",
    website: "http://www.cooperativefederal.org",
  },
  "23888": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "BEREA",
    website: null,
  },
  "23896": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "EAST END BAPTIST TABERNACLE",
    website: null,
  },
  "23899": {
    loansProduced: "0.00",
    membersServed: "3",
    interestEarned: "0.00",
    name: "A M E CHURCH",
    website: null,
  },
  "23900": {
    loansProduced: "2.18",
    membersServed: "247",
    interestEarned: "0.19",
    name: "N. MISSISSIPPI HEALTH SERVICES EMP.",
    website: "http://www.nmhs.net/creditunion",
  },
  "23908": {
    loansProduced: "0.28",
    membersServed: "81",
    interestEarned: "0.02",
    name: "FLINT RIVER EMPLOYEES",
    website: null,
  },
  "23949": {
    loansProduced: "30.95",
    membersServed: "2,613",
    interestEarned: "2.63",
    name: "SIGNET",
    website: "http://www.signetfcu.com",
  },
  "23952": {
    loansProduced: "5.19",
    membersServed: "1,034",
    interestEarned: "0.44",
    name: "UNITED ARKANSAS",
    website: "http://www.unitedarkansas.org",
  },
  "23957": {
    loansProduced: "486.30",
    membersServed: "54,543",
    interestEarned: "41.34",
    name: "AMERICAN AIRLINES",
    website: "http://www.aacreditunion.org",
  },
  "23958": {
    loansProduced: "5.23",
    membersServed: "914",
    interestEarned: "0.44",
    name: "NEW YORK UNIVERSITY",
    website: "http://www.nyufcu.com",
  },
  "23964": {
    loansProduced: "11.05",
    membersServed: "819",
    interestEarned: "0.94",
    name: "FIRST CALIFORNIA",
    website: "http://www.firstcalfcu.org",
  },
  "23974": {
    loansProduced: "0.16",
    membersServed: "19",
    interestEarned: "0.01",
    name: "YOGAVILLE",
    website: null,
  },
  "23975": {
    loansProduced: "1.01",
    membersServed: "386",
    interestEarned: "0.09",
    name: "LINK",
    website: "http://www.linkfcu.org",
  },
  "23979": {
    loansProduced: "1.29",
    membersServed: "1,692",
    interestEarned: "0.11",
    name: "GEORGETOWN UNIVERSITY ALUMNI AND ST",
    website: "http://www.guasfcu.com",
  },
  "23986": {
    loansProduced: "3.88",
    membersServed: "212",
    interestEarned: "0.33",
    name: "UMATILLA COUNTY",
    website: "www.umatillacfcu.com",
  },
  "23990": {
    loansProduced: "0.32",
    membersServed: "121",
    interestEarned: "0.03",
    name: "ANDALUSIA MILLS EMPLOYEES CREDIT AS",
    website: null,
  },
  "24003": {
    loansProduced: "350.67",
    membersServed: "43,155",
    interestEarned: "29.81",
    name: "LOCAL GOVERNMENT",
    website: "http://www.lgfcu.org",
  },
  "24026": {
    loansProduced: "42.60",
    membersServed: "5,506",
    interestEarned: "3.62",
    name: "INDUSTRIAL",
    website: "http://www.ifcu.com",
  },
  "24029": {
    loansProduced: "49.97",
    membersServed: "3,202",
    interestEarned: "4.25",
    name: "SCIENT",
    website: "https://www.scientfcu.org",
  },
  "24030": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "NEW RISING STAR",
    website: null,
  },
  "24034": {
    loansProduced: "75.57",
    membersServed: "10,712",
    interestEarned: "6.42",
    name: "NOTRE DAME",
    website: "http://WWW.NOTREDAMEFCU.COM",
  },
  "24036": {
    loansProduced: "2.74",
    membersServed: "251",
    interestEarned: "0.23",
    name: "STEWART'S",
    website: "http://www.stewartscu.com",
  },
  "24039": {
    loansProduced: "0.45",
    membersServed: "60",
    interestEarned: "0.04",
    name: "N.I.C.E.",
    website: "http://nicefederalcu.org",
  },
  "24043": {
    loansProduced: "0.09",
    membersServed: "12",
    interestEarned: "0.01",
    name: "MESSIAH BAPTIST-JUBILEE",
    website: "www.mbjfcu.org",
  },
  "24047": {
    loansProduced: "23.44",
    membersServed: "2,710",
    interestEarned: "1.99",
    name: "FOREST AREA",
    website: "http://www.forestarea.com",
  },
  "24063": {
    loansProduced: "470.86",
    membersServed: "60,196",
    interestEarned: "40.02",
    name: "FOUNDERS",
    website: "https://www.foundersfcu.com",
  },
  "24073": {
    loansProduced: "0.03",
    membersServed: "7",
    interestEarned: "0.00",
    name: "PARAMOUNT BAPTIST CHURCH",
    website: null,
  },
  "24077": {
    loansProduced: "257.78",
    membersServed: "24,994",
    interestEarned: "21.91",
    name: "ALTRA",
    website: "http://www.altra.org",
  },
  "24078": {
    loansProduced: "11.73",
    membersServed: "678",
    interestEarned: "1.00",
    name: "ADVANCE FINANCIAL",
    website: "http://www.Advancefcu.org",
  },
  "24090": {
    loansProduced: "1.42",
    membersServed: "158",
    interestEarned: "0.12",
    name: "NATRIUM EMPLOYEES",
    website: "http://www.natriumefcu.com",
  },
  "24104": {
    loansProduced: "0.20",
    membersServed: "104",
    interestEarned: "0.02",
    name: "TROUVAILLE",
    website: "http://www.trouvaillefcu.com",
  },
  "24108": {
    loansProduced: "8.21",
    membersServed: "652",
    interestEarned: "0.70",
    name: "INDIANA HEARTLAND",
    website: "http://www.indianaheartland.org",
  },
  "24110": {
    loansProduced: "3.70",
    membersServed: "524",
    interestEarned: "0.31",
    name: "SAN DIEGO FIREFIGHTERS",
    website: "http://www.sdffcu.org",
  },
  "24123": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "M.W.P.H. GRAND LODGE OF ILLINOIS",
    website: null,
  },
  "24131": {
    loansProduced: "5.60",
    membersServed: "398",
    interestEarned: "0.48",
    name: "UNION YES",
    website: "http://www.uyfcu.og",
  },
  "24140": {
    loansProduced: "0.42",
    membersServed: "93",
    interestEarned: "0.04",
    name: "NORTH CAROLINA PRESS ASSOCIATION",
    website: "http://www.ncpafcu.org",
  },
  "24150": {
    loansProduced: "10.53",
    membersServed: "1,058",
    interestEarned: "0.89",
    name: "HEALTHCARE EMPLOYEES",
    website: "http://www.hefcu.com",
  },
  "24165": {
    loansProduced: "152.00",
    membersServed: "14,935",
    interestEarned: "12.92",
    name: "UNIVERSITY OF KENTUCKY",
    website: "http://www.ukfcu.org",
  },
  "24167": {
    loansProduced: "0.01",
    membersServed: "6",
    interestEarned: "0.00",
    name: "NEW COMMUNITY",
    website: "http://www.newcommunityFCU.org",
  },
  "24171": {
    loansProduced: "11.72",
    membersServed: "1,022",
    interestEarned: "1.00",
    name: "INDEPENDENT",
    website: "http://www.independentfcu.org",
  },
  "24181": {
    loansProduced: "60.45",
    membersServed: "6,674",
    interestEarned: "5.14",
    name: "ADVANTAGE",
    website: "http://www.advantagefcu.org",
  },
  "24187": {
    loansProduced: "12.00",
    membersServed: "1,089",
    interestEarned: "1.02",
    name: "ARIZE",
    website: "http://www.arizefcu.org",
  },
  "24189": {
    loansProduced: "1.29",
    membersServed: "171",
    interestEarned: "0.11",
    name: "CENTRAL NEBRASKA",
    website: "http://www.cnfcu.com",
  },
  "24192": {
    loansProduced: "2.34",
    membersServed: "138",
    interestEarned: "0.20",
    name: "BEVERLY MUNICIPAL",
    website: "http://www.beverlycreditunion.com",
  },
  "24197": {
    loansProduced: "15.26",
    membersServed: "1,364",
    interestEarned: "1.30",
    name: "MIL-WAY",
    website: "http://www.milway.org",
  },
  "24199": {
    loansProduced: "8.86",
    membersServed: "1,418",
    interestEarned: "0.75",
    name: "COMMUNITY FIRST",
    website: "http://cffcu.biz",
  },
  "24200": {
    loansProduced: "0.10",
    membersServed: "43",
    interestEarned: "0.01",
    name: "LOGAN COUNTY SCHOOL EMPLOYEES",
    website: null,
  },
  "24201": {
    loansProduced: "0.49",
    membersServed: "73",
    interestEarned: "0.04",
    name: "I. C.",
    website: "ic-fcu.com",
  },
  "24202": {
    loansProduced: "14.74",
    membersServed: "1,044",
    interestEarned: "1.25",
    name: "TELCOE",
    website: "http://www.telcoe.com",
  },
  "24204": {
    loansProduced: "6.84",
    membersServed: "864",
    interestEarned: "0.58",
    name: "EVERGLADES",
    website: "http://www.evergladesfcu.com",
  },
  "24212": {
    loansProduced: "2,717.96",
    membersServed: "282,600",
    interestEarned: "231.03",
    name: "SCHOOLSFIRST",
    website: "https://www.schoolsfirstfcu.org",
  },
  "24219": {
    loansProduced: "0.11",
    membersServed: "20",
    interestEarned: "0.01",
    name: "MT. AIRY BAPTIST CHURCH",
    website: null,
  },
  "24220": {
    loansProduced: "4.21",
    membersServed: "755",
    interestEarned: "0.36",
    name: "KEMBA DELTA",
    website: "http://www.kembadelta.org",
  },
  "24221": {
    loansProduced: "2.97",
    membersServed: "278",
    interestEarned: "0.25",
    name: "CHATTAHOOCHEE",
    website: "https://WWW.CHATT.COOP",
  },
  "24223": {
    loansProduced: "153.07",
    membersServed: "11,095",
    interestEarned: "13.01",
    name: "USF FEDERAL CREDIT UNION",
    website: "http://www.usffcu.com",
  },
  "24224": {
    loansProduced: "317.96",
    membersServed: "24,332",
    interestEarned: "27.03",
    name: "ROCKLAND",
    website: "http://www.rfcu.com",
  },
  "24226": {
    loansProduced: "1.35",
    membersServed: "105",
    interestEarned: "0.11",
    name: "P.S.E. & G. NUCLEAR EMPLOYEES",
    website: "http://www.psegnuclearcreditunion.c",
  },
  "24232": {
    loansProduced: "1.82",
    membersServed: "474",
    interestEarned: "0.15",
    name: "LOWER EAST SIDE PEOPLE'S",
    website: "http://WWW.LESPEOPLES.ORG",
  },
  "24234": {
    loansProduced: "0.54",
    membersServed: "51",
    interestEarned: "0.05",
    name: "OMEGA PSI PHI FRATERNITY",
    website: "http://www.oppffcu.com",
  },
  "24235": {
    loansProduced: "114.73",
    membersServed: "11,456",
    interestEarned: "9.75",
    name: "OPERATING ENGINEERS LOCAL UNION #3",
    website: "http://oefederal.org",
  },
  "24236": {
    loansProduced: "4.86",
    membersServed: "438",
    interestEarned: "0.41",
    name: "HUNTINGTON C & O RAILWAY EMPLOYEES",
    website: "http://www.c-ocreditunion.com",
  },
  "24238": {
    loansProduced: "0.90",
    membersServed: "132",
    interestEarned: "0.08",
    name: "CALIFORNIA ADVENTIST",
    website: "http://www.SDACreditUnion.com",
  },
  "24239": {
    loansProduced: "4.90",
    membersServed: "403",
    interestEarned: "0.42",
    name: "SOMERSET",
    website: "http://www.somersetfcu.com",
  },
  "24246": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "MT. JEZREEL",
    website: "http://www.mjfcu.org",
  },
  "24250": {
    loansProduced: "66.51",
    membersServed: "5,526",
    interestEarned: "5.65",
    name: "I-C",
    website: "http://www.iccreditunion.org",
  },
  "24254": {
    loansProduced: "89.27",
    membersServed: "6,286",
    interestEarned: "7.59",
    name: "FIRST CITIZENS'",
    website: "http://www.firstcitizens.org",
  },
  "24257": {
    loansProduced: "3.34",
    membersServed: "276",
    interestEarned: "0.28",
    name: "RIVER TOWN",
    website: "https://www.rivertownfcu.org",
  },
  "24262": {
    loansProduced: "0.09",
    membersServed: "7",
    interestEarned: "0.01",
    name: "PHI BETA SIGMA",
    website: "http://www.pbsfcu.org",
  },
  "24266": {
    loansProduced: "0.01",
    membersServed: "4",
    interestEarned: "0.00",
    name: "M.A.B.C.",
    website: null,
  },
  "24270": {
    loansProduced: "0.10",
    membersServed: "5",
    interestEarned: "0.01",
    name: "FIRST FRONTIER",
    website: null,
  },
  "24273": {
    loansProduced: "0.29",
    membersServed: "193",
    interestEarned: "0.02",
    name: "MISSISSIPPI CENTRAL",
    website: null,
  },
  "24274": {
    loansProduced: "1.88",
    membersServed: "166",
    interestEarned: "0.16",
    name: "GREECE COMMUNITY",
    website: "http://www.greecefcu.com",
  },
  "24279": {
    loansProduced: "10.00",
    membersServed: "1,785",
    interestEarned: "0.85",
    name: "GFA",
    website: "http://GFAFCU.COM",
  },
  "24281": {
    loansProduced: "2.42",
    membersServed: "612",
    interestEarned: "0.21",
    name: "MOSAIC",
    website: "http://www.mosaicfcu.org",
  },
  "24284": {
    loansProduced: "0.16",
    membersServed: "11",
    interestEarned: "0.01",
    name: "PLUMBERS & FITTERS LOCAL 675",
    website: null,
  },
  "24286": {
    loansProduced: "21.80",
    membersServed: "1,719",
    interestEarned: "1.85",
    name: "NEBRASKA ENERGY",
    website: "http://www.ne-fcu.org",
  },
  "24290": {
    loansProduced: "0.12",
    membersServed: "1,101",
    interestEarned: "0.01",
    name: "UNIVERSITY OF PENNSYLVANIA STUDENTS",
    website: "http://www.upennsfcu.org",
  },
  "24292": {
    loansProduced: "98.24",
    membersServed: "10,286",
    interestEarned: "8.35",
    name: "NORTHROP GRUMMAN",
    website: "http://www.ngfcu.us",
  },
  "24299": {
    loansProduced: "48.05",
    membersServed: "6,810",
    interestEarned: "4.08",
    name: "SANTA CLARA COUNTY",
    website: "http://www.sccfcu.org",
  },
  "24304": {
    loansProduced: "24.67",
    membersServed: "3,698",
    interestEarned: "2.10",
    name: "BORDER",
    website: "http://www.borderfcu.com",
  },
  "24312": {
    loansProduced: "79.87",
    membersServed: "9,983",
    interestEarned: "6.79",
    name: "NORTH COUNTRY",
    website: "http://www.northcountry.org",
  },
  "24313": {
    loansProduced: "57.60",
    membersServed: "5,078",
    interestEarned: "4.90",
    name: "ARGENT",
    website: "http://www.argentcu.org",
  },
  "24324": {
    loansProduced: "1.52",
    membersServed: "387",
    interestEarned: "0.13",
    name: "MET TRAN",
    website: "http://www.mettranfcu.org",
  },
  "24326": {
    loansProduced: "75.46",
    membersServed: "4,576",
    interestEarned: "6.41",
    name: "CHEVRON",
    website: "http://www.chevronfcu.org",
  },
  "24327": {
    loansProduced: "31.21",
    membersServed: "3,088",
    interestEarned: "2.65",
    name: "SIOUXLAND",
    website: "www.siouxlandfederalcu.com",
  },
  "24358": {
    loansProduced: "36.90",
    membersServed: "2,417",
    interestEarned: "3.14",
    name: "ST. PAUL",
    website: "http://www.stpaulfcu.org",
  },
  "24362": {
    loansProduced: "381.49",
    membersServed: "47,528",
    interestEarned: "32.43",
    name: "AFFINITY PLUS",
    website: "http://www.affinityplus.org",
  },
  "24373": {
    loansProduced: "3.38",
    membersServed: "248",
    interestEarned: "0.29",
    name: "LOCAL UNION 392",
    website: "http://www.392fcu.org",
  },
  "24381": {
    loansProduced: "1.66",
    membersServed: "260",
    interestEarned: "0.14",
    name: "DELTA COUNTY",
    website: "http://www.deltacountyfcu.com",
  },
  "24382": {
    loansProduced: "457.18",
    membersServed: "25,492",
    interestEarned: "38.86",
    name: "CREDIT HUMAN",
    website: "https://www.credithuman.com",
  },
  "24384": {
    loansProduced: "34.06",
    membersServed: "1,205",
    interestEarned: "2.89",
    name: "NIZARI PROGRESSIVE",
    website: "http://www.nizaricu.org",
  },
  "24385": {
    loansProduced: "4.36",
    membersServed: "622",
    interestEarned: "0.37",
    name: "BLUE CHIP",
    website: "http://www.bluechipfcu.org",
  },
  "24387": {
    loansProduced: "0.84",
    membersServed: "83",
    interestEarned: "0.07",
    name: "SYRACUSE POSTAL",
    website: null,
  },
  "24390": {
    loansProduced: "6.45",
    membersServed: "546",
    interestEarned: "0.55",
    name: "TRUSERVICE COMMUNITY",
    website: "http://www.truservice.net",
  },
  "24396": {
    loansProduced: "1.63",
    membersServed: "137",
    interestEarned: "0.14",
    name: "NEIGHBORHOOD COMMUNITY",
    website: "http://www.neighborhoodcfcu.org",
  },
  "24402": {
    loansProduced: "10.08",
    membersServed: "1,418",
    interestEarned: "0.86",
    name: "ONEUNITED",
    website: "http://www.bankingwithyou.com",
  },
  "24404": {
    loansProduced: "9.25",
    membersServed: "1,058",
    interestEarned: "0.79",
    name: "CARO",
    website: "http://www.SmartCaro.org",
  },
  "24405": {
    loansProduced: "45.47",
    membersServed: "7,499",
    interestEarned: "3.87",
    name: "VERMONT",
    website: "http://www.vermontfederal.org",
  },
  "24410": {
    loansProduced: "157.08",
    membersServed: "18,226",
    interestEarned: "13.35",
    name: "SRP",
    website: "https://www.srpfcu.org",
  },
  "24414": {
    loansProduced: "13.46",
    membersServed: "1,949",
    interestEarned: "1.14",
    name: "BUILDING TRADES",
    website: "http://www.buildingtradesCU.com",
  },
  "24421": {
    loansProduced: "68.35",
    membersServed: "5,775",
    interestEarned: "5.81",
    name: "LEGACY COMMUNITY",
    website: "http://www.legacycreditunion.com",
  },
  "24422": {
    loansProduced: "3.64",
    membersServed: "370",
    interestEarned: "0.31",
    name: "UP ARKANSAS",
    website: "http://www.upfcu.org",
  },
  "24423": {
    loansProduced: "0.08",
    membersServed: "13",
    interestEarned: "0.01",
    name: "PINE BLUFF POSTAL",
    website: null,
  },
  "24428": {
    loansProduced: "3.52",
    membersServed: "259",
    interestEarned: "0.30",
    name: "WHITE COUNTY",
    website: "https://whitecountyfcu.org",
  },
  "24429": {
    loansProduced: "2.03",
    membersServed: "128",
    interestEarned: "0.17",
    name: "VA HOSPITAL",
    website: "http://VAHospitalFCU.org",
  },
  "24430": {
    loansProduced: "0.73",
    membersServed: "62",
    interestEarned: "0.06",
    name: "FORT ROOTS",
    website: "http://WWW.FORTROOTSFCU.COM",
  },
  "24431": {
    loansProduced: "0.85",
    membersServed: "133",
    interestEarned: "0.07",
    name: "COOPERATIVE EXTENSION SERVICE",
    website: "http://cesfcu.org",
  },
  "24432": {
    loansProduced: "1.65",
    membersServed: "163",
    interestEarned: "0.14",
    name: "NATURAL STATE",
    website: "https://www.naturalstatefcu.net",
  },
  "24433": {
    loansProduced: "0.48",
    membersServed: "121",
    interestEarned: "0.04",
    name: "ARKANSAS TEACHERS",
    website: "http://artfcu.org",
  },
  "24434": {
    loansProduced: "0.12",
    membersServed: "21",
    interestEarned: "0.01",
    name: "NORTH LITTLE ROCK EDUCATORS",
    website: "http://www.nlrsd.org/credit-union-i",
  },
  "24435": {
    loansProduced: "0.19",
    membersServed: "106",
    interestEarned: "0.02",
    name: "U.P. EMPLOYEES",
    website: "http://www.upemployees.org",
  },
  "24437": {
    loansProduced: "2.10",
    membersServed: "218",
    interestEarned: "0.18",
    name: "LION",
    website: "http://www.lioncreditunion.com",
  },
  "24438": {
    loansProduced: "193.58",
    membersServed: "19,870",
    interestEarned: "16.45",
    name: "ALLEGACY",
    website: "http://www.allegacy.org",
  },
  "24439": {
    loansProduced: "1.01",
    membersServed: "73",
    interestEarned: "0.09",
    name: "ARKANSAS FARM BUREAU",
    website: "http://www.afbfcu.virtualcu.net",
  },
  "24443": {
    loansProduced: "12.63",
    membersServed: "1,568",
    interestEarned: "1.07",
    name: "PIONEER VALLEY",
    website: "https://www.pvcu.org",
  },
  "24445": {
    loansProduced: "7.55",
    membersServed: "803",
    interestEarned: "0.64",
    name: "BP",
    website: "http://www.bpfcu.org",
  },
  "24451": {
    loansProduced: "1.56",
    membersServed: "164",
    interestEarned: "0.13",
    name: "SPOKANE MEDIA",
    website: "http://smfcu.org",
  },
  "24454": {
    loansProduced: "3.13",
    membersServed: "316",
    interestEarned: "0.27",
    name: "MID-TEX",
    website: "http://MTFCU.ORG",
  },
  "24463": {
    loansProduced: "0.18",
    membersServed: "12",
    interestEarned: "0.02",
    name: "BRENTWOOD BAPTIST CHURCH",
    website: "http://www.bbcfcu.org",
  },
  "24464": {
    loansProduced: "8.06",
    membersServed: "1,036",
    interestEarned: "0.69",
    name: "KNOXVILLE TEACHERS",
    website: "https://ktfcu.org",
  },
  "24465": {
    loansProduced: "16.71",
    membersServed: "1,697",
    interestEarned: "1.42",
    name: "GOVERNMENT EMPLOYEES",
    website: "http://www.gefcuaustin.org",
  },
  "24470": {
    loansProduced: "67.07",
    membersServed: "7,169",
    interestEarned: "5.70",
    name: "EDUCATION FIRST",
    website: "http://www.educationfirstfcu.org",
  },
  "24472": {
    loansProduced: "0.57",
    membersServed: "67",
    interestEarned: "0.05",
    name: "POSTAL EMPLOYEES REGIONAL",
    website: null,
  },
  "24479": {
    loansProduced: "10.53",
    membersServed: "816",
    interestEarned: "0.90",
    name: "CITY & POLICE",
    website: "http://www.cityfcu.com",
  },
  "24481": {
    loansProduced: "8.89",
    membersServed: "631",
    interestEarned: "0.76",
    name: "IRVING CITY EMPLOYEES",
    website: "http://icefcu.com",
  },
  "24482": {
    loansProduced: "11.94",
    membersServed: "671",
    interestEarned: "1.02",
    name: "SOUTH METRO",
    website: "http://www.southmet.com",
  },
  "24483": {
    loansProduced: "0.18",
    membersServed: "30",
    interestEarned: "0.02",
    name: "EAST END FOOD COOPERATIVE",
    website: "https://www.eefcfcu.org",
  },
  "24484": {
    loansProduced: "17.20",
    membersServed: "2,045",
    interestEarned: "1.46",
    name: "UPSTATE",
    website: "http://www.upstatefcu.org",
  },
  "24486": {
    loansProduced: "1.05",
    membersServed: "90",
    interestEarned: "0.09",
    name: "CUMBERLAND MUNICIPAL EMPLOYEES",
    website: "http://www.cumberlandcu.org",
  },
  "24490": {
    loansProduced: "24.85",
    membersServed: "2,192",
    interestEarned: "2.11",
    name: "FIRST CAPITAL",
    website: "http://www.firstcapitalfcu.com",
  },
  "24491": {
    loansProduced: "9.03",
    membersServed: "976",
    interestEarned: "0.77",
    name: "PALMETTO FIRST",
    website: "http://www.palmettofirst.com",
  },
  "24492": {
    loansProduced: "2.88",
    membersServed: "331",
    interestEarned: "0.24",
    name: "DELTA SCHOOLS",
    website: "http://deltaschoolsfcu.org",
  },
  "24495": {
    loansProduced: "12.06",
    membersServed: "1,576",
    interestEarned: "1.02",
    name: "APPALACHIAN COMMUNITY",
    website: "http://www.myacfcu.org",
  },
  "24501": {
    loansProduced: "3.94",
    membersServed: "826",
    interestEarned: "0.33",
    name: "WAVE",
    website: "https://www.wavefcu.org",
  },
  "24506": {
    loansProduced: "0.64",
    membersServed: "51",
    interestEarned: "0.05",
    name: "EPISCOPAL COMMUNITY",
    website: "http://www.efcula.org",
  },
  "24508": {
    loansProduced: "125.82",
    membersServed: "11,883",
    interestEarned: "10.70",
    name: "DADE COUNTY",
    website: "http://www.dcfcu.org",
  },
  "24511": {
    loansProduced: "0.63",
    membersServed: "55",
    interestEarned: "0.05",
    name: "MORTON",
    website: "http://mortonfedcu.com",
  },
  "24516": {
    loansProduced: "0.24",
    membersServed: "129",
    interestEarned: "0.02",
    name: "CLEVELAND SELFRELIANCE",
    website: "http://CLEVELANDSELFRELIANCE.COM",
  },
  "24520": {
    loansProduced: "5.81",
    membersServed: "1,007",
    interestEarned: "0.49",
    name: "SANTA ANA",
    website: "http://www.safcu.org",
  },
  "24523": {
    loansProduced: "5.32",
    membersServed: "3,022",
    interestEarned: "0.45",
    name: "BLACKSTONE RIVER",
    website: "http://www.blackstoneriverfcu.org",
  },
  "24524": {
    loansProduced: "766.19",
    membersServed: "71,960",
    interestEarned: "65.13",
    name: "TINKER",
    website: "https://www.tinkerfcu.org",
  },
  "24526": {
    loansProduced: "8.36",
    membersServed: "596",
    interestEarned: "0.71",
    name: "BRECO",
    website: "http://www.brecofcu.com",
  },
  "24530": {
    loansProduced: "1.19",
    membersServed: "187",
    interestEarned: "0.10",
    name: "PRESIDENTS",
    website: "http://www.presidentsfcu.org",
  },
  "24532": {
    loansProduced: "0.69",
    membersServed: "41",
    interestEarned: "0.06",
    name: "COVENANT SAVINGS",
    website: "mycsfcu.org",
  },
  "24534": {
    loansProduced: "369.25",
    membersServed: "28,710",
    interestEarned: "31.39",
    name: "AMERICA'S FIRST",
    website: "www.amfirst.org",
  },
  "24535": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "FIRST BAPTIST CHURCH OF VIENNA (VA)",
    website: null,
  },
  "24536": {
    loansProduced: "11.73",
    membersServed: "1,107",
    interestEarned: "1.00",
    name: "VITAL",
    website: "https://www.vitalfcu.com",
  },
  "24539": {
    loansProduced: "0.50",
    membersServed: "52",
    interestEarned: "0.04",
    name: "TRANSIT OPERATIONS",
    website: "tofcu.org",
  },
  "24540": {
    loansProduced: "16.51",
    membersServed: "1,344",
    interestEarned: "1.40",
    name: "MUTUAL FIRST",
    website: "http://www.mutualfirst.com",
  },
  "24542": {
    loansProduced: "30.62",
    membersServed: "5,720",
    interestEarned: "2.60",
    name: "SAN FRANCISCO",
    website: "http://www.sanfranciscofcu.com",
  },
  "24543": {
    loansProduced: "39.33",
    membersServed: "2,580",
    interestEarned: "3.34",
    name: "MILLBURY",
    website: "https://www.millburycu.com",
  },
  "24545": {
    loansProduced: "3.64",
    membersServed: "565",
    interestEarned: "0.31",
    name: "LAKE COUNTY EDUCATIONAL",
    website: "https://www.lakecountyedufcu.org",
  },
  "24546": {
    loansProduced: "0.00",
    membersServed: "1",
    interestEarned: "0.00",
    name: "BIG BETHEL A.M.E. CHURCH",
    website: null,
  },
  "24547": {
    loansProduced: "1.34",
    membersServed: "110",
    interestEarned: "0.11",
    name: "EVERGREEN PARK SCHOOLS",
    website: "http://www.epsfcu.org",
  },
  "24549": {
    loansProduced: "7.97",
    membersServed: "398",
    interestEarned: "0.68",
    name: "HANIN",
    website: "http://www.haninfcu.org",
  },
  "24552": {
    loansProduced: "167.36",
    membersServed: "17,539",
    interestEarned: "14.23",
    name: "NOBLE",
    website: "http://www.noblecu.com",
  },
  "24553": {
    loansProduced: "91.43",
    membersServed: "9,629",
    interestEarned: "7.77",
    name: "LOS ANGELES POLICE",
    website: "https://www.lapfcu.org",
  },
  "24557": {
    loansProduced: "96.24",
    membersServed: "6,775",
    interestEarned: "8.18",
    name: "WEBSTER FIRST",
    website: "https://www.websterfirst.com",
  },
  "24560": {
    loansProduced: "94.94",
    membersServed: "8,501",
    interestEarned: "8.07",
    name: "GREYLOCK",
    website: "https://www.greylock.org",
  },
  "24563": {
    loansProduced: "492.46",
    membersServed: "75,906",
    interestEarned: "41.86",
    name: "ESL",
    website: "https://www.esl.org/",
  },
  "24564": {
    loansProduced: "10.14",
    membersServed: "1,473",
    interestEarned: "0.86",
    name: "HEALTHCARE SYSTEMS",
    website: "http://Healthcarefcu.org",
  },
  "24566": {
    loansProduced: "13.71",
    membersServed: "1,830",
    interestEarned: "1.17",
    name: "MASSMUTUAL",
    website: "http://www.massmutualfcu.org",
  },
  "24567": {
    loansProduced: "31.04",
    membersServed: "3,168",
    interestEarned: "2.64",
    name: "TEXAR",
    website: "http://WWW.GOTEXAR.COM",
  },
  "24575": {
    loansProduced: "2.99",
    membersServed: "476",
    interestEarned: "0.25",
    name: "RESOURCE",
    website: "http://Resourcefcu.com",
  },
  "24578": {
    loansProduced: "1.44",
    membersServed: "230",
    interestEarned: "0.12",
    name: "TOLEDO URBAN",
    website: "http://www.toledourban.net",
  },
  "24579": {
    loansProduced: "6.69",
    membersServed: "485",
    interestEarned: "0.57",
    name: "WAKOTA",
    website: "http://www.wakotafcu.org",
  },
  "24583": {
    loansProduced: "0.30",
    membersServed: "44",
    interestEarned: "0.03",
    name: "NRS COMMUNITY DEVELOPMENT",
    website: "http://www.starfcu.net",
  },
  "24585": {
    loansProduced: "17.51",
    membersServed: "2,031",
    interestEarned: "1.49",
    name: "MISSISSIPPI",
    website: "http://WWW.MSFCU.US",
  },
  "24589": {
    loansProduced: "0.25",
    membersServed: "77",
    interestEarned: "0.02",
    name: "NEIGHBORHOOD TRUST",
    website: "http://www.neighborhoodtrustfcu.org",
  },
  "24598": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "VARICK MEMORIAL",
    website: null,
  },
  "24603": {
    loansProduced: "10.86",
    membersServed: "1,032",
    interestEarned: "0.92",
    name: "FAMILY ADVANTAGE",
    website: "http://www.myfafcu.org",
  },
  "24605": {
    loansProduced: "1.65",
    membersServed: "103",
    interestEarned: "0.14",
    name: "MOUNT OLIVE BAPTIST CHURCH",
    website: "http://mobcfcu.com",
  },
  "24606": {
    loansProduced: "24.91",
    membersServed: "2,946",
    interestEarned: "2.12",
    name: "GREAT NORTHWEST",
    website: "http://www.greatnwfcu.com",
  },
  "24612": {
    loansProduced: "0.99",
    membersServed: "64",
    interestEarned: "0.08",
    name: "FAIRMONT SCHOOL EMPLOYEES",
    website: "https://sites.google.com/a/apps.fai",
  },
  "24613": {
    loansProduced: "16.66",
    membersServed: "956",
    interestEarned: "1.42",
    name: "GREEN COUNTRY",
    website: "http://www.greencountryfcu.com",
  },
  "24614": {
    loansProduced: "0.01",
    membersServed: "4",
    interestEarned: "0.00",
    name: "GIDEON",
    website: null,
  },
  "24615": {
    loansProduced: "0.67",
    membersServed: "80",
    interestEarned: "0.06",
    name: "MEDFORD MUNICIPAL EMPLOYEES",
    website: null,
  },
  "24623": {
    loansProduced: "0.98",
    membersServed: "57",
    interestEarned: "0.08",
    name: "BROOKLAND",
    website: "https://www.brooklandfcu.org",
  },
  "24626": {
    loansProduced: "16.41",
    membersServed: "2,322",
    interestEarned: "1.39",
    name: "TRUSTAR",
    website: "http://www.trustarfcu.com",
  },
  "24627": {
    loansProduced: "4.75",
    membersServed: "436",
    interestEarned: "0.40",
    name: "1ST COOPERATIVE",
    website: "http://www.1stcooperative.com",
  },
  "24629": {
    loansProduced: "10.85",
    membersServed: "768",
    interestEarned: "0.92",
    name: "TRUST",
    website: "https://www.trustfcu.com",
  },
  "24630": {
    loansProduced: "18.42",
    membersServed: "3,075",
    interestEarned: "1.57",
    name: "CU HAWAII",
    website: "https://www.cuhawaii.com",
  },
  "24631": {
    loansProduced: "34.32",
    membersServed: "1,515",
    interestEarned: "2.92",
    name: "PLATINUM",
    website: "http://www.Platinumfcu.org",
  },
  "24632": {
    loansProduced: "13.65",
    membersServed: "3,236",
    interestEarned: "1.16",
    name: "GREAT RIVER",
    website: "http://www.greatriverfcu.org",
  },
  "24637": {
    loansProduced: "39.76",
    membersServed: "2,990",
    interestEarned: "3.38",
    name: "LORMET COMMUNITY",
    website: "http://lormet.com",
  },
  "24640": {
    loansProduced: "0.01",
    membersServed: "2",
    interestEarned: "0.00",
    name: "MOUNT PLEASANT BAPTIST CHURCH",
    website: null,
  },
  "24642": {
    loansProduced: "0.94",
    membersServed: "247",
    interestEarned: "0.08",
    name: "BROOKLYN COOPERATIVE",
    website: "http://www.brooklyn.coop",
  },
  "24648": {
    loansProduced: "0.19",
    membersServed: "100",
    interestEarned: "0.02",
    name: "LCO",
    website: "http://www.lcofcu.com",
  },
  "24649": {
    loansProduced: "7.08",
    membersServed: "760",
    interestEarned: "0.60",
    name: "FIRST PRIORITY",
    website: "http://www.firstpriorityfcu.org",
  },
  "24657": {
    loansProduced: "0.49",
    membersServed: "36",
    interestEarned: "0.04",
    name: "NONE SUFFER LACK",
    website: "http://www.nslfcu.org",
  },
  "24658": {
    loansProduced: "7.92",
    membersServed: "1,264",
    interestEarned: "0.67",
    name: "NEXTMARK",
    website: "http://www.NextMarkcu.org",
  },
  "24661": {
    loansProduced: "24.46",
    membersServed: "2,289",
    interestEarned: "2.08",
    name: "EAGLE LOUISIANA",
    website: "http://www.eaglefederal.org",
  },
  "24668": {
    loansProduced: "12.11",
    membersServed: "1,505",
    interestEarned: "1.03",
    name: "CENTURYFIRST",
    website: "http://CenturyFirstFCU.com",
  },
  "24670": {
    loansProduced: "5.63",
    membersServed: "679",
    interestEarned: "0.48",
    name: "1199 SEIU",
    website: "https://www.1199federalcu.org",
  },
  "24673": {
    loansProduced: "13.86",
    membersServed: "1,218",
    interestEarned: "1.18",
    name: "FIRST",
    website: "http://www.firstfedcu.com",
  },
  "24674": {
    loansProduced: "3.49",
    membersServed: "373",
    interestEarned: "0.30",
    name: "TRADES & LABOR",
    website: "http://www.tradesandlabor.com",
  },
  "24676": {
    loansProduced: "3.74",
    membersServed: "284",
    interestEarned: "0.32",
    name: "CARPENTERS",
    website: "http://mycarpenterscu.org",
  },
  "24679": {
    loansProduced: "1.20",
    membersServed: "113",
    interestEarned: "0.10",
    name: "CITY AND COUNTY EMPLOYEES",
    website: "http://www.alcitycountycu.org",
  },
  "24682": {
    loansProduced: "1.01",
    membersServed: "114",
    interestEarned: "0.09",
    name: "PIONEER COMMUNITY",
    website: "http://pioneercreditunion.net",
  },
  "24683": {
    loansProduced: "0.54",
    membersServed: "66",
    interestEarned: "0.05",
    name: "UNITED NEIGHBORHOOD",
    website: "http://www.unfcu1.com",
  },
  "24688": {
    loansProduced: "231.03",
    membersServed: "17,712",
    interestEarned: "19.64",
    name: "CYPRUS",
    website: "http://www.cypruscu.com",
  },
  "24690": {
    loansProduced: "1.06",
    membersServed: "79",
    interestEarned: "0.09",
    name: "FASNY",
    website: "http://WWW.FASNYCU.COM",
  },
  "24692": {
    loansProduced: "2,204.13",
    membersServed: "244,373",
    interestEarned: "187.35",
    name: "MOUNTAIN AMERICA",
    website: "http://www.macu.com",
  },
  "24693": {
    loansProduced: "262.31",
    membersServed: "34,702",
    interestEarned: "22.30",
    name: "GOLDENWEST",
    website: "http://www.gwcu.org",
  },
  "24694": {
    loansProduced: "2,460.51",
    membersServed: "228,489",
    interestEarned: "209.14",
    name: "AMERICA FIRST",
    website: "http://www.americafirst.com",
  },
  "24695": {
    loansProduced: "0.46",
    membersServed: "56",
    interestEarned: "0.04",
    name: "NEBRASKA RURAL COMMUNITY",
    website: "http://www.nrcfcu.com",
  },
  "24698": {
    loansProduced: "30.93",
    membersServed: "3,491",
    interestEarned: "2.63",
    name: "JORDAN",
    website: "http://www.jordan-cu.org",
  },
  "24703": {
    loansProduced: "47.96",
    membersServed: "5,534",
    interestEarned: "4.08",
    name: "TRAX",
    website: "https://www.traxcu.com",
  },
  "24704": {
    loansProduced: "0.24",
    membersServed: "39",
    interestEarned: "0.02",
    name: "SOUTH SIDE COMMUNITY",
    website: "http://www.southsidecommunityfcu.or",
  },
  "24705": {
    loansProduced: "10.70",
    membersServed: "2,412",
    interestEarned: "0.91",
    name: "SOUND",
    website: "http://www.mysoundcu.org",
  },
  "24708": {
    loansProduced: "70.06",
    membersServed: "13,868",
    interestEarned: "5.96",
    name: "DESERET FIRST",
    website: "http://www.dfcu.com",
  },
  "24709": {
    loansProduced: "15.42",
    membersServed: "2,262",
    interestEarned: "1.31",
    name: "EASTERN UTAH COMMUNITY",
    website: "http://www.euccu.com",
  },
  "24710": {
    loansProduced: "211.95",
    membersServed: "15,632",
    interestEarned: "18.02",
    name: "UNIVERSITY FIRST",
    website: "http://www.ufirstcu.com",
  },
  "24711": {
    loansProduced: "0.22",
    membersServed: "17",
    interestEarned: "0.02",
    name: "HOMEWOOD",
    website: null,
  },
  "24712": {
    loansProduced: "3.86",
    membersServed: "443",
    interestEarned: "0.33",
    name: "POST OFFICE EMPLOYEES",
    website: "http://www.poefcu.org",
  },
  "24713": {
    loansProduced: "3.78",
    membersServed: "582",
    interestEarned: "0.32",
    name: "PEOPLE'S COMMUNITY",
    website: "http://www.peoplescu.org",
  },
  "24715": {
    loansProduced: "5.03",
    membersServed: "560",
    interestEarned: "0.43",
    name: "RADIUS",
    website: "http://www.radiusfcu.com",
  },
  "24718": {
    loansProduced: "19.51",
    membersServed: "1,887",
    interestEarned: "1.66",
    name: "JETSTREAM",
    website: "http://www.jetstreamfcu.org",
  },
  "24720": {
    loansProduced: "4.16",
    membersServed: "462",
    interestEarned: "0.35",
    name: "DN COMMUNITY",
    website: "http://www.dncfcu.com",
  },
  "24724": {
    loansProduced: "2.97",
    membersServed: "305",
    interestEarned: "0.25",
    name: "SPIRIT OF AMERICA",
    website: "http://www.spiritofamericafcu.org",
  },
  "24725": {
    loansProduced: "10.64",
    membersServed: "1,491",
    interestEarned: "0.90",
    name: "OHIO HEALTHCARE",
    website: "http://www.ohiohealthcarefcu.com",
  },
  "24726": {
    loansProduced: "47.38",
    membersServed: "3,910",
    interestEarned: "4.03",
    name: "GRANITE",
    website: "http://www.granite.org",
  },
  "24730": {
    loansProduced: "0.42",
    membersServed: "47",
    interestEarned: "0.04",
    name: "COMMUNITY & TEACHERS",
    website: "http://WWW.TEACHERSCU.ORG",
  },
  "24731": {
    loansProduced: "14.72",
    membersServed: "1,318",
    interestEarned: "1.25",
    name: "WESTERN DIVISION",
    website: "http://WWW.WESTERNDIVISION.ORG",
  },
  "24733": {
    loansProduced: "0.03",
    membersServed: "5",
    interestEarned: "0.00",
    name: "SERVICE STATION DEALERS",
    website: null,
  },
  "24742": {
    loansProduced: "53.77",
    membersServed: "8,865",
    interestEarned: "4.57",
    name: "HERITAGE FAMILY",
    website: "http://www.hfcuvt.com",
  },
  "24743": {
    loansProduced: "0.97",
    membersServed: "106",
    interestEarned: "0.08",
    name: "CHARLESTON POSTAL",
    website: "http://www.charlestonpostalfcu.com",
  },
  "24745": {
    loansProduced: "0.09",
    membersServed: "10",
    interestEarned: "0.01",
    name: "UNITED METHODIST OF MISSISSIPPI",
    website: null,
  },
  "24746": {
    loansProduced: "12.43",
    membersServed: "2,846",
    interestEarned: "1.06",
    name: "HORIZON UTAH",
    website: "http://www.myHorizonCU.com",
  },
  "24748": {
    loansProduced: "59.41",
    membersServed: "6,479",
    interestEarned: "5.05",
    name: "MAYO EMPLOYEES",
    website: "https://mayocreditunion.org",
  },
  "24751": {
    loansProduced: "3.57",
    membersServed: "393",
    interestEarned: "0.30",
    name: "COMMUNITY PLUS",
    website: "http://www.cPLUSfcu.org",
  },
  "24753": {
    loansProduced: "13.44",
    membersServed: "1,128",
    interestEarned: "1.14",
    name: "FOUR POINTS",
    website: "http://www.fourpointsfcu.org",
  },
  "24759": {
    loansProduced: "0.38",
    membersServed: "28",
    interestEarned: "0.03",
    name: "MEADOW GROVE",
    website: "http://meadowgrovefcu.org",
  },
  "24763": {
    loansProduced: "2.37",
    membersServed: "188",
    interestEarned: "0.20",
    name: "LOGAN CACHE RICH",
    website: "http://www.lcrcu.org",
  },
  "24767": {
    loansProduced: "0.00",
    membersServed: "5",
    interestEarned: "0.00",
    name: "UNIFIED HOMEOWNERS OF ILLINOIS",
    website: "http://www.uhifcu.virtualcu.net",
  },
  "24769": {
    loansProduced: "0.10",
    membersServed: "10",
    interestEarned: "0.01",
    name: "EMPOWERMENT COMMUNITY DEVELOPMENT",
    website: "http://www.ecdfcu.com",
  },
  "24770": {
    loansProduced: "1.47",
    membersServed: "299",
    interestEarned: "0.13",
    name: "COMMUNITY LINK",
    website: "http://www.communitylinkfcu.com",
  },
  "24771": {
    loansProduced: "6.02",
    membersServed: "766",
    interestEarned: "0.51",
    name: "FAMILY FOCUS",
    website: "www.familyfocusfcu.org",
  },
  "24773": {
    loansProduced: "1.91",
    membersServed: "998",
    interestEarned: "0.16",
    name: "VAREX",
    website: "http://www.varexfederalcu.net",
  },
  "24774": {
    loansProduced: "68.03",
    membersServed: "5,235",
    interestEarned: "5.78",
    name: "ALLTRU",
    website: "http://www.alltrucu.org",
  },
  "24776": {
    loansProduced: "1.83",
    membersServed: "203",
    interestEarned: "0.16",
    name: "COMUNIDAD LATINA",
    website: "http://WWW.CLFCU.ORG",
  },
  "24780": {
    loansProduced: "32.19",
    membersServed: "5,108",
    interestEarned: "2.74",
    name: "WASATCH PEAKS",
    website: "http://www.wasatchpeaks.com",
  },
  "24781": {
    loansProduced: "0.10",
    membersServed: "13",
    interestEarned: "0.01",
    name: "URBAN BEGINNINGS CHOICE",
    website: "http://www.ubc-fcu.org",
  },
  "24783": {
    loansProduced: "8.46",
    membersServed: "1,012",
    interestEarned: "0.72",
    name: "FIRST PIONEERS",
    website: "http://www.firstpioneers.com",
  },
  "24784": {
    loansProduced: "0.59",
    membersServed: "110",
    interestEarned: "0.05",
    name: "NEW COVENANT DOMINION",
    website: "http://www.newcovenantcreditunion.o",
  },
  "24786": {
    loansProduced: "2.10",
    membersServed: "336",
    interestEarned: "0.18",
    name: "WELLNESS",
    website: "https://www.wellnessfcu.org",
  },
  "24793": {
    loansProduced: "3.94",
    membersServed: "737",
    interestEarned: "0.33",
    name: "SETTLERS",
    website: "http://www.settlersfcu.com",
  },
  "24796": {
    loansProduced: "8.44",
    membersServed: "959",
    interestEarned: "0.72",
    name: "GARDEN ISLAND",
    website: "http://www.gardenislandfcu.com",
  },
  "24797": {
    loansProduced: "9.31",
    membersServed: "1,015",
    interestEarned: "0.79",
    name: "GATEWAY METRO",
    website: "https://www.gogmfcu.org",
  },
  "24801": {
    loansProduced: "11.65",
    membersServed: "1,088",
    interestEarned: "0.99",
    name: "CAROLINA COOPERATIVE",
    website: "http://www.carolinacu.com",
  },
  "24802": {
    loansProduced: "28.56",
    membersServed: "5,057",
    interestEarned: "2.43",
    name: "SELF-HELP",
    website: "http://www.self-helpfcu.org",
  },
  "24804": {
    loansProduced: "1.45",
    membersServed: "94",
    interestEarned: "0.12",
    name: "OAK CLIFF CHRISTIAN",
    website: "https://www.occfcu.org",
  },
  "24807": {
    loansProduced: "8.45",
    membersServed: "923",
    interestEarned: "0.72",
    name: "WELCOME",
    website: "http://www.welcomecu.org",
  },
  "24808": {
    loansProduced: "123.86",
    membersServed: "18,383",
    interestEarned: "10.53",
    name: "SKYLA",
    website: "http://www.skylacu.com",
  },
  "24810": {
    loansProduced: "0.53",
    membersServed: "62",
    interestEarned: "0.04",
    name: "1ST BERGEN",
    website: "http://www.1stbergen.com",
  },
  "24811": {
    loansProduced: "2.64",
    membersServed: "452",
    interestEarned: "0.22",
    name: "SHUFORD",
    website: "http://www.shufordcu.org",
  },
  "24812": {
    loansProduced: "58.54",
    membersServed: "7,007",
    interestEarned: "4.98",
    name: "WESTREET",
    website: "http://www.westreet.org",
  },
  "24813": {
    loansProduced: "14.03",
    membersServed: "2,327",
    interestEarned: "1.19",
    name: "LION'S SHARE",
    website: "http://www.lionsharecu.org",
  },
  "24814": {
    loansProduced: "3.30",
    membersServed: "697",
    interestEarned: "0.28",
    name: "WINSTON-SALEM",
    website: "http://www.mywsfcu.org",
  },
  "24816": {
    loansProduced: "1.75",
    membersServed: "153",
    interestEarned: "0.15",
    name: "PRECISION",
    website: "http://www.tspcu.org",
  },
  "24818": {
    loansProduced: "23.13",
    membersServed: "1,161",
    interestEarned: "1.97",
    name: "PIONEER MUTUAL",
    website: "http://www.pioneeronline.org",
  },
  "24819": {
    loansProduced: "8.69",
    membersServed: "761",
    interestEarned: "0.74",
    name: "ELECTEL COOPERATIVE",
    website: "http://www.electel.org",
  },
  "24823": {
    loansProduced: "0.02",
    membersServed: "13",
    interestEarned: "0.00",
    name: "URBAN UPBOUND",
    website: "http://www.uufcu.org",
  },
  "24824": {
    loansProduced: "139.46",
    membersServed: "20,795",
    interestEarned: "11.85",
    name: "S. C. STATE",
    website: "https://www.scscu.com",
  },
  "24826": {
    loansProduced: "24.35",
    membersServed: "2,805",
    interestEarned: "2.07",
    name: "PYRAMID",
    website: "http://www.pyramidfcu.com",
  },
  "24829": {
    loansProduced: "7.25",
    membersServed: "1,712",
    interestEarned: "0.62",
    name: "HOPE",
    website: "https://hopecu.org",
  },
  "24830": {
    loansProduced: "2.33",
    membersServed: "295",
    interestEarned: "0.20",
    name: "OAHU",
    website: "https://OAHUFCU.ORG",
  },
  "24831": {
    loansProduced: "87.10",
    membersServed: "5,922",
    interestEarned: "7.40",
    name: "ORION",
    website: "http://www.orionfcu.com",
  },
  "24839": {
    loansProduced: "12.77",
    membersServed: "1,679",
    interestEarned: "1.09",
    name: "HAWAII CENTRAL",
    website: "http://www.hawaiicentral.org",
  },
  "24840": {
    loansProduced: "7.54",
    membersServed: "1,467",
    interestEarned: "0.64",
    name: "GREENSBORO MUNICIPAL",
    website: "http://www.greensboromcu.org",
  },
  "24841": {
    loansProduced: "1.13",
    membersServed: "156",
    interestEarned: "0.10",
    name: "CAPE",
    website: "http://www.capecu.com",
  },
  "24843": {
    loansProduced: "1.72",
    membersServed: "146",
    interestEarned: "0.15",
    name: "ARBUCKLE",
    website: "http://www.arbucklefcu.com",
  },
  "24845": {
    loansProduced: "0.12",
    membersServed: "30",
    interestEarned: "0.01",
    name: "STEPPING STONES COMMUNITY",
    website: "http://www.sscreditunion.org",
  },
  "24847": {
    loansProduced: "1.16",
    membersServed: "206",
    interestEarned: "0.10",
    name: "LAKOTA",
    website: "http://www.lakotafcu.org",
  },
  "24848": {
    loansProduced: "0.21",
    membersServed: "31",
    interestEarned: "0.02",
    name: "COMMUNITY PROMISE",
    website: "http://www.communitypromisefcu.org",
  },
  "24849": {
    loansProduced: "20.51",
    membersServed: "2,275",
    interestEarned: "1.74",
    name: "THRIVENT",
    website: "http://www.thriventcu.com",
  },
  "24850": {
    loansProduced: "0.11",
    membersServed: "13",
    interestEarned: "0.01",
    name: "CRAYOLA LLC EMPLOYEES CREDIT UNION",
    website: "http://www.crayolacu.com",
  },
  "24851": {
    loansProduced: "5.13",
    membersServed: "778",
    interestEarned: "0.44",
    name: "NEIGHBORS UNITED",
    website: "http://www.mynucu.org",
  },
  "24853": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "NEW LIFE",
    website: null,
  },
  "24854": {
    loansProduced: "215.82",
    membersServed: "23,167",
    interestEarned: "18.34",
    name: "CREDIT UNION OF COLORADO, A",
    website: "http://www.cuofco.org",
  },
  "24855": {
    loansProduced: "1.62",
    membersServed: "599",
    interestEarned: "0.14",
    name: "MEMBERS FIRST OF MARYLAND",
    website: "http://www.mfirstcu.org",
  },
  "24857": {
    loansProduced: "0.26",
    membersServed: "32",
    interestEarned: "0.02",
    name: "BRADFORD AREA SCHOOL EMPLOYEES",
    website: "http://www.basecu.org",
  },
  "24858": {
    loansProduced: "58.94",
    membersServed: "5,201",
    interestEarned: "5.01",
    name: "MAINSTREET",
    website: "https://www.mainstreetcu.org",
  },
  "24859": {
    loansProduced: "2.67",
    membersServed: "521",
    interestEarned: "0.23",
    name: "FIRST UNITY",
    website: null,
  },
  "24860": {
    loansProduced: "54.09",
    membersServed: "3,089",
    interestEarned: "4.60",
    name: "RIVERLAND",
    website: "http://www.riverlandfcu.org",
  },
  "24861": {
    loansProduced: "3.95",
    membersServed: "386",
    interestEarned: "0.34",
    name: "LUTHERAN",
    website: "http://www.lutheranfcu.org",
  },
  "24862": {
    loansProduced: "7.00",
    membersServed: "1,098",
    interestEarned: "0.60",
    name: "THE FINEST",
    website: "http://thefinestfcu.org",
  },
  "24863": {
    loansProduced: "1.22",
    membersServed: "136",
    interestEarned: "0.10",
    name: "SENECA NATION OF INDIANS",
    website: "http://snifcu.org",
  },
  "24866": {
    loansProduced: "2.01",
    membersServed: "372",
    interestEarned: "0.17",
    name: "ELCA",
    website: "http://www.elcafcu.org",
  },
  "24867": {
    loansProduced: "0.66",
    membersServed: "58",
    interestEarned: "0.06",
    name: "REDEEMER",
    website: "http://www.redeemerfcu.org",
  },
  "24868": {
    loansProduced: "3.38",
    membersServed: "192",
    interestEarned: "0.29",
    name: "WORCESTER POLICE DEPARTMENT",
    website: "http://www.wpdfcu.com",
  },
  "24871": {
    loansProduced: "71.25",
    membersServed: "8,652",
    interestEarned: "6.06",
    name: "JEFFERSON FINANCIAL",
    website: "https://my.jeffersonfinancial.org/",
  },
  "24872": {
    loansProduced: "2.47",
    membersServed: "180",
    interestEarned: "0.21",
    name: "DALE COMMUNITY",
    website: "http://dalecu.com",
  },
  "24873": {
    loansProduced: "1.40",
    membersServed: "120",
    interestEarned: "0.12",
    name: "ENCOMPASS NIAGARA",
    website: "http://www.encompassniagara.com/",
  },
  "24874": {
    loansProduced: "2.75",
    membersServed: "460",
    interestEarned: "0.23",
    name: "GREAT LAKES",
    website: "http://greatlakesfcu.com",
  },
  "24875": {
    loansProduced: "66.97",
    membersServed: "8,549",
    interestEarned: "5.69",
    name: "THE FIRST FINANCIAL",
    website: "http://www.ffcu.org",
  },
  "24876": {
    loansProduced: "1.27",
    membersServed: "122",
    interestEarned: "0.11",
    name: "VICKSWOOD",
    website: "https://vickswoodcu.com",
  },
  "24877": {
    loansProduced: "125.32",
    membersServed: "12,749",
    interestEarned: "10.65",
    name: "FIREFIGHTERS FIRST",
    website: "http://www.firefightersfirstcu.org",
  },
  "24878": {
    loansProduced: "4.51",
    membersServed: "264",
    interestEarned: "0.38",
    name: "NORTHERN LIGHTS",
    website: "http://www.northernlightscu.com",
  },
  "24880": {
    loansProduced: "0.41",
    membersServed: "97",
    interestEarned: "0.03",
    name: "PEARL MUNICIPAL",
    website: "http://pearlcu.com",
  },
  "24881": {
    loansProduced: "1.89",
    membersServed: "220",
    interestEarned: "0.16",
    name: "CM MEMBERS",
    website: "http://cmcreditunion.com",
  },
  "24883": {
    loansProduced: "3.91",
    membersServed: "305",
    interestEarned: "0.33",
    name: "ECM",
    website: "http://www.ecmfcu.com",
  },
  "24885": {
    loansProduced: "2.12",
    membersServed: "303",
    interestEarned: "0.18",
    name: "SOUTHERN COASTAL",
    website: "http://www.socofcu.org",
  },
  "24887": {
    loansProduced: "247.92",
    membersServed: "17,889",
    interestEarned: "21.07",
    name: "TTCU",
    website: "http://www.ttcu.com",
  },
  "24888": {
    loansProduced: "33.60",
    membersServed: "2,490",
    interestEarned: "2.86",
    name: "WEST VIRGINIA CENTRAL",
    website: "http://www.wvccu.org",
  },
  "24889": {
    loansProduced: "1.88",
    membersServed: "90",
    interestEarned: "0.16",
    name: "CLEAN ENERGY",
    website: "http://www.cleanenergycu.org",
  },
  "24890": {
    loansProduced: "1.85",
    membersServed: "295",
    interestEarned: "0.16",
    name: "ELEVATOR",
    website: null,
  },
  "24892": {
    loansProduced: "1.83",
    membersServed: "138",
    interestEarned: "0.16",
    name: "VICKSBURG RAILROAD",
    website: "http://www.vrcums.com",
  },
  "24893": {
    loansProduced: "0.76",
    membersServed: "227",
    interestEarned: "0.06",
    name: "DELTA",
    website: "http://deltacu.virtual.cu.net",
  },
  "24894": {
    loansProduced: "0.15",
    membersServed: "17",
    interestEarned: "0.01",
    name: "MERIDIAN POSTAL",
    website: null,
  },
  "24895": {
    loansProduced: "0.18",
    membersServed: "31",
    interestEarned: "0.02",
    name: "MISSISSIPPI COLLEGE EMPLOYEES",
    website: null,
  },
  "24896": {
    loansProduced: "0.17",
    membersServed: "79",
    interestEarned: "0.01",
    name: "NATCHEZ EDUCATORS",
    website: null,
  },
  "24898": {
    loansProduced: "11.57",
    membersServed: "593",
    interestEarned: "0.98",
    name: "CIVIC",
    website: "https://www.civicfcu.org",
  },
  "24899": {
    loansProduced: "112.22",
    membersServed: "7,330",
    interestEarned: "9.54",
    name: "WEOKIE",
    website: "http://www.weokie.org",
  },
  "24901": {
    loansProduced: "6.25",
    membersServed: "526",
    interestEarned: "0.53",
    name: "FRATERNAL ORDER OF POLICE",
    website: "http://www.fopfcu.org",
  },
  "24902": {
    loansProduced: "19.32",
    membersServed: "1,072",
    interestEarned: "1.64",
    name: "HERCULES FIRST",
    website: "http://www.herculescu.com",
  },
  "24903": {
    loansProduced: "0.09",
    membersServed: "26",
    interestEarned: "0.01",
    name: "CAMDEN FIREMEN'S",
    website: null,
  },
  "24904": {
    loansProduced: "9.82",
    membersServed: "813",
    interestEarned: "0.83",
    name: "RIVERVIEW",
    website: "http://www.riverviewcu.com",
  },
  "24905": {
    loansProduced: "0.56",
    membersServed: "193",
    interestEarned: "0.05",
    name: "COMMUNITY PARTNERS",
    website: "www.communitypartnersfcu.com",
  },
  "24906": {
    loansProduced: "0.36",
    membersServed: "35",
    interestEarned: "0.03",
    name: "EVEREST",
    website: "http://www.everestfcu.org",
  },
  "24908": {
    loansProduced: "2.11",
    membersServed: "484",
    interestEarned: "0.18",
    name: "GULF TRUST",
    website: "http://www.gulftrustcu.org",
  },
  "24909": {
    loansProduced: "25.70",
    membersServed: "2,103",
    interestEarned: "2.18",
    name: "OHIO VALLEY COMMUNITY",
    website: "http://www.ohiovalleycu.org",
  },
  "24910": {
    loansProduced: "0.69",
    membersServed: "104",
    interestEarned: "0.06",
    name: "OTOE-MISSOURIA",
    website: "http://OtoeMissouriafcu.org",
  },
  "24911": {
    loansProduced: "0.46",
    membersServed: "38",
    interestEarned: "0.04",
    name: "NORTHAMPTON AREA SCHOOL DIST. EMPS",
    website: "https://www.nasdecu.com",
  },
  "24914": {
    loansProduced: "252.04",
    membersServed: "18,691",
    interestEarned: "21.42",
    name: "MERITRUST",
    website: "http://www.meritrustcu.org",
  },
  "24916": {
    loansProduced: "50.70",
    membersServed: "4,281",
    interestEarned: "4.31",
    name: "I.L.W.U.",
    website: "http://www.ilwucu.org",
  },
  "24917": {
    loansProduced: "204.73",
    membersServed: "13,079",
    interestEarned: "17.40",
    name: "COBALT",
    website: "http://www.cobaltcu.com",
  },
  "24918": {
    loansProduced: "13.43",
    membersServed: "1,141",
    interestEarned: "1.14",
    name: "DESERT RIVERS",
    website: "http://www.desertriverscu.com",
  },
  "24919": {
    loansProduced: "0.24",
    membersServed: "34",
    interestEarned: "0.02",
    name: "GROWING OAKS",
    website: "http://growingoaksfcu.com",
  },
  "24920": {
    loansProduced: "6.70",
    membersServed: "1,039",
    interestEarned: "0.57",
    name: "BEST REWARD",
    website: "http://www.bestrewardfcu.coop",
  },
  "24921": {
    loansProduced: "699.47",
    membersServed: "58,372",
    interestEarned: "59.46",
    name: "SERVICE",
    website: "https://www.servicecu.org",
  },
  "24923": {
    loansProduced: "87.55",
    membersServed: "7,991",
    interestEarned: "7.44",
    name: "WORKERS",
    website: "http://www.wcu.com",
  },
  "24924": {
    loansProduced: "1.49",
    membersServed: "159",
    interestEarned: "0.13",
    name: "MASSACHUSETTS FAMILY",
    website: "http://www.massfamilycu.com",
  },
  "24925": {
    loansProduced: "0.28",
    membersServed: "14",
    interestEarned: "0.02",
    name: "MAUN",
    website: "www.maunfcu.org",
  },
  "24927": {
    loansProduced: "39.83",
    membersServed: "5,243",
    interestEarned: "3.39",
    name: "CREDIT UNION OF NEW JERSEY, A",
    website: "http://www.cunj.org",
  },
  "24928": {
    loansProduced: "0.16",
    membersServed: "25",
    interestEarned: "0.01",
    name: "COMMUNITY FIRST FUND",
    website: "http://www.communityfirst.org",
  },
  "24929": {
    loansProduced: "14.06",
    membersServed: "3,876",
    interestEarned: "1.19",
    name: "MC",
    website: "http://www.mcfcu.org",
  },
  "24930": {
    loansProduced: "0.75",
    membersServed: "179",
    interestEarned: "0.06",
    name: "CAPITAL",
    website: "www.capitalfcu.org",
  },
  "24931": {
    loansProduced: "51.77",
    membersServed: "6,032",
    interestEarned: "4.40",
    name: "PARK",
    website: "http://www.parkcommunity.com",
  },
  "24934": {
    loansProduced: "110.64",
    membersServed: "9,863",
    interestEarned: "9.40",
    name: "TRUE SKY",
    website: "https://www.TrueSkyCU.org",
  },
  "24935": {
    loansProduced: "0.06",
    membersServed: "3",
    interestEarned: "0.00",
    name: "UNITY OF EATONVILLE",
    website: "https://unityofeatonvillefcu.com",
  },
  "24936": {
    loansProduced: "42.44",
    membersServed: "2,151",
    interestEarned: "3.61",
    name: "ALLEGIANCE",
    website: "http://www.allegiancecu.org",
  },
  "24937": {
    loansProduced: "0.15",
    membersServed: "16",
    interestEarned: "0.01",
    name: "WEDEVELOPMENT",
    website: "http://wedevelopmentfcu.com",
  },
  "24939": {
    loansProduced: "1.81",
    membersServed: "148",
    interestEarned: "0.15",
    name: "THE MUNICIPAL",
    website: "http://www.municipalcu.com",
  },
  "24940": {
    loansProduced: "0.05",
    membersServed: "11",
    interestEarned: "0.00",
    name: "PEOPLE TRUST COMMUNITY",
    website: "www.ptcfederal.org",
  },
  "24941": {
    loansProduced: "735.54",
    membersServed: "59,777",
    interestEarned: "62.52",
    name: "GECU",
    website: "http://www.GECU.com",
  },
  "24942": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "NEW YORK EPISCOPAL",
    website: "https://nyefcu.org/",
  },
  "24943": {
    loansProduced: "0.07",
    membersServed: "50",
    interestEarned: "0.01",
    name: "THE MORNING STAR",
    website: "https://www.tmsfcu.com/",
  },
  "24944": {
    loansProduced: "1.77",
    membersServed: "264",
    interestEarned: "0.15",
    name: "CCE",
    website: "ccefcu.net",
  },
  "24945": {
    loansProduced: "83.55",
    membersServed: "8,305",
    interestEarned: "7.10",
    name: "ENVISTA",
    website: "www.envistacu.com",
  },
  "24948": {
    loansProduced: "28.57",
    membersServed: "3,708",
    interestEarned: "2.43",
    name: "MUTUAL",
    website: "http://www.mutualcu.org",
  },
  "60002": {
    loansProduced: "54.36",
    membersServed: "9,224",
    interestEarned: "4.62",
    name: "MEMBERS",
    website: "http://www.memcu.com",
  },
  "60013": {
    loansProduced: "3.00",
    membersServed: "293",
    interestEarned: "0.25",
    name: "METCO",
    website: "http://www.metcocu.org",
  },
  "60024": {
    loansProduced: "14.14",
    membersServed: "1,811",
    interestEarned: "1.20",
    name: "PRIORITY ONE",
    website: "https://www.priorityonecu.org",
  },
  "60037": {
    loansProduced: "6.84",
    membersServed: "29,127",
    interestEarned: "0.58",
    name: "MIDWEST REGIONAL",
    website: "http://www.mrcu.com",
  },
  "60039": {
    loansProduced: "0.75",
    membersServed: "88",
    interestEarned: "0.06",
    name: "PEOPLES CHOICE",
    website: "http://mypccu.com",
  },
  "60041": {
    loansProduced: "22.44",
    membersServed: "30,591",
    interestEarned: "1.91",
    name: "UNITED",
    website: "http://www.unitedcreditunion.com",
  },
  "60042": {
    loansProduced: "541.90",
    membersServed: "56,675",
    interestEarned: "46.06",
    name: "WASHINGTON STATE EMPLOYEES",
    website: "wsecu.org",
  },
  "60048": {
    loansProduced: "1.17",
    membersServed: "176",
    interestEarned: "0.10",
    name: "STATE POLICE CREDIT UNION INC.",
    website: "http://www.cspcu.org",
  },
  "60051": {
    loansProduced: "17.53",
    membersServed: "1,906",
    interestEarned: "1.49",
    name: "TARRANT COUNTY'S CREDIT UNION",
    website: "http://www.tccu-tx.com",
  },
  "60056": {
    loansProduced: "27.67",
    membersServed: "1,805",
    interestEarned: "2.35",
    name: "CONSUMER",
    website: "http://consumercreditunion.com",
  },
  "60057": {
    loansProduced: "56.66",
    membersServed: "8,587",
    interestEarned: "4.82",
    name: "WILDFIRE",
    website: "http://www.wildfirecu.org",
  },
  "60059": {
    loansProduced: "48.66",
    membersServed: "4,337",
    interestEarned: "4.14",
    name: "O BEE",
    website: "https://www.obee.com",
  },
  "60060": {
    loansProduced: "521.76",
    membersServed: "33,323",
    interestEarned: "44.35",
    name: "EVERWISE",
    website: "http://WWW.everwisecu.com",
  },
  "60062": {
    loansProduced: "219.08",
    membersServed: "21,633",
    interestEarned: "18.62",
    name: "CREDIT UNION OF AMERICA",
    website: "http://www.cuofamerica.com",
  },
  "60077": {
    loansProduced: "0.83",
    membersServed: "63",
    interestEarned: "0.07",
    name: "ECCO",
    website: null,
  },
  "60079": {
    loansProduced: "2.43",
    membersServed: "910",
    interestEarned: "0.21",
    name: "COMMUNITY 1ST",
    website: "https://WWW.MYC1CU.COM",
  },
  "60082": {
    loansProduced: "5.95",
    membersServed: "355",
    interestEarned: "0.51",
    name: "PATRIOT EQUITY",
    website: "http://www.pecujax.org",
  },
  "60087": {
    loansProduced: "395.87",
    membersServed: "66,530",
    interestEarned: "33.65",
    name: "ACHIEVA CREDIT UNION",
    website: "http://www.achievacu.com",
  },
  "60095": {
    loansProduced: "13.73",
    membersServed: "26,566",
    interestEarned: "1.17",
    name: "UNITED COMMUNITY",
    website: "http://www.myuccu.com",
  },
  "60103": {
    loansProduced: "42.37",
    membersServed: "5,273",
    interestEarned: "3.60",
    name: "FIRST COMMUNITY",
    website: "https://www.myfccu.com",
  },
  "60105": {
    loansProduced: "49.11",
    membersServed: "7,750",
    interestEarned: "4.17",
    name: "OMNI COMMUNITY",
    website: "http://www.omnicommunitycu.org",
  },
  "60110": {
    loansProduced: "2.46",
    membersServed: "257",
    interestEarned: "0.21",
    name: "SAN JUAN MOUNTAINS",
    website: "http://www.sjmcu.com",
  },
  "60113": {
    loansProduced: "2.27",
    membersServed: "844",
    interestEarned: "0.19",
    name: "LANCASTER RED ROSE",
    website: "http://www.lrrcu.org",
  },
  "60124": {
    loansProduced: "1.68",
    membersServed: "129",
    interestEarned: "0.14",
    name: "EASTPOINTE COMMUNITY",
    website: "http://www.eastpointeccu.com",
  },
  "60125": {
    loansProduced: "13.66",
    membersServed: "1,997",
    interestEarned: "1.16",
    name: "MY",
    website: "https://www.mymncu.org",
  },
  "60137": {
    loansProduced: "140.58",
    membersServed: "11,989",
    interestEarned: "11.95",
    name: "DOW",
    website: "http://www.dowcreditunion.org",
  },
  "60148": {
    loansProduced: "18.83",
    membersServed: "2,033",
    interestEarned: "1.60",
    name: "ANOKA HENNEPIN",
    website: "http://www.ahcu.coop",
  },
  "60149": {
    loansProduced: "1.30",
    membersServed: "180",
    interestEarned: "0.11",
    name: "MCDOWELL CORNERSTONE",
    website: "http://WWW.MCDOWELLCORNERSTONE.COM",
  },
  "60151": {
    loansProduced: "9.64",
    membersServed: "773",
    interestEarned: "0.82",
    name: "COCHISE",
    website: "http://www.cochisecu.com",
  },
  "60153": {
    loansProduced: "354.73",
    membersServed: "71,266",
    interestEarned: "30.15",
    name: "MUNICIPAL",
    website: "http://www.NYMCU.ORG",
  },
  "60160": {
    loansProduced: "39.94",
    membersServed: "4,735",
    interestEarned: "3.39",
    name: "PIEDMONT ADVANTAGE CREDIT UNION",
    website: "http://www.pacu.com",
  },
  "60164": {
    loansProduced: "5.34",
    membersServed: "611",
    interestEarned: "0.45",
    name: "HIBBING COOPERATIVE",
    website: "http://www.hccu.net",
  },
  "60165": {
    loansProduced: "5.06",
    membersServed: "578",
    interestEarned: "0.43",
    name: "ALHAMBRA",
    website: "http://www.alhambracu.com",
  },
  "60167": {
    loansProduced: "4.33",
    membersServed: "313",
    interestEarned: "0.37",
    name: "HIGHWAY CROSSROADS",
    website: "http://www.highwaycrossroadscu.com",
  },
  "60175": {
    loansProduced: "3.29",
    membersServed: "347",
    interestEarned: "0.28",
    name: "CHICAGO FIREMANS ASSOC",
    website: "http://chicagofiremansacu.org",
  },
  "60184": {
    loansProduced: "1.61",
    membersServed: "269",
    interestEarned: "0.14",
    name: "ANCO COMMUNITY",
    website: "http://americannickeloidemployeescr",
  },
  "60186": {
    loansProduced: "5.92",
    membersServed: "622",
    interestEarned: "0.50",
    name: "MOLINE MUNICIPAL",
    website: "http://www.molinecu.org",
  },
  "60189": {
    loansProduced: "14.32",
    membersServed: "53,980",
    interestEarned: "1.22",
    name: "CORNERSTONE",
    website: "http://www.cornerstonecu.org",
  },
  "60190": {
    loansProduced: "1.09",
    membersServed: "93",
    interestEarned: "0.09",
    name: "PEORIA FIRE FIGHTERS",
    website: null,
  },
  "60198": {
    loansProduced: "2.29",
    membersServed: "213",
    interestEarned: "0.19",
    name: "KANKAKEE FEDERATION OF TEACHERS",
    website: "http://www.kftcu.com",
  },
  "60201": {
    loansProduced: "3.39",
    membersServed: "313",
    interestEarned: "0.29",
    name: "HARVARD COMMUNITY",
    website: "http://WWW.HARVARDCCU.ORG",
  },
  "60205": {
    loansProduced: "29.53",
    membersServed: "3,707",
    interestEarned: "2.51",
    name: "GULF",
    website: "http://www.gecu.org",
  },
  "60207": {
    loansProduced: "6.74",
    membersServed: "1,278",
    interestEarned: "0.57",
    name: "HARBOR POINTE",
    website: "http://www.hpcu.us",
  },
  "60212": {
    loansProduced: "2.04",
    membersServed: "136",
    interestEarned: "0.17",
    name: "MATERNITY B.V.M.",
    website: "http://www.mbvmcu.com",
  },
  "60215": {
    loansProduced: "4.19",
    membersServed: "1,178",
    interestEarned: "0.36",
    name: "SUPERIOR CREDIT UNION",
    website: "http://www.superiorcu.org",
  },
  "60216": {
    loansProduced: "68.30",
    membersServed: "5,465",
    interestEarned: "5.81",
    name: "MEMBERS COOPERATIVE",
    website: "http://www.membersccu.org",
  },
  "60238": {
    loansProduced: "39.88",
    membersServed: "3,608",
    interestEarned: "3.39",
    name: "GREAT LAKES",
    website: "http://www.glcu.org",
  },
  "60239": {
    loansProduced: "37.35",
    membersServed: "6,612",
    interestEarned: "3.17",
    name: "MEMPHIS CITY EMPLOYEES",
    website: "http://www.memphiscu.org",
  },
  "60242": {
    loansProduced: "2.58",
    membersServed: "290",
    interestEarned: "0.22",
    name: "SHARE ADVANTAGE",
    website: "http://www.myshareadvantage.com",
  },
  "60247": {
    loansProduced: "2.86",
    membersServed: "375",
    interestEarned: "0.24",
    name: "COMMUNITY HEALTHCARE",
    website: "http://www.chcu.org",
  },
  "60251": {
    loansProduced: "8.06",
    membersServed: "747",
    interestEarned: "0.69",
    name: "NORTHPARK COMMUNITY",
    website: "http://northparkccu.org",
  },
  "60255": {
    loansProduced: "71.54",
    membersServed: "23,161",
    interestEarned: "6.08",
    name: "CITY & COUNTY",
    website: "http://www.cccu.com",
  },
  "60261": {
    loansProduced: "0.33",
    membersServed: "44",
    interestEarned: "0.03",
    name: "GRAPHIC ARTS",
    website: null,
  },
  "60263": {
    loansProduced: "6.99",
    membersServed: "604",
    interestEarned: "0.59",
    name: "UNITED EMPLOYEES",
    website: "http://www.unitedecu.com",
  },
  "60269": {
    loansProduced: "868.36",
    membersServed: "197,670",
    interestEarned: "73.81",
    name: "GREENSTATE",
    website: "http://greenstate.org",
  },
  "60275": {
    loansProduced: "112.09",
    membersServed: "13,440",
    interestEarned: "9.53",
    name: "FREEDOM",
    website: "http://www.freedomcu.org",
  },
  "60279": {
    loansProduced: "73.78",
    membersServed: "45,581",
    interestEarned: "6.27",
    name: "SIU",
    website: "http://www.siucu.org",
  },
  "60293": {
    loansProduced: "1.31",
    membersServed: "76",
    interestEarned: "0.11",
    name: "DECATUR POLICEMEN",
    website: null,
  },
  "60295": {
    loansProduced: "5.29",
    membersServed: "502",
    interestEarned: "0.45",
    name: "KANSAS BLUE CROSS-BLUE SHIELD",
    website: "http://www.ksbcbscu.org",
  },
  "60299": {
    loansProduced: "0.89",
    membersServed: "71",
    interestEarned: "0.08",
    name: "PONTIAC DWIGHT PRISON EMPLOYS",
    website: null,
  },
  "60302": {
    loansProduced: "2.18",
    membersServed: "253",
    interestEarned: "0.19",
    name: "BLAW-KNOX",
    website: "http://www.bkcu.net",
  },
  "60304": {
    loansProduced: "4.12",
    membersServed: "14,457",
    interestEarned: "0.35",
    name: "ELITE COMMUNITY",
    website: "http://www.eliteccu.com",
  },
  "60307": {
    loansProduced: "1.78",
    membersServed: "199",
    interestEarned: "0.15",
    name: "GRAND PRAIRIE",
    website: "http://WWW.GPCREDITUNION.ORG",
  },
  "60325": {
    loansProduced: "5.63",
    membersServed: "26,953",
    interestEarned: "0.48",
    name: "FIELDSTONE",
    website: "http://www.fieldstonecu.com",
  },
  "60327": {
    loansProduced: "6.23",
    membersServed: "1,050",
    interestEarned: "0.53",
    name: "GALE",
    website: "http://www.galecu.net",
  },
  "60335": {
    loansProduced: "6.62",
    membersServed: "703",
    interestEarned: "0.56",
    name: "TAYLORVILLE COMMUNITY",
    website: "http://www.tccu.org",
  },
  "60337": {
    loansProduced: "317.73",
    membersServed: "22,559",
    interestEarned: "27.01",
    name: "I. H. MISSISSIPPI VALLEY",
    website: "http://www.ihmvcu.org",
  },
  "60345": {
    loansProduced: "18.45",
    membersServed: "1,650",
    interestEarned: "1.57",
    name: "JOHNSONVILLE TVA EMPLOYEES",
    website: "http://www.jtvaecu.org",
  },
  "60346": {
    loansProduced: "7.65",
    membersServed: "1,692",
    interestEarned: "0.65",
    name: "REDBRAND",
    website: "http://WWW.REDBRANDCU.COM",
  },
  "60363": {
    loansProduced: "24.94",
    membersServed: "2,336",
    interestEarned: "2.12",
    name: "CENTRAL STATE",
    website: "http://www.centralstatecu.org",
  },
  "60369": {
    loansProduced: "3.12",
    membersServed: "3,744",
    interestEarned: "0.27",
    name: "SPIRIT FINANCIAL",
    website: "http://www.spiritfinancialcu.org",
  },
  "60377": {
    loansProduced: "7.56",
    membersServed: "32,846",
    interestEarned: "0.64",
    name: "MINNESOTA POWER EMPLOYEES",
    website: "http://www.mpecu.com",
  },
  "60378": {
    loansProduced: "5.95",
    membersServed: "560",
    interestEarned: "0.51",
    name: "LUBRIZOL EMPLOYEES'",
    website: "http://www.lzecu.org",
  },
  "60383": {
    loansProduced: "16.21",
    membersServed: "1,727",
    interestEarned: "1.38",
    name: "CENTRAL MISSOURI COMMUNITY",
    website: "http://www.cmccreditunion.org",
  },
  "60388": {
    loansProduced: "1.12",
    membersServed: "113",
    interestEarned: "0.10",
    name: "DEER RIVER COOPERATIVE",
    website: "http://DRCCU.COM",
  },
  "60389": {
    loansProduced: "0.90",
    membersServed: "262",
    interestEarned: "0.08",
    name: "AMERICAN PRIDE",
    website: "http://www.americanpridecu.org",
  },
  "60390": {
    loansProduced: "3.56",
    membersServed: "24,761",
    interestEarned: "0.30",
    name: "TEE-PAK",
    website: "http://www.teepakcu.com",
  },
  "60392": {
    loansProduced: "0.25",
    membersServed: "87",
    interestEarned: "0.02",
    name: "READING BERKS SCHOOL EMPLOYEES",
    website: "http://rbsecu.com",
  },
  "60396": {
    loansProduced: "0.95",
    membersServed: "59",
    interestEarned: "0.08",
    name: "SKEL-TEX",
    website: null,
  },
  "60400": {
    loansProduced: "41.22",
    membersServed: "5,996",
    interestEarned: "3.50",
    name: "ST. LOUIS COMMUNITY",
    website: "http://www.stlouiscommunity.com",
  },
  "60406": {
    loansProduced: "31.10",
    membersServed: "3,746",
    interestEarned: "2.64",
    name: "FREESTAR FINANCIAL",
    website: "http://www.freestarfinancial.com",
  },
  "60408": {
    loansProduced: "22.03",
    membersServed: "1,190",
    interestEarned: "1.87",
    name: "UNITED LOCAL",
    website: "http://www.unitedlocal.org",
  },
  "60409": {
    loansProduced: "0.86",
    membersServed: "68",
    interestEarned: "0.07",
    name: "COBURN",
    website: "http://www.coburncu.com",
  },
  "60423": {
    loansProduced: "4.42",
    membersServed: "613",
    interestEarned: "0.38",
    name: "TAHQUAMENON AREA",
    website: "http://WWW.TACUMI.COM",
  },
  "60428": {
    loansProduced: "27.49",
    membersServed: "4,727",
    interestEarned: "2.34",
    name: "EXTRA",
    website: "http://www.extracreditunion.org",
  },
  "60429": {
    loansProduced: "1.92",
    membersServed: "21,437",
    interestEarned: "0.16",
    name: "GENERATIONS",
    website: "http://www.generationscu.org",
  },
  "60435": {
    loansProduced: "11.82",
    membersServed: "35,273",
    interestEarned: "1.00",
    name: "CENTURY",
    website: "http://WWW.CENTURYCU.ORG",
  },
  "60436": {
    loansProduced: "3.37",
    membersServed: "289",
    interestEarned: "0.29",
    name: "BRAINERD B. N.",
    website: "http://www.bbncu.org",
  },
  "60438": {
    loansProduced: "176.00",
    membersServed: "18,019",
    interestEarned: "14.96",
    name: "IQ",
    website: "http://www.iQcu.com",
  },
  "60457": {
    loansProduced: "27.29",
    membersServed: "4,190",
    interestEarned: "2.32",
    name: "DUTCH POINT",
    website: "www.dutchpoint.org",
  },
  "60466": {
    loansProduced: "20.91",
    membersServed: "2,144",
    interestEarned: "1.78",
    name: "ASSOCIATED HEALTHCARE",
    website: "http://www.ahcu.org",
  },
  "60467": {
    loansProduced: "27.22",
    membersServed: "2,076",
    interestEarned: "2.31",
    name: "ZIA",
    website: "http://www.ziacu.org",
  },
  "60470": {
    loansProduced: "11.11",
    membersServed: "1,586",
    interestEarned: "0.94",
    name: "COPOCO COMMUNITY",
    website: "http://www.copoco.org",
  },
  "60475": {
    loansProduced: "2.23",
    membersServed: "254",
    interestEarned: "0.19",
    name: "TANGIPAHOA PARISH TEACHERS",
    website: "http://www.tangiteachers.com",
  },
  "60483": {
    loansProduced: "7.07",
    membersServed: "483",
    interestEarned: "0.60",
    name: "NASHVILLE FIREMEN'S",
    website: "http://nashvillefirecu.org",
  },
  "60484": {
    loansProduced: "309.42",
    membersServed: "57,880",
    interestEarned: "26.30",
    name: "ASSOCIATED CREDIT UNION",
    website: "http://www.acuonline.org",
  },
  "60491": {
    loansProduced: "1.65",
    membersServed: "202",
    interestEarned: "0.14",
    name: "HIGHWAY DISTRICT 19 EMPLOYEES",
    website: null,
  },
  "60494": {
    loansProduced: "6.02",
    membersServed: "789",
    interestEarned: "0.51",
    name: "MILLARD COUNTY",
    website: "http://WWW.MILLARDCCU.COM",
  },
  "60497": {
    loansProduced: "317.65",
    membersServed: "37,675",
    interestEarned: "27.00",
    name: "FLORIDA",
    website: "http://www.flcu.org",
  },
  "60500": {
    loansProduced: "10.63",
    membersServed: "864",
    interestEarned: "0.90",
    name: "NORTH ALABAMA EDUCATORS",
    website: "http://www.naecu.org",
  },
  "60501": {
    loansProduced: "11.96",
    membersServed: "1,568",
    interestEarned: "1.02",
    name: "MUTUAL SAVINGS",
    website: "http://www.mutualsavings.org",
  },
  "60509": {
    loansProduced: "34.50",
    membersServed: "3,330",
    interestEarned: "2.93",
    name: "TLC COMMUNITY",
    website: "http://www.tlccu.org",
  },
  "60520": {
    loansProduced: "410.01",
    membersServed: "54,067",
    interestEarned: "34.85",
    name: "EDUCATIONAL EMPLOYEES",
    website: "http://WWW.MYEECU.ORG",
  },
  "60522": {
    loansProduced: "64.20",
    membersServed: "21,335",
    interestEarned: "5.46",
    name: "MICHIGAN FIRST",
    website: "http://www.michiganfirst.com",
  },
  "60527": {
    loansProduced: "18.21",
    membersServed: "1,676",
    interestEarned: "1.55",
    name: "LOWLAND",
    website: "http://lowlandcu.org",
  },
  "60531": {
    loansProduced: "5.87",
    membersServed: "39,381",
    interestEarned: "0.50",
    name: "SELECT EMPLOYEES",
    website: "http://www.selectcu.com",
  },
  "60532": {
    loansProduced: "12.02",
    membersServed: "35,765",
    interestEarned: "1.02",
    name: "LANDMARK",
    website: "https://www.landmarkcreditunion.com",
  },
  "60533": {
    loansProduced: "28.64",
    membersServed: "1,371",
    interestEarned: "2.43",
    name: "SOUTHWEST HERITAGE",
    website: "http://www.southwestheritagecu.com",
  },
  "60536": {
    loansProduced: "1.63",
    membersServed: "154",
    interestEarned: "0.14",
    name: "KANKAKEE TERMINAL BELT",
    website: "http://WWW.KTBCU.com",
  },
  "60543": {
    loansProduced: "12.31",
    membersServed: "1,742",
    interestEarned: "1.05",
    name: "COMMUNITY TRUST",
    website: "https://www.ctcu.org",
  },
  "60545": {
    loansProduced: "1.19",
    membersServed: "183",
    interestEarned: "0.10",
    name: "FINANCIAL PARTNERS",
    website: "http://www.fpcu.net",
  },
  "60582": {
    loansProduced: "0.94",
    membersServed: "63",
    interestEarned: "0.08",
    name: "RIG EMPLOYEES",
    website: null,
  },
  "60583": {
    loansProduced: "107.72",
    membersServed: "12,977",
    interestEarned: "9.16",
    name: "UNIVERSITY OF ILLINOIS COMMUNITY",
    website: "http://www.uoficreditunion.org",
  },
  "60598": {
    loansProduced: "13.42",
    membersServed: "7,244",
    interestEarned: "1.14",
    name: "MEMBERS EXCHANGE",
    website: "http://www.mecuanywhere.com",
  },
  "60605": {
    loansProduced: "93.13",
    membersServed: "11,007",
    interestEarned: "7.92",
    name: "FAMILY SECURITY",
    website: "http://myfscu.com",
  },
  "60608": {
    loansProduced: "81.43",
    membersServed: "10,924",
    interestEarned: "6.92",
    name: "ASSOCIATED CREDIT UNION OF TEXAS",
    website: "http://www.acutx.org",
  },
  "60619": {
    loansProduced: "3.19",
    membersServed: "347",
    interestEarned: "0.27",
    name: "EPB EMPLOYEES",
    website: "http://www.epbecu.org",
  },
  "60627": {
    loansProduced: "0.15",
    membersServed: "22",
    interestEarned: "0.01",
    name: "CLIFFORD-JACOBS EMPLOYEES",
    website: null,
  },
  "60645": {
    loansProduced: "7.85",
    membersServed: "2,318",
    interestEarned: "0.67",
    name: "NEW SOUTH CREDIT UNION",
    website: "http://www.newsouthcu.com",
  },
  "60646": {
    loansProduced: "3.22",
    membersServed: "214",
    interestEarned: "0.27",
    name: "COAST LINE",
    website: "http://www.coastlinecu.com",
  },
  "60648": {
    loansProduced: "4.00",
    membersServed: "518",
    interestEarned: "0.34",
    name: "A.A.E.C.",
    website: "http://www.aaeccu.com",
  },
  "60657": {
    loansProduced: "0.60",
    membersServed: "69",
    interestEarned: "0.05",
    name: "SYNERGY PARTNERS",
    website: "www.synergypartnerscu.org",
  },
  "60660": {
    loansProduced: "1.49",
    membersServed: "210",
    interestEarned: "0.13",
    name: "ALLVAC SAVINGS AND",
    website: "http://WWW.ALLVACCU.COM",
  },
  "60666": {
    loansProduced: "17.05",
    membersServed: "3,362",
    interestEarned: "1.45",
    name: "SOVITA",
    website: "http://www.sovitacu.org",
  },
  "60680": {
    loansProduced: "0.06",
    membersServed: "3",
    interestEarned: "0.00",
    name: "ST. GREGORY PARISH",
    website: "http://www.stgregorycu.org",
  },
  "60683": {
    loansProduced: "43.60",
    membersServed: "4,000",
    interestEarned: "3.71",
    name: "PUBLIC SERVICE",
    website: "http://www.pscunow.com",
  },
  "60686": {
    loansProduced: "29.43",
    membersServed: "3,461",
    interestEarned: "2.50",
    name: "SPC",
    website: "http://www.spccu.org",
  },
  "60688": {
    loansProduced: "0.06",
    membersServed: "10",
    interestEarned: "0.00",
    name: "COLLEGE AND UNIVERSITY",
    website: "http://cucu1600.com",
  },
  "60689": {
    loansProduced: "4.75",
    membersServed: "638",
    interestEarned: "0.40",
    name: "NORTH STAR",
    website: "http://www.northstarcreditunion.org",
  },
  "60696": {
    loansProduced: "6.76",
    membersServed: "499",
    interestEarned: "0.57",
    name: "FIRE FIGHTERS",
    website: "http://firefighterscu.org",
  },
  "60700": {
    loansProduced: "294.85",
    membersServed: "22,729",
    interestEarned: "25.06",
    name: "ARROWHEAD CENTRAL",
    website: "http://www.arrowheadcu.org",
  },
  "60705": {
    loansProduced: "586.81",
    membersServed: "58,621",
    interestEarned: "49.88",
    name: "TEXAS DOW EMPLOYEES",
    website: "http://www.tdecu.org",
  },
  "60707": {
    loansProduced: "3.45",
    membersServed: "218",
    interestEarned: "0.29",
    name: "ROME KRAFT EMPLOYEES",
    website: "http://romekraftcu.com",
  },
  "60712": {
    loansProduced: "22.54",
    membersServed: "2,998",
    interestEarned: "1.92",
    name: "ALABAMA CENTRAL",
    website: "http://www.alabamacentral.org",
  },
  "60715": {
    loansProduced: "1.05",
    membersServed: "109",
    interestEarned: "0.09",
    name: "SOUTH CAROLINA METHODIST CONF",
    website: "http://scmccu.org",
  },
  "60716": {
    loansProduced: "5.90",
    membersServed: "811",
    interestEarned: "0.50",
    name: "ONE CREDIT UNION OF NY",
    website: "http://www.OneCreditUnion.org",
  },
  "60718": {
    loansProduced: "2.74",
    membersServed: "240",
    interestEarned: "0.23",
    name: "IOWA HEARTLAND",
    website: "http://WWW.IOWAHEARTLAND.ORG",
  },
  "60721": {
    loansProduced: "51.01",
    membersServed: "5,435",
    interestEarned: "4.34",
    name: "CHAMPION",
    website: "http://www.championcu.com",
  },
  "60726": {
    loansProduced: "4.04",
    membersServed: "230",
    interestEarned: "0.34",
    name: "THE WEST TENNESSEE",
    website: "http://www.wtncu.org",
  },
  "60728": {
    loansProduced: "23.39",
    membersServed: "2,815",
    interestEarned: "1.99",
    name: "THE FAMILY",
    website: "http://www.familycu.com",
  },
  "60731": {
    loansProduced: "5.60",
    membersServed: "554",
    interestEarned: "0.48",
    name: "CAMPUS",
    website: "http://www.campuscu.org",
  },
  "60735": {
    loansProduced: "21.72",
    membersServed: "1,660",
    interestEarned: "1.85",
    name: "MID CAROLINA",
    website: "http://www.midcarolinacu.com",
  },
  "60745": {
    loansProduced: "11.44",
    membersServed: "1,028",
    interestEarned: "0.97",
    name: "SANTEE COOPER CREDIT UNION",
    website: "http://www.santeecoopercreditunion.",
  },
  "60747": {
    loansProduced: "35.39",
    membersServed: "3,946",
    interestEarned: "3.01",
    name: "BELLWETHER COMMUNITY",
    website: "http://www.bccu.org",
  },
  "60750": {
    loansProduced: "0.69",
    membersServed: "46",
    interestEarned: "0.06",
    name: "DULUTH FIRE DEPARTMENT",
    website: null,
  },
  "60755": {
    loansProduced: "2.89",
    membersServed: "316",
    interestEarned: "0.25",
    name: "L. A. ELECTRICAL WORKERS CREDIT UN.",
    website: "http://laewcu.com",
  },
  "60768": {
    loansProduced: "35.06",
    membersServed: "4,487",
    interestEarned: "2.98",
    name: "HOOSIER HILLS",
    website: "http://www.hoosierhills.com",
  },
  "60780": {
    loansProduced: "4.74",
    membersServed: "314",
    interestEarned: "0.40",
    name: "CRANSTON MUNICIPAL EMPLOYEES",
    website: "http://www.cranstonmecu.org",
  },
  "60782": {
    loansProduced: "1.44",
    membersServed: "96",
    interestEarned: "0.12",
    name: "PORT OF HOUSTON",
    website: "http://www.pohcu.com",
  },
  "60784": {
    loansProduced: "200.07",
    membersServed: "23,045",
    interestEarned: "17.01",
    name: "CALIFORNIA",
    website: "http://www.ccu.com",
  },
  "60787": {
    loansProduced: "8.66",
    membersServed: "635",
    interestEarned: "0.74",
    name: "RAILWAY",
    website: "http://www.railwaycu.com",
  },
  "60790": {
    loansProduced: "24.84",
    membersServed: "3,290",
    interestEarned: "2.11",
    name: "TOWN AND COUNTRY",
    website: "http://www.townandcountry.org",
  },
  "60791": {
    loansProduced: "21.96",
    membersServed: "3,225",
    interestEarned: "1.87",
    name: "GEORGETOWN KRAFT",
    website: "http://www.gkcu.org",
  },
  "60793": {
    loansProduced: "725.03",
    membersServed: "60,406",
    interestEarned: "61.63",
    name: "REDWOOD",
    website: "http://www.redwoodcu.org",
  },
  "60798": {
    loansProduced: "24.49",
    membersServed: "2,582",
    interestEarned: "2.08",
    name: "ACCENTRA",
    website: "http://www.accentracu.org",
  },
  "60799": {
    loansProduced: "19.11",
    membersServed: "2,067",
    interestEarned: "1.62",
    name: "NEW HORIZONS",
    website: "http://www.newhcu.org",
  },
  "60801": {
    loansProduced: "22.22",
    membersServed: "2,052",
    interestEarned: "1.89",
    name: "BLUPEAK",
    website: "http://www.blupeak.com",
  },
  "60806": {
    loansProduced: "256.09",
    membersServed: "15,162",
    interestEarned: "21.77",
    name: "TEXANS",
    website: "http://WWW.TEXANSCU.ORG",
  },
  "60816": {
    loansProduced: "108.87",
    membersServed: "9,215",
    interestEarned: "9.25",
    name: "COMMUNITY CREDIT UNION OF FLORIDA",
    website: "http://www.ccuflorida.org",
  },
  "60823": {
    loansProduced: "149.74",
    membersServed: "15,056",
    interestEarned: "12.73",
    name: "ALABAMA",
    website: "http://www.alabamacu.com",
  },
  "60825": {
    loansProduced: "3.42",
    membersServed: "377",
    interestEarned: "0.29",
    name: "COMBINED EMPLOYEES",
    website: "https://www.combinedecu.com",
  },
  "60842": {
    loansProduced: "9.55",
    membersServed: "968",
    interestEarned: "0.81",
    name: "INSTEP",
    website: "http://www.instepmembers.com",
  },
  "60852": {
    loansProduced: "14.14",
    membersServed: "2,069",
    interestEarned: "1.20",
    name: "CENTRAL CREDIT UNION OF ILLINOIS",
    website: "http://www.centralcu.org",
  },
  "60855": {
    loansProduced: "2.67",
    membersServed: "379",
    interestEarned: "0.23",
    name: "KONE EMPLOYEES",
    website: "http://www.konecu.com",
  },
  "60863": {
    loansProduced: "1.96",
    membersServed: "171",
    interestEarned: "0.17",
    name: "PEORIA POSTAL EMPLOYEES",
    website: "http://www.ppecu.info",
  },
  "60873": {
    loansProduced: "1.21",
    membersServed: "108",
    interestEarned: "0.10",
    name: "PEORIA CITY EMPLOYEES",
    website: "http://peoriacu.com",
  },
  "60883": {
    loansProduced: "6.09",
    membersServed: "790",
    interestEarned: "0.52",
    name: "BEACON COMMUNITY",
    website: "https://www.beaconccu.org",
  },
  "60906": {
    loansProduced: "3.93",
    membersServed: "521",
    interestEarned: "0.33",
    name: "MID-KANSAS",
    website: "http://www.mkcu.coop",
  },
  "60920": {
    loansProduced: "15.84",
    membersServed: "2,152",
    interestEarned: "1.35",
    name: "ASTERA",
    website: "http://www.asteracu.com",
  },
  "60923": {
    loansProduced: "0.07",
    membersServed: "4",
    interestEarned: "0.01",
    name: "PARK MANOR CHRISTIAN CHURCH",
    website: "pmcccreditunion.com",
  },
  "60928": {
    loansProduced: "19.30",
    membersServed: "1,987",
    interestEarned: "1.64",
    name: "KIMBERLY CLARK",
    website: "http://www.kimberlyclarkcu.org",
  },
  "60930": {
    loansProduced: "4.59",
    membersServed: "783",
    interestEarned: "0.39",
    name: "VALLEY",
    website: "http://www.valleycreditunion.net",
  },
  "60931": {
    loansProduced: "67.29",
    membersServed: "7,885",
    interestEarned: "5.72",
    name: "MICHIGAN EDUCATIONAL",
    website: "http://www.michedcu.org",
  },
  "60934": {
    loansProduced: "15.28",
    membersServed: "3,296",
    interestEarned: "1.30",
    name: "ALATRUST",
    website: "www.alatrustcu.com",
  },
  "60936": {
    loansProduced: "35.48",
    membersServed: "4,988",
    interestEarned: "3.02",
    name: "ADVENTURE",
    website: "http://www.adventurecu.org",
  },
  "60942": {
    loansProduced: "17.99",
    membersServed: "1,820",
    interestEarned: "1.53",
    name: "RIVERFALL",
    website: "http://riverfallcu.com",
  },
  "60947": {
    loansProduced: "4.83",
    membersServed: "820",
    interestEarned: "0.41",
    name: "CONSUMERS PROFESSIONAL",
    website: "http://www.cpcu.co",
  },
  "60967": {
    loansProduced: "2.31",
    membersServed: "1,403",
    interestEarned: "0.20",
    name: "HUNTINGTON BEACH",
    website: "https://www.huntingtonbeachcu.org",
  },
  "60968": {
    loansProduced: "53.75",
    membersServed: "6,220",
    interestEarned: "4.57",
    name: "THE POLICE",
    website: "http://www.thepolicecu.org",
  },
  "60978": {
    loansProduced: "149.25",
    membersServed: "12,018",
    interestEarned: "12.69",
    name: "DIRECTIONS",
    website: "http://www.directionscu.org",
  },
  "60991": {
    loansProduced: "9.74",
    membersServed: "926",
    interestEarned: "0.83",
    name: "LENCO",
    website: "http://www.lenco.org",
  },
  "60994": {
    loansProduced: "6.21",
    membersServed: "422",
    interestEarned: "0.53",
    name: "ENERGIZE",
    website: "http://www.energize.coop",
  },
  "61003": {
    loansProduced: "15.83",
    membersServed: "1,526",
    interestEarned: "1.35",
    name: "WHEELHOUSE",
    website: "http://www.wheelhousecu.com",
  },
  "61004": {
    loansProduced: "393.13",
    membersServed: "38,108",
    interestEarned: "33.42",
    name: "SAN DIEGO COUNTY",
    website: "http://www.sdccu.com",
  },
  "61021": {
    loansProduced: "0.12",
    membersServed: "26",
    interestEarned: "0.01",
    name: "I.B.E.W. LOCAL #681",
    website: null,
  },
  "61028": {
    loansProduced: "10.20",
    membersServed: "926",
    interestEarned: "0.87",
    name: "NUCOR EMPLOYEE'S",
    website: "http://www.nucorcu.org",
  },
  "61035": {
    loansProduced: "0.20",
    membersServed: "68",
    interestEarned: "0.02",
    name: "SWINDELL-DRESSLER",
    website: "http://swindelldresslercu.com",
  },
  "61048": {
    loansProduced: "25.76",
    membersServed: "4,601",
    interestEarned: "2.19",
    name: "OUCU FINANCIAL CREDIT UNION, INC.",
    website: "http://www.oucu.org",
  },
  "61055": {
    loansProduced: "0.07",
    membersServed: "5",
    interestEarned: "0.01",
    name: "CHEM FAMILY",
    website: null,
  },
  "61056": {
    loansProduced: "0.19",
    membersServed: "14",
    interestEarned: "0.02",
    name: "REAVIS - STICKNEY",
    website: null,
  },
  "61065": {
    loansProduced: "1.34",
    membersServed: "116",
    interestEarned: "0.11",
    name: "COLUMBIA POST OFFICE",
    website: "http://www.columbiapostalcreditunio",
  },
  "61068": {
    loansProduced: "44.52",
    membersServed: "8,223",
    interestEarned: "3.78",
    name: "PEOPLE DRIVEN",
    website: "http://www.peopledrivencu.org",
  },
  "61077": {
    loansProduced: "154.39",
    membersServed: "17,517",
    interestEarned: "13.12",
    name: "SEVEN SEVENTEEN",
    website: "http://www.717cu.com",
  },
  "61085": {
    loansProduced: "3.07",
    membersServed: "223",
    interestEarned: "0.26",
    name: "UTILITY DISTRICT",
    website: "http://UDCU.ORG",
  },
  "61088": {
    loansProduced: "32.51",
    membersServed: "2,197",
    interestEarned: "2.76",
    name: "OKLAHOMA EDUCATORS",
    website: "http://www.oecu.com",
  },
  "61089": {
    loansProduced: "33.14",
    membersServed: "4,039",
    interestEarned: "2.82",
    name: "HOMELAND",
    website: "www.homelandcu.com",
  },
  "61090": {
    loansProduced: "57.27",
    membersServed: "4,231",
    interestEarned: "4.87",
    name: "SHAREFAX",
    website: "http://WWW.SHAREFAX.ORG",
  },
  "61093": {
    loansProduced: "61.19",
    membersServed: "7,518",
    interestEarned: "5.20",
    name: "VIBRANT",
    website: "http://www.vibrantcreditunion.org",
  },
  "61094": {
    loansProduced: "4.81",
    membersServed: "706",
    interestEarned: "0.41",
    name: "COMMUNITY FIRST",
    website: "http://WWW.CFCUA.COM",
  },
  "61101": {
    loansProduced: "2.16",
    membersServed: "380",
    interestEarned: "0.18",
    name: "ACCESS",
    website: "http://www.accessmycu.org",
  },
  "61123": {
    loansProduced: "4.58",
    membersServed: "562",
    interestEarned: "0.39",
    name: "LOUISVILLE GAS AND ELECTRIC COMPANY",
    website: "http://www.lgecu.org",
  },
  "61128": {
    loansProduced: "5.89",
    membersServed: "770",
    interestEarned: "0.50",
    name: "ROLLING F",
    website: "http://www.rollingf.org",
  },
  "61135": {
    loansProduced: "22.11",
    membersServed: "2,091",
    interestEarned: "1.88",
    name: "SPACE CITY",
    website: "http://www.spacecitycu.com",
  },
  "61136": {
    loansProduced: "2.96",
    membersServed: "194",
    interestEarned: "0.25",
    name: "OHIO TEAMSTERS",
    website: "http://www.ohioteamsterscu.com",
  },
  "61150": {
    loansProduced: "4.15",
    membersServed: "215",
    interestEarned: "0.35",
    name: "WCU CREDIT UNION",
    website: "https://www.wcucu.com",
  },
  "61152": {
    loansProduced: "2.80",
    membersServed: "236",
    interestEarned: "0.24",
    name: "RIVER TO RIVER",
    website: "http://r2rcu.com",
  },
  "61157": {
    loansProduced: "7.18",
    membersServed: "22,284",
    interestEarned: "0.61",
    name: "FOUNDATION",
    website: "http://www.foundationcreditunion.co",
  },
  "61159": {
    loansProduced: "79.86",
    membersServed: "7,703",
    interestEarned: "6.79",
    name: "SECURITY",
    website: "http://www.securitycu.org",
  },
  "61160": {
    loansProduced: "22.49",
    membersServed: "28,021",
    interestEarned: "1.91",
    name: "MEMBERS FIRST CREDIT UNION OF FLORI",
    website: "http://www.membersfirstfl.org",
  },
  "61165": {
    loansProduced: "2.78",
    membersServed: "247",
    interestEarned: "0.24",
    name: "LOS ALAMOS SCHOOLS",
    website: "http://www.lascu.org",
  },
  "61170": {
    loansProduced: "127.73",
    membersServed: "22,109",
    interestEarned: "10.86",
    name: "FIRST FLORIDA",
    website: "https://www.firstflorida.org",
  },
  "61185": {
    loansProduced: "157.30",
    membersServed: "10,554",
    interestEarned: "13.37",
    name: "LEADERS CREDIT UNION",
    website: "http://www.leaderscu.com",
  },
  "61186": {
    loansProduced: "35.27",
    membersServed: "3,821",
    interestEarned: "3.00",
    name: "UNITED BAY COMMUNITY",
    website: "http://www.ubccu.org",
  },
  "61189": {
    loansProduced: "3.86",
    membersServed: "1,372",
    interestEarned: "0.33",
    name: "ARRHA",
    website: "http://www.arrhacu.com",
  },
  "61209": {
    loansProduced: "13.86",
    membersServed: "1,125",
    interestEarned: "1.18",
    name: "CHICAGO FIREFIGHTER'S",
    website: "http://chicagofirefighterscu.com",
  },
  "61219": {
    loansProduced: "32.84",
    membersServed: "3,450",
    interestEarned: "2.79",
    name: "KALSEE",
    website: "http://www.kalsee.com",
  },
  "61220": {
    loansProduced: "2.93",
    membersServed: "566",
    interestEarned: "0.25",
    name: "GEAUGA",
    website: "http://www.geaugacreditunion.com",
  },
  "61230": {
    loansProduced: "5.20",
    membersServed: "977",
    interestEarned: "0.44",
    name: "ACME CONTINENTAL",
    website: "http://www.acmecontinentalcu.com",
  },
  "61231": {
    loansProduced: "55.08",
    membersServed: "18,303",
    interestEarned: "4.68",
    name: "FINANCIAL PLUS",
    website: "http://www.financialplus.org",
  },
  "61232": {
    loansProduced: "1.09",
    membersServed: "74",
    interestEarned: "0.09",
    name: "PEORIA HIWAY",
    website: "http://www.peoriahiwaycu.com",
  },
  "61233": {
    loansProduced: "1.15",
    membersServed: "132",
    interestEarned: "0.10",
    name: "WES",
    website: "http://www.wescreditunion.com",
  },
  "61239": {
    loansProduced: "9.54",
    membersServed: "1,561",
    interestEarned: "0.81",
    name: "GOLDEN CIRCLE",
    website: "http://www.goldencirclecu.com",
  },
  "61256": {
    loansProduced: "26.86",
    membersServed: "3,002",
    interestEarned: "2.28",
    name: "ACHIEVE FINANCIAL",
    website: "http://www.achievefinancialcu.com",
  },
  "61257": {
    loansProduced: "7.84",
    membersServed: "978",
    interestEarned: "0.67",
    name: "NORTHRIDGE COMMUNITY",
    website: "www.nrccu.org",
  },
  "61260": {
    loansProduced: "12.35",
    membersServed: "1,234",
    interestEarned: "1.05",
    name: "PALMETTO HEALTH",
    website: "http://www.palmettohealthcu.org",
  },
  "61261": {
    loansProduced: "1.59",
    membersServed: "134",
    interestEarned: "0.14",
    name: "COMMUNITY CU OF NEW MILFORD, INC.",
    website: "http://www.ccunm.org",
  },
  "61262": {
    loansProduced: "0.39",
    membersServed: "59",
    interestEarned: "0.03",
    name: "CRMC EMPLOYEES",
    website: "http://www.coffeeregional.org/getpa",
  },
  "61265": {
    loansProduced: "0.09",
    membersServed: "6",
    interestEarned: "0.01",
    name: "KIEF PROTECTIVE MUTUAL BENEFIT ASS.",
    website: null,
  },
  "61267": {
    loansProduced: "2.17",
    membersServed: "407",
    interestEarned: "0.18",
    name: "ALAMO CITY",
    website: "http://alamocitycu.com",
  },
  "61268": {
    loansProduced: "0.49",
    membersServed: "46",
    interestEarned: "0.04",
    name: "BOTHWELL HOSPITAL EMPLOYEES",
    website: "http://www.bhecu.com",
  },
  "61277": {
    loansProduced: "0.91",
    membersServed: "63",
    interestEarned: "0.08",
    name: "CHEMCO",
    website: null,
  },
  "61286": {
    loansProduced: "4.69",
    membersServed: "447",
    interestEarned: "0.40",
    name: "MOBILE EDUCATORS",
    website: "http://www.yourmecu.com",
  },
  "61290": {
    loansProduced: "2.81",
    membersServed: "20,699",
    interestEarned: "0.24",
    name: "DUPAGE COUNTY EMPLOYEES",
    website: "http://www.dupagececu.org",
  },
  "61306": {
    loansProduced: "13.79",
    membersServed: "938",
    interestEarned: "1.17",
    name: "TEXAS BRIDGE",
    website: "http://WWW.TXBCU.COM",
  },
  "61315": {
    loansProduced: "224.03",
    membersServed: "16,400",
    interestEarned: "19.04",
    name: "ONEAZ",
    website: "https://www.oneazcu.com",
  },
  "61318": {
    loansProduced: "10.84",
    membersServed: "2,028",
    interestEarned: "0.92",
    name: "ISABELLA COMMUNITY",
    website: "https://www.iccuonline.com",
  },
  "61324": {
    loansProduced: "18.29",
    membersServed: "992",
    interestEarned: "1.55",
    name: "PILLAR",
    website: "http://pillarcu.com",
  },
  "61330": {
    loansProduced: "263.88",
    membersServed: "33,641",
    interestEarned: "22.43",
    name: "NEWPORT NEWS SHIPBUILDING EMPLOYEES",
    website: "http://www.bayportcu.org",
  },
  "61336": {
    loansProduced: "0.68",
    membersServed: "173",
    interestEarned: "0.06",
    name: "COMMUNITY",
    website: "http://www.community-credit-union.o",
  },
  "61339": {
    loansProduced: "0.60",
    membersServed: "52",
    interestEarned: "0.05",
    name: "MOBILE GOVERNMENT EMP.",
    website: "http://mobgecu.com",
  },
  "61365": {
    loansProduced: "109.93",
    membersServed: "15,479",
    interestEarned: "9.34",
    name: "4FRONT",
    website: "http://www.4frontcu.com",
  },
  "61368": {
    loansProduced: "1.45",
    membersServed: "252",
    interestEarned: "0.12",
    name: "SHERWIN WILLIAMS EMP.",
    website: "http://swcu.org",
  },
  "61383": {
    loansProduced: "5.38",
    membersServed: "586",
    interestEarned: "0.46",
    name: "ADVANTAGE",
    website: "http://www.advantage4.org",
  },
  "61387": {
    loansProduced: "4.86",
    membersServed: "487",
    interestEarned: "0.41",
    name: "BHCU",
    website: "http://www.bhcu.org",
  },
  "61391": {
    loansProduced: "3.08",
    membersServed: "555",
    interestEarned: "0.26",
    name: "SOCIAL SECURITY",
    website: "https://www.ssacu.com/",
  },
  "61393": {
    loansProduced: "0.69",
    membersServed: "70",
    interestEarned: "0.06",
    name: "LEE COUNTY",
    website: null,
  },
  "61405": {
    loansProduced: "3.28",
    membersServed: "691",
    interestEarned: "0.28",
    name: "AEROQUIP",
    website: "http://www.aeroquipcu.com",
  },
  "61416": {
    loansProduced: "2.95",
    membersServed: "458",
    interestEarned: "0.25",
    name: "CHIPPEWA COUNTY",
    website: "http://chippewacountycu.com",
  },
  "61425": {
    loansProduced: "0.50",
    membersServed: "54",
    interestEarned: "0.04",
    name: "AURORA FIREFIGHTERS'",
    website: null,
  },
  "61437": {
    loansProduced: "219.73",
    membersServed: "21,784",
    interestEarned: "18.68",
    name: "COMMONWEALTH",
    website: "http://www.ccuky.org",
  },
  "61438": {
    loansProduced: "2.98",
    membersServed: "326",
    interestEarned: "0.25",
    name: "SPCO",
    website: "http://www.spcocu.org",
  },
  "61447": {
    loansProduced: "78.40",
    membersServed: "26,360",
    interestEarned: "6.66",
    name: "NUMARK",
    website: "http://www.numarkcu.org",
  },
  "61448": {
    loansProduced: "0.25",
    membersServed: "72",
    interestEarned: "0.02",
    name: "ETHICON SUTURE",
    website: null,
  },
  "61453": {
    loansProduced: "3.40",
    membersServed: "233",
    interestEarned: "0.29",
    name: "HI-LAND",
    website: "http://www.hilandcu.com",
  },
  "61457": {
    loansProduced: "35.92",
    membersServed: "2,868",
    interestEarned: "3.05",
    name: "PRIORITY TRUST",
    website: "http://www.plus4cu.org",
  },
  "61474": {
    loansProduced: "0.77",
    membersServed: "143",
    interestEarned: "0.07",
    name: "RAILWAY EMPLOYEES",
    website: "http://www.railwaycreditunion.com",
  },
  "61495": {
    loansProduced: "6.29",
    membersServed: "1,849",
    interestEarned: "0.53",
    name: "ONE DETROIT",
    website: "http://WWW.OneDetroitCU.org",
  },
  "61503": {
    loansProduced: "254.58",
    membersServed: "23,243",
    interestEarned: "21.64",
    name: "ADVIA",
    website: "http://www.adviacu.org",
  },
  "61515": {
    loansProduced: "2.46",
    membersServed: "225",
    interestEarned: "0.21",
    name: "FAYETTE COUNTY SCHOOL EMPLOYEES",
    website: "http://www.fayetteschoolcu.org",
  },
  "61518": {
    loansProduced: "0.73",
    membersServed: "77",
    interestEarned: "0.06",
    name: "HERSHEY ROBINSON EMPLOYEES",
    website: null,
  },
  "61522": {
    loansProduced: "95.29",
    membersServed: "10,515",
    interestEarned: "8.10",
    name: "VIBE",
    website: "http://www.vibecu.com",
  },
  "61525": {
    loansProduced: "73.45",
    membersServed: "8,146",
    interestEarned: "6.24",
    name: "WE FLORIDA FINANCIAL",
    website: "http://www.wefloridafinancial.com",
  },
  "61527": {
    loansProduced: "34.36",
    membersServed: "2,442",
    interestEarned: "2.92",
    name: "TEXOMA COMMUNITY",
    website: "http://www.TexomaCU.com",
  },
  "61532": {
    loansProduced: "1.82",
    membersServed: "121",
    interestEarned: "0.16",
    name: "REEVES COUNTY TEACHERS",
    website: null,
  },
  "61540": {
    loansProduced: "1.67",
    membersServed: "191",
    interestEarned: "0.14",
    name: "VERMILION SCHOOL EMPLOYEES",
    website: "vermilioncu.org",
  },
  "61552": {
    loansProduced: "178.67",
    membersServed: "22,103",
    interestEarned: "15.19",
    name: "UNIVERSITY OF MICHIGAN",
    website: "http://www.umcu.org",
  },
  "61559": {
    loansProduced: "0.81",
    membersServed: "153",
    interestEarned: "0.07",
    name: "DEMING SCHOOLS EMPLOYEES",
    website: "dsecu.org/",
  },
  "61560": {
    loansProduced: "2.53",
    membersServed: "233",
    interestEarned: "0.22",
    name: "CHICAGO'S BRAVEST",
    website: "http://www.chicagosbravestcu.com",
  },
  "61566": {
    loansProduced: "0.01",
    membersServed: "4",
    interestEarned: "0.00",
    name: "ST. MARK",
    website: "http://st.markcu.org",
  },
  "61598": {
    loansProduced: "178.81",
    membersServed: "11,900",
    interestEarned: "15.20",
    name: "SAFE 1",
    website: "http://safe1.org",
  },
  "61604": {
    loansProduced: "21.47",
    membersServed: "2,180",
    interestEarned: "1.83",
    name: "LIBERTYONE",
    website: "http://www.libertyonecu.com",
  },
  "61605": {
    loansProduced: "59.44",
    membersServed: "5,357",
    interestEarned: "5.05",
    name: "121 FINANCIAL",
    website: "http://www.121fcu.org",
  },
  "61619": {
    loansProduced: "8.86",
    membersServed: "1,303",
    interestEarned: "0.75",
    name: "FINANCIALEDGE COMMUNITY",
    website: "http://www.financialedgeccu.org",
  },
  "61620": {
    loansProduced: "5.30",
    membersServed: "323",
    interestEarned: "0.45",
    name: "FIRSTENERGY FAMILY",
    website: "http://www.fefcu.com",
  },
  "61623": {
    loansProduced: "245.47",
    membersServed: "24,442",
    interestEarned: "20.86",
    name: "KEMBA FINANCIAL",
    website: "http://www.kemba.org",
  },
  "61624": {
    loansProduced: "6.70",
    membersServed: "761",
    interestEarned: "0.57",
    name: "DAWSON CO-OP",
    website: "http://dawsonco-opcu.com",
  },
  "61630": {
    loansProduced: "3.99",
    membersServed: "716",
    interestEarned: "0.34",
    name: "GABRIELS COMMUNITY",
    website: "http://www.gabrielscu.com",
  },
  "61631": {
    loansProduced: "2.43",
    membersServed: "457",
    interestEarned: "0.21",
    name: "MICHIGAN COASTAL",
    website: "http://www.michigancoastal.com",
  },
  "61633": {
    loansProduced: "16.91",
    membersServed: "2,303",
    interestEarned: "1.44",
    name: "FAMILY FINANCIAL",
    website: "https://www.ff-cu.org",
  },
  "61634": {
    loansProduced: "18.16",
    membersServed: "2,123",
    interestEarned: "1.54",
    name: "ILLINOIS COMMUNITY",
    website: "www.myICCU.org",
  },
  "61636": {
    loansProduced: "2.47",
    membersServed: "378",
    interestEarned: "0.21",
    name: "NORTH DISTRICTS",
    website: "https://www.ndcupa.org",
  },
  "61637": {
    loansProduced: "28.24",
    membersServed: "4,701",
    interestEarned: "2.40",
    name: "CAPITAL",
    website: "https://www.capcu.org/",
  },
  "61638": {
    loansProduced: "7.79",
    membersServed: "1,284",
    interestEarned: "0.66",
    name: "LAKE HURON",
    website: "http://lhcu.org",
  },
  "61643": {
    loansProduced: "2.18",
    membersServed: "230",
    interestEarned: "0.19",
    name: "PLANITES",
    website: "http://www.planites.org",
  },
  "61648": {
    loansProduced: "1.98",
    membersServed: "197",
    interestEarned: "0.17",
    name: "HOMETOWN",
    website: "http://www.hcuonline.com",
  },
  "61650": {
    loansProduced: "1,850.99",
    membersServed: "137,192",
    interestEarned: "157.33",
    name: "THE GOLDEN 1",
    website: "http://www.golden1.com",
  },
  "61657": {
    loansProduced: "23.97",
    membersServed: "2,265",
    interestEarned: "2.04",
    name: "UNITED FINANCIAL",
    website: "http://unitedfinancialcu.org",
  },
  "61664": {
    loansProduced: "13.87",
    membersServed: "944",
    interestEarned: "1.18",
    name: "RIVER VALLEY",
    website: "http://www.rvcu.org",
  },
  "61667": {
    loansProduced: "123.23",
    membersServed: "7,892",
    interestEarned: "10.47",
    name: "COMMUNITY FIRST",
    website: "http://www.comfirstcu.org",
  },
  "61677": {
    loansProduced: "23.52",
    membersServed: "4,490",
    interestEarned: "2.00",
    name: "COMMUNITY WEST",
    website: "http://www.communitywestcu.org",
  },
  "61682": {
    loansProduced: "26.01",
    membersServed: "1,614",
    interestEarned: "2.21",
    name: "SISKIYOU",
    website: "http://www.siskiyoucu.org",
  },
  "61693": {
    loansProduced: "3.55",
    membersServed: "469",
    interestEarned: "0.30",
    name: "RURAL COOPERATIVES",
    website: "http://rccu.com",
  },
  "61696": {
    loansProduced: "0.79",
    membersServed: "73",
    interestEarned: "0.07",
    name: "MASON COUNTY SCHOOL EMPLOYEES",
    website: null,
  },
  "61698": {
    loansProduced: "48.12",
    membersServed: "4,141",
    interestEarned: "4.09",
    name: "MICHIGAN UNITED",
    website: "http://www.michiganunitedcu.org",
  },
  "61701": {
    loansProduced: "1.15",
    membersServed: "313",
    interestEarned: "0.10",
    name: "LINCOLN PARK COMMUNITY",
    website: "http://www.lpccu.coop",
  },
  "61708": {
    loansProduced: "39.05",
    membersServed: "7,500",
    interestEarned: "3.32",
    name: "ARBOR FINANCIAL",
    website: "http://www.arborfcu.org",
  },
  "61724": {
    loansProduced: "2.62",
    membersServed: "418",
    interestEarned: "0.22",
    name: "LOUISIANA CENTRAL",
    website: "https://louisianacentral.org",
  },
  "61734": {
    loansProduced: "23.10",
    membersServed: "1,957",
    interestEarned: "1.96",
    name: "RIO GRANDE VALLEY CREDIT UNION",
    website: "http://www.rgvcu.coop",
  },
  "61741": {
    loansProduced: "42.11",
    membersServed: "4,647",
    interestEarned: "3.58",
    name: "COMMONWEALTH CENTRAL",
    website: "http://www.wealthcu.org",
  },
  "61748": {
    loansProduced: "30.08",
    membersServed: "3,905",
    interestEarned: "2.56",
    name: "MONROE COMMUNITY",
    website: "www.monroecommunitycu.org",
  },
  "61755": {
    loansProduced: "105.29",
    membersServed: "13,157",
    interestEarned: "8.95",
    name: "FRANKENMUTH",
    website: "http://www.frankenmuthcu.org",
  },
  "61756": {
    loansProduced: "2.47",
    membersServed: "239",
    interestEarned: "0.21",
    name: "FIRST AREA",
    website: "http://www.firstareacu.com",
  },
  "61758": {
    loansProduced: "20.78",
    membersServed: "1,088",
    interestEarned: "1.77",
    name: "WESTERN COOPERATIVE",
    website: "http://www.wccu.org",
  },
  "61759": {
    loansProduced: "23.07",
    membersServed: "7,355",
    interestEarned: "1.96",
    name: "ALLIANCE CATHOLIC",
    website: "https://www.allianceccu.com",
  },
  "61760": {
    loansProduced: "2.34",
    membersServed: "267",
    interestEarned: "0.20",
    name: "WHITEWATER COMMUNITY",
    website: "http://www.whitewaterccu.org",
  },
  "61765": {
    loansProduced: "1.18",
    membersServed: "102",
    interestEarned: "0.10",
    name: "CENTRAL KANSAS EDUCATION",
    website: "https://ckecusalina.com",
  },
  "61778": {
    loansProduced: "8.78",
    membersServed: "1,144",
    interestEarned: "0.75",
    name: "RED WING",
    website: "http://www.redwingcu.com",
  },
  "61779": {
    loansProduced: "2.68",
    membersServed: "527",
    interestEarned: "0.23",
    name: "SLO",
    website: "http://www.slocu.com",
  },
  "61783": {
    loansProduced: "2.63",
    membersServed: "7,611",
    interestEarned: "0.22",
    name: "CO-OPERATIVE",
    website: "http://www.cooperativecu.com",
  },
  "61790": {
    loansProduced: "25.52",
    membersServed: "3,903",
    interestEarned: "2.17",
    name: "BLUCURRENT",
    website: "http://www.blucurrent.org",
  },
  "61791": {
    loansProduced: "1.46",
    membersServed: "335",
    interestEarned: "0.12",
    name: "TEAMSTERS",
    website: "http://Teamsterscreditunion.com",
  },
  "61792": {
    loansProduced: "37.91",
    membersServed: "3,256",
    interestEarned: "3.22",
    name: "ASHLAND",
    website: "http://WWW.ASHLANDCU.ORG",
  },
  "61793": {
    loansProduced: "5.73",
    membersServed: "40,935",
    interestEarned: "0.49",
    name: "U. S. EMPLOYEES",
    website: "http://www.usemployees.org",
  },
  "61795": {
    loansProduced: "28.76",
    membersServed: "2,628",
    interestEarned: "2.44",
    name: "TBA",
    website: "http://www.tbacu.com",
  },
  "61797": {
    loansProduced: "186.87",
    membersServed: "23,225",
    interestEarned: "15.88",
    name: "ELGA",
    website: "http://www.elgacu.com",
  },
  "61800": {
    loansProduced: "16.45",
    membersServed: "1,779",
    interestEarned: "1.40",
    name: "FORT MCCLELLAN",
    website: "http://FORTMCCLELLANCU.ORG",
  },
  "61808": {
    loansProduced: "1.73",
    membersServed: "184",
    interestEarned: "0.15",
    name: "AFFILIATED TRADES",
    website: "http://www.affiliatedtradescu.org",
  },
  "61810": {
    loansProduced: "188.89",
    membersServed: "20,560",
    interestEarned: "16.06",
    name: "KEMBA",
    website: "http://www.kemba.com",
  },
  "61823": {
    loansProduced: "123.45",
    membersServed: "10,437",
    interestEarned: "10.49",
    name: "AZURA",
    website: "http://www.azuracu.com",
  },
  "61824": {
    loansProduced: "1.60",
    membersServed: "181",
    interestEarned: "0.14",
    name: "TEAMSTERS",
    website: "http://mnteamsterscu.com",
  },
  "61831": {
    loansProduced: "15.73",
    membersServed: "43,487",
    interestEarned: "1.34",
    name: "FAMILY FIRST",
    website: "https://www.ffcuga.org",
  },
  "61838": {
    loansProduced: "0.31",
    membersServed: "22",
    interestEarned: "0.03",
    name: "TRUMBULL",
    website: null,
  },
  "61840": {
    loansProduced: "14.99",
    membersServed: "15,433",
    interestEarned: "1.27",
    name: "METRO",
    website: "http://www.metro.coop",
  },
  "61844": {
    loansProduced: "100.26",
    membersServed: "20,747",
    interestEarned: "8.52",
    name: "DFCU FINANCIAL",
    website: "www.dfcufinancial.com",
  },
  "61847": {
    loansProduced: "8.00",
    membersServed: "806",
    interestEarned: "0.68",
    name: "MIDWEST COALITION OF LABOR",
    website: "https://www.mclcu.org",
  },
  "61872": {
    loansProduced: "0.46",
    membersServed: "56",
    interestEarned: "0.04",
    name: "MONMOUTH COUNTY POSTAL EMPLOYEES",
    website: "http://www.monmouthpostalcu.org",
  },
  "61879": {
    loansProduced: "4.28",
    membersServed: "492",
    interestEarned: "0.36",
    name: "T&I",
    website: "www.ticreditunion.org",
  },
  "61908": {
    loansProduced: "42.51",
    membersServed: "6,741",
    interestEarned: "3.61",
    name: "CASE",
    website: "http://www.casecu.org",
  },
  "61909": {
    loansProduced: "7.64",
    membersServed: "1,075",
    interestEarned: "0.65",
    name: "FAMILY FIRST",
    website: "http://www.fam1st.com",
  },
  "61918": {
    loansProduced: "10.37",
    membersServed: "919",
    interestEarned: "0.88",
    name: "MIDLAND",
    website: "http://www.midlandcu.org",
  },
  "61920": {
    loansProduced: "6.62",
    membersServed: "404",
    interestEarned: "0.56",
    name: "POWER CO-OP EMPLOYEES",
    website: "http://www.powercoopcu.com",
  },
  "61926": {
    loansProduced: "1.29",
    membersServed: "249",
    interestEarned: "0.11",
    name: "ALCOSE",
    website: "http://www.alcosecu.com",
  },
  "61933": {
    loansProduced: "3.83",
    membersServed: "320",
    interestEarned: "0.33",
    name: "COMPASS COMMUNITY",
    website: "http://www.compassccu.org",
  },
  "61936": {
    loansProduced: "40.94",
    membersServed: "3,342",
    interestEarned: "3.48",
    name: "ENERGY CAPITAL",
    website: "https://www.eccu.net",
  },
  "61942": {
    loansProduced: "3.06",
    membersServed: "354",
    interestEarned: "0.26",
    name: "ORGANIZED LABOR",
    website: "http://www.olcu.org",
  },
  "61944": {
    loansProduced: "1.60",
    membersServed: "129",
    interestEarned: "0.14",
    name: "LAUDERDALE COUNTY TEACHERS",
    website: "http://www.lctcu.org",
  },
  "61946": {
    loansProduced: "1.01",
    membersServed: "95",
    interestEarned: "0.09",
    name: "RINCONES PRESBYTERIAN",
    website: null,
  },
  "61949": {
    loansProduced: "24.85",
    membersServed: "2,176",
    interestEarned: "2.11",
    name: "WHITEFISH CREDIT UNION ASSOCIATION",
    website: "http://www.whitefishcu.com",
  },
  "61953": {
    loansProduced: "24.18",
    membersServed: "3,117",
    interestEarned: "2.06",
    name: "NORTH CENTRAL AREA",
    website: "http://www.ncacu.org",
  },
  "61963": {
    loansProduced: "1.38",
    membersServed: "97",
    interestEarned: "0.12",
    name: "OLD SPANISH TRAIL",
    website: "http://oldspanishtrailcreditunion.c",
  },
  "61985": {
    loansProduced: "12.96",
    membersServed: "2,971",
    interestEarned: "1.10",
    name: "PARKSIDE",
    website: "http://WWW.PARKSIDECU.ORG",
  },
  "61986": {
    loansProduced: "9.24",
    membersServed: "1,228",
    interestEarned: "0.79",
    name: "GRATIOT COMMUNITY",
    website: "http://www.gratiotcu.org",
  },
  "61988": {
    loansProduced: "8.21",
    membersServed: "1,054",
    interestEarned: "0.70",
    name: "SAFE HARBOR",
    website: "http://www.safeharborcu.org",
  },
  "61995": {
    loansProduced: "5.00",
    membersServed: "1,145",
    interestEarned: "0.42",
    name: "SUNRISE FAMILY",
    website: "http://www.sunrisefamilycu.org",
  },
  "61999": {
    loansProduced: "1.65",
    membersServed: "136",
    interestEarned: "0.14",
    name: "MONTCALM PUBLIC EMPLOYEES",
    website: "https://mpecreditunion.com",
  },
  "62010": {
    loansProduced: "14.22",
    membersServed: "1,546",
    interestEarned: "1.21",
    name: "FILER",
    website: "http://www.filercu.com",
  },
  "62018": {
    loansProduced: "14.34",
    membersServed: "1,224",
    interestEarned: "1.22",
    name: "ASCENSION",
    website: "http://www.ascensioncu.org",
  },
  "62026": {
    loansProduced: "30.44",
    membersServed: "3,388",
    interestEarned: "2.59",
    name: "MEMBERS CHOICE",
    website: "http://www.mccu.net",
  },
  "62027": {
    loansProduced: "13.95",
    membersServed: "2,129",
    interestEarned: "1.19",
    name: "BEST FINANCIAL",
    website: "http://www.bestfinancialcu.org",
  },
  "62028": {
    loansProduced: "39.85",
    membersServed: "11,168",
    interestEarned: "3.39",
    name: "OUR",
    website: "http://www.ourcuonline.org",
  },
  "62031": {
    loansProduced: "80.96",
    membersServed: "8,064",
    interestEarned: "6.88",
    name: "HEARTLAND",
    website: "http://www.hcu.coop",
  },
  "62035": {
    loansProduced: "55.78",
    membersServed: "7,341",
    interestEarned: "4.74",
    name: "ALPENA-ALCONA AREA",
    website: "http://www.aaacu.com",
  },
  "62038": {
    loansProduced: "37.80",
    membersServed: "7,176",
    interestEarned: "3.21",
    name: "EMBERS",
    website: "https://www.embers.org",
  },
  "62040": {
    loansProduced: "9.68",
    membersServed: "1,116",
    interestEarned: "0.82",
    name: "COMPASS",
    website: "http://www.compasscu.com",
  },
  "62047": {
    loansProduced: "183.94",
    membersServed: "16,730",
    interestEarned: "15.63",
    name: "LAKE TRUST",
    website: "http://www.laketrust.org",
  },
  "62048": {
    loansProduced: "0.93",
    membersServed: "215",
    interestEarned: "0.08",
    name: "CORRY JAMESTOWN",
    website: "http://www.corryjamestowncu.org",
  },
  "62049": {
    loansProduced: "346.04",
    membersServed: "33,236",
    interestEarned: "29.41",
    name: "MICHIGAN SCHOOLS AND GOVERNMENT",
    website: "http://www.msgcu.org",
  },
  "62062": {
    loansProduced: "2.84",
    membersServed: "296",
    interestEarned: "0.24",
    name: "CITY",
    website: "www.ccutuscaloosa.com",
  },
  "62066": {
    loansProduced: "12.05",
    membersServed: "1,867",
    interestEarned: "1.02",
    name: "HARBORLIGHT",
    website: "http://www.harborlightcu.org",
  },
  "62072": {
    loansProduced: "26.89",
    membersServed: "3,127",
    interestEarned: "2.29",
    name: "BLUEOX",
    website: "http://blueoxcu.org",
  },
  "62076": {
    loansProduced: "108.95",
    membersServed: "15,320",
    interestEarned: "9.26",
    name: "MEMBERS FIRST",
    website: "http://www.mfcu.net",
  },
  "62079": {
    loansProduced: "0.25",
    membersServed: "19",
    interestEarned: "0.02",
    name: "COUNTRY HERITAGE",
    website: "http://www.countryheritagecu.com",
  },
  "62085": {
    loansProduced: "11.91",
    membersServed: "1,524",
    interestEarned: "1.01",
    name: "U.P. STATE",
    website: "http://www.upscu.com",
  },
  "62105": {
    loansProduced: "2.19",
    membersServed: "230",
    interestEarned: "0.19",
    name: "FEDERAL EMPLOYEES OF CHIPPEWA CNTY",
    website: "http://www.fecccu.com",
  },
  "62114": {
    loansProduced: "0.96",
    membersServed: "133",
    interestEarned: "0.08",
    name: "ARCADE",
    website: "http://arcadecu.com",
  },
  "62121": {
    loansProduced: "7.27",
    membersServed: "11,217",
    interestEarned: "0.62",
    name: "ILLINOIS VALLEY",
    website: "http://www.ivcu.com",
  },
  "62123": {
    loansProduced: "12.03",
    membersServed: "1,247",
    interestEarned: "1.02",
    name: "RIVER VALLEY",
    website: "http://www.myrivervalleycu.org",
  },
  "62141": {
    loansProduced: "52.20",
    membersServed: "8,385",
    interestEarned: "4.44",
    name: "TEAM ONE",
    website: "http://WWW.TEAMONECU.ORG",
  },
  "62143": {
    loansProduced: "10.56",
    membersServed: "1,089",
    interestEarned: "0.90",
    name: "SOUTH CENTRAL",
    website: "http://southcentralcu.org",
  },
  "62144": {
    loansProduced: "0.77",
    membersServed: "456",
    interestEarned: "0.07",
    name: "UNITED CHURCHES",
    website: "http://www.unitedchurchescu.org",
  },
  "62148": {
    loansProduced: "1.49",
    membersServed: "147",
    interestEarned: "0.13",
    name: "POSTAL",
    website: "http://www.brlapcu.com",
  },
  "62160": {
    loansProduced: "14.21",
    membersServed: "1,754",
    interestEarned: "1.21",
    name: "MARSHALL COMMUNITY",
    website: "http://www.marshallcommunitycu.com",
  },
  "62167": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "I.M. DETROIT DISTRICT",
    website: null,
  },
  "62169": {
    loansProduced: "20.63",
    membersServed: "2,197",
    interestEarned: "1.75",
    name: "MICHIGAN ONE COMMUNITY",
    website: "http://www.M1ccu.org",
  },
  "62172": {
    loansProduced: "240.03",
    membersServed: "20,658",
    interestEarned: "20.40",
    name: "CONSUMERS",
    website: "http://www.consumerscu.org",
  },
  "62196": {
    loansProduced: "9.32",
    membersServed: "1,167",
    interestEarned: "0.79",
    name: "THORNAPPLE",
    website: "https://www.thornapplecu.com",
  },
  "62203": {
    loansProduced: "8.87",
    membersServed: "1,906",
    interestEarned: "0.75",
    name: "WOLVERINE STATE",
    website: "http://www.wolverinescu.com",
  },
  "62217": {
    loansProduced: "14.89",
    membersServed: "2,075",
    interestEarned: "1.27",
    name: "AAC",
    website: "http://aaccreditunion.org",
  },
  "62218": {
    loansProduced: "1.52",
    membersServed: "107",
    interestEarned: "0.13",
    name: "LAMOURE",
    website: "http://www.lamourecu.com",
  },
  "62219": {
    loansProduced: "4.41",
    membersServed: "545",
    interestEarned: "0.37",
    name: "H.P.C.",
    website: "http://www.hpccu.com",
  },
  "62228": {
    loansProduced: "18.41",
    membersServed: "1,345",
    interestEarned: "1.56",
    name: "GREATER KENTUCKY CU, INC.",
    website: "http://www.gtkycu.com",
  },
  "62243": {
    loansProduced: "15.97",
    membersServed: "2,252",
    interestEarned: "1.36",
    name: "BLOOM",
    website: "https://bloomcu.org/",
  },
  "62245": {
    loansProduced: "9.66",
    membersServed: "1,612",
    interestEarned: "0.82",
    name: "TOTAL COMMUNITY",
    website: "http://www.tccu.us",
  },
  "62249": {
    loansProduced: "4.53",
    membersServed: "773",
    interestEarned: "0.38",
    name: "INTANDEM",
    website: "http://www.intandemcu.com",
  },
  "62258": {
    loansProduced: "0.36",
    membersServed: "41",
    interestEarned: "0.03",
    name: "COAST GUARD EMPLOYEES",
    website: "https://www.coastguardecu.net",
  },
  "62264": {
    loansProduced: "1.66",
    membersServed: "287",
    interestEarned: "0.14",
    name: "ALPENA COMMUNITY",
    website: "www.alpenacreditunion.com",
  },
  "62316": {
    loansProduced: "0.21",
    membersServed: "15",
    interestEarned: "0.02",
    name: "WESTACRES",
    website: null,
  },
  "62322": {
    loansProduced: "61.79",
    membersServed: "4,362",
    interestEarned: "5.25",
    name: "OKLAHOMA CENTRAL",
    website: "http://www.oklahomacentral.org",
  },
  "62323": {
    loansProduced: "4.89",
    membersServed: "601",
    interestEarned: "0.42",
    name: "E-CENTRAL",
    website: "http://www.ecentralcu.org",
  },
  "62325": {
    loansProduced: "29.87",
    membersServed: "3,427",
    interestEarned: "2.54",
    name: "SOO CO-OP",
    website: "http://www.soocoop.com",
  },
  "62330": {
    loansProduced: "3.51",
    membersServed: "401",
    interestEarned: "0.30",
    name: "FARM BUREAU FAMILY",
    website: "http://www.farmbureaufamilycu.org",
  },
  "62337": {
    loansProduced: "7.30",
    membersServed: "425",
    interestEarned: "0.62",
    name: "PROFESSIONAL FIRE FIGHTERS",
    website: "http://www.profirefighterscu.com",
  },
  "62338": {
    loansProduced: "0.10",
    membersServed: "11",
    interestEarned: "0.01",
    name: "EVANSTON FIREMENS",
    website: null,
  },
  "62339": {
    loansProduced: "37.23",
    membersServed: "3,821",
    interestEarned: "3.16",
    name: "DIVERSIFIED MEMBERS",
    website: "http://dmcu.com",
  },
  "62340": {
    loansProduced: "25.65",
    membersServed: "3,870",
    interestEarned: "2.18",
    name: "PREFERRED",
    website: "www.preferredcu.org",
  },
  "62344": {
    loansProduced: "1.42",
    membersServed: "168",
    interestEarned: "0.12",
    name: "DETOUR DRUMMOND COMMUNITY",
    website: "http://ddccu.com",
  },
  "62347": {
    loansProduced: "19.80",
    membersServed: "3,185",
    interestEarned: "1.68",
    name: "WEST MICHIGAN",
    website: "http://www.WestMichiganCU.com",
  },
  "62352": {
    loansProduced: "19.73",
    membersServed: "2,397",
    interestEarned: "1.68",
    name: "C E S",
    website: "http://www.cescu.com",
  },
  "62356": {
    loansProduced: "0.89",
    membersServed: "113",
    interestEarned: "0.08",
    name: "L&N EMPLOYEES",
    website: "http://www.mylncu.com",
  },
  "62358": {
    loansProduced: "3.07",
    membersServed: "436",
    interestEarned: "0.26",
    name: "KEMBA LOUISVILLE",
    website: "http://kembaky.org",
  },
  "62367": {
    loansProduced: "73.19",
    membersServed: "6,596",
    interestEarned: "6.22",
    name: "COAST CENTRAL",
    website: "http://www.coastccu.org",
  },
  "62370": {
    loansProduced: "2.79",
    membersServed: "417",
    interestEarned: "0.24",
    name: "LEXINGTON POSTAL COMMUNITY",
    website: "http://www.lexpccu.com",
  },
  "62380": {
    loansProduced: "7.20",
    membersServed: "2,396",
    interestEarned: "0.61",
    name: "DAKOTA WEST",
    website: "http://www.dakotawestcu.com",
  },
  "62393": {
    loansProduced: "5.37",
    membersServed: "1,502",
    interestEarned: "0.46",
    name: "MEIJER",
    website: "http://www.meijercreditunion.com",
  },
  "62406": {
    loansProduced: "19.90",
    membersServed: "3,128",
    interestEarned: "1.69",
    name: "BRIDGE",
    website: "http://www.bridgecu.org",
  },
  "62417": {
    loansProduced: "28.34",
    membersServed: "4,312",
    interestEarned: "2.41",
    name: "CENTRAL CREDIT UNION OF FLORIDA",
    website: "http://ccufl.org",
  },
  "62419": {
    loansProduced: "13.34",
    membersServed: "1,739",
    interestEarned: "1.13",
    name: "CO-OP CREDIT UNION OF MONTEVIDEO",
    website: "http://www.co-opcreditunion.com",
  },
  "62422": {
    loansProduced: "12.80",
    membersServed: "1,198",
    interestEarned: "1.09",
    name: "METRUM COMMUNITY",
    website: "http://www.metrumcu.org",
  },
  "62426": {
    loansProduced: "65.64",
    membersServed: "12,547",
    interestEarned: "5.58",
    name: "ZEAL",
    website: "http://www.zealcu.org",
  },
  "62429": {
    loansProduced: "1.09",
    membersServed: "88",
    interestEarned: "0.09",
    name: "DU PONT EMPLOYEES",
    website: null,
  },
  "62431": {
    loansProduced: "12.03",
    membersServed: "1,328",
    interestEarned: "1.02",
    name: "EXPREE",
    website: "http://www.expree.org",
  },
  "62432": {
    loansProduced: "1.48",
    membersServed: "144",
    interestEarned: "0.13",
    name: "WHITESVILLE COMMUNITY",
    website: "http://www.whitesvillecommunitycu.c",
  },
  "62433": {
    loansProduced: "1.72",
    membersServed: "179",
    interestEarned: "0.15",
    name: "LOUISVILLE FEDERAL",
    website: "http://www.louisvillefcu.com",
  },
  "62438": {
    loansProduced: "3.31",
    membersServed: "306",
    interestEarned: "0.28",
    name: "ALEXANDRIA MUNICIPAL EMPLOYEES",
    website: "http://www.amecu.org",
  },
  "62441": {
    loansProduced: "0.24",
    membersServed: "19",
    interestEarned: "0.02",
    name: "CABOT EMPLOYEES",
    website: null,
  },
  "62446": {
    loansProduced: "10.11",
    membersServed: "886",
    interestEarned: "0.86",
    name: "DOCHES",
    website: "http://www.dochescu.com",
  },
  "62452": {
    loansProduced: "9.98",
    membersServed: "613",
    interestEarned: "0.85",
    name: "FINANCIAL SECURITY",
    website: "http://www.finseccu.com",
  },
  "62471": {
    loansProduced: "2.37",
    membersServed: "260",
    interestEarned: "0.20",
    name: "MOBILE POSTAL",
    website: "http://www.mobilepostalcu.com",
  },
  "62474": {
    loansProduced: "90.69",
    membersServed: "15,593",
    interestEarned: "7.71",
    name: "DUPONT COMMUNITY",
    website: "http://mydccu.com",
  },
  "62479": {
    loansProduced: "5.96",
    membersServed: "275",
    interestEarned: "0.51",
    name: "EL RENO R.I.L.",
    website: "http://www.rockislandcu.com",
  },
  "62482": {
    loansProduced: "2.31",
    membersServed: "198",
    interestEarned: "0.20",
    name: "BROWN-FORMAN EMPLOYEES",
    website: "http://www.bfecu.com",
  },
  "62484": {
    loansProduced: "25.35",
    membersServed: "3,737",
    interestEarned: "2.16",
    name: "SERVICE ONE",
    website: "http://www.socu.com",
  },
  "62486": {
    loansProduced: "0.31",
    membersServed: "43",
    interestEarned: "0.03",
    name: "OPP-MICOLAS",
    website: null,
  },
  "62497": {
    loansProduced: "3.30",
    membersServed: "563",
    interestEarned: "0.28",
    name: "MAROON FINANCIAL",
    website: "http://www.maroonfinancial.org",
  },
  "62500": {
    loansProduced: "3.97",
    membersServed: "39,469",
    interestEarned: "0.34",
    name: "AREA EDUCATIONAL",
    website: "www.myaecu.com",
  },
  "62502": {
    loansProduced: "1.33",
    membersServed: "77",
    interestEarned: "0.11",
    name: "COOPERATIVE EMPLOYEES",
    website: "https://cecuok.com/",
  },
  "62504": {
    loansProduced: "130.45",
    membersServed: "10,076",
    interestEarned: "11.09",
    name: "UNIVERSITY OF VA COMMUNITY CU",
    website: "http://www.uvacreditunion.org",
  },
  "62508": {
    loansProduced: "3.93",
    membersServed: "424",
    interestEarned: "0.33",
    name: "TRI-CITIES",
    website: "http://www.tricu.org",
  },
  "62509": {
    loansProduced: "97.22",
    membersServed: "10,883",
    interestEarned: "8.26",
    name: "FIRSTMARK",
    website: "http://www.firstmarkcu.org",
  },
  "62514": {
    loansProduced: "472.76",
    membersServed: "62,720",
    interestEarned: "40.18",
    name: "LAKE MICHIGAN",
    website: "http://www.lmcu.org",
  },
  "62517": {
    loansProduced: "1.68",
    membersServed: "299",
    interestEarned: "0.14",
    name: "CREDIT UNION ADVANTAGE",
    website: "https://cuadvantage.coop",
  },
  "62536": {
    loansProduced: "2.05",
    membersServed: "2,054",
    interestEarned: "0.17",
    name: "VIRGINIA UNITED METHODIST",
    website: "http://www.tumcu.org",
  },
  "62537": {
    loansProduced: "5.12",
    membersServed: "459",
    interestEarned: "0.43",
    name: "CHATTANOOGA FEDERAL EMPLOYEES",
    website: "https://www.cfecu.org",
  },
  "62542": {
    loansProduced: "244.78",
    membersServed: "15,531",
    interestEarned: "20.81",
    name: "GOLDEN PLAINS",
    website: "http://www.gpcu.org",
  },
  "62543": {
    loansProduced: "2.10",
    membersServed: "252",
    interestEarned: "0.18",
    name: "AUTO-OWNERS ASSOCIATES",
    website: "http://aoacu.com",
  },
  "62562": {
    loansProduced: "160.15",
    membersServed: "15,290",
    interestEarned: "13.61",
    name: "CREDIT UNION ONE",
    website: "http://WWW.CUONE.ORG",
  },
  "62563": {
    loansProduced: "44.82",
    membersServed: "3,301",
    interestEarned: "3.81",
    name: "NORTH STAR COMMUNITY",
    website: "http://www.northstarccu.com",
  },
  "62564": {
    loansProduced: "2.26",
    membersServed: "155",
    interestEarned: "0.19",
    name: "PEOPLE'S",
    website: "http://peoplescu.info",
  },
  "62571": {
    loansProduced: "15.95",
    membersServed: "1,721",
    interestEarned: "1.36",
    name: "MEMORIAL",
    website: "https://www.memorialcu.org",
  },
  "62573": {
    loansProduced: "97.14",
    membersServed: "6,004",
    interestEarned: "8.26",
    name: "RIO GRANDE",
    website: "http://riograndecu.org",
  },
  "62574": {
    loansProduced: "100.27",
    membersServed: "10,729",
    interestEarned: "8.52",
    name: "PREMIER MEMBERS",
    website: "http://www.pmcu.org",
  },
  "62588": {
    loansProduced: "3.19",
    membersServed: "405",
    interestEarned: "0.27",
    name: "CLEVELAND POLICE",
    website: "http://www.clevepolicecu.com",
  },
  "62589": {
    loansProduced: "14.87",
    membersServed: "7,033",
    interestEarned: "1.26",
    name: "EDUCATIONAL COMMUNITY",
    website: "http://www.educationalccu.org",
  },
  "62590": {
    loansProduced: "26.05",
    membersServed: "27,095",
    interestEarned: "2.21",
    name: "ELECTRO SAVINGS",
    website: "http://www.electrosavings.com",
  },
  "62593": {
    loansProduced: "2.23",
    membersServed: "206",
    interestEarned: "0.19",
    name: "MED PARK",
    website: "http://www.medparkcu.com",
  },
  "62598": {
    loansProduced: "9.32",
    membersServed: "36,770",
    interestEarned: "0.79",
    name: "CIVIC CENTRAL",
    website: "www.goodcu.org",
  },
  "62599": {
    loansProduced: "2.37",
    membersServed: "425",
    interestEarned: "0.20",
    name: "FEDERAL EMPLOYEES",
    website: "http://www.federalempscu.com",
  },
  "62604": {
    loansProduced: "1,711.84",
    membersServed: "193,147",
    interestEarned: "145.51",
    name: "BOEING EMPLOYEES",
    website: "http://www.becu.org",
  },
  "62611": {
    loansProduced: "2.15",
    membersServed: "256",
    interestEarned: "0.18",
    name: "NORTHWEST MISSOURI REGIONAL",
    website: "http://www.nwmrcu.org",
  },
  "62620": {
    loansProduced: "1.76",
    membersServed: "161",
    interestEarned: "0.15",
    name: "INDEPENDENCE TEACHERS",
    website: "http://sites.isdschools.org/itcu",
  },
  "62624": {
    loansProduced: "0.70",
    membersServed: "54",
    interestEarned: "0.06",
    name: "ST. JAMES PARISH",
    website: "http://WWW.STJPCU.COM",
  },
  "62628": {
    loansProduced: "11.15",
    membersServed: "1,623",
    interestEarned: "0.95",
    name: "COMMUNITY ONE CREDIT UNION OF OHIO",
    website: "http://www.c1cu.com",
  },
  "62637": {
    loansProduced: "1.79",
    membersServed: "212",
    interestEarned: "0.15",
    name: "JEMEZ VALLEY",
    website: "http://www.jvcu.org",
  },
  "62638": {
    loansProduced: "24.66",
    membersServed: "1,895",
    interestEarned: "2.10",
    name: "NORTH STAR COMMUNITY",
    website: "http://www.nsccu.org",
  },
  "62641": {
    loansProduced: "60.07",
    membersServed: "3,216",
    interestEarned: "5.11",
    name: "SACRAMENTO",
    website: "http://www.sactocu.org",
  },
  "62642": {
    loansProduced: "25.01",
    membersServed: "2,700",
    interestEarned: "2.13",
    name: "MICHIGAN LEGACY",
    website: "http://www.michiganlegacycu.org",
  },
  "62656": {
    loansProduced: "0.06",
    membersServed: "5",
    interestEarned: "0.00",
    name: "BERYLCO EMPLOYEES'",
    website: null,
  },
  "62659": {
    loansProduced: "158.22",
    membersServed: "12,272",
    interestEarned: "13.45",
    name: "ADVANTIS",
    website: "http://www.advantiscu.org",
  },
  "62661": {
    loansProduced: "7.38",
    membersServed: "801",
    interestEarned: "0.63",
    name: "NAHEOLA",
    website: "http://www.naheola.com",
  },
  "62664": {
    loansProduced: "4.25",
    membersServed: "1,685",
    interestEarned: "0.36",
    name: "HEALTH",
    website: "http://www.healthcu.com",
  },
  "62665": {
    loansProduced: "59.26",
    membersServed: "4,710",
    interestEarned: "5.04",
    name: "CENTRAL WILLAMETTE",
    website: "http://www.centralwcu.org",
  },
  "62673": {
    loansProduced: "2.50",
    membersServed: "279",
    interestEarned: "0.21",
    name: "FIRST CAROLINA PEOPLE'S",
    website: "http://www.fcpcu.com",
  },
  "62677": {
    loansProduced: "49.28",
    membersServed: "3,111",
    interestEarned: "4.19",
    name: "MEMBERS 1ST",
    website: "http://www.m1cu.org",
  },
  "62683": {
    loansProduced: "6.39",
    membersServed: "6,715",
    interestEarned: "0.54",
    name: "MEMBERS COMMUNITY",
    website: "http://memberscommunitycu.org",
  },
  "62701": {
    loansProduced: "5.51",
    membersServed: "1,000",
    interestEarned: "0.47",
    name: "CSD",
    website: "http://WWW.CSDCU.ORG",
  },
  "62702": {
    loansProduced: "2.77",
    membersServed: "12,368",
    interestEarned: "0.24",
    name: "K.U.M.C.",
    website: "http://www.kumccu.org",
  },
  "62710": {
    loansProduced: "98.45",
    membersServed: "11,941",
    interestEarned: "8.37",
    name: "PELICAN STATE",
    website: "http://www.pelicanstatecu.com",
  },
  "62715": {
    loansProduced: "292.17",
    membersServed: "55,056",
    interestEarned: "24.83",
    name: "TOGETHER",
    website: "www.togethercu.org",
  },
  "62716": {
    loansProduced: "15.24",
    membersServed: "8,365",
    interestEarned: "1.30",
    name: "FIRST MISSOURI",
    website: "http://www.1stmocu.org",
  },
  "62717": {
    loansProduced: "0.84",
    membersServed: "65",
    interestEarned: "0.07",
    name: "OAK LAWN MUNICIPAL EMPLOYEES",
    website: "olmecu.virualcu.net",
  },
  "62722": {
    loansProduced: "2.39",
    membersServed: "261",
    interestEarned: "0.20",
    name: "FREEDOM COMMUNITY",
    website: "http://www.freedomccu.com",
  },
  "62726": {
    loansProduced: "4.17",
    membersServed: "465",
    interestEarned: "0.35",
    name: "WEST METRO SCHOOLS",
    website: "http://westmetroschoolscu.org",
  },
  "62728": {
    loansProduced: "77.06",
    membersServed: "9,142",
    interestEarned: "6.55",
    name: "APCO EMPLOYEES",
    website: "http://www.apcocu.org",
  },
  "62733": {
    loansProduced: "4.70",
    membersServed: "34,402",
    interestEarned: "0.40",
    name: "NORTHERN STATES POWER - ST. PAUL",
    website: "http://www.mynspcu.org",
  },
  "62736": {
    loansProduced: "135.24",
    membersServed: "14,417",
    interestEarned: "11.50",
    name: "SAN MATEO",
    website: "http://www.smcu.org",
  },
  "62745": {
    loansProduced: "525.13",
    membersServed: "46,208",
    interestEarned: "44.64",
    name: "ONPOINT COMMUNITY",
    website: "http://www.onpointcu.com",
  },
  "62755": {
    loansProduced: "51.74",
    membersServed: "3,389",
    interestEarned: "4.40",
    name: "AVENTA",
    website: "http://www.aventa.com",
  },
  "62756": {
    loansProduced: "19.45",
    membersServed: "2,467",
    interestEarned: "1.65",
    name: "SOUTHWEST LOUISIANA",
    website: "http://www.swlacu.com",
  },
  "62776": {
    loansProduced: "73.06",
    membersServed: "5,741",
    interestEarned: "6.21",
    name: "SOOPER",
    website: "http://www.soopercu.org",
  },
  "62783": {
    loansProduced: "20.99",
    membersServed: "942",
    interestEarned: "1.78",
    name: "ARTESIA",
    website: "http://www.artesiacu.org",
  },
  "62787": {
    loansProduced: "0.53",
    membersServed: "52",
    interestEarned: "0.05",
    name: "KNOLL EMPLOYEES",
    website: null,
  },
  "62796": {
    loansProduced: "1.21",
    membersServed: "154",
    interestEarned: "0.10",
    name: "UNITED CITIES",
    website: "http://nbeecu.org",
  },
  "62799": {
    loansProduced: "246.67",
    membersServed: "23,641",
    interestEarned: "20.97",
    name: "ALTURA",
    website: "http://www.alturacu.com",
  },
  "62801": {
    loansProduced: "44.50",
    membersServed: "4,664",
    interestEarned: "3.78",
    name: "WEST COMMUNITY",
    website: "http://www.westcommunitycu.org",
  },
  "62808": {
    loansProduced: "13.54",
    membersServed: "1,926",
    interestEarned: "1.15",
    name: "DEPARTMENT OF CORRECTIONS",
    website: "http://www.doccu.org",
  },
  "62812": {
    loansProduced: "19.63",
    membersServed: "28,750",
    interestEarned: "1.67",
    name: "PUBLIC SAFETY",
    website: "http://www.publicsafetycu.org",
  },
  "62815": {
    loansProduced: "11.06",
    membersServed: "1,585",
    interestEarned: "0.94",
    name: "RAYTOWN-LEE'S SUMMIT COMMUNITY",
    website: "http://rlsccu.org",
  },
  "62822": {
    loansProduced: "0.27",
    membersServed: "45",
    interestEarned: "0.02",
    name: "LATVIAN CLEVELAND",
    website: "http://www.latvianclevelandcu.org",
  },
  "62826": {
    loansProduced: "2.91",
    membersServed: "273",
    interestEarned: "0.25",
    name: "ROCKFORD MUNICIPAL EMPLOYEES",
    website: "http://www.rmecu.net",
  },
  "62827": {
    loansProduced: "4.72",
    membersServed: "542",
    interestEarned: "0.40",
    name: "2 RIVERS AREA",
    website: "http://www.2riversacu.com",
  },
  "62829": {
    loansProduced: "16.00",
    membersServed: "1,674",
    interestEarned: "1.36",
    name: "AFFINITY",
    website: "http://www.affinitycuia.org",
  },
  "62840": {
    loansProduced: "0.47",
    membersServed: "65",
    interestEarned: "0.04",
    name: "WAKARUSA VALLEY",
    website: null,
  },
  "62841": {
    loansProduced: "8.99",
    membersServed: "559",
    interestEarned: "0.76",
    name: "RAILROAD EMPLOYEES",
    website: "http://www.brecu.org",
  },
  "62847": {
    loansProduced: "20.07",
    membersServed: "2,192",
    interestEarned: "1.71",
    name: "NORTHWOODS",
    website: "https://www.northwoodscu.org",
  },
  "62848": {
    loansProduced: "93.28",
    membersServed: "7,809",
    interestEarned: "7.93",
    name: "TELHIO",
    website: "http://www.telhio.org",
  },
  "62854": {
    loansProduced: "17.42",
    membersServed: "42,743",
    interestEarned: "1.48",
    name: "PARTNERSHIP FINANCIAL",
    website: "http://WWW.MYPFCU.ORG",
  },
  "62855": {
    loansProduced: "1.92",
    membersServed: "224",
    interestEarned: "0.16",
    name: "DIVISION 819 TRANSIT EMPLOYEES",
    website: "www.div819cu.com",
  },
  "62872": {
    loansProduced: "32.49",
    membersServed: "35,948",
    interestEarned: "2.76",
    name: "FIRST ALLIANCE",
    website: "http://www.firstalliancecu.com",
  },
  "62877": {
    loansProduced: "1.32",
    membersServed: "174",
    interestEarned: "0.11",
    name: "MIDDLETOWN AREA SCHOOLS",
    website: "http://www.mascu.com",
  },
  "62878": {
    loansProduced: "2.88",
    membersServed: "354",
    interestEarned: "0.25",
    name: "CAPITOL VIEW",
    website: "http://www.capview.com",
  },
  "62881": {
    loansProduced: "112.34",
    membersServed: "8,002",
    interestEarned: "9.55",
    name: "ARIZONA CENTRAL",
    website: "http://www.azcentralcu.org",
  },
  "62882": {
    loansProduced: "232.43",
    membersServed: "14,207",
    interestEarned: "19.76",
    name: "NAVIGANT",
    website: "http://www.navigantcu.org",
  },
  "62894": {
    loansProduced: "5.25",
    membersServed: "686",
    interestEarned: "0.45",
    name: "ARK VALLEY",
    website: "http://arkvalleycu.org",
  },
  "62895": {
    loansProduced: "1.89",
    membersServed: "13,007",
    interestEarned: "0.16",
    name: "BURLINGTON NORTHTOWN COMMUNITY",
    website: "http://www.bnccu.org",
  },
  "62896": {
    loansProduced: "0.06",
    membersServed: "7",
    interestEarned: "0.01",
    name: "CABOT EMPLOYEES",
    website: null,
  },
  "62897": {
    loansProduced: "11.85",
    membersServed: "30,485",
    interestEarned: "1.01",
    name: "ASSEMBLIES OF GOD",
    website: "http://www.agcu.org",
  },
  "62903": {
    loansProduced: "77.86",
    membersServed: "5,777",
    interestEarned: "6.62",
    name: "PARTNER COLORADO",
    website: "http://partnercoloradocu.org",
  },
  "62905": {
    loansProduced: "57.04",
    membersServed: "38,351",
    interestEarned: "4.85",
    name: "NEIGHBORS",
    website: "http://www.neighborscu.org",
  },
  "62918": {
    loansProduced: "19.37",
    membersServed: "1,889",
    interestEarned: "1.65",
    name: "MEMBERS1ST COMMUNITY",
    website: "http://www.members1st.com",
  },
  "62923": {
    loansProduced: "2.71",
    membersServed: "329",
    interestEarned: "0.23",
    name: "ERIE COUNTY EMPLOYEES",
    website: "http://www.eriecountycreditunion.ne",
  },
  "62929": {
    loansProduced: "3.16",
    membersServed: "195",
    interestEarned: "0.27",
    name: "FEDERAL EMPLOYEES",
    website: "https://federalemployeescu-dn.finan",
  },
  "62930": {
    loansProduced: "3.67",
    membersServed: "16,623",
    interestEarned: "0.31",
    name: "COLUMBIA",
    website: "http://www.como-cu.com",
  },
  "62936": {
    loansProduced: "0.72",
    membersServed: "70",
    interestEarned: "0.06",
    name: "N.O. PORT COMMISSION EMPLOYEES",
    website: "http://www.portnocu.com",
  },
  "62937": {
    loansProduced: "41.93",
    membersServed: "3,052",
    interestEarned: "3.56",
    name: "LANDINGS",
    website: "http://www.landingscu.org",
  },
  "62939": {
    loansProduced: "1.71",
    membersServed: "274",
    interestEarned: "0.15",
    name: "STREATOR COMMUNITY",
    website: "http://www.streatorccu.org",
  },
  "62950": {
    loansProduced: "0.62",
    membersServed: "62",
    interestEarned: "0.05",
    name: "DANFOSS EMPLOYEES",
    website: "http://www.eatoncu.com",
  },
  "62960": {
    loansProduced: "2.08",
    membersServed: "422",
    interestEarned: "0.18",
    name: "SHOW-ME",
    website: "http://showmecu.org",
  },
  "62964": {
    loansProduced: "18.64",
    membersServed: "27,467",
    interestEarned: "1.58",
    name: "EXPEDITION",
    website: "http://www.uecu.coop",
  },
  "62965": {
    loansProduced: "17.69",
    membersServed: "3,926",
    interestEarned: "1.50",
    name: "FINANCIAL PLUS",
    website: "http://www.financialpluscu.com",
  },
  "62969": {
    loansProduced: "26.08",
    membersServed: "7,948",
    interestEarned: "2.22",
    name: "COLLINS COMMUNITY",
    website: "http://www.collinscu.org",
  },
  "62976": {
    loansProduced: "8.44",
    membersServed: "1,001",
    interestEarned: "0.72",
    name: "CONNECTED",
    website: "http://www.connectedcreditunion.org",
  },
  "62978": {
    loansProduced: "59.14",
    membersServed: "5,414",
    interestEarned: "5.03",
    name: "IDEAL",
    website: "http://www.idealcu.com",
  },
  "62983": {
    loansProduced: "28.00",
    membersServed: "3,880",
    interestEarned: "2.38",
    name: "802",
    website: "http://www.802cu.com",
  },
  "62985": {
    loansProduced: "1.37",
    membersServed: "115",
    interestEarned: "0.12",
    name: "GUCO",
    website: "http://gucocu.org",
  },
  "62998": {
    loansProduced: "0.60",
    membersServed: "48",
    interestEarned: "0.05",
    name: "SPOJNIA",
    website: "http://www.spojniacreditunion.org",
  },
  "63004": {
    loansProduced: "136.36",
    membersServed: "18,750",
    interestEarned: "11.59",
    name: "HONOR",
    website: "http://WWW.HONORCU.COM",
  },
  "63008": {
    loansProduced: "2.40",
    membersServed: "232",
    interestEarned: "0.20",
    name: "NEW ORLEANS POLICE DEPARTMENT EMP.",
    website: "HTTPS://WWW.NOPDECU.COM",
  },
  "63020": {
    loansProduced: "30.85",
    membersServed: "5,330",
    interestEarned: "2.62",
    name: "SUMMIT",
    website: "http://summitcu.org",
  },
  "63042": {
    loansProduced: "1.44",
    membersServed: "100",
    interestEarned: "0.12",
    name: "TOPEKA FIREMEN'S",
    website: "http://tfcu9.org",
  },
  "63055": {
    loansProduced: "0.71",
    membersServed: "40",
    interestEarned: "0.06",
    name: "ST. LOUIS NEWSPAPER CARRIERS",
    website: null,
  },
  "63057": {
    loansProduced: "94.56",
    membersServed: "13,934",
    interestEarned: "8.04",
    name: "LISTERHILL",
    website: "http://www.listerhill.com",
  },
  "63058": {
    loansProduced: "3.70",
    membersServed: "552",
    interestEarned: "0.31",
    name: "METRO EMPLOYEES",
    website: "http://www.metroemployeescu.org",
  },
  "63069": {
    loansProduced: "3.73",
    membersServed: "567",
    interestEarned: "0.32",
    name: "POST OFFICE EMPLOYEES'",
    website: "http://www.poecu.org",
  },
  "63090": {
    loansProduced: "2.48",
    membersServed: "389",
    interestEarned: "0.21",
    name: "HUTCHINSON GOVERNMENT EMPLOYEES",
    website: "http://www.hgecu.com",
  },
  "63098": {
    loansProduced: "13.89",
    membersServed: "16,997",
    interestEarned: "1.18",
    name: "SHAREPOINT",
    website: "https://www.sharepointcu.com/",
  },
  "63100": {
    loansProduced: "5.38",
    membersServed: "680",
    interestEarned: "0.46",
    name: "CHAMPION",
    website: "http://www.ChampionCU.org",
  },
  "63103": {
    loansProduced: "3.12",
    membersServed: "17,011",
    interestEarned: "0.27",
    name: "BLOOMINGTON MUNICIPAL",
    website: "http://www.bmcu.org",
  },
  "63109": {
    loansProduced: "3.04",
    membersServed: "16,975",
    interestEarned: "0.26",
    name: "CITIES",
    website: "http://www.citiescu.org",
  },
  "63116": {
    loansProduced: "49.29",
    membersServed: "3,576",
    interestEarned: "4.19",
    name: "TELCOMM",
    website: "http://www.telcommcu.com",
  },
  "63122": {
    loansProduced: "12.81",
    membersServed: "895",
    interestEarned: "1.09",
    name: "WELD COMMUNITY",
    website: "http://www.weldccu.com",
  },
  "63124": {
    loansProduced: "15.93",
    membersServed: "2,469",
    interestEarned: "1.35",
    name: "1ST GATEWAY",
    website: "http://www.1stgateway.org",
  },
  "63125": {
    loansProduced: "1.59",
    membersServed: "18,626",
    interestEarned: "0.14",
    name: "ARCHER HEIGHTS",
    website: "http://www.archerheights.com",
  },
  "63133": {
    loansProduced: "28.44",
    membersServed: "3,390",
    interestEarned: "2.42",
    name: "UNIVERSITY",
    website: "http://www.ucumaine.com",
  },
  "63137": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "VISION ONE",
    website: "http://www.visionone.org",
  },
  "63142": {
    loansProduced: "4.71",
    membersServed: "376",
    interestEarned: "0.40",
    name: "CALCASIEU TEACHERS AND EMPLOYEES",
    website: "http://www.calteacherscu.com",
  },
  "63143": {
    loansProduced: "0.02",
    membersServed: "4",
    interestEarned: "0.00",
    name: "IMMACULATE HEART OF MARY",
    website: null,
  },
  "63157": {
    loansProduced: "5.81",
    membersServed: "1,004",
    interestEarned: "0.49",
    name: "PIPEFITTERS-STEAMFITTERS",
    website: "www.pipefitterscu.org",
  },
  "63159": {
    loansProduced: "6.01",
    membersServed: "619",
    interestEarned: "0.51",
    name: "FORT DODGE FAMILY",
    website: "http://www.fdfcu.com",
  },
  "63169": {
    loansProduced: "2.82",
    membersServed: "22,570",
    interestEarned: "0.24",
    name: "RELIANCE",
    website: "http://www.reliancecu.org",
  },
  "63177": {
    loansProduced: "1.21",
    membersServed: "125",
    interestEarned: "0.10",
    name: "THE CATHOLIC",
    website: "http://WWW.CUCATHOLIC.COM",
  },
  "63183": {
    loansProduced: "4.22",
    membersServed: "504",
    interestEarned: "0.36",
    name: "RIMROCK",
    website: "http://www.rimrockcu.org",
  },
  "63189": {
    loansProduced: "19.50",
    membersServed: "1,679",
    interestEarned: "1.66",
    name: "WHITE EAGLE",
    website: "http://www.whiteeaglecu.com",
  },
  "63194": {
    loansProduced: "1,013.48",
    membersServed: "83,342",
    interestEarned: "86.15",
    name: "IDAHO CENTRAL",
    website: "http://www.iccu.com",
  },
  "63196": {
    loansProduced: "283.51",
    membersServed: "20,705",
    interestEarned: "24.10",
    name: "SELCO COMMUNITY",
    website: "http://www.selco.org",
  },
  "63199": {
    loansProduced: "4.11",
    membersServed: "544",
    interestEarned: "0.35",
    name: "LINCOLN COUNTY",
    website: "http://www.lincolncountycu.com",
  },
  "63201": {
    loansProduced: "28.76",
    membersServed: "3,783",
    interestEarned: "2.44",
    name: "FRONTIER COMMUNITY",
    website: "http://www.FrontierCCU.org",
  },
  "63211": {
    loansProduced: "5.06",
    membersServed: "589",
    interestEarned: "0.43",
    name: "WHEAT STATE",
    website: "http://www.wheatstatecu.com",
  },
  "63213": {
    loansProduced: "1.68",
    membersServed: "198",
    interestEarned: "0.14",
    name: "OTTAWA HIWAY",
    website: "http://ottawahiwaycu.com",
  },
  "63217": {
    loansProduced: "1.44",
    membersServed: "125",
    interestEarned: "0.12",
    name: "1ST KANSAS",
    website: "http://www.1stkansascu.com",
  },
  "63218": {
    loansProduced: "30.91",
    membersServed: "2,582",
    interestEarned: "2.63",
    name: "TELCO TRIAD COMMUNITY",
    website: "http://www.telcotriad.org",
  },
  "63249": {
    loansProduced: "4.98",
    membersServed: "24,876",
    interestEarned: "0.42",
    name: "HEALTH CARE FAMILY",
    website: "http://www.healthcarefamilycreditun",
  },
  "63258": {
    loansProduced: "1.44",
    membersServed: "154",
    interestEarned: "0.12",
    name: "MOWER COUNTY CATHOLIC PARISHES",
    website: "www.mccpcu.com",
  },
  "63262": {
    loansProduced: "19.03",
    membersServed: "1,892",
    interestEarned: "1.62",
    name: "BAY CU",
    website: "www.baycu.com",
  },
  "63268": {
    loansProduced: "41.34",
    membersServed: "6,651",
    interestEarned: "3.51",
    name: "ASCENTRA",
    website: "http://www.ascentra.org",
  },
  "63272": {
    loansProduced: "58.52",
    membersServed: "4,467",
    interestEarned: "4.97",
    name: "GRANITE STATE",
    website: "www.gscu.org",
  },
  "63286": {
    loansProduced: "3.22",
    membersServed: "480",
    interestEarned: "0.27",
    name: "FOX VALLEY",
    website: "http://www.foxvalleycu.com",
  },
  "63299": {
    loansProduced: "18.85",
    membersServed: "1,623",
    interestEarned: "1.60",
    name: "EMPLOYEES",
    website: "http://www.employeescu.com",
  },
  "63300": {
    loansProduced: "36.82",
    membersServed: "3,646",
    interestEarned: "3.13",
    name: "SKYWARD",
    website: "https://www.skywardcu.com",
  },
  "63306": {
    loansProduced: "13.91",
    membersServed: "1,252",
    interestEarned: "1.18",
    name: "MERCY",
    website: "http://www.mymercycu.com",
  },
  "63315": {
    loansProduced: "7.43",
    membersServed: "2,779",
    interestEarned: "0.63",
    name: "NW PRIORITY",
    website: "http://www.nwprioritycu.org",
  },
  "63319": {
    loansProduced: "2.69",
    membersServed: "395",
    interestEarned: "0.23",
    name: "CREDIT UNION OF EMPORIA",
    website: "http://www.cuofe.org",
  },
  "63340": {
    loansProduced: "0.24",
    membersServed: "33",
    interestEarned: "0.02",
    name: "S.C.F.E.",
    website: null,
  },
  "63342": {
    loansProduced: "9.71",
    membersServed: "1,005",
    interestEarned: "0.83",
    name: "THE LOCAL",
    website: "http://www.thelocalcreditunion.com",
  },
  "63368": {
    loansProduced: "9.95",
    membersServed: "1,834",
    interestEarned: "0.85",
    name: "MEMBERS ''FIRST'' COMMUNITY",
    website: "http://membersfirstccu.com",
  },
  "63373": {
    loansProduced: "0.41",
    membersServed: "66",
    interestEarned: "0.04",
    name: "NORTH BAY",
    website: "http://www.northbaycu.com",
  },
  "63374": {
    loansProduced: "3.51",
    membersServed: "431",
    interestEarned: "0.30",
    name: "FARMWAY",
    website: "http://www.farmwaycu.com",
  },
  "63375": {
    loansProduced: "7.78",
    membersServed: "950",
    interestEarned: "0.66",
    name: "BAY AREA",
    website: "http://www.bayareacu.com",
  },
  "63377": {
    loansProduced: "56.09",
    membersServed: "5,505",
    interestEarned: "4.77",
    name: "MAINE STATE",
    website: "http://www.mainestatecu.org",
  },
  "63378": {
    loansProduced: "0.24",
    membersServed: "27",
    interestEarned: "0.02",
    name: "QUINCY POSTAL EMPLOYEES",
    website: null,
  },
  "63386": {
    loansProduced: "2.31",
    membersServed: "223",
    interestEarned: "0.20",
    name: "SCOTT ASSOCIATES",
    website: "http://www.mysacu.com",
  },
  "63388": {
    loansProduced: "3.40",
    membersServed: "492",
    interestEarned: "0.29",
    name: "KANSAS CITY",
    website: "http://www.kccu.net",
  },
  "63389": {
    loansProduced: "10.95",
    membersServed: "26,085",
    interestEarned: "0.93",
    name: "VOLT",
    website: "http://voltcu.org",
  },
  "63398": {
    loansProduced: "2.55",
    membersServed: "402",
    interestEarned: "0.22",
    name: "ELY AREA",
    website: "http://www.elyareacu.org",
  },
  "63405": {
    loansProduced: "6.93",
    membersServed: "1,458",
    interestEarned: "0.59",
    name: "POCATELLO SIMPLOT",
    website: "http://www.pocsimplotcu.com",
  },
  "63411": {
    loansProduced: "9.67",
    membersServed: "26,586",
    interestEarned: "0.82",
    name: "MULTIPLI",
    website: "http://multiplicu.com",
  },
  "63420": {
    loansProduced: "1.64",
    membersServed: "136",
    interestEarned: "0.14",
    name: "DISTRICT ONE HIGHWAY",
    website: "http://district1hwycu.org",
  },
  "63425": {
    loansProduced: "8.51",
    membersServed: "1,473",
    interestEarned: "0.72",
    name: "NOVA",
    website: "https://www.novacu.com",
  },
  "63440": {
    loansProduced: "156.79",
    membersServed: "13,312",
    interestEarned: "13.33",
    name: "UNITUS COMMUNITY",
    website: "http://www.unitusccu.com",
  },
  "63442": {
    loansProduced: "2.83",
    membersServed: "582",
    interestEarned: "0.24",
    name: "MISSISSIPPI PUBLIC EMPLOYEES",
    website: "http://www.mspecu.org",
  },
  "63445": {
    loansProduced: "1.18",
    membersServed: "89",
    interestEarned: "0.10",
    name: "GREENSBORO",
    website: "https://www.gsocu.org",
  },
  "63446": {
    loansProduced: "0.75",
    membersServed: "262",
    interestEarned: "0.06",
    name: "ATRIUM",
    website: "http://WWW.ATRIUM4U.ORG",
  },
  "63447": {
    loansProduced: "14.56",
    membersServed: "1,533",
    interestEarned: "1.24",
    name: "COMMUNITY",
    website: "http://www.communitycreditunion.com",
  },
  "63459": {
    loansProduced: "0.53",
    membersServed: "52",
    interestEarned: "0.04",
    name: "WILLIAMSON COUNTY CATHOLIC",
    website: "http://www.wcccu.us",
  },
  "63468": {
    loansProduced: "1.12",
    membersServed: "81",
    interestEarned: "0.09",
    name: "VALLEY EDUCATORS",
    website: "http://WWW.ITSME247.COM",
  },
  "63476": {
    loansProduced: "1.93",
    membersServed: "164",
    interestEarned: "0.16",
    name: "GATEWAY",
    website: "http://www.yourgcu.org",
  },
  "63507": {
    loansProduced: "3.89",
    membersServed: "259",
    interestEarned: "0.33",
    name: "DE SOTO MO PAC",
    website: "http://www.desotomopaccu.com",
  },
  "63519": {
    loansProduced: "4.65",
    membersServed: "465",
    interestEarned: "0.39",
    name: "BLUE FLAME",
    website: "https://www.blueflamecu.org",
  },
  "63532": {
    loansProduced: "70.03",
    membersServed: "6,235",
    interestEarned: "5.95",
    name: "ATOMIC",
    website: "http://www.atomiccu.com",
  },
  "63541": {
    loansProduced: "13.68",
    membersServed: "1,414",
    interestEarned: "1.16",
    name: "THE CREDIT UNION FOR ALL",
    website: "www.cuforall.com",
  },
  "63545": {
    loansProduced: "30.48",
    membersServed: "3,394",
    interestEarned: "2.59",
    name: "TELCO COMMUNITY CREDIT UNION",
    website: "http://www.telcoccu.org",
  },
  "63566": {
    loansProduced: "5.70",
    membersServed: "417",
    interestEarned: "0.48",
    name: "CHARLOTTE FIRE DEPARTMENT",
    website: "http://www.cfdcu.org",
  },
  "63589": {
    loansProduced: "3.07",
    membersServed: "207",
    interestEarned: "0.26",
    name: "JACOM",
    website: "http://jacomcu.org",
  },
  "63595": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "MOUNT VERNON BAPTIST CHURCH",
    website: null,
  },
  "63597": {
    loansProduced: "42.41",
    membersServed: "36,218",
    interestEarned: "3.61",
    name: "UNITED",
    website: "http://www.unitedcu.org",
  },
  "63598": {
    loansProduced: "4.21",
    membersServed: "362",
    interestEarned: "0.36",
    name: "B&V",
    website: "http://bvcreditunion.com",
  },
  "63602": {
    loansProduced: "54.47",
    membersServed: "5,679",
    interestEarned: "4.63",
    name: "LINN AREA",
    website: "http://www.linnareacu.org",
  },
  "63606": {
    loansProduced: "15.30",
    membersServed: "29,105",
    interestEarned: "1.30",
    name: "GOETZ",
    website: "http://www.goetzcu.com",
  },
  "63613": {
    loansProduced: "6.40",
    membersServed: "725",
    interestEarned: "0.54",
    name: "DAKOTA PLAINS",
    website: "http://dakotaplainscreditunion.com",
  },
  "63614": {
    loansProduced: "3.09",
    membersServed: "646",
    interestEarned: "0.26",
    name: "CHAMPION COMMUNITY",
    website: "http://www.caecu.org/",
  },
  "63627": {
    loansProduced: "2.93",
    membersServed: "348",
    interestEarned: "0.25",
    name: "WINSLOW SANTA FE",
    website: "http://wsfcu.net",
  },
  "63630": {
    loansProduced: "2.23",
    membersServed: "170",
    interestEarned: "0.19",
    name: "ATCHISON VILLAGE",
    website: "atchisonvillagecu.org",
  },
  "63637": {
    loansProduced: "56.97",
    membersServed: "7,211",
    interestEarned: "4.84",
    name: "NAVIGATOR CU",
    website: "http://www.navigatorcu.org",
  },
  "63639": {
    loansProduced: "44.52",
    membersServed: "4,496",
    interestEarned: "3.78",
    name: "MINNCO",
    website: "http://minnco.com",
  },
  "63648": {
    loansProduced: "14.79",
    membersServed: "1,842",
    interestEarned: "1.26",
    name: "TENDTO",
    website: "https://www.tendtocu.com",
  },
  "63663": {
    loansProduced: "5.18",
    membersServed: "791",
    interestEarned: "0.44",
    name: "ECUSTA",
    website: "http://www.ecustacu.com",
  },
  "63672": {
    loansProduced: "1.79",
    membersServed: "38,653",
    interestEarned: "0.15",
    name: "TAZEWELL COUNTY SCHOOL EMPLOYEES",
    website: "http://www.tcsecu.com",
  },
  "63676": {
    loansProduced: "0.78",
    membersServed: "86",
    interestEarned: "0.07",
    name: "COLUMBIANA COUNTY SCHOOL EMPL",
    website: "http://ccsecreditunion.com",
  },
  "63679": {
    loansProduced: "5.06",
    membersServed: "507",
    interestEarned: "0.43",
    name: "PAINESVILLE CREDIT UNION",
    website: "http://www.painesvillecu.org/",
  },
  "63704": {
    loansProduced: "2.04",
    membersServed: "587",
    interestEarned: "0.17",
    name: "MOUNTAIN RIVER",
    website: "http://www.mountainrivercu.org",
  },
  "63715": {
    loansProduced: "0.33",
    membersServed: "26",
    interestEarned: "0.03",
    name: "GREENSBURG TEACHERS",
    website: null,
  },
  "63716": {
    loansProduced: "44.23",
    membersServed: "5,300",
    interestEarned: "3.76",
    name: "LAND OF LINCOLN",
    website: "https://www.llcu.org",
  },
  "63757": {
    loansProduced: "46.86",
    membersServed: "5,242",
    interestEarned: "3.98",
    name: "LIBERTY FIRST",
    website: "http://www.libertyfirstcu.com",
  },
  "63783": {
    loansProduced: "23.30",
    membersServed: "31,439",
    interestEarned: "1.98",
    name: "COOPERATIVE CHOICE NETWORK",
    website: "http://www.ccncu.com",
  },
  "63789": {
    loansProduced: "25.81",
    membersServed: "36,536",
    interestEarned: "2.19",
    name: "ALLIANCE",
    website: "http://alliancecu.com",
  },
  "63791": {
    loansProduced: "4.29",
    membersServed: "490",
    interestEarned: "0.36",
    name: "RIVERSIDE COMMUNITY",
    website: "http://www.riversidecu.com",
  },
  "63793": {
    loansProduced: "0.32",
    membersServed: "53",
    interestEarned: "0.03",
    name: "NIAGARA FRONTIER FEDERAL MUNICIPAL",
    website: "https://nffmecu.com/",
  },
  "63802": {
    loansProduced: "1.39",
    membersServed: "146",
    interestEarned: "0.12",
    name: "MEMORIAL",
    website: "http://www.memorialcreditunion.com",
  },
  "63805": {
    loansProduced: "50.47",
    membersServed: "7,940",
    interestEarned: "4.29",
    name: "CHRISTIAN FINANCIAL",
    website: "http://www.christianfinancialcu.org",
  },
  "63828": {
    loansProduced: "27.98",
    membersServed: "2,974",
    interestEarned: "2.38",
    name: "CPORT",
    website: "http://www.cportcu.org",
  },
  "63829": {
    loansProduced: "127.10",
    membersServed: "9,815",
    interestEarned: "10.80",
    name: "ST. MARY'S BANK",
    website: "http://www.stmarysbank.com",
  },
  "63830": {
    loansProduced: "0.77",
    membersServed: "86",
    interestEarned: "0.07",
    name: "SIKESTON PUBLIC SCHOOLS",
    website: "https://spscu.com",
  },
  "63832": {
    loansProduced: "156.23",
    membersServed: "14,939",
    interestEarned: "13.28",
    name: "TRUWEST",
    website: "http://www.truwest.org",
  },
  "63834": {
    loansProduced: "6.81",
    membersServed: "2,075",
    interestEarned: "0.58",
    name: "JEFFERSON CREDIT UNION",
    website: "http://www.Jeffersoncreditunion.org",
  },
  "63837": {
    loansProduced: "40.36",
    membersServed: "2,397",
    interestEarned: "3.43",
    name: "ADELFI",
    website: "http://www.adelfibanking.com",
  },
  "63844": {
    loansProduced: "11.20",
    membersServed: "12,697",
    interestEarned: "0.95",
    name: "HORIZON",
    website: "http://www.yourhcu.com",
  },
  "63851": {
    loansProduced: "1.05",
    membersServed: "63",
    interestEarned: "0.09",
    name: "C-F LA.",
    website: "http://WWW.CFLACU.COM",
  },
  "63859": {
    loansProduced: "2.97",
    membersServed: "464",
    interestEarned: "0.25",
    name: "GREEN MOUNTAIN",
    website: "http://www.greenmountaincu.com",
  },
  "63868": {
    loansProduced: "1.15",
    membersServed: "89",
    interestEarned: "0.10",
    name: "GALLATIN STEAM PLANT",
    website: "http://www.steamplantcu.org",
  },
  "63891": {
    loansProduced: "0.98",
    membersServed: "62",
    interestEarned: "0.08",
    name: "LATVIAN",
    website: "https://www.latviancu.com",
  },
  "63896": {
    loansProduced: "4.20",
    membersServed: "557",
    interestEarned: "0.36",
    name: "CREDIT UNION OF VERMONT",
    website: "https://cuvermont.coop",
  },
  "63899": {
    loansProduced: "1.93",
    membersServed: "390",
    interestEarned: "0.16",
    name: "MEMBERS 1ST",
    website: "http://www.members1cu.com",
  },
  "63903": {
    loansProduced: "0.04",
    membersServed: "6",
    interestEarned: "0.00",
    name: "RADIO CAB",
    website: null,
  },
  "63923": {
    loansProduced: "27.34",
    membersServed: "3,170",
    interestEarned: "2.32",
    name: "ONE",
    website: "http://www.onecu.org",
  },
  "63932": {
    loansProduced: "138.39",
    membersServed: "26,813",
    interestEarned: "11.76",
    name: "1ST MIDAMERICA",
    website: "http://www.1stmidamerica.org",
  },
  "63940": {
    loansProduced: "3.73",
    membersServed: "2,297",
    interestEarned: "0.32",
    name: "RED OAK",
    website: "http://www.redoakcu.com",
  },
  "63943": {
    loansProduced: "1.93",
    membersServed: "254",
    interestEarned: "0.16",
    name: "GLATCO",
    website: "http://www.glatcocu.com",
  },
  "63958": {
    loansProduced: "124.44",
    membersServed: "10,604",
    interestEarned: "10.58",
    name: "1ST UNITED",
    website: "http://www.1stUnitedcu.org",
  },
  "63965": {
    loansProduced: "4.48",
    membersServed: "25,696",
    interestEarned: "0.38",
    name: "VIRGINIA COOP",
    website: "http://www.vccu.org",
  },
  "63971": {
    loansProduced: "11.61",
    membersServed: "1,959",
    interestEarned: "0.99",
    name: "POINT WEST",
    website: "http://www.pointwestcu.com",
  },
  "63977": {
    loansProduced: "3.60",
    membersServed: "32,415",
    interestEarned: "0.31",
    name: "MINNESOTA CATHOLIC",
    website: "http://www.mncathcu.com",
  },
  "63988": {
    loansProduced: "1.20",
    membersServed: "136",
    interestEarned: "0.10",
    name: "ELECTRIC MACHINERY EMPLOYEES",
    website: "http://EMCU.ORG",
  },
  "63996": {
    loansProduced: "49.85",
    membersServed: "19,147",
    interestEarned: "4.24",
    name: "INFUZE",
    website: "http://www.infuzecu.org",
  },
  "64029": {
    loansProduced: "16.58",
    membersServed: "1,882",
    interestEarned: "1.41",
    name: "SANTA CRUZ COMMUNITY",
    website: "http://scccu.org",
  },
  "64030": {
    loansProduced: "15.99",
    membersServed: "1,156",
    interestEarned: "1.36",
    name: "ADVANTAGE",
    website: "http://www.acuiowa.org",
  },
  "64034": {
    loansProduced: "0.79",
    membersServed: "153",
    interestEarned: "0.07",
    name: "GREATER KINSTON",
    website: "http://www.greaterkcu.org",
  },
  "64036": {
    loansProduced: "14.01",
    membersServed: "801",
    interestEarned: "1.19",
    name: "MISSOURI CENTRAL",
    website: "https://www.mocentral.org",
  },
  "64037": {
    loansProduced: "9.71",
    membersServed: "1,963",
    interestEarned: "0.83",
    name: "POWER",
    website: "http://www.powercu.org",
  },
  "64038": {
    loansProduced: "2.10",
    membersServed: "302",
    interestEarned: "0.18",
    name: "BLOOMINGTON POSTAL EMPLOYEES",
    website: "https://www.ea.financial-net.com",
  },
  "64049": {
    loansProduced: "226.64",
    membersServed: "24,477",
    interestEarned: "19.26",
    name: "DUPACO COMMUNITY",
    website: "http://www.dupaco.com",
  },
  "64056": {
    loansProduced: "2.86",
    membersServed: "22,668",
    interestEarned: "0.24",
    name: "COUNTY",
    website: "http://www.countycu.org",
  },
  "64059": {
    loansProduced: "2.09",
    membersServed: "268",
    interestEarned: "0.18",
    name: "BENTON COUNTY SCHOOLS",
    website: "http://www.bcscu.org",
  },
  "64062": {
    loansProduced: "3.10",
    membersServed: "270",
    interestEarned: "0.26",
    name: "SABATTUS REGIONAL",
    website: "http://www.srcu4u.com",
  },
  "64067": {
    loansProduced: "1.60",
    membersServed: "124",
    interestEarned: "0.14",
    name: "FAMILY COMMUNITY",
    website: "http://www.ccfccu.com",
  },
  "64084": {
    loansProduced: "119.78",
    membersServed: "9,848",
    interestEarned: "10.18",
    name: "MAZUMA",
    website: "http://www.mazuma.org",
  },
  "64089": {
    loansProduced: "1.01",
    membersServed: "80",
    interestEarned: "0.09",
    name: "FIRST PACE",
    website: "http://www.firstpacecu.com",
  },
  "64103": {
    loansProduced: "8.04",
    membersServed: "2,031",
    interestEarned: "0.68",
    name: "FORRIT",
    website: "https://www.forritcu.org",
  },
  "64108": {
    loansProduced: "0.36",
    membersServed: "23",
    interestEarned: "0.03",
    name: "FOUNTAIN VALLEY",
    website: null,
  },
  "64110": {
    loansProduced: "26.76",
    membersServed: "2,036",
    interestEarned: "2.28",
    name: "ARAPAHOE",
    website: "http://www.arapahoecu.org",
  },
  "64122": {
    loansProduced: "12.95",
    membersServed: "1,042",
    interestEarned: "1.10",
    name: "VALLEY OAK",
    website: "http://www.valleyoak.org",
  },
  "64131": {
    loansProduced: "1,194.42",
    membersServed: "111,863",
    interestEarned: "101.53",
    name: "PENNSYLVANIA STATE EMPLOYEES",
    website: "http://www.psecu.com",
  },
  "64140": {
    loansProduced: "12.49",
    membersServed: "1,042",
    interestEarned: "1.06",
    name: "PEOPLES",
    website: "http://www.peoples-credit-union.com",
  },
  "64144": {
    loansProduced: "3.04",
    membersServed: "505",
    interestEarned: "0.26",
    name: "WORCESTER",
    website: "http://www.worcestercu.com",
  },
  "64145": {
    loansProduced: "12.18",
    membersServed: "836",
    interestEarned: "1.04",
    name: "POSTAL & COMMUNITY",
    website: "http://www.yourpccu.org",
  },
  "64166": {
    loansProduced: "101.29",
    membersServed: "9,222",
    interestEarned: "8.61",
    name: "MARION AND POLK SCHOOLS",
    website: "http://www.mapscu.com",
  },
  "64168": {
    loansProduced: "16.38",
    membersServed: "14,934",
    interestEarned: "1.39",
    name: "HEARTLAND",
    website: "http://www.heartlandcu.com",
  },
  "64169": {
    loansProduced: "11.87",
    membersServed: "3,620",
    interestEarned: "1.01",
    name: "VALLEY",
    website: "https://www.valleycu.org/",
  },
  "64171": {
    loansProduced: "48.64",
    membersServed: "6,579",
    interestEarned: "4.13",
    name: "UNIVERSITY",
    website: "https://www.ucu.org",
  },
  "64186": {
    loansProduced: "1.21",
    membersServed: "128",
    interestEarned: "0.10",
    name: "AUSTIN CITY EMPLOYEES",
    website: "http://www.austincityecu.org",
  },
  "64191": {
    loansProduced: "21.41",
    membersServed: "1,563",
    interestEarned: "1.82",
    name: "NORTH IOWA COMMUNITY",
    website: "http://WWW.NICCU.COM",
  },
  "64196": {
    loansProduced: "2.62",
    membersServed: "13,552",
    interestEarned: "0.22",
    name: "CENTRAL COMMUNICATIONS",
    website: "http://www.centralccu.com",
  },
  "64199": {
    loansProduced: "15.94",
    membersServed: "34,474",
    interestEarned: "1.35",
    name: "CONSERVATION EMPLOYEES",
    website: "http://www.cecuonline.org",
  },
  "64203": {
    loansProduced: "104.77",
    membersServed: "8,418",
    interestEarned: "8.91",
    name: "GREATER IOWA",
    website: "https://www.gicu.org",
  },
  "64208": {
    loansProduced: "1.95",
    membersServed: "276",
    interestEarned: "0.17",
    name: "LOVERS LANE",
    website: null,
  },
  "64212": {
    loansProduced: "2.07",
    membersServed: "245",
    interestEarned: "0.18",
    name: "MERIDIAN",
    website: "http://www.mymeridiancu.org",
  },
  "64222": {
    loansProduced: "1.16",
    membersServed: "86",
    interestEarned: "0.10",
    name: "SOUTH CENTRAL MISSOURI",
    website: "http://www.creditunionmo.com",
  },
  "64231": {
    loansProduced: "6.67",
    membersServed: "631",
    interestEarned: "0.57",
    name: "FIRST CLASS COMMUNITY",
    website: "http://www.gofirstclass.org",
  },
  "64234": {
    loansProduced: "9.94",
    membersServed: "806",
    interestEarned: "0.85",
    name: "LENNOX EMPLOYEES",
    website: "http://www.lennoxecu.com",
  },
  "64240": {
    loansProduced: "0.40",
    membersServed: "41",
    interestEarned: "0.03",
    name: "FLASHER COMMUNITY",
    website: "http://flashercreditunion.org",
  },
  "64242": {
    loansProduced: "1.91",
    membersServed: "142",
    interestEarned: "0.16",
    name: "PARIS HI-WAY",
    website: null,
  },
  "64252": {
    loansProduced: "0.03",
    membersServed: "7",
    interestEarned: "0.00",
    name: "FELLOWSHIP BAPTIST CHURCH",
    website: "fellowshipchicagocu.com",
  },
  "64267": {
    loansProduced: "1.37",
    membersServed: "154",
    interestEarned: "0.12",
    name: "ELECTRICAL WORKERS LOCAL 58",
    website: "http://www.ewcu.net",
  },
  "64269": {
    loansProduced: "0.37",
    membersServed: "50",
    interestEarned: "0.03",
    name: "S E C U",
    website: null,
  },
  "64271": {
    loansProduced: "24.89",
    membersServed: "4,128",
    interestEarned: "2.12",
    name: "PREMIER",
    website: "http://www.premiercu.org",
  },
  "64275": {
    loansProduced: "7.43",
    membersServed: "2,072",
    interestEarned: "0.63",
    name: "PUBLIC SERVICE #3",
    website: "http://www.mypscu.com",
  },
  "64281": {
    loansProduced: "16.38",
    membersServed: "1,200",
    interestEarned: "1.39",
    name: "CHEROKEE STRIP",
    website: "http://www.cherokeestrip.com",
  },
  "64282": {
    loansProduced: "1.29",
    membersServed: "154",
    interestEarned: "0.11",
    name: "NORTH WESTERN EMPLOYEES",
    website: "https://www.nwecu.org",
  },
  "64283": {
    loansProduced: "60.98",
    membersServed: "6,305",
    interestEarned: "5.18",
    name: "RIVER REGION",
    website: "http://www.rrcu.org",
  },
  "64294": {
    loansProduced: "2.86",
    membersServed: "249",
    interestEarned: "0.24",
    name: "AREA COMMUNITY",
    website: "http://www.gfaccu.com",
  },
  "64304": {
    loansProduced: "1.66",
    membersServed: "103",
    interestEarned: "0.14",
    name: "ALEXANDRIA SCHOOLS EMPLOYEES",
    website: "http://www.alexcreditunion.org",
  },
  "64323": {
    loansProduced: "0.50",
    membersServed: "49",
    interestEarned: "0.04",
    name: "ACE",
    website: null,
  },
  "64332": {
    loansProduced: "31.17",
    membersServed: "3,546",
    interestEarned: "2.65",
    name: "ALPINE",
    website: "http://www.alpinecu.com",
  },
  "64340": {
    loansProduced: "1.33",
    membersServed: "178",
    interestEarned: "0.11",
    name: "ONE THIRTEEN",
    website: "http://www.113creditunion.com",
  },
  "64342": {
    loansProduced: "92.38",
    membersServed: "10,132",
    interestEarned: "7.85",
    name: "CREDIT UNION OF DENVER",
    website: "http://www.cudenver.com",
  },
  "64359": {
    loansProduced: "1.18",
    membersServed: "69",
    interestEarned: "0.10",
    name: "DULUTH POLICE DEPARTMENT EMPLOYEES",
    website: null,
  },
  "64361": {
    loansProduced: "7.35",
    membersServed: "749",
    interestEarned: "0.63",
    name: "JOURNEY",
    website: "http://www.journeycu.org",
  },
  "64380": {
    loansProduced: "4.65",
    membersServed: "855",
    interestEarned: "0.40",
    name: "DES MOINES METRO",
    website: "http://www.dmmcu.org",
  },
  "64390": {
    loansProduced: "2.10",
    membersServed: "160",
    interestEarned: "0.18",
    name: "PUBLIC EMPLOYEES",
    website: "http://www.publicemployeescu.com",
  },
  "64394": {
    loansProduced: "4.25",
    membersServed: "299",
    interestEarned: "0.36",
    name: "SUMMIT RIDGE",
    website: "http://mysrcu.com",
  },
  "64397": {
    loansProduced: "0.42",
    membersServed: "32",
    interestEarned: "0.04",
    name: "FEDCO",
    website: null,
  },
  "64408": {
    loansProduced: "2.96",
    membersServed: "286",
    interestEarned: "0.25",
    name: "VUE COMMUNITY",
    website: "http://www.vueccu.com",
  },
  "64412": {
    loansProduced: "17.46",
    membersServed: "1,870",
    interestEarned: "1.48",
    name: "FIRST AMERICAN",
    website: "http://www.firstamerican.org",
  },
  "64417": {
    loansProduced: "4.44",
    membersServed: "440",
    interestEarned: "0.38",
    name: "SIOUX VALLEY COMMUNITY",
    website: "http://www.siouxvalleyccu.com",
  },
  "64418": {
    loansProduced: "1,000.69",
    membersServed: "77,174",
    interestEarned: "85.06",
    name: "VERIDIAN",
    website: "http://Veridiancu.org",
  },
  "64421": {
    loansProduced: "4.56",
    membersServed: "480",
    interestEarned: "0.39",
    name: "WHITE RIVER",
    website: "http://www.whitercu.com",
  },
  "64425": {
    loansProduced: "2.18",
    membersServed: "163",
    interestEarned: "0.19",
    name: "ST. LOUIS POLICEMEN`S",
    website: "http://www.stlpolicecu.com",
  },
  "64441": {
    loansProduced: "92.90",
    membersServed: "30,636",
    interestEarned: "7.90",
    name: "HEARTLAND",
    website: "http://www.hcu.org",
  },
  "64462": {
    loansProduced: "0.86",
    membersServed: "115",
    interestEarned: "0.07",
    name: "NORTHEAST SCHOOLS AND HOSPITAL",
    website: "http://nshcu.virtualcu.net",
  },
  "64464": {
    loansProduced: "1.77",
    membersServed: "225",
    interestEarned: "0.15",
    name: "TUSCALOOSA COUNTY",
    website: "http://www.tuscaloosacountycu.com",
  },
  "64471": {
    loansProduced: "121.66",
    membersServed: "9,000",
    interestEarned: "10.34",
    name: "DU TRAC COMMUNITY",
    website: "http://WWW.DUTRAC.ORG",
  },
  "64482": {
    loansProduced: "78.98",
    membersServed: "4,343",
    interestEarned: "6.71",
    name: "UNCLE",
    website: "http://WWW.UNCLECU.ORG",
  },
  "64503": {
    loansProduced: "3.91",
    membersServed: "820",
    interestEarned: "0.33",
    name: "DOW GREAT WESTERN",
    website: "http://www.dowcu.org",
  },
  "64518": {
    loansProduced: "1.48",
    membersServed: "159",
    interestEarned: "0.13",
    name: "MOWER COUNTY EMPLOYEES",
    website: "http://www.mowercountyecu.org",
  },
  "64528": {
    loansProduced: "2.31",
    membersServed: "388",
    interestEarned: "0.20",
    name: "BIRMINGHAM CITY",
    website: "http://www.bhamcu.org",
  },
  "64531": {
    loansProduced: "14.46",
    membersServed: "1,472",
    interestEarned: "1.23",
    name: "ALTA VISTA",
    website: "http://www.altavistacu.org",
  },
  "64568": {
    loansProduced: "0.86",
    membersServed: "110",
    interestEarned: "0.07",
    name: "URBANA MUNICIPAL EMPLOYEES",
    website: "http://umecu-urbana.com",
  },
  "64594": {
    loansProduced: "1.06",
    membersServed: "144",
    interestEarned: "0.09",
    name: "FIREMAN'S",
    website: null,
  },
  "64598": {
    loansProduced: "60.64",
    membersServed: "5,219",
    interestEarned: "5.15",
    name: "ALABAMA STATE EMPLOYEES",
    website: "https://www.yourasecu.com",
  },
  "64603": {
    loansProduced: "2.70",
    membersServed: "281",
    interestEarned: "0.23",
    name: "ALABAMA LAW ENFORCEMENT CREDIT UNIO",
    website: "http://www.allecu.org",
  },
  "64606": {
    loansProduced: "16.42",
    membersServed: "2,033",
    interestEarned: "1.40",
    name: "LEHIGH VALLEY EDUCATORS",
    website: "http://www.lvecu.org",
  },
  "64621": {
    loansProduced: "2.83",
    membersServed: "191",
    interestEarned: "0.24",
    name: "CAPE REGIONAL",
    website: "http://www.CAPEREGIONALCU.com",
  },
  "64644": {
    loansProduced: "1.21",
    membersServed: "98",
    interestEarned: "0.10",
    name: "BLUE FLAME",
    website: "http://blueflamecreditunion.org",
  },
  "64645": {
    loansProduced: "0.70",
    membersServed: "152",
    interestEarned: "0.06",
    name: "ALABAMA POSTAL",
    website: "https://alabamapostalcu.com",
  },
  "64655": {
    loansProduced: "11.50",
    membersServed: "28,830",
    interestEarned: "0.98",
    name: "NORTHERN COMMUNITIES",
    website: "http://nccyou.com",
  },
  "64660": {
    loansProduced: "2.27",
    membersServed: "156",
    interestEarned: "0.19",
    name: "GREATER VALLEY",
    website: "https://greatervalleycu.org",
  },
  "64688": {
    loansProduced: "0.10",
    membersServed: "7",
    interestEarned: "0.01",
    name: "LISBON FARMERS UNION",
    website: null,
  },
  "64690": {
    loansProduced: "4.64",
    membersServed: "928",
    interestEarned: "0.39",
    name: "HOLY ROSARY",
    website: "http://www.HolyRosaryCU.org",
  },
  "64691": {
    loansProduced: "8.78",
    membersServed: "25,217",
    interestEarned: "0.75",
    name: "JOPLIN METRO",
    website: "http://www.joplinmcu.com",
  },
  "64703": {
    loansProduced: "7.88",
    membersServed: "761",
    interestEarned: "0.67",
    name: "FEDERATED EMPLOYEES",
    website: "http://www.fedecu.com",
  },
  "64706": {
    loansProduced: "7.38",
    membersServed: "1,024",
    interestEarned: "0.63",
    name: "STAR CHOICE",
    website: "http://www.starchoicecu.org",
  },
  "64708": {
    loansProduced: "2.30",
    membersServed: "175",
    interestEarned: "0.20",
    name: "LEGACY",
    website: "http://www.yourlcu.com",
  },
  "64709": {
    loansProduced: "0.39",
    membersServed: "52",
    interestEarned: "0.03",
    name: "NORTHEAST REGIONAL",
    website: "http://www.mynrcu.com",
  },
  "64718": {
    loansProduced: "1.07",
    membersServed: "83",
    interestEarned: "0.09",
    name: "MISSOURI BAPTIST",
    website: "https://mobaptistcu.org",
  },
  "64723": {
    loansProduced: "0.23",
    membersServed: "17",
    interestEarned: "0.02",
    name: "CO - LIB",
    website: null,
  },
  "64731": {
    loansProduced: "0.23",
    membersServed: "20",
    interestEarned: "0.02",
    name: "SMARTCHOICE",
    website: null,
  },
  "64746": {
    loansProduced: "1.21",
    membersServed: "121",
    interestEarned: "0.10",
    name: "COMMUNITY FIRST",
    website: "http://mycommunityfirstcu.com",
  },
  "64755": {
    loansProduced: "1.76",
    membersServed: "5,364",
    interestEarned: "0.15",
    name: "DIVISION #6 HIGHWAY",
    website: "http://www.d6hcu.com",
  },
  "64759": {
    loansProduced: "155.51",
    membersServed: "10,760",
    interestEarned: "13.22",
    name: "DEL NORTE",
    website: "www.dncu.com",
  },
  "64762": {
    loansProduced: "1.47",
    membersServed: "35",
    interestEarned: "0.12",
    name: "CATHOLIC FAMILY",
    website: "http://www.catholicfamilycu.com",
  },
  "64766": {
    loansProduced: "30.30",
    membersServed: "2,176",
    interestEarned: "2.58",
    name: "MERCO",
    website: "http://www.merco.org",
  },
  "64777": {
    loansProduced: "0.29",
    membersServed: "31",
    interestEarned: "0.02",
    name: "BLUESCOPE EMPLOYEES'",
    website: null,
  },
  "64778": {
    loansProduced: "629.50",
    membersServed: "54,416",
    interestEarned: "53.51",
    name: "CANVAS",
    website: "https://www.canvas.org/",
  },
  "64782": {
    loansProduced: "1.53",
    membersServed: "163",
    interestEarned: "0.13",
    name: "ORLEX GOVERNMENT EMPLOYEES",
    website: "http://www.orlex.com",
  },
  "64825": {
    loansProduced: "1.18",
    membersServed: "79",
    interestEarned: "0.10",
    name: "DECA",
    website: null,
  },
  "64833": {
    loansProduced: "0.97",
    membersServed: "101",
    interestEarned: "0.08",
    name: "HAZLETON SCHOOL EMPLOYEES",
    website: "https://www.financial-net.com/hazle",
  },
  "64834": {
    loansProduced: "0.14",
    membersServed: "8",
    interestEarned: "0.01",
    name: "CALIFORNIA LITHUANIAN",
    website: "http://www.clcu.org",
  },
  "64835": {
    loansProduced: "13.47",
    membersServed: "5,399",
    interestEarned: "1.14",
    name: "PACIFIC POSTAL",
    website: "http://www.ppcu.org",
  },
  "64850": {
    loansProduced: "8.49",
    membersServed: "3,085",
    interestEarned: "0.72",
    name: "CREDIT UNION ONE OF OKLAHOMA",
    website: "http://www.cuoneok.org",
  },
  "64852": {
    loansProduced: "3.44",
    membersServed: "389",
    interestEarned: "0.29",
    name: "FELLOWSHIP",
    website: "http://www.fellowshipcreditunion.co",
  },
  "64856": {
    loansProduced: "2.03",
    membersServed: "158",
    interestEarned: "0.17",
    name: "PATRIOT",
    website: "http://www.patriotcu.com",
  },
  "64859": {
    loansProduced: "0.33",
    membersServed: "62",
    interestEarned: "0.03",
    name: "NEOSHO SCHOOL EMPLOYEES",
    website: "http://neoshosecu.us",
  },
  "64868": {
    loansProduced: "2.00",
    membersServed: "339",
    interestEarned: "0.17",
    name: "CASEBINE COMMUNITY",
    website: "http://www.casebine.com",
  },
  "64878": {
    loansProduced: "3.66",
    membersServed: "371",
    interestEarned: "0.31",
    name: "5 STAR COMMUNITY",
    website: "http://www.5starcu.org",
  },
  "64880": {
    loansProduced: "176.74",
    membersServed: "18,101",
    interestEarned: "15.02",
    name: "COMMUNITY CHOICE",
    website: "http://www.communitychoicecu.com",
  },
  "64882": {
    loansProduced: "2.65",
    membersServed: "501",
    interestEarned: "0.23",
    name: "POSTAL EMPLOYEES",
    website: "http://www.pecu24.org",
  },
  "64883": {
    loansProduced: "5.94",
    membersServed: "656",
    interestEarned: "0.51",
    name: "MOONLIGHT CREDIT UNION",
    website: "http://www.moonlightcu.com",
  },
  "64884": {
    loansProduced: "9.05",
    membersServed: "970",
    interestEarned: "0.77",
    name: "GOLD",
    website: "www.goldcu.org",
  },
  "64886": {
    loansProduced: "13.13",
    membersServed: "2,029",
    interestEarned: "1.12",
    name: "AIM",
    website: "http://www.aimcreditunion.com",
  },
  "64892": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "JONES METHODIST CHURCH",
    website: null,
  },
  "64893": {
    loansProduced: "7.74",
    membersServed: "721",
    interestEarned: "0.66",
    name: "UNIVERSAL CITY STUDIOS",
    website: "http://WWW.UCSCU.ORG",
  },
  "64896": {
    loansProduced: "39.92",
    membersServed: "6,282",
    interestEarned: "3.39",
    name: "UTILITIES EMPLOYEES",
    website: "http://www.uecu.org",
  },
  "64913": {
    loansProduced: "26.46",
    membersServed: "10,985",
    interestEarned: "2.25",
    name: "ON TAP",
    website: "http://www.ontapcu.org",
  },
  "64917": {
    loansProduced: "0.41",
    membersServed: "36",
    interestEarned: "0.03",
    name: "DUBUQUE POSTAL EMPLOYEES",
    website: "http://dpecu.com",
  },
  "64920": {
    loansProduced: "29.17",
    membersServed: "3,299",
    interestEarned: "2.48",
    name: "ILLINOIS STATE",
    website: "http://www.itsmycreditunion.org",
  },
  "64932": {
    loansProduced: "12.59",
    membersServed: "5,013",
    interestEarned: "1.07",
    name: "HIDDEN RIVER",
    website: "http://www.hdriver.org",
  },
  "64938": {
    loansProduced: "0.15",
    membersServed: "14",
    interestEarned: "0.01",
    name: "OUR FAMILY SOCIAL",
    website: null,
  },
  "64939": {
    loansProduced: "1.40",
    membersServed: "19,757",
    interestEarned: "0.12",
    name: "K.C. AREA",
    website: "http://www.kcareacu.com",
  },
  "64942": {
    loansProduced: "19.17",
    membersServed: "1,354",
    interestEarned: "1.63",
    name: "FAMILY HORIZONS",
    website: "http://www.familyhorizons.com",
  },
  "64943": {
    loansProduced: "0.67",
    membersServed: "36",
    interestEarned: "0.06",
    name: "DES MOINES FIRE DEPARTMENT",
    website: null,
  },
  "64948": {
    loansProduced: "2.13",
    membersServed: "23,484",
    interestEarned: "0.18",
    name: "LINXUS",
    website: "http://www.linxuscu.org",
  },
  "64967": {
    loansProduced: "1.17",
    membersServed: "134",
    interestEarned: "0.10",
    name: "A.S.H. EMPLOYEES",
    website: null,
  },
  "64970": {
    loansProduced: "53.92",
    membersServed: "4,579",
    interestEarned: "4.58",
    name: "UNIVERSAL 1",
    website: "http://www.universal1cu.org",
  },
  "64986": {
    loansProduced: "4.38",
    membersServed: "467",
    interestEarned: "0.37",
    name: "RIVER COMMUNITY",
    website: "http://www.rivercommunitycu.org",
  },
  "64991": {
    loansProduced: "1.47",
    membersServed: "161",
    interestEarned: "0.13",
    name: "QUAKER OATS",
    website: "http://www.quakeroatscu.com",
  },
  "64999": {
    loansProduced: "1.01",
    membersServed: "76",
    interestEarned: "0.09",
    name: "METROPOLITAN SERVICES",
    website: "http://www.mymscu.org",
  },
  "65003": {
    loansProduced: "7.91",
    membersServed: "8,763",
    interestEarned: "0.67",
    name: "GAS AND ELECTRIC",
    website: "http://www.gaselectriccu.com",
  },
  "65013": {
    loansProduced: "7.62",
    membersServed: "1,222",
    interestEarned: "0.65",
    name: "HEALTHCARE FIRST CREDIT UNION",
    website: "http://www.healthcarefirstcu.com",
  },
  "65032": {
    loansProduced: "102.68",
    membersServed: "10,067",
    interestEarned: "8.73",
    name: "OKLAHOMA'S",
    website: "http://www.okcu.org",
  },
  "65039": {
    loansProduced: "1.27",
    membersServed: "99",
    interestEarned: "0.11",
    name: "POLK COUNTY",
    website: "http://www.polkcountycreditunion.co",
  },
  "65040": {
    loansProduced: "0.72",
    membersServed: "44",
    interestEarned: "0.06",
    name: "GAS & ELECTRIC EMPLOYEES",
    website: null,
  },
  "65046": {
    loansProduced: "2.56",
    membersServed: "35,086",
    interestEarned: "0.22",
    name: "SIUE",
    website: "http://www.siuecu.org",
  },
  "65059": {
    loansProduced: "2.73",
    membersServed: "279",
    interestEarned: "0.23",
    name: "NIKKEI",
    website: "http://www.nikkeicu.org",
  },
  "65062": {
    loansProduced: "0.08",
    membersServed: "6",
    interestEarned: "0.01",
    name: "ST. LUDMILA S",
    website: null,
  },
  "65063": {
    loansProduced: "22.28",
    membersServed: "2,081",
    interestEarned: "1.89",
    name: "NOVATION",
    website: "http://www.Novation.org",
  },
  "65071": {
    loansProduced: "1.09",
    membersServed: "191",
    interestEarned: "0.09",
    name: "UNITE",
    website: "http://www.unite-cu.org",
  },
  "65078": {
    loansProduced: "72.68",
    membersServed: "9,587",
    interestEarned: "6.18",
    name: "COMMUNITY CHOICE",
    website: "http://comchoicecu.org",
  },
  "65082": {
    loansProduced: "18.43",
    membersServed: "2,115",
    interestEarned: "1.57",
    name: "SUNWEST",
    website: "http://sunwestecu.com",
  },
  "65085": {
    loansProduced: "1.10",
    membersServed: "123",
    interestEarned: "0.09",
    name: "LAKE SUPERIOR",
    website: "http://www.lscu.virtualcu.net",
  },
  "65088": {
    loansProduced: "21.33",
    membersServed: "3,104",
    interestEarned: "1.81",
    name: "HOLY ROSARY",
    website: "http://www.hrcu.org",
  },
  "65090": {
    loansProduced: "1.22",
    membersServed: "126",
    interestEarned: "0.10",
    name: "CENTRAL VERMONT MEDICAL CENTER,INC.",
    website: "https://www.cvmccu.org",
  },
  "65091": {
    loansProduced: "0.08",
    membersServed: "7",
    interestEarned: "0.01",
    name: "ST. PATRICK S PARISH",
    website: null,
  },
  "65111": {
    loansProduced: "0.81",
    membersServed: "144",
    interestEarned: "0.07",
    name: "OPTIONS",
    website: "http://www.optionscreditunion.com",
  },
  "65136": {
    loansProduced: "0.13",
    membersServed: "10",
    interestEarned: "0.01",
    name: "ST. ATHANASIUS",
    website: null,
  },
  "65150": {
    loansProduced: "10.28",
    membersServed: "1,136",
    interestEarned: "0.87",
    name: "SERVE",
    website: "http://www.servecu.org",
  },
  "65155": {
    loansProduced: "1.66",
    membersServed: "90",
    interestEarned: "0.14",
    name: "UNITED ASSOCIATION",
    website: "http://UNITEDASSOCIATIONCU.ORG",
  },
  "65168": {
    loansProduced: "4.47",
    membersServed: "516",
    interestEarned: "0.38",
    name: "TOWN AND COUNTRY",
    website: "http://www.towncountrycu.com",
  },
  "65186": {
    loansProduced: "3.09",
    membersServed: "350",
    interestEarned: "0.26",
    name: "CENTRAL ILLINOIS",
    website: "http://www.cicu.com",
  },
  "65193": {
    loansProduced: "0.53",
    membersServed: "40",
    interestEarned: "0.04",
    name: "WATERLOO FIREMEN'S",
    website: null,
  },
  "65205": {
    loansProduced: "2.86",
    membersServed: "255",
    interestEarned: "0.24",
    name: "CINCINNATI EMPLOYEES",
    website: "http://www.cintiecu.org",
  },
  "65208": {
    loansProduced: "24.63",
    membersServed: "3,518",
    interestEarned: "2.09",
    name: "NATCO",
    website: "http://www.natcocu.org",
  },
  "65211": {
    loansProduced: "1.03",
    membersServed: "75",
    interestEarned: "0.09",
    name: "WILLIAMSPORT TEACHERS",
    website: "http://www.williamsportteacherscu.c",
  },
  "65231": {
    loansProduced: "0.00",
    membersServed: "2",
    interestEarned: "0.00",
    name: "PILGRIM BAPTIST",
    website: null,
  },
  "65238": {
    loansProduced: "12.94",
    membersServed: "977",
    interestEarned: "1.10",
    name: "CENT",
    website: "http://www.centcu.org",
  },
  "65239": {
    loansProduced: "1.14",
    membersServed: "106",
    interestEarned: "0.10",
    name: "BURLINGTON MUNICIPAL EMPLOYEES",
    website: "http://MYBMECU.COM",
  },
  "65242": {
    loansProduced: "1.29",
    membersServed: "154",
    interestEarned: "0.11",
    name: "INDUSTRIAL EMPLOYEES",
    website: "http://industrialemployeescu.com",
  },
  "65243": {
    loansProduced: "0.20",
    membersServed: "13",
    interestEarned: "0.02",
    name: "HOLY GHOST PARISH",
    website: null,
  },
  "65256": {
    loansProduced: "0.20",
    membersServed: "17",
    interestEarned: "0.02",
    name: "SPRINGFIELD FIREFIGHTERS",
    website: null,
  },
  "65259": {
    loansProduced: "1.02",
    membersServed: "100",
    interestEarned: "0.09",
    name: "STATE EMPLOYEES COMMUNITY",
    website: "https://stateemployeescu.virtualcu.net/",
  },
  "65291": {
    loansProduced: "66.61",
    membersServed: "4,473",
    interestEarned: "5.66",
    name: "ARMCO",
    website: "www.armcocu.com",
  },
  "65299": {
    loansProduced: "71.15",
    membersServed: "5,293",
    interestEarned: "6.05",
    name: "KEYPOINT",
    website: "https://www.kpcu.com",
  },
  "65305": {
    loansProduced: "18.80",
    membersServed: "2,595",
    interestEarned: "1.60",
    name: "ILLINOIS EDUCATORS",
    website: "http://www.iecumember.org",
  },
  "65323": {
    loansProduced: "5.02",
    membersServed: "1,100",
    interestEarned: "0.43",
    name: "UPWARD",
    website: "https://www.upwardcu.org",
  },
  "65346": {
    loansProduced: "0.42",
    membersServed: "41",
    interestEarned: "0.04",
    name: "SALEM SCHOOL SYSTEM",
    website: "http://www.salemschoolscreditunion.",
  },
  "65356": {
    loansProduced: "4.47",
    membersServed: "891",
    interestEarned: "0.38",
    name: "FRANKLIN-OIL REGION CREDIT UNION",
    website: "https://www.for-cu.com/",
  },
  "65371": {
    loansProduced: "5.05",
    membersServed: "408",
    interestEarned: "0.43",
    name: "MCPHERSON CO-OP",
    website: "http://mcphersoncu.com",
  },
  "65378": {
    loansProduced: "86.72",
    membersServed: "6,182",
    interestEarned: "7.37",
    name: "MID AMERICAN",
    website: "http://www.midamerican.coop",
  },
  "65384": {
    loansProduced: "2.75",
    membersServed: "274",
    interestEarned: "0.23",
    name: "TCP",
    website: "http://tcpcu.org",
  },
  "65388": {
    loansProduced: "18.82",
    membersServed: "1,191",
    interestEarned: "1.60",
    name: "VOCALITY COMMUNITY",
    website: "http://www.vocalityccu.org",
  },
  "65398": {
    loansProduced: "20.68",
    membersServed: "2,106",
    interestEarned: "1.76",
    name: "FIRST NEBRASKA",
    website: "http://www.firstnebraska.org",
  },
  "65402": {
    loansProduced: "1.74",
    membersServed: "382",
    interestEarned: "0.15",
    name: "ALABAMA RIVER",
    website: "http://www.arivercu.com",
  },
  "65411": {
    loansProduced: "0.68",
    membersServed: "100",
    interestEarned: "0.06",
    name: "BUCKS COUNTY EMPLOYEES",
    website: null,
  },
  "65412": {
    loansProduced: "5.74",
    membersServed: "845",
    interestEarned: "0.49",
    name: "1ST ED CREDIT UNION",
    website: "http://www.1edcu.org",
  },
  "65421": {
    loansProduced: "50.58",
    membersServed: "40,914",
    interestEarned: "4.30",
    name: "HEALTHCARE ASSOCIATES",
    website: "https://www.hacu.org",
  },
  "65424": {
    loansProduced: "1.96",
    membersServed: "227",
    interestEarned: "0.17",
    name: "SPRINGFIELD CITY EMPLOYEES",
    website: "http://www.scecu.org",
  },
  "65426": {
    loansProduced: "4.95",
    membersServed: "18,299",
    interestEarned: "0.42",
    name: "WESTERN ILLINOIS SCHOOL EMPLOYEES",
    website: "http://WISECU.COM",
  },
  "65427": {
    loansProduced: "0.75",
    membersServed: "77",
    interestEarned: "0.06",
    name: "CARBONDALE HIGHWAY",
    website: null,
  },
  "65448": {
    loansProduced: "0.52",
    membersServed: "34",
    interestEarned: "0.04",
    name: "RIVERVIEW",
    website: "http://myrcu.com",
  },
  "65458": {
    loansProduced: "5.38",
    membersServed: "683",
    interestEarned: "0.46",
    name: "ENERGY PLUS",
    website: "http://www.energypluscu.org",
  },
  "65464": {
    loansProduced: "11.60",
    membersServed: "5,364",
    interestEarned: "0.99",
    name: "ECO",
    website: "http://www.ecocu.org",
  },
  "65466": {
    loansProduced: "1.06",
    membersServed: "120",
    interestEarned: "0.09",
    name: "MORTON",
    website: "http://MORTONCU.COM",
  },
  "65471": {
    loansProduced: "0.91",
    membersServed: "143",
    interestEarned: "0.08",
    name: "GUADALUPE PARISH",
    website: "http://www.guadalupeparishcu.org",
  },
  "65477": {
    loansProduced: "0.16",
    membersServed: "11",
    interestEarned: "0.01",
    name: "ST. ELIZABETH",
    website: null,
  },
  "65484": {
    loansProduced: "0.01",
    membersServed: "2",
    interestEarned: "0.00",
    name: "ST. JUDE",
    website: "http://www.stjudecreditunion.org",
  },
  "65491": {
    loansProduced: "13.97",
    membersServed: "1,174",
    interestEarned: "1.19",
    name: "MEMBERS FIRST CREDIT UNION OF N.H.",
    website: "http://www.membersfirstnh.org",
  },
  "65493": {
    loansProduced: "2.78",
    membersServed: "209",
    interestEarned: "0.24",
    name: "COMMUNITY SPIRIT CREDIT UNION",
    website: "http://www.communityspiritcu.org",
  },
  "65503": {
    loansProduced: "20.97",
    membersServed: "1,562",
    interestEarned: "1.78",
    name: "MUNICIPAL EMPS CU OF OKLAHOMA CITY",
    website: "http://WWW.MECUOKC.ORG",
  },
  "65513": {
    loansProduced: "124.81",
    membersServed: "7,378",
    interestEarned: "10.61",
    name: "STATE EMPLOYEES",
    website: "http://www.secunm.org",
  },
  "65521": {
    loansProduced: "4.29",
    membersServed: "430",
    interestEarned: "0.36",
    name: "AEGIS",
    website: "http://www.aegiscu.com",
  },
  "65528": {
    loansProduced: "5.49",
    membersServed: "422",
    interestEarned: "0.47",
    name: "NORTH PLATTE UNION PACIFIC EMPLOYEE",
    website: "http://www.upemployeescu.com",
  },
  "65555": {
    loansProduced: "1.44",
    membersServed: "82",
    interestEarned: "0.12",
    name: "JOLIET FIREFIGHTERS",
    website: null,
  },
  "65557": {
    loansProduced: "1.19",
    membersServed: "93",
    interestEarned: "0.10",
    name: "UNITED",
    website: "http://www.unitedcreditunion.org",
  },
  "65561": {
    loansProduced: "0.67",
    membersServed: "125",
    interestEarned: "0.06",
    name: "LINCOLN S.D.A.",
    website: "http://www.lincolnsdacu.org",
  },
  "65564": {
    loansProduced: "0.01",
    membersServed: "4",
    interestEarned: "0.00",
    name: "SAINT NORBERT'S",
    website: null,
  },
  "65565": {
    loansProduced: "11.74",
    membersServed: "1,679",
    interestEarned: "1.00",
    name: "CEDAR FALLS COMMUNITY",
    website: "http://www.cfccu.org",
  },
  "65578": {
    loansProduced: "6.86",
    membersServed: "1,020",
    interestEarned: "0.58",
    name: "UNITED SAVINGS",
    website: "http://www.unitedsavingscu.org",
  },
  "65595": {
    loansProduced: "120.69",
    membersServed: "14,519",
    interestEarned: "10.26",
    name: "AMERICAN 1",
    website: "http://www.american1cu.org",
  },
  "65606": {
    loansProduced: "0.87",
    membersServed: "91",
    interestEarned: "0.07",
    name: "TEAMSTERS LOCAL #238",
    website: "teamsterslocal238cu.org",
  },
  "65636": {
    loansProduced: "0.59",
    membersServed: "72",
    interestEarned: "0.05",
    name: "ST. MARY",
    website: null,
  },
  "65639": {
    loansProduced: "5.41",
    membersServed: "4,445",
    interestEarned: "0.46",
    name: "WEXFORD COMMUNITY",
    website: "http://www.wexccu.com",
  },
  "65640": {
    loansProduced: "1.97",
    membersServed: "40,395",
    interestEarned: "0.17",
    name: "HEIGHTS AUTO WORKERS",
    website: "http://www.hawcu.com",
  },
  "65644": {
    loansProduced: "82.09",
    membersServed: "12,004",
    interestEarned: "6.98",
    name: "RIVERMARK COMMUNITY",
    website: "http://www.rivermarkcu.org",
  },
  "65656": {
    loansProduced: "88.53",
    membersServed: "31,337",
    interestEarned: "7.52",
    name: "MISSOURI",
    website: "http://www.missouricu.org",
  },
  "65664": {
    loansProduced: "1.04",
    membersServed: "62",
    interestEarned: "0.09",
    name: "HIGH SIERRA",
    website: null,
  },
  "65680": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "BETHEL A.M.E. CHURCH",
    website: null,
  },
  "65712": {
    loansProduced: "8.46",
    membersServed: "25,978",
    interestEarned: "0.72",
    name: "GALESBURG BURLINGTON",
    website: "http://www.gbcu.net",
  },
  "65722": {
    loansProduced: "6.62",
    membersServed: "910",
    interestEarned: "0.56",
    name: "FREEDOM NORTHWEST",
    website: "http://fnwcu.org",
  },
  "65728": {
    loansProduced: "35.92",
    membersServed: "3,186",
    interestEarned: "3.05",
    name: "CONNECTICUT STATE EMPLOYEES",
    website: "http://CSECREDITUNION.COM",
  },
  "65735": {
    loansProduced: "24.98",
    membersServed: "40,237",
    interestEarned: "2.12",
    name: "CATHOLIC & COMMUNITY",
    website: "http://candccu.com",
  },
  "65759": {
    loansProduced: "0.60",
    membersServed: "38",
    interestEarned: "0.05",
    name: "B.E.A.",
    website: null,
  },
  "65770": {
    loansProduced: "1.72",
    membersServed: "233",
    interestEarned: "0.15",
    name: "SERVICE PLUS",
    website: "http://www.service-pluscu.org",
  },
  "65774": {
    loansProduced: "0.95",
    membersServed: "205",
    interestEarned: "0.08",
    name: "TEACHERS",
    website: "http://www.tcuok.org",
  },
  "65775": {
    loansProduced: "4.44",
    membersServed: "482",
    interestEarned: "0.38",
    name: "NORTHERN COLORADO",
    website: "www.nococu.org",
  },
  "65780": {
    loansProduced: "0.03",
    membersServed: "8",
    interestEarned: "0.00",
    name: "COGIC",
    website: null,
  },
  "65781": {
    loansProduced: "1.10",
    membersServed: "114",
    interestEarned: "0.09",
    name: "PIEDMONT",
    website: "http://www.piedmontcreditunionnc.co",
  },
  "65788": {
    loansProduced: "98.42",
    membersServed: "9,628",
    interestEarned: "8.37",
    name: "WESTERRA",
    website: "http://westerracu.com",
  },
  "65803": {
    loansProduced: "4.58",
    membersServed: "482",
    interestEarned: "0.39",
    name: "NORTHWEST HILLS",
    website: "https://www.nwhcu.com",
  },
  "65809": {
    loansProduced: "1.78",
    membersServed: "226",
    interestEarned: "0.15",
    name: "NEW HAVEN COUNTY",
    website: "http://www.nhccu.com",
  },
  "65816": {
    loansProduced: "2.46",
    membersServed: "275",
    interestEarned: "0.21",
    name: "NEBRASKA STATE EMPLOYEES",
    website: "http://www.nsecu.org",
  },
  "65817": {
    loansProduced: "2.15",
    membersServed: "187",
    interestEarned: "0.18",
    name: "METROPOLITAN DISTRICT EMPLOYEES",
    website: "http://www.mdecu.org",
  },
  "65840": {
    loansProduced: "0.45",
    membersServed: "51",
    interestEarned: "0.04",
    name: "I.B.E.W. LOCAL #146",
    website: "http://www.ibew146cu.com",
  },
  "65841": {
    loansProduced: "0.82",
    membersServed: "53",
    interestEarned: "0.07",
    name: "DECATUR POSTAL",
    website: "http://decaturpostalcreditunion.com",
  },
  "65842": {
    loansProduced: "0.90",
    membersServed: "155",
    interestEarned: "0.08",
    name: "DECATUR MEDICAL DENTAL",
    website: "http://dmdcu.virtualcu.net/",
  },
  "65843": {
    loansProduced: "3.47",
    membersServed: "40,355",
    interestEarned: "0.29",
    name: "MWRD EMPLOYEES",
    website: "http://mwrdecu.org",
  },
  "65856": {
    loansProduced: "488.38",
    membersServed: "48,987",
    interestEarned: "41.51",
    name: "OREGON COMMUNITY",
    website: "http://www.myoccu.org",
  },
  "65861": {
    loansProduced: "30.82",
    membersServed: "2,527",
    interestEarned: "2.62",
    name: "THE PEOPLES",
    website: "http://www.peoplescu.com",
  },
  "65862": {
    loansProduced: "0.64",
    membersServed: "63",
    interestEarned: "0.05",
    name: "NEW HAVEN FIREFIGHTERS",
    website: null,
  },
  "65874": {
    loansProduced: "0.37",
    membersServed: "47",
    interestEarned: "0.03",
    name: "TAYLORVILLE SCHOOL EMPLOYEES",
    website: null,
  },
  "65896": {
    loansProduced: "0.11",
    membersServed: "13",
    interestEarned: "0.01",
    name: "MEA",
    website: null,
  },
  "65905": {
    loansProduced: "0.84",
    membersServed: "63",
    interestEarned: "0.07",
    name: "PEORIA BELL",
    website: "http://www.peoriabell.org",
  },
  "65906": {
    loansProduced: "0.48",
    membersServed: "58",
    interestEarned: "0.04",
    name: "AURORA POSTAL EMPLOYEES",
    website: null,
  },
  "65932": {
    loansProduced: "8.17",
    membersServed: "2,327",
    interestEarned: "0.69",
    name: "CHICAGO MUNICIPAL EMPLOYEES",
    website: "http://www.cmecuonline.org",
  },
  "65954": {
    loansProduced: "1.12",
    membersServed: "86",
    interestEarned: "0.10",
    name: "NORWALK HOSPITAL",
    website: "http://www.nohocu.org",
  },
  "65955": {
    loansProduced: "5.02",
    membersServed: "383",
    interestEarned: "0.43",
    name: "ROCKFORD BELL",
    website: "http://WWW.RBCU.NET",
  },
  "65988": {
    loansProduced: "3.97",
    membersServed: "11,512",
    interestEarned: "0.34",
    name: "KASKASKIA VALLEY",
    website: "http://mykvccu.org",
  },
  "65995": {
    loansProduced: "0.55",
    membersServed: "42",
    interestEarned: "0.05",
    name: "MERCER",
    website: "https://mercercreditunion.com",
  },
  "66002": {
    loansProduced: "1.25",
    membersServed: "133",
    interestEarned: "0.11",
    name: "STAMFORD HEALTHCARE",
    website: "http://www.shccu.org",
  },
  "66012": {
    loansProduced: "6.18",
    membersServed: "751",
    interestEarned: "0.53",
    name: "COMMUNITY CHOICE",
    website: "http://www.yourbetterbankingchoice.",
  },
  "66036": {
    loansProduced: "0.10",
    membersServed: "6",
    interestEarned: "0.01",
    name: "KAN COLO",
    website: null,
  },
  "66044": {
    loansProduced: "0.38",
    membersServed: "27",
    interestEarned: "0.03",
    name: "REGIONAL WATER AUTHORITY EMPLOYEES",
    website: null,
  },
  "66057": {
    loansProduced: "1.72",
    membersServed: "160",
    interestEarned: "0.15",
    name: "NORTHEASTERN CT. HEALTH CARE",
    website: "http://www.necthealthcarecreditunion.org",
  },
  "66066": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "THE WAY",
    website: null,
  },
  "66089": {
    loansProduced: "0.00",
    membersServed: "3",
    interestEarned: "0.00",
    name: "BEREAN",
    website: null,
  },
  "66097": {
    loansProduced: "0.62",
    membersServed: "93",
    interestEarned: "0.05",
    name: "CUBA",
    website: "http://www.cubacreditunion.org",
  },
  "66116": {
    loansProduced: "14.67",
    membersServed: "1,782",
    interestEarned: "1.25",
    name: "TUCSON OLD PUEBLO",
    website: "http://www.topcu.org",
  },
  "66117": {
    loansProduced: "7.72",
    membersServed: "887",
    interestEarned: "0.66",
    name: "LOCO",
    website: "http://www.lococreditunion.com",
  },
  "66126": {
    loansProduced: "3.89",
    membersServed: "368",
    interestEarned: "0.33",
    name: "OMAHA FIREFIGHTERS",
    website: "http://omahafirefighterscu.org",
  },
  "66130": {
    loansProduced: "6.87",
    membersServed: "854",
    interestEarned: "0.58",
    name: "NEW JERSEY LAW AND PUBLIC SAFETY",
    website: "http://www.njlpscu.org",
  },
  "66143": {
    loansProduced: "3.13",
    membersServed: "233",
    interestEarned: "0.27",
    name: "CHAVES COUNTY SCHOOL EMPLOYEES",
    website: "http://www.ccsecunm.org",
  },
  "66149": {
    loansProduced: "25.93",
    membersServed: "4,614",
    interestEarned: "2.20",
    name: "GUADALUPE",
    website: "http://www.guadalupecu.org",
  },
  "66155": {
    loansProduced: "2.61",
    membersServed: "276",
    interestEarned: "0.22",
    name: "PEOPLES",
    website: "http://www.peoples-cu.com",
  },
  "66157": {
    loansProduced: "171.11",
    membersServed: "15,476",
    interestEarned: "14.54",
    name: "CREDIT UNION 1",
    website: "http://www.cu1.org",
  },
  "66158": {
    loansProduced: "186.57",
    membersServed: "20,469",
    interestEarned: "15.86",
    name: "TRUMARK FINANCIAL",
    website: "http://www.trumarkonline.org",
  },
  "66159": {
    loansProduced: "0.20",
    membersServed: "77",
    interestEarned: "0.02",
    name: "NEWARK POST OFFICE EMPLOYEES",
    website: null,
  },
  "66185": {
    loansProduced: "1.91",
    membersServed: "184",
    interestEarned: "0.16",
    name: "HIGH DESERT COMMUNITY",
    website: "http://www.highdesertcu.org",
  },
  "66188": {
    loansProduced: "0.03",
    membersServed: "11",
    interestEarned: "0.00",
    name: "PLAINFIELD",
    website: null,
  },
  "66207": {
    loansProduced: "2.29",
    membersServed: "257",
    interestEarned: "0.19",
    name: "NEW HAMPSHIRE POSTAL",
    website: "https://nhpcu.com",
  },
  "66237": {
    loansProduced: "2.13",
    membersServed: "144",
    interestEarned: "0.18",
    name: "HEALTH CARE IDAHO",
    website: "http://hcicu.com",
  },
  "66252": {
    loansProduced: "2.10",
    membersServed: "164",
    interestEarned: "0.18",
    name: "QUESTA",
    website: "www.questacreditunion.org",
  },
  "66253": {
    loansProduced: "0.42",
    membersServed: "38",
    interestEarned: "0.04",
    name: "FUNERAL SERVICE",
    website: "http://fscunet.org",
  },
  "66258": {
    loansProduced: "10.84",
    membersServed: "4,160",
    interestEarned: "0.92",
    name: "SELF-HELP",
    website: "http://www.self-help.org",
  },
  "66259": {
    loansProduced: "0.37",
    membersServed: "172",
    interestEarned: "0.03",
    name: "ORLEANS PARISH SHERIFF'S",
    website: null,
  },
  "66268": {
    loansProduced: "14.74",
    membersServed: "1,466",
    interestEarned: "1.25",
    name: "MEMBERSOWN",
    website: "http://www.membersowncu.org",
  },
  "66270": {
    loansProduced: "4.11",
    membersServed: "400",
    interestEarned: "0.35",
    name: "ARCHER COOPERATIVE",
    website: "http://www.archerccu.com",
  },
  "66274": {
    loansProduced: "0.09",
    membersServed: "7",
    interestEarned: "0.01",
    name: "EDDYVILLE COOPERATIVE",
    website: null,
  },
  "66296": {
    loansProduced: "0.04",
    membersServed: "11",
    interestEarned: "0.00",
    name: "ST. HELENA PARISH",
    website: "www.sthelenacreditunion.com",
  },
  "66299": {
    loansProduced: "2.83",
    membersServed: "312",
    interestEarned: "0.24",
    name: "K C K FIREMEN & POLICE",
    website: null,
  },
  "66300": {
    loansProduced: "0.00",
    membersServed: "4",
    interestEarned: "0.00",
    name: "IMPERIAL",
    website: "http://www.imperialcreditunion.org",
  },
  "66310": {
    loansProduced: "2,179.71",
    membersServed: "277,366",
    interestEarned: "185.28",
    name: "STATE EMPLOYEES",
    website: "http://www.ncsecu.org",
  },
  "66317": {
    loansProduced: "4.58",
    membersServed: "753",
    interestEarned: "0.39",
    name: "HEALTHSHARE",
    website: "http://www.healthsharecu.org",
  },
  "66319": {
    loansProduced: "7.39",
    membersServed: "846",
    interestEarned: "0.63",
    name: "TELCO",
    website: "http://www.telcu.com",
  },
  "66320": {
    loansProduced: "0.26",
    membersServed: "45",
    interestEarned: "0.02",
    name: "TEXAS GULF CAROLINA EMP",
    website: null,
  },
  "66324": {
    loansProduced: "4.40",
    membersServed: "581",
    interestEarned: "0.37",
    name: "WEYCO COMMUNITY",
    website: "http://weycocreditunion.com",
  },
  "66327": {
    loansProduced: "319.88",
    membersServed: "22,016",
    interestEarned: "27.19",
    name: "CREDIT UNION OF TEXAS",
    website: "http://cutx.org",
  },
  "66328": {
    loansProduced: "877.25",
    membersServed: "92,221",
    interestEarned: "74.57",
    name: "WRIGHT-PATT CREDIT UNION, INC.",
    website: "http://wpcu.coop",
  },
  "66329": {
    loansProduced: "1.41",
    membersServed: "207",
    interestEarned: "0.12",
    name: "L. E. O.",
    website: "http://www.leocu.org",
  },
  "66330": {
    loansProduced: "195.85",
    membersServed: "30,625",
    interestEarned: "16.65",
    name: "STATE EMPLOYEES CU OF MARYLAND, INC",
    website: "http://WWW.SECUMD.ORG",
  },
  "66331": {
    loansProduced: "284.33",
    membersServed: "22,708",
    interestEarned: "24.17",
    name: "SOUND",
    website: "https://www.soundcu.com",
  },
  "66333": {
    loansProduced: "6.27",
    membersServed: "752",
    interestEarned: "0.53",
    name: "DESTINATIONS CREDIT UNION",
    website: "http://www.destinationscu.org",
  },
  "66336": {
    loansProduced: "0.89",
    membersServed: "74",
    interestEarned: "0.08",
    name: "BILLERICA MUNICIPAL EMPLOYEES",
    website: "http://www.billericamecu.com",
  },
  "66337": {
    loansProduced: "66.74",
    membersServed: "5,330",
    interestEarned: "5.67",
    name: "INDUSTRIAL CU OF WHATCOM COUNTY",
    website: "http://www.industrialcu.org",
  },
  "66340": {
    loansProduced: "4.61",
    membersServed: "424",
    interestEarned: "0.39",
    name: "CENTRAL CREDIT UNION OF MARYLAND,IN",
    website: "http://www.ccumd.org",
  },
  "66343": {
    loansProduced: "112.22",
    membersServed: "13,834",
    interestEarned: "9.54",
    name: "PROVIDENT",
    website: "https://providentcu.org",
  },
  "66346": {
    loansProduced: "1.69",
    membersServed: "175",
    interestEarned: "0.14",
    name: "RIVER WORKS",
    website: "http://www.rwcu.com",
  },
  "66349": {
    loansProduced: "18.03",
    membersServed: "4,165",
    interestEarned: "1.53",
    name: "SALAL",
    website: "http://www.salalcu.org",
  },
  "66350": {
    loansProduced: "2.25",
    membersServed: "212",
    interestEarned: "0.19",
    name: "BROTHERHOOD",
    website: "http://www.brotherhoodcreditunion.o",
  },
  "66351": {
    loansProduced: "19.16",
    membersServed: "1,691",
    interestEarned: "1.63",
    name: "TELCO PLUS",
    website: "http://www.telcoplus.org",
  },
  "66353": {
    loansProduced: "84.54",
    membersServed: "8,862",
    interestEarned: "7.19",
    name: "BRAZOS VALLEY SCHOOLS",
    website: "https://www.bvscu.org",
  },
  "66354": {
    loansProduced: "5.69",
    membersServed: "479",
    interestEarned: "0.48",
    name: "EMERGENCY RESPONDERS",
    website: "http://www.emrspcu.com",
  },
  "66357": {
    loansProduced: "776.80",
    membersServed: "98,314",
    interestEarned: "66.03",
    name: "DELTA COMMUNITY",
    website: "https://www.deltacommunitycu.com",
  },
  "66359": {
    loansProduced: "31.70",
    membersServed: "3,244",
    interestEarned: "2.69",
    name: "FIRST NORTHERN",
    website: "http://www.fncu.org",
  },
  "66364": {
    loansProduced: "349.58",
    membersServed: "21,878",
    interestEarned: "29.71",
    name: "METRO",
    website: "http://www.metrocu.org",
  },
  "66365": {
    loansProduced: "17.90",
    membersServed: "3,446",
    interestEarned: "1.52",
    name: "ALIGN",
    website: "http://www.aligncu.com",
  },
  "66366": {
    loansProduced: "5.43",
    membersServed: "568",
    interestEarned: "0.46",
    name: "EDINBURG TEACHERS",
    website: "www.etcu.coop",
  },
  "66369": {
    loansProduced: "6.13",
    membersServed: "861",
    interestEarned: "0.52",
    name: "ENERGY",
    website: "https://www.energycreditunion.org",
  },
  "66370": {
    loansProduced: "232.29",
    membersServed: "33,840",
    interestEarned: "19.74",
    name: "SCOTT",
    website: "http://www.scu.org",
  },
  "66372": {
    loansProduced: "10.86",
    membersServed: "972",
    interestEarned: "0.92",
    name: "U. S. EMPLOYEES",
    website: "http://www.usecuweb.com",
  },
  "66373": {
    loansProduced: "223.70",
    membersServed: "23,317",
    interestEarned: "19.01",
    name: "WHATCOM EDUCATIONAL",
    website: "http://www.wecu.com",
  },
  "66374": {
    loansProduced: "6.58",
    membersServed: "1,872",
    interestEarned: "0.56",
    name: "SOUTHBRIDGE",
    website: "https://www.southbridgecu.com",
  },
  "66376": {
    loansProduced: "50.22",
    membersServed: "8,171",
    interestEarned: "4.27",
    name: "PATHWAYS FINANCIAL",
    website: "http://www.pathwayscu.com",
  },
  "66388": {
    loansProduced: "7.04",
    membersServed: "20,555",
    interestEarned: "0.60",
    name: "TAYLOR",
    website: "http://taylorcu.org",
  },
  "66393": {
    loansProduced: "0.45",
    membersServed: "103",
    interestEarned: "0.04",
    name: "NEKOOSA",
    website: "http://www.nekoosacu.com",
  },
  "66395": {
    loansProduced: "3.53",
    membersServed: "688",
    interestEarned: "0.30",
    name: "EVERGREEN",
    website: "http://www.evergreencu.com",
  },
  "66399": {
    loansProduced: "184.51",
    membersServed: "9,397",
    interestEarned: "15.68",
    name: "HARBORSTONE",
    website: "http://www.Harborstone.com",
  },
  "66406": {
    loansProduced: "0.75",
    membersServed: "90",
    interestEarned: "0.06",
    name: "LA CROSSE-BURLINGTON",
    website: "http://www.forlbcu.com",
  },
  "66410": {
    loansProduced: "1.40",
    membersServed: "112",
    interestEarned: "0.12",
    name: "KENOSHA POLICE AND FIREMEN'S",
    website: null,
  },
  "66418": {
    loansProduced: "0.95",
    membersServed: "112",
    interestEarned: "0.08",
    name: "OSHKOSH POSTAL EMPLOYEES",
    website: "http://www.opecu.com",
  },
  "66423": {
    loansProduced: "4.93",
    membersServed: "473",
    interestEarned: "0.42",
    name: "FIREFIGHTERS",
    website: "http://www.myfirecu.org",
  },
  "66424": {
    loansProduced: "18.69",
    membersServed: "2,656",
    interestEarned: "1.59",
    name: "PCM",
    website: "http://www.pcmcu.org",
  },
  "66430": {
    loansProduced: "12.43",
    membersServed: "1,105",
    interestEarned: "1.06",
    name: "VALLEY COMMUNITIES",
    website: "http://valleycommunities.org",
  },
  "66431": {
    loansProduced: "4.30",
    membersServed: "22,695",
    interestEarned: "0.37",
    name: "MADISON",
    website: "http://www.madisoncu.com",
  },
  "66434": {
    loansProduced: "6.75",
    membersServed: "448",
    interestEarned: "0.57",
    name: "INDIANHEAD",
    website: "http://www.indianheadcu.org",
  },
  "66437": {
    loansProduced: "5.75",
    membersServed: "857",
    interestEarned: "0.49",
    name: "WINNEBAGO COMMUNITY",
    website: "http://www.wincu.org",
  },
  "66439": {
    loansProduced: "9.08",
    membersServed: "1,274",
    interestEarned: "0.77",
    name: "SENTRY",
    website: "http://www.sentrycu.org",
  },
  "66444": {
    loansProduced: "10.79",
    membersServed: "797",
    interestEarned: "0.92",
    name: "SHIPBUILDERS",
    website: "http://www.shipbuilderscu.com",
  },
  "66447": {
    loansProduced: "8.51",
    membersServed: "1,403",
    interestEarned: "0.72",
    name: "SHORELINE",
    website: "http://www.shorelinecu.org",
  },
  "66448": {
    loansProduced: "209.74",
    membersServed: "32,014",
    interestEarned: "17.83",
    name: "EDUCATORS",
    website: "http://www.ecu.com",
  },
  "66449": {
    loansProduced: "22.04",
    membersServed: "2,987",
    interestEarned: "1.87",
    name: "PRIME FINANCIAL",
    website: "http://www.primefinancialcu.org",
  },
  "66451": {
    loansProduced: "11.53",
    membersServed: "1,208",
    interestEarned: "0.98",
    name: "FOCUS",
    website: "http://www.focus-cu.com",
  },
  "66456": {
    loansProduced: "2.02",
    membersServed: "290",
    interestEarned: "0.17",
    name: "COUNTY - CITY",
    website: "http://www.countycitycreditunion.com",
  },
  "66461": {
    loansProduced: "0.96",
    membersServed: "83",
    interestEarned: "0.08",
    name: "PUBLIC SERVICE",
    website: "http://pscu-wausau.com",
  },
  "66466": {
    loansProduced: "3.76",
    membersServed: "573",
    interestEarned: "0.32",
    name: "MARATHON COUNTY EMPLOYEES",
    website: "https://WWW.MCECU.ORG",
  },
  "66476": {
    loansProduced: "1.15",
    membersServed: "90",
    interestEarned: "0.10",
    name: "HOLY FAMILY MEMORIAL",
    website: "http://hfmcu.org",
  },
  "66477": {
    loansProduced: "4.80",
    membersServed: "644",
    interestEarned: "0.41",
    name: "GUNDERSEN",
    website: "http://www.gundersencu.org",
  },
  "66492": {
    loansProduced: "421.52",
    membersServed: "101,114",
    interestEarned: "35.83",
    name: "UNIVERSITY OF WISCONSIN",
    website: "http://www.uwcu.org",
  },
  "66499": {
    loansProduced: "1.39",
    membersServed: "81",
    interestEarned: "0.12",
    name: "MADISON FIRE DEPARTMENT",
    website: "http://www.mfdcu.org",
  },
  "66503": {
    loansProduced: "4.61",
    membersServed: "465",
    interestEarned: "0.39",
    name: "IRON COUNTY COMMUNITY",
    website: "http://www.ironcccu.com",
  },
  "66513": {
    loansProduced: "1.88",
    membersServed: "214",
    interestEarned: "0.16",
    name: "CENTRAL WISCONSIN",
    website: "http://www.mycwcu.com",
  },
  "66529": {
    loansProduced: "9.80",
    membersServed: "1,027",
    interestEarned: "0.83",
    name: "GOVERNMENTAL EMPLOYEES",
    website: "www.gecuwi.com",
  },
  "66530": {
    loansProduced: "1.72",
    membersServed: "117",
    interestEarned: "0.15",
    name: "SERVICE",
    website: "http://www.service-cu.com",
  },
  "66532": {
    loansProduced: "5.28",
    membersServed: "614",
    interestEarned: "0.45",
    name: "ARCADIA",
    website: "http://www.arcadiacu.com",
  },
  "66537": {
    loansProduced: "3.65",
    membersServed: "513",
    interestEarned: "0.31",
    name: "AVESTAR",
    website: "http://avestarcu.com",
  },
  "66538": {
    loansProduced: "428.66",
    membersServed: "50,148",
    interestEarned: "36.44",
    name: "CONNEXUS",
    website: "http://www.connexuscu.org",
  },
  "66545": {
    loansProduced: "2.83",
    membersServed: "362",
    interestEarned: "0.24",
    name: "FIRST CHOICE",
    website: "http://FIRSTCHOICECREDITUNION.COM",
  },
  "66546": {
    loansProduced: "0.44",
    membersServed: "35",
    interestEarned: "0.04",
    name: "AIR TECH",
    website: null,
  },
  "66547": {
    loansProduced: "0.28",
    membersServed: "25",
    interestEarned: "0.02",
    name: "WOOD COUNTY EMPLOYEES",
    website: null,
  },
  "66549": {
    loansProduced: "4.09",
    membersServed: "449",
    interestEarned: "0.35",
    name: "BAY SHORE",
    website: "http://www.bayshorecu.org",
  },
  "66555": {
    loansProduced: "5.97",
    membersServed: "357",
    interestEarned: "0.51",
    name: "NORTHWESTERN MUTUAL",
    website: "http://www.mynmcu.com",
  },
  "66565": {
    loansProduced: "0.45",
    membersServed: "44",
    interestEarned: "0.04",
    name: "STOPPENBACH",
    website: null,
  },
  "66582": {
    loansProduced: "1.20",
    membersServed: "100",
    interestEarned: "0.10",
    name: "P.I.E.",
    website: "http://piecu.org",
  },
  "66584": {
    loansProduced: "290.91",
    membersServed: "30,943",
    interestEarned: "24.73",
    name: "CALIFORNIA COAST",
    website: "http://www.calcoastcu.org",
  },
  "66585": {
    loansProduced: "45.62",
    membersServed: "5,976",
    interestEarned: "3.88",
    name: "POINT BREEZE",
    website: "http://www.pbcu.com",
  },
  "66592": {
    loansProduced: "50.08",
    membersServed: "4,618",
    interestEarned: "4.26",
    name: "CITIZENS COMMUNITY",
    website: "http://www.citizenscu.com",
  },
  "66593": {
    loansProduced: "4.49",
    membersServed: "459",
    interestEarned: "0.38",
    name: "FIRST PRIORITY",
    website: "http://WWW.FIRSTPRIORITYCU.COM",
  },
  "66594": {
    loansProduced: "107.97",
    membersServed: "10,876",
    interestEarned: "9.18",
    name: "GREENWOOD",
    website: "http://www.greenwoodcu.org",
  },
  "66595": {
    loansProduced: "1.27",
    membersServed: "225",
    interestEarned: "0.11",
    name: "OCEAN STATE",
    website: "http://www.oceanstatecu.org",
  },
  "66597": {
    loansProduced: "8.88",
    membersServed: "980",
    interestEarned: "0.75",
    name: "POLISH NATIONAL",
    website: "http://www.pncu.com",
  },
  "66613": {
    loansProduced: "0.06",
    membersServed: "7",
    interestEarned: "0.00",
    name: "STOUGHTON U.S. RUBBER EMPLOYEES",
    website: null,
  },
  "66615": {
    loansProduced: "2.99",
    membersServed: "527",
    interestEarned: "0.25",
    name: "TOMAH AREA",
    website: "http://www.tacuonline.com",
  },
  "66616": {
    loansProduced: "7.54",
    membersServed: "546",
    interestEarned: "0.64",
    name: "SHEBOYGAN AREA",
    website: "http://www.shebareacu.com",
  },
  "66618": {
    loansProduced: "2.58",
    membersServed: "293",
    interestEarned: "0.22",
    name: "MEADOWLAND",
    website: "http://www.meadowlandcu.org",
  },
  "66622": {
    loansProduced: "2.97",
    membersServed: "294",
    interestEarned: "0.25",
    name: "RACINE MUNICIPAL EMPLOYEES",
    website: "rmecu.org",
  },
  "66625": {
    loansProduced: "1.47",
    membersServed: "658",
    interestEarned: "0.13",
    name: "WISCONSIN MEDICAL",
    website: "bsdc.onlinecu.com/wimedical/",
  },
  "66633": {
    loansProduced: "11.50",
    membersServed: "1,516",
    interestEarned: "0.98",
    name: "MEMBERS' ADVANTAGE",
    website: "http://www.membersadvantagecu.com",
  },
  "66637": {
    loansProduced: "6.52",
    membersServed: "1,375",
    interestEarned: "0.55",
    name: "BREWERY",
    website: "http://www.brewerycu.com",
  },
  "66638": {
    loansProduced: "29.49",
    membersServed: "26,895",
    interestEarned: "2.51",
    name: "GUARDIAN",
    website: "http://www.guardiancu.org",
  },
  "66640": {
    loansProduced: "3.13",
    membersServed: "324",
    interestEarned: "0.27",
    name: "TRI-COUNTY",
    website: "http://www.tri-county.org",
  },
  "66642": {
    loansProduced: "156.81",
    membersServed: "19,066",
    interestEarned: "13.33",
    name: "COMMUNITY FIRST",
    website: "http://WWW.COMMUNITYFIRSTCU.ORG",
  },
  "66652": {
    loansProduced: "27.36",
    membersServed: "3,360",
    interestEarned: "2.33",
    name: "PROSPERA",
    website: "http://www.myprospera.com",
  },
  "66660": {
    loansProduced: "0.02",
    membersServed: "4",
    interestEarned: "0.00",
    name: "ALLOY EMPLOYEES",
    website: null,
  },
  "66662": {
    loansProduced: "0.63",
    membersServed: "53",
    interestEarned: "0.05",
    name: "SCHOOL EMPLOYEES",
    website: "secusuperior.com",
  },
  "66663": {
    loansProduced: "0.29",
    membersServed: "21",
    interestEarned: "0.02",
    name: "POLICE",
    website: null,
  },
  "66665": {
    loansProduced: "1.53",
    membersServed: "125",
    interestEarned: "0.13",
    name: "DAIRYLAND POWER",
    website: "http://www.dpcreditunion.com",
  },
  "66675": {
    loansProduced: "6.66",
    membersServed: "499",
    interestEarned: "0.57",
    name: "APPLETREE",
    website: "http://www.appletree.org",
  },
  "66677": {
    loansProduced: "3.10",
    membersServed: "467",
    interestEarned: "0.26",
    name: "WEA",
    website: "https://www.weacu.com",
  },
  "66678": {
    loansProduced: "36.50",
    membersServed: "4,848",
    interestEarned: "3.10",
    name: "SIMPLICITY",
    website: "http://www.simplicity.coop",
  },
  "66683": {
    loansProduced: "2.77",
    membersServed: "289",
    interestEarned: "0.24",
    name: "HEALTH CARE",
    website: "http://healthcarecu.org",
  },
  "66686": {
    loansProduced: "0.12",
    membersServed: "8",
    interestEarned: "0.01",
    name: "WISCONSIN LATVIAN, INC.",
    website: "http://www.wislat.com",
  },
  "66699": {
    loansProduced: "60.92",
    membersServed: "5,503",
    interestEarned: "5.18",
    name: "LEOMINSTER",
    website: "http://www.leominstercu.com",
  },
  "66700": {
    loansProduced: "1.84",
    membersServed: "824",
    interestEarned: "0.16",
    name: "VIRGINIA EDUCATORS CREDIT UNION",
    website: "http://www.vecu.org",
  },
  "66703": {
    loansProduced: "254.87",
    membersServed: "40,011",
    interestEarned: "21.66",
    name: "WESCOM CENTRAL",
    website: "https://www.wescom.org",
  },
  "66705": {
    loansProduced: "20.13",
    membersServed: "2,452",
    interestEarned: "1.71",
    name: "TRANSWEST",
    website: "http://www.transwestcu.com",
  },
  "66706": {
    loansProduced: "11.12",
    membersServed: "1,649",
    interestEarned: "0.95",
    name: "MEMBERS PLUS",
    website: "http://memberspluscu.org",
  },
  "66707": {
    loansProduced: "15.01",
    membersServed: "1,124",
    interestEarned: "1.28",
    name: "SOUTHERN LAKES",
    website: "http://southernlakescu.org",
  },
  "66710": {
    loansProduced: "34.96",
    membersServed: "3,891",
    interestEarned: "2.97",
    name: "HEARTLAND",
    website: "https://www.heartlandcu.org/",
  },
  "66731": {
    loansProduced: "153.41",
    membersServed: "16,725",
    interestEarned: "13.04",
    name: "FOX COMMUNITIES",
    website: "www.foxcu.org",
  },
  "66733": {
    loansProduced: "7.79",
    membersServed: "1,198",
    interestEarned: "0.66",
    name: "NEW BEDFORD",
    website: "http://nbcu4u.com",
  },
  "66734": {
    loansProduced: "27.65",
    membersServed: "2,451",
    interestEarned: "2.35",
    name: "NORTH COAST",
    website: "http://www.northcoastcu.com",
  },
  "66742": {
    loansProduced: "132.59",
    membersServed: "17,597",
    interestEarned: "11.27",
    name: "WESTCONSIN",
    website: "http://www.westconsincu.org",
  },
  "66750": {
    loansProduced: "3.32",
    membersServed: "432",
    interestEarned: "0.28",
    name: "BADGER-GLOBE",
    website: "http://badgerglobecu.org",
  },
  "66751": {
    loansProduced: "590.87",
    membersServed: "43,178",
    interestEarned: "50.22",
    name: "LANDMARK",
    website: "https://www.landmarkcu.com",
  },
  "66752": {
    loansProduced: "44.49",
    membersServed: "7,579",
    interestEarned: "3.78",
    name: "MARINE",
    website: "http://marinecu.com",
  },
  "66755": {
    loansProduced: "1.32",
    membersServed: "120",
    interestEarned: "0.11",
    name: "COMPASSIONATE CARE",
    website: "http://compassionatecarecu.org",
  },
  "66760": {
    loansProduced: "6.55",
    membersServed: "725",
    interestEarned: "0.56",
    name: "PREMIER FINANCIAL",
    website: "http://www.yourpfcu.com",
  },
  "66777": {
    loansProduced: "54.69",
    membersServed: "6,799",
    interestEarned: "4.65",
    name: "VERVE, A CREDIT UNION",
    website: "https://www.Verveacu.com",
  },
  "66779": {
    loansProduced: "4.10",
    membersServed: "483",
    interestEarned: "0.35",
    name: "OSHKOSH COMMUNITY",
    website: "http://www.oshkoshcommunitycu.com",
  },
  "66787": {
    loansProduced: "116.47",
    membersServed: "12,119",
    interestEarned: "9.90",
    name: "MUNICIPAL EMPL.CREDIT UNION OF BALT",
    website: "https://www.mecu.com",
  },
  "66788": {
    loansProduced: "13.86",
    membersServed: "561",
    interestEarned: "1.18",
    name: "SAN JOAQUIN POWER EMPLOYEES",
    website: "http://www.sjpecu.org",
  },
  "66798": {
    loansProduced: "5.34",
    membersServed: "840",
    interestEarned: "0.45",
    name: "FORWARD FINANCIAL",
    website: "http://www.forwardfinancialcu.com",
  },
  "66818": {
    loansProduced: "292.30",
    membersServed: "26,024",
    interestEarned: "24.85",
    name: "FIRST COMMUNITY",
    website: "http://FCCU.org",
  },
  "66819": {
    loansProduced: "5.02",
    membersServed: "1,343",
    interestEarned: "0.43",
    name: "ATHOL",
    website: "http://www.atholcreditunion.com",
  },
  "66824": {
    loansProduced: "16.36",
    membersServed: "3,712",
    interestEarned: "1.39",
    name: "FREEDOM",
    website: "http://www.freedom.coop",
  },
  "66834": {
    loansProduced: "565.23",
    membersServed: "60,496",
    interestEarned: "48.04",
    name: "ROYAL",
    website: "http://www.rcu.org",
  },
  "66835": {
    loansProduced: "99.80",
    membersServed: "9,212",
    interestEarned: "8.48",
    name: "DAY AIR",
    website: "http://www.dayair.org",
  },
  "66836": {
    loansProduced: "548.95",
    membersServed: "54,268",
    interestEarned: "46.66",
    name: "VIRGINIA CREDIT UNION, INC.,",
    website: "http://www.vacu.org",
  },
  "66840": {
    loansProduced: "15.78",
    membersServed: "1,986",
    interestEarned: "1.34",
    name: "CODE",
    website: "http://www.codecu.org",
  },
  "66841": {
    loansProduced: "243.67",
    membersServed: "28,885",
    interestEarned: "20.71",
    name: "NUMERICA",
    website: "http://www.numericacu.com",
  },
  "66844": {
    loansProduced: "18.28",
    membersServed: "27,678",
    interestEarned: "1.55",
    name: "CLOVERBELT",
    website: "http://www.ccuwausau.com",
  },
  "66847": {
    loansProduced: "45.07",
    membersServed: "3,777",
    interestEarned: "3.83",
    name: "EAGLE COMMUNITY",
    website: "http://www.eaglecu.org",
  },
  "66848": {
    loansProduced: "261.41",
    membersServed: "34,363",
    interestEarned: "22.22",
    name: "COVANTAGE",
    website: "http://www.covantagecu.org",
  },
  "66851": {
    loansProduced: "13.28",
    membersServed: "1,660",
    interestEarned: "1.13",
    name: "UNISON",
    website: "http://www.unisoncu.org",
  },
  "66854": {
    loansProduced: "1.84",
    membersServed: "227",
    interestEarned: "0.16",
    name: "WNC COMMUNITY CU",
    website: "http://wncccu.org",
  },
  "66856": {
    loansProduced: "2.32",
    membersServed: "518",
    interestEarned: "0.20",
    name: "CS",
    website: "https://csnccreditunion.org",
  },
  "66857": {
    loansProduced: "3.83",
    membersServed: "185",
    interestEarned: "0.33",
    name: "GERMANIA",
    website: "http://www.germaniacreditunion.com",
  },
  "66860": {
    loansProduced: "1.60",
    membersServed: "436",
    interestEarned: "0.14",
    name: "GREATER CLEVELAND COMMUNITY",
    website: "http://www.greaterclevcu.com",
  },
  "66862": {
    loansProduced: "1.30",
    membersServed: "147",
    interestEarned: "0.11",
    name: "M.E. EMPLOYEES",
    website: "http://meecu.org",
  },
  "66865": {
    loansProduced: "214.37",
    membersServed: "14,843",
    interestEarned: "18.22",
    name: "CAPITAL",
    website: "http://www.capitalcu.com",
  },
  "66867": {
    loansProduced: "10.17",
    membersServed: "1,364",
    interestEarned: "0.86",
    name: "LEADING EDGE",
    website: "www.leadingedgecu.org",
  },
  "66872": {
    loansProduced: "46.78",
    membersServed: "3,025",
    interestEarned: "3.98",
    name: "KOHLER",
    website: "http://www.kohlercu.com",
  },
  "66877": {
    loansProduced: "1.81",
    membersServed: "247",
    interestEarned: "0.15",
    name: "TEACHERS",
    website: "http://www.tcubeloit.org",
  },
  "66878": {
    loansProduced: "5.41",
    membersServed: "514",
    interestEarned: "0.46",
    name: "MCU FINANCIAL CENTER",
    website: "http://www.mcucreditunion.com",
  },
  "66880": {
    loansProduced: "4.24",
    membersServed: "12,552",
    interestEarned: "0.36",
    name: "ENTERPRISE",
    website: "http://www.enterprisecu.com",
  },
  "66886": {
    loansProduced: "32.34",
    membersServed: "4,956",
    interestEarned: "2.75",
    name: "THE TENNESSEE CREDIT UNION",
    website: "http://TTCU.ORG",
  },
  "66899": {
    loansProduced: "1.88",
    membersServed: "255",
    interestEarned: "0.16",
    name: "RICHMOND VIRGINIA FIRE POLICE CREDI",
    website: "http://WWW.FirePoliceCU.Org",
  },
  "66913": {
    loansProduced: "4.72",
    membersServed: "646",
    interestEarned: "0.40",
    name: "HAMPTON ROADS EDUC CREDIT UNION INC",
    website: "http://www.hrecu.org",
  },
  "66924": {
    loansProduced: "50.67",
    membersServed: "7,724",
    interestEarned: "4.31",
    name: "VALLEYSTAR",
    website: "http://www.valleystar.org",
  },
  "66929": {
    loansProduced: "6.63",
    membersServed: "863",
    interestEarned: "0.56",
    name: "CREDIT UNION OF RICHMOND INCORPORAT",
    website: "https://www.curich.org",
  },
  "66933": {
    loansProduced: "1.66",
    membersServed: "131",
    interestEarned: "0.14",
    name: "AUGUSTA HEALTH CARE CREDIT UNION",
    website: "http://ahccu.com",
  },
  "66942": {
    loansProduced: "5.60",
    membersServed: "344",
    interestEarned: "0.48",
    name: "PFD FIREFIGHTERS CREDIT UNION INC.",
    website: "http://www.pfdcu.com",
  },
  "66954": {
    loansProduced: "4.91",
    membersServed: "3,386",
    interestEarned: "0.42",
    name: "LOUDOUN CREDIT UNION",
    website: "http://www.loudouncu.com",
  },
  "66956": {
    loansProduced: "9.45",
    membersServed: "1,143",
    interestEarned: "0.80",
    name: "NORTHERN STAR CREDIT UNION, INC.",
    website: "http://www.nstarcu.org",
  },
  "66965": {
    loansProduced: "59.75",
    membersServed: "5,525",
    interestEarned: "5.08",
    name: "BEACON CREDIT UNION, INCORPORATED",
    website: "http://www.mybcu.org",
  },
  "66967": {
    loansProduced: "13.69",
    membersServed: "906",
    interestEarned: "1.16",
    name: "JACKSON RIVER COMMUNITY",
    website: "http://www.jacksonrivcommcu.org",
  },
  "66970": {
    loansProduced: "4.60",
    membersServed: "2,564",
    interestEarned: "0.39",
    name: "NEWPORT NEWS MUN. EMP CREDIT UNION",
    website: "http://www.nnmecu.org",
  },
  "66973": {
    loansProduced: "32.17",
    membersServed: "3,321",
    interestEarned: "2.73",
    name: "DOMINION ENERGY CREDIT UNION",
    website: "http://www.dominionenergycu.org",
  },
  "66975": {
    loansProduced: "7.02",
    membersServed: "721",
    interestEarned: "0.60",
    name: "P W C EMPLOYEES CREDIT UNION",
    website: "http://www.pwcecu.org",
  },
  "66980": {
    loansProduced: "2.37",
    membersServed: "605",
    interestEarned: "0.20",
    name: "SOUTHERN",
    website: "http://www.southerncu.com",
  },
  "66983": {
    loansProduced: "21.07",
    membersServed: "2,587",
    interestEarned: "1.79",
    name: "CO-OP",
    website: "http://www.coopcu.com",
  },
  "66998": {
    loansProduced: "39.86",
    membersServed: "5,632",
    interestEarned: "3.39",
    name: "BLACKHAWK COMMUNITY",
    website: "http://www.BHCCU.org",
  },
  "66999": {
    loansProduced: "1.06",
    membersServed: "12,952",
    interestEarned: "0.09",
    name: "LAKEWOOD",
    website: "http://www.lakewoodcu.org",
  },
  "67000": {
    loansProduced: "4.25",
    membersServed: "528",
    interestEarned: "0.36",
    name: "BROKAW",
    website: "www.brokawcu.com",
  },
  "67003": {
    loansProduced: "1.36",
    membersServed: "92",
    interestEarned: "0.12",
    name: "BRANTWOOD",
    website: "www.brantwoodcu.com",
  },
  "67005": {
    loansProduced: "2.76",
    membersServed: "1,123",
    interestEarned: "0.23",
    name: "NATIONAL J. A. C. L.",
    website: "http://jaclcu.com",
  },
  "67008": {
    loansProduced: "3.92",
    membersServed: "396",
    interestEarned: "0.33",
    name: "MEMBERS FIRST",
    website: "http://mbr1cu.org",
  },
  "67009": {
    loansProduced: "2.76",
    membersServed: "439",
    interestEarned: "0.23",
    name: "UNITED LABOR",
    website: "http://UNITEDLABORCU.ORG",
  },
  "67012": {
    loansProduced: "20.98",
    membersServed: "1,858",
    interestEarned: "1.78",
    name: "1ST COMMUNITY",
    website: "http://www.1stccu.com",
  },
  "67017": {
    loansProduced: "10.40",
    membersServed: "7,376",
    interestEarned: "0.88",
    name: "N.E.W.",
    website: "http://www.newcu.org",
  },
  "67021": {
    loansProduced: "6.21",
    membersServed: "581",
    interestEarned: "0.53",
    name: "NORTHEAST COMMUNITY",
    website: "http://www.BeMyCU.org",
  },
  "67022": {
    loansProduced: "0.51",
    membersServed: "80",
    interestEarned: "0.04",
    name: "1ST CLASS EXPRESS",
    website: null,
  },
  "67024": {
    loansProduced: "0.02",
    membersServed: "3",
    interestEarned: "0.00",
    name: "MT. ZION",
    website: null,
  },
  "67056": {
    loansProduced: "3.15",
    membersServed: "269",
    interestEarned: "0.27",
    name: "P & S",
    website: "http://www.pandscu.org",
  },
  "67066": {
    loansProduced: "2.78",
    membersServed: "874",
    interestEarned: "0.24",
    name: "MARSHFIELD MEDICAL CENTER",
    website: "http://www.mmccu.com",
  },
  "67076": {
    loansProduced: "8.67",
    membersServed: "1,281",
    interestEarned: "0.74",
    name: "UTAH HERITAGE",
    website: "http://www.utahheritagecu.org",
  },
  "67078": {
    loansProduced: "1.34",
    membersServed: "92",
    interestEarned: "0.11",
    name: "HOLLYFRONTIER EMPLOYEE'S",
    website: null,
  },
  "67079": {
    loansProduced: "5.60",
    membersServed: "685",
    interestEarned: "0.48",
    name: "FIREFIGHTERS",
    website: "https://www.firefighterscu.com",
  },
  "67096": {
    loansProduced: "2.17",
    membersServed: "406",
    interestEarned: "0.18",
    name: "GOLDEN RULE COMMUNITY",
    website: "http://www.goldenrulecu.org",
  },
  "67108": {
    loansProduced: "0.06",
    membersServed: "5",
    interestEarned: "0.00",
    name: "PRESTO LEWISTON EMPLOYEES",
    website: null,
  },
  "67110": {
    loansProduced: "0.45",
    membersServed: "50",
    interestEarned: "0.04",
    name: "PROVO POLICE & FIRE DEPT.",
    website: null,
  },
  "67127": {
    loansProduced: "3.91",
    membersServed: "412",
    interestEarned: "0.33",
    name: "EDUCATION 1ST",
    website: "http://E1cu.org",
  },
  "67129": {
    loansProduced: "6.55",
    membersServed: "644",
    interestEarned: "0.56",
    name: "FREEDOM",
    website: "https://www.freedomcu.com/",
  },
  "67130": {
    loansProduced: "0.23",
    membersServed: "30",
    interestEarned: "0.02",
    name: "SOUTH SANPETE",
    website: null,
  },
  "67132": {
    loansProduced: "43.65",
    membersServed: "3,782",
    interestEarned: "3.71",
    name: "UTAH POWER",
    website: "http://www.utahpowercu.org",
  },
  "67140": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "BECKSTRAND AND ASSOCIATES",
    website: null,
  },
  "67146": {
    loansProduced: "18.19",
    membersServed: "2,181",
    interestEarned: "1.55",
    name: "NEBO",
    website: "http://www.nebocreditunion.org",
  },
  "67147": {
    loansProduced: "18.80",
    membersServed: "1,454",
    interestEarned: "1.60",
    name: "PACIFIC HORIZON",
    website: "http://www.pacifichorizoncu.com",
  },
  "67149": {
    loansProduced: "1.08",
    membersServed: "69",
    interestEarned: "0.09",
    name: "S E A",
    website: null,
  },
  "67153": {
    loansProduced: "19.56",
    membersServed: "2,466",
    interestEarned: "1.66",
    name: "MEMBERS FIRST",
    website: "https://memfirstcu.com",
  },
  "67156": {
    loansProduced: "0.24",
    membersServed: "35",
    interestEarned: "0.02",
    name: "UINTAH",
    website: null,
  },
  "67160": {
    loansProduced: "6.28",
    membersServed: "470",
    interestEarned: "0.53",
    name: "KINGS PEAK",
    website: "http://www.kingspeakcu.com",
  },
  "67163": {
    loansProduced: "4.98",
    membersServed: "503",
    interestEarned: "0.42",
    name: "SAN JUAN",
    website: "http://SANJUANCU.COM",
  },
  "67165": {
    loansProduced: "1.21",
    membersServed: "91",
    interestEarned: "0.10",
    name: "TANNER EMPLOYEES",
    website: "http://www.tannercu.com",
  },
  "67173": {
    loansProduced: "65.38",
    membersServed: "11,406",
    interestEarned: "5.56",
    name: "SUPERIOR CHOICE",
    website: "http://www.superiorchoice.com",
  },
  "67176": {
    loansProduced: "5.96",
    membersServed: "927",
    interestEarned: "0.51",
    name: "FOND DU LAC",
    website: "http://www.fdlcu.com",
  },
  "67178": {
    loansProduced: "20.93",
    membersServed: "2,225",
    interestEarned: "1.78",
    name: "ACCESS COMMUNITY",
    website: "http://www.accesscreditunion.com",
  },
  "67181": {
    loansProduced: "9.17",
    membersServed: "1,054",
    interestEarned: "0.78",
    name: "FOOTHILLS",
    website: "http://www.foothills-cu.com",
  },
  "67183": {
    loansProduced: "15.42",
    membersServed: "2,405",
    interestEarned: "1.31",
    name: "RIPCO",
    website: "http://www.ripco.org",
  },
  "67184": {
    loansProduced: "29.44",
    membersServed: "3,235",
    interestEarned: "2.50",
    name: "FORT COMMUNITY",
    website: "http://www.fortcommunity.com",
  },
  "67185": {
    loansProduced: "4.08",
    membersServed: "476",
    interestEarned: "0.35",
    name: "FIRST CHOICE COMMUNITY",
    website: "http://www.firstchoiceccu.org",
  },
  "67187": {
    loansProduced: "26.81",
    membersServed: "1,823",
    interestEarned: "2.28",
    name: "QUANTUM",
    website: "http://www.theq.org",
  },
  "67190": {
    loansProduced: "323.17",
    membersServed: "55,070",
    interestEarned: "27.47",
    name: "SUMMIT",
    website: "http://www.summitcreditunion.com",
  },
  "67195": {
    loansProduced: "53.74",
    membersServed: "4,767",
    interestEarned: "4.57",
    name: "ARSENAL",
    website: "http://www.arsenalcu.com",
  },
  "67196": {
    loansProduced: "24.33",
    membersServed: "2,137",
    interestEarned: "2.07",
    name: "PARK CITY",
    website: "http://www.parkcitycu.org",
  },
  "67197": {
    loansProduced: "6.02",
    membersServed: "695",
    interestEarned: "0.51",
    name: "JACK DANIEL EMPLOYEES",
    website: "www.jdecu.org",
  },
  "67200": {
    loansProduced: "9.17",
    membersServed: "911",
    interestEarned: "0.78",
    name: "OAKDALE",
    website: "http://oakdalecu.coop",
  },
  "67201": {
    loansProduced: "15.98",
    membersServed: "3,320",
    interestEarned: "1.36",
    name: "WHITE RIVER",
    website: "http://www.whiterivercu.com",
  },
  "67208": {
    loansProduced: "47.33",
    membersServed: "4,937",
    interestEarned: "4.02",
    name: "WESTBY CO-OP",
    website: "http://WCCUCREDITUNION.COOP",
  },
  "67214": {
    loansProduced: "57.20",
    membersServed: "3,800",
    interestEarned: "4.86",
    name: "FIRST COMMUNITY CU OF BELOIT",
    website: "http://www.firstccu.com",
  },
  "67222": {
    loansProduced: "10.53",
    membersServed: "935",
    interestEarned: "0.89",
    name: "THE STATE",
    website: "http://WWW.WVPECU.ORG",
  },
  "67226": {
    loansProduced: "10.06",
    membersServed: "1,375",
    interestEarned: "0.86",
    name: "CREDIT UNION OF THE ROCKIES",
    website: "http://www.cu-rockies.org",
  },
  "67237": {
    loansProduced: "0.46",
    membersServed: "78",
    interestEarned: "0.04",
    name: "SUPERIOR MUNICIPAL EMPLOYEES",
    website: null,
  },
  "67238": {
    loansProduced: "23.33",
    membersServed: "2,233",
    interestEarned: "1.98",
    name: "GLACIER HILLS",
    website: "http://www.glacierhills.com",
  },
  "67240": {
    loansProduced: "12.63",
    membersServed: "1,184",
    interestEarned: "1.07",
    name: "NORTHWOODS COMMUNITY",
    website: "http://www.northwoodscu.com",
  },
  "67243": {
    loansProduced: "2.31",
    membersServed: "187",
    interestEarned: "0.20",
    name: "ATHENS AREA",
    website: "http://athensareacu.com",
  },
  "67248": {
    loansProduced: "17.42",
    membersServed: "1,576",
    interestEarned: "1.48",
    name: "UNITEDONE",
    website: "www.unitedone.org",
  },
  "67251": {
    loansProduced: "2.16",
    membersServed: "173",
    interestEarned: "0.18",
    name: "OPPORTUNITIES",
    website: "www.oppsvt.org",
  },
  "67252": {
    loansProduced: "41.90",
    membersServed: "4,155",
    interestEarned: "3.56",
    name: "ALABAMA TEACHERS",
    website: "http://atcu.com",
  },
  "67262": {
    loansProduced: "22.46",
    membersServed: "1,785",
    interestEarned: "1.91",
    name: "NORTHWEST PLUS",
    website: "http://nwpluscu.com",
  },
  "67263": {
    loansProduced: "4.70",
    membersServed: "556",
    interestEarned: "0.40",
    name: "BELL",
    website: "http://www.bellcu.com",
  },
  "67264": {
    loansProduced: "159.76",
    membersServed: "11,303",
    interestEarned: "13.58",
    name: "HORIZON",
    website: "http://www.hzcu.org",
  },
  "67269": {
    loansProduced: "47.12",
    membersServed: "3,998",
    interestEarned: "4.01",
    name: "RHODE ISLAND",
    website: "http://www.ricreditunion.org",
  },
  "67270": {
    loansProduced: "17.58",
    membersServed: "3,500",
    interestEarned: "1.49",
    name: "WESTERLY COMMUNITY",
    website: "http://www.westerlyccu.com",
  },
  "67278": {
    loansProduced: "216.17",
    membersServed: "11,654",
    interestEarned: "18.37",
    name: "COASTAL1",
    website: "https://www.coastal1.org",
  },
  "67290": {
    loansProduced: "296.05",
    membersServed: "22,120",
    interestEarned: "25.16",
    name: "COMMUNITY FIRST CREDIT UNION OF FLO",
    website: "http://www.communityfirstfl.org",
  },
  "67297": {
    loansProduced: "1,439.26",
    membersServed: "95,591",
    interestEarned: "122.34",
    name: "SPACE COAST",
    website: "http://www.sccu.com",
  },
  "67309": {
    loansProduced: "2.83",
    membersServed: "26,254",
    interestEarned: "0.24",
    name: "1ST STREET",
    website: "http://www.1streetcu.com",
  },
  "67316": {
    loansProduced: "19.86",
    membersServed: "1,439",
    interestEarned: "1.69",
    name: "ALIVE",
    website: "http://www.alivecu.coop",
  },
  "67318": {
    loansProduced: "5.98",
    membersServed: "660",
    interestEarned: "0.51",
    name: "POMPANO BEACH CITY EMP.",
    website: "http://WWW.PBECU.COM",
  },
  "67319": {
    loansProduced: "8.48",
    membersServed: "37,320",
    interestEarned: "0.72",
    name: "PRIORITY",
    website: "http://www.prioritycu.org",
  },
  "67323": {
    loansProduced: "6.86",
    membersServed: "26,050",
    interestEarned: "0.58",
    name: "CITY-COUNTY EMPLOYEES",
    website: "http://www.ccecu.org",
  },
  "67327": {
    loansProduced: "0.07",
    membersServed: "23",
    interestEarned: "0.01",
    name: "SHAW-ROSS EMPLOYEES",
    website: null,
  },
  "67329": {
    loansProduced: "101.23",
    membersServed: "39,537",
    interestEarned: "8.60",
    name: "ENVISION",
    website: "http://www.envisioncu.com",
  },
  "67330": {
    loansProduced: "23.94",
    membersServed: "27,703",
    interestEarned: "2.03",
    name: "MIAMI POSTAL SERVICE",
    website: "http://www.mpscu.org",
  },
  "67332": {
    loansProduced: "10.58",
    membersServed: "36,386",
    interestEarned: "0.90",
    name: "SUN",
    website: "http://www.suncu.org",
  },
  "67334": {
    loansProduced: "3.01",
    membersServed: "284",
    interestEarned: "0.26",
    name: "JACKSON COUNTY TEACHERS",
    website: "http://www.jacksoncountytcu.com",
  },
  "67336": {
    loansProduced: "2.84",
    membersServed: "17,769",
    interestEarned: "0.24",
    name: "ECU",
    website: "http://www.ecucreditunion.com",
  },
  "67337": {
    loansProduced: "14.55",
    membersServed: "32,805",
    interestEarned: "1.24",
    name: "FLORIDA WEST COAST",
    website: "www.FWCCU.COM",
  },
  "67338": {
    loansProduced: "0.40",
    membersServed: "31",
    interestEarned: "0.03",
    name: "ELECTRICIANS' LOCAL 349",
    website: "http://www.local349cu.org",
  },
  "67340": {
    loansProduced: "1.15",
    membersServed: "96",
    interestEarned: "0.10",
    name: "BROOKLINE MUNICIPAL",
    website: "http://BROOKLINECU.COM",
  },
  "67341": {
    loansProduced: "2.00",
    membersServed: "171",
    interestEarned: "0.17",
    name: "JEFFERSON COUNTY TEACHERS",
    website: "http://jctcuonline.org",
  },
  "67342": {
    loansProduced: "16.36",
    membersServed: "37,042",
    interestEarned: "1.39",
    name: "CONNECT CREDIT UNION",
    website: "www.connectcu.org",
  },
  "67344": {
    loansProduced: "33.04",
    membersServed: "4,371",
    interestEarned: "2.81",
    name: "INSIGHT",
    website: "http://insightcreditunion.com",
  },
  "67347": {
    loansProduced: "51.21",
    membersServed: "42,228",
    interestEarned: "4.35",
    name: "BRIGHTSTAR",
    website: "www.bscu.org",
  },
  "67348": {
    loansProduced: "9.32",
    membersServed: "1,079",
    interestEarned: "0.79",
    name: "UNIVERSITY",
    website: "http://WWW.MYUNICU.COOP",
  },
  "67349": {
    loansProduced: "14.68",
    membersServed: "29,512",
    interestEarned: "1.25",
    name: "ADVENTHEALTH",
    website: "http://www.ahcu.cc",
  },
  "67352": {
    loansProduced: "74.86",
    membersServed: "10,120",
    interestEarned: "6.36",
    name: "JEANNE D'ARC",
    website: "http://www.jdcu.com",
  },
  "67354": {
    loansProduced: "3.03",
    membersServed: "163",
    interestEarned: "0.26",
    name: "GLYNN COUNTY FEDERAL EMPLOYEES",
    website: "https://www.glyncofecu.org",
  },
  "67363": {
    loansProduced: "1.47",
    membersServed: "130",
    interestEarned: "0.12",
    name: "G. P. A.",
    website: "http://gpa-cu.com",
  },
  "67364": {
    loansProduced: "2.16",
    membersServed: "293",
    interestEarned: "0.18",
    name: "SAVANNAH POSTAL",
    website: "http://savannahpostalcu.com",
  },
  "67367": {
    loansProduced: "9.72",
    membersServed: "24,715",
    interestEarned: "0.83",
    name: "CGR",
    website: "http://www.cgrcu.org",
  },
  "67371": {
    loansProduced: "17.78",
    membersServed: "27,498",
    interestEarned: "1.51",
    name: "CORE",
    website: "https://www.corecu.org",
  },
  "67382": {
    loansProduced: "0.95",
    membersServed: "142",
    interestEarned: "0.08",
    name: "GENUINE PARTS",
    website: "http://www.gpcu.net",
  },
  "67383": {
    loansProduced: "10.56",
    membersServed: "37,734",
    interestEarned: "0.90",
    name: "CREDIT UNION OF ATLANTA",
    website: "http://www.cuatlanta.org",
  },
  "67388": {
    loansProduced: "5.13",
    membersServed: "737",
    interestEarned: "0.44",
    name: "MUTUAL SAVINGS",
    website: "http://mutualsavingscu.org",
  },
  "67389": {
    loansProduced: "216.72",
    membersServed: "50,918",
    interestEarned: "18.42",
    name: "ATLANTA POSTAL",
    website: "http://www.apcu.com",
  },
  "67390": {
    loansProduced: "379.85",
    membersServed: "76,316",
    interestEarned: "32.29",
    name: "GEORGIA'S OWN",
    website: "http://www.georgiasown.org",
  },
  "67395": {
    loansProduced: "8.74",
    membersServed: "25,264",
    interestEarned: "0.74",
    name: "NORTH GEORGIA",
    website: "http://www.ngcu.org",
  },
  "67403": {
    loansProduced: "1.98",
    membersServed: "139",
    interestEarned: "0.17",
    name: "SOUTHERN PINE",
    website: "http://www.southernpinecu.org",
  },
  "67407": {
    loansProduced: "0.37",
    membersServed: "64",
    interestEarned: "0.03",
    name: "NAVARRO",
    website: null,
  },
  "67408": {
    loansProduced: "3.29",
    membersServed: "380",
    interestEarned: "0.28",
    name: "BRAZOS STAR",
    website: "http://www.brazosstarcu.com",
  },
  "67414": {
    loansProduced: "0.39",
    membersServed: "73",
    interestEarned: "0.03",
    name: "FEDERAL EMPLOYEES",
    website: null,
  },
  "67416": {
    loansProduced: "15.21",
    membersServed: "1,719",
    interestEarned: "1.29",
    name: "CENTEX CITIZENS",
    website: "https://centexcitizenscu.com",
  },
  "67417": {
    loansProduced: "0.70",
    membersServed: "83",
    interestEarned: "0.06",
    name: "UNION PACIFIC EMPLOYEES",
    website: null,
  },
  "67421": {
    loansProduced: "0.37",
    membersServed: "71",
    interestEarned: "0.03",
    name: "THE ELECTRIC UTILITIES",
    website: null,
  },
  "67425": {
    loansProduced: "2.15",
    membersServed: "194",
    interestEarned: "0.18",
    name: "CENTRAL TEXAS TEACHERS",
    website: "http://www.cttcu.com",
  },
  "67427": {
    loansProduced: "1.54",
    membersServed: "94",
    interestEarned: "0.13",
    name: "GALVESTON GOVERNMENT EMPLOYEES",
    website: "www.ggecu.com",
  },
  "67428": {
    loansProduced: "41.89",
    membersServed: "2,156",
    interestEarned: "3.56",
    name: "PUBLIC EMPLOYEES",
    website: "http://www.pecutx.org",
  },
  "67431": {
    loansProduced: "9.46",
    membersServed: "807",
    interestEarned: "0.80",
    name: "CALHOUN-LIBERTY EMPLOYEES",
    website: "http://www.clecu.org",
  },
  "67432": {
    loansProduced: "10.23",
    membersServed: "681",
    interestEarned: "0.87",
    name: "ALLIANCE CREDIT UNION OF FLORIDA",
    website: "http://www.alliancecufl.org",
  },
  "67434": {
    loansProduced: "22.98",
    membersServed: "2,300",
    interestEarned: "1.95",
    name: "COMMUNITY SOUTH",
    website: "http://www.communitysouth.net",
  },
  "67436": {
    loansProduced: "4.71",
    membersServed: "231",
    interestEarned: "0.40",
    name: "MESQUITE",
    website: "http://www.mesquitecu.org",
  },
  "67439": {
    loansProduced: "2.95",
    membersServed: "223",
    interestEarned: "0.25",
    name: "TXDOT",
    website: "http://www.txdotcu.com",
  },
  "67440": {
    loansProduced: "1.31",
    membersServed: "238",
    interestEarned: "0.11",
    name: "ANGELINA COUNTY TEACHERS",
    website: "http://actcu.net",
  },
  "67441": {
    loansProduced: "4.88",
    membersServed: "629",
    interestEarned: "0.41",
    name: "CAPROCK SANTA FE",
    website: "http://www.csfcu.com",
  },
  "67444": {
    loansProduced: "15.92",
    membersServed: "37,169",
    interestEarned: "1.35",
    name: "PRIORITY ONE CREDIT UNION",
    website: "http://www.priorityonefl.org",
  },
  "67446": {
    loansProduced: "1.14",
    membersServed: "88",
    interestEarned: "0.10",
    name: "ACU",
    website: "http://acucreditunion.com",
  },
  "67452": {
    loansProduced: "8.91",
    membersServed: "769",
    interestEarned: "0.76",
    name: "TEXAS HEALTH",
    website: "http://thcu.org",
  },
  "67456": {
    loansProduced: "0.84",
    membersServed: "99",
    interestEarned: "0.07",
    name: "EVERMAN PARKWAY",
    website: "http://epcreditunion.com",
  },
  "67458": {
    loansProduced: "0.33",
    membersServed: "35",
    interestEarned: "0.03",
    name: "WHARTON COUNTY TEACHERS",
    website: "https://wctcu.org/",
  },
  "67465": {
    loansProduced: "103.24",
    membersServed: "10,772",
    interestEarned: "8.78",
    name: "TEXAS BAY",
    website: "http://www.texasbaycu.org",
  },
  "67468": {
    loansProduced: "0.55",
    membersServed: "47",
    interestEarned: "0.05",
    name: "HIGHWAY DISTRICT 2",
    website: "http://www.dist2cu.com",
  },
  "67469": {
    loansProduced: "0.46",
    membersServed: "43",
    interestEarned: "0.04",
    name: "HIGHWAY DISTRICT 9",
    website: "https://www.hwydist9.com",
  },
  "67470": {
    loansProduced: "32.02",
    membersServed: "2,919",
    interestEarned: "2.72",
    name: "AMERICA'S",
    website: "http://WWW.AMERICASCU.ORG",
  },
  "67473": {
    loansProduced: "1.48",
    membersServed: "190",
    interestEarned: "0.13",
    name: "CONCHO VALLEY",
    website: "http://www.conchovalleycu.com",
  },
  "67474": {
    loansProduced: "0.31",
    membersServed: "31",
    interestEarned: "0.03",
    name: "HIGHWAY EMPLOYEES",
    website: null,
  },
  "67475": {
    loansProduced: "0.88",
    membersServed: "87",
    interestEarned: "0.07",
    name: "MIDLAND MUNICIPAL EMPLOYEES",
    website: null,
  },
  "67476": {
    loansProduced: "0.52",
    membersServed: "49",
    interestEarned: "0.04",
    name: "PRIORITY POSTAL",
    website: "http://www.prioritypostalcu.com",
  },
  "67477": {
    loansProduced: "4.46",
    membersServed: "292",
    interestEarned: "0.38",
    name: "SHARED RESOURCES",
    website: "http://www.srcu.net",
  },
  "67480": {
    loansProduced: "0.66",
    membersServed: "40",
    interestEarned: "0.06",
    name: "THD DISTRICT 17",
    website: null,
  },
  "67481": {
    loansProduced: "3.10",
    membersServed: "566",
    interestEarned: "0.26",
    name: "ALLTRUST",
    website: "http://www.alltrustcu.org",
  },
  "67483": {
    loansProduced: "1.32",
    membersServed: "112",
    interestEarned: "0.11",
    name: "1ST UNIVERSITY",
    website: "http://www.culink.net",
  },
  "67485": {
    loansProduced: "0.89",
    membersServed: "59",
    interestEarned: "0.08",
    name: "CORPUS CHRISTI S.P.",
    website: "http://www.ccspcu.com",
  },
  "67486": {
    loansProduced: "1.03",
    membersServed: "43",
    interestEarned: "0.09",
    name: "REED",
    website: "http://REEDCREDITUNION.COM",
  },
  "67489": {
    loansProduced: "4.86",
    membersServed: "442",
    interestEarned: "0.41",
    name: "ANGELINA FEDERAL EMPLOYEES",
    website: "http://afecu.net",
  },
  "67490": {
    loansProduced: "7.29",
    membersServed: "2,191",
    interestEarned: "0.62",
    name: "TEXAS TELCOM",
    website: "http://www.textelcu.org",
  },
  "67493": {
    loansProduced: "5.55",
    membersServed: "546",
    interestEarned: "0.47",
    name: "UNITED ENERGY",
    website: "http://www.unitedenergycu.com",
  },
  "67494": {
    loansProduced: "62.72",
    membersServed: "10,303",
    interestEarned: "5.33",
    name: "EDUCATION",
    website: "http://www.educationcu.com",
  },
  "67499": {
    loansProduced: "0.06",
    membersServed: "14",
    interestEarned: "0.01",
    name: "PARIS DISTRICT",
    website: null,
  },
  "67505": {
    loansProduced: "6.06",
    membersServed: "23,927",
    interestEarned: "0.51",
    name: "1ST CHOICE",
    website: "http://www.1stchoicecu.org",
  },
  "67507": {
    loansProduced: "16.37",
    membersServed: "1,134",
    interestEarned: "1.39",
    name: "PRESTIGE COMMUNITY",
    website: "https://www.prestigecu.org",
  },
  "67508": {
    loansProduced: "72.96",
    membersServed: "6,314",
    interestEarned: "6.20",
    name: "MEMBERS CHOICE",
    website: "http://www.mccu.com",
  },
  "67510": {
    loansProduced: "5.74",
    membersServed: "421",
    interestEarned: "0.49",
    name: "BRAZOS COMMUNITY",
    website: "https://www.brazosccu.org",
  },
  "67511": {
    loansProduced: "10.15",
    membersServed: "1,065",
    interestEarned: "0.86",
    name: "TEXAS DPS",
    website: "http://www.txdpscu.org",
  },
  "67512": {
    loansProduced: "3.19",
    membersServed: "371",
    interestEarned: "0.27",
    name: "STAR OF TEXAS",
    website: "http://www.staroftexascu.org",
  },
  "67516": {
    loansProduced: "5.11",
    membersServed: "535",
    interestEarned: "0.43",
    name: "HOCKLEY COUNTY SCHOOL EMPLOYEES",
    website: "http://www.hcsecu.org",
  },
  "67517": {
    loansProduced: "4.15",
    membersServed: "620",
    interestEarned: "0.35",
    name: "METRO MEDICAL",
    website: "http://www.mmcu.com",
  },
  "67519": {
    loansProduced: "2.79",
    membersServed: "291",
    interestEarned: "0.24",
    name: "LINKAGE",
    website: "http://www.linkagecu.com",
  },
  "67520": {
    loansProduced: "0.78",
    membersServed: "42",
    interestEarned: "0.07",
    name: "SWEETEX",
    website: null,
  },
  "67521": {
    loansProduced: "33.16",
    membersServed: "3,493",
    interestEarned: "2.82",
    name: "GUARDIANS",
    website: "http://www.Guardianscu.coop",
  },
  "67531": {
    loansProduced: "0.23",
    membersServed: "35",
    interestEarned: "0.02",
    name: "DEL RIO S.P.",
    website: null,
  },
  "67532": {
    loansProduced: "17.02",
    membersServed: "2,651",
    interestEarned: "1.45",
    name: "FORT WORTH CITY",
    website: "http://www.fwccu.org",
  },
  "67534": {
    loansProduced: "2.15",
    membersServed: "171",
    interestEarned: "0.18",
    name: "ODESSA EMPLOYEES",
    website: "http://odessaecu.com",
  },
  "67535": {
    loansProduced: "271.72",
    membersServed: "16,890",
    interestEarned: "23.10",
    name: "DATCU",
    website: "http://WWW.DATCU.ORG",
  },
  "67537": {
    loansProduced: "4.25",
    membersServed: "223",
    interestEarned: "0.36",
    name: "LCRA",
    website: "http://lcracu.org",
  },
  "67538": {
    loansProduced: "36.97",
    membersServed: "5,078",
    interestEarned: "3.14",
    name: "MEMBERSFIRST",
    website: "http://www.membersfirstga.com",
  },
  "67541": {
    loansProduced: "19.90",
    membersServed: "1,270",
    interestEarned: "1.69",
    name: "LIBERTY BAY",
    website: "http://www.lbcu.org",
  },
  "67542": {
    loansProduced: "2.15",
    membersServed: "170",
    interestEarned: "0.18",
    name: "FIRST PRIORITY",
    website: "http://www.firstpriorityabilene.org",
  },
  "67544": {
    loansProduced: "12.21",
    membersServed: "1,092",
    interestEarned: "1.04",
    name: "BAYLOR HEALTH CARE SYSTEM",
    website: "http://www.bhcscu.com",
  },
  "67545": {
    loansProduced: "2.78",
    membersServed: "296",
    interestEarned: "0.24",
    name: "MATAGORDA COUNTY",
    website: "http://matagordaccu.com",
  },
  "67547": {
    loansProduced: "4.54",
    membersServed: "281",
    interestEarned: "0.39",
    name: "DALLAS U.P. EMPLOYEES",
    website: "http://www.dupecu.org",
  },
  "67549": {
    loansProduced: "0.51",
    membersServed: "65",
    interestEarned: "0.04",
    name: "MIDWESTERN STATE UNIVERSITY",
    website: "http://www.mwsucu.com",
  },
  "67550": {
    loansProduced: "13.07",
    membersServed: "2,411",
    interestEarned: "1.11",
    name: "SOUTHWEST 66",
    website: "https://www.southwest66.com",
  },
  "67554": {
    loansProduced: "4.71",
    membersServed: "572",
    interestEarned: "0.40",
    name: "POSTEL FAMILY",
    website: "http://www.postelcu.com",
  },
  "67558": {
    loansProduced: "8.81",
    membersServed: "1,023",
    interestEarned: "0.75",
    name: "COMMUNITY CREDIT UNION OF LYNN",
    website: "http://www.myccu.org",
  },
  "67561": {
    loansProduced: "4.54",
    membersServed: "441",
    interestEarned: "0.39",
    name: "FLORIDA RURAL ELECTRIC",
    website: "http://www.frecu.org",
  },
  "67563": {
    loansProduced: "0.10",
    membersServed: "15",
    interestEarned: "0.01",
    name: "HARRIS EMPLOYEES",
    website: null,
  },
  "67564": {
    loansProduced: "1.60",
    membersServed: "216",
    interestEarned: "0.14",
    name: "THE WRIGHT",
    website: "https://thewrightcu.org",
  },
  "67573": {
    loansProduced: "2.77",
    membersServed: "237",
    interestEarned: "0.24",
    name: "TEMPLE SANTA FE COMMUNITY",
    website: "http://www.templecreditunion.com",
  },
  "67574": {
    loansProduced: "3.06",
    membersServed: "362",
    interestEarned: "0.26",
    name: "BEAUMONT COMMUNITY",
    website: "http://www.bmtccu.com",
  },
  "67576": {
    loansProduced: "10.66",
    membersServed: "1,109",
    interestEarned: "0.91",
    name: "COOPERATIVE TEACHERS",
    website: "http://www.coopteachers.com",
  },
  "67578": {
    loansProduced: "0.90",
    membersServed: "85",
    interestEarned: "0.08",
    name: "NCE",
    website: "http://www.ncecreditunion.net",
  },
  "67579": {
    loansProduced: "1.42",
    membersServed: "241",
    interestEarned: "0.12",
    name: "TEX MEX",
    website: "http://tmcu.com",
  },
  "67581": {
    loansProduced: "7.31",
    membersServed: "690",
    interestEarned: "0.62",
    name: "CABOT COMMUNITY",
    website: "http://cabotccu.com",
  },
  "67587": {
    loansProduced: "3.09",
    membersServed: "402",
    interestEarned: "0.26",
    name: "UNITED",
    website: "http://unitedcu.coop",
  },
  "67588": {
    loansProduced: "1.41",
    membersServed: "159",
    interestEarned: "0.12",
    name: "UNITED SAVERS TRUST",
    website: "http://WWW.USTCU.COM",
  },
  "67592": {
    loansProduced: "6.99",
    membersServed: "581",
    interestEarned: "0.59",
    name: "WEST TEXAS",
    website: "http://www.westtexascu.com",
  },
  "67599": {
    loansProduced: "2.16",
    membersServed: "339",
    interestEarned: "0.18",
    name: "ALLCOM",
    website: "http://www.allcomcu.org",
  },
  "67602": {
    loansProduced: "2.44",
    membersServed: "238",
    interestEarned: "0.21",
    name: "CEN TEX MANUFACTURING",
    website: "http://www.centexmfgcu.org",
  },
  "67604": {
    loansProduced: "4.34",
    membersServed: "434",
    interestEarned: "0.37",
    name: "TRAVIS COUNTY",
    website: "https://www.tccu.net/",
  },
  "67605": {
    loansProduced: "15.78",
    membersServed: "1,845",
    interestEarned: "1.34",
    name: "ST. ANNE'S",
    website: "http://www.stannes.com",
  },
  "67608": {
    loansProduced: "8.66",
    membersServed: "636",
    interestEarned: "0.74",
    name: "UPS EMPLOYEES",
    website: "http://www.upscu.org",
  },
  "67609": {
    loansProduced: "42.92",
    membersServed: "2,984",
    interestEarned: "3.65",
    name: "FIRST BASIN",
    website: "http://www.firstbasin.com",
  },
  "67611": {
    loansProduced: "33.85",
    membersServed: "2,398",
    interestEarned: "2.88",
    name: "EDUCATORS",
    website: "http://EDCU.COM",
  },
  "67612": {
    loansProduced: "6.37",
    membersServed: "543",
    interestEarned: "0.54",
    name: "SCOTT AND WHITE EMPLOYEES",
    website: "http://www.swecu.org",
  },
  "67615": {
    loansProduced: "0.37",
    membersServed: "36",
    interestEarned: "0.03",
    name: "WATERTOWN MUNICIPAL",
    website: "http://www.watertownmcu.com",
  },
  "67619": {
    loansProduced: "562.44",
    membersServed: "38,611",
    interestEarned: "47.81",
    name: "FIRST COMMUNITY",
    website: "http://www.firstcommunity.com",
  },
  "67621": {
    loansProduced: "1.13",
    membersServed: "93",
    interestEarned: "0.10",
    name: "PAMPA MUNICIPAL",
    website: null,
  },
  "67622": {
    loansProduced: "0.31",
    membersServed: "28",
    interestEarned: "0.03",
    name: "AMERICAN BAPTIST ASSOCIATION",
    website: "http://www.abacu.org",
  },
  "67624": {
    loansProduced: "0.91",
    membersServed: "79",
    interestEarned: "0.08",
    name: "OAK FARMS EMPLOYEES",
    website: "http://www.ofecu.com",
  },
  "67625": {
    loansProduced: "24.80",
    membersServed: "4,907",
    interestEarned: "2.11",
    name: "SCHLUMBERGER EMPLOYEES",
    website: "http://www.secu.slb.com",
  },
  "67627": {
    loansProduced: "7.49",
    membersServed: "667",
    interestEarned: "0.64",
    name: "HOUSTON HIGHWAY",
    website: "http://www.houstonhighwaycu.com",
  },
  "67630": {
    loansProduced: "0.59",
    membersServed: "138",
    interestEarned: "0.05",
    name: "MADISON EDUCATION ASSOC.",
    website: "http://madisoncuonline.org",
  },
  "67632": {
    loansProduced: "9.30",
    membersServed: "35,649",
    interestEarned: "0.79",
    name: "PINNACLE",
    website: "https://www.pinnaclecu.org",
  },
  "67634": {
    loansProduced: "1.53",
    membersServed: "194",
    interestEarned: "0.13",
    name: "TRINITY VALLEY TEACHERS",
    website: "http://tvtcu.com",
  },
  "67638": {
    loansProduced: "5.22",
    membersServed: "505",
    interestEarned: "0.44",
    name: "TYLER CITY EMPLOYEES",
    website: "http://tylercityecu.com",
  },
  "67639": {
    loansProduced: "1.78",
    membersServed: "213",
    interestEarned: "0.15",
    name: "AMARILLO POSTAL EMPLOYEES",
    website: "http://www.crunion.org",
  },
  "67641": {
    loansProduced: "0.10",
    membersServed: "3",
    interestEarned: "0.01",
    name: "LEHRER INTERESTS",
    website: null,
  },
  "67642": {
    loansProduced: "15.11",
    membersServed: "1,236",
    interestEarned: "1.28",
    name: "MTCU",
    website: "http://mtcu.org",
  },
  "67644": {
    loansProduced: "15.72",
    membersServed: "2,320",
    interestEarned: "1.34",
    name: "FIRST CENTRAL",
    website: "http://www.firstcentralcu.com",
  },
  "67645": {
    loansProduced: "8.11",
    membersServed: "1,862",
    interestEarned: "0.69",
    name: "SERVICE 1ST",
    website: "s1cu.org",
  },
  "67648": {
    loansProduced: "3.47",
    membersServed: "400",
    interestEarned: "0.29",
    name: "HOOSIER UNITED",
    website: "http://www.hoosierunitedcu.com",
  },
  "67649": {
    loansProduced: "14.57",
    membersServed: "993",
    interestEarned: "1.24",
    name: "MEMBERS",
    website: "http://www.memberscreditunion.com",
  },
  "67651": {
    loansProduced: "0.76",
    membersServed: "93",
    interestEarned: "0.06",
    name: "INTERCORP",
    website: "http://www.intercorpcu.com",
  },
  "67658": {
    loansProduced: "12.93",
    membersServed: "1,694",
    interestEarned: "1.10",
    name: "MEMBERS FIRST",
    website: "http://www.m1st.org",
  },
  "67660": {
    loansProduced: "2.48",
    membersServed: "454",
    interestEarned: "0.21",
    name: "SOUTHERN STAR",
    website: "http://www.southernstarcu.org",
  },
  "67661": {
    loansProduced: "2.27",
    membersServed: "125",
    interestEarned: "0.19",
    name: "VATAT",
    website: "https://www.vatatcu.org/",
  },
  "67664": {
    loansProduced: "386.46",
    membersServed: "34,511",
    interestEarned: "32.85",
    name: "COMMUNITYAMERICA",
    website: "http://www.cacu.com",
  },
  "67666": {
    loansProduced: "12.51",
    membersServed: "1,086",
    interestEarned: "1.06",
    name: "TNCONNECT",
    website: "http://www.tnconnectcu.org",
  },
  "67668": {
    loansProduced: "62.77",
    membersServed: "4,833",
    interestEarned: "5.34",
    name: "FLORIDA CENTRAL",
    website: "http://floridacentralcu.com",
  },
  "67669": {
    loansProduced: "62.83",
    membersServed: "6,399",
    interestEarned: "5.34",
    name: "CITY",
    website: "http://www.citycu.org",
  },
  "67681": {
    loansProduced: "135.99",
    membersServed: "11,310",
    interestEarned: "11.56",
    name: "NEIGHBORHOOD",
    website: "http://www.myncu.com",
  },
  "67682": {
    loansProduced: "0.10",
    membersServed: "10",
    interestEarned: "0.01",
    name: "ATLAS",
    website: null,
  },
  "67683": {
    loansProduced: "1.73",
    membersServed: "210",
    interestEarned: "0.15",
    name: "WORCESTER FIRE DEPT.",
    website: "http://www.wfdcu.org",
  },
  "67687": {
    loansProduced: "0.94",
    membersServed: "99",
    interestEarned: "0.08",
    name: "MACON FIREMEN'S",
    website: null,
  },
  "67688": {
    loansProduced: "0.72",
    membersServed: "95",
    interestEarned: "0.06",
    name: "MACON-BIBB EMPLOYEES CREDIT UNION",
    website: "maconbibbecu.org",
  },
  "67691": {
    loansProduced: "0.67",
    membersServed: "93",
    interestEarned: "0.06",
    name: "J C FEDERAL EMPLOYEES",
    website: null,
  },
  "67695": {
    loansProduced: "2.01",
    membersServed: "402",
    interestEarned: "0.17",
    name: "ACADEMIC EMPLOYEES",
    website: "http://www.academicecu.com",
  },
  "67696": {
    loansProduced: "24.37",
    membersServed: "6,613",
    interestEarned: "2.07",
    name: "HARVARD UNIVERSITY EMPLOYEES",
    website: "http://www.huecu.org",
  },
  "67700": {
    loansProduced: "3.59",
    membersServed: "31,053",
    interestEarned: "0.31",
    name: "HIGHWAY ALLIANCE",
    website: "http://www.hacu4u.com",
  },
  "67701": {
    loansProduced: "5.56",
    membersServed: "605",
    interestEarned: "0.47",
    name: "GREATER EASTERN",
    website: "http://www.greatereastern.org",
  },
  "67703": {
    loansProduced: "1.07",
    membersServed: "74",
    interestEarned: "0.09",
    name: "DEXTER PUBLIC SCHOOLS",
    website: null,
  },
  "67708": {
    loansProduced: "0.84",
    membersServed: "113",
    interestEarned: "0.07",
    name: "ST. JOSEPH TEACHERS'",
    website: "http://SJTCREDITUNION.COM",
  },
  "67709": {
    loansProduced: "32.83",
    membersServed: "5,350",
    interestEarned: "2.79",
    name: "QUINCY",
    website: "http://www.qcu.org",
  },
  "67710": {
    loansProduced: "1.77",
    membersServed: "140",
    interestEarned: "0.15",
    name: "HAVERHILL FIRE DEPARTMENT",
    website: "http://www.hfdcu.com",
  },
  "67713": {
    loansProduced: "5.22",
    membersServed: "510",
    interestEarned: "0.44",
    name: "LAKESIDE",
    website: "https://lcu.coop",
  },
  "67715": {
    loansProduced: "0.82",
    membersServed: "51",
    interestEarned: "0.07",
    name: "OUR LADY OF SNOWS",
    website: null,
  },
  "67716": {
    loansProduced: "1.01",
    membersServed: "124",
    interestEarned: "0.09",
    name: "SOUTHEAST MISSOURI COMMUNITY",
    website: "http://southeastcreditunion.com",
  },
  "67717": {
    loansProduced: "4.27",
    membersServed: "31,487",
    interestEarned: "0.36",
    name: "JAX FIRE",
    website: "http://www.jaxfirecu.org",
  },
  "67720": {
    loansProduced: "35.62",
    membersServed: "3,242",
    interestEarned: "3.03",
    name: "US COMMUNITY",
    website: "http://www.usccu.org",
  },
  "67725": {
    loansProduced: "11.78",
    membersServed: "34,677",
    interestEarned: "1.00",
    name: "FIRST CHOICE",
    website: "http://www.firstchoicecu.org",
  },
  "67730": {
    loansProduced: "5.96",
    membersServed: "7,091",
    interestEarned: "0.51",
    name: "DILLON",
    website: "http://www.DillonCU.com",
  },
  "67738": {
    loansProduced: "0.95",
    membersServed: "153",
    interestEarned: "0.08",
    name: "BCBST EMPLOYEES",
    website: "http://www.bcbstecu.com",
  },
  "67739": {
    loansProduced: "23.12",
    membersServed: "2,172",
    interestEarned: "1.97",
    name: "MISSOURI ELECTRIC COOPERATIVES EMPL",
    website: "http://www.mececu.com",
  },
  "67741": {
    loansProduced: "13.18",
    membersServed: "1,282",
    interestEarned: "1.12",
    name: "CREDIT UNION FOR ROBERTSON COUNTY",
    website: "http://www.tcurc.org",
  },
  "67747": {
    loansProduced: "29.88",
    membersServed: "1,829",
    interestEarned: "2.54",
    name: "SCENIC COMMUNITY",
    website: "http://www.mysccu.com",
  },
  "67748": {
    loansProduced: "23.38",
    membersServed: "4,554",
    interestEarned: "1.99",
    name: "HERITAGE SOUTH COMMUNITY CU",
    website: "http://www.heritagesouth.org",
  },
  "67749": {
    loansProduced: "1.49",
    membersServed: "221",
    interestEarned: "0.13",
    name: "HTM",
    website: "http://www.htmcreditunion.com",
  },
  "67750": {
    loansProduced: "0.31",
    membersServed: "17",
    interestEarned: "0.03",
    name: "ST. AUGUSTINE",
    website: null,
  },
  "67757": {
    loansProduced: "4.03",
    membersServed: "335",
    interestEarned: "0.34",
    name: "YOUR BEST CREDIT UNION",
    website: "https://yourbestcu.com/",
  },
  "67760": {
    loansProduced: "1.15",
    membersServed: "72",
    interestEarned: "0.10",
    name: "FORBES FIELD",
    website: "http://www.forbesfieldcu.com",
  },
  "67761": {
    loansProduced: "145.03",
    membersServed: "10,113",
    interestEarned: "12.33",
    name: "EAST TEXAS PROFESSIONAL",
    website: "http://www.etpcu.org",
  },
  "67762": {
    loansProduced: "0.68",
    membersServed: "59",
    interestEarned: "0.06",
    name: "MARTIN COUNTY COOPERATIVE",
    website: null,
  },
  "67767": {
    loansProduced: "2.66",
    membersServed: "197",
    interestEarned: "0.23",
    name: "LOWELL FIREFIGHTERS",
    website: "http://www.lffcu.com",
  },
  "67770": {
    loansProduced: "4.01",
    membersServed: "287",
    interestEarned: "0.34",
    name: "MEMBERS 1ST",
    website: "http://www.me1stcu.com",
  },
  "67776": {
    loansProduced: "3.20",
    membersServed: "243",
    interestEarned: "0.27",
    name: "BEACON FINANCIAL",
    website: "http://www.beaconfcu.org",
  },
  "67778": {
    loansProduced: "8.82",
    membersServed: "6,343",
    interestEarned: "0.75",
    name: "CITY EMPLOYEES",
    website: "http://www.cecuknox.com",
  },
  "67779": {
    loansProduced: "0.53",
    membersServed: "44",
    interestEarned: "0.04",
    name: "P.I.A.S.",
    website: "http://piascu.org",
  },
  "67783": {
    loansProduced: "0.94",
    membersServed: "113",
    interestEarned: "0.08",
    name: "C & R",
    website: "http://www.candrcreditunion.com",
  },
  "67785": {
    loansProduced: "1.51",
    membersServed: "122",
    interestEarned: "0.13",
    name: "TOPEKA POLICE",
    website: "https://toppdcu.com",
  },
  "67789": {
    loansProduced: "27.06",
    membersServed: "2,930",
    interestEarned: "2.30",
    name: "EMPLOYEE RESOURCES",
    website: "http://www.ercu.org",
  },
  "67793": {
    loansProduced: "2.06",
    membersServed: "169",
    interestEarned: "0.17",
    name: "KNOX CO. EMPLOYEES",
    website: "http://www.knoxcountyecu.com",
  },
  "67796": {
    loansProduced: "0.17",
    membersServed: "90",
    interestEarned: "0.01",
    name: "PROCTER & GAMBLE ST. LOUIS EMP.",
    website: null,
  },
  "67797": {
    loansProduced: "0.47",
    membersServed: "38",
    interestEarned: "0.04",
    name: "ELLIS",
    website: null,
  },
  "67799": {
    loansProduced: "4.63",
    membersServed: "592",
    interestEarned: "0.39",
    name: "SALINA INTERPAROCHIAL",
    website: null,
  },
  "67800": {
    loansProduced: "0.23",
    membersServed: "31",
    interestEarned: "0.02",
    name: "SALINA MUNICIPAL",
    website: null,
  },
  "67801": {
    loansProduced: "1.40",
    membersServed: "392",
    interestEarned: "0.12",
    name: "STATIONERY",
    website: "http://www.stationerycreditunion.co",
  },
  "67804": {
    loansProduced: "89.00",
    membersServed: "33,153",
    interestEarned: "7.57",
    name: "VANTAGE",
    website: "http://www.vcu.com",
  },
  "67812": {
    loansProduced: "0.29",
    membersServed: "38",
    interestEarned: "0.02",
    name: "MID PLAINS",
    website: null,
  },
  "67829": {
    loansProduced: "1.06",
    membersServed: "176",
    interestEarned: "0.09",
    name: "HUTCHINSON POSTAL & COMMUNITY",
    website: "http://hutchpccu.com",
  },
  "67831": {
    loansProduced: "1.14",
    membersServed: "98",
    interestEarned: "0.10",
    name: "ALLEN HOSPITAL PERSONNEL",
    website: null,
  },
  "67835": {
    loansProduced: "2.56",
    membersServed: "243",
    interestEarned: "0.22",
    name: "TRANS TEXAS SOUTHWEST",
    website: "http://www.transtexas.coop",
  },
  "67836": {
    loansProduced: "43.19",
    membersServed: "4,782",
    interestEarned: "3.67",
    name: "SHARON & CRESCENT UNITED",
    website: "http://www.scucu.com",
  },
  "67837": {
    loansProduced: "16.53",
    membersServed: "2,064",
    interestEarned: "1.41",
    name: "BOSTON FIREFIGHTERS",
    website: "http://www.bosfirecu.com",
  },
  "67840": {
    loansProduced: "0.89",
    membersServed: "67",
    interestEarned: "0.08",
    name: "LYNN POLICE",
    website: null,
  },
  "67841": {
    loansProduced: "39.57",
    membersServed: "4,684",
    interestEarned: "3.36",
    name: "CITY OF BOSTON",
    website: "http://www.cityofbostoncu.com",
  },
  "67851": {
    loansProduced: "0.46",
    membersServed: "35",
    interestEarned: "0.04",
    name: "REVERE FIREFIGHTERS",
    website: null,
  },
  "67855": {
    loansProduced: "0.15",
    membersServed: "15",
    interestEarned: "0.01",
    name: "TRI-COUNTY",
    website: null,
  },
  "67856": {
    loansProduced: "16.08",
    membersServed: "1,156",
    interestEarned: "1.37",
    name: "CREDIT UNION OF DODGE CITY",
    website: "http://www.cudodge.com",
  },
  "67864": {
    loansProduced: "84.51",
    membersServed: "5,515",
    interestEarned: "7.18",
    name: "ST. MARY'S",
    website: "http://www.stmaryscu.org",
  },
  "67865": {
    loansProduced: "25.82",
    membersServed: "2,193",
    interestEarned: "2.19",
    name: "CAPITOL",
    website: "http://www.ccutx.org",
  },
  "67869": {
    loansProduced: "2.41",
    membersServed: "286",
    interestEarned: "0.20",
    name: "KEYSTONE",
    website: "http://keystonecu.com",
  },
  "67872": {
    loansProduced: "3.22",
    membersServed: "417",
    interestEarned: "0.27",
    name: "ALPHA",
    website: "http://www.alphacu.com",
  },
  "67874": {
    loansProduced: "68.73",
    membersServed: "5,324",
    interestEarned: "5.84",
    name: "FLORIDA STATE UNIVERSITY",
    website: "http://www.fsucu.org",
  },
  "67875": {
    loansProduced: "5.19",
    membersServed: "1,148",
    interestEarned: "0.44",
    name: "HOLYOKE",
    website: "http://www.holyokecu.com",
  },
  "67882": {
    loansProduced: "0.42",
    membersServed: "68",
    interestEarned: "0.04",
    name: "LYNN TEACHERS",
    website: "http://www.lynnteacherscu.org",
  },
  "67890": {
    loansProduced: "2.62",
    membersServed: "626",
    interestEarned: "0.22",
    name: "LUSO-AMERICAN",
    website: "http://www.luso-american.com",
  },
  "67891": {
    loansProduced: "0.74",
    membersServed: "59",
    interestEarned: "0.06",
    name: "CABOT BOSTON",
    website: "http://www.cbcu.com",
  },
  "67892": {
    loansProduced: "0.29",
    membersServed: "15",
    interestEarned: "0.02",
    name: "WORKMEN'S CIRCLE INCORPORATED",
    website: "http://www.workmenscirclecu.com",
  },
  "67894": {
    loansProduced: "6.87",
    membersServed: "1,744",
    interestEarned: "0.58",
    name: "FALL RIVER MUNICIPAL",
    website: "http://www.frmcu.com",
  },
  "67902": {
    loansProduced: "5.77",
    membersServed: "588",
    interestEarned: "0.49",
    name: "GREATER SPRINGFIELD",
    website: "http://www.grscu.org",
  },
  "67911": {
    loansProduced: "13.19",
    membersServed: "1,551",
    interestEarned: "1.12",
    name: "PRIMESOURCE",
    website: "http://www.primesourcecu.org",
  },
  "67915": {
    loansProduced: "16.39",
    membersServed: "1,970",
    interestEarned: "1.39",
    name: "WESTEX COMMUNITY",
    website: "https://wccu.net",
  },
  "67921": {
    loansProduced: "2.38",
    membersServed: "220",
    interestEarned: "0.20",
    name: "LONG BEACH FIREMEN S",
    website: "https://www.lbfcu.org",
  },
  "67922": {
    loansProduced: "5.77",
    membersServed: "602",
    interestEarned: "0.49",
    name: "NASHVILLE POST OFFICE",
    website: "http://www.npocu.org",
  },
  "67924": {
    loansProduced: "8.39",
    membersServed: "28,942",
    interestEarned: "0.71",
    name: "CITY",
    website: "http://www.city-cu.com",
  },
  "67927": {
    loansProduced: "75.24",
    membersServed: "6,026",
    interestEarned: "6.40",
    name: "SMART FINANCIAL",
    website: "http://www.smartcu.org",
  },
  "67928": {
    loansProduced: "565.19",
    membersServed: "33,781",
    interestEarned: "48.04",
    name: "EECU",
    website: "http://www.eecu.org",
  },
  "67929": {
    loansProduced: "1.97",
    membersServed: "164",
    interestEarned: "0.17",
    name: "EDISON",
    website: "http://EDISONCU.COM",
  },
  "67933": {
    loansProduced: "0.57",
    membersServed: "53",
    interestEarned: "0.05",
    name: "CONTAINER MUTUAL",
    website: null,
  },
  "67936": {
    loansProduced: "3.51",
    membersServed: "12,191",
    interestEarned: "0.30",
    name: "LEADCO COMMUNITY",
    website: "http://leadco.org",
  },
  "67937": {
    loansProduced: "1.94",
    membersServed: "196",
    interestEarned: "0.17",
    name: "DIXIE LINE",
    website: "http://www.dixieline.org",
  },
  "67938": {
    loansProduced: "3.59",
    membersServed: "412",
    interestEarned: "0.31",
    name: "OCALA COMMUNITY",
    website: "http://www.ocalacommunitycu.com",
  },
  "67946": {
    loansProduced: "2.12",
    membersServed: "234",
    interestEarned: "0.18",
    name: "O.M.C. EMPLOYEES'",
    website: "http://omcecu.com",
  },
  "67951": {
    loansProduced: "8.08",
    membersServed: "847",
    interestEarned: "0.69",
    name: "ALDEN",
    website: "http://www.aldencu.com",
  },
  "67955": {
    loansProduced: "623.33",
    membersServed: "79,569",
    interestEarned: "52.98",
    name: "ALLIANT",
    website: "http://www.alliantcreditunion.org",
  },
  "67959": {
    loansProduced: "16.72",
    membersServed: "2,236",
    interestEarned: "1.42",
    name: "ST. JEAN'S",
    website: "http://www.stjeanscu.com",
  },
  "67963": {
    loansProduced: "2.26",
    membersServed: "166",
    interestEarned: "0.19",
    name: "CORPUS CHRISTI POSTAL EMPLOYEES",
    website: "http://ccpecu.com",
  },
  "67965": {
    loansProduced: "0.14",
    membersServed: "30",
    interestEarned: "0.01",
    name: "SPRINGFIELD STREET RAILWAY EMP.",
    website: null,
  },
  "67970": {
    loansProduced: "27.38",
    membersServed: "2,542",
    interestEarned: "2.33",
    name: "BOWATER EMPLOYEES",
    website: "http://www.bowaterecu.org",
  },
  "67971": {
    loansProduced: "2.49",
    membersServed: "159",
    interestEarned: "0.21",
    name: "TENNESSEE DEPARTMENT OF SAFETY",
    website: "TDOSCU.COM",
  },
  "67972": {
    loansProduced: "97.70",
    membersServed: "6,336",
    interestEarned: "8.30",
    name: "CORNERSTONE FINANCIAL",
    website: "http://www.bankcfcu.org",
  },
  "67974": {
    loansProduced: "23.77",
    membersServed: "1,693",
    interestEarned: "2.02",
    name: "COMMUNITY SERVICE",
    website: "http://www.cscutx.com",
  },
  "67976": {
    loansProduced: "1.85",
    membersServed: "830",
    interestEarned: "0.16",
    name: "APPLIANCE",
    website: "http://www.appliancecu.com",
  },
  "67979": {
    loansProduced: "5.37",
    membersServed: "485",
    interestEarned: "0.46",
    name: "M.P.D. COMMUNITY",
    website: "http://WWW.MPDCU.ORG",
  },
  "67982": {
    loansProduced: "43.08",
    membersServed: "3,407",
    interestEarned: "3.66",
    name: "OLD HICKORY",
    website: "http://www.ohcu.org",
  },
  "67993": {
    loansProduced: "27.65",
    membersServed: "5,691",
    interestEarned: "2.35",
    name: "FIVE COUNTY",
    website: "https://www.fivecounty.com",
  },
  "67995": {
    loansProduced: "17.67",
    membersServed: "1,100",
    interestEarned: "1.50",
    name: "KANSAS TEACHERS COMMUNITY",
    website: "http://www.kstcu.org",
  },
  "68005": {
    loansProduced: "3.42",
    membersServed: "400",
    interestEarned: "0.29",
    name: "FREESTONE",
    website: "http://www.freestonecu.org",
  },
  "68008": {
    loansProduced: "5.56",
    membersServed: "327",
    interestEarned: "0.47",
    name: "CENTRAL VALLEY FIREFIGHTERS",
    website: "http://WWW.CVFIRECU.ORG",
  },
  "68010": {
    loansProduced: "1.05",
    membersServed: "120",
    interestEarned: "0.09",
    name: "ALPINE COMMUNITY",
    website: null,
  },
  "68011": {
    loansProduced: "3.57",
    membersServed: "470",
    interestEarned: "0.30",
    name: "CORNER STONE",
    website: "http://www.cornerstonecreditunion.n",
  },
  "68016": {
    loansProduced: "4.16",
    membersServed: "257",
    interestEarned: "0.35",
    name: "COAST 2 COAST FINANCIAL",
    website: "http://www.c2cfcu.com",
  },
  "68018": {
    loansProduced: "1.19",
    membersServed: "95",
    interestEarned: "0.10",
    name: "F.R.S.A.",
    website: "http://frsacu.org",
  },
  "68021": {
    loansProduced: "102.90",
    membersServed: "10,269",
    interestEarned: "8.75",
    name: "COMMUNITY RESOURCE",
    website: "http://www.crcu.org",
  },
  "68027": {
    loansProduced: "4.33",
    membersServed: "1,426",
    interestEarned: "0.37",
    name: "1ST VALLEY",
    website: "https://www.1stvalleycu.com",
  },
  "68038": {
    loansProduced: "26.37",
    membersServed: "2,933",
    interestEarned: "2.24",
    name: "EVERGREEN",
    website: "http://www.egcu.org",
  },
  "68042": {
    loansProduced: "3.44",
    membersServed: "20,856",
    interestEarned: "0.29",
    name: "POWERNET",
    website: "http://www.powernetcu.org",
  },
  "68044": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "HOLY REDEEMER COMMUNITY OF SE WIS.",
    website: "https://holyredeemerccu.com",
  },
  "68046": {
    loansProduced: "3.39",
    membersServed: "333",
    interestEarned: "0.29",
    name: "NAVEO",
    website: "http://www.naveo.org",
  },
  "68047": {
    loansProduced: "0.43",
    membersServed: "60",
    interestEarned: "0.04",
    name: "SOUTHWEST COUNTIES SCHOOL EMPLOYEES",
    website: "http://WWW.SWCSECU.US",
  },
  "68052": {
    loansProduced: "3.32",
    membersServed: "483",
    interestEarned: "0.28",
    name: "CALIFORNIA AGRIBUSINESS",
    website: "http://www.CalAgCU.org",
  },
  "68053": {
    loansProduced: "202.42",
    membersServed: "13,680",
    interestEarned: "17.21",
    name: "SIERRA CENTRAL",
    website: "http://www.sierracentral.com",
  },
  "68057": {
    loansProduced: "9.90",
    membersServed: "1,804",
    interestEarned: "0.84",
    name: "HOMEFIELD",
    website: "http://www.homefieldcu.com",
  },
  "68061": {
    loansProduced: "19.65",
    membersServed: "2,985",
    interestEarned: "1.67",
    name: "SOLARITY",
    website: "http://www.solaritycu.org",
  },
  "68062": {
    loansProduced: "24.72",
    membersServed: "2,208",
    interestEarned: "2.10",
    name: "AMERICA'S CHRISTIAN",
    website: "http://www.americaschristiancu.com",
  },
  "68069": {
    loansProduced: "1.82",
    membersServed: "168",
    interestEarned: "0.15",
    name: "CN/IC EMPLOYEES",
    website: "http://www.cnicecu.net",
  },
  "68071": {
    loansProduced: "3.45",
    membersServed: "408",
    interestEarned: "0.29",
    name: "ST. THOMAS",
    website: "www.stthomascu.org",
  },
  "68073": {
    loansProduced: "17.04",
    membersServed: "1,107",
    interestEarned: "1.45",
    name: "HOLLEY",
    website: "http://www.holleycreditunion.org",
  },
  "68077": {
    loansProduced: "0.78",
    membersServed: "838",
    interestEarned: "0.07",
    name: "MARYVILLE MUNICIPAL",
    website: "http://MMCUTN.COM",
  },
  "68084": {
    loansProduced: "73.01",
    membersServed: "6,917",
    interestEarned: "6.21",
    name: "FIRST SOUTH FINANCIAL",
    website: "http://www.firstsouth.com",
  },
  "68085": {
    loansProduced: "660.20",
    membersServed: "40,686",
    interestEarned: "56.12",
    name: "KNOXVILLE TVA EMPLOYEES",
    website: "http://www.tvacreditunion.com",
  },
  "68095": {
    loansProduced: "638.66",
    membersServed: "59,047",
    interestEarned: "54.29",
    name: "EASTMAN",
    website: "http://www.ecu.org",
  },
  "68097": {
    loansProduced: "2.40",
    membersServed: "407",
    interestEarned: "0.20",
    name: "HAPPY VALLEY",
    website: "http://www.hvcu-tn.com",
  },
  "68098": {
    loansProduced: "0.40",
    membersServed: "43",
    interestEarned: "0.03",
    name: "KINGSTON TVA EMPLOYEES",
    website: null,
  },
  "68100": {
    loansProduced: "2.10",
    membersServed: "131",
    interestEarned: "0.18",
    name: "SKYLINE",
    website: "http://www.skylinecreditunion.com",
  },
  "68101": {
    loansProduced: "3.58",
    membersServed: "554",
    interestEarned: "0.30",
    name: "TENNESSEE EMPLOYEES",
    website: "http://www.tnecu.org",
  },
  "68104": {
    loansProduced: "6.37",
    membersServed: "739",
    interestEarned: "0.54",
    name: "KINGSPORT PRESS CU",
    website: "http://www.kpcu.org",
  },
  "68112": {
    loansProduced: "0.01",
    membersServed: "6",
    interestEarned: "0.00",
    name: "W. VIRGINIA STATE CONVENTION",
    website: null,
  },
  "68113": {
    loansProduced: "1.26",
    membersServed: "80",
    interestEarned: "0.11",
    name: "LOCAL NO. 317 I.A.F.F.",
    website: null,
  },
  "68119": {
    loansProduced: "3.89",
    membersServed: "351",
    interestEarned: "0.33",
    name: "VANDERBILT",
    website: "https://www.vanderbiltcu.org",
  },
  "68120": {
    loansProduced: "0.15",
    membersServed: "11",
    interestEarned: "0.01",
    name: "HARDIN COUNTY HOSPITAL EMPLOYEES",
    website: null,
  },
  "68124": {
    loansProduced: "1.18",
    membersServed: "1,034",
    interestEarned: "0.10",
    name: "PATHWAY",
    website: "http://www.pathwaycredit.com",
  },
  "68126": {
    loansProduced: "0.20",
    membersServed: "36",
    interestEarned: "0.02",
    name: "GREENEVILLE WORKS EMPLS. SAV. ASSN.",
    website: null,
  },
  "68127": {
    loansProduced: "2.26",
    membersServed: "183",
    interestEarned: "0.19",
    name: "GREENEVILLE CITY EMPLOYEES'",
    website: null,
  },
  "68135": {
    loansProduced: "0.26",
    membersServed: "323",
    interestEarned: "0.02",
    name: "METROPOLITAN TEACHERS",
    website: "http://metrotcu.com",
  },
  "68137": {
    loansProduced: "16.11",
    membersServed: "1,214",
    interestEarned: "1.37",
    name: "MASS BAY",
    website: "http://www.massbaycu.org",
  },
  "68138": {
    loansProduced: "3.63",
    membersServed: "451",
    interestEarned: "0.31",
    name: "HORIZON",
    website: "http://www.myhorizoncu.org",
  },
  "68144": {
    loansProduced: "0.66",
    membersServed: "57",
    interestEarned: "0.06",
    name: "HURD EMPLOYEES",
    website: null,
  },
  "68148": {
    loansProduced: "17.03",
    membersServed: "1,382",
    interestEarned: "1.45",
    name: "ENBRIGHT",
    website: "https://enbrightcu.com",
  },
  "68149": {
    loansProduced: "0.47",
    membersServed: "79",
    interestEarned: "0.04",
    name: "CEMC EMPLOYEES",
    website: null,
  },
  "68150": {
    loansProduced: "15.86",
    membersServed: "2,481",
    interestEarned: "1.35",
    name: "IMPACT CREDIT UNION, INC.",
    website: "http://www.impactcu.org",
  },
  "68153": {
    loansProduced: "0.24",
    membersServed: "19",
    interestEarned: "0.02",
    name: "WCG EMPLOYEES",
    website: null,
  },
  "68161": {
    loansProduced: "0.46",
    membersServed: "35",
    interestEarned: "0.04",
    name: "A.U.B. EMPLOYEES'",
    website: null,
  },
  "68165": {
    loansProduced: "0.04",
    membersServed: "6",
    interestEarned: "0.00",
    name: "OLIVET BAPTIST",
    website: null,
  },
  "68167": {
    loansProduced: "1.07",
    membersServed: "1,627",
    interestEarned: "0.09",
    name: "COLLEGEDALE",
    website: "http://www.collegedale.org",
  },
  "68171": {
    loansProduced: "2.21",
    membersServed: "228",
    interestEarned: "0.19",
    name: "GATEWAY",
    website: "http://www.gatewaycreditunion.com",
  },
  "68172": {
    loansProduced: "2.02",
    membersServed: "552",
    interestEarned: "0.17",
    name: "KOIN",
    website: "http://www.koincu.org",
  },
  "68173": {
    loansProduced: "4.81",
    membersServed: "844",
    interestEarned: "0.41",
    name: "HEALTHCARE SERVICES",
    website: "http://www.hscu.net",
  },
  "68180": {
    loansProduced: "1.00",
    membersServed: "148",
    interestEarned: "0.08",
    name: "BURLINGTON NORTHERN SANTA FE RAILWY",
    website: null,
  },
  "68181": {
    loansProduced: "5.34",
    membersServed: "451",
    interestEarned: "0.45",
    name: "EDUCATIONAL COMMUNITY ALLIANCE",
    website: "http://www.educacu.com",
  },
  "68186": {
    loansProduced: "251.85",
    membersServed: "15,970",
    interestEarned: "21.41",
    name: "COLUMBIA COMMUNITY",
    website: "http://www.columbiacu.org",
  },
  "68187": {
    loansProduced: "597.99",
    membersServed: "62,528",
    interestEarned: "50.83",
    name: "BAXTER",
    website: "http://www.bcu.org",
  },
  "68190": {
    loansProduced: "1.57",
    membersServed: "204",
    interestEarned: "0.13",
    name: "MONTANA EDUCATORS'",
    website: "http://www.mtedcu.org",
  },
  "68196": {
    loansProduced: "87.10",
    membersServed: "10,593",
    interestEarned: "7.40",
    name: "RED CANOE",
    website: "http://www.redcanoecu.com",
  },
  "68203": {
    loansProduced: "432.48",
    membersServed: "47,585",
    interestEarned: "36.76",
    name: "SPOKANE TEACHERS",
    website: "http://www.stcu.org",
  },
  "68204": {
    loansProduced: "2.48",
    membersServed: "548",
    interestEarned: "0.21",
    name: "PUGET SOUND COOPERATIVE",
    website: "http://www.psccu.org",
  },
  "68205": {
    loansProduced: "4.25",
    membersServed: "299",
    interestEarned: "0.36",
    name: "SPOKANE FIREFIGHTERS",
    website: "http://www.firecu.net",
  },
  "68210": {
    loansProduced: "67.39",
    membersServed: "5,171",
    interestEarned: "5.73",
    name: "TRIANGLE",
    website: "http://trianglecu.org",
  },
  "68215": {
    loansProduced: "103.43",
    membersServed: "49,210",
    interestEarned: "8.79",
    name: "CORPORATE AMERICA FAMILY",
    website: "http://www.cafcu.org",
  },
  "68219": {
    loansProduced: "242.48",
    membersServed: "23,710",
    interestEarned: "20.61",
    name: "NORTHEAST",
    website: "https://www.necu.org",
  },
  "68222": {
    loansProduced: "11.99",
    membersServed: "1,263",
    interestEarned: "1.02",
    name: "MILL TOWN",
    website: "milltowncu.org",
  },
  "68223": {
    loansProduced: "77.38",
    membersServed: "8,792",
    interestEarned: "6.58",
    name: "QUALSTAR",
    website: "http://www.qualstarcu.com",
  },
  "68224": {
    loansProduced: "4.80",
    membersServed: "459",
    interestEarned: "0.41",
    name: "AZALEA CITY",
    website: "http://www.azaleacitycu.com",
  },
  "68225": {
    loansProduced: "49.03",
    membersServed: "3,243",
    interestEarned: "4.17",
    name: "TECH",
    website: "http://www.techcu.org",
  },
  "68226": {
    loansProduced: "67.32",
    membersServed: "35,534",
    interestEarned: "5.72",
    name: "STREATOR ONIZED",
    website: "http://www.socu.org",
  },
  "68227": {
    loansProduced: "20.43",
    membersServed: "3,004",
    interestEarned: "1.74",
    name: "WESTSTAR",
    website: "http://www.weststar.org",
  },
  "68228": {
    loansProduced: "141.88",
    membersServed: "7,285",
    interestEarned: "12.06",
    name: "GREATER NEVADA",
    website: "http://www.gncu.org",
  },
  "68229": {
    loansProduced: "4.42",
    membersServed: "228",
    interestEarned: "0.38",
    name: "TACOMA LONGSHOREMEN",
    website: "http://tlcu23.com",
  },
  "68231": {
    loansProduced: "6.54",
    membersServed: "834",
    interestEarned: "0.56",
    name: "STRAIT VIEW",
    website: "http://www.svcu.com",
  },
  "68239": {
    loansProduced: "415.81",
    membersServed: "32,165",
    interestEarned: "35.34",
    name: "HAPO COMMUNITY",
    website: "http://www.hapo.org",
  },
  "68240": {
    loansProduced: "9.83",
    membersServed: "1,230",
    interestEarned: "0.84",
    name: "MINNEQUA WORKS",
    website: "http://www.mymwcu.com",
  },
  "68241": {
    loansProduced: "58.87",
    membersServed: "7,909",
    interestEarned: "5.00",
    name: "DIAMOND",
    website: "http://www.diamondcu.org",
  },
  "68242": {
    loansProduced: "6.48",
    membersServed: "757",
    interestEarned: "0.55",
    name: "MEMBERS ADVANTAGE",
    website: "http://www.macuonline.org",
  },
  "68244": {
    loansProduced: "141.11",
    membersServed: "12,619",
    interestEarned: "11.99",
    name: "FIRST FINANCIAL",
    website: "http://www.ffnm.org",
  },
  "68248": {
    loansProduced: "357.62",
    membersServed: "23,744",
    interestEarned: "30.40",
    name: "FORUM",
    website: "http://www.forumcu.com",
  },
  "68251": {
    loansProduced: "140.88",
    membersServed: "7,349",
    interestEarned: "11.97",
    name: "UNITED HERITAGE",
    website: "http://www.uhcu.org",
  },
  "68253": {
    loansProduced: "18.11",
    membersServed: "1,204",
    interestEarned: "1.54",
    name: "MOUNTAINCREST",
    website: "http://www.mountaincrestcu.com",
  },
  "68254": {
    loansProduced: "4.32",
    membersServed: "1,265",
    interestEarned: "0.37",
    name: "CONNECTION",
    website: "https://connectioncu.org",
  },
  "68255": {
    loansProduced: "14.11",
    membersServed: "799",
    interestEarned: "1.20",
    name: "PROGRESSIONS",
    website: "http://progressionscu.org",
  },
  "68256": {
    loansProduced: "92.01",
    membersServed: "10,665",
    interestEarned: "7.82",
    name: "BELCO COMMUNITY CREDIT UNION",
    website: "http://www.belco.org",
  },
  "68257": {
    loansProduced: "24.60",
    membersServed: "2,736",
    interestEarned: "2.09",
    name: "CONNECTIONS",
    website: "http://www.connectidaho.org",
  },
  "68259": {
    loansProduced: "167.25",
    membersServed: "15,886",
    interestEarned: "14.22",
    name: "INDIANA MEMBERS",
    website: "http://www.imcu.org",
  },
  "68266": {
    loansProduced: "22.20",
    membersServed: "1,172",
    interestEarned: "1.89",
    name: "PIKES PEAK",
    website: "http://www.pikespeakcu.com",
  },
  "68271": {
    loansProduced: "399.84",
    membersServed: "36,095",
    interestEarned: "33.99",
    name: "TWINSTAR",
    website: "http://www.twinstarcu.com",
  },
  "68273": {
    loansProduced: "5.70",
    membersServed: "534",
    interestEarned: "0.48",
    name: "EXPRESS",
    website: "http://www.expresscu.org",
  },
  "68274": {
    loansProduced: "8.44",
    membersServed: "953",
    interestEarned: "0.72",
    name: "HORIZONS NORTH",
    website: "http://www.hncu.org",
  },
  "68278": {
    loansProduced: "643.87",
    membersServed: "52,742",
    interestEarned: "54.73",
    name: "GESA",
    website: "http://www.gesa.com",
  },
  "68280": {
    loansProduced: "5.42",
    membersServed: "728",
    interestEarned: "0.46",
    name: "BLUE MOUNTAIN",
    website: "http://www.bmcu.com",
  },
  "68283": {
    loansProduced: "4.16",
    membersServed: "663",
    interestEarned: "0.35",
    name: "SPOKANE CITY",
    website: "http://www.sccu.net",
  },
  "68284": {
    loansProduced: "3.35",
    membersServed: "232",
    interestEarned: "0.28",
    name: "AVISTA CORP.",
    website: "http://avistacu.com",
  },
  "68286": {
    loansProduced: "4.49",
    membersServed: "574",
    interestEarned: "0.38",
    name: "TLCU FINANCIAL",
    website: "http://tlcufinancial.org",
  },
  "68287": {
    loansProduced: "210.93",
    membersServed: "17,812",
    interestEarned: "17.93",
    name: "CENTRA",
    website: "http://www.centra.org",
  },
  "68291": {
    loansProduced: "0.77",
    membersServed: "79",
    interestEarned: "0.07",
    name: "GEORGIA GUARD",
    website: "http://GGCU.ORG",
  },
  "68293": {
    loansProduced: "4.88",
    membersServed: "538",
    interestEarned: "0.41",
    name: "SNO FALLS",
    website: "http://www.snofalls.com",
  },
  "68295": {
    loansProduced: "22.15",
    membersServed: "2,203",
    interestEarned: "1.88",
    name: "CLARITY",
    website: "http://www.claritycu.com",
  },
  "68296": {
    loansProduced: "14.96",
    membersServed: "1,266",
    interestEarned: "1.27",
    name: "WHITE ROSE",
    website: "http://whiterosecu.com",
  },
  "68297": {
    loansProduced: "1.27",
    membersServed: "109",
    interestEarned: "0.11",
    name: "NORRISTOWN BELL",
    website: "www.3hillcu.com",
  },
  "68298": {
    loansProduced: "78.75",
    membersServed: "4,547",
    interestEarned: "6.69",
    name: "TAPCO",
    website: "http://www.tapcocu.org",
  },
  "68300": {
    loansProduced: "7.59",
    membersServed: "2,307",
    interestEarned: "0.65",
    name: "STAR FINANCIAL",
    website: "http://www.starcu.org",
  },
  "68301": {
    loansProduced: "105.30",
    membersServed: "6,325",
    interestEarned: "8.95",
    name: "FIRST COMMERCE",
    website: "http://www.firstcommercecu.org",
  },
  "68302": {
    loansProduced: "53.79",
    membersServed: "38,867",
    interestEarned: "4.57",
    name: "FIVE STAR",
    website: "http://www.fivestarcu.com",
  },
  "68305": {
    loansProduced: "6.77",
    membersServed: "511",
    interestEarned: "0.58",
    name: "RESPONDERS EMERGENCY SERVICES",
    website: "https://www.myrescu.org/",
  },
  "68315": {
    loansProduced: "86.58",
    membersServed: "7,704",
    interestEarned: "7.36",
    name: "SEATTLE METROPOLITAN",
    website: "http://www.seattlecu.com",
  },
  "68316": {
    loansProduced: "6.50",
    membersServed: "944",
    interestEarned: "0.55",
    name: "BAY CITIES",
    website: "http://www.baycities.org",
  },
  "68317": {
    loansProduced: "4.83",
    membersServed: "491",
    interestEarned: "0.41",
    name: "EMPOWER",
    website: "http://empower-cu.org",
  },
  "68321": {
    loansProduced: "5.26",
    membersServed: "996",
    interestEarned: "0.45",
    name: "CALIFORNIA COMMUNITY",
    website: "https://www.caccu.org",
  },
  "68322": {
    loansProduced: "98.30",
    membersServed: "9,492",
    interestEarned: "8.36",
    name: "CFCU COMMUNITY",
    website: "https://www.mycfcu.com",
  },
  "68324": {
    loansProduced: "8.90",
    membersServed: "1,040",
    interestEarned: "0.76",
    name: "SACO VALLEY",
    website: "http://www.sacovalley.org",
  },
  "68325": {
    loansProduced: "18.15",
    membersServed: "1,633",
    interestEarned: "1.54",
    name: "RELYON",
    website: "http://www.relyoncu.org",
  },
  "68328": {
    loansProduced: "0.21",
    membersServed: "38",
    interestEarned: "0.02",
    name: "WCLA",
    website: "http://loggerscu.com",
  },
  "68331": {
    loansProduced: "8.11",
    membersServed: "1,603",
    interestEarned: "0.69",
    name: "OLYMPIA",
    website: "http://www.olycu.org",
  },
  "68332": {
    loansProduced: "3.74",
    membersServed: "515",
    interestEarned: "0.32",
    name: "GR CONSUMERS",
    website: "http://www.grccu.com",
  },
  "68342": {
    loansProduced: "35.91",
    membersServed: "4,095",
    interestEarned: "3.05",
    name: "HUDSON RIVER COMMUNITY",
    website: "http://www.hrccu.org",
  },
  "68343": {
    loansProduced: "3.66",
    membersServed: "15,397",
    interestEarned: "0.31",
    name: "ADVANTAGE ONE",
    website: "http://www.adonecu.org",
  },
  "68349": {
    loansProduced: "15.89",
    membersServed: "8,393",
    interestEarned: "1.35",
    name: "TREMONT",
    website: "http://www.tremontcu.org",
  },
  "68353": {
    loansProduced: "146.55",
    membersServed: "10,227",
    interestEarned: "12.46",
    name: "WESTMARK",
    website: "http://www.westmark.org",
  },
  "68354": {
    loansProduced: "4.58",
    membersServed: "907",
    interestEarned: "0.39",
    name: "EATON FAMILY",
    website: "https://www.eatonfamilycu.com",
  },
  "68356": {
    loansProduced: "40.65",
    membersServed: "2,918",
    interestEarned: "3.45",
    name: "FIRST IMPERIAL",
    website: "http://www.ficu.com",
  },
  "68358": {
    loansProduced: "6.58",
    membersServed: "997",
    interestEarned: "0.56",
    name: "BAPTIST",
    website: "http://www.baptistcu.org",
  },
  "68359": {
    loansProduced: "130.32",
    membersServed: "9,233",
    interestEarned: "11.08",
    name: "VELOCITY",
    website: "http://www.velocitycu.com",
  },
  "68361": {
    loansProduced: "6.27",
    membersServed: "523",
    interestEarned: "0.53",
    name: "IDAHO UNITED",
    website: "http://www.idahounited.org",
  },
  "68366": {
    loansProduced: "353.25",
    membersServed: "33,940",
    interestEarned: "30.03",
    name: "SAFE",
    website: "http://www.safecu.org",
  },
  "68369": {
    loansProduced: "21.32",
    membersServed: "2,322",
    interestEarned: "1.81",
    name: "UNITED COMMUNITY",
    website: "http://www.unitedccu.com",
  },
  "68371": {
    loansProduced: "8.77",
    membersServed: "1,715",
    interestEarned: "0.75",
    name: "AMERICAN LAKE",
    website: "http://www.americanlake.org",
  },
  "68373": {
    loansProduced: "127.20",
    membersServed: "11,916",
    interestEarned: "10.81",
    name: "FIRST ENTERTAINMENT",
    website: "http://www.firstent.org",
  },
  "68374": {
    loansProduced: "157.68",
    membersServed: "16,184",
    interestEarned: "13.40",
    name: "GUARDIAN",
    website: "https://www.myguardiancu.com",
  },
  "68376": {
    loansProduced: "78.20",
    membersServed: "8,175",
    interestEarned: "6.65",
    name: "EAST IDAHO",
    website: "https://frontiercreditunion.com",
  },
  "68377": {
    loansProduced: "28.42",
    membersServed: "4,331",
    interestEarned: "2.42",
    name: "MYPOINT",
    website: "http://www.mypointcu.com",
  },
  "68383": {
    loansProduced: "43.48",
    membersServed: "4,029",
    interestEarned: "3.70",
    name: "TECHNOLOGY",
    website: "http://www.techcu.com",
  },
  "68386": {
    loansProduced: "38.57",
    membersServed: "3,334",
    interestEarned: "3.28",
    name: "LEWIS CLARK",
    website: "https://www.lewisclarkcu.org",
  },
  "68389": {
    loansProduced: "7.01",
    membersServed: "21,246",
    interestEarned: "0.60",
    name: "CROW WING POWER",
    website: "http://www.cwpcu.org",
  },
  "68391": {
    loansProduced: "416.54",
    membersServed: "25,613",
    interestEarned: "35.41",
    name: "CAMPUS USA",
    website: "http://www.campuscu.com",
  },
  "68394": {
    loansProduced: "86.56",
    membersServed: "5,639",
    interestEarned: "7.36",
    name: "MERIWEST",
    website: "http://www.meriwest.com",
  },
  "68395": {
    loansProduced: "21.05",
    membersServed: "1,478",
    interestEarned: "1.79",
    name: "HERITAGE COMMUNITY",
    website: "http://www.heritageccu.com",
  },
  "68402": {
    loansProduced: "152.51",
    membersServed: "10,463",
    interestEarned: "12.96",
    name: "VALLEY FIRST",
    website: "http://www.valleyfirstcu.org",
  },
  "68407": {
    loansProduced: "0.29",
    membersServed: "43",
    interestEarned: "0.02",
    name: "ENTERPRISE",
    website: "ecuks.com",
  },
  "68409": {
    loansProduced: "36.71",
    membersServed: "4,157",
    interestEarned: "3.12",
    name: "CABRILLO",
    website: "http://www.cabrillocu.com",
  },
  "68410": {
    loansProduced: "27.21",
    membersServed: "24,365",
    interestEarned: "2.31",
    name: "UNITED CONSUMERS",
    website: "http://www.uccumo.com",
  },
  "68411": {
    loansProduced: "1.39",
    membersServed: "170",
    interestEarned: "0.12",
    name: "BLUESTEM COMMUNITY",
    website: "http://www.bluestemccu.com",
  },
  "68413": {
    loansProduced: "140.87",
    membersServed: "12,942",
    interestEarned: "11.97",
    name: "PACIFIC SERVICE",
    website: "http://www.pacificservice.org",
  },
  "68415": {
    loansProduced: "95.30",
    membersServed: "10,189",
    interestEarned: "8.10",
    name: "SOUTHLAND",
    website: "http://www.southlandcu.org",
  },
  "68416": {
    loansProduced: "201.26",
    membersServed: "15,455",
    interestEarned: "17.11",
    name: "ORANGE COUNTY'S",
    website: "http://www.orangecountyscu.org",
  },
  "68417": {
    loansProduced: "199.39",
    membersServed: "23,267",
    interestEarned: "16.95",
    name: "FAIRWINDS",
    website: "http://www.fairwinds.org",
  },
  "68420": {
    loansProduced: "0.69",
    membersServed: "48",
    interestEarned: "0.06",
    name: "DIRECTORS CHOICE",
    website: "http://www.directorschoicecu.com/",
  },
  "68421": {
    loansProduced: "176.43",
    membersServed: "12,294",
    interestEarned: "15.00",
    name: "CREDIT UNION WEST",
    website: "http://www.cuwest.org",
  },
  "68423": {
    loansProduced: "19.70",
    membersServed: "2,760",
    interestEarned: "1.67",
    name: "IAA",
    website: "http://www.iaacu.org",
  },
  "68425": {
    loansProduced: "21.46",
    membersServed: "3,164",
    interestEarned: "1.82",
    name: "ILLIANA FINANCIAL",
    website: "http://www.illiana.org",
  },
  "68428": {
    loansProduced: "129.66",
    membersServed: "15,467",
    interestEarned: "11.02",
    name: "PREMIER AMERICA",
    website: "http://www.PremierAmerica.com",
  },
  "68429": {
    loansProduced: "576.12",
    membersServed: "45,796",
    interestEarned: "48.97",
    name: "TRAVIS",
    website: "http://www.traviscu.org",
  },
  "68430": {
    loansProduced: "77.59",
    membersServed: "7,205",
    interestEarned: "6.60",
    name: "LATINO COMMUNITY",
    website: "http://www.latinoccu.org",
  },
  "68433": {
    loansProduced: "248.43",
    membersServed: "23,384",
    interestEarned: "21.12",
    name: "AMERICU",
    website: "http://www.americu.org",
  },
  "68434": {
    loansProduced: "191.74",
    membersServed: "10,999",
    interestEarned: "16.30",
    name: "TEXAS TRUST",
    website: "http://www.texastrustcu.org",
  },
  "68437": {
    loansProduced: "533.22",
    membersServed: "52,094",
    interestEarned: "45.32",
    name: "BELLCO",
    website: "http://www.bellco.org",
  },
  "68439": {
    loansProduced: "98.12",
    membersServed: "9,544",
    interestEarned: "8.34",
    name: "RESOURCE ONE",
    website: "http://r1cu.org",
  },
  "68440": {
    loansProduced: "30.19",
    membersServed: "2,645",
    interestEarned: "2.57",
    name: "MEMBERSOURCE",
    website: "http://www.membersourcecu.org",
  },
  "68441": {
    loansProduced: "80.84",
    membersServed: "6,463",
    interestEarned: "6.87",
    name: "EXCITE",
    website: "http://www.excitecu.org",
  },
  "68442": {
    loansProduced: "34.29",
    membersServed: "3,253",
    interestEarned: "2.91",
    name: "PEOPLESCHOICE",
    website: "http://www.peopleschoicecreditunion",
  },
  "68443": {
    loansProduced: "26.01",
    membersServed: "2,840",
    interestEarned: "2.21",
    name: "COLORADO",
    website: "http://www.ccu.org",
  },
  "68444": {
    loansProduced: "75.35",
    membersServed: "5,443",
    interestEarned: "6.41",
    name: "FIRST",
    website: "http://www.FirstCU.net",
  },
  "68445": {
    loansProduced: "22.02",
    membersServed: "3,454",
    interestEarned: "1.87",
    name: "POWER FINANCIAL",
    website: "http://www.powerfi.org",
  },
  "68447": {
    loansProduced: "13.71",
    membersServed: "2,216",
    interestEarned: "1.17",
    name: "EDUCATION FIRST CREDIT UNION, INC.",
    website: "http://www.educu.org",
  },
  "68448": {
    loansProduced: "711.71",
    membersServed: "53,221",
    interestEarned: "60.50",
    name: "CITIZENS EQUITY FIRST",
    website: "www.cefcu.com",
  },
  "68449": {
    loansProduced: "18.48",
    membersServed: "1,639",
    interestEarned: "1.57",
    name: "LONE STAR",
    website: "http://www.lonestarcu.org",
  },
  "68450": {
    loansProduced: "14.06",
    membersServed: "1,366",
    interestEarned: "1.20",
    name: "ENCURAGE FINANCIAL NETWORK",
    website: "http://WWW.FIRSTFCU.ORG",
  },
  "68451": {
    loansProduced: "3.62",
    membersServed: "260",
    interestEarned: "0.31",
    name: "GENESIS EMPLOYEES",
    website: "http://genesisecu.com",
  },
  "68453": {
    loansProduced: "65.95",
    membersServed: "6,473",
    interestEarned: "5.61",
    name: "SIKORSKY FINANCIAL",
    website: "http://WWW.SIKORSKYCU.org",
  },
  "68456": {
    loansProduced: "187.43",
    membersServed: "21,723",
    interestEarned: "15.93",
    name: "CREDIT UNION OF SOUTHERN CALIFORNIA",
    website: "http://www.cusocal.org",
  },
  "68457": {
    loansProduced: "131.83",
    membersServed: "12,776",
    interestEarned: "11.21",
    name: "FINANCIAL PARTNERS",
    website: "http://www.fpcu.org",
  },
  "68458": {
    loansProduced: "65.66",
    membersServed: "8,184",
    interestEarned: "5.58",
    name: "VENTURA COUNTY",
    website: "https://onlinebanking.vccuonline.ne",
  },
  "68459": {
    loansProduced: "62.26",
    membersServed: "6,621",
    interestEarned: "5.29",
    name: "USC",
    website: "http://www.usccreditunion.org",
  },
  "68460": {
    loansProduced: "248.08",
    membersServed: "15,501",
    interestEarned: "21.09",
    name: "LBS FINANCIAL",
    website: "http://www.lbsfcu.org",
  },
  "68463": {
    loansProduced: "2.14",
    membersServed: "218",
    interestEarned: "0.18",
    name: "NORTH COUNTY",
    website: "http://www.northcountycu.org",
  },
  "68465": {
    loansProduced: "148.28",
    membersServed: "11,515",
    interestEarned: "12.60",
    name: "STAR ONE",
    website: "http://www.starone.org",
  },
  "68469": {
    loansProduced: "3.78",
    membersServed: "290",
    interestEarned: "0.32",
    name: "CATHOLIC UNITED FINANCIAL",
    website: "http://www.catholicunitedcu.org",
  },
  "68471": {
    loansProduced: "165.08",
    membersServed: "12,003",
    interestEarned: "14.03",
    name: "FORT WORTH COMMUNITY",
    website: "http://www.ftwccu.org",
  },
  "68474": {
    loansProduced: "38.04",
    membersServed: "3,361",
    interestEarned: "3.23",
    name: "FIRST U.S. COMMUNITY",
    website: "http://www.firstus.org",
  },
  "68476": {
    loansProduced: "132.19",
    membersServed: "9,155",
    interestEarned: "11.24",
    name: "TROPICAL FINANCIAL",
    website: "http://tropicalfcu.org",
  },
  "68479": {
    loansProduced: "42.46",
    membersServed: "3,979",
    interestEarned: "3.61",
    name: "MUTUAL SECURITY",
    website: "http://www.mscu.net",
  },
  "68482": {
    loansProduced: "81.63",
    membersServed: "4,011",
    interestEarned: "6.94",
    name: "COASTLIFE",
    website: "http://www.coastlifecu.com",
  },
  "68483": {
    loansProduced: "26.43",
    membersServed: "1,631",
    interestEarned: "2.25",
    name: "MATADORS COMMUNITY",
    website: "http://www.matadors.org",
  },
  "68485": {
    loansProduced: "56.86",
    membersServed: "3,143",
    interestEarned: "4.83",
    name: "AMERICAN FIRST",
    website: "http://amerfirst.org",
  },
  "68487": {
    loansProduced: "4.70",
    membersServed: "809",
    interestEarned: "0.40",
    name: "MEMBERS",
    website: "http://www.memberscu.coop",
  },
  "68490": {
    loansProduced: "1,218.02",
    membersServed: "120,505",
    interestEarned: "103.53",
    name: "VYSTAR",
    website: "http://www.vystarcu.org",
  },
  "68491": {
    loansProduced: "0.68",
    membersServed: "239",
    interestEarned: "0.06",
    name: "SILVERADO",
    website: "http://www.silveradocu.com",
  },
  "68493": {
    loansProduced: "53.08",
    membersServed: "5,103",
    interestEarned: "4.51",
    name: "WATER AND POWER COMMUNITY",
    website: "http://www.WPCU.ORG",
  },
  "68494": {
    loansProduced: "105.17",
    membersServed: "14,621",
    interestEarned: "8.94",
    name: "FRONTWAVE",
    website: "http://www.frontwavecu.com",
  },
  "68495": {
    loansProduced: "26.13",
    membersServed: "1,712",
    interestEarned: "2.22",
    name: "INTREPID",
    website: "http://www.intrepidcu.org",
  },
  "68502": {
    loansProduced: "68.52",
    membersServed: "4,558",
    interestEarned: "5.82",
    name: "COPPER STATE",
    website: "http://www.copperstatecu.org",
  },
  "68503": {
    loansProduced: "60.01",
    membersServed: "5,795",
    interestEarned: "5.10",
    name: "FIRST CITY",
    website: "http://Firstcitycu.org",
  },
  "68505": {
    loansProduced: "85.18",
    membersServed: "6,129",
    interestEarned: "7.24",
    name: "INTOUCH",
    website: "http://www.itcu.org",
  },
  "68508": {
    loansProduced: "2.34",
    membersServed: "8,374",
    interestEarned: "0.20",
    name: "CANALS & TRAILS",
    website: "http://www.canals-trailscu.org",
  },
  "68510": {
    loansProduced: "56.26",
    membersServed: "5,778",
    interestEarned: "4.78",
    name: "COMMUNITY 1ST",
    website: "http://www.c1stcu.com",
  },
  "68511": {
    loansProduced: "139.27",
    membersServed: "9,143",
    interestEarned: "11.84",
    name: "CONNEX",
    website: "http://www.connexcu.org",
  },
  "68513": {
    loansProduced: "0.36",
    membersServed: "70",
    interestEarned: "0.03",
    name: "SMART CHOICE",
    website: "http://smartchoicecreditunion.com",
  },
  "68517": {
    loansProduced: "32.47",
    membersServed: "4,796",
    interestEarned: "2.76",
    name: "VERITY",
    website: "http://www.veritycu.com",
  },
  "68524": {
    loansProduced: "9.83",
    membersServed: "1,012",
    interestEarned: "0.84",
    name: "FIRST COAST COMMUNITY",
    website: "http://www.firstcoastccu.com",
  },
  "68529": {
    loansProduced: "0.77",
    membersServed: "42",
    interestEarned: "0.07",
    name: "LIGHT COMMERCE",
    website: "http://www.lightcommercecu.org",
  },
  "68530": {
    loansProduced: "79.04",
    membersServed: "8,006",
    interestEarned: "6.72",
    name: "FIRST COMMUNITY",
    website: "http://www.myfirstccu.org",
  },
  "68531": {
    loansProduced: "41.73",
    membersServed: "4,164",
    interestEarned: "3.55",
    name: "MOUNTAIN",
    website: "http://www.mountaincu.org",
  },
  "68536": {
    loansProduced: "525.61",
    membersServed: "49,979",
    interestEarned: "44.68",
    name: "GENISYS",
    website: "http://www.genisyscu.org",
  },
  "68540": {
    loansProduced: "38.23",
    membersServed: "4,718",
    interestEarned: "3.25",
    name: "PREMIER ONE",
    website: "http://premieronecu.org",
  },
  "68542": {
    loansProduced: "8.37",
    membersServed: "1,194",
    interestEarned: "0.71",
    name: "KH CREDIT UNION, INC.",
    website: "http://www.khnetworkcu.com",
  },
  "68545": {
    loansProduced: "7.14",
    membersServed: "452",
    interestEarned: "0.61",
    name: "MY",
    website: "http://www.mycutx.com",
  },
  "68546": {
    loansProduced: "8.15",
    membersServed: "1,092",
    interestEarned: "0.69",
    name: "EVERGREENDIRECT",
    website: "http://www.evergreendirect.org",
  },
  "68547": {
    loansProduced: "0.01",
    membersServed: "3",
    interestEarned: "0.00",
    name: "CATHOLICS UNITED",
    website: "https://catholicsunitedcreditunion.com/",
  },
  "68549": {
    loansProduced: "3.13",
    membersServed: "349",
    interestEarned: "0.27",
    name: "MEDIA CITY",
    website: "http://www.mediacitycu.org",
  },
  "68557": {
    loansProduced: "1.04",
    membersServed: "90",
    interestEarned: "0.09",
    name: "CROSSROADS",
    website: "http://www.crossroadscreditunion.co",
  },
  "68558": {
    loansProduced: "351.72",
    membersServed: "28,854",
    interestEarned: "29.90",
    name: "VANTAGE WEST",
    website: "http://www.vantagewest.org",
  },
  "68559": {
    loansProduced: "17.05",
    membersServed: "2,528",
    interestEarned: "1.45",
    name: "TVA COMMUNITY CREDIT UNION",
    website: "http://www.tvaccu.com",
  },
  "68560": {
    loansProduced: "19.11",
    membersServed: "3,143",
    interestEarned: "1.62",
    name: "RED ROCKS",
    website: "http://www.redrocks.org",
  },
  "68561": {
    loansProduced: "12.83",
    membersServed: "2,692",
    interestEarned: "1.09",
    name: "CREDIT UNION OF OHIO",
    website: "http://www.cuofohio.org",
  },
  "68562": {
    loansProduced: "4.82",
    membersServed: "585",
    interestEarned: "0.41",
    name: "MEMBERS SOURCE",
    website: "http://www.ms-cu.com",
  },
  "68563": {
    loansProduced: "47.46",
    membersServed: "3,603",
    interestEarned: "4.03",
    name: "DOWNEAST",
    website: "http://www.downeastcu.com",
  },
  "68565": {
    loansProduced: "51.61",
    membersServed: "14,769",
    interestEarned: "4.39",
    name: "ELEVATIONS",
    website: "http://www.elevationscu.com",
  },
  "68567": {
    loansProduced: "20.16",
    membersServed: "1,843",
    interestEarned: "1.71",
    name: "AMERICAN SOUTHWEST",
    website: "http://www.ascu.org",
  },
  "68571": {
    loansProduced: "45.13",
    membersServed: "31,727",
    interestEarned: "3.84",
    name: "CREDIT UNION OF GEORGIA",
    website: "http://www.cuofga.org",
  },
  "68572": {
    loansProduced: "90.81",
    membersServed: "7,220",
    interestEarned: "7.72",
    name: "VIA CREDIT UNION",
    website: "http://www.viacu.org",
  },
  "68574": {
    loansProduced: "645.84",
    membersServed: "50,906",
    interestEarned: "54.90",
    name: "GENERAL ELECTRIC",
    website: "http://www.gecreditunion.org",
  },
  "68575": {
    loansProduced: "163.82",
    membersServed: "13,215",
    interestEarned: "13.92",
    name: "MAX",
    website: "http://www.mymax.com",
  },
  "68576": {
    loansProduced: "279.41",
    membersServed: "19,620",
    interestEarned: "23.75",
    name: "KITSAP",
    website: "https://www.kitsapcu.org",
  },
  "68577": {
    loansProduced: "86.89",
    membersServed: "6,406",
    interestEarned: "7.39",
    name: "INDIANA UNIVERSITY",
    website: "http://www.iucu.org",
  },
  "68579": {
    loansProduced: "732.11",
    membersServed: "77,757",
    interestEarned: "62.23",
    name: "PATELCO",
    website: "http://WWW.PATELCO.ORG",
  },
  "68581": {
    loansProduced: "197.28",
    membersServed: "19,409",
    interestEarned: "16.77",
    name: "LGE COMMUNITY",
    website: "http://www.LGEccu.org",
  },
  "68583": {
    loansProduced: "135.58",
    membersServed: "11,302",
    interestEarned: "11.52",
    name: "AVADIAN",
    website: "http://www.avadiancu.com",
  },
  "68584": {
    loansProduced: "6.65",
    membersServed: "986",
    interestEarned: "0.56",
    name: "HARVESTER FINANCIAL",
    website: "http://www.harvesterfcu.org",
  },
  "68586": {
    loansProduced: "18.19",
    membersServed: "2,123",
    interestEarned: "1.55",
    name: "HERITAGE SOUTH",
    website: "http://www.myhscu.com",
  },
  "68587": {
    loansProduced: "23.56",
    membersServed: "1,771",
    interestEarned: "2.00",
    name: "ASSOCIATED SCHOOL EMPLOYEES",
    website: "http://asecu.com",
  },
  "68588": {
    loansProduced: "486.12",
    membersServed: "37,102",
    interestEarned: "41.32",
    name: "CONSUMERS COOPERATIVE",
    website: "http://www.myconsumers.org",
  },
  "68589": {
    loansProduced: "34.66",
    membersServed: "3,101",
    interestEarned: "2.95",
    name: "UNITY ONE",
    website: "http://www.unityone.org",
  },
  "68595": {
    loansProduced: "65.74",
    membersServed: "12,089",
    interestEarned: "5.59",
    name: "ALABAMA ONE",
    website: "http://www.alabamaone.org",
  },
  "68596": {
    loansProduced: "6.31",
    membersServed: "937",
    interestEarned: "0.54",
    name: "FIRST TRUST",
    website: "http://www.firsttrustcu.com",
  },
  "68597": {
    loansProduced: "38.57",
    membersServed: "34,476",
    interestEarned: "3.28",
    name: "DECATUR EARTHMOVER",
    website: "www.decu.com",
  },
  "68598": {
    loansProduced: "170.65",
    membersServed: "20,743",
    interestEarned: "14.51",
    name: "EMPEOPLE",
    website: "http://www.deereemployeescu.com",
  },
  "68599": {
    loansProduced: "67.82",
    membersServed: "7,729",
    interestEarned: "5.76",
    name: "SOUTHEAST FINANCIAL",
    website: "http://www.southeastfinancial.org",
  },
  "68600": {
    loansProduced: "820.66",
    membersServed: "94,262",
    interestEarned: "69.76",
    name: "MIDFLORIDA",
    website: "http://www.midflorida.com",
  },
  "68601": {
    loansProduced: "370.47",
    membersServed: "43,921",
    interestEarned: "31.49",
    name: "WINGS FINANCIAL",
    website: "http://www.wingsfinancial.com",
  },
  "68602": {
    loansProduced: "30.61",
    membersServed: "2,295",
    interestEarned: "2.60",
    name: "MYGEORGIA",
    website: "http://www.mygacu.com",
  },
  "68603": {
    loansProduced: "0.79",
    membersServed: "68",
    interestEarned: "0.07",
    name: "NUEVA ESPERANZA COMMUNITY",
    website: "www.nuevaesperanzaccu.org",
  },
  "68604": {
    loansProduced: "47.32",
    membersServed: "8,126",
    interestEarned: "4.02",
    name: "MAGNIFI FINANCIAL",
    website: "http://www.mymagnifi.org",
  },
  "68605": {
    loansProduced: "175.81",
    membersServed: "14,497",
    interestEarned: "14.94",
    name: "FIRST SERVICE",
    website: "https://www.fscu.com",
  },
  "68606": {
    loansProduced: "73.99",
    membersServed: "3,500",
    interestEarned: "6.29",
    name: "MOBILITY CREDIT UNION",
    website: "http://www.mobilitycu.com",
  },
  "68607": {
    loansProduced: "178.77",
    membersServed: "18,147",
    interestEarned: "15.20",
    name: "GEORGIA UNITED",
    website: "http://www.gucu.org",
  },
  "68608": {
    loansProduced: "96.85",
    membersServed: "11,768",
    interestEarned: "8.23",
    name: "TEXELL",
    website: "http://www.texell.org",
  },
  "68609": {
    loansProduced: "45.90",
    membersServed: "4,061",
    interestEarned: "3.90",
    name: "OUR COMMUNITY",
    website: "http://www.ourcu.com",
  },
  "68613": {
    loansProduced: "78.59",
    membersServed: "10,793",
    interestEarned: "6.68",
    name: "ONE NEVADA",
    website: "https://www.onenevada.org",
  },
  "68615": {
    loansProduced: "654.32",
    membersServed: "31,597",
    interestEarned: "55.62",
    name: "RALLY",
    website: "rallycu.com",
  },
  "68616": {
    loansProduced: "40.68",
    membersServed: "3,685",
    interestEarned: "3.46",
    name: "DENVER COMMUNITY CU D.B.A. ZING CU",
    website: "http://www.myzing.com",
  },
  "68617": {
    loansProduced: "16.83",
    membersServed: "1,916",
    interestEarned: "1.43",
    name: "AUTOTRUCK FINANCIAL",
    website: "http://www.autotruckfcu.org",
  },
  "68620": {
    loansProduced: "5.47",
    membersServed: "966",
    interestEarned: "0.46",
    name: "SOUNDVIEW FINANCIAL",
    website: "http://www.soundviewfcu.org",
  },
  "68622": {
    loansProduced: "12.88",
    membersServed: "1,097",
    interestEarned: "1.10",
    name: "SNOCOPE",
    website: "http://snocope.org/",
  },
  "68623": {
    loansProduced: "40.87",
    membersServed: "4,950",
    interestEarned: "3.47",
    name: "GCS",
    website: "http://revitycu.com",
  },
  "68628": {
    loansProduced: "124.91",
    membersServed: "10,231",
    interestEarned: "10.62",
    name: "FAMILY SAVINGS",
    website: "http://www.familysavingscu.com",
  },
  "68629": {
    loansProduced: "28.66",
    membersServed: "5,184",
    interestEarned: "2.44",
    name: "BLUE EAGLE",
    website: "http://www.blueeaglecreditunion.com",
  },
  "68631": {
    loansProduced: "184.78",
    membersServed: "12,496",
    interestEarned: "15.71",
    name: "COMMUNITY FINANCIAL",
    website: "http://WWW.CFCU.ORG",
  },
  "68632": {
    loansProduced: "170.35",
    membersServed: "14,257",
    interestEarned: "14.48",
    name: "LAFCU",
    website: "http://www.lafcu.com",
  },
  "68638": {
    loansProduced: "41.84",
    membersServed: "4,014",
    interestEarned: "3.56",
    name: "THE SOUTHERN",
    website: "http://www.southernonline.org",
  },
  "68639": {
    loansProduced: "12.17",
    membersServed: "1,736",
    interestEarned: "1.03",
    name: "HAR-CO",
    website: "http://www.harcocu.org",
  },
  "68641": {
    loansProduced: "57.47",
    membersServed: "5,673",
    interestEarned: "4.88",
    name: "CORNERSTONE COMMUNITY FINANCIAL",
    website: "http://www.ccfinancial.com",
  },
  "68643": {
    loansProduced: "116.28",
    membersServed: "9,728",
    interestEarned: "9.88",
    name: "FIVEPOINT",
    website: "http://www.5pointcu.org",
  },
  "68645": {
    loansProduced: "2,229.20",
    membersServed: "203,506",
    interestEarned: "189.48",
    name: "SUNCOAST",
    website: "http://www.suncoastcreditunion.com",
  },
  "68646": {
    loansProduced: "99.55",
    membersServed: "13,563",
    interestEarned: "8.46",
    name: "FINANCIAL PLUS",
    website: "https://www.myfpcu.com",
  },
  "68647": {
    loansProduced: "32.72",
    membersServed: "3,248",
    interestEarned: "2.78",
    name: "PARK SIDE FINANCIAL",
    website: "http://www.parksidefcu.com",
  },
  "68651": {
    loansProduced: "191.64",
    membersServed: "28,568",
    interestEarned: "16.29",
    name: "SPIRE",
    website: "http://www.myspire.com",
  },
  "68653": {
    loansProduced: "181.14",
    membersServed: "14,586",
    interestEarned: "15.40",
    name: "COASTHILLS",
    website: "http://www.coasthills.coop",
  },
  "68656": {
    loansProduced: "173.96",
    membersServed: "17,390",
    interestEarned: "14.79",
    name: "OREGON STATE",
    website: "http://www.oregonstatecu.com",
  },
  "68657": {
    loansProduced: "39.09",
    membersServed: "4,544",
    interestEarned: "3.32",
    name: "NUTMEG STATE FINANCIAL",
    website: "http://www.nutmegstatefcu.org",
  },
  "68658": {
    loansProduced: "112.24",
    membersServed: "8,154",
    interestEarned: "9.54",
    name: "FINANCIAL CENTER FIRST",
    website: "http://www.fcfcu.com",
  },
  "68659": {
    loansProduced: "192.68",
    membersServed: "17,432",
    interestEarned: "16.38",
    name: "AMERICAN EAGLE FINANCIAL",
    website: "http://www.americaneagle.org",
  },
  "68660": {
    loansProduced: "261.02",
    membersServed: "33,057",
    interestEarned: "22.19",
    name: "ROGUE",
    website: "http://www.roguecu.org",
  },
  "68661": {
    loansProduced: "60.49",
    membersServed: "4,702",
    interestEarned: "5.14",
    name: "MEMBERS HERITAGE",
    website: "http://www.membersheritage.org",
  },
  "68662": {
    loansProduced: "22.45",
    membersServed: "3,162",
    interestEarned: "1.91",
    name: "FINEX",
    website: "http://www.finexcu.org",
  },
  "68663": {
    loansProduced: "110.29",
    membersServed: "10,222",
    interestEarned: "9.37",
    name: "ITHINK FINANCIAL",
    website: "http://ithinkfi.org",
  },
  "68664": {
    loansProduced: "75.43",
    membersServed: "4,948",
    interestEarned: "6.41",
    name: "UNION SQUARE",
    website: "http://www.unionsquare.org",
  },
  "68665": {
    loansProduced: "48.16",
    membersServed: "7,115",
    interestEarned: "4.09",
    name: "SUPERIOR CREDIT UNION, INC",
    website: "http://www.superiorcu.com",
  },
  "68666": {
    loansProduced: "79.18",
    membersServed: "6,015",
    interestEarned: "6.73",
    name: "TRANSCEND",
    website: "http://www.transcendcu.com",
  },
  "68667": {
    loansProduced: "37.87",
    membersServed: "3,688",
    interestEarned: "3.22",
    name: "SOUTHPOINT FINANCIAL",
    website: "http://www.southpointfinancial.com",
  },
  "68668": {
    loansProduced: "41.75",
    membersServed: "2,983",
    interestEarned: "3.55",
    name: "1ST NORTHERN CALIFORNIA",
    website: "http://www.1stnorcalcu.org/",
  },
  "68669": {
    loansProduced: "39.35",
    membersServed: "3,414",
    interestEarned: "3.34",
    name: "OREGONIANS",
    website: "http://www.oregonianscu.com",
  },
  "68670": {
    loansProduced: "480.14",
    membersServed: "34,990",
    interestEarned: "40.81",
    name: "ROBINS FINANCIAL",
    website: "http://www.robinsfcu.org",
  },
  "68671": {
    loansProduced: "738.45",
    membersServed: "53,300",
    interestEarned: "62.77",
    name: "ENT",
    website: "http://www.ent.com",
  },
  "68672": {
    loansProduced: "87.98",
    membersServed: "7,496",
    interestEarned: "7.48",
    name: "CRANE",
    website: "http://www.cranecu.org",
  },
  "68673": {
    loansProduced: "27.34",
    membersServed: "2,169",
    interestEarned: "2.32",
    name: "SOUTHEASTERN",
    website: "http://www.southeasterncu.com",
  },
  "68674": {
    loansProduced: "3.39",
    membersServed: "403",
    interestEarned: "0.29",
    name: "CORNERSTONE COMMUNITY",
    website: "http://www.cornerstoneccu.com",
  },
  "68675": {
    loansProduced: "0.23",
    membersServed: "25",
    interestEarned: "0.02",
    name: "JAFARI NO-INTEREST",
    website: "http://www.JafariCU.com",
  },
  "68676": {
    loansProduced: "6.40",
    membersServed: "664",
    interestEarned: "0.54",
    name: "SMW FINANCIAL",
    website: "http://www.smwfcu.com",
  },
  "68677": {
    loansProduced: "3.37",
    membersServed: "395",
    interestEarned: "0.29",
    name: "VOCAL",
    website: "http://www.vocal.coop",
  },
  "68678": {
    loansProduced: "70.90",
    membersServed: "7,832",
    interestEarned: "6.03",
    name: "KELLOGG COMMUNITY",
    website: "www.kelloggccu.org",
  },
  "68679": {
    loansProduced: "32.42",
    membersServed: "4,564",
    interestEarned: "2.76",
    name: "ADVANTAGE ONE",
    website: "https://www.myaocu.com/",
  },
  "68680": {
    loansProduced: "6.00",
    membersServed: "641",
    interestEarned: "0.51",
    name: "AMERICA'S FIRST NETWORK",
    website: "http://afncu.com",
  },
  "68681": {
    loansProduced: "20.41",
    membersServed: "2,705",
    interestEarned: "1.73",
    name: "AMPLIFY",
    website: "http://www.goamplify.com",
  },
  "68682": {
    loansProduced: "90.18",
    membersServed: "6,534",
    interestEarned: "7.67",
    name: "MY COMMUNITY",
    website: "http://www.mccu.coop",
  },
  "68683": {
    loansProduced: "92.92",
    membersServed: "6,026",
    interestEarned: "7.90",
    name: "COOSA VALLEY",
    website: "http://mycvcu.org",
  },
  "68684": {
    loansProduced: "37.13",
    membersServed: "28,289",
    interestEarned: "3.16",
    name: "MIDWEST MEMBERS",
    website: "http://www.midmembers.org",
  },
  "68685": {
    loansProduced: "26.06",
    membersServed: "2,747",
    interestEarned: "2.22",
    name: "CENTRICITY",
    website: "http://www.centricity.org",
  },
  "68686": {
    loansProduced: "7.25",
    membersServed: "1,041",
    interestEarned: "0.62",
    name: "BAYLANDS FAMILY",
    website: "http://www.baylandsfcu.org",
  },
  "68687": {
    loansProduced: "18.36",
    membersServed: "1,435",
    interestEarned: "1.56",
    name: "ROCK VALLEY",
    website: "http://www.rockvalleycreditunion.or",
  },
  "68688": {
    loansProduced: "3.42",
    membersServed: "362",
    interestEarned: "0.29",
    name: "RAILROAD",
    website: "https://railroadccu.com",
  },
  "68689": {
    loansProduced: "62.58",
    membersServed: "5,702",
    interestEarned: "5.32",
    name: "VALLEY FINANCIAL",
    website: "http://www.valleyfcu.com",
  },
  "68690": {
    loansProduced: "79.56",
    membersServed: "8,772",
    interestEarned: "6.76",
    name: "PFCU",
    website: "http://www.pfcu4me.com",
  },
  "68691": {
    loansProduced: "8.96",
    membersServed: "20,771",
    interestEarned: "0.76",
    name: "TRU FI",
    website: "http://www.truficu.org",
  },
  "68693": {
    loansProduced: "83.34",
    membersServed: "9,483",
    interestEarned: "7.08",
    name: "MERRIMACK VALLEY",
    website: "http://www.mvcu.com",
  },
  "68694": {
    loansProduced: "129.30",
    membersServed: "10,445",
    interestEarned: "10.99",
    name: "GULF WINDS",
    website: "https://www.gogulfwinds.com",
  },
  "68696": {
    loansProduced: "29.24",
    membersServed: "3,722",
    interestEarned: "2.49",
    name: "NORTHERN",
    website: "http://www.mynorthern.com",
  },
  "68697": {
    loansProduced: "32.15",
    membersServed: "3,703",
    interestEarned: "2.73",
    name: "ST. CLOUD FINANCIAL",
    website: "https://www.stcloudfcu.coop",
  },
  "68698": {
    loansProduced: "15.46",
    membersServed: "1,541",
    interestEarned: "1.31",
    name: "GO ENERGY FINANCIAL",
    website: "http://goenergyfin.com",
  },
  "68700": {
    loansProduced: "271.74",
    membersServed: "16,906",
    interestEarned: "23.10",
    name: "POTLATCH NO. 1 FINANCIAL",
    website: "https://www.p1fcu.org",
  },
  "68701": {
    loansProduced: "16.70",
    membersServed: "1,719",
    interestEarned: "1.42",
    name: "AUBURN UNIVERSITY",
    website: "http://myaucu.org",
  },
  "68702": {
    loansProduced: "389.05",
    membersServed: "25,793",
    interestEarned: "33.07",
    name: "ADDITION FINANCIAL",
    website: "http://www.additionfi.com",
  },
  "68703": {
    loansProduced: "33.12",
    membersServed: "5,707",
    interestEarned: "2.82",
    name: "JOLT",
    website: "http://www.joltcu.com",
  },
  "68705": {
    loansProduced: "405.82",
    membersServed: "47,593",
    interestEarned: "34.49",
    name: "HUDSON VALLEY",
    website: "http://www.hvcu.org",
  },
  "68706": {
    loansProduced: "29.84",
    membersServed: "17,717",
    interestEarned: "2.54",
    name: "ORLANDO",
    website: "http://www.OrlandoCreditUnion.org",
  },
  "68707": {
    loansProduced: "20.83",
    membersServed: "3,100",
    interestEarned: "1.77",
    name: "CARDINAL CREDIT UNION, INC.",
    website: "http://www.cardinalcu.com",
  },
  "68708": {
    loansProduced: "182.38",
    membersServed: "24,342",
    interestEarned: "15.50",
    name: "DORT FINANCIAL",
    website: "http://DORTONLINE.ORG",
  },
  "68709": {
    loansProduced: "9.50",
    membersServed: "837",
    interestEarned: "0.81",
    name: "CATHOLIC VANTAGE FINANCIAL",
    website: "https://www.mycvf.org",
  },
  "68710": {
    loansProduced: "110.72",
    membersServed: "13,494",
    interestEarned: "9.41",
    name: "SUNMARK",
    website: "http://sunmark.org",
  },
  "68711": {
    loansProduced: "73.49",
    membersServed: "5,128",
    interestEarned: "6.25",
    name: "HERITAGE FINANCIAL",
    website: "https://heritagefcu.com",
  },
  "68712": {
    loansProduced: "272.13",
    membersServed: "78,756",
    interestEarned: "23.13",
    name: "VALLEY STRONG",
    website: "http://www.valleystrong.com",
  },
  "68713": {
    loansProduced: "508.34",
    membersServed: "30,551",
    interestEarned: "43.21",
    name: "DESERT FINANCIAL",
    website: "http://www.desertfinancial.com",
  },
  "68714": {
    loansProduced: "21.63",
    membersServed: "27,757",
    interestEarned: "1.84",
    name: "MARSHLAND",
    website: "https://www.marshlandcu.com",
  },
  "68716": {
    loansProduced: "135.44",
    membersServed: "29,678",
    interestEarned: "11.51",
    name: "LAUNCH CREDIT UNION",
    website: "http://launchcu.com",
  },
  "68717": {
    loansProduced: "58.92",
    membersServed: "4,214",
    interestEarned: "5.01",
    name: "INTERSTATE",
    website: "https://interstatecu.org/",
  },
  "68718": {
    loansProduced: "89.64",
    membersServed: "10,178",
    interestEarned: "7.62",
    name: "TRUE COMMUNITY",
    website: "http://www.trueccu.com",
  },
  "68719": {
    loansProduced: "4.47",
    membersServed: "562",
    interestEarned: "0.38",
    name: "PERFECT CIRCLE",
    website: "http://www.pccu.com",
  },
  "68720": {
    loansProduced: "18.41",
    membersServed: "1,636",
    interestEarned: "1.56",
    name: "PANHANDLE",
    website: "http://www.panhandlecu.org",
  },
  "68721": {
    loansProduced: "128.78",
    membersServed: "16,817",
    interestEarned: "10.95",
    name: "HIWAY",
    website: "http://www.hiway.org",
  },
  "68722": {
    loansProduced: "201.52",
    membersServed: "29,228",
    interestEarned: "17.13",
    name: "TRUSTONE FINANCIAL",
    website: "https://www.trustonefinancial.org",
  },
  "68723": {
    loansProduced: "83.23",
    membersServed: "7,697",
    interestEarned: "7.07",
    name: "RADIANT",
    website: "https://www.radiantcu.org",
  },
  "68726": {
    loansProduced: "52.03",
    membersServed: "3,300",
    interestEarned: "4.42",
    name: "HARVESTERS",
    website: "http://www.myharvesterscu.com",
  },
  "68727": {
    loansProduced: "93.44",
    membersServed: "58,049",
    interestEarned: "7.94",
    name: "CREDIT UNION 1",
    website: "http://www.creditunion1.org",
  },
  "68729": {
    loansProduced: "14.12",
    membersServed: "1,971",
    interestEarned: "1.20",
    name: "IGNITE",
    website: "http://www.allegancu.com",
  },
  "68730": {
    loansProduced: "187.82",
    membersServed: "24,134",
    interestEarned: "15.96",
    name: "ARIZONA FINANCIAL",
    website: "http://www.azfcu.org",
  },
  "68731": {
    loansProduced: "71.05",
    membersServed: "6,312",
    interestEarned: "6.04",
    name: "TOPLINE FINANCIAL",
    website: "http://www.toplinecu.com",
  },
  "68732": {
    loansProduced: "37.61",
    membersServed: "4,027",
    interestEarned: "3.20",
    name: "INNOVATIONS FINANCIAL",
    website: "http://www.innovationsfcu.org",
  },
  "68734": {
    loansProduced: "309.56",
    membersServed: "18,553",
    interestEarned: "26.31",
    name: "PEN AIR",
    website: "https://www.penair.org",
  },
  "68735": {
    loansProduced: "141.49",
    membersServed: "13,681",
    interestEarned: "12.03",
    name: "MOBILOIL",
    website: "http://www.mobiloilcu.org",
  },
  "68736": {
    loansProduced: "59.87",
    membersServed: "3,369",
    interestEarned: "5.09",
    name: "ESSENTIAL",
    website: "http://www.essentialcu.org",
  },
  "68737": {
    loansProduced: "29.02",
    membersServed: "2,569",
    interestEarned: "2.47",
    name: "SPACE AGE",
    website: "http://www.spaceagefcu.coop",
  },
  "68738": {
    loansProduced: "12.26",
    membersServed: "1,018",
    interestEarned: "1.04",
    name: "MEMORIAL EMPLOYEES FINANCIAL",
    website: "http://www.mefcu.org",
  },
  "81200": {
    loansProduced: "3.28",
    membersServed: "198",
    interestEarned: "0.28",
    name: "PEOPLES ENERGY",
    website: "http://peoplesenergycu.org",
  },
  "81402": {
    loansProduced: "28.67",
    membersServed: "33,651",
    interestEarned: "2.44",
    name: "MEMBERSALLIANCE",
    website: "http://www.membersalliance.org",
  },
  "81416": {
    loansProduced: "24.43",
    membersServed: "3,444",
    interestEarned: "2.08",
    name: "STALEY",
    website: "http://www.staleycu.com",
  },
  "81553": {
    loansProduced: "1.50",
    membersServed: "109",
    interestEarned: "0.13",
    name: "EFFINGHAM HIGHWAY",
    website: null,
  },
  "81688": {
    loansProduced: "8.80",
    membersServed: "3,071",
    interestEarned: "0.75",
    name: "UNITED EQUITY",
    website: "https://www.uecu.com",
  },
  "82504": {
    loansProduced: "2.41",
    membersServed: "117",
    interestEarned: "0.20",
    name: "BOISE FIRE DEPARTMENT",
    website: "www.boisefirecu.com",
  },
  "82527": {
    loansProduced: "0.10",
    membersServed: "13",
    interestEarned: "0.01",
    name: "MINI-CASSIA EMPL.",
    website: null,
  },
  "82547": {
    loansProduced: "8.86",
    membersServed: "992",
    interestEarned: "0.75",
    name: "MEMBERS PREFERRED",
    website: "http://www.memberspreferred.org",
  },
  "82753": {
    loansProduced: "0.06",
    membersServed: "47",
    interestEarned: "0.01",
    name: "BLUFFTON MOTOR WORKS/FRANKLIN ELECT",
    website: null,
  },
  "82762": {
    loansProduced: "0.09",
    membersServed: "32",
    interestEarned: "0.01",
    name: "HAMMOND FIREFIGHTERS ASSN.",
    website: null,
  },
  "82769": {
    loansProduced: "4.65",
    membersServed: "370",
    interestEarned: "0.40",
    name: "PROFESSIONAL POLICE OFFICERS",
    website: "https://www.ppocu.org",
  },
  "82770": {
    loansProduced: "3.33",
    membersServed: "236",
    interestEarned: "0.28",
    name: "INDIANAPOLIS POST OFFICE",
    website: "http://ipocu.org",
  },
  "82772": {
    loansProduced: "0.00",
    membersServed: "0",
    interestEarned: "0.00",
    name: "EAST CHICAGO FIREMEN'S",
    website: null,
  },
  "82789": {
    loansProduced: "0.90",
    membersServed: "100",
    interestEarned: "0.08",
    name: "WESTERN INDIANA CREDIT UNION",
    website: "http://www.westernindianacu.com",
  },
  "82791": {
    loansProduced: "14.34",
    membersServed: "1,284",
    interestEarned: "1.22",
    name: "BEACON",
    website: "http://www.beaconcu.org",
  },
  "82793": {
    loansProduced: "4.39",
    membersServed: "619",
    interestEarned: "0.37",
    name: "KEMBA INDIANAPOLIS",
    website: "http://www.mykemba.org",
  },
  "82794": {
    loansProduced: "81.57",
    membersServed: "10,578",
    interestEarned: "6.93",
    name: "INTERRA",
    website: "http://www.interracu.com",
  },
  "86063": {
    loansProduced: "3.28",
    membersServed: "473",
    interestEarned: "0.28",
    name: "POSTCITY FINANCIAL",
    website: "http://www.postcitycu.org",
  },
  "86752": {
    loansProduced: "102.98",
    membersServed: "9,557",
    interestEarned: "8.75",
    name: "CLARK COUNTY",
    website: "http://www.ccculv.org",
  },
  "86755": {
    loansProduced: "23.05",
    membersServed: "1,189",
    interestEarned: "1.96",
    name: "FINANCIAL HORIZONS",
    website: "http://www.fhcunv.org",
  },
  "86756": {
    loansProduced: "5.08",
    membersServed: "850",
    interestEarned: "0.43",
    name: "PLUS",
    website: "http://www.pluscu.org",
  },
  "95008": {
    loansProduced: "8.35",
    membersServed: "724",
    interestEarned: "0.71",
    name: "COMMUNITY STAR",
    website: "http://www.commstar.org",
  },
  "95015": {
    loansProduced: "37.69",
    membersServed: "26,423",
    interestEarned: "3.20",
    name: "KANE COUNTY TEACHERS",
    website: "http://www.kctcu.org",
  },
  "95042": {
    loansProduced: "5.23",
    membersServed: "15,283",
    interestEarned: "0.44",
    name: "BLACKHAWK AREA",
    website: "http://www.bacu.org",
  },
  "95052": {
    loansProduced: "13.89",
    membersServed: "1,105",
    interestEarned: "1.18",
    name: "SOUTH BAY",
    website: "http://southbaycu.com",
  },
  "95071": {
    loansProduced: "59.99",
    membersServed: "4,701",
    interestEarned: "5.10",
    name: "CAHP",
    website: "http://cahpcu.org",
  },
  "95072": {
    loansProduced: "2.18",
    membersServed: "256",
    interestEarned: "0.19",
    name: "EL MONTE COMMUNITY",
    website: "www.elmonteccu.org",
  },
  "95073": {
    loansProduced: "1.54",
    membersServed: "2,434",
    interestEarned: "0.13",
    name: "MID-CITIES",
    website: "http://www.midcitiesfcu.org",
  },
  "95107": {
    loansProduced: "22.40",
    membersServed: "2,011",
    interestEarned: "1.90",
    name: "OHIO EDUCATIONAL",
    website: "http://ohecu.com",
  },
  "95742": {
    loansProduced: "2.39",
    membersServed: "161",
    interestEarned: "0.20",
    name: "POST OFFICE CU OF MD, INC.",
    website: "www.pocumd.org",
  },
  "95786": {
    loansProduced: "1.27",
    membersServed: "134",
    interestEarned: "0.11",
    name: "NORTH COAST CREDIT UNION",
    website: "http://www.nccuonline.com",
  },
  "95802": {
    loansProduced: "13.12",
    membersServed: "2,871",
    interestEarned: "1.12",
    name: "TRUPARTNER",
    website: "http://www.Trupartnercu.org",
  },
  "95821": {
    loansProduced: "6.86",
    membersServed: "407",
    interestEarned: "0.58",
    name: "TALERIS",
    website: "http://www.taleriscu.org",
  },
  "95826": {
    loansProduced: "4.64",
    membersServed: "23,019",
    interestEarned: "0.39",
    name: "CHICAGO POST OFFICE EMPLOYEES",
    website: "http://cpoecu.com",
  },
  "96066": {
    loansProduced: "0.01",
    membersServed: "973",
    interestEarned: "0.00",
    name: "SERVICES",
    website: null,
  },
  "96071": {
    loansProduced: "51.25",
    membersServed: "44,961",
    interestEarned: "4.36",
    name: "ABBOTT LABORATORIES",
    website: "http://www.alecu.org",
  },
  "96084": {
    loansProduced: "7.32",
    membersServed: "1,150",
    interestEarned: "0.62",
    name: "BOULDER DAM",
    website: "https://www.boulderdamcu.org",
  },
  "96652": {
    loansProduced: "0.25",
    membersServed: "31",
    interestEarned: "0.02",
    name: "540 I.B.E.W.",
    website: null,
  },
  "96658": {
    loansProduced: "27.40",
    membersServed: "3,752",
    interestEarned: "2.33",
    name: "TOWPATH",
    website: "http://www.towpathcu.com",
  },
  "96660": {
    loansProduced: "0.08",
    membersServed: "18",
    interestEarned: "0.01",
    name: "ANTIOCH",
    website: null,
  },
  "96672": {
    loansProduced: "15.67",
    membersServed: "2,685",
    interestEarned: "1.33",
    name: "BUCKEYE STATE",
    website: "http://www.buckeyecu.org",
  },
  "96674": {
    loansProduced: "8.98",
    membersServed: "900",
    interestEarned: "0.76",
    name: "SOUTHEASTERN OHIO",
    website: "http://southeasternohiocu.org",
  },
  "96691": {
    loansProduced: "0.03",
    membersServed: "5",
    interestEarned: "0.00",
    name: "WRIGHT-DUNBAR AREA",
    website: null,
  },
  "96699": {
    loansProduced: "2.11",
    membersServed: "483",
    interestEarned: "0.18",
    name: "CHRISTIAN FAMILY",
    website: "http://www.christianfamilycu.com",
  },
  "96706": {
    loansProduced: "1.28",
    membersServed: "349",
    interestEarned: "0.11",
    name: "FAITH COMMUNITY UNITED",
    website: "http://www.faithcommcu.com",
  },
  "96707": {
    loansProduced: "5.79",
    membersServed: "756",
    interestEarned: "0.49",
    name: "FALLS CATHOLIC",
    website: "http://fallsccu.org",
  },
  "96711": {
    loansProduced: "2.47",
    membersServed: "244",
    interestEarned: "0.21",
    name: "MEMBERS CHOICE",
    website: "http://www.mccugreenville.org",
  },
  "96719": {
    loansProduced: "2.81",
    membersServed: "268",
    interestEarned: "0.24",
    name: "GORMAN-RUPP & ASSOCIATES",
    website: "https://grohiocu.com",
  },
  "96722": {
    loansProduced: "3.82",
    membersServed: "1,143",
    interestEarned: "0.32",
    name: "GREATER CINCINNATI SCHOOL",
    website: "http://www.gccu.org",
  },
  "96723": {
    loansProduced: "44.94",
    membersServed: "4,262",
    interestEarned: "3.82",
    name: "FIREFIGHTERS COMMUNITY CU",
    website: "http://www.ffcommunity.com",
  },
  "96737": {
    loansProduced: "2.19",
    membersServed: "211",
    interestEarned: "0.19",
    name: "KYGER CREEK",
    website: null,
  },
  "96750": {
    loansProduced: "14.46",
    membersServed: "1,583",
    interestEarned: "1.23",
    name: "FRIENDS AND FAMILY CREDIT UNION INC",
    website: "http://www.friendsandfamilycu.com",
  },
  "96755": {
    loansProduced: "24.55",
    membersServed: "3,698",
    interestEarned: "2.09",
    name: "MYUSA",
    website: "http://www.myusacu.com",
  },
  "96756": {
    loansProduced: "5.09",
    membersServed: "842",
    interestEarned: "0.43",
    name: "MILLSTREAM AREA",
    website: "http://www.millstreamcu.com",
  },
  "96761": {
    loansProduced: "24.04",
    membersServed: "1,454",
    interestEarned: "2.04",
    name: "AURGROUP",
    website: "http://www.aurgroup.org",
  },
  "96768": {
    loansProduced: "34.19",
    membersServed: "3,923",
    interestEarned: "2.91",
    name: "PSE CREDIT UNION, INC.",
    website: "http://psecreditunion.org",
  },
  "96770": {
    loansProduced: "0.18",
    membersServed: "14",
    interestEarned: "0.02",
    name: "PRODUCERS EMPLOYEES'",
    website: "http://www.producerscu.com",
  },
  "96787": {
    loansProduced: "0.01",
    membersServed: "1",
    interestEarned: "0.00",
    name: "ST. PAUL A.M.E. ZION CHURCH",
    website: null,
  },
  "96796": {
    loansProduced: "1.27",
    membersServed: "125",
    interestEarned: "0.11",
    name: "TAPPAN COMMUNITY CREDIT UNION, INC.",
    website: "http://www.tappancu.com",
  },
  "96799": {
    loansProduced: "0.83",
    membersServed: "57",
    interestEarned: "0.07",
    name: "U.P.S.",
    website: null,
  },
  "96808": {
    loansProduced: "3.05",
    membersServed: "295",
    interestEarned: "0.26",
    name: "WIREMEN'S",
    website: "http://wiremenscu.com",
  },
  "96812": {
    loansProduced: "0.98",
    membersServed: "104",
    interestEarned: "0.08",
    name: "YOUNGSTOWN CITY SCHOOLS",
    website: "http://www.ycsd.org",
  },
  "96821": {
    loansProduced: "0.05",
    membersServed: "11",
    interestEarned: "0.00",
    name: "HEEKIN CAN EMPL",
    website: null,
  },
  "97052": {
    loansProduced: "7.39",
    membersServed: "569",
    interestEarned: "0.63",
    name: "FIREFIGHTERS",
    website: "http://www.fire-cu.org",
  },
  "97053": {
    loansProduced: "2.53",
    membersServed: "296",
    interestEarned: "0.22",
    name: "MAUMEE VALLEY",
    website: "http://www.maumeevalleycu.com",
  },
  "97059": {
    loansProduced: "38.55",
    membersServed: "44,775",
    interestEarned: "3.28",
    name: "EARTHMOVER",
    website: "http://www.earthmovercu.com",
  },
  "97063": {
    loansProduced: "0.79",
    membersServed: "41",
    interestEarned: "0.07",
    name: "JACKSON COUNTY CO-OP",
    website: "http://www.myjcccu.com",
  },
  "97066": {
    loansProduced: "0.95",
    membersServed: "328",
    interestEarned: "0.08",
    name: "ST JOE VALLEY",
    website: "http://www.stjoevalleycu.com",
  },
  "97068": {
    loansProduced: "24.19",
    membersServed: "2,862",
    interestEarned: "2.06",
    name: "CHRISTIAN COMMUNITY",
    website: "http://www.mycccu.com",
  },
  "97069": {
    loansProduced: "40.06",
    membersServed: "7,387",
    interestEarned: "3.41",
    name: "SAN FRANCISCO FIRE",
    website: "http://www.sffirecu.org",
  },
  "97071": {
    loansProduced: "32.40",
    membersServed: "1,894",
    interestEarned: "2.75",
    name: "MONTEREY",
    website: "http://www.montereycu.com",
  },
  "97073": {
    loansProduced: "8.59",
    membersServed: "574",
    interestEarned: "0.73",
    name: "NORTHWEST CHRISTIAN",
    website: "http://www.mynccu.org",
  },
  "97076": {
    loansProduced: "44.69",
    membersServed: "4,931",
    interestEarned: "3.80",
    name: "WINSOUTH",
    website: "http://www.winsouthcu.com",
  },
  "97077": {
    loansProduced: "2.93",
    membersServed: "468",
    interestEarned: "0.25",
    name: "EMERALD CREDIT UNION, INC.",
    website: "http://www.emeraldgcu.com",
  },
  "97078": {
    loansProduced: "1.58",
    membersServed: "360",
    interestEarned: "0.13",
    name: "BSE",
    website: "http://bsecu.org",
  },
  "97079": {
    loansProduced: "3.18",
    membersServed: "572",
    interestEarned: "0.27",
    name: "POSTAL FAMILY CREDIT UNION, INC.",
    website: "http://urmycu.com",
  },
  "97084": {
    loansProduced: "104.01",
    membersServed: "9,947",
    interestEarned: "8.84",
    name: "DUPAGE",
    website: "http://www.dupagecu.com",
  },
  "97085": {
    loansProduced: "147.53",
    membersServed: "7,546",
    interestEarned: "12.54",
    name: "SILVER STATE SCHOOLS",
    website: "http://www.silverstatecu.com",
  },
  "97086": {
    loansProduced: "26.45",
    membersServed: "13,972",
    interestEarned: "2.25",
    name: "ABRI",
    website: "http://www.abricu.com",
  },
  "97089": {
    loansProduced: "24.43",
    membersServed: "3,524",
    interestEarned: "2.08",
    name: "MCT",
    website: "http://www.mctcu.org",
  },
  "97093": {
    loansProduced: "73.35",
    membersServed: "4,730",
    interestEarned: "6.23",
    name: "SAFEAMERICA",
    website: "http://www.safeamerica.com",
  },
  "97094": {
    loansProduced: "11.53",
    membersServed: "2,166",
    interestEarned: "0.98",
    name: "ACHIEVE",
    website: "http://www.achievecu.org",
  },
  "97095": {
    loansProduced: "16.04",
    membersServed: "872",
    interestEarned: "1.36",
    name: "COMMONWEALTH",
    website: "http://www.cwcu.com",
  },
  "97096": {
    loansProduced: "3.14",
    membersServed: "394",
    interestEarned: "0.27",
    name: "FRESNO POLICE DEPARTMENT",
    website: "http://www.fpdcu.org",
  },
  "97097": {
    loansProduced: "5.68",
    membersServed: "705",
    interestEarned: "0.48",
    name: "ACCESS",
    website: "http://access-cu.com",
  },
  "97098": {
    loansProduced: "14.27",
    membersServed: "1,180",
    interestEarned: "1.21",
    name: "EASTEX",
    website: "http://www.eastexcu.org",
  },
  "97100": {
    loansProduced: "1.09",
    membersServed: "180",
    interestEarned: "0.09",
    name: "AURORA POLICEMEN",
    website: "http://www.apcu4u.com",
  },
  "97101": {
    loansProduced: "5.24",
    membersServed: "1,161",
    interestEarned: "0.45",
    name: "GLENDALE AREA SCHOOLS",
    website: "https://gascu.org",
  },
  "97102": {
    loansProduced: "5.28",
    membersServed: "545",
    interestEarned: "0.45",
    name: "LATAH",
    website: "http://www.latahcu.coop",
  },
  "97103": {
    loansProduced: "79.53",
    membersServed: "5,253",
    interestEarned: "6.76",
    name: "ALLIANCE",
    website: "http://www.alliancecutx.com",
  },
  "97104": {
    loansProduced: "27.74",
    membersServed: "2,747",
    interestEarned: "2.36",
    name: "IH CREDIT UNION, INC.",
    website: "http://www.ihcreditunion.com",
  },
  "97105": {
    loansProduced: "15.59",
    membersServed: "4,177",
    interestEarned: "1.32",
    name: "DAY-MET",
    website: "http://www.daymetcu.com",
  },
  "97106": {
    loansProduced: "28.58",
    membersServed: "3,286",
    interestEarned: "2.43",
    name: "GENFED FINANCIAL",
    website: "http://www.genfed.com",
  },
  "97107": {
    loansProduced: "29.50",
    membersServed: "3,382",
    interestEarned: "2.51",
    name: "UNITED TEXAS",
    website: "https://www.utxcu.com",
  },
  "97108": {
    loansProduced: "3.32",
    membersServed: "23,335",
    interestEarned: "0.28",
    name: "NORTHWEST COMMUNITY",
    website: "http://www.nwccu.com",
  },
  "97109": {
    loansProduced: "65.02",
    membersServed: "4,957",
    interestEarned: "5.53",
    name: "RIVER VALLEY",
    website: "http://www.rivervalleycu.org",
  },
  "97110": {
    loansProduced: "34.40",
    membersServed: "3,377",
    interestEarned: "2.92",
    name: "ROCKY MOUNTAIN",
    website: "http://www.rmcu.net",
  },
  "97111": {
    loansProduced: "13.84",
    membersServed: "2,477",
    interestEarned: "1.18",
    name: "ABBEY",
    website: "http://www.abbeycu.com",
  },
  "97112": {
    loansProduced: "29.07",
    membersServed: "3,350",
    interestEarned: "2.47",
    name: "INVESTEX",
    website: "http://www.investexcu.org",
  },
};
