export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function adjustFeatureListPadding() {
  console.log("adjustFeatureListPadding");
  const calculatorDisclaimer = document.querySelector(
    ".calculator-disclaimer"
  ) as HTMLElement;
  const featureListWrapper = document.querySelector(
    ".feature-list-wrapper"
  ) as HTMLElement;

  if (calculatorDisclaimer && featureListWrapper) {
    const padding =
      calculatorDisclaimer.getBoundingClientRect().bottom -
      featureListWrapper.getBoundingClientRect().top +
      100;

    featureListWrapper.style.paddingTop = `${padding}px`;
  }
}
