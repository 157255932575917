export const options = [
  {
    "value": "7723",
    "label": "https://www.360FCU.ORG"
  },
  {
    "value": "18749",
    "label": "http://www.705fcu.com"
  },
  {
    "value": "62983",
    "label": "http://www.802cu.com"
  },
  {
    "value": "24670",
    "label": "https://www.1199federalcu.org"
  },
  {
    "value": "61605",
    "label": "http://www.121fcu.org"
  },
  {
    "value": "13028",
    "label": "http://www.167tfrfcu.com"
  },
  {
    "value": "7448",
    "label": "http://www.1stadvantage.org"
  },
  {
    "value": "24810",
    "label": "http://www.1stbergen.com"
  },
  {
    "value": "67505",
    "label": "http://www.1stchoicecu.org"
  },
  {
    "value": "8715",
    "label": "http://www.1cfcu.org"
  },
  {
    "value": "67012",
    "label": "http://www.1stccu.com"
  },
  {
    "value": "24627",
    "label": "http://www.1stcooperative.com"
  },
  {
    "value": "65412",
    "label": "http://www.1edcu.org"
  },
  {
    "value": "63124",
    "label": "http://www.1stgateway.org"
  },
  {
    "value": "63217",
    "label": "http://www.1stkansascu.com"
  },
  {
    "value": "10375",
    "label": "http://www.1stliberty.org"
  },
  {
    "value": "63932",
    "label": "http://www.1stmidamerica.org"
  },
  {
    "value": "21073",
    "label": "https://www.1stms.com"
  },
  {
    "value": "68668",
    "label": "http://www.1stnorcalcu.org/"
  },
  {
    "value": "67309",
    "label": "http://www.1streetcu.com"
  },
  {
    "value": "63958",
    "label": "http://www.1stUnitedcu.org"
  },
  {
    "value": "67483",
    "label": "http://www.culink.net"
  },
  {
    "value": "68027",
    "label": "https://www.1stvalleycu.com"
  },
  {
    "value": "62827",
    "label": "http://www.2riversacu.com"
  },
  {
    "value": "61365",
    "label": "http://www.4frontcu.com"
  },
  {
    "value": "11067",
    "label": "http://www.4ucu.org"
  },
  {
    "value": "64878",
    "label": "http://www.5starcu.org"
  },
  {
    "value": "5256",
    "label": "http://www.600atlanticfcu.org"
  },
  {
    "value": "2505",
    "label": "http://www.77thstreetdepotfcu.org"
  },
  {
    "value": "3741",
    "label": "http://www.asfederal.org"
  },
  {
    "value": "8512",
    "label": "http://www.acmgfcu.org"
  },
  {
    "value": "17339",
    "label": "www.aheplant3fcu.com"
  },
  {
    "value": "19131",
    "label": "http://www.alecfcu.com"
  },
  {
    "value": "200",
    "label": "http://www.attpghfcu.com"
  },
  {
    "value": "4915",
    "label": "http://WWW.AEAFCU.ORG"
  },
  {
    "value": "60648",
    "label": "http://www.aaeccu.com"
  },
  {
    "value": "14879",
    "label": "http://www.abfederalcu.com"
  },
  {
    "value": "5885",
    "label": "http://www.abdfcu.com"
  },
  {
    "value": "11867",
    "label": "http://www.acpefcu.com"
  },
  {
    "value": "6178",
    "label": "https://www.aplusfcu.org"
  },
  {
    "value": "3412",
    "label": "http://www.aaafcu.com"
  },
  {
    "value": "62217",
    "label": "http://aaccreditunion.org"
  },
  {
    "value": "97111",
    "label": "http://www.abbeycu.com"
  },
  {
    "value": "96071",
    "label": "http://www.alecu.org"
  },
  {
    "value": "12679",
    "label": "http://www.abcofcu.org"
  },
  {
    "value": "2540",
    "label": "https://www.apgfcu.com"
  },
  {
    "value": "6743",
    "label": "http://abileneteachersfcu.org"
  },
  {
    "value": "13605",
    "label": "http://www.abnbfcu.org"
  },
  {
    "value": "7000",
    "label": "http://www.Aboundcu.com"
  },
  {
    "value": "97086",
    "label": "http://www.abricu.com"
  },
  {
    "value": "4170",
    "label": "http://abyssinianfcu.org"
  },
  {
    "value": "23425",
    "label": "http://www.academicfcu.org"
  },
  {
    "value": "67695",
    "label": "http://www.academicecu.com"
  },
  {
    "value": "15619",
    "label": "http://www.acadiafcu.org"
  },
  {
    "value": "6898",
    "label": "http://www.acadianfcu.org"
  },
  {
    "value": "19249",
    "label": "http://www.myamfcu.com"
  },
  {
    "value": "3226",
    "label": "http://www.acbafcu.com"
  },
  {
    "value": "60798",
    "label": "http://www.accentracu.org"
  },
  {
    "value": "5579",
    "label": "http://www.accessfcu.org"
  },
  {
    "value": "61101",
    "label": "http://www.accessmycu.org"
  },
  {
    "value": "97097",
    "label": "http://access-cu.com"
  },
  {
    "value": "67178",
    "label": "http://www.accesscreditunion.com"
  },
  {
    "value": "10353",
    "label": "http://www.access.coop"
  },
  {
    "value": "23386",
    "label": "http://www.AcclaimFCU.org"
  },
  {
    "value": "5168",
    "label": "http://www.acfcu.org"
  },
  {
    "value": "60087",
    "label": "http://www.achievacu.com"
  },
  {
    "value": "97094",
    "label": "http://www.achievecu.org"
  },
  {
    "value": "61256",
    "label": "http://www.achievefinancialcu.com"
  },
  {
    "value": "16500",
    "label": "http://www.acipcofcu.org"
  },
  {
    "value": "3447",
    "label": "http://www.acmefcu.org"
  },
  {
    "value": "61230",
    "label": "http://www.acmecontinentalcu.com"
  },
  {
    "value": "6679",
    "label": "https://www.act1stfcu.org"
  },
  {
    "value": "15358",
    "label": "https://actorsfcu.com"
  },
  {
    "value": "67446",
    "label": "http://acucreditunion.com"
  },
  {
    "value": "13814",
    "label": "http://www.acushnetfcu.com"
  },
  {
    "value": "68702",
    "label": "http://www.additionfi.com"
  },
  {
    "value": "63837",
    "label": "http://www.adelfibanking.com"
  },
  {
    "value": "5546",
    "label": "http://www.adkcreditunion.com"
  },
  {
    "value": "24078",
    "label": "http://www.Advancefcu.org"
  },
  {
    "value": "16785",
    "label": "http://www.advfinfcu.com"
  },
  {
    "value": "2285",
    "label": "http://www.Advancial.org"
  },
  {
    "value": "24181",
    "label": "http://www.advantagefcu.org"
  },
  {
    "value": "61383",
    "label": "http://www.advantage4.org"
  },
  {
    "value": "64030",
    "label": "http://www.acuiowa.org"
  },
  {
    "value": "16416",
    "label": "https://www.advfcu.org"
  },
  {
    "value": "68343",
    "label": "http://www.adonecu.org"
  },
  {
    "value": "68679",
    "label": "https://www.myaocu.com/"
  },
  {
    "value": "8428",
    "label": "http://www.advantagepluscreditunion"
  },
  {
    "value": "8925",
    "label": "http://www.apifcu.com"
  },
  {
    "value": "62659",
    "label": "http://www.advantiscu.org"
  },
  {
    "value": "19853",
    "label": "http://www.advanzcu.org"
  },
  {
    "value": "67349",
    "label": "http://www.ahcu.cc"
  },
  {
    "value": "60936",
    "label": "http://www.adventurecu.org"
  },
  {
    "value": "61503",
    "label": "http://www.adviacu.org"
  },
  {
    "value": "65521",
    "label": "http://www.aegiscu.com"
  },
  {
    "value": "12164",
    "label": "http://www.aerofed.net"
  },
  {
    "value": "61405",
    "label": "http://www.aeroquipcu.com"
  },
  {
    "value": "14723",
    "label": "http://www.aerofcu.org"
  },
  {
    "value": "12153",
    "label": "https://www.afenafcu.org"
  },
  {
    "value": "61808",
    "label": "http://www.affiliatedtradescu.org"
  },
  {
    "value": "857",
    "label": "affinityfcu.com"
  },
  {
    "value": "62829",
    "label": "http://www.affinitycuia.org"
  },
  {
    "value": "12980",
    "label": "http://www.affinityfcund.com"
  },
  {
    "value": "5785",
    "label": "http://affinityonefcu.org"
  },
  {
    "value": "24362",
    "label": "http://www.affinityplus.org"
  },
  {
    "value": "15618",
    "label": "http://AFGMENTFCU.NET"
  },
  {
    "value": "7992",
    "label": "http://www.aflcioefcu.org"
  },
  {
    "value": "16096",
    "label": "http://aflacfcu.net"
  },
  {
    "value": "16400",
    "label": "http://www.agriculturefcu.org"
  },
  {
    "value": "64886",
    "label": "http://www.aimcreditunion.com"
  },
  {
    "value": "10600",
    "label": "http://www.aafcu.com"
  },
  {
    "value": "8169",
    "label": "https://goaffcu.com"
  },
  {
    "value": "11917",
    "label": "http://www.aircofcu.org"
  },
  {
    "value": "13367",
    "label": "http://www.asefcu.net"
  },
  {
    "value": "60823",
    "label": "http://www.alabamacu.com"
  },
  {
    "value": "60712",
    "label": "http://www.alabamacentral.org"
  },
  {
    "value": "64603",
    "label": "http://www.allecu.org"
  },
  {
    "value": "68595",
    "label": "http://www.alabamaone.org"
  },
  {
    "value": "64645",
    "label": "https://alabamapostalcu.com"
  },
  {
    "value": "65402",
    "label": "http://www.arivercu.com"
  },
  {
    "value": "64598",
    "label": "https://www.yourasecu.com"
  },
  {
    "value": "67252",
    "label": "http://atcu.com"
  },
  {
    "value": "61267",
    "label": "http://alamocitycu.com"
  },
  {
    "value": "8100",
    "label": "http://www.aagcu.org"
  },
  {
    "value": "6237",
    "label": "http://www.adefcu.org"
  },
  {
    "value": "60934",
    "label": "www.alatrustcu.com"
  },
  {
    "value": "13655",
    "label": "http://www.albagoldenfcu.com"
  },
  {
    "value": "8975",
    "label": "http://alcofcu.com"
  },
  {
    "value": "11913",
    "label": "http://www.alcoacommunityfcu.org"
  },
  {
    "value": "2554",
    "label": "http://www.alcoapittfcu.org"
  },
  {
    "value": "1509",
    "label": "https://www.atfcu.com"
  },
  {
    "value": "20990",
    "label": "https://www.alconefcu.org"
  },
  {
    "value": "61926",
    "label": "http://www.alcosecu.com"
  },
  {
    "value": "67951",
    "label": "http://www.aldencu.com"
  },
  {
    "value": "15062",
    "label": "http://igrc.org"
  },
  {
    "value": "62438",
    "label": "http://www.amecu.org"
  },
  {
    "value": "64304",
    "label": "http://www.alexcreditunion.org"
  },
  {
    "value": "60165",
    "label": "http://www.alhambracu.com"
  },
  {
    "value": "66365",
    "label": "http://www.aligncu.com"
  },
  {
    "value": "67316",
    "label": "http://www.alivecu.coop"
  },
  {
    "value": "17642",
    "label": "http://www.allincu.com"
  },
  {
    "value": "4845",
    "label": "http://www.allsaintsfcu.com"
  },
  {
    "value": "67599",
    "label": "http://www.allcomcu.org"
  },
  {
    "value": "24438",
    "label": "http://www.allegacy.org"
  },
  {
    "value": "22883",
    "label": "http://www.alleganyfirstfcu.com"
  },
  {
    "value": "205",
    "label": "http://www.allegentfcu.org"
  },
  {
    "value": "841",
    "label": "http://www.acefcu.org"
  },
  {
    "value": "21161",
    "label": "https://www.ahsfcu.com"
  },
  {
    "value": "4095",
    "label": "http://www.albfcu.org"
  },
  {
    "value": "4756",
    "label": "http://www.alleghenyvalleyfcu.com"
  },
  {
    "value": "24936",
    "label": "http://www.allegiancecu.org"
  },
  {
    "value": "17389",
    "label": "http://allegius.org"
  },
  {
    "value": "8825",
    "label": "www.akpostalfcu.org"
  },
  {
    "value": "10015",
    "label": "http://www.allentownfcu.com"
  },
  {
    "value": "63789",
    "label": "http://alliancecu.com"
  },
  {
    "value": "97103",
    "label": "http://www.alliancecutx.com"
  },
  {
    "value": "61759",
    "label": "https://www.allianceccu.com"
  },
  {
    "value": "67432",
    "label": "http://www.alliancecufl.org"
  },
  {
    "value": "18409",
    "label": "http://www.allianceniagara.com"
  },
  {
    "value": "67955",
    "label": "http://www.alliantcreditunion.org"
  },
  {
    "value": "17100",
    "label": "https://www.alliedfcu.com/"
  },
  {
    "value": "11005",
    "label": "http://www.ahfcu.org"
  },
  {
    "value": "11906",
    "label": "http://apfcu.net"
  },
  {
    "value": "20324",
    "label": "http://www.alloyfcu.com"
  },
  {
    "value": "13828",
    "label": "https://www.allsouth.org"
  },
  {
    "value": "24774",
    "label": "http://www.alltrucu.org"
  },
  {
    "value": "67481",
    "label": "http://www.alltrustcu.org"
  },
  {
    "value": "60660",
    "label": "http://WWW.ALLVACCU.COM"
  },
  {
    "value": "5845",
    "label": "http://www.allwealth.org"
  },
  {
    "value": "1845",
    "label": "https://alohapacific.com"
  },
  {
    "value": "62264",
    "label": "www.alpenacreditunion.com"
  },
  {
    "value": "62035",
    "label": "http://www.aaacu.com"
  },
  {
    "value": "67872",
    "label": "http://www.alphacu.com"
  },
  {
    "value": "64332",
    "label": "http://www.alpinecu.com"
  },
  {
    "value": "64531",
    "label": "http://www.altavistacu.org"
  },
  {
    "value": "10262",
    "label": "http://www.altamaha.org"
  },
  {
    "value": "6275",
    "label": "http://www.altanafcu.org"
  },
  {
    "value": "5612",
    "label": "http://www.altaone.org"
  },
  {
    "value": "23283",
    "label": "http://www.alternatives.org"
  },
  {
    "value": "3316",
    "label": "http://ALTONIZEDFCU.ORG"
  },
  {
    "value": "23753",
    "label": "http://ALTOONAFCU.COM"
  },
  {
    "value": "24077",
    "label": "http://www.altra.org"
  },
  {
    "value": "62799",
    "label": "http://www.alturacu.com"
  },
  {
    "value": "67639",
    "label": "http://www.crunion.org"
  },
  {
    "value": "567",
    "label": "http://ambrawfcu.com"
  },
  {
    "value": "900",
    "label": "http://www.ambridgeareafcu.com"
  },
  {
    "value": "24694",
    "label": "http://www.americafirst.com"
  },
  {
    "value": "65595",
    "label": "http://www.american1cu.org"
  },
  {
    "value": "23957",
    "label": "http://www.aacreditunion.org"
  },
  {
    "value": "67622",
    "label": "http://www.abacu.org"
  },
  {
    "value": "18273",
    "label": "http://www.abefcu.org"
  },
  {
    "value": "68659",
    "label": "http://www.americaneagle.org"
  },
  {
    "value": "68485",
    "label": "http://amerfirst.org"
  },
  {
    "value": "5621",
    "label": "http://www.americanheritagecu.org"
  },
  {
    "value": "68371",
    "label": "http://www.americanlake.org"
  },
  {
    "value": "22119",
    "label": "http://www.apfcu.com"
  },
  {
    "value": "60389",
    "label": "http://www.americanpridecu.org"
  },
  {
    "value": "68567",
    "label": "http://www.ascu.org"
  },
  {
    "value": "15426",
    "label": "http://www.americanspirit.org"
  },
  {
    "value": "8187",
    "label": "www.amucu.org"
  },
  {
    "value": "67470",
    "label": "http://WWW.AMERICASCU.ORG"
  },
  {
    "value": "68062",
    "label": "http://www.americaschristiancu.com"
  },
  {
    "value": "9373",
    "label": "http://www.youracu.org"
  },
  {
    "value": "24534",
    "label": "www.amfirst.org"
  },
  {
    "value": "68680",
    "label": "http://afncu.com"
  },
  {
    "value": "20041",
    "label": "http://www.americhoice.org"
  },
  {
    "value": "12752",
    "label": "http://www.americofcu.com"
  },
  {
    "value": "68433",
    "label": "http://www.americu.org"
  },
  {
    "value": "5439",
    "label": "http://www.amherstcu.org"
  },
  {
    "value": "2441",
    "label": "https://www.amocofcu.org"
  },
  {
    "value": "68681",
    "label": "http://www.goamplify.com"
  },
  {
    "value": "19538",
    "label": "http://ampotfcu.org"
  },
  {
    "value": "60184",
    "label": "http://americannickeloidemployeescr"
  },
  {
    "value": "9880",
    "label": "http://www.ancfcu.com"
  },
  {
    "value": "5754",
    "label": "andrewsfcu.org"
  },
  {
    "value": "3212",
    "label": "https://aneca.org"
  },
  {
    "value": "11975",
    "label": "http://www.angfcu.org"
  },
  {
    "value": "67440",
    "label": "http://actcu.net"
  },
  {
    "value": "67489",
    "label": "http://afecu.net"
  },
  {
    "value": "21508",
    "label": "http://www.anmedhealthfcu.com"
  },
  {
    "value": "60148",
    "label": "http://www.ahcu.coop"
  },
  {
    "value": "13261",
    "label": "http://www.antiochcommfcu.org"
  },
  {
    "value": "6680",
    "label": "http://www.aodfcu.com"
  },
  {
    "value": "9607",
    "label": "https://www.apcifcu.org"
  },
  {
    "value": "62728",
    "label": "http://www.apcocu.org"
  },
  {
    "value": "9475",
    "label": "http://www.aplfcu.org"
  },
  {
    "value": "24495",
    "label": "http://www.myacfcu.org"
  },
  {
    "value": "1463",
    "label": "http://www.appalachianpowerefcu.vir"
  },
  {
    "value": "11307",
    "label": "http://www.applefcu.org"
  },
  {
    "value": "66675",
    "label": "http://www.appletree.org"
  },
  {
    "value": "67976",
    "label": "http://www.appliancecu.com"
  },
  {
    "value": "838",
    "label": "http://www.apsfcu.org"
  },
  {
    "value": "64110",
    "label": "http://www.arapahoecu.org"
  },
  {
    "value": "61708",
    "label": "http://www.arborfcu.org"
  },
  {
    "value": "24843",
    "label": "http://www.arbucklefcu.com"
  },
  {
    "value": "11304",
    "label": "http://www.arcfcu.org"
  },
  {
    "value": "62114",
    "label": "http://arcadecu.com"
  },
  {
    "value": "66532",
    "label": "http://www.arcadiacu.com"
  },
  {
    "value": "66270",
    "label": "http://www.archerccu.com"
  },
  {
    "value": "63125",
    "label": "http://www.archerheights.com"
  },
  {
    "value": "22637",
    "label": "http://www.ardentcu.org"
  },
  {
    "value": "274",
    "label": "http://www.areafcu.org"
  },
  {
    "value": "64294",
    "label": "http://www.gfaccu.com"
  },
  {
    "value": "62500",
    "label": "www.myaecu.com"
  },
  {
    "value": "24313",
    "label": "http://www.argentcu.org"
  },
  {
    "value": "20438",
    "label": "http://www.arhfcu.org"
  },
  {
    "value": "24187",
    "label": "http://www.arizefcu.org"
  },
  {
    "value": "62881",
    "label": "http://www.azcentralcu.org"
  },
  {
    "value": "9951",
    "label": "http://www.rayfcu.org"
  },
  {
    "value": "68730",
    "label": "http://www.azfcu.org"
  },
  {
    "value": "62894",
    "label": "http://arkvalleycu.org"
  },
  {
    "value": "10920",
    "label": "http://www.afcu.org"
  },
  {
    "value": "7700",
    "label": "http://www.uapbfcu.virtualcu.net"
  },
  {
    "value": "13737",
    "label": "http://www.abfcu.org"
  },
  {
    "value": "23170",
    "label": "http://aeacu.org"
  },
  {
    "value": "24439",
    "label": "http://www.afbfcu.virtualcu.net"
  },
  {
    "value": "8987",
    "label": "http://www.ahcfcu.com"
  },
  {
    "value": "11685",
    "label": "http://www.asfcu.coop"
  },
  {
    "value": "24433",
    "label": "http://artfcu.org"
  },
  {
    "value": "7573",
    "label": "http://www.arlingtoncu.org"
  },
  {
    "value": "9805",
    "label": "http://www.arlfcu.org"
  },
  {
    "value": "65291",
    "label": "www.armcocu.com"
  },
  {
    "value": "12438",
    "label": "http://www.aafcu4u.coop"
  },
  {
    "value": "61189",
    "label": "http://www.arrhacu.com"
  },
  {
    "value": "60700",
    "label": "http://www.arrowheadcu.org"
  },
  {
    "value": "67195",
    "label": "http://www.arsenalcu.com"
  },
  {
    "value": "62783",
    "label": "http://www.artesiacu.org"
  },
  {
    "value": "6859",
    "label": "http://www.artesianfcu.org"
  },
  {
    "value": "21190",
    "label": "http://www.asafcu.com"
  },
  {
    "value": "7397",
    "label": "https://ascend.org"
  },
  {
    "value": "62018",
    "label": "http://www.ascensioncu.org"
  },
  {
    "value": "11520",
    "label": "http://www.ascentcu.com"
  },
  {
    "value": "63268",
    "label": "http://www.ascentra.org"
  },
  {
    "value": "61792",
    "label": "http://WWW.ASHLANDCU.ORG"
  },
  {
    "value": "7072",
    "label": "http://www.ashlandfcu.org"
  },
  {
    "value": "17224",
    "label": "www.aspenfcu.org"
  },
  {
    "value": "1888",
    "label": "https://aspire.creditunion"
  },
  {
    "value": "62897",
    "label": "http://www.agcu.org"
  },
  {
    "value": "60484",
    "label": "http://www.acuonline.org"
  },
  {
    "value": "60608",
    "label": "http://www.acutx.org"
  },
  {
    "value": "60466",
    "label": "http://www.ahcu.org"
  },
  {
    "value": "68587",
    "label": "http://asecu.com"
  },
  {
    "value": "60920",
    "label": "http://www.asteracu.com"
  },
  {
    "value": "63630",
    "label": "atchisonvillagecu.org"
  },
  {
    "value": "67243",
    "label": "http://athensareacu.com"
  },
  {
    "value": "66819",
    "label": "http://www.atholcreditunion.com"
  },
  {
    "value": "67389",
    "label": "http://www.apcu.com"
  },
  {
    "value": "16009",
    "label": "http://www.atlanticcity.coop"
  },
  {
    "value": "3755",
    "label": "http://www.acecefcu.org"
  },
  {
    "value": "6652",
    "label": "http://www.affcu.org"
  },
  {
    "value": "21737",
    "label": "http://www.ahefcu.org"
  },
  {
    "value": "4281",
    "label": "http://atlanticfcu.com"
  },
  {
    "value": "63532",
    "label": "http://www.atomiccu.com"
  },
  {
    "value": "63446",
    "label": "http://WWW.ATRIUM4U.ORG"
  },
  {
    "value": "16011",
    "label": "http://WWW.AttleboroFCU.org"
  },
  {
    "value": "884",
    "label": "http://www.attwyocefcu.com"
  },
  {
    "value": "988",
    "label": "http://www.auburnfcu.com"
  },
  {
    "value": "68701",
    "label": "http://myaucu.org"
  },
  {
    "value": "11168",
    "label": "http://audubonfcu.net"
  },
  {
    "value": "11744",
    "label": "http://AUGUSTACOUNTYFCU.ORG"
  },
  {
    "value": "66933",
    "label": "http://ahccu.com"
  },
  {
    "value": "96761",
    "label": "http://www.aurgroup.org"
  },
  {
    "value": "18574",
    "label": "http://www.auroracu.com"
  },
  {
    "value": "97100",
    "label": "http://www.apcu4u.com"
  },
  {
    "value": "17955",
    "label": "http://www.austinfcu.com"
  },
  {
    "value": "64186",
    "label": "http://www.austincityecu.org"
  },
  {
    "value": "4584",
    "label": "https://www.atfcu.org"
  },
  {
    "value": "62543",
    "label": "http://aoacu.com"
  },
  {
    "value": "68617",
    "label": "http://www.autotruckfcu.org"
  },
  {
    "value": "68583",
    "label": "http://www.avadiancu.com"
  },
  {
    "value": "8801",
    "label": "http://www.avantifcu.net"
  },
  {
    "value": "62755",
    "label": "http://www.aventa.com"
  },
  {
    "value": "66537",
    "label": "http://avestarcu.com"
  },
  {
    "value": "22953",
    "label": "http://www.avhfcu.com"
  },
  {
    "value": "68284",
    "label": "http://avistacu.com"
  },
  {
    "value": "17697",
    "label": "http://WWW.ITSME247.COM/855"
  },
  {
    "value": "7465",
    "label": "http://www.awakonfcu.net"
  },
  {
    "value": "68224",
    "label": "http://www.azaleacitycu.com"
  },
  {
    "value": "61823",
    "label": "http://www.azuracu.com"
  },
  {
    "value": "3653",
    "label": "http://betcreditunion.com"
  },
  {
    "value": "6240",
    "label": "http://www.bifcu.org"
  },
  {
    "value": "63598",
    "label": "http://bvcreditunion.com"
  },
  {
    "value": "20974",
    "label": "http://www.bondcu.com"
  },
  {
    "value": "21458",
    "label": "http://backmtnfcu.com"
  },
  {
    "value": "66750",
    "label": "http://badgerglobecu.org"
  },
  {
    "value": "4227",
    "label": "http://badlandsfcu.org"
  },
  {
    "value": "16271",
    "label": "http://bhfcu.org"
  },
  {
    "value": "1339",
    "label": "http://www.bakcityefcu.org"
  },
  {
    "value": "13329",
    "label": "http://www.baldwincountyfcu.com"
  },
  {
    "value": "15394",
    "label": "http://www.bcefcu.com"
  },
  {
    "value": "20719",
    "label": "http://www.baltimorewashingtonfcu.o"
  },
  {
    "value": "10939",
    "label": "http://www.bangorfederal.com"
  },
  {
    "value": "5582",
    "label": "https://bfsfcu.org/"
  },
  {
    "value": "11680",
    "label": "http://bannerfcu.org"
  },
  {
    "value": "68358",
    "label": "http://www.baptistcu.org"
  },
  {
    "value": "12813",
    "label": "http://www.baptist-healthfcu.com"
  },
  {
    "value": "14391",
    "label": "http://www.bhsffcu.org"
  },
  {
    "value": "7672",
    "label": "http://baragafcu.com"
  },
  {
    "value": "17040",
    "label": "http://www.barcons.org"
  },
  {
    "value": "9589",
    "label": "http://www.bfcu.org"
  },
  {
    "value": "12292",
    "label": "http://www.bartonfcu.com"
  },
  {
    "value": "12826",
    "label": "http://www.basfchattafcu.com"
  },
  {
    "value": "6339",
    "label": "http://www.brcpefcu.org"
  },
  {
    "value": "1257",
    "label": "http://brfdfcu.com"
  },
  {
    "value": "1726",
    "label": "http://www.brtelco.org"
  },
  {
    "value": "68187",
    "label": "http://www.bcu.org"
  },
  {
    "value": "12067",
    "label": "http://www.bayfed.com"
  },
  {
    "value": "63375",
    "label": "http://www.bayareacu.com"
  },
  {
    "value": "3541",
    "label": "www.bayatlanticfcu.org"
  },
  {
    "value": "68316",
    "label": "http://www.baycities.org"
  },
  {
    "value": "63262",
    "label": "www.baycu.com"
  },
  {
    "value": "66549",
    "label": "http://www.bayshorecu.org"
  },
  {
    "value": "15563",
    "label": "http://www.baycel.org"
  },
  {
    "value": "11836",
    "label": "http://bayerhfcu.com"
  },
  {
    "value": "68686",
    "label": "http://www.baylandsfcu.org"
  },
  {
    "value": "67544",
    "label": "http://www.bhcscu.com"
  },
  {
    "value": "9931",
    "label": "http://www.bayoufcu.org"
  },
  {
    "value": "23582",
    "label": "http://www.bcfcu.com"
  },
  {
    "value": "11782",
    "label": "http://www.bayoucfcu.com"
  },
  {
    "value": "67738",
    "label": "http://www.bcbstecu.com"
  },
  {
    "value": "12892",
    "label": "http://www.bcmfcu.com"
  },
  {
    "value": "20394",
    "label": "http://www.beachmunicipal.org"
  },
  {
    "value": "6062",
    "label": "http://www.beaconfed.org"
  },
  {
    "value": "82791",
    "label": "http://www.beaconcu.org"
  },
  {
    "value": "60883",
    "label": "https://www.beaconccu.org"
  },
  {
    "value": "66965",
    "label": "http://www.mybcu.org"
  },
  {
    "value": "67776",
    "label": "http://www.beaconfcu.org"
  },
  {
    "value": "67574",
    "label": "http://www.bmtccu.com"
  },
  {
    "value": "4794",
    "label": "http://www.beavervalleyfcu.org"
  },
  {
    "value": "9893",
    "label": "www.beefcu.org"
  },
  {
    "value": "13741",
    "label": "http://www.beehive.org"
  },
  {
    "value": "11943",
    "label": "http://www.bffcu.org"
  },
  {
    "value": "21575",
    "label": "http://bekafcu.com"
  },
  {
    "value": "68256",
    "label": "http://www.belco.org"
  },
  {
    "value": "67263",
    "label": "http://www.bellcu.com"
  },
  {
    "value": "2531",
    "label": "http://www.bellcocu.org"
  },
  {
    "value": "68437",
    "label": "http://www.bellco.org"
  },
  {
    "value": "60747",
    "label": "http://www.bccu.org"
  },
  {
    "value": "15565",
    "label": "http://beltonfcu.com"
  },
  {
    "value": "514",
    "label": "http://www.bekefcu.org"
  },
  {
    "value": "3738",
    "label": "https://www.benchmarkfcu.org"
  },
  {
    "value": "64059",
    "label": "http://www.bcscu.org"
  },
  {
    "value": "13472",
    "label": "http://www.berkcofcu.com"
  },
  {
    "value": "21499",
    "label": "http://www.bcpsfcu.com"
  },
  {
    "value": "4982",
    "label": "http://bessfcu.com"
  },
  {
    "value": "62027",
    "label": "http://www.bestfinancialcu.org"
  },
  {
    "value": "24920",
    "label": "http://www.bestrewardfcu.coop"
  },
  {
    "value": "716",
    "label": "http://www.beth1st.org"
  },
  {
    "value": "4735",
    "label": "https://www.bethpagefcu.com"
  },
  {
    "value": "9823",
    "label": "http://www.bhcefcu.org"
  },
  {
    "value": "24192",
    "label": "http://www.beverlycreditunion.com"
  },
  {
    "value": "511",
    "label": "http://bfgfcu.org"
  },
  {
    "value": "61387",
    "label": "http://www.bhcu.org"
  },
  {
    "value": "1607",
    "label": "https://www.bigislandfcu.com"
  },
  {
    "value": "10577",
    "label": "http://WWW.BSTCU.COM"
  },
  {
    "value": "66336",
    "label": "http://www.billericamecu.com"
  },
  {
    "value": "887",
    "label": "http://www.billingsfcu.org"
  },
  {
    "value": "16699",
    "label": "https://www.biloxischools.net/domai"
  },
  {
    "value": "64528",
    "label": "http://www.bhamcu.org"
  },
  {
    "value": "7149",
    "label": "http://bisonfcu.com"
  },
  {
    "value": "18964",
    "label": "http://www.bitterrootcommunityfcu.c"
  },
  {
    "value": "4078",
    "label": "http://www.bivinsfcu.com"
  },
  {
    "value": "4365",
    "label": "http://www.blackhillsfcu.org"
  },
  {
    "value": "21845",
    "label": "http://www.bfcu.net"
  },
  {
    "value": "95042",
    "label": "http://www.bacu.org"
  },
  {
    "value": "66998",
    "label": "http://www.BHCCU.org"
  },
  {
    "value": "24523",
    "label": "http://www.blackstoneriverfcu.org"
  },
  {
    "value": "8831",
    "label": "http://www.blairctyfcu.org"
  },
  {
    "value": "60302",
    "label": "http://www.bkcu.net"
  },
  {
    "value": "62243",
    "label": "https://bloomcu.org/"
  },
  {
    "value": "7069",
    "label": "http://bfpfcu.com"
  },
  {
    "value": "63103",
    "label": "http://www.bmcu.org"
  },
  {
    "value": "64038",
    "label": "https://www.ea.financial-net.com"
  },
  {
    "value": "61790",
    "label": "http://www.blucurrent.org"
  },
  {
    "value": "7361",
    "label": "https://www.bluefcu.com"
  },
  {
    "value": "24385",
    "label": "http://www.bluechipfcu.org"
  },
  {
    "value": "11154",
    "label": "www.myBCFCU.com"
  },
  {
    "value": "68629",
    "label": "http://www.blueeaglecreditunion.com"
  },
  {
    "value": "63519",
    "label": "https://www.blueflamecu.org"
  },
  {
    "value": "64644",
    "label": "http://blueflamecreditunion.org"
  },
  {
    "value": "68280",
    "label": "http://www.bmcu.com"
  },
  {
    "value": "12906",
    "label": "http://bluewaterfcu.com"
  },
  {
    "value": "12648",
    "label": "http://www.bluegrasscreditunion.com"
  },
  {
    "value": "62072",
    "label": "http://blueoxcu.org"
  },
  {
    "value": "68411",
    "label": "http://www.bluestemccu.com"
  },
  {
    "value": "2874",
    "label": "http://www.bluestonefcu.org"
  },
  {
    "value": "60801",
    "label": "http://www.blupeak.com"
  },
  {
    "value": "9146",
    "label": "http://bmsfcu.org"
  },
  {
    "value": "1199",
    "label": "http://www.bmifcu.org"
  },
  {
    "value": "62604",
    "label": "http://www.becu.org"
  },
  {
    "value": "82504",
    "label": "www.boisefirecu.com"
  },
  {
    "value": "10433",
    "label": "http://www.boptifcu.com"
  },
  {
    "value": "24304",
    "label": "http://www.borderfcu.com"
  },
  {
    "value": "9300",
    "label": "http://www.bossierfcu.org"
  },
  {
    "value": "524",
    "label": "bostoncustomsfcu.org"
  },
  {
    "value": "67837",
    "label": "http://www.bosfirecu.com"
  },
  {
    "value": "61268",
    "label": "http://www.bhecu.com"
  },
  {
    "value": "96084",
    "label": "https://www.boulderdamcu.org"
  },
  {
    "value": "11640",
    "label": "http://www.blvdfcu.com"
  },
  {
    "value": "17415",
    "label": "http://www.bournsfcu.org"
  },
  {
    "value": "67970",
    "label": "http://www.bowaterecu.org"
  },
  {
    "value": "4176",
    "label": "http://WWW.BOXBUTTEFCU.COM"
  },
  {
    "value": "24445",
    "label": "http://www.bpfcu.org"
  },
  {
    "value": "13823",
    "label": "http://bradfordareafcu.org"
  },
  {
    "value": "24857",
    "label": "http://www.basecu.org"
  },
  {
    "value": "7955",
    "label": "http://www.braggmutual.org"
  },
  {
    "value": "60436",
    "label": "http://www.bbncu.org"
  },
  {
    "value": "67003",
    "label": "www.brantwoodcu.com"
  },
  {
    "value": "67510",
    "label": "https://www.brazosccu.org"
  },
  {
    "value": "67408",
    "label": "http://www.brazosstarcu.com"
  },
  {
    "value": "66353",
    "label": "https://www.bvscu.org"
  },
  {
    "value": "8212",
    "label": "https://www.breakwaterfcu.org"
  },
  {
    "value": "24526",
    "label": "http://www.brecofcu.com"
  },
  {
    "value": "24463",
    "label": "http://www.bbcfcu.org"
  },
  {
    "value": "13682",
    "label": "http://brewerfcu.org"
  },
  {
    "value": "66637",
    "label": "http://www.brewerycu.com"
  },
  {
    "value": "11445",
    "label": "http://www.brewsterfcu.org"
  },
  {
    "value": "62406",
    "label": "http://www.bridgecu.org"
  },
  {
    "value": "9582",
    "label": "http://www.mybpfcu.org"
  },
  {
    "value": "3575",
    "label": "http://www.bptpofcu.org"
  },
  {
    "value": "3476",
    "label": "http://www.bofcu.com"
  },
  {
    "value": "67347",
    "label": "www.bscu.org"
  },
  {
    "value": "6527",
    "label": "http://www.brightviewcu.com"
  },
  {
    "value": "51",
    "label": "https://www.broadviewfcu.com"
  },
  {
    "value": "20013",
    "label": "http://www.brockportfcu.org"
  },
  {
    "value": "67000",
    "label": "www.brokawcu.com"
  },
  {
    "value": "4616",
    "label": "http://www.broncofcu.com"
  },
  {
    "value": "24623",
    "label": "https://www.brooklandfcu.org"
  },
  {
    "value": "67340",
    "label": "http://BROOKLINECU.COM"
  },
  {
    "value": "24642",
    "label": "http://www.brooklyn.coop"
  },
  {
    "value": "66350",
    "label": "http://www.brotherhoodcreditunion.o"
  },
  {
    "value": "11746",
    "label": "http://www.bhcfcu.org"
  },
  {
    "value": "13873",
    "label": "http://brownfieldfcu.com"
  },
  {
    "value": "62482",
    "label": "http://www.bfecu.com"
  },
  {
    "value": "20392",
    "label": "http://brownsvillecityfcu.com/"
  },
  {
    "value": "97078",
    "label": "http://bsecu.org"
  },
  {
    "value": "96672",
    "label": "http://www.buckeyecu.org"
  },
  {
    "value": "845",
    "label": "bufconfcu.com"
  },
  {
    "value": "2209",
    "label": "www.bflometrocu.org"
  },
  {
    "value": "1157",
    "label": "http://www.buffalopolicefcu.org"
  },
  {
    "value": "24414",
    "label": "http://www.buildingtradesCU.com"
  },
  {
    "value": "18868",
    "label": "http://bdfcu.com"
  },
  {
    "value": "65239",
    "label": "http://MYBMECU.COM"
  },
  {
    "value": "62895",
    "label": "http://www.bnccu.org"
  },
  {
    "value": "13940",
    "label": "http://www.busindcu.com"
  },
  {
    "value": "3085",
    "label": "http://www.bctfcu.net"
  },
  {
    "value": "11440",
    "label": "http://www.butlerheritagefcu.org"
  },
  {
    "value": "19896",
    "label": "http://www.buttecommunityfcu.com"
  },
  {
    "value": "16063",
    "label": "http://www.bvafcu.org"
  },
  {
    "value": "67783",
    "label": "http://www.candrcreditunion.com"
  },
  {
    "value": "11299",
    "label": "http://www.casefcu.org"
  },
  {
    "value": "21057",
    "label": "https://www.ccmhfcu.org"
  },
  {
    "value": "22754",
    "label": "http://www.ccseonline.com"
  },
  {
    "value": "62352",
    "label": "http://www.cescu.com"
  },
  {
    "value": "6418",
    "label": "http://www.cspempfcu.com"
  },
  {
    "value": "13833",
    "label": "https://cupfcu.com"
  },
  {
    "value": "67891",
    "label": "http://www.cbcu.com"
  },
  {
    "value": "67581",
    "label": "http://cabotccu.com"
  },
  {
    "value": "68409",
    "label": "http://www.cabrillocu.com"
  },
  {
    "value": "10449",
    "label": "http://www.cabwaytelcofcu.org"
  },
  {
    "value": "8523",
    "label": "http://www.caclfcu.org"
  },
  {
    "value": "15589",
    "label": "http://www.CPTFCU.ORG"
  },
  {
    "value": "6930",
    "label": "http://cfcu@cadetsfcu.com"
  },
  {
    "value": "95071",
    "label": "http://cahpcu.org"
  },
  {
    "value": "14499",
    "label": "http://www.calstatela-fcu.org"
  },
  {
    "value": "18962",
    "label": "http://www.cpefcu.com"
  },
  {
    "value": "63142",
    "label": "http://www.calteacherscu.com"
  },
  {
    "value": "2237",
    "label": "http://www.calcoefcu.com"
  },
  {
    "value": "18623",
    "label": "http://www.calcomcu.org"
  },
  {
    "value": "18935",
    "label": "http://www.calcomfcu.org"
  },
  {
    "value": "13503",
    "label": "http://www.cal-ed.com"
  },
  {
    "value": "67431",
    "label": "http://www.clecu.org"
  },
  {
    "value": "60784",
    "label": "http://www.ccu.com"
  },
  {
    "value": "24238",
    "label": "http://www.SDACreditUnion.com"
  },
  {
    "value": "68052",
    "label": "http://www.CalAgCU.org"
  },
  {
    "value": "66584",
    "label": "http://www.calcoastcu.org"
  },
  {
    "value": "68321",
    "label": "https://www.caccu.org"
  },
  {
    "value": "64834",
    "label": "http://www.clcu.org"
  },
  {
    "value": "15209",
    "label": "http://www.callfcu.org"
  },
  {
    "value": "6681",
    "label": "http://www.cefcu.org"
  },
  {
    "value": "1236",
    "label": "http://www.cambridgefirefcu.org"
  },
  {
    "value": "3056",
    "label": "http://www.cambridgefcu.com"
  },
  {
    "value": "3115",
    "label": "http://CAMBRIDGETEACHERSFCU.ORG"
  },
  {
    "value": "22657",
    "label": "http://www.camcfcu.org"
  },
  {
    "value": "4633",
    "label": "http://www.caminofcu.org"
  },
  {
    "value": "18150",
    "label": "www.campshelbycreditunion.us"
  },
  {
    "value": "2141",
    "label": "http://www.campbellcu.org"
  },
  {
    "value": "20727",
    "label": "http://www.campcofcu.com"
  },
  {
    "value": "79",
    "label": "https://www.campusfederal.org"
  },
  {
    "value": "60731",
    "label": "http://www.campuscu.org"
  },
  {
    "value": "68391",
    "label": "http://www.campuscu.com"
  },
  {
    "value": "68508",
    "label": "http://www.canals-trailscu.org"
  },
  {
    "value": "16176",
    "label": "https://www.canandaiguafcu.com"
  },
  {
    "value": "7822",
    "label": "http://www.cannonfcu.org"
  },
  {
    "value": "11162",
    "label": "http://www.canopycu.com"
  },
  {
    "value": "2535",
    "label": "http://www.csefcu.com"
  },
  {
    "value": "64778",
    "label": "https://www.canvas.org/"
  },
  {
    "value": "24841",
    "label": "http://www.capecu.com"
  },
  {
    "value": "64621",
    "label": "http://www.CAPEREGIONALCU.com"
  },
  {
    "value": "24930",
    "label": "www.capitalfcu.org"
  },
  {
    "value": "61637",
    "label": "https://www.capcu.org/"
  },
  {
    "value": "66865",
    "label": "http://www.capitalcu.com"
  },
  {
    "value": "9919",
    "label": "https://WWW.CAPITALAREAFCU.ORG"
  },
  {
    "value": "20543",
    "label": "https://www.carfcu.org"
  },
  {
    "value": "1040",
    "label": "http://www.capedcu.com"
  },
  {
    "value": "67865",
    "label": "http://www.ccutx.org"
  },
  {
    "value": "62878",
    "label": "http://www.capview.com"
  },
  {
    "value": "16813",
    "label": "http://WWW.CAPROCKCU.COM"
  },
  {
    "value": "67441",
    "label": "http://www.csfcu.com"
  },
  {
    "value": "8611",
    "label": "https://www.capstonefcu.coop"
  },
  {
    "value": "68707",
    "label": "http://www.cardinalcu.com"
  },
  {
    "value": "6636",
    "label": "http://www.poverellofcu.com"
  },
  {
    "value": "7345",
    "label": "www.caribefederal.com"
  },
  {
    "value": "24404",
    "label": "http://www.SmartCaro.org"
  },
  {
    "value": "19788",
    "label": "http://www.carolinafcu.org"
  },
  {
    "value": "24801",
    "label": "http://www.carolinacu.com"
  },
  {
    "value": "13476",
    "label": "http://www.carolinafoothillsfcu.coo"
  },
  {
    "value": "12361",
    "label": "http://carolinatrust.org"
  },
  {
    "value": "23494",
    "label": "http://www.ctelco.org"
  },
  {
    "value": "24676",
    "label": "http://mycarpenterscu.org"
  },
  {
    "value": "9164",
    "label": "http://www.cartercu.org"
  },
  {
    "value": "7748",
    "label": "http://www.cascadefcu.org"
  },
  {
    "value": "5652",
    "label": "http://www.cascadecu.org"
  },
  {
    "value": "9327",
    "label": "http://www.cascofcu.com"
  },
  {
    "value": "61908",
    "label": "http://www.casecu.org"
  },
  {
    "value": "64868",
    "label": "http://www.casebine.com"
  },
  {
    "value": "11905",
    "label": "http://www.castpartsfcu.org"
  },
  {
    "value": "65735",
    "label": "http://candccu.com"
  },
  {
    "value": "5510",
    "label": "http://www.cffcu.com"
  },
  {
    "value": "64762",
    "label": "http://www.catholicfamilycu.com"
  },
  {
    "value": "68469",
    "label": "http://www.catholicunitedcu.org"
  },
  {
    "value": "68709",
    "label": "https://www.mycvf.org"
  },
  {
    "value": "68547",
    "label": "https://catholicsunitedcreditunion.com/"
  },
  {
    "value": "19027",
    "label": "http://www.cattcountyefcu.com"
  },
  {
    "value": "7608",
    "label": "http://www.cbcfcu.org"
  },
  {
    "value": "15848",
    "label": "https://cbifcu.org"
  },
  {
    "value": "11374",
    "label": "http://www.cbwsfcu.org"
  },
  {
    "value": "24944",
    "label": "ccefcu.net"
  },
  {
    "value": "6053",
    "label": "http://www.cdcfcu.com"
  },
  {
    "value": "8549",
    "label": "http://www.ccsefcu.org"
  },
  {
    "value": "65565",
    "label": "http://www.cfccu.org"
  },
  {
    "value": "5234",
    "label": "http://www.cpfcu.com"
  },
  {
    "value": "11254",
    "label": "http://www.celcofcu.org"
  },
  {
    "value": "67602",
    "label": "http://www.centexmfgcu.org"
  },
  {
    "value": "6733",
    "label": "http://WWW.CENCAP.COM"
  },
  {
    "value": "15602",
    "label": "http://www.cenlafcu.org"
  },
  {
    "value": "5641",
    "label": "http://www.censusfcu.com"
  },
  {
    "value": "65238",
    "label": "http://www.centcu.org"
  },
  {
    "value": "67416",
    "label": "https://centexcitizenscu.com"
  },
  {
    "value": "68287",
    "label": "http://www.centra.org"
  },
  {
    "value": "14455",
    "label": "http://www.centcoastfcu.com"
  },
  {
    "value": "64196",
    "label": "http://www.centralccu.com"
  },
  {
    "value": "62417",
    "label": "http://ccufl.org"
  },
  {
    "value": "60852",
    "label": "http://www.centralcu.org"
  },
  {
    "value": "66340",
    "label": "http://www.ccumd.org"
  },
  {
    "value": "65186",
    "label": "http://www.cicu.com"
  },
  {
    "value": "1108",
    "label": "http://www.cjfcu.org"
  },
  {
    "value": "3202",
    "label": "http://cjpolicefirefcu.org"
  },
  {
    "value": "61765",
    "label": "https://ckecusalina.com"
  },
  {
    "value": "9213",
    "label": "http://www.ckfcu.org"
  },
  {
    "value": "6936",
    "label": "http://www.centralmainecu.com"
  },
  {
    "value": "60383",
    "label": "http://www.cmccreditunion.org"
  },
  {
    "value": "24189",
    "label": "http://www.cnfcu.com"
  },
  {
    "value": "8218",
    "label": "http://www.centralfcu.com"
  },
  {
    "value": "4152",
    "label": "https://www.centralsoyafcu.org"
  },
  {
    "value": "60363",
    "label": "http://www.centralstatecu.org"
  },
  {
    "value": "14180",
    "label": "http://cscfederal.org"
  },
  {
    "value": "67425",
    "label": "http://www.cttcu.com"
  },
  {
    "value": "68008",
    "label": "http://WWW.CVFIRECU.ORG"
  },
  {
    "value": "65090",
    "label": "https://www.cvmccu.org"
  },
  {
    "value": "22307",
    "label": "http://www.cvfcu.com"
  },
  {
    "value": "62665",
    "label": "http://www.centralwcu.org"
  },
  {
    "value": "66513",
    "label": "http://www.mycwcu.com"
  },
  {
    "value": "1982",
    "label": "http://www.mycentric.org"
  },
  {
    "value": "68685",
    "label": "http://www.centricity.org"
  },
  {
    "value": "28",
    "label": "http://www.centrisfcu.org"
  },
  {
    "value": "5852",
    "label": "http://cenfedcu.org"
  },
  {
    "value": "60435",
    "label": "http://WWW.CENTURYCU.ORG"
  },
  {
    "value": "1352",
    "label": "chfcu.org"
  },
  {
    "value": "24668",
    "label": "http://CenturyFirstFCU.com"
  },
  {
    "value": "9230",
    "label": "http://www.certifiedfed.com"
  },
  {
    "value": "63851",
    "label": "http://WWW.CFLACU.COM"
  },
  {
    "value": "68322",
    "label": "https://www.mycfcu.com"
  },
  {
    "value": "67367",
    "label": "http://www.cgrcu.org"
  },
  {
    "value": "13966",
    "label": "http://www.chadronfcu.org"
  },
  {
    "value": "16030",
    "label": "http://chaffey.com"
  },
  {
    "value": "20570",
    "label": "http://www.championfcu.com"
  },
  {
    "value": "60721",
    "label": "http://www.championcu.com"
  },
  {
    "value": "63100",
    "label": "http://www.ChampionCU.org"
  },
  {
    "value": "63614",
    "label": "http://www.caecu.org/"
  },
  {
    "value": "17472",
    "label": "http://www.changingseasonsfcu.com"
  },
  {
    "value": "12922",
    "label": "http://cfcuwv.virtualcu.net"
  },
  {
    "value": "24743",
    "label": "http://www.charlestonpostalfcu.com"
  },
  {
    "value": "63566",
    "label": "http://www.cfdcu.org"
  },
  {
    "value": "3413",
    "label": "http://www.charteroak.org"
  },
  {
    "value": "13242",
    "label": "https://www.chartway.com"
  },
  {
    "value": "15935",
    "label": "http://www.chatelfcu.com"
  },
  {
    "value": "24221",
    "label": "https://WWW.CHATT.COOP"
  },
  {
    "value": "690",
    "label": "http://CASFCU.COM"
  },
  {
    "value": "62537",
    "label": "https://www.cfecu.org"
  },
  {
    "value": "14725",
    "label": "http://www.chattfirst.org"
  },
  {
    "value": "66143",
    "label": "http://www.ccsecunm.org"
  },
  {
    "value": "851",
    "label": "http://www.ChelseaEFCU.org"
  },
  {
    "value": "7129",
    "label": "http://www.chemcel.org"
  },
  {
    "value": "18172",
    "label": "http://WWW.CHEMCOFCU.COM"
  },
  {
    "value": "9517",
    "label": "http://www.chendelo.org"
  },
  {
    "value": "7101",
    "label": "http://www.cheneyfcu.com"
  },
  {
    "value": "13591",
    "label": "http://www.cherokeecountyfcu.com"
  },
  {
    "value": "10269",
    "label": "http://WWW.CCTEACHERS.COM"
  },
  {
    "value": "64281",
    "label": "http://www.cherokeestrip.com"
  },
  {
    "value": "5636",
    "label": "http://www.chessiefcu.org"
  },
  {
    "value": "15234",
    "label": "http://cadfcu.com"
  },
  {
    "value": "24326",
    "label": "http://www.chevronfcu.org"
  },
  {
    "value": "7307",
    "label": "www.clcefcu.org"
  },
  {
    "value": "23545",
    "label": "http://www.chhe.org"
  },
  {
    "value": "5045",
    "label": "http://www.chicagoareafcu.org"
  },
  {
    "value": "61209",
    "label": "http://chicagofirefighterscu.com"
  },
  {
    "value": "60175",
    "label": "http://chicagofiremansacu.org"
  },
  {
    "value": "65932",
    "label": "http://www.cmecuonline.org"
  },
  {
    "value": "2876",
    "label": "http://www.cpdfcu.com"
  },
  {
    "value": "95826",
    "label": "http://cpoecu.com"
  },
  {
    "value": "61560",
    "label": "http://www.chicagosbravestcu.com"
  },
  {
    "value": "4271",
    "label": "http://www.chiefonline.com"
  },
  {
    "value": "20186",
    "label": "www.cmcfcu.org"
  },
  {
    "value": "5422",
    "label": "http://WWW.CHIPHONE.ORG"
  },
  {
    "value": "61416",
    "label": "http://chippewacountycu.com"
  },
  {
    "value": "6871",
    "label": "http://www.chirofcu.org"
  },
  {
    "value": "5741",
    "label": "http://www.chivaho.com"
  },
  {
    "value": "15143",
    "label": "http://www.chocolatebayou.org"
  },
  {
    "value": "22314",
    "label": "http://choctawfcu.org"
  },
  {
    "value": "4240",
    "label": "http://www.choiceone.org"
  },
  {
    "value": "97068",
    "label": "http://www.mycccu.com"
  },
  {
    "value": "96699",
    "label": "http://www.christianfamilycu.com"
  },
  {
    "value": "63805",
    "label": "http://www.christianfinancialcu.org"
  },
  {
    "value": "7989",
    "label": "http://www.cfcuvi.org"
  },
  {
    "value": "20558",
    "label": "https://www.chromefcu.org"
  },
  {
    "value": "5127",
    "label": "churchofthemasterfcu.com"
  },
  {
    "value": "11824",
    "label": "https://www.myccfcu.org"
  },
  {
    "value": "65205",
    "label": "http://www.cintiecu.org"
  },
  {
    "value": "21157",
    "label": "http://www.healfcu.org"
  },
  {
    "value": "7088",
    "label": "http://www.cifcu.org"
  },
  {
    "value": "389",
    "label": "http://www.copfcu.com"
  },
  {
    "value": "21930",
    "label": "http://www.cinfed.org"
  },
  {
    "value": "22114",
    "label": "http://www.circlefcu.org"
  },
  {
    "value": "12912",
    "label": "http://WWW.CHEEKTOWAGACOMMUNITYFCU."
  },
  {
    "value": "2099",
    "label": "http://www.citadelbanking.com"
  },
  {
    "value": "9644",
    "label": "http://www.citcofcu.com"
  },
  {
    "value": "63109",
    "label": "http://www.citiescu.org"
  },
  {
    "value": "10170",
    "label": "http://www.citizensfcu.com"
  },
  {
    "value": "17715",
    "label": "http://www.citizenschoicefcu.org"
  },
  {
    "value": "66592",
    "label": "http://www.citizenscu.com"
  },
  {
    "value": "68448",
    "label": "www.cefcu.com"
  },
  {
    "value": "5665",
    "label": "http://CITYFEDERALCU.com"
  },
  {
    "value": "62062",
    "label": "www.ccutuscaloosa.com"
  },
  {
    "value": "67669",
    "label": "http://www.citycu.org"
  },
  {
    "value": "67924",
    "label": "http://www.city-cu.com"
  },
  {
    "value": "60255",
    "label": "http://www.cccu.com"
  },
  {
    "value": "24479",
    "label": "http://www.cityfcu.com"
  },
  {
    "value": "24679",
    "label": "http://www.alcitycountycu.org"
  },
  {
    "value": "4233",
    "label": "http://www.citycofcu.com"
  },
  {
    "value": "67778",
    "label": "http://www.cecuknox.com"
  },
  {
    "value": "67841",
    "label": "http://www.cityofbostoncu.com"
  },
  {
    "value": "16061",
    "label": "http://WWW.DPEFCU.ORG"
  },
  {
    "value": "3012",
    "label": "http://coffcu.org"
  },
  {
    "value": "3710",
    "label": "http://cpsibewfcu.org"
  },
  {
    "value": "67323",
    "label": "http://www.ccecu.org"
  },
  {
    "value": "14074",
    "label": "http://www.citymark.org"
  },
  {
    "value": "24898",
    "label": "https://www.civicfcu.org"
  },
  {
    "value": "62598",
    "label": "www.goodcu.org"
  },
  {
    "value": "1646",
    "label": "www.clairtonworksfcu.com"
  },
  {
    "value": "68295",
    "label": "http://www.claritycu.com"
  },
  {
    "value": "86752",
    "label": "http://www.ccculv.org"
  },
  {
    "value": "9106",
    "label": "http://www.classact.org"
  },
  {
    "value": "24889",
    "label": "http://www.cleanenergycu.org"
  },
  {
    "value": "9556",
    "label": "http://www.clearpathfcu.org"
  },
  {
    "value": "9007",
    "label": "http://www.clearviewfcu.org"
  },
  {
    "value": "11300",
    "label": "http://www.clearwatercreditunion.or"
  },
  {
    "value": "62588",
    "label": "http://www.clevepolicecu.com"
  },
  {
    "value": "24516",
    "label": "http://CLEVELANDSELFRELIANCE.COM"
  },
  {
    "value": "14677",
    "label": "http://www.cbctfcu.com"
  },
  {
    "value": "12152",
    "label": "http://bsdc.onlinecu.com/cliftycree"
  },
  {
    "value": "5549",
    "label": "http://www.clinchfieldcu.com"
  },
  {
    "value": "66844",
    "label": "http://www.ccuwausau.com"
  },
  {
    "value": "24881",
    "label": "http://cmcreditunion.com"
  },
  {
    "value": "22174",
    "label": "http://cmcfcpi.com"
  },
  {
    "value": "294",
    "label": "https://www.cmefcu.org"
  },
  {
    "value": "68069",
    "label": "http://www.cnicecu.net"
  },
  {
    "value": "68016",
    "label": "http://www.c2cfcu.com"
  },
  {
    "value": "62367",
    "label": "http://www.coastccu.org"
  },
  {
    "value": "62258",
    "label": "https://www.coastguardecu.net"
  },
  {
    "value": "60646",
    "label": "http://www.coastlinecu.com"
  },
  {
    "value": "15222",
    "label": "http://www.coast360fcu.com"
  },
  {
    "value": "18297",
    "label": "http://www.coastal24.com"
  },
  {
    "value": "11927",
    "label": "www.coastalcommunityfcu.org"
  },
  {
    "value": "67278",
    "label": "https://www.coastal1.org"
  },
  {
    "value": "68653",
    "label": "http://www.coasthills.coop"
  },
  {
    "value": "68482",
    "label": "http://www.coastlifecu.com"
  },
  {
    "value": "1115",
    "label": "coastlinefcu.org"
  },
  {
    "value": "24917",
    "label": "http://www.cobaltcu.com"
  },
  {
    "value": "16672",
    "label": "http://www.cobblestonecountryfcu.co"
  },
  {
    "value": "60409",
    "label": "http://www.coburncu.com"
  },
  {
    "value": "17017",
    "label": "http://creditunion.coca-cola.com"
  },
  {
    "value": "60151",
    "label": "http://www.cochisecu.com"
  },
  {
    "value": "14166",
    "label": "http://www.cochrancountyschoolsfcu."
  },
  {
    "value": "10335",
    "label": "http://www.coconinofcu.org"
  },
  {
    "value": "66840",
    "label": "http://www.codecu.org"
  },
  {
    "value": "3237",
    "label": "http://www.colfaxpowerplant.com"
  },
  {
    "value": "60688",
    "label": "http://cucu1600.com"
  },
  {
    "value": "68167",
    "label": "http://www.collegedale.org"
  },
  {
    "value": "62969",
    "label": "http://www.collinscu.org"
  },
  {
    "value": "68443",
    "label": "http://www.ccu.org"
  },
  {
    "value": "8725",
    "label": "http://www.Coloramo.org"
  },
  {
    "value": "62930",
    "label": "http://www.como-cu.com"
  },
  {
    "value": "68186",
    "label": "http://www.columbiacu.org"
  },
  {
    "value": "61065",
    "label": "http://www.columbiapostalcreditunio"
  },
  {
    "value": "63676",
    "label": "http://ccsecreditunion.com"
  },
  {
    "value": "11548",
    "label": "http://www.columbinefcu.org"
  },
  {
    "value": "16367",
    "label": "http://www.comanchecountyfcu.com"
  },
  {
    "value": "18474",
    "label": "http://www.combinedfcu.org"
  },
  {
    "value": "60825",
    "label": "https://www.combinedecu.com"
  },
  {
    "value": "21873",
    "label": "http://www.commodoreperryfcu.com"
  },
  {
    "value": "15788",
    "label": "http://www.commoncentscu.com"
  },
  {
    "value": "17847",
    "label": "http://www.commontrustfcu.org"
  },
  {
    "value": "8313",
    "label": "https://commonrootsfcu.com"
  },
  {
    "value": "61437",
    "label": "http://www.ccuky.org"
  },
  {
    "value": "97095",
    "label": "http://www.cwcu.com"
  },
  {
    "value": "61741",
    "label": "http://www.wealthcu.org"
  },
  {
    "value": "5134",
    "label": "http://www.cofcu.org"
  },
  {
    "value": "3454",
    "label": "http://www.comfedcu.org"
  },
  {
    "value": "10706",
    "label": "http://www.coafcu.org"
  },
  {
    "value": "61336",
    "label": "http://www.community-credit-union.o"
  },
  {
    "value": "63447",
    "label": "http://www.communitycreditunion.com"
  },
  {
    "value": "24730",
    "label": "http://WWW.TEACHERSCU.ORG"
  },
  {
    "value": "8588",
    "label": "http://www.c1stfcu.org"
  },
  {
    "value": "60079",
    "label": "https://WWW.MYC1CU.COM"
  },
  {
    "value": "68510",
    "label": "http://www.c1stcu.com"
  },
  {
    "value": "64880",
    "label": "http://www.communitychoicecu.com"
  },
  {
    "value": "65078",
    "label": "http://comchoicecu.org"
  },
  {
    "value": "66012",
    "label": "http://www.yourbetterbankingchoice."
  },
  {
    "value": "11172",
    "label": "http://www.communityconnectfcu.com"
  },
  {
    "value": "60816",
    "label": "http://www.ccuflorida.org"
  },
  {
    "value": "67558",
    "label": "http://www.myccu.org"
  },
  {
    "value": "61261",
    "label": "http://www.ccunm.org"
  },
  {
    "value": "68631",
    "label": "http://WWW.CFCU.ORG"
  },
  {
    "value": "6179",
    "label": "http://cfsfcu.com"
  },
  {
    "value": "24199",
    "label": "http://cffcu.biz"
  },
  {
    "value": "61094",
    "label": "http://WWW.CFCUA.COM"
  },
  {
    "value": "61667",
    "label": "http://www.comfirstcu.org"
  },
  {
    "value": "64746",
    "label": "http://mycommunityfirstcu.com"
  },
  {
    "value": "66642",
    "label": "http://WWW.COMMUNITYFIRSTCU.ORG"
  },
  {
    "value": "67290",
    "label": "http://www.communityfirstfl.org"
  },
  {
    "value": "24928",
    "label": "http://www.communityfirst.org"
  },
  {
    "value": "15051",
    "label": "http://www.cfirstguam.com"
  },
  {
    "value": "14098",
    "label": "http://www.communityfocusfcu.org"
  },
  {
    "value": "12847",
    "label": "http://www.Commhealthcu.org"
  },
  {
    "value": "60247",
    "label": "http://www.chcu.org"
  },
  {
    "value": "24770",
    "label": "http://www.communitylinkfcu.com"
  },
  {
    "value": "62628",
    "label": "http://www.c1cu.com"
  },
  {
    "value": "24905",
    "label": "www.communitypartnersfcu.com"
  },
  {
    "value": "24751",
    "label": "http://www.cPLUSfcu.org"
  },
  {
    "value": "15185",
    "label": "https://cpwrfcu.org"
  },
  {
    "value": "24848",
    "label": "http://www.communitypromisefcu.org"
  },
  {
    "value": "770",
    "label": "http://www.communityresourcefcu.com"
  },
  {
    "value": "68021",
    "label": "http://www.crcu.org"
  },
  {
    "value": "67974",
    "label": "http://www.cscutx.com"
  },
  {
    "value": "67434",
    "label": "http://www.communitysouth.net"
  },
  {
    "value": "65493",
    "label": "http://www.communityspiritcu.org"
  },
  {
    "value": "95008",
    "label": "http://www.commstar.org"
  },
  {
    "value": "60543",
    "label": "https://www.ctcu.org"
  },
  {
    "value": "61677",
    "label": "http://www.communitywestcu.org"
  },
  {
    "value": "67664",
    "label": "http://www.cacu.com"
  },
  {
    "value": "18336",
    "label": "http://www.comwide.com"
  },
  {
    "value": "17665",
    "label": "http://www.compassfcu.com"
  },
  {
    "value": "62040",
    "label": "http://www.compasscu.com"
  },
  {
    "value": "61933",
    "label": "http://www.compassccu.org"
  },
  {
    "value": "1068",
    "label": "http://www.compassffcu.org"
  },
  {
    "value": "66755",
    "label": "http://compassionatecarecu.org"
  },
  {
    "value": "12697",
    "label": "http://www.comcfcu.com/"
  },
  {
    "value": "606",
    "label": "https://www.comtrustfcu.com"
  },
  {
    "value": "24776",
    "label": "http://WWW.CLFCU.ORG"
  },
  {
    "value": "4684",
    "label": "http://www.conchoeducators.org"
  },
  {
    "value": "67473",
    "label": "http://www.conchovalleycu.com"
  },
  {
    "value": "7504",
    "label": "https://concordfcu.org"
  },
  {
    "value": "17250",
    "label": "http://www.co-nefcu.net"
  },
  {
    "value": "67342",
    "label": "www.connectcu.org"
  },
  {
    "value": "62976",
    "label": "http://www.connectedcreditunion.org"
  },
  {
    "value": "3544",
    "label": "http://ctlaborfcu.com"
  },
  {
    "value": "65728",
    "label": "http://CSECREDITUNION.COM"
  },
  {
    "value": "68254",
    "label": "https://connectioncu.org"
  },
  {
    "value": "68257",
    "label": "http://www.connectidaho.org"
  },
  {
    "value": "6670",
    "label": "http://www.connectsfcu.org"
  },
  {
    "value": "68511",
    "label": "http://www.connexcu.org"
  },
  {
    "value": "66538",
    "label": "http://www.connexuscu.org"
  },
  {
    "value": "64199",
    "label": "http://www.cecuonline.org"
  },
  {
    "value": "9292",
    "label": "https://www.consolidatedccu.com"
  },
  {
    "value": "21798",
    "label": "http://cfcuonline.com"
  },
  {
    "value": "60056",
    "label": "http://consumercreditunion.com"
  },
  {
    "value": "16951",
    "label": "http://CONSUMERSFCU.ORG"
  },
  {
    "value": "62172",
    "label": "http://www.consumerscu.org"
  },
  {
    "value": "22719",
    "label": "http://consumersfcu.coop"
  },
  {
    "value": "6974",
    "label": "www.ccfcuonline.com"
  },
  {
    "value": "68588",
    "label": "http://www.myconsumers.org"
  },
  {
    "value": "60947",
    "label": "http://www.cpcu.co"
  },
  {
    "value": "66983",
    "label": "http://www.coopcu.com"
  },
  {
    "value": "62419",
    "label": "http://www.co-opcreditunion.com"
  },
  {
    "value": "1134",
    "label": "http://WWW.COOPFEDERAL.COM"
  },
  {
    "value": "61783",
    "label": "http://www.cooperativecu.com"
  },
  {
    "value": "4900",
    "label": "http://www.coopfcu.org"
  },
  {
    "value": "63783",
    "label": "http://www.ccncu.com"
  },
  {
    "value": "62502",
    "label": "https://cecuok.com/"
  },
  {
    "value": "24431",
    "label": "http://cesfcu.org"
  },
  {
    "value": "67576",
    "label": "http://www.coopteachers.com"
  },
  {
    "value": "12671",
    "label": "http://cooperscavefcu.com"
  },
  {
    "value": "6774",
    "label": "http://www.coosapinesfcu.org"
  },
  {
    "value": "68683",
    "label": "http://mycvcu.org"
  },
  {
    "value": "60470",
    "label": "http://www.copoco.org"
  },
  {
    "value": "2796",
    "label": "www.copperandglassfcu.com/"
  },
  {
    "value": "16773",
    "label": "http://www.cbfcreditunion.com"
  },
  {
    "value": "68502",
    "label": "http://www.copperstatecu.org"
  },
  {
    "value": "22159",
    "label": "http://www.coralfcu.org"
  },
  {
    "value": "67371",
    "label": "https://www.corecu.org"
  },
  {
    "value": "1148",
    "label": "http://www.coreplus.org"
  },
  {
    "value": "1172",
    "label": "http://www.cornerpostfcu.org"
  },
  {
    "value": "68011",
    "label": "http://www.cornerstonecreditunion.n"
  },
  {
    "value": "60189",
    "label": "http://www.cornerstonecu.org"
  },
  {
    "value": "11723",
    "label": "http://CornerstoneCommunityFCU.org"
  },
  {
    "value": "68674",
    "label": "http://www.cornerstoneccu.com"
  },
  {
    "value": "68641",
    "label": "http://www.ccfinancial.com"
  },
  {
    "value": "67972",
    "label": "http://www.bankcfcu.org"
  },
  {
    "value": "1644",
    "label": "http://www.corningcu.org"
  },
  {
    "value": "68215",
    "label": "http://www.cafcu.org"
  },
  {
    "value": "67963",
    "label": "http://ccpecu.com"
  },
  {
    "value": "67485",
    "label": "http://www.ccspcu.com"
  },
  {
    "value": "1113",
    "label": "http://www.cw-fcu.com"
  },
  {
    "value": "13254",
    "label": "http://www.CORFED.com"
  },
  {
    "value": "12643",
    "label": "http://www.corryfcu.org"
  },
  {
    "value": "62048",
    "label": "http://www.corryjamestowncu.org"
  },
  {
    "value": "1750",
    "label": "http://www.cosden.org"
  },
  {
    "value": "6992",
    "label": "http://coteauvalleyfcu.com"
  },
  {
    "value": "4960",
    "label": "http://www.ccfcu.coop"
  },
  {
    "value": "62079",
    "label": "http://www.countryheritagecu.com"
  },
  {
    "value": "64056",
    "label": "http://www.countycu.org"
  },
  {
    "value": "66456",
    "label": "http://www.countycitycreditunion.com"
  },
  {
    "value": "120",
    "label": "http://WWW.COUNTYEDFCU.ORG"
  },
  {
    "value": "13495",
    "label": "http://csfcu.org"
  },
  {
    "value": "66848",
    "label": "http://www.covantagecu.org"
  },
  {
    "value": "19879",
    "label": "http://www.covefcu.com"
  },
  {
    "value": "24532",
    "label": "mycsfcu.org"
  },
  {
    "value": "5935",
    "label": "http://www.cowboycountry.virtualcu."
  },
  {
    "value": "20509",
    "label": "http://www.cccefcu.org"
  },
  {
    "value": "7524",
    "label": "http://www.cplant.com"
  },
  {
    "value": "21971",
    "label": "http://www.cpmfed.com"
  },
  {
    "value": "63828",
    "label": "http://www.cportcu.org"
  },
  {
    "value": "68672",
    "label": "http://www.cranecu.org"
  },
  {
    "value": "60780",
    "label": "http://www.cranstonmecu.org"
  },
  {
    "value": "24850",
    "label": "http://www.crayolacu.com"
  },
  {
    "value": "22322",
    "label": "http://www.crchcreditunion.com"
  },
  {
    "value": "24382",
    "label": "https://www.credithuman.com"
  },
  {
    "value": "66157",
    "label": "http://www.cu1.org"
  },
  {
    "value": "68727",
    "label": "http://www.creditunion1.org"
  },
  {
    "value": "62517",
    "label": "https://cuadvantage.coop"
  },
  {
    "value": "67741",
    "label": "http://www.tcurc.org"
  },
  {
    "value": "60062",
    "label": "http://www.cuofamerica.com"
  },
  {
    "value": "67383",
    "label": "http://www.cuatlanta.org"
  },
  {
    "value": "24854",
    "label": "http://www.cuofco.org"
  },
  {
    "value": "64342",
    "label": "http://www.cudenver.com"
  },
  {
    "value": "67856",
    "label": "http://www.cudodge.com"
  },
  {
    "value": "63319",
    "label": "http://www.cuofe.org"
  },
  {
    "value": "68571",
    "label": "http://www.cuofga.org"
  },
  {
    "value": "24927",
    "label": "http://www.cunj.org"
  },
  {
    "value": "68561",
    "label": "http://www.cuofohio.org"
  },
  {
    "value": "66929",
    "label": "https://www.curich.org"
  },
  {
    "value": "68456",
    "label": "http://www.cusocal.org"
  },
  {
    "value": "66327",
    "label": "http://cutx.org"
  },
  {
    "value": "67226",
    "label": "http://www.cu-rockies.org"
  },
  {
    "value": "63896",
    "label": "https://cuvermont.coop"
  },
  {
    "value": "62562",
    "label": "http://WWW.CUONE.ORG"
  },
  {
    "value": "64850",
    "label": "http://www.cuoneok.org"
  },
  {
    "value": "68421",
    "label": "http://www.cuwest.org"
  },
  {
    "value": "7576",
    "label": "http://www.creightonfederal.org"
  },
  {
    "value": "61262",
    "label": "http://www.coffeeregional.org/getpa"
  },
  {
    "value": "19228",
    "label": "http://www.crossvalleyfcu.org"
  },
  {
    "value": "1309",
    "label": "www.crosspointfcu.org"
  },
  {
    "value": "68557",
    "label": "http://www.crossroadscreditunion.co"
  },
  {
    "value": "2508",
    "label": "http://www.crcfcu.com"
  },
  {
    "value": "7002",
    "label": "http://www.crossroadsfcu.org"
  },
  {
    "value": "22972",
    "label": "http://www.crousefcu.com"
  },
  {
    "value": "68389",
    "label": "http://www.cwpcu.org"
  },
  {
    "value": "66856",
    "label": "https://csnccreditunion.org"
  },
  {
    "value": "62701",
    "label": "http://WWW.CSDCU.ORG"
  },
  {
    "value": "5086",
    "label": "http://www.csefcu.org"
  },
  {
    "value": "2498",
    "label": "http://74thstdfcu.com"
  },
  {
    "value": "24630",
    "label": "https://www.cuhawaii.com"
  },
  {
    "value": "66097",
    "label": "http://www.cubacreditunion.org"
  },
  {
    "value": "9500",
    "label": "http://www.myccfcu.com"
  },
  {
    "value": "24486",
    "label": "http://www.cumberlandcu.org"
  },
  {
    "value": "10640",
    "label": "http://www.cusafcu.com"
  },
  {
    "value": "8317",
    "label": "http://www.cuttingedgefcu.org"
  },
  {
    "value": "22195",
    "label": "http://www.cvphemployeesfcu.com"
  },
  {
    "value": "11452",
    "label": "http://www.cyfairfcu.org"
  },
  {
    "value": "24688",
    "label": "http://www.cypruscu.com"
  },
  {
    "value": "7021",
    "label": "http://www.dacotahfcu.com"
  },
  {
    "value": "24508",
    "label": "http://www.dcfcu.org"
  },
  {
    "value": "66665",
    "label": "http://www.dpcreditunion.com"
  },
  {
    "value": "11526",
    "label": "http://dakotaplainsfcu.com"
  },
  {
    "value": "63613",
    "label": "http://dakotaplainscreditunion.com"
  },
  {
    "value": "8039",
    "label": "http://www.dakotastar.org"
  },
  {
    "value": "62380",
    "label": "http://www.dakotawestcu.com"
  },
  {
    "value": "167",
    "label": "http://www.dakotalandfcu.com"
  },
  {
    "value": "24872",
    "label": "http://dalecu.com"
  },
  {
    "value": "67547",
    "label": "http://www.dupecu.org"
  },
  {
    "value": "62950",
    "label": "http://www.eatoncu.com"
  },
  {
    "value": "5063",
    "label": "https://www.dsfcu.net"
  },
  {
    "value": "12473",
    "label": "http://dfcu.net"
  },
  {
    "value": "14191",
    "label": "http://wwwdanversmfcu.com"
  },
  {
    "value": "67535",
    "label": "http://WWW.DATCU.ORG"
  },
  {
    "value": "20684",
    "label": "http://www.dctfcu.com"
  },
  {
    "value": "8967",
    "label": "davisonfcu.com"
  },
  {
    "value": "61624",
    "label": "http://dawsonco-opcu.com"
  },
  {
    "value": "66835",
    "label": "http://www.dayair.org"
  },
  {
    "value": "97105",
    "label": "http://www.daymetcu.com"
  },
  {
    "value": "16411",
    "label": "http://www.dccreditunion.coop"
  },
  {
    "value": "63507",
    "label": "http://www.desotomopaccu.com"
  },
  {
    "value": "68597",
    "label": "www.decu.com"
  },
  {
    "value": "65842",
    "label": "http://dmdcu.virtualcu.net/"
  },
  {
    "value": "65841",
    "label": "http://decaturpostalcreditunion.com"
  },
  {
    "value": "15073",
    "label": "https://www.dedhamcu.org"
  },
  {
    "value": "430",
    "label": "http://www.deepwaterind.org"
  },
  {
    "value": "60388",
    "label": "http://DRCCU.COM"
  },
  {
    "value": "19390",
    "label": "http://www.delmetfcu.com"
  },
  {
    "value": "64759",
    "label": "www.dncu.com"
  },
  {
    "value": "15790",
    "label": "http://www.all4youfcu.org"
  },
  {
    "value": "13791",
    "label": "https://www.dspfcu.com"
  },
  {
    "value": "21167",
    "label": "http://www.delmarvacreditunion.com"
  },
  {
    "value": "13919",
    "label": "http://www.del-one.org"
  },
  {
    "value": "24893",
    "label": "http://deltacu.virtual.cu.net"
  },
  {
    "value": "66357",
    "label": "https://www.deltacommunitycu.com"
  },
  {
    "value": "24381",
    "label": "http://www.deltacountyfcu.com"
  },
  {
    "value": "24492",
    "label": "http://deltaschoolsfcu.org"
  },
  {
    "value": "61559",
    "label": "dsecu.org/"
  },
  {
    "value": "1407",
    "label": "http://www.democracyfcu.org"
  },
  {
    "value": "17311",
    "label": "http://demopolisfcu.com"
  },
  {
    "value": "12028",
    "label": "www.denocoscu.com"
  },
  {
    "value": "68616",
    "label": "http://www.myzing.com"
  },
  {
    "value": "2881",
    "label": "http://www.dfdfcu.com"
  },
  {
    "value": "16410",
    "label": "http://www.docfcu.org"
  },
  {
    "value": "62808",
    "label": "http://www.doccu.org"
  },
  {
    "value": "8890",
    "label": "http://www.dpsfcu.org"
  },
  {
    "value": "319",
    "label": "http://www.interiorfcu.org"
  },
  {
    "value": "4963",
    "label": "http://www.dolfcu.org"
  },
  {
    "value": "6196",
    "label": "http://www.dafcu.com"
  },
  {
    "value": "64380",
    "label": "http://www.dmmcu.org"
  },
  {
    "value": "15882",
    "label": "http://www.descofcu.org"
  },
  {
    "value": "24708",
    "label": "http://www.dfcu.com"
  },
  {
    "value": "68713",
    "label": "http://www.desertfinancial.com"
  },
  {
    "value": "24918",
    "label": "http://www.desertriverscu.com"
  },
  {
    "value": "5366",
    "label": "http://www.desertvalleys.org"
  },
  {
    "value": "13790",
    "label": "http://www.dview.org"
  },
  {
    "value": "66333",
    "label": "http://www.destinationscu.org"
  },
  {
    "value": "62344",
    "label": "http://ddccu.com"
  },
  {
    "value": "16264",
    "label": "http://devilsslidefcu.org"
  },
  {
    "value": "2187",
    "label": "http://www.dexsta.com"
  },
  {
    "value": "61844",
    "label": "www.dfcufinancial.com"
  },
  {
    "value": "14409",
    "label": "http://www.diablovalleyfcu.org"
  },
  {
    "value": "68241",
    "label": "http://www.diamondcu.org"
  },
  {
    "value": "13762",
    "label": "http://www.diamondlakesfcu.org"
  },
  {
    "value": "14135",
    "label": "http://www.diamondvalleyfcu.org"
  },
  {
    "value": "23521",
    "label": "http://www.dcu.org"
  },
  {
    "value": "21268",
    "label": "http://www.mydfcu.com"
  },
  {
    "value": "67730",
    "label": "http://www.DillonCU.com"
  },
  {
    "value": "9071",
    "label": "http://www.direct.com"
  },
  {
    "value": "60978",
    "label": "http://www.directionscu.org"
  },
  {
    "value": "68420",
    "label": "http://www.directorschoicecu.com/"
  },
  {
    "value": "11144",
    "label": "http://www.dirigofcu.com"
  },
  {
    "value": "12935",
    "label": "http://www.discoveryfcu.org"
  },
  {
    "value": "1080",
    "label": "http://www.district6fcu.com"
  },
  {
    "value": "14651",
    "label": "http://www.district08fcu.org"
  },
  {
    "value": "17023",
    "label": "http://d123.virtualcu.net"
  },
  {
    "value": "1821",
    "label": "http://www.dctfcu.org"
  },
  {
    "value": "63420",
    "label": "http://district1hwycu.org"
  },
  {
    "value": "17411",
    "label": "http://www.yourdgfcu.org"
  },
  {
    "value": "62339",
    "label": "http://dmcu.com"
  },
  {
    "value": "64755",
    "label": "http://www.d6hcu.com"
  },
  {
    "value": "20336",
    "label": "http://div726fcu.com"
  },
  {
    "value": "62855",
    "label": "www.div819cu.com"
  },
  {
    "value": "67937",
    "label": "http://www.dixieline.org"
  },
  {
    "value": "5569",
    "label": "http://www.dixiefed.com"
  },
  {
    "value": "24720",
    "label": "http://www.dncfcu.com"
  },
  {
    "value": "62446",
    "label": "http://www.dochescu.com"
  },
  {
    "value": "23376",
    "label": "http://www.doerunfcu.com"
  },
  {
    "value": "66973",
    "label": "http://www.dominionenergycu.org"
  },
  {
    "value": "21383",
    "label": "http://www.dominofcu.com"
  },
  {
    "value": "68708",
    "label": "http://DORTONLINE.ORG"
  },
  {
    "value": "12443",
    "label": "http://doverfcu.com"
  },
  {
    "value": "8822",
    "label": "http://www.dpfcu.org"
  },
  {
    "value": "60137",
    "label": "http://www.dowcreditunion.org"
  },
  {
    "value": "64503",
    "label": "http://www.dowcu.org"
  },
  {
    "value": "21070",
    "label": "https://www.dafcu.net"
  },
  {
    "value": "68563",
    "label": "http://www.downeastcu.com"
  },
  {
    "value": "11870",
    "label": "http://www.downeyfcu.org"
  },
  {
    "value": "4853",
    "label": "http://www.downrivercu.com"
  },
  {
    "value": "20661",
    "label": "http://doyfcu.com"
  },
  {
    "value": "64471",
    "label": "http://WWW.DUTRAC.ORG"
  },
  {
    "value": "20805",
    "label": "http://www.duboispike.org"
  },
  {
    "value": "64917",
    "label": "http://dpecu.com"
  },
  {
    "value": "15146",
    "label": "http://www.dugood.org"
  },
  {
    "value": "18616",
    "label": "http://www.dukefcu.org"
  },
  {
    "value": "64049",
    "label": "http://www.dupaco.com"
  },
  {
    "value": "97084",
    "label": "http://www.dupagecu.com"
  },
  {
    "value": "61290",
    "label": "http://www.dupagececu.org"
  },
  {
    "value": "62474",
    "label": "http://mydccu.com"
  },
  {
    "value": "60457",
    "label": "www.dutchpoint.org"
  },
  {
    "value": "6747",
    "label": "http://DYNAMICFCU.COM"
  },
  {
    "value": "66847",
    "label": "http://www.eaglecu.org"
  },
  {
    "value": "48",
    "label": "http://www.eagleexpressfcu.com"
  },
  {
    "value": "24661",
    "label": "http://www.eaglefederal.org"
  },
  {
    "value": "464",
    "label": "http://www.eagleonefcu.org"
  },
  {
    "value": "97059",
    "label": "http://www.earthmovercu.com"
  },
  {
    "value": "12383",
    "label": "http://www.eamcfcu.com"
  },
  {
    "value": "7253",
    "label": "http://www.ebrteachersfcu.org"
  },
  {
    "value": "11099",
    "label": "http://www.eastcountyschools.org"
  },
  {
    "value": "24483",
    "label": "https://www.eefcfcu.org"
  },
  {
    "value": "68376",
    "label": "https://frontiercreditunion.com"
  },
  {
    "value": "11014",
    "label": "eoffcu.com"
  },
  {
    "value": "11604",
    "label": "http://www.eovahfcu.com"
  },
  {
    "value": "11353",
    "label": "http://www.eastriverfcu.com"
  },
  {
    "value": "67761",
    "label": "http://www.etpcu.org"
  },
  {
    "value": "7066",
    "label": "http://www.easterninfcu.com"
  },
  {
    "value": "19280",
    "label": "http://www.ekyfcu.com"
  },
  {
    "value": "19890",
    "label": "https://www.easternpanhandlefcu.com"
  },
  {
    "value": "24709",
    "label": "http://www.euccu.com"
  },
  {
    "value": "97098",
    "label": "http://www.eastexcu.org"
  },
  {
    "value": "68095",
    "label": "http://www.ecu.org"
  },
  {
    "value": "9518",
    "label": "http://www.eastmillfcu.org"
  },
  {
    "value": "60124",
    "label": "http://www.eastpointeccu.com"
  },
  {
    "value": "68354",
    "label": "https://www.eatonfamilycu.com"
  },
  {
    "value": "62323",
    "label": "http://www.ecentralcu.org"
  },
  {
    "value": "24883",
    "label": "http://www.ecmfcu.com"
  },
  {
    "value": "65464",
    "label": "http://www.ecocu.org"
  },
  {
    "value": "67336",
    "label": "http://www.ecucreditunion.com"
  },
  {
    "value": "63663",
    "label": "http://www.ecustacu.com"
  },
  {
    "value": "9566",
    "label": "http://www.eddyfederalcu.com"
  },
  {
    "value": "66366",
    "label": "www.etcu.coop"
  },
  {
    "value": "67929",
    "label": "http://EDISONCU.COM"
  },
  {
    "value": "1397",
    "label": "www.edistofederalcu.org"
  },
  {
    "value": "13186",
    "label": "http://EDMEDFCU.COM"
  },
  {
    "value": "8609",
    "label": "http://www.edufcu.org"
  },
  {
    "value": "67494",
    "label": "http://www.educationcu.com"
  },
  {
    "value": "67127",
    "label": "http://E1cu.org"
  },
  {
    "value": "24470",
    "label": "http://www.educationfirstfcu.org"
  },
  {
    "value": "68447",
    "label": "http://www.educu.org"
  },
  {
    "value": "1969",
    "label": "http://www.educationpersonnelfcu.co"
  },
  {
    "value": "176",
    "label": "http://www.edfed.org"
  },
  {
    "value": "2346",
    "label": "http://www.egefcu.com"
  },
  {
    "value": "62589",
    "label": "http://www.educationalccu.org"
  },
  {
    "value": "68181",
    "label": "http://www.educacu.com"
  },
  {
    "value": "60520",
    "label": "http://WWW.MYEECU.ORG"
  },
  {
    "value": "10696",
    "label": "http://www.esfcu.org"
  },
  {
    "value": "66448",
    "label": "http://www.ecu.com"
  },
  {
    "value": "67611",
    "label": "http://EDCU.COM"
  },
  {
    "value": "14830",
    "label": "https://edwardsfcu.org/"
  },
  {
    "value": "67928",
    "label": "http://www.eecu.org"
  },
  {
    "value": "13",
    "label": "http://www.efcuFINANCIAL.org"
  },
  {
    "value": "9788",
    "label": "https://www.eglinfcu.org"
  },
  {
    "value": "20222",
    "label": "http://eightfederalcu.org"
  },
  {
    "value": "95072",
    "label": "www.elmonteccu.org"
  },
  {
    "value": "62479",
    "label": "http://www.rockislandcu.com"
  },
  {
    "value": "24866",
    "label": "http://www.elcafcu.org"
  },
  {
    "value": "24819",
    "label": "http://www.electel.org"
  },
  {
    "value": "23361",
    "label": "http://www.ElectricCoopFCU.org"
  },
  {
    "value": "63988",
    "label": "http://EMCU.ORG"
  },
  {
    "value": "8701",
    "label": "http://www.electricalfcu.org"
  },
  {
    "value": "10393",
    "label": "https://ibewlu130.com/local-130-fed"
  },
  {
    "value": "64267",
    "label": "http://www.ewcu.net"
  },
  {
    "value": "10763",
    "label": "http://www.ew22fcu.org"
  },
  {
    "value": "12478",
    "label": "http://www.ew558fcu.com"
  },
  {
    "value": "67338",
    "label": "http://www.local349cu.org"
  },
  {
    "value": "62590",
    "label": "http://www.electrosavings.com"
  },
  {
    "value": "23162",
    "label": "http://www.elementfcu.org"
  },
  {
    "value": "22468",
    "label": "http://www.elements.org"
  },
  {
    "value": "9163",
    "label": "http://www.elevatecu.com"
  },
  {
    "value": "68565",
    "label": "http://www.elevationscu.com"
  },
  {
    "value": "61797",
    "label": "http://www.elgacu.com"
  },
  {
    "value": "60304",
    "label": "http://www.eliteccu.com"
  },
  {
    "value": "916",
    "label": "http://home.earthlink.net/~enjffcu"
  },
  {
    "value": "13800",
    "label": "http://www.elkofcu.org"
  },
  {
    "value": "390",
    "label": "http://elliottfcu.com"
  },
  {
    "value": "12266",
    "label": "http://ectefcu.net"
  },
  {
    "value": "63398",
    "label": "http://www.elyareacu.org"
  },
  {
    "value": "7715",
    "label": "http://emfederalcreditunion.org"
  },
  {
    "value": "4708",
    "label": "http://www.evfcu.org"
  },
  {
    "value": "62038",
    "label": "https://www.embers.org"
  },
  {
    "value": "11793",
    "label": "www.emboldcu.org"
  },
  {
    "value": "3388",
    "label": "https://www.emeraldcoastfcu.com"
  },
  {
    "value": "19047",
    "label": "http://www.emeraldcu.com"
  },
  {
    "value": "97077",
    "label": "http://www.emeraldgcu.com"
  },
  {
    "value": "9877",
    "label": "http://www.youreecu.org"
  },
  {
    "value": "66354",
    "label": "http://www.emrspcu.com"
  },
  {
    "value": "3360",
    "label": "http://www.emeryfcu.org"
  },
  {
    "value": "68598",
    "label": "http://www.deereemployeescu.com"
  },
  {
    "value": "613",
    "label": "http://www.empireonefcu.org"
  },
  {
    "value": "67789",
    "label": "http://www.ercu.org"
  },
  {
    "value": "9715",
    "label": "http://www.employeesfed.com"
  },
  {
    "value": "63299",
    "label": "http://www.employeescu.com"
  },
  {
    "value": "13526",
    "label": "http://www.employeeschoice.org"
  },
  {
    "value": "21904",
    "label": "www.employeesunited.org"
  },
  {
    "value": "2227",
    "label": "https://www.esfcu.com"
  },
  {
    "value": "3025",
    "label": "http://www.empowerfcu.com"
  },
  {
    "value": "68317",
    "label": "http://empower-cu.org"
  },
  {
    "value": "24769",
    "label": "http://www.ecdfcu.com"
  },
  {
    "value": "68148",
    "label": "https://enbrightcu.com"
  },
  {
    "value": "20015",
    "label": "http://www.encentusfcu.org"
  },
  {
    "value": "8548",
    "label": "http://www.encompasscu.org"
  },
  {
    "value": "24873",
    "label": "http://www.encompassniagara.com/"
  },
  {
    "value": "68450",
    "label": "http://WWW.FIRSTFCU.ORG"
  },
  {
    "value": "13898",
    "label": "http://www.endurancefcu.org"
  },
  {
    "value": "60994",
    "label": "http://www.energize.coop"
  },
  {
    "value": "66369",
    "label": "https://www.energycreditunion.org"
  },
  {
    "value": "61936",
    "label": "https://www.eccu.net"
  },
  {
    "value": "256",
    "label": "http://www.energyone.org"
  },
  {
    "value": "13729",
    "label": "http://energypeoplefcu.com"
  },
  {
    "value": "65458",
    "label": "http://www.energypluscu.org"
  },
  {
    "value": "1729",
    "label": "http://www.enfieldcommunityfcu.org"
  },
  {
    "value": "22005",
    "label": "http://www.engagefcu.org"
  },
  {
    "value": "20662",
    "label": "http://www.enlightenfcu.org"
  },
  {
    "value": "6778",
    "label": "http://www.enrichmentfcu.org"
  },
  {
    "value": "68671",
    "label": "http://www.ent.com"
  },
  {
    "value": "66880",
    "label": "http://www.enterprisecu.com"
  },
  {
    "value": "68407",
    "label": "ecuks.com"
  },
  {
    "value": "22032",
    "label": "http://www.eicu.org"
  },
  {
    "value": "67329",
    "label": "http://www.envisioncu.com"
  },
  {
    "value": "24945",
    "label": "www.envistacu.com"
  },
  {
    "value": "262",
    "label": "http://www.epfcu.org"
  },
  {
    "value": "60619",
    "label": "http://www.epbecu.org"
  },
  {
    "value": "24506",
    "label": "http://www.efcula.org"
  },
  {
    "value": "5028",
    "label": "http://www.eqtfcu.org"
  },
  {
    "value": "1476",
    "label": "http://www.eriefcu.org"
  },
  {
    "value": "12616",
    "label": "http://www.eriecommunityfcu.org"
  },
  {
    "value": "62923",
    "label": "http://www.eriecountycreditunion.ne"
  },
  {
    "value": "8625",
    "label": "http://www.eriefirefightersfcu.com"
  },
  {
    "value": "13402",
    "label": "http://www.escondidofcu.com"
  },
  {
    "value": "24563",
    "label": "https://www.esl.org/"
  },
  {
    "value": "9590",
    "label": "http://www.espeeco.org"
  },
  {
    "value": "68736",
    "label": "http://www.essentialcu.org"
  },
  {
    "value": "1131",
    "label": "http://www.ectcu.org"
  },
  {
    "value": "8299",
    "label": "http://WWW.ESTACADOFCU.COM"
  },
  {
    "value": "11152",
    "label": "https://www.etmafcu.net"
  },
  {
    "value": "14562",
    "label": "http://www.evansvillefcu.org"
  },
  {
    "value": "1263",
    "label": "http://www.evffcu.info"
  },
  {
    "value": "18649",
    "label": "http://www.evergreenfcu.com"
  },
  {
    "value": "10223",
    "label": "http://www.everence.com"
  },
  {
    "value": "24906",
    "label": "http://www.everestfcu.org"
  },
  {
    "value": "24204",
    "label": "http://www.evergladesfcu.com"
  },
  {
    "value": "66395",
    "label": "http://www.evergreencu.com"
  },
  {
    "value": "68038",
    "label": "http://www.egcu.org"
  },
  {
    "value": "24547",
    "label": "http://www.epsfcu.org"
  },
  {
    "value": "68546",
    "label": "http://www.evergreendirect.org"
  },
  {
    "value": "67456",
    "label": "http://epcreditunion.com"
  },
  {
    "value": "60060",
    "label": "http://WWW.everwisecu.com"
  },
  {
    "value": "9370",
    "label": "http://everyonesfcu.com"
  },
  {
    "value": "1792",
    "label": "http://WWW.evolveFCU.ORG"
  },
  {
    "value": "1987",
    "label": "http://www.ewafcu.com"
  },
  {
    "value": "9777",
    "label": "http://www.myewebcu.org/"
  },
  {
    "value": "7825",
    "label": "http://WWW.EXCELFCU.ORG"
  },
  {
    "value": "68441",
    "label": "http://www.excitecu.org"
  },
  {
    "value": "62964",
    "label": "http://www.uecu.coop"
  },
  {
    "value": "14874",
    "label": "http://www.explorerscu.com"
  },
  {
    "value": "62431",
    "label": "http://www.expree.org"
  },
  {
    "value": "68273",
    "label": "http://www.expresscu.org"
  },
  {
    "value": "60428",
    "label": "http://www.extracreditunion.org"
  },
  {
    "value": "1493",
    "label": "http://WWW.FAFCU.ORG"
  },
  {
    "value": "9639",
    "label": "http://www.faafcu.org"
  },
  {
    "value": "9527",
    "label": "www.fabfcu.com"
  },
  {
    "value": "320",
    "label": "http://frbfcu.org"
  },
  {
    "value": "9102",
    "label": "http://FFE-FCU.org"
  },
  {
    "value": "68018",
    "label": "http://frsacu.org"
  },
  {
    "value": "12305",
    "label": "http://www.fairfieldcreditunion.org"
  },
  {
    "value": "16584",
    "label": "http://www.fdufcu.org"
  },
  {
    "value": "3439",
    "label": "http://www.fairmontfcu.com"
  },
  {
    "value": "24612",
    "label": "https://sites.google.com/a/apps.fai"
  },
  {
    "value": "68417",
    "label": "http://www.fairwinds.org"
  },
  {
    "value": "96706",
    "label": "http://www.faithcommcu.com"
  },
  {
    "value": "13704",
    "label": "http://www.unitedmethodistcu.com"
  },
  {
    "value": "12859",
    "label": "http://www.faithcfcu.com/"
  },
  {
    "value": "67894",
    "label": "http://www.frmcu.com"
  },
  {
    "value": "96707",
    "label": "http://fallsccu.org"
  },
  {
    "value": "9109",
    "label": "www.fastcu.com"
  },
  {
    "value": "7383",
    "label": "http://www.family1stfcu.com"
  },
  {
    "value": "678",
    "label": "http://www.family1stfcu.org"
  },
  {
    "value": "24603",
    "label": "http://www.myfafcu.org"
  },
  {
    "value": "64067",
    "label": "http://www.ccfccu.com"
  },
  {
    "value": "61633",
    "label": "https://www.ff-cu.org"
  },
  {
    "value": "61831",
    "label": "https://www.ffcuga.org"
  },
  {
    "value": "61909",
    "label": "http://www.fam1st.com"
  },
  {
    "value": "19304",
    "label": "http://www.familyfirstny.com"
  },
  {
    "value": "24771",
    "label": "www.familyfocusfcu.org"
  },
  {
    "value": "64942",
    "label": "http://www.familyhorizons.com"
  },
  {
    "value": "68628",
    "label": "http://www.familysavingscu.com"
  },
  {
    "value": "60605",
    "label": "http://myfscu.com"
  },
  {
    "value": "11554",
    "label": "http://www.familytrust.org"
  },
  {
    "value": "17254",
    "label": "www.fannincu.com"
  },
  {
    "value": "19741",
    "label": "http://fanninteachersfcu.com"
  },
  {
    "value": "47",
    "label": "http://www.fpsfcu.com"
  },
  {
    "value": "288",
    "label": "http://www.fargovafcu.org"
  },
  {
    "value": "62330",
    "label": "http://www.farmbureaufamilycu.org"
  },
  {
    "value": "22132",
    "label": "http://www.fcefcu.com"
  },
  {
    "value": "26",
    "label": "http://FARMERSFCU.COM"
  },
  {
    "value": "20303",
    "label": "https://fbcefcu.virtualcu.net/"
  },
  {
    "value": "1074",
    "label": "http://www.figfcu.com"
  },
  {
    "value": "63374",
    "label": "http://www.farmwaycu.com"
  },
  {
    "value": "24690",
    "label": "http://WWW.FASNYCU.COM"
  },
  {
    "value": "13115",
    "label": "http://www.fassoncreditunion.com"
  },
  {
    "value": "12310",
    "label": "http://fayettefcu.virtualcu.net"
  },
  {
    "value": "61515",
    "label": "http://www.fayetteschoolcu.org"
  },
  {
    "value": "10690",
    "label": "http://www.ffefcu.org"
  },
  {
    "value": "4195",
    "label": "http://fcifcu.com"
  },
  {
    "value": "42",
    "label": "http://www.fdcommunityfcu.org"
  },
  {
    "value": "150",
    "label": "http://www.fedchoice.org"
  },
  {
    "value": "62599",
    "label": "http://www.federalempscu.com"
  },
  {
    "value": "62929",
    "label": "https://federalemployeescu-dn.finan"
  },
  {
    "value": "4894",
    "label": "http://WWW.FENFCU.COM"
  },
  {
    "value": "62105",
    "label": "http://www.fecccu.com"
  },
  {
    "value": "64703",
    "label": "http://www.fedecu.com"
  },
  {
    "value": "21751",
    "label": "fecca.com"
  },
  {
    "value": "216",
    "label": "http://www.fedfinancial.org"
  },
  {
    "value": "14026",
    "label": "www.FedmontFCU.com"
  },
  {
    "value": "13233",
    "label": "http://www.fedtrustfcu.com"
  },
  {
    "value": "13687",
    "label": "http://www.felicianafcu.com"
  },
  {
    "value": "64852",
    "label": "http://www.fellowshipcreditunion.co"
  },
  {
    "value": "64252",
    "label": "fellowshipchicagocu.com"
  },
  {
    "value": "8881",
    "label": "https://www.fergusfcu.com"
  },
  {
    "value": "18515",
    "label": "http://fergusonfcu.org"
  },
  {
    "value": "10381",
    "label": "http://www.ferkomfcu.com"
  },
  {
    "value": "2191",
    "label": "http://www.fibrecu.com"
  },
  {
    "value": "13902",
    "label": "http://www.ficarefcu.org"
  },
  {
    "value": "11380",
    "label": "www.fidelisfcu.org"
  },
  {
    "value": "9810",
    "label": "http://www.fideliscu.org"
  },
  {
    "value": "60325",
    "label": "http://www.fieldstonecu.com"
  },
  {
    "value": "62010",
    "label": "http://www.filercu.com"
  },
  {
    "value": "12238",
    "label": "http://www.FinancialAccessfcu.org"
  },
  {
    "value": "16213",
    "label": "http://www.Financialbuilders.org"
  },
  {
    "value": "68658",
    "label": "http://www.fcfcu.com"
  },
  {
    "value": "19605",
    "label": "http://www.financialedufcu.com"
  },
  {
    "value": "20793",
    "label": "http://financialhealthfcu.org"
  },
  {
    "value": "86755",
    "label": "http://www.fhcunv.org"
  },
  {
    "value": "16619",
    "label": "http://www.financialpartnersfcu.org"
  },
  {
    "value": "60545",
    "label": "http://www.fpcu.net"
  },
  {
    "value": "68457",
    "label": "http://www.fpcu.org"
  },
  {
    "value": "61231",
    "label": "http://www.financialplus.org"
  },
  {
    "value": "62965",
    "label": "http://www.financialpluscu.com"
  },
  {
    "value": "68646",
    "label": "https://www.myfpcu.com"
  },
  {
    "value": "6665",
    "label": "http://WWW.MYFINANCIALRESOURCES.ORG"
  },
  {
    "value": "62452",
    "label": "http://www.finseccu.com"
  },
  {
    "value": "6870",
    "label": "http://www.financialtrustfederalcre"
  },
  {
    "value": "61619",
    "label": "http://www.financialedgeccu.org"
  },
  {
    "value": "1457",
    "label": "http://www.finansfcu.org"
  },
  {
    "value": "68662",
    "label": "http://www.finexcu.org"
  },
  {
    "value": "18022",
    "label": "http://www.flfcu.org"
  },
  {
    "value": "21294",
    "label": "http://WWW.FLHC.COM"
  },
  {
    "value": "60696",
    "label": "http://firefighterscu.org"
  },
  {
    "value": "21593",
    "label": "http://www.fpccfcu.org"
  },
  {
    "value": "66423",
    "label": "http://www.myfirecu.org"
  },
  {
    "value": "67079",
    "label": "https://www.firefighterscu.com"
  },
  {
    "value": "97052",
    "label": "http://www.fire-cu.org"
  },
  {
    "value": "395",
    "label": "http://www.ffcocu.org"
  },
  {
    "value": "96723",
    "label": "http://www.ffcommunity.com"
  },
  {
    "value": "24877",
    "label": "http://www.firefightersfirstcu.org"
  },
  {
    "value": "11674",
    "label": "http://www.firelandsfcu.org"
  },
  {
    "value": "14066",
    "label": "http://www.fofcu.com"
  },
  {
    "value": "24673",
    "label": "http://www.firstfedcu.com"
  },
  {
    "value": "68444",
    "label": "http://www.FirstCU.net"
  },
  {
    "value": "62872",
    "label": "http://www.firstalliancecu.com"
  },
  {
    "value": "64412",
    "label": "http://www.firstamerican.org"
  },
  {
    "value": "14346",
    "label": "https://www.firstareafcu.com"
  },
  {
    "value": "61756",
    "label": "http://www.firstareacu.com"
  },
  {
    "value": "82",
    "label": "http://FAFCU.COM"
  },
  {
    "value": "22226",
    "label": "http://www.firstbaptistfcu.com"
  },
  {
    "value": "67609",
    "label": "http://www.firstbasin.com"
  },
  {
    "value": "722",
    "label": "http://www.firstbristol.org"
  },
  {
    "value": "23964",
    "label": "http://www.firstcalfcu.org"
  },
  {
    "value": "24490",
    "label": "http://www.firstcapitalfcu.com"
  },
  {
    "value": "62673",
    "label": "http://www.fcpcu.com"
  },
  {
    "value": "67644",
    "label": "http://www.firstcentralcu.com"
  },
  {
    "value": "8229",
    "label": "https://www.firstchoicefcu.com"
  },
  {
    "value": "66545",
    "label": "http://FIRSTCHOICECREDITUNION.COM"
  },
  {
    "value": "67725",
    "label": "http://www.firstchoicecu.org"
  },
  {
    "value": "3264",
    "label": "http://www.firstchoiceamericacu.org"
  },
  {
    "value": "67185",
    "label": "http://www.firstchoiceccu.org"
  },
  {
    "value": "21012",
    "label": "http://www.fcfinancialcu.org"
  },
  {
    "value": "24254",
    "label": "http://www.firstcitizens.org"
  },
  {
    "value": "68503",
    "label": "http://Firstcitycu.org"
  },
  {
    "value": "64231",
    "label": "http://www.gofirstclass.org"
  },
  {
    "value": "68524",
    "label": "http://www.firstcoastccu.com"
  },
  {
    "value": "68301",
    "label": "http://www.firstcommercecu.org"
  },
  {
    "value": "12765",
    "label": "http://www.firstcomcu.org"
  },
  {
    "value": "60103",
    "label": "https://www.myfccu.com"
  },
  {
    "value": "66818",
    "label": "http://FCCU.org"
  },
  {
    "value": "67619",
    "label": "http://www.firstcommunity.com"
  },
  {
    "value": "68530",
    "label": "http://www.myfirstccu.org"
  },
  {
    "value": "67214",
    "label": "http://www.firstccu.com"
  },
  {
    "value": "14163",
    "label": "http://www.firstcountyfcu.org"
  },
  {
    "value": "10958",
    "label": "http://www.firsteaglefcu.org"
  },
  {
    "value": "5465",
    "label": "https://firstedfcu.com"
  },
  {
    "value": "68373",
    "label": "http://www.firstent.org"
  },
  {
    "value": "11057",
    "label": "http://www.firstfamilyfcu.com"
  },
  {
    "value": "1021",
    "label": "http://www.firstffcu.com"
  },
  {
    "value": "68244",
    "label": "http://www.ffnm.org"
  },
  {
    "value": "8554",
    "label": "http://www.firstfinancial.org"
  },
  {
    "value": "7870",
    "label": "http://www.efirstflight.com"
  },
  {
    "value": "61170",
    "label": "https://www.firstflorida.org"
  },
  {
    "value": "3951",
    "label": "http://www.firstharvestcu.com"
  },
  {
    "value": "9604",
    "label": "www.fhfcu.org"
  },
  {
    "value": "68356",
    "label": "http://www.ficu.com"
  },
  {
    "value": "10756",
    "label": "http://www.firstlincoln.org"
  },
  {
    "value": "62716",
    "label": "http://www.1stmocu.org"
  },
  {
    "value": "65398",
    "label": "http://www.firstnebraska.org"
  },
  {
    "value": "16435",
    "label": "http://fnfcu.org"
  },
  {
    "value": "2440",
    "label": "http://www.firstnewyork.org"
  },
  {
    "value": "66359",
    "label": "http://www.fncu.org"
  },
  {
    "value": "2263",
    "label": "http://www.firstnrvcreditunion.com"
  },
  {
    "value": "2454",
    "label": "http://www.firstohiocfcu.org"
  },
  {
    "value": "9090",
    "label": "http://www.firstokfcu.org"
  },
  {
    "value": "64089",
    "label": "http://www.firstpacecu.com"
  },
  {
    "value": "13345",
    "label": "http://www.firstpeoples.com"
  },
  {
    "value": "24783",
    "label": "http://www.firstpioneers.com"
  },
  {
    "value": "10319",
    "label": "http://firstpointfcu.com"
  },
  {
    "value": "24649",
    "label": "http://www.firstpriorityfcu.org"
  },
  {
    "value": "66593",
    "label": "http://WWW.FIRSTPRIORITYCU.COM"
  },
  {
    "value": "67542",
    "label": "http://www.firstpriorityabilene.org"
  },
  {
    "value": "11170",
    "label": "http://firstcu.com"
  },
  {
    "value": "68605",
    "label": "https://www.fscu.com"
  },
  {
    "value": "2658",
    "label": "http://www.fsource.org"
  },
  {
    "value": "68084",
    "label": "http://www.firstsouth.com"
  },
  {
    "value": "20417",
    "label": "https://www.1stfcu.com/"
  },
  {
    "value": "19976",
    "label": "www.firsttechfed.com"
  },
  {
    "value": "68596",
    "label": "http://www.firsttrustcu.com"
  },
  {
    "value": "68474",
    "label": "http://www.firstus.org"
  },
  {
    "value": "13290",
    "label": "www.myfwcu.org"
  },
  {
    "value": "839",
    "label": "http://www.fechoice.com"
  },
  {
    "value": "61620",
    "label": "http://www.fefcu.com"
  },
  {
    "value": "10174",
    "label": "http://www.firstlightfcu.org"
  },
  {
    "value": "62509",
    "label": "http://www.firstmarkcu.org"
  },
  {
    "value": "7844",
    "label": "http://www.fishercreditunion.org"
  },
  {
    "value": "10572",
    "label": "http://www.fitzsimonscu.com"
  },
  {
    "value": "67993",
    "label": "https://www.fivecounty.com"
  },
  {
    "value": "68302",
    "label": "http://www.fivestarcu.com"
  },
  {
    "value": "19668",
    "label": "http://www.fivestarfcu.org"
  },
  {
    "value": "68643",
    "label": "http://www.5pointcu.org"
  },
  {
    "value": "13391",
    "label": "www.flagship.org"
  },
  {
    "value": "64240",
    "label": "http://flashercreditunion.org"
  },
  {
    "value": "7376",
    "label": "http://www.fdlfcu.com"
  },
  {
    "value": "13167",
    "label": "http://ffcuonline.com"
  },
  {
    "value": "60497",
    "label": "http://www.flcu.org"
  },
  {
    "value": "187",
    "label": "http://www.famufcu.com"
  },
  {
    "value": "67668",
    "label": "http://floridacentralcu.com"
  },
  {
    "value": "378",
    "label": "http://www.flcustomsfcu.org"
  },
  {
    "value": "67561",
    "label": "http://www.frecu.org"
  },
  {
    "value": "67874",
    "label": "http://www.fsucu.org"
  },
  {
    "value": "67337",
    "label": "www.FWCCU.COM"
  },
  {
    "value": "66451",
    "label": "http://www.focus-cu.com"
  },
  {
    "value": "10220",
    "label": "http://www.focusfirstfcu.org"
  },
  {
    "value": "67176",
    "label": "http://www.fdlcu.com"
  },
  {
    "value": "12898",
    "label": "http://www.fontanafcu.org"
  },
  {
    "value": "12425",
    "label": "http://www.foothillcu.org"
  },
  {
    "value": "15015",
    "label": "http://foothillsfcu.org"
  },
  {
    "value": "67181",
    "label": "http://www.foothills-cu.com"
  },
  {
    "value": "67760",
    "label": "http://www.forbesfieldcu.com"
  },
  {
    "value": "24047",
    "label": "http://www.forestarea.com"
  },
  {
    "value": "9622",
    "label": "http://www.forgecu.org"
  },
  {
    "value": "64103",
    "label": "https://www.forritcu.org"
  },
  {
    "value": "1838",
    "label": "http://fortbayardfcu.org"
  },
  {
    "value": "1167",
    "label": "http://www.fortbillings.org"
  },
  {
    "value": "13690",
    "label": "http://www.myfortlibertyfcu.org"
  },
  {
    "value": "67184",
    "label": "http://www.fortcommunity.com"
  },
  {
    "value": "15513",
    "label": "http://www.ftdixfcu.com"
  },
  {
    "value": "63159",
    "label": "http://www.fdfcu.com"
  },
  {
    "value": "5431",
    "label": "http://FortFinancial.org"
  },
  {
    "value": "12505",
    "label": "http://www.fortligonierfcu.com"
  },
  {
    "value": "61800",
    "label": "http://FORTMCCLELLANCU.ORG"
  },
  {
    "value": "8037",
    "label": "http://www.fortpeckcu.com"
  },
  {
    "value": "24430",
    "label": "http://WWW.FORTROOTSFCU.COM"
  },
  {
    "value": "10832",
    "label": "http://www.fsfcu.com"
  },
  {
    "value": "6134",
    "label": "http://www.dixiecupfcu.com"
  },
  {
    "value": "67532",
    "label": "http://www.fwccu.org"
  },
  {
    "value": "68471",
    "label": "http://www.ftwccu.org"
  },
  {
    "value": "9759",
    "label": "http://forteracu.com"
  },
  {
    "value": "7392",
    "label": "http://fortressfcu.org"
  },
  {
    "value": "68248",
    "label": "http://www.forumcu.com"
  },
  {
    "value": "66798",
    "label": "http://www.forwardfinancialcu.com"
  },
  {
    "value": "61157",
    "label": "http://www.foundationcreditunion.co"
  },
  {
    "value": "24063",
    "label": "https://www.foundersfcu.com"
  },
  {
    "value": "16754",
    "label": "http://fourcornersfcu.org"
  },
  {
    "value": "24753",
    "label": "http://www.fourpointsfcu.org"
  },
  {
    "value": "16479",
    "label": "http://www.fourseasonsfcu.com"
  },
  {
    "value": "16954",
    "label": "https://www.foxcredit.org"
  },
  {
    "value": "66731",
    "label": "www.foxcu.org"
  },
  {
    "value": "63286",
    "label": "http://www.foxvalleycu.com"
  },
  {
    "value": "61755",
    "label": "http://www.frankenmuthcu.org"
  },
  {
    "value": "13355",
    "label": "http://frankfortcu.org"
  },
  {
    "value": "12604",
    "label": "http://www.franklinfirst.org"
  },
  {
    "value": "6723",
    "label": "http://www.franklinjohnstown.com"
  },
  {
    "value": "20042",
    "label": "http://www.fmfcu.org"
  },
  {
    "value": "18582",
    "label": "http://www.frsfcu.com"
  },
  {
    "value": "12",
    "label": "http://www.franklintrust.org"
  },
  {
    "value": "65356",
    "label": "https://www.for-cu.com/"
  },
  {
    "value": "15159",
    "label": "http://www.f-sfcu.com"
  },
  {
    "value": "24901",
    "label": "http://www.fopfcu.org"
  },
  {
    "value": "8069",
    "label": "http://www.ffcuvi.org"
  },
  {
    "value": "60275",
    "label": "http://www.freedomcu.org"
  },
  {
    "value": "66824",
    "label": "http://www.freedom.coop"
  },
  {
    "value": "67129",
    "label": "https://www.freedomcu.com/"
  },
  {
    "value": "11643",
    "label": "http://www.freedom1stfcu.org"
  },
  {
    "value": "62722",
    "label": "http://www.freedomccu.com"
  },
  {
    "value": "11111",
    "label": "http://www.freedomfirst.com"
  },
  {
    "value": "65722",
    "label": "http://fnwcu.org"
  },
  {
    "value": "8550",
    "label": "http://www.freedomfcu.org"
  },
  {
    "value": "3487",
    "label": "http://FREEDOMUNITEDFCU.COM"
  },
  {
    "value": "60406",
    "label": "http://www.freestarfinancial.com"
  },
  {
    "value": "68005",
    "label": "http://www.freestonecu.org"
  },
  {
    "value": "5370",
    "label": "https://www.fremontfcu.com"
  },
  {
    "value": "97096",
    "label": "http://www.fpdcu.org"
  },
  {
    "value": "14775",
    "label": "http://www.frickfin.org"
  },
  {
    "value": "10184",
    "label": "http://www.friendlyfcu.org"
  },
  {
    "value": "96750",
    "label": "http://www.friendsandfamilycu.com"
  },
  {
    "value": "9843",
    "label": "https://www.ea.financial-net.com/vb"
  },
  {
    "value": "6084",
    "label": "http://www.frontroyalfcu.org"
  },
  {
    "value": "63201",
    "label": "http://www.FrontierCCU.org"
  },
  {
    "value": "68494",
    "label": "http://www.frontwavecu.com"
  },
  {
    "value": "7067",
    "label": "http://www.ftrandallfcu.com"
  },
  {
    "value": "66253",
    "label": "http://fscunet.org"
  },
  {
    "value": "67363",
    "label": "http://gpa-cu.com"
  },
  {
    "value": "2405",
    "label": "http://www.gapfcu.org"
  },
  {
    "value": "22931",
    "label": "http://www.gpofcu.com"
  },
  {
    "value": "61630",
    "label": "http://www.gabrielscu.com"
  },
  {
    "value": "2348",
    "label": "http://www.gafcreditunion.org"
  },
  {
    "value": "3943",
    "label": "http://www.gainfcu.com"
  },
  {
    "value": "4116",
    "label": "http://www.galaxyfcu.com"
  },
  {
    "value": "60327",
    "label": "http://www.galecu.net"
  },
  {
    "value": "65712",
    "label": "http://www.gbcu.net"
  },
  {
    "value": "63868",
    "label": "http://www.steamplantcu.org"
  },
  {
    "value": "23292",
    "label": "http://www.gallupfcu.com"
  },
  {
    "value": "67427",
    "label": "www.ggecu.com"
  },
  {
    "value": "7092",
    "label": "https://gsefcu-dn.financial-net.com"
  },
  {
    "value": "7662",
    "label": "http://gctcu.org"
  },
  {
    "value": "24796",
    "label": "http://www.gardenislandfcu.com"
  },
  {
    "value": "18710",
    "label": "http://Gardensavingsfcu.org"
  },
  {
    "value": "14015",
    "label": "http://GARDENSTATEFCU.ORG"
  },
  {
    "value": "8367",
    "label": "gardinerfcu.org"
  },
  {
    "value": "18814",
    "label": "http://www.gcefcuhotsprings.org"
  },
  {
    "value": "65003",
    "label": "http://www.gaselectriccu.com"
  },
  {
    "value": "16236",
    "label": "https://gateschilifcu.org"
  },
  {
    "value": "63476",
    "label": "http://www.yourgcu.org"
  },
  {
    "value": "68171",
    "label": "http://www.gatewaycreditunion.com"
  },
  {
    "value": "24797",
    "label": "https://www.gogmfcu.org"
  },
  {
    "value": "9381",
    "label": "http://www.gatherfcu.org"
  },
  {
    "value": "68623",
    "label": "http://revitycu.com"
  },
  {
    "value": "61220",
    "label": "http://www.geaugacreditunion.com"
  },
  {
    "value": "24941",
    "label": "http://www.GECU.com"
  },
  {
    "value": "23620",
    "label": "http://www.gcfcu.net"
  },
  {
    "value": "8585",
    "label": "http://gencofcu.org"
  },
  {
    "value": "68574",
    "label": "http://www.gecreditunion.org"
  },
  {
    "value": "4043",
    "label": "https://www.myGECreditUnion.com"
  },
  {
    "value": "23156",
    "label": "http://generationslaportefederalcre"
  },
  {
    "value": "60429",
    "label": "http://www.generationscu.org"
  },
  {
    "value": "4015",
    "label": "http://www.mygenfcu.org"
  },
  {
    "value": "23848",
    "label": "http://WWW.GENESEE.COOP"
  },
  {
    "value": "21658",
    "label": "http://www.geneseevalleyfcu.com"
  },
  {
    "value": "68451",
    "label": "http://genesisecu.com"
  },
  {
    "value": "97106",
    "label": "http://www.genfed.com"
  },
  {
    "value": "68536",
    "label": "http://www.genisyscu.org"
  },
  {
    "value": "67382",
    "label": "http://www.gpcu.net"
  },
  {
    "value": "60791",
    "label": "http://www.gkcu.org"
  },
  {
    "value": "23979",
    "label": "http://www.guasfcu.com"
  },
  {
    "value": "68291",
    "label": "http://GGCU.ORG"
  },
  {
    "value": "3800",
    "label": "https://www.gaheritagefcu.org"
  },
  {
    "value": "12291",
    "label": "http://www.gpvfcu.com"
  },
  {
    "value": "68607",
    "label": "http://www.gucu.org"
  },
  {
    "value": "67390",
    "label": "http://www.georgiasown.org"
  },
  {
    "value": "16168",
    "label": "http://www.geovistacu.com"
  },
  {
    "value": "6832",
    "label": "http://Gerberfcu.Com"
  },
  {
    "value": "66857",
    "label": "http://www.germaniacreditunion.com"
  },
  {
    "value": "68278",
    "label": "http://www.gesa.com"
  },
  {
    "value": "13974",
    "label": "http://www.gesbfcu.org"
  },
  {
    "value": "24279",
    "label": "http://GFAFCU.COM"
  },
  {
    "value": "21774",
    "label": "https://www.ghafcu.org"
  },
  {
    "value": "3841",
    "label": "http://www.ghsfcu.com"
  },
  {
    "value": "14917",
    "label": "http://grcreditunion.com"
  },
  {
    "value": "18312",
    "label": "http://www.gibbsafcu.com"
  },
  {
    "value": "67238",
    "label": "http://www.glacierhills.com"
  },
  {
    "value": "13733",
    "label": "http://www.glasscapfcu.com"
  },
  {
    "value": "5495",
    "label": "https://www.glasscityfcu.com"
  },
  {
    "value": "63943",
    "label": "http://www.glatcocu.com"
  },
  {
    "value": "7557",
    "label": "http://www.glendalefcu.org"
  },
  {
    "value": "97101",
    "label": "https://gascu.org"
  },
  {
    "value": "7716",
    "label": "http://WWW.BNFCU.ORG"
  },
  {
    "value": "5913",
    "label": "https://www.alaskausa.org"
  },
  {
    "value": "67354",
    "label": "https://www.glyncofecu.org"
  },
  {
    "value": "1542",
    "label": "www.gncfcu.com"
  },
  {
    "value": "68698",
    "label": "http://goenergyfin.com"
  },
  {
    "value": "63606",
    "label": "http://www.goetzcu.com"
  },
  {
    "value": "7644",
    "label": "http://www.gogebicfcu.com"
  },
  {
    "value": "64884",
    "label": "www.goldcu.org"
  },
  {
    "value": "967",
    "label": "http://GCFCU.ORG"
  },
  {
    "value": "61239",
    "label": "http://www.goldencirclecu.com"
  },
  {
    "value": "62542",
    "label": "http://www.gpcu.org"
  },
  {
    "value": "67096",
    "label": "http://www.goldenrulecu.org"
  },
  {
    "value": "8298",
    "label": "http://www.gtfcu.coop"
  },
  {
    "value": "11652",
    "label": "http://www.goldenvalleyfcu.org"
  },
  {
    "value": "24693",
    "label": "http://www.gwcu.org"
  },
  {
    "value": "11400",
    "label": "http://www.goldmarkfcu.com"
  },
  {
    "value": "11663",
    "label": "http://www.goodneighborscu.com"
  },
  {
    "value": "21710",
    "label": "http://www.gysafcu.com"
  },
  {
    "value": "96719",
    "label": "https://grohiocu.com"
  },
  {
    "value": "24465",
    "label": "http://www.gefcuaustin.org"
  },
  {
    "value": "538",
    "label": "http://www.gpofcu.org"
  },
  {
    "value": "66529",
    "label": "www.gecuwi.com"
  },
  {
    "value": "818",
    "label": "https://gafcu.org"
  },
  {
    "value": "365",
    "label": "www.gpfederal.org"
  },
  {
    "value": "19320",
    "label": "http://www.gplafcu.com"
  },
  {
    "value": "68332",
    "label": "http://www.grccu.com"
  },
  {
    "value": "15304",
    "label": "http://www.gracofcu.org"
  },
  {
    "value": "10904",
    "label": "http://www.granco.org"
  },
  {
    "value": "10666",
    "label": "http://ghfcu.com"
  },
  {
    "value": "11718",
    "label": "http://www.grandjunctionfcu.org"
  },
  {
    "value": "60307",
    "label": "http://WWW.GPCREDITUNION.ORG"
  },
  {
    "value": "5170",
    "label": "http://www.grandtrunkcu.com"
  },
  {
    "value": "24726",
    "label": "http://www.granite.org"
  },
  {
    "value": "63272",
    "label": "www.gscu.org"
  },
  {
    "value": "11030",
    "label": "http://www.grasslandscu.com"
  },
  {
    "value": "61986",
    "label": "http://www.gratiotcu.org"
  },
  {
    "value": "7063",
    "label": "http://www.greatbasin.org"
  },
  {
    "value": "7925",
    "label": "www.greateriefcu.com"
  },
  {
    "value": "10221",
    "label": "http://www.greatfallsfcu.com"
  },
  {
    "value": "22695",
    "label": "http://greathorizonsfcu.org"
  },
  {
    "value": "24874",
    "label": "http://greatlakesfcu.com"
  },
  {
    "value": "60238",
    "label": "http://www.glcu.org"
  },
  {
    "value": "6102",
    "label": "http://www.glffcu.com"
  },
  {
    "value": "11402",
    "label": "http://www.greatmeadowfcu.org"
  },
  {
    "value": "24606",
    "label": "http://www.greatnwfcu.com"
  },
  {
    "value": "6853",
    "label": "http://www.greatplainsfcu.com"
  },
  {
    "value": "24632",
    "label": "http://www.greatriverfcu.org"
  },
  {
    "value": "2182",
    "label": "http://www.greateralliance.org"
  },
  {
    "value": "19775",
    "label": "https://greatercentennialfcu.com"
  },
  {
    "value": "11995",
    "label": "http://www.gctfcu.net"
  },
  {
    "value": "3759",
    "label": "http://www.greatercfcu.com"
  },
  {
    "value": "96722",
    "label": "http://www.gccu.org"
  },
  {
    "value": "66860",
    "label": "http://www.greaterclevcu.com"
  },
  {
    "value": "67701",
    "label": "http://www.greatereastern.org"
  },
  {
    "value": "64203",
    "label": "https://www.gicu.org"
  },
  {
    "value": "62228",
    "label": "http://www.gtkycu.com"
  },
  {
    "value": "64034",
    "label": "http://www.greaterkcu.org"
  },
  {
    "value": "20382",
    "label": "http://www.greatermetrofcu.org"
  },
  {
    "value": "68228",
    "label": "http://www.gncu.org"
  },
  {
    "value": "404",
    "label": "http://www.gnofcu.com"
  },
  {
    "value": "9537",
    "label": "https://www.greaterniagarafcu.com"
  },
  {
    "value": "10671",
    "label": "http://www.greaterpittsburghfcu.org"
  },
  {
    "value": "240",
    "label": "http://www.pittsburghpolicefcu.com"
  },
  {
    "value": "67902",
    "label": "http://www.grscu.org"
  },
  {
    "value": "7590",
    "label": "http://www.gtfcu.org"
  },
  {
    "value": "64660",
    "label": "https://greatervalleycu.org"
  },
  {
    "value": "10865",
    "label": "http://www.gwhfcu.com"
  },
  {
    "value": "4369",
    "label": "http://gwcfcu.com"
  },
  {
    "value": "18800",
    "label": "http://GRWFCU.COM"
  },
  {
    "value": "24274",
    "label": "http://www.greecefcu.com"
  },
  {
    "value": "24613",
    "label": "http://www.greencountryfcu.com"
  },
  {
    "value": "63859",
    "label": "http://www.greenmountaincu.com"
  },
  {
    "value": "12852",
    "label": "https://www.greenriverafcu.com"
  },
  {
    "value": "2490",
    "label": "http://greenbeltfcu.com"
  },
  {
    "value": "63445",
    "label": "https://www.gsocu.org"
  },
  {
    "value": "24840",
    "label": "http://www.greensboromcu.org"
  },
  {
    "value": "60269",
    "label": "http://greenstate.org"
  },
  {
    "value": "19116",
    "label": "http://greenvillefcu.com"
  },
  {
    "value": "4400",
    "label": "http://www.greenvilleheritage.com"
  },
  {
    "value": "19203",
    "label": "http://www.gmefcu.com"
  },
  {
    "value": "66594",
    "label": "http://www.greenwoodcu.org"
  },
  {
    "value": "21628",
    "label": "http://www.gm-fcu.org"
  },
  {
    "value": "24560",
    "label": "https://www.greylock.org"
  },
  {
    "value": "14505",
    "label": "http://www.springvillefcu.org"
  },
  {
    "value": "6722",
    "label": "http://www.gcafcu.org"
  },
  {
    "value": "9976",
    "label": "http://www.growfinancial.org"
  },
  {
    "value": "24919",
    "label": "http://growingoaksfcu.com"
  },
  {
    "value": "2299",
    "label": "http://www.gsafcu.gsa.gov"
  },
  {
    "value": "196",
    "label": "http://www.gtefinancial.org"
  },
  {
    "value": "66149",
    "label": "http://www.guadalupecu.org"
  },
  {
    "value": "65471",
    "label": "http://www.guadalupeparishcu.org"
  },
  {
    "value": "66638",
    "label": "http://www.guardiancu.org"
  },
  {
    "value": "68374",
    "label": "https://www.myguardiancu.com"
  },
  {
    "value": "67521",
    "label": "http://www.Guardianscu.coop"
  },
  {
    "value": "62985",
    "label": "http://gucocu.org"
  },
  {
    "value": "60205",
    "label": "http://www.gecu.org"
  },
  {
    "value": "4060",
    "label": "http://www.ccgcfcu.com"
  },
  {
    "value": "7520",
    "label": "http://www.gulfcoastfcu.org"
  },
  {
    "value": "242",
    "label": "http://www.gulfcoastcommunityfcu.or"
  },
  {
    "value": "5648",
    "label": "http://www.gcefcu.org"
  },
  {
    "value": "2442",
    "label": "http://www.gulfshorefcu.org"
  },
  {
    "value": "24908",
    "label": "http://www.gulftrustcu.org"
  },
  {
    "value": "68694",
    "label": "https://www.gogulfwinds.com"
  },
  {
    "value": "66477",
    "label": "http://www.gundersencu.org"
  },
  {
    "value": "23394",
    "label": "http://www.guthriefcu.org"
  },
  {
    "value": "14670",
    "label": "http://www.heafcu.org"
  },
  {
    "value": "15650",
    "label": "https://www.hebfcu.org"
  },
  {
    "value": "14303",
    "label": "https://www.hmsaefcu.org"
  },
  {
    "value": "17569",
    "label": "https://hetelfcu.org"
  },
  {
    "value": "62219",
    "label": "http://www.hpccu.com"
  },
  {
    "value": "2280",
    "label": "https://www.hamakuafcu.org"
  },
  {
    "value": "9411",
    "label": "http://www.hamiltonhorizons.org"
  },
  {
    "value": "66913",
    "label": "http://www.hrecu.org"
  },
  {
    "value": "1250",
    "label": "http://www.hvafcu.org"
  },
  {
    "value": "2815",
    "label": "http://www.hancockfcu.com"
  },
  {
    "value": "21115",
    "label": "http://www.hsefcu.com"
  },
  {
    "value": "24549",
    "label": "http://www.haninfcu.org"
  },
  {
    "value": "9095",
    "label": "http://www.hfcu.org"
  },
  {
    "value": "68239",
    "label": "http://www.hapo.org"
  },
  {
    "value": "68097",
    "label": "http://www.hvcu-tn.com"
  },
  {
    "value": "60207",
    "label": "http://www.hpcu.us"
  },
  {
    "value": "62066",
    "label": "http://www.harborlightcu.org"
  },
  {
    "value": "66399",
    "label": "http://www.Harborstone.com"
  },
  {
    "value": "68639",
    "label": "http://www.harcocu.org"
  },
  {
    "value": "3837",
    "label": "http://www.harmonyfcu.org"
  },
  {
    "value": "7552",
    "label": "http://www.hcfcu.com"
  },
  {
    "value": "18254",
    "label": "http://harrisoncountyfcu.net"
  },
  {
    "value": "6638",
    "label": "https://WWW.HPFFCU.ORG"
  },
  {
    "value": "2806",
    "label": "http://Harrisonteachersfcu.com"
  },
  {
    "value": "14388",
    "label": "http://www.hartfordfcu.com"
  },
  {
    "value": "6498",
    "label": "http://www.myhffcu.org"
  },
  {
    "value": "60201",
    "label": "http://WWW.HARVARDCCU.ORG"
  },
  {
    "value": "67696",
    "label": "http://www.huecu.org"
  },
  {
    "value": "20302",
    "label": "http://www.harvestfcu.org"
  },
  {
    "value": "68584",
    "label": "http://www.harvesterfcu.org"
  },
  {
    "value": "68726",
    "label": "http://www.myharvesterscu.com"
  },
  {
    "value": "5291",
    "label": "http://www.hastingsfcu.org"
  },
  {
    "value": "67710",
    "label": "http://www.hfdcu.com"
  },
  {
    "value": "2403",
    "label": "http://www.hawaiifcu.org"
  },
  {
    "value": "24839",
    "label": "http://www.hawaiicentral.org"
  },
  {
    "value": "1719",
    "label": "http://www.hicommfcu.com"
  },
  {
    "value": "1784",
    "label": "http://www.hawaiicountyfcu.com"
  },
  {
    "value": "10938",
    "label": "http://www.hawaiifirstfcu.com"
  },
  {
    "value": "1870",
    "label": "http://www.hlefcu.com"
  },
  {
    "value": "1785",
    "label": "http://www.hawaiischoolsfcu.org"
  },
  {
    "value": "1718",
    "label": "https://www.hawaiistatefcu.com"
  },
  {
    "value": "1869",
    "label": "http://www.hecoefcu.org"
  },
  {
    "value": "1717",
    "label": "www.hificu.com"
  },
  {
    "value": "1815",
    "label": "www.hawaiiusafcu.com"
  },
  {
    "value": "2794",
    "label": "http://haxtuncu.com"
  },
  {
    "value": "5773",
    "label": "http://www.haynesfcu.org"
  },
  {
    "value": "64833",
    "label": "https://www.financial-net.com/hazle"
  },
  {
    "value": "9888",
    "label": "hbtelcofcu.com"
  },
  {
    "value": "62664",
    "label": "http://www.healthcu.com"
  },
  {
    "value": "20007",
    "label": "http://healthadvantagecu.com"
  },
  {
    "value": "17679",
    "label": "http://www.myhacu.org"
  },
  {
    "value": "66683",
    "label": "http://healthcarecu.org"
  },
  {
    "value": "63249",
    "label": "http://www.healthcarefamilycreditun"
  },
  {
    "value": "66237",
    "label": "http://hcicu.com"
  },
  {
    "value": "22213",
    "label": "http://www.hcpfcu.org"
  },
  {
    "value": "65421",
    "label": "https://www.hacu.org"
  },
  {
    "value": "24150",
    "label": "http://www.hefcu.com"
  },
  {
    "value": "6626",
    "label": "http://www.hcffcu.org"
  },
  {
    "value": "65013",
    "label": "http://www.healthcarefirstcu.com"
  },
  {
    "value": "23371",
    "label": "www.hcpfcu.coop"
  },
  {
    "value": "68173",
    "label": "http://www.hscu.net"
  },
  {
    "value": "24564",
    "label": "http://Healthcarefcu.org"
  },
  {
    "value": "9994",
    "label": "http://www.healthnetfcu.org"
  },
  {
    "value": "5930",
    "label": "http://www.healthplusfcu.com"
  },
  {
    "value": "66317",
    "label": "http://www.healthsharecu.org"
  },
  {
    "value": "11032",
    "label": "http://www.hotfcu.org"
  },
  {
    "value": "10164",
    "label": "http://www.heartcu.org"
  },
  {
    "value": "62031",
    "label": "http://www.hcu.coop"
  },
  {
    "value": "64168",
    "label": "http://www.heartlandcu.com"
  },
  {
    "value": "64441",
    "label": "http://www.hcu.org"
  },
  {
    "value": "66710",
    "label": "https://www.heartlandcu.org/"
  },
  {
    "value": "9194",
    "label": "http://www.heartlandfcu.org"
  },
  {
    "value": "65640",
    "label": "http://www.hawcu.com"
  },
  {
    "value": "2705",
    "label": "http://www.helcofcu.org"
  },
  {
    "value": "19514",
    "label": "http://hemafcu.org"
  },
  {
    "value": "7210",
    "label": "http://hemingfordcreditunion.com"
  },
  {
    "value": "13180",
    "label": "hempfieldareafcu@gmail.com"
  },
  {
    "value": "18175",
    "label": "http://www.henricofcu.org"
  },
  {
    "value": "24902",
    "label": "http://www.herculescu.com"
  },
  {
    "value": "1401",
    "label": "http://www.htfcu.com"
  },
  {
    "value": "16626",
    "label": "http://www.heritagefederal.org"
  },
  {
    "value": "68395",
    "label": "http://www.heritageccu.com"
  },
  {
    "value": "24742",
    "label": "http://www.hfcuvt.com"
  },
  {
    "value": "68711",
    "label": "https://heritagefcu.com"
  },
  {
    "value": "16113",
    "label": "http://www.ourgrovecu.com"
  },
  {
    "value": "68586",
    "label": "http://www.myhscu.com"
  },
  {
    "value": "67748",
    "label": "http://www.heritagesouth.org"
  },
  {
    "value": "6434",
    "label": "http://www.heritage-usa.net"
  },
  {
    "value": "303",
    "label": "http://heritagevalleyfcu.org"
  },
  {
    "value": "6095",
    "label": "http://www.hersheyfcu.org"
  },
  {
    "value": "1829",
    "label": "https://SECURE.HFSFCU.ORG"
  },
  {
    "value": "7534",
    "label": "http://www.hialeahcu.org"
  },
  {
    "value": "60164",
    "label": "http://www.hccu.net"
  },
  {
    "value": "1733",
    "label": "https://www.hickamfcu.org"
  },
  {
    "value": "64932",
    "label": "http://www.hdriver.org"
  },
  {
    "value": "66185",
    "label": "http://www.highdesertcu.org"
  },
  {
    "value": "14668",
    "label": "http://www.highpeaksfcu.com"
  },
  {
    "value": "4122",
    "label": "http://www.highmarkfcu.com"
  },
  {
    "value": "20691",
    "label": "http://highwayfcu.org"
  },
  {
    "value": "67700",
    "label": "http://www.hacu4u.com"
  },
  {
    "value": "60167",
    "label": "http://www.highwaycrossroadscu.com"
  },
  {
    "value": "67468",
    "label": "http://www.dist2cu.com"
  },
  {
    "value": "4148",
    "label": "http://www.highwaydistrict21fcu.com"
  },
  {
    "value": "67469",
    "label": "https://www.hwydist9.com"
  },
  {
    "value": "61453",
    "label": "http://www.hilandcu.com"
  },
  {
    "value": "20354",
    "label": "http://www.hilldistrictfcu.org"
  },
  {
    "value": "68721",
    "label": "http://www.hiway.org"
  },
  {
    "value": "23052",
    "label": "http://www.hmcfederalcreditunion.or"
  },
  {
    "value": "2155",
    "label": "http://WWW.HSEFCU.COOP"
  },
  {
    "value": "67516",
    "label": "http://www.hcsecu.org"
  },
  {
    "value": "68073",
    "label": "http://www.holleycreditunion.org"
  },
  {
    "value": "66476",
    "label": "http://hfmcu.org"
  },
  {
    "value": "8944",
    "label": "http://www.holyfamilycreditunion.co"
  },
  {
    "value": "68044",
    "label": "https://holyredeemerccu.com"
  },
  {
    "value": "64690",
    "label": "http://www.HolyRosaryCU.org"
  },
  {
    "value": "65088",
    "label": "http://www.hrcu.org"
  },
  {
    "value": "67875",
    "label": "http://www.holyokecu.com"
  },
  {
    "value": "2818",
    "label": "http://hcfcu.org"
  },
  {
    "value": "6674",
    "label": "https://www.homefcu.org"
  },
  {
    "value": "21669",
    "label": "http://www.hometowncu.coop"
  },
  {
    "value": "7722",
    "label": "https://www.homebasecu.org"
  },
  {
    "value": "68057",
    "label": "http://www.homefieldcu.com"
  },
  {
    "value": "61089",
    "label": "www.homelandcu.com"
  },
  {
    "value": "1621",
    "label": "http://www.hometownfcu.org"
  },
  {
    "value": "61648",
    "label": "http://www.hcuonline.com"
  },
  {
    "value": "17623",
    "label": "http://www.hondafcu.org"
  },
  {
    "value": "2374",
    "label": "http://www.honeywellfcu.com"
  },
  {
    "value": "1830",
    "label": "http://www.myhocu.com"
  },
  {
    "value": "1880",
    "label": "http://www.hfdfcu.org"
  },
  {
    "value": "63004",
    "label": "http://WWW.HONORCU.COM"
  },
  {
    "value": "60768",
    "label": "http://www.hoosierhills.com"
  },
  {
    "value": "67648",
    "label": "http://www.hoosierunitedcu.com"
  },
  {
    "value": "220",
    "label": "http://www.hopefcu.com"
  },
  {
    "value": "24829",
    "label": "https://hopecu.org"
  },
  {
    "value": "20824",
    "label": "http://www.hopesouth.org"
  },
  {
    "value": "15307",
    "label": "http://www.hopewellfcu.org"
  },
  {
    "value": "16605",
    "label": "http://www.hopechemfcu.org"
  },
  {
    "value": "10687",
    "label": "https://HORIZONFCU.ORG"
  },
  {
    "value": "63844",
    "label": "http://www.yourhcu.com"
  },
  {
    "value": "67264",
    "label": "http://www.hzcu.org"
  },
  {
    "value": "68138",
    "label": "http://www.myhorizoncu.org"
  },
  {
    "value": "24746",
    "label": "http://www.myHorizonCU.com"
  },
  {
    "value": "68274",
    "label": "http://www.hncu.org"
  },
  {
    "value": "9115",
    "label": "http://www.htifcu.com"
  },
  {
    "value": "14091",
    "label": "http://www.houstonfcu.org"
  },
  {
    "value": "6318",
    "label": "http://hbtfcu.com"
  },
  {
    "value": "67627",
    "label": "http://www.houstonhighwaycu.com"
  },
  {
    "value": "2357",
    "label": "http://www.hpcu.coop"
  },
  {
    "value": "258",
    "label": "http://htfffcu.org"
  },
  {
    "value": "8642",
    "label": "http://www.hctafcu.org"
  },
  {
    "value": "16065",
    "label": "https://www.hcsefcu.org"
  },
  {
    "value": "648",
    "label": "http://www.huefcu.org"
  },
  {
    "value": "22740",
    "label": "http://www.hsmfcu.org"
  },
  {
    "value": "67749",
    "label": "http://www.htmcreditunion.com"
  },
  {
    "value": "4037",
    "label": "http://www.hudfcu.org"
  },
  {
    "value": "68342",
    "label": "http://www.hrccu.org"
  },
  {
    "value": "68705",
    "label": "http://www.hvcu.org"
  },
  {
    "value": "7531",
    "label": "http://www.hughesfcu.org"
  },
  {
    "value": "60967",
    "label": "https://www.huntingtonbeachcu.org"
  },
  {
    "value": "24236",
    "label": "http://www.c-ocreditunion.com"
  },
  {
    "value": "3438",
    "label": "http://www.huntingtonized.com"
  },
  {
    "value": "7599",
    "label": "http://huronareaeducationfcu.com"
  },
  {
    "value": "11786",
    "label": "http://www.hurricanecreekfcu.org"
  },
  {
    "value": "63090",
    "label": "http://www.hgecu.com"
  },
  {
    "value": "67829",
    "label": "http://hutchpccu.com"
  },
  {
    "value": "10323",
    "label": "http://www.ibew26fcu.com"
  },
  {
    "value": "14247",
    "label": "https://ibew56fcu.org"
  },
  {
    "value": "24201",
    "label": "ic-fcu.com"
  },
  {
    "value": "60337",
    "label": "http://www.ihmvcu.org"
  },
  {
    "value": "21140",
    "label": "iu7federalcreditunion.org"
  },
  {
    "value": "21782",
    "label": "http://ibew5.virtualcu.net"
  },
  {
    "value": "65840",
    "label": "http://www.ibew146cu.com"
  },
  {
    "value": "20469",
    "label": "https://www.MyCU66.org"
  },
  {
    "value": "23807",
    "label": "http://www.ila1235fcu.com"
  },
  {
    "value": "24916",
    "label": "http://www.ilwucu.org"
  },
  {
    "value": "68423",
    "label": "http://www.iaacu.org"
  },
  {
    "value": "12356",
    "label": "http://www.ibervillefcu.org"
  },
  {
    "value": "9483",
    "label": "http://www.ibewuwfcu.com"
  },
  {
    "value": "14030",
    "label": "ibew317fcu.com"
  },
  {
    "value": "11949",
    "label": "http://ibew76fcu.org"
  },
  {
    "value": "14402",
    "label": "http://ibewsjcu.org"
  },
  {
    "value": "24250",
    "label": "http://www.iccreditunion.org"
  },
  {
    "value": "63194",
    "label": "http://www.iccu.com"
  },
  {
    "value": "68361",
    "label": "http://www.idahounited.org"
  },
  {
    "value": "14176",
    "label": "http://www.idbglobalfcu.org"
  },
  {
    "value": "62978",
    "label": "http://www.idealcu.com"
  },
  {
    "value": "68729",
    "label": "http://www.allegancu.com"
  },
  {
    "value": "97104",
    "label": "http://www.ihcreditunion.com"
  },
  {
    "value": "17253",
    "label": "http://www.ila1351fcu.com"
  },
  {
    "value": "68425",
    "label": "http://www.illiana.org"
  },
  {
    "value": "61634",
    "label": "www.myICCU.org"
  },
  {
    "value": "65305",
    "label": "http://www.iecumember.org"
  },
  {
    "value": "64920",
    "label": "http://www.itsmycreditunion.org"
  },
  {
    "value": "17335",
    "label": "http://www.ispfcu.org"
  },
  {
    "value": "62121",
    "label": "http://www.ivcu.com"
  },
  {
    "value": "68150",
    "label": "http://www.impactcu.org"
  },
  {
    "value": "66300",
    "label": "http://www.imperialcreditunion.org"
  },
  {
    "value": "62620",
    "label": "http://sites.isdschools.org/itcu"
  },
  {
    "value": "24171",
    "label": "http://www.independentfcu.org"
  },
  {
    "value": "24108",
    "label": "http://www.indianaheartland.org"
  },
  {
    "value": "22704",
    "label": "http://www.ilfcu.org"
  },
  {
    "value": "68259",
    "label": "http://www.imcu.org"
  },
  {
    "value": "13616",
    "label": "http://www.isucreditunion.com"
  },
  {
    "value": "68577",
    "label": "http://www.iucu.org"
  },
  {
    "value": "82770",
    "label": "http://ipocu.org"
  },
  {
    "value": "66434",
    "label": "http://www.indianheadcu.org"
  },
  {
    "value": "24026",
    "label": "http://www.ifcu.com"
  },
  {
    "value": "66337",
    "label": "http://www.industrialcu.org"
  },
  {
    "value": "65242",
    "label": "http://industrialemployeescu.com"
  },
  {
    "value": "233",
    "label": "http://www.infirstfcu.org"
  },
  {
    "value": "63996",
    "label": "http://www.infuzecu.org"
  },
  {
    "value": "14425",
    "label": "http://ingersollrandfcu.com"
  },
  {
    "value": "14016",
    "label": "http://www.inlandfederal.org"
  },
  {
    "value": "5684",
    "label": "http://www.inlandvalleyfcu.com"
  },
  {
    "value": "7717",
    "label": "http://www.innerlakesfcu.org"
  },
  {
    "value": "68732",
    "label": "http://www.innovationsfcu.org"
  },
  {
    "value": "4968",
    "label": "http://www.inovafederal.org"
  },
  {
    "value": "2730",
    "label": "http://www.inroadscu.org"
  },
  {
    "value": "67344",
    "label": "http://insightcreditunion.com"
  },
  {
    "value": "1759",
    "label": "https://www.inspirefcu.org"
  },
  {
    "value": "60842",
    "label": "http://www.instepmembers.com"
  },
  {
    "value": "62249",
    "label": "http://www.intandemcu.com"
  },
  {
    "value": "5748",
    "label": "http://www.integrafirstfcu.com"
  },
  {
    "value": "17218",
    "label": "http://www.integrisfcu.org"
  },
  {
    "value": "475",
    "label": "http://www.integrityfcu.org"
  },
  {
    "value": "67651",
    "label": "http://www.intercorpcu.com"
  },
  {
    "value": "5816",
    "label": "http://www.interfaithfcu.org"
  },
  {
    "value": "2446",
    "label": "http://www.irfcuno.com"
  },
  {
    "value": "3685",
    "label": "http://www.irefcu_ncsc.com"
  },
  {
    "value": "5123",
    "label": "http://www.iuawfcu.com"
  },
  {
    "value": "7027",
    "label": "http://Internationalites.com"
  },
  {
    "value": "82794",
    "label": "http://www.interracu.com"
  },
  {
    "value": "68717",
    "label": "https://interstatecu.org/"
  },
  {
    "value": "68505",
    "label": "http://www.itcu.org"
  },
  {
    "value": "68495",
    "label": "http://www.intrepidcu.org"
  },
  {
    "value": "97112",
    "label": "http://www.investexcu.org"
  },
  {
    "value": "60718",
    "label": "http://WWW.IOWAHEARTLAND.ORG"
  },
  {
    "value": "60438",
    "label": "http://www.iQcu.com"
  },
  {
    "value": "17395",
    "label": "www.ircocu.com"
  },
  {
    "value": "66503",
    "label": "http://www.ironcccu.com"
  },
  {
    "value": "5660",
    "label": "http://www.pcbranch.com"
  },
  {
    "value": "15797",
    "label": "http://iwfcu.com"
  },
  {
    "value": "13649",
    "label": "http://www.ironworkersfcu.org"
  },
  {
    "value": "11524",
    "label": "http://www.irsbflofcu.org"
  },
  {
    "value": "24481",
    "label": "http://icefcu.com"
  },
  {
    "value": "61318",
    "label": "https://www.iccuonline.com"
  },
  {
    "value": "10023",
    "label": "http://www.islandfcu.com"
  },
  {
    "value": "15673",
    "label": "imfcu.com/"
  },
  {
    "value": "110",
    "label": "http://www.italoamericanfcu.com"
  },
  {
    "value": "68663",
    "label": "http://ithinkfi.org"
  },
  {
    "value": "20581",
    "label": "http://www.jacksoncountyteachers.co"
  },
  {
    "value": "67197",
    "label": "www.jdecu.org"
  },
  {
    "value": "8445",
    "label": "http://www.jacksonareafcu.com"
  },
  {
    "value": "10324",
    "label": "http://www.jacksoncountyfcu.com"
  },
  {
    "value": "97063",
    "label": "http://www.myjcccu.com"
  },
  {
    "value": "67334",
    "label": "http://www.jacksoncountytcu.com"
  },
  {
    "value": "66967",
    "label": "http://www.jacksonrivcommcu.org"
  },
  {
    "value": "63589",
    "label": "http://jacomcu.org"
  },
  {
    "value": "68675",
    "label": "http://www.JafariCU.com"
  },
  {
    "value": "3551",
    "label": "http://jacfcu.org"
  },
  {
    "value": "67717",
    "label": "http://www.jaxfirecu.org"
  },
  {
    "value": "67352",
    "label": "http://www.jdcu.com"
  },
  {
    "value": "2918",
    "label": "http://www.jeepcountryfcu.com"
  },
  {
    "value": "19319",
    "label": "http://jcfcu.com"
  },
  {
    "value": "67341",
    "label": "http://jctcuonline.org"
  },
  {
    "value": "63834",
    "label": "http://www.Jeffersoncreditunion.org"
  },
  {
    "value": "24871",
    "label": "https://my.jeffersonfinancial.org/"
  },
  {
    "value": "13271",
    "label": "http://www.jpefcu.org"
  },
  {
    "value": "62637",
    "label": "http://www.jvcu.org"
  },
  {
    "value": "2493",
    "label": "http://www.jerseycentralfcu.com"
  },
  {
    "value": "15483",
    "label": "http://www.jerseyshorefcu.org"
  },
  {
    "value": "7724",
    "label": "http://www.jessopfcu.org"
  },
  {
    "value": "24718",
    "label": "http://www.jetstreamfcu.org"
  },
  {
    "value": "23709",
    "label": "https://www.jmafcu.org"
  },
  {
    "value": "20623",
    "label": "http://www.jhfcu.org"
  },
  {
    "value": "60345",
    "label": "http://www.jtvaecu.org"
  },
  {
    "value": "3120",
    "label": "http://jsefcu.com"
  },
  {
    "value": "16437",
    "label": "http://www.jolietmunicipal.com"
  },
  {
    "value": "68703",
    "label": "http://www.joltcu.com"
  },
  {
    "value": "64691",
    "label": "http://www.joplinmcu.com"
  },
  {
    "value": "24698",
    "label": "http://www.jordan-cu.org"
  },
  {
    "value": "20943",
    "label": "http://www.journeyfcu.org"
  },
  {
    "value": "64361",
    "label": "http://www.journeycu.org"
  },
  {
    "value": "2760",
    "label": "http://www.jovia.org"
  },
  {
    "value": "14762",
    "label": "http://www.wellbyfinancial.com"
  },
  {
    "value": "7045",
    "label": "http://jjfcu.com"
  },
  {
    "value": "151",
    "label": "www.jfcu.org"
  },
  {
    "value": "13802",
    "label": "http://kgcfcu.com"
  },
  {
    "value": "16947",
    "label": "http://www.kitfcu.org"
  },
  {
    "value": "15328",
    "label": "http://www.kvfcu.org"
  },
  {
    "value": "64939",
    "label": "http://www.kcareacu.com"
  },
  {
    "value": "62702",
    "label": "http://www.kumccu.org"
  },
  {
    "value": "2275",
    "label": "http://WWW.KAHUKUFCU.COM"
  },
  {
    "value": "10399",
    "label": "http://www.kahuluifcu.com"
  },
  {
    "value": "11944",
    "label": "http://www.kaipermcu.org"
  },
  {
    "value": "20516",
    "label": "http://www.kaipermnw.org"
  },
  {
    "value": "22220",
    "label": "http://www.kaleidahealthfcu.org"
  },
  {
    "value": "61219",
    "label": "http://www.kalsee.com"
  },
  {
    "value": "11553",
    "label": "https://www.kamehamehafcu.org"
  },
  {
    "value": "95015",
    "label": "http://www.kctcu.org"
  },
  {
    "value": "60198",
    "label": "http://www.kftcu.com"
  },
  {
    "value": "60536",
    "label": "http://WWW.KTBCU.com"
  },
  {
    "value": "60295",
    "label": "http://www.ksbcbscu.org"
  },
  {
    "value": "63388",
    "label": "http://www.kccu.net"
  },
  {
    "value": "5257",
    "label": "http://www.kstatecu.com"
  },
  {
    "value": "67995",
    "label": "http://www.kstcu.org"
  },
  {
    "value": "65988",
    "label": "http://mykvccu.org"
  },
  {
    "value": "9348",
    "label": "http://www.katahdinfcu.org"
  },
  {
    "value": "5487",
    "label": "http://www.kauaicreditunion.org"
  },
  {
    "value": "2577",
    "label": "http://www.kauaiteachersfcu.org"
  },
  {
    "value": "8344",
    "label": "http://WWW.KBRHFCU.ORG"
  },
  {
    "value": "5589",
    "label": "http://www.kcffcu.com"
  },
  {
    "value": "4531",
    "label": "http://www.kcunidosfcu.com"
  },
  {
    "value": "5561",
    "label": "http://www.kfcu.org"
  },
  {
    "value": "68678",
    "label": "www.kelloggccu.org"
  },
  {
    "value": "5286",
    "label": "http://www.kellogg-fcu.org"
  },
  {
    "value": "15467",
    "label": "http://www.kellycommunity.org"
  },
  {
    "value": "61810",
    "label": "http://www.kemba.com"
  },
  {
    "value": "108",
    "label": "http://KEMBACHARLESTONFCU.ORG"
  },
  {
    "value": "24220",
    "label": "http://www.kembadelta.org"
  },
  {
    "value": "61623",
    "label": "http://www.kemba.org"
  },
  {
    "value": "82793",
    "label": "http://www.mykemba.org"
  },
  {
    "value": "62358",
    "label": "http://kembaky.org"
  },
  {
    "value": "107",
    "label": "http://WWW.KEMBAFCU.ORG"
  },
  {
    "value": "858",
    "label": "http://kenteachfcu.com"
  },
  {
    "value": "5811",
    "label": "http://www.kennedyvaefcu.org"
  },
  {
    "value": "8119",
    "label": "http://www.kenowacu.com"
  },
  {
    "value": "1558",
    "label": "http://kerrcountyfcu.com"
  },
  {
    "value": "65299",
    "label": "https://www.kpcu.com"
  },
  {
    "value": "4135",
    "label": "http://www.keysfcu.org"
  },
  {
    "value": "67869",
    "label": "http://keystonecu.com"
  },
  {
    "value": "68542",
    "label": "http://www.khnetworkcu.com"
  },
  {
    "value": "60928",
    "label": "http://www.kimberlyclarkcu.org"
  },
  {
    "value": "4142",
    "label": "http://www.kinecta.org"
  },
  {
    "value": "10866",
    "label": "http://kineticCU.com"
  },
  {
    "value": "10395",
    "label": "http://kingsfcu.com"
  },
  {
    "value": "67160",
    "label": "http://www.kingspeakcu.com"
  },
  {
    "value": "68104",
    "label": "http://www.kpcu.org"
  },
  {
    "value": "11391",
    "label": "http://www.kinzuafcu.com"
  },
  {
    "value": "12199",
    "label": "www.kirtlandcu.org"
  },
  {
    "value": "68576",
    "label": "https://www.kitsapcu.org"
  },
  {
    "value": "67793",
    "label": "http://www.knoxcountyecu.com"
  },
  {
    "value": "10676",
    "label": "http://www.kctfcu.org"
  },
  {
    "value": "9873",
    "label": "http://www.knoxfirefcu.com"
  },
  {
    "value": "11533",
    "label": "http://www.klefcu.com"
  },
  {
    "value": "24464",
    "label": "https://ktfcu.org"
  },
  {
    "value": "68085",
    "label": "http://www.tvacreditunion.com"
  },
  {
    "value": "8435",
    "label": "http://www.koolaufcu.org"
  },
  {
    "value": "66872",
    "label": "http://www.kohlercu.com"
  },
  {
    "value": "68172",
    "label": "http://www.koincu.org"
  },
  {
    "value": "60855",
    "label": "http://www.konecu.com"
  },
  {
    "value": "23503",
    "label": "http://www.kacfcu.org"
  },
  {
    "value": "20891",
    "label": "http://www.kraftcorfcu.org"
  },
  {
    "value": "8530",
    "label": "https://kraftmanfcu.com"
  },
  {
    "value": "20204",
    "label": "http://www.kbfcu.org"
  },
  {
    "value": "9943",
    "label": "https://www.kswfcu.org"
  },
  {
    "value": "22478",
    "label": "http://www.kuefcu.com"
  },
  {
    "value": "9260",
    "label": "http://www.lnfcu.com"
  },
  {
    "value": "13885",
    "label": "http://www.lcefcu.org"
  },
  {
    "value": "3796",
    "label": "https://www.lgwfcu.com"
  },
  {
    "value": "6725",
    "label": "http://lrfdfcu.virtualcu.net"
  },
  {
    "value": "62356",
    "label": "http://www.mylncu.com"
  },
  {
    "value": "60755",
    "label": "http://laewcu.com"
  },
  {
    "value": "20720",
    "label": "lamissionfcu.org/"
  },
  {
    "value": "66329",
    "label": "http://www.leocu.org"
  },
  {
    "value": "16571",
    "label": "http://www.LAHFCU.ORG"
  },
  {
    "value": "14568",
    "label": "http://www.lacapfcu.org"
  },
  {
    "value": "66406",
    "label": "http://www.forlbcu.com"
  },
  {
    "value": "2024",
    "label": "https://www.lafinancial.org"
  },
  {
    "value": "14734",
    "label": "http://WWW.LAJOYACREDITUNION.COOP"
  },
  {
    "value": "10351",
    "label": "http://www.llfcu.org"
  },
  {
    "value": "2051",
    "label": "http://www.laportecfcu.com"
  },
  {
    "value": "20002",
    "label": "http://www.laterrefcu.org"
  },
  {
    "value": "21853",
    "label": "http://www.lmfcu.net"
  },
  {
    "value": "619",
    "label": "http://www.lfcu.org"
  },
  {
    "value": "68632",
    "label": "http://www.lafcu.com"
  },
  {
    "value": "20641",
    "label": "http://www.lakechemfcu.org"
  },
  {
    "value": "24545",
    "label": "https://www.lakecountyedufcu.org"
  },
  {
    "value": "61638",
    "label": "http://lhcu.org"
  },
  {
    "value": "62514",
    "label": "http://www.lmcu.org"
  },
  {
    "value": "17878",
    "label": "http://Lakeshore-FCU.Net"
  },
  {
    "value": "65085",
    "label": "http://www.lscu.virtualcu.net"
  },
  {
    "value": "62047",
    "label": "http://www.laketrust.org"
  },
  {
    "value": "7958",
    "label": "http://www.LNFCU.net"
  },
  {
    "value": "10772",
    "label": "http://www.lakesfcu.com"
  },
  {
    "value": "8709",
    "label": "http://www.lakeshorefcu.com"
  },
  {
    "value": "67713",
    "label": "https://lcu.coop"
  },
  {
    "value": "14584",
    "label": "http://Lakeviewfcu.com"
  },
  {
    "value": "66999",
    "label": "http://www.lakewoodcu.org"
  },
  {
    "value": "24847",
    "label": "http://www.lakotafcu.org"
  },
  {
    "value": "62218",
    "label": "http://www.lamourecu.com"
  },
  {
    "value": "15248",
    "label": "http://www.lampco.com"
  },
  {
    "value": "2953",
    "label": "https://www.lanaifcu.org"
  },
  {
    "value": "60113",
    "label": "http://www.lrrcu.org"
  },
  {
    "value": "16657",
    "label": "http://www.lancofcu.com"
  },
  {
    "value": "63716",
    "label": "https://www.llcu.org"
  },
  {
    "value": "62937",
    "label": "http://www.landingscu.org"
  },
  {
    "value": "60532",
    "label": "https://www.landmarkcreditunion.com"
  },
  {
    "value": "66751",
    "label": "https://www.landmarkcu.com"
  },
  {
    "value": "12590",
    "label": "http://www.laneco.org"
  },
  {
    "value": "17327",
    "label": "http://WWW.LANFAIRFCU.COM"
  },
  {
    "value": "1261",
    "label": "https://www.langleyfcu.org"
  },
  {
    "value": "19552",
    "label": "http://www.LanierFCU.org"
  },
  {
    "value": "11011",
    "label": "http://www.laredofcu.org"
  },
  {
    "value": "12472",
    "label": "http://WWW.laredofirecreditunion.co"
  },
  {
    "value": "10424",
    "label": "http://www.lascolinasfcu.com"
  },
  {
    "value": "17232",
    "label": "http://www.lassencreditunion.com"
  },
  {
    "value": "97102",
    "label": "http://www.latahcu.coop"
  },
  {
    "value": "68430",
    "label": "http://www.latinoccu.org"
  },
  {
    "value": "8059",
    "label": "http://www.latitude32.org"
  },
  {
    "value": "6689",
    "label": "www.latrobefcu.com"
  },
  {
    "value": "16209",
    "label": "http://www.lahcreditunion.com"
  },
  {
    "value": "63891",
    "label": "https://www.latviancu.com"
  },
  {
    "value": "62822",
    "label": "http://www.latvianclevelandcu.org"
  },
  {
    "value": "15779",
    "label": "http://www.latvianheritage.org"
  },
  {
    "value": "61944",
    "label": "http://www.lctcu.org"
  },
  {
    "value": "68716",
    "label": "http://launchcu.com"
  },
  {
    "value": "971",
    "label": "https://WWW.UNITEDPOLICEFCU.COM"
  },
  {
    "value": "13305",
    "label": "http://www.lmhospcu.com"
  },
  {
    "value": "68460",
    "label": "http://www.lbsfcu.org"
  },
  {
    "value": "24648",
    "label": "http://www.lcofcu.com"
  },
  {
    "value": "67537",
    "label": "http://lcracu.org"
  },
  {
    "value": "12170",
    "label": "http://www.leroyfcu.org"
  },
  {
    "value": "67936",
    "label": "http://leadco.org"
  },
  {
    "value": "61185",
    "label": "http://www.leaderscu.com"
  },
  {
    "value": "66867",
    "label": "www.leadingedgecu.org"
  },
  {
    "value": "22625",
    "label": "http://www.lsrfcu.org"
  },
  {
    "value": "19510",
    "label": "https://lebanonfcu.org"
  },
  {
    "value": "15174",
    "label": "http://Leefcu.com"
  },
  {
    "value": "64708",
    "label": "http://www.yourlcu.com"
  },
  {
    "value": "24421",
    "label": "http://www.legacycreditunion.com"
  },
  {
    "value": "21204",
    "label": "http://www.legacyfinancialfcu.org"
  },
  {
    "value": "16746",
    "label": "http://www.lehighcountycu.com"
  },
  {
    "value": "64606",
    "label": "http://www.lvecu.org"
  },
  {
    "value": "60991",
    "label": "http://www.lenco.org"
  },
  {
    "value": "64234",
    "label": "http://www.lennoxecu.com"
  },
  {
    "value": "66699",
    "label": "http://www.leominstercu.com"
  },
  {
    "value": "1461",
    "label": "http://www.leomefcu.org"
  },
  {
    "value": "6584",
    "label": "http://www.lesfcu.com"
  },
  {
    "value": "6785",
    "label": "http://www.lescofcu.com"
  },
  {
    "value": "60",
    "label": "http://www.levo.org"
  },
  {
    "value": "68386",
    "label": "https://www.lewisclarkcu.org"
  },
  {
    "value": "10728",
    "label": "http://www.lewistonporterfcu.com"
  },
  {
    "value": "12833",
    "label": "http://www.lexfcu.org"
  },
  {
    "value": "14750",
    "label": "http://www.lexmafcu.com"
  },
  {
    "value": "62370",
    "label": "http://www.lexpccu.com"
  },
  {
    "value": "68581",
    "label": "http://www.LGEccu.org"
  },
  {
    "value": "1682",
    "label": "https://Libertyfcu.org"
  },
  {
    "value": "67541",
    "label": "http://www.lbcu.org"
  },
  {
    "value": "14052",
    "label": "http://www.libertycu.com"
  },
  {
    "value": "63757",
    "label": "http://www.libertyfirstcu.com"
  },
  {
    "value": "7184",
    "label": "https://www.lsfcu.org/"
  },
  {
    "value": "61604",
    "label": "http://www.libertyonecu.com"
  },
  {
    "value": "266",
    "label": "http://WWW.LCFCU.ORG"
  },
  {
    "value": "6012",
    "label": "http://lifetimefcu.org"
  },
  {
    "value": "68529",
    "label": "http://www.lightcommercecu.org"
  },
  {
    "value": "10083",
    "label": "http://WWW.LIMESTONEFEDERAL.COM"
  },
  {
    "value": "63199",
    "label": "http://www.lincolncountycu.com"
  },
  {
    "value": "17362",
    "label": "http://WWW.LINCOLNMAINEFCU.COM"
  },
  {
    "value": "61701",
    "label": "http://www.lpccu.coop"
  },
  {
    "value": "2643",
    "label": "http://www.lpscu.com"
  },
  {
    "value": "65561",
    "label": "http://www.lincolnsdacu.org"
  },
  {
    "value": "14847",
    "label": "http://sudbury.ma.us"
  },
  {
    "value": "10585",
    "label": "http://www.linconefcu.org"
  },
  {
    "value": "23975",
    "label": "http://www.linkfcu.org"
  },
  {
    "value": "67519",
    "label": "http://www.linkagecu.com"
  },
  {
    "value": "10779",
    "label": "http://www.linncofcu.org"
  },
  {
    "value": "63602",
    "label": "http://www.linnareacu.org"
  },
  {
    "value": "64948",
    "label": "http://www.linxuscu.org"
  },
  {
    "value": "24437",
    "label": "http://www.lioncreditunion.com"
  },
  {
    "value": "24813",
    "label": "http://www.lionsharecu.org"
  },
  {
    "value": "13190",
    "label": "http://www.lisboncu.org"
  },
  {
    "value": "63057",
    "label": "http://www.listerhill.com"
  },
  {
    "value": "11409",
    "label": "http://www.lithiumfcu.org"
  },
  {
    "value": "3090",
    "label": "http://www.littlerockfcu.com"
  },
  {
    "value": "7927",
    "label": "http://livelifefcu.org"
  },
  {
    "value": "21279",
    "label": "http://www.lifefcu.com"
  },
  {
    "value": "19295",
    "label": "http://WWW.LPFCU.ORG"
  },
  {
    "value": "6039",
    "label": "http://www.lmfcu.org"
  },
  {
    "value": "20773",
    "label": "www.local1233fcu.org"
  },
  {
    "value": "16429",
    "label": "http://local20ibewfcu.com"
  },
  {
    "value": "14656",
    "label": "www.local24efcu.com"
  },
  {
    "value": "15522",
    "label": "https://www.itsme247.com/886/authentication/username"
  },
  {
    "value": "22447",
    "label": "http://WWW.LOCAL41FCU.COM"
  },
  {
    "value": "14283",
    "label": "http://local520fcu.org"
  },
  {
    "value": "16979",
    "label": "http://www.local606fcu.org"
  },
  {
    "value": "12745",
    "label": "https://www.697fcu.org"
  },
  {
    "value": "23144",
    "label": "http://www.cu804.org"
  },
  {
    "value": "24003",
    "label": "http://www.lgfcu.org"
  },
  {
    "value": "24373",
    "label": "http://www.392fcu.org"
  },
  {
    "value": "17372",
    "label": "https://www.lscfcu.org"
  },
  {
    "value": "66117",
    "label": "http://www.lococreditunion.com"
  },
  {
    "value": "13516",
    "label": "http://www.locogafcu.com"
  },
  {
    "value": "1111",
    "label": "http://locomotivecontrolefcu.com"
  },
  {
    "value": "24763",
    "label": "http://www.lcrcu.org"
  },
  {
    "value": "14571",
    "label": "http://www.loganmedicalfcu.org"
  },
  {
    "value": "1999",
    "label": "http://www.logixbanking.com"
  },
  {
    "value": "68449",
    "label": "http://www.lonestarcu.org"
  },
  {
    "value": "1438",
    "label": "https://www.lbcefcu.org"
  },
  {
    "value": "67921",
    "label": "https://www.lbfcu.org"
  },
  {
    "value": "3256",
    "label": "http://lbeach.org"
  },
  {
    "value": "2782",
    "label": "http://www.liafcu.org"
  },
  {
    "value": "22241",
    "label": "http://www.lrefcu.org"
  },
  {
    "value": "13857",
    "label": "http://www.longshorefcu.com"
  },
  {
    "value": "10799",
    "label": "http://www.lshore4cu.com"
  },
  {
    "value": "7776",
    "label": "http://www.lookoutcu.com"
  },
  {
    "value": "21109",
    "label": "http://www.lorealusafcu.com"
  },
  {
    "value": "24637",
    "label": "http://lormet.com"
  },
  {
    "value": "61165",
    "label": "http://www.lascu.org"
  },
  {
    "value": "1207",
    "label": "http://www.lafcu.org"
  },
  {
    "value": "24553",
    "label": "https://www.lapfcu.org"
  },
  {
    "value": "66954",
    "label": "http://www.loudouncu.com"
  },
  {
    "value": "174",
    "label": "http://www.louisianafcu.org"
  },
  {
    "value": "21968",
    "label": "http://www.labaptistfcu.com"
  },
  {
    "value": "7331",
    "label": "http://www.louisianacatholicfcu.com"
  },
  {
    "value": "61724",
    "label": "https://louisianacentral.org"
  },
  {
    "value": "3061",
    "label": "https://louisianausa.org"
  },
  {
    "value": "62433",
    "label": "http://www.louisvillefcu.com"
  },
  {
    "value": "61123",
    "label": "http://www.lgecu.org"
  },
  {
    "value": "18765",
    "label": "http://www.louviers.com"
  },
  {
    "value": "67767",
    "label": "http://www.lffcu.com"
  },
  {
    "value": "2065",
    "label": "http://lmefcu.net"
  },
  {
    "value": "9289",
    "label": "http://www.lclfcu.org"
  },
  {
    "value": "24232",
    "label": "http://WWW.LESPEOPLES.ORG"
  },
  {
    "value": "60527",
    "label": "http://lowlandcu.org"
  },
  {
    "value": "23421",
    "label": "http://www.luefcu.org"
  },
  {
    "value": "9398",
    "label": "http://www.ibewfcu.org"
  },
  {
    "value": "60378",
    "label": "http://www.lzecu.org"
  },
  {
    "value": "16351",
    "label": "http://www.lufkinfcu.com"
  },
  {
    "value": "20341",
    "label": "http://www.lhcu.com"
  },
  {
    "value": "20600",
    "label": "http://www.lusofederal.com"
  },
  {
    "value": "67890",
    "label": "http://www.luso-american.com"
  },
  {
    "value": "24861",
    "label": "http://www.lutheranfcu.org"
  },
  {
    "value": "17323",
    "label": "http://www.luzernecountyfcu.virtual"
  },
  {
    "value": "14623",
    "label": "http://lmefcu.org"
  },
  {
    "value": "484",
    "label": "http://www.lynnffcu.com"
  },
  {
    "value": "67882",
    "label": "http://www.lynnteacherscu.org"
  },
  {
    "value": "17865",
    "label": "http://www.mcmenlopark.com"
  },
  {
    "value": "11331",
    "label": "http://www.mdufcu.com"
  },
  {
    "value": "7020",
    "label": "http://m-ofcu.com"
  },
  {
    "value": "13766",
    "label": "http://mct-fcu.org"
  },
  {
    "value": "66862",
    "label": "http://meecu.org"
  },
  {
    "value": "23627",
    "label": "https://moses-ma.org/moses-federal-credit-union/"
  },
  {
    "value": "67979",
    "label": "http://WWW.MPDCU.ORG"
  },
  {
    "value": "8080",
    "label": "http://www.macfcu.org"
  },
  {
    "value": "14708",
    "label": "http://www.mbfcu.com"
  },
  {
    "value": "67688",
    "label": "maconbibbecu.org"
  },
  {
    "value": "66431",
    "label": "http://www.madisoncu.com"
  },
  {
    "value": "306",
    "label": "http://www.madcofcu.org"
  },
  {
    "value": "67630",
    "label": "http://madisoncuonline.org"
  },
  {
    "value": "66499",
    "label": "http://www.mfdcu.org"
  },
  {
    "value": "68604",
    "label": "http://www.mymagnifi.org"
  },
  {
    "value": "224",
    "label": "http://www.magfedcu.org"
  },
  {
    "value": "14469",
    "label": "http://www.mahoningvalleyfcu.com"
  },
  {
    "value": "2644",
    "label": "http://www.mainefamilyfcu.com"
  },
  {
    "value": "17398",
    "label": "http://www.mhfcu.com"
  },
  {
    "value": "640",
    "label": "http://www.mainemedia.com"
  },
  {
    "value": "14565",
    "label": "http://www.mainesavings.com"
  },
  {
    "value": "17436",
    "label": "http://www.mesolutionsfcu.org"
  },
  {
    "value": "63377",
    "label": "http://www.mainestatecu.org"
  },
  {
    "value": "24858",
    "label": "https://www.mainstreetcu.org"
  },
  {
    "value": "4472",
    "label": "http://maldenfcu.com"
  },
  {
    "value": "2800",
    "label": "http://www.mamtafcu.org"
  },
  {
    "value": "15296",
    "label": "http://www.manchester.ma.us"
  },
  {
    "value": "13602",
    "label": "http://www.mmfcu.net"
  },
  {
    "value": "18067",
    "label": "http://manistiquefederal.com"
  },
  {
    "value": "2168",
    "label": "http://www.manville.org"
  },
  {
    "value": "10241",
    "label": "http://www.maplefcu.net"
  },
  {
    "value": "66466",
    "label": "https://WWW.MCECU.ORG"
  },
  {
    "value": "23276",
    "label": "www.marbleheadcu.com"
  },
  {
    "value": "15659",
    "label": "www.marincu.org"
  },
  {
    "value": "12977",
    "label": "http://marinefederalhb.org"
  },
  {
    "value": "66752",
    "label": "http://marinecu.com"
  },
  {
    "value": "64166",
    "label": "http://www.mapscu.com"
  },
  {
    "value": "22428",
    "label": "http://www.schoolcu.virtualcu.net"
  },
  {
    "value": "9469",
    "label": "https://www.marisolcu.org"
  },
  {
    "value": "9003",
    "label": "http://www.marketusafcu.com"
  },
  {
    "value": "62497",
    "label": "http://www.maroonfinancial.org"
  },
  {
    "value": "7873",
    "label": "http://www.marquettecomm.org"
  },
  {
    "value": "62160",
    "label": "http://www.marshallcommunitycu.com"
  },
  {
    "value": "21713",
    "label": "http://www.marshallcountyfcu.com"
  },
  {
    "value": "67066",
    "label": "http://www.mmccu.com"
  },
  {
    "value": "68714",
    "label": "https://www.marshlandcu.com"
  },
  {
    "value": "22337",
    "label": "http://martinsburgfcu.com"
  },
  {
    "value": "12837",
    "label": "http://www.marvelcityfcu.com"
  },
  {
    "value": "11550",
    "label": "http://maryvaleschoolsfcu.com"
  },
  {
    "value": "68077",
    "label": "http://MMCUTN.COM"
  },
  {
    "value": "68137",
    "label": "http://www.massbaycu.org"
  },
  {
    "value": "3830",
    "label": "http://www.mitfcu.org"
  },
  {
    "value": "24924",
    "label": "http://www.massfamilycu.com"
  },
  {
    "value": "24566",
    "label": "http://www.massmutualfcu.org"
  },
  {
    "value": "12440",
    "label": "http://creditunion.bridgedeck.org"
  },
  {
    "value": "68483",
    "label": "http://www.matadors.org"
  },
  {
    "value": "67545",
    "label": "http://matagordaccu.com"
  },
  {
    "value": "5924",
    "label": "http://www.mvfcu.coop"
  },
  {
    "value": "20063",
    "label": "http://www.materionfcu.com"
  },
  {
    "value": "60212",
    "label": "http://www.mbvmcu.com"
  },
  {
    "value": "2843",
    "label": "http://www.matsoncreditunion.com"
  },
  {
    "value": "8261",
    "label": "http://www.mattelfcu.org"
  },
  {
    "value": "2562",
    "label": "https://www.mauifcu.com"
  },
  {
    "value": "1866",
    "label": "http://www.mauicountyfcu.org"
  },
  {
    "value": "1817",
    "label": "http://www.mauiteachersfcu.com"
  },
  {
    "value": "15390",
    "label": "http://www.maumeeeducators.com"
  },
  {
    "value": "97053",
    "label": "http://www.maumeevalleycu.com"
  },
  {
    "value": "24925",
    "label": "www.maunfcu.org"
  },
  {
    "value": "68575",
    "label": "http://www.mymax.com"
  },
  {
    "value": "24748",
    "label": "https://mayocreditunion.org"
  },
  {
    "value": "64084",
    "label": "http://www.mazuma.org"
  },
  {
    "value": "24929",
    "label": "http://www.mcfcu.org"
  },
  {
    "value": "2713",
    "label": "http://www.mcbrydefcu.org"
  },
  {
    "value": "4090",
    "label": "http://mccombfcu.com"
  },
  {
    "value": "9915",
    "label": "http://www.mccoyfcu.org"
  },
  {
    "value": "60149",
    "label": "http://WWW.MCDOWELLCORNERSTONE.COM"
  },
  {
    "value": "8774",
    "label": "www.mahpfcu.org"
  },
  {
    "value": "10461",
    "label": "http://www.mclennancountyefcu.com"
  },
  {
    "value": "10481",
    "label": "http://www.mcmurreyfcu.com"
  },
  {
    "value": "65371",
    "label": "http://mcphersoncu.com"
  },
  {
    "value": "97089",
    "label": "http://www.mctcu.org"
  },
  {
    "value": "66878",
    "label": "http://www.mcucreditunion.com"
  },
  {
    "value": "24759",
    "label": "http://meadowgrovefcu.org"
  },
  {
    "value": "66618",
    "label": "http://www.meadowlandcu.org"
  },
  {
    "value": "62593",
    "label": "http://www.medparkcu.com"
  },
  {
    "value": "68549",
    "label": "http://www.mediacitycu.org"
  },
  {
    "value": "13794",
    "label": "http://www.medfed.com"
  },
  {
    "value": "20885",
    "label": "http://WWW.MEDISYSEFCU.ORG"
  },
  {
    "value": "62393",
    "label": "http://www.meijercreditunion.com"
  },
  {
    "value": "4035",
    "label": "http://www.memberonefcu.com"
  },
  {
    "value": "577",
    "label": "http://www.memberpreferredfcu.com"
  },
  {
    "value": "60002",
    "label": "http://www.memcu.com"
  },
  {
    "value": "67649",
    "label": "http://www.memberscreditunion.com"
  },
  {
    "value": "68487",
    "label": "http://www.memberscu.coop"
  },
  {
    "value": "6694",
    "label": "http://members1st.org"
  },
  {
    "value": "62677",
    "label": "http://www.m1cu.org"
  },
  {
    "value": "63899",
    "label": "http://www.members1cu.com"
  },
  {
    "value": "67770",
    "label": "http://www.me1stcu.com"
  },
  {
    "value": "2574",
    "label": "http://www.membersonenj.org"
  },
  {
    "value": "68242",
    "label": "http://www.macuonline.org"
  },
  {
    "value": "66633",
    "label": "http://www.membersadvantagecu.com"
  },
  {
    "value": "62026",
    "label": "http://www.mccu.net"
  },
  {
    "value": "67508",
    "label": "http://www.mccu.com"
  },
  {
    "value": "96711",
    "label": "http://www.mccugreenville.org"
  },
  {
    "value": "199",
    "label": "http://www.choicefcu.com"
  },
  {
    "value": "4969",
    "label": "http://www.memberschoicewv.com"
  },
  {
    "value": "62683",
    "label": "http://memberscommunitycu.org"
  },
  {
    "value": "60216",
    "label": "http://www.membersccu.org"
  },
  {
    "value": "60598",
    "label": "http://www.mecuanywhere.com"
  },
  {
    "value": "4020",
    "label": "http://www.membersffcu.org"
  },
  {
    "value": "62076",
    "label": "http://www.mfcu.net"
  },
  {
    "value": "67008",
    "label": "http://mbr1cu.org"
  },
  {
    "value": "67153",
    "label": "https://memfirstcu.com"
  },
  {
    "value": "67658",
    "label": "http://www.m1st.org"
  },
  {
    "value": "63368",
    "label": "http://membersfirstccu.com"
  },
  {
    "value": "61160",
    "label": "http://www.membersfirstfl.org"
  },
  {
    "value": "65491",
    "label": "http://www.membersfirstnh.org"
  },
  {
    "value": "24855",
    "label": "http://www.mfirstcu.org"
  },
  {
    "value": "68661",
    "label": "http://www.membersheritage.org"
  },
  {
    "value": "66706",
    "label": "http://memberspluscu.org"
  },
  {
    "value": "82547",
    "label": "http://www.memberspreferred.org"
  },
  {
    "value": "68562",
    "label": "http://www.ms-cu.com"
  },
  {
    "value": "15874",
    "label": "http://www.memberstrust.org"
  },
  {
    "value": "1584",
    "label": "www.mtfcu.coop"
  },
  {
    "value": "62918",
    "label": "http://www.members1st.com"
  },
  {
    "value": "81402",
    "label": "http://www.membersalliance.org"
  },
  {
    "value": "67538",
    "label": "http://www.membersfirstga.com"
  },
  {
    "value": "1049",
    "label": "http://MembersFirstCTFCU.com"
  },
  {
    "value": "68440",
    "label": "http://www.membersourcecu.org"
  },
  {
    "value": "66268",
    "label": "http://www.membersowncu.org"
  },
  {
    "value": "14824",
    "label": "https://memorialfcu.org"
  },
  {
    "value": "62571",
    "label": "https://www.memorialcu.org"
  },
  {
    "value": "63802",
    "label": "http://www.memorialcreditunion.com"
  },
  {
    "value": "68738",
    "label": "http://www.mefcu.org"
  },
  {
    "value": "60239",
    "label": "http://www.memphiscu.org"
  },
  {
    "value": "15433",
    "label": "http://www.mmefcu.org"
  },
  {
    "value": "9119",
    "label": "http://mercedschoolcu.org"
  },
  {
    "value": "65995",
    "label": "https://mercercreditunion.com"
  },
  {
    "value": "11891",
    "label": "http://www.mercomfcu.org"
  },
  {
    "value": "1351",
    "label": "http://www.mcnjtfcu.org"
  },
  {
    "value": "9413",
    "label": "http://www.mctfcu.virtualcu.net"
  },
  {
    "value": "1454",
    "label": "http://WWW.MERCKCU.COM"
  },
  {
    "value": "6574",
    "label": "http://www.msdfcu.org"
  },
  {
    "value": "64766",
    "label": "http://www.merco.org"
  },
  {
    "value": "22362",
    "label": "https://mercyfcu.org"
  },
  {
    "value": "63306",
    "label": "http://www.mymercycu.com"
  },
  {
    "value": "20921",
    "label": "http://www.merhofcu.org"
  },
  {
    "value": "10684",
    "label": "http://www.meridiacu.com"
  },
  {
    "value": "64212",
    "label": "http://www.mymeridiancu.org"
  },
  {
    "value": "15377",
    "label": "http://www.meridianangfcu.org"
  },
  {
    "value": "8433",
    "label": "http://www.meridianmutualfcu.com"
  },
  {
    "value": "9160",
    "label": "http://www.mymeridiantrust.com"
  },
  {
    "value": "24914",
    "label": "http://www.meritrustcu.org"
  },
  {
    "value": "9643",
    "label": "http://www.merituscu.net"
  },
  {
    "value": "68394",
    "label": "http://www.meriwest.com"
  },
  {
    "value": "68693",
    "label": "http://www.mvcu.com"
  },
  {
    "value": "67436",
    "label": "http://www.mesquitecu.org"
  },
  {
    "value": "21440",
    "label": "http://www.mbcfcu.org"
  },
  {
    "value": "24043",
    "label": "www.mbjfcu.org"
  },
  {
    "value": "24324",
    "label": "http://www.mettranfcu.org"
  },
  {
    "value": "60013",
    "label": "http://www.metcocu.org"
  },
  {
    "value": "13456",
    "label": "http://www.methodistcu.org"
  },
  {
    "value": "7731",
    "label": "http://www.mhcu.net"
  },
  {
    "value": "10954",
    "label": "http://methuencreditunion.com"
  },
  {
    "value": "21326",
    "label": "http://www.mcu.org"
  },
  {
    "value": "61840",
    "label": "http://www.metro.coop"
  },
  {
    "value": "66364",
    "label": "http://www.metrocu.org"
  },
  {
    "value": "8791",
    "label": "www.metrocommunityfcu.com"
  },
  {
    "value": "7091",
    "label": "https://www.metrofcu.org"
  },
  {
    "value": "63058",
    "label": "http://www.metroemployeescu.org"
  },
  {
    "value": "67517",
    "label": "http://www.mmcu.com"
  },
  {
    "value": "9441",
    "label": "http://www.metronorthfcu.com"
  },
  {
    "value": "20857",
    "label": "https://www.mrfcu.com"
  },
  {
    "value": "65817",
    "label": "http://www.mdecu.org"
  },
  {
    "value": "2370",
    "label": "http://www.metlfcu.com"
  },
  {
    "value": "64999",
    "label": "http://www.mymscu.org"
  },
  {
    "value": "68135",
    "label": "http://metrotcu.com"
  },
  {
    "value": "4731",
    "label": "http://www.mwcfcu.com"
  },
  {
    "value": "62422",
    "label": "http://www.metrumcu.org"
  },
  {
    "value": "9287",
    "label": "http://www.miamifcu.org"
  },
  {
    "value": "872",
    "label": "www.mffcu.org"
  },
  {
    "value": "67330",
    "label": "http://www.mpscu.org"
  },
  {
    "value": "19678",
    "label": "http://www.muccu.org"
  },
  {
    "value": "61631",
    "label": "http://www.michigancoastal.com"
  },
  {
    "value": "5554",
    "label": "http://www.mcfcu.net"
  },
  {
    "value": "60931",
    "label": "http://www.michedcu.org"
  },
  {
    "value": "60522",
    "label": "http://www.michiganfirst.com"
  },
  {
    "value": "62642",
    "label": "http://www.michiganlegacycu.org"
  },
  {
    "value": "62169",
    "label": "http://www.M1ccu.org"
  },
  {
    "value": "62049",
    "label": "http://www.msgcu.org"
  },
  {
    "value": "23279",
    "label": "http://www.msufcu.org"
  },
  {
    "value": "61698",
    "label": "http://www.michiganunitedcu.org"
  },
  {
    "value": "65378",
    "label": "http://www.midamerican.coop"
  },
  {
    "value": "60735",
    "label": "http://www.midcarolinacu.com"
  },
  {
    "value": "20194",
    "label": "http://www.mmfcu.org"
  },
  {
    "value": "12084",
    "label": "http://www.midoregon.com"
  },
  {
    "value": "18716",
    "label": "http://www.mafcu.org"
  },
  {
    "value": "95073",
    "label": "http://www.midcitiesfcu.org"
  },
  {
    "value": "10898",
    "label": "https://midcoastfcu.me"
  },
  {
    "value": "19700",
    "label": "http://www.midtenfcu.com"
  },
  {
    "value": "18628",
    "label": "http://mccunj.cuwebservices.com"
  },
  {
    "value": "62877",
    "label": "http://www.mascu.com"
  },
  {
    "value": "68600",
    "label": "http://www.midflorida.com"
  },
  {
    "value": "15766",
    "label": "http://mhvfcu.com"
  },
  {
    "value": "23811",
    "label": "http://www.midislandfcu.org"
  },
  {
    "value": "60906",
    "label": "http://www.mkcu.coop"
  },
  {
    "value": "61918",
    "label": "http://www.midlandcu.org"
  },
  {
    "value": "1410",
    "label": "http://www.midsouthfcu.org"
  },
  {
    "value": "6739",
    "label": "http://www.midstatefcu.org"
  },
  {
    "value": "24454",
    "label": "http://MTFCU.ORG"
  },
  {
    "value": "14408",
    "label": "http://www.midwayfcu.org"
  },
  {
    "value": "1427",
    "label": "https://www.mwafcu.org"
  },
  {
    "value": "13959",
    "label": "http://www.mcmfcu.com"
  },
  {
    "value": "61847",
    "label": "https://www.mclcu.org"
  },
  {
    "value": "9522",
    "label": "http://midwestcommunity.org"
  },
  {
    "value": "15007",
    "label": "https://www.mwffcu.org"
  },
  {
    "value": "6466",
    "label": "http://www.midwestlibertyfcu.org"
  },
  {
    "value": "68684",
    "label": "http://www.midmembers.org"
  },
  {
    "value": "60037",
    "label": "http://www.mrcu.com"
  },
  {
    "value": "67549",
    "label": "http://www.mwsucu.com"
  },
  {
    "value": "1739",
    "label": "http://www.milehighfcu.org"
  },
  {
    "value": "5144",
    "label": "https://milestonesfcu.com/"
  },
  {
    "value": "68222",
    "label": "milltowncu.org"
  },
  {
    "value": "60494",
    "label": "http://WWW.MILLARDCCU.COM"
  },
  {
    "value": "24543",
    "label": "https://www.millburycu.com"
  },
  {
    "value": "12666",
    "label": "http://www.mills42fcu.com"
  },
  {
    "value": "96756",
    "label": "http://www.millstreamcu.com"
  },
  {
    "value": "24197",
    "label": "http://www.milway.org"
  },
  {
    "value": "20622",
    "label": "http://www.minervacreditunion.com"
  },
  {
    "value": "63639",
    "label": "http://minnco.com"
  },
  {
    "value": "68240",
    "label": "http://www.mymwcu.com"
  },
  {
    "value": "63977",
    "label": "http://www.mncathcu.com"
  },
  {
    "value": "60377",
    "label": "http://www.mpecu.com"
  },
  {
    "value": "19440",
    "label": "http://www.mnvalleyfcu.coop"
  },
  {
    "value": "8943",
    "label": "http://www.masfcu.org"
  },
  {
    "value": "1827",
    "label": "http://www.mintvalleyfcu.org"
  },
  {
    "value": "14260",
    "label": "http://www.minutemanfcu.org"
  },
  {
    "value": "14384",
    "label": "http://www.MissionFed.com"
  },
  {
    "value": "13131",
    "label": "http://www.missioncityfcu.org"
  },
  {
    "value": "24585",
    "label": "http://WWW.MSFCU.US"
  },
  {
    "value": "8052",
    "label": "http://www.msdhsfcu.com"
  },
  {
    "value": "16327",
    "label": "http://www.mhspfcu.com"
  },
  {
    "value": "14805",
    "label": "https://www.msng.org"
  },
  {
    "value": "63442",
    "label": "http://www.mspecu.org"
  },
  {
    "value": "65656",
    "label": "http://www.missouricu.org"
  },
  {
    "value": "64718",
    "label": "https://mobaptistcu.org"
  },
  {
    "value": "64036",
    "label": "https://www.mocentral.org"
  },
  {
    "value": "67739",
    "label": "http://www.mececu.com"
  },
  {
    "value": "18948",
    "label": "http://www.mncppcfcu.com"
  },
  {
    "value": "61286",
    "label": "http://www.yourmecu.com"
  },
  {
    "value": "61339",
    "label": "http://mobgecu.com"
  },
  {
    "value": "62471",
    "label": "http://www.mobilepostalcu.com"
  },
  {
    "value": "68606",
    "label": "http://www.mobilitycu.com"
  },
  {
    "value": "68735",
    "label": "http://www.mobiloilcu.org"
  },
  {
    "value": "12805",
    "label": "http://www.mocse.org"
  },
  {
    "value": "8940",
    "label": "http://www.modernefcu.net"
  },
  {
    "value": "4022",
    "label": "http://m1fcu.org"
  },
  {
    "value": "12000",
    "label": "http://mohavecommunityfcu.com"
  },
  {
    "value": "907",
    "label": "http://www.mohawkprogressivefcu.com"
  },
  {
    "value": "20823",
    "label": "http://www.mohawkvalleyfcu.org"
  },
  {
    "value": "13265",
    "label": "http://www.mokelumnefcu.com"
  },
  {
    "value": "23109",
    "label": "http://molexfcu.com"
  },
  {
    "value": "60186",
    "label": "http://www.molinecu.org"
  },
  {
    "value": "7471",
    "label": "http://www.molokaicommunityfcu.com"
  },
  {
    "value": "1911",
    "label": "http://www.mvcfcu.com"
  },
  {
    "value": "22566",
    "label": "https://www.moneyfcu.org"
  },
  {
    "value": "7264",
    "label": "http://www.moneyonefcu.org"
  },
  {
    "value": "61872",
    "label": "http://www.monmouthpostalcu.org"
  },
  {
    "value": "61748",
    "label": "www.monroecommunitycu.org"
  },
  {
    "value": "7039",
    "label": "http://monroecountyteachersfcu.org"
  },
  {
    "value": "1725",
    "label": "http://www.monroetelcofcu.org"
  },
  {
    "value": "4164",
    "label": "http://www.montanacu.com"
  },
  {
    "value": "68190",
    "label": "http://www.mtedcu.org"
  },
  {
    "value": "16273",
    "label": "http://www.MontanaHealthFCU.org"
  },
  {
    "value": "61999",
    "label": "https://mpecreditunion.com"
  },
  {
    "value": "97071",
    "label": "http://www.montereycu.com"
  },
  {
    "value": "5345",
    "label": "http://WWW.MVAFCU.ORG"
  },
  {
    "value": "18144",
    "label": "https://www.netit.financial-net.com"
  },
  {
    "value": "15669",
    "label": "http://www.moogfcu.com"
  },
  {
    "value": "64883",
    "label": "http://www.moonlightcu.com"
  },
  {
    "value": "11458",
    "label": "http://mcsfcu.com"
  },
  {
    "value": "1129",
    "label": "http://www.mopaccu.com"
  },
  {
    "value": "23070",
    "label": "http://moreheadcommunity.com"
  },
  {
    "value": "10695",
    "label": "http://www.aesfcu.com"
  },
  {
    "value": "1",
    "label": "http://www.morrissheppardfcu.org"
  },
  {
    "value": "9681",
    "label": "http://www.mcove1stfcu.com"
  },
  {
    "value": "21606",
    "label": "http://morrowcountyfcu.com"
  },
  {
    "value": "24511",
    "label": "http://mortonfedcu.com"
  },
  {
    "value": "65466",
    "label": "http://MORTONCU.COM"
  },
  {
    "value": "15021",
    "label": "http://www.mortonlanedirect.com"
  },
  {
    "value": "24281",
    "label": "http://www.mosaicfcu.org"
  },
  {
    "value": "53",
    "label": "http://www.motionfcu.org"
  },
  {
    "value": "24605",
    "label": "http://mobcfcu.com"
  },
  {
    "value": "68531",
    "label": "http://www.mountaincu.org"
  },
  {
    "value": "24692",
    "label": "http://www.macu.com"
  },
  {
    "value": "8197",
    "label": "https://www.mountainempirefcu.org"
  },
  {
    "value": "12470",
    "label": "http://www.mhfcu.net"
  },
  {
    "value": "20053",
    "label": "http://www.mlfcu.net"
  },
  {
    "value": "63704",
    "label": "http://www.mountainrivercu.org"
  },
  {
    "value": "856",
    "label": "http://www.mountainstarfcu.org"
  },
  {
    "value": "15516",
    "label": "www.mountainvalleyfcu.com"
  },
  {
    "value": "8129",
    "label": "http://mountainwestfcu.com"
  },
  {
    "value": "68253",
    "label": "http://www.mountaincrestcu.com"
  },
  {
    "value": "63258",
    "label": "www.mccpcu.com"
  },
  {
    "value": "64518",
    "label": "http://www.mowercountyecu.org"
  },
  {
    "value": "20595",
    "label": "https://louisvillemsd.org/msd-feder"
  },
  {
    "value": "22978",
    "label": "www.msu-fcu.org"
  },
  {
    "value": "1057",
    "label": "http://www.mtlcu.org"
  },
  {
    "value": "15757",
    "label": "http://www.mzbchurch.org/credit_uni"
  },
  {
    "value": "24246",
    "label": "http://www.mjfcu.org"
  },
  {
    "value": "18068",
    "label": "http://www.mtrainierfcu.com"
  },
  {
    "value": "22520",
    "label": "http://www.mtcfederal.com"
  },
  {
    "value": "67642",
    "label": "http://mtcu.org"
  },
  {
    "value": "63411",
    "label": "http://multiplicu.com"
  },
  {
    "value": "2612",
    "label": "https://multischoolsfcu.org"
  },
  {
    "value": "15108",
    "label": "http://www.munafederal.com"
  },
  {
    "value": "339",
    "label": "http://www.munciefcu.org"
  },
  {
    "value": "60153",
    "label": "http://www.NYMCU.ORG"
  },
  {
    "value": "66787",
    "label": "https://www.mecu.com"
  },
  {
    "value": "65503",
    "label": "http://WWW.MECUOKC.ORG"
  },
  {
    "value": "17361",
    "label": "http://www.musiciansdfw.org"
  },
  {
    "value": "7509",
    "label": "http://WWW.MUSKFEDCU.COM"
  },
  {
    "value": "946",
    "label": "http://www.mfcuonline.org"
  },
  {
    "value": "24948",
    "label": "http://www.mutualcu.org"
  },
  {
    "value": "24540",
    "label": "http://www.mutualfirst.com"
  },
  {
    "value": "60501",
    "label": "http://www.mutualsavings.org"
  },
  {
    "value": "67388",
    "label": "http://mutualsavingscu.org"
  },
  {
    "value": "68479",
    "label": "http://www.mscu.net"
  },
  {
    "value": "16770",
    "label": "http://www.muwfederalcreditunion.com"
  },
  {
    "value": "8001",
    "label": "http://www.mvpfcu.org"
  },
  {
    "value": "65843",
    "label": "http://mwrdecu.org"
  },
  {
    "value": "60125",
    "label": "https://www.mymncu.org"
  },
  {
    "value": "68545",
    "label": "http://www.mycutx.com"
  },
  {
    "value": "68682",
    "label": "http://www.mccu.coop"
  },
  {
    "value": "8442",
    "label": "www.MyPensacolaCU.com"
  },
  {
    "value": "10065",
    "label": "http://www.mycomcu.org"
  },
  {
    "value": "68602",
    "label": "http://www.mygacu.com"
  },
  {
    "value": "68377",
    "label": "http://www.mypointcu.com"
  },
  {
    "value": "96755",
    "label": "http://www.myusacu.com"
  },
  {
    "value": "16706",
    "label": "http://www.naefcu.org"
  },
  {
    "value": "3850",
    "label": "http://ncpdfcu.org"
  },
  {
    "value": "19216",
    "label": "http://www.nyteamfcu.org"
  },
  {
    "value": "20463",
    "label": "http://njlatviancreditunion.com"
  },
  {
    "value": "23900",
    "label": "http://www.nmhs.net/creditunion"
  },
  {
    "value": "67017",
    "label": "http://www.newcu.org"
  },
  {
    "value": "9776",
    "label": "http://www.nfg2.com"
  },
  {
    "value": "12963",
    "label": "http://www.nhcommunityfcu.org"
  },
  {
    "value": "24039",
    "label": "http://nicefederalcu.org"
  },
  {
    "value": "62936",
    "label": "http://www.portnocu.com"
  },
  {
    "value": "10994",
    "label": "http://www.naftfcu.coop"
  },
  {
    "value": "62661",
    "label": "http://www.naheola.com"
  },
  {
    "value": "6328",
    "label": "http://www.nasafcu.com"
  },
  {
    "value": "60483",
    "label": "http://nashvillefirecu.org"
  },
  {
    "value": "67922",
    "label": "http://www.npocu.org"
  },
  {
    "value": "1404",
    "label": "http://www.nassaufinancial.org"
  },
  {
    "value": "65208",
    "label": "http://www.natcocu.org"
  },
  {
    "value": "5713",
    "label": "http://www.nefcu.virtualcu.net"
  },
  {
    "value": "3601",
    "label": "http://www.nihfcu.org"
  },
  {
    "value": "67005",
    "label": "http://jaclcu.com"
  },
  {
    "value": "24090",
    "label": "http://www.natriumefcu.com"
  },
  {
    "value": "2158",
    "label": "www.nrcscreditunion.org"
  },
  {
    "value": "24432",
    "label": "https://www.naturalstatefcu.net"
  },
  {
    "value": "68046",
    "label": "http://www.naveo.org"
  },
  {
    "value": "62882",
    "label": "http://www.navigantcu.org"
  },
  {
    "value": "63637",
    "label": "http://www.navigatorcu.org"
  },
  {
    "value": "5536",
    "label": "www.navyfcu.org"
  },
  {
    "value": "67578",
    "label": "http://www.ncecreditunion.net"
  },
  {
    "value": "13458",
    "label": "http://www.nepafcu.org"
  },
  {
    "value": "67146",
    "label": "http://www.nebocreditunion.org"
  },
  {
    "value": "24286",
    "label": "http://www.ne-fcu.org"
  },
  {
    "value": "24695",
    "label": "http://www.nrcfcu.com"
  },
  {
    "value": "65816",
    "label": "http://www.nsecu.org"
  },
  {
    "value": "7916",
    "label": "http://www.nechesfcu.org"
  },
  {
    "value": "67681",
    "label": "http://www.myncu.com"
  },
  {
    "value": "24396",
    "label": "http://www.neighborhoodcfcu.org"
  },
  {
    "value": "24589",
    "label": "http://www.neighborhoodtrustfcu.org"
  },
  {
    "value": "9229",
    "label": "http://www.neighborsfcu.org"
  },
  {
    "value": "62905",
    "label": "http://www.neighborscu.org"
  },
  {
    "value": "24851",
    "label": "http://www.mynucu.org"
  },
  {
    "value": "6304",
    "label": "http://www.neimanmarcusfcu.com"
  },
  {
    "value": "66393",
    "label": "http://www.nekoosacu.com"
  },
  {
    "value": "64859",
    "label": "http://neoshosecu.us"
  },
  {
    "value": "6920",
    "label": "http://nephicreditunion.com"
  },
  {
    "value": "12334",
    "label": "http://www.nescfcu.org"
  },
  {
    "value": "4792",
    "label": "http://netcreditunion.com"
  },
  {
    "value": "373",
    "label": "https://www.newalliancefcu.com"
  },
  {
    "value": "66733",
    "label": "http://nbcu4u.com"
  },
  {
    "value": "2394",
    "label": "http://nbpostalfcu.org"
  },
  {
    "value": "8903",
    "label": "http://WWW.NEWCASTLEBELLCO.COM"
  },
  {
    "value": "17687",
    "label": "http://www.nccdefcu.com"
  },
  {
    "value": "1251",
    "label": "http://newcenturyfcu.org"
  },
  {
    "value": "24167",
    "label": "http://www.newcommunityFCU.org"
  },
  {
    "value": "24784",
    "label": "http://www.newcovenantcreditunion.o"
  },
  {
    "value": "8110",
    "label": "http://www.ncfcuonline.org"
  },
  {
    "value": "11253",
    "label": "https://newdimensionsfcu.com"
  },
  {
    "value": "19263",
    "label": "http://www.nefcu.com"
  },
  {
    "value": "21507",
    "label": "http://www.netfcu.org"
  },
  {
    "value": "4746",
    "label": "http://www.nhfcu.org"
  },
  {
    "value": "66207",
    "label": "https://nhpcu.com"
  },
  {
    "value": "65809",
    "label": "http://www.nhccu.com"
  },
  {
    "value": "616",
    "label": "http://www.newhorizonfcu.com"
  },
  {
    "value": "60799",
    "label": "http://www.newhcu.org"
  },
  {
    "value": "9555",
    "label": "http://WWW.NEWJERSEYCOMMUNITYFCU.CO"
  },
  {
    "value": "66130",
    "label": "http://www.njlpscu.org"
  },
  {
    "value": "10515",
    "label": "http://www.facebook.com/noccfcu"
  },
  {
    "value": "63008",
    "label": "HTTPS://WWW.NOPDECU.COM"
  },
  {
    "value": "16858",
    "label": "http://www.npfcu.com"
  },
  {
    "value": "60645",
    "label": "http://www.newsouthcu.com"
  },
  {
    "value": "24942",
    "label": "https://nyefcu.org/"
  },
  {
    "value": "9358",
    "label": "http://www.nytimescu.org"
  },
  {
    "value": "23958",
    "label": "http://www.nyufcu.com"
  },
  {
    "value": "4175",
    "label": "http://newarkfiremenfcu.org"
  },
  {
    "value": "19045",
    "label": "NEWELLFCU.ORG"
  },
  {
    "value": "66970",
    "label": "http://www.nnmecu.org"
  },
  {
    "value": "61330",
    "label": "http://www.bayportcu.org"
  },
  {
    "value": "16893",
    "label": "http://www.newrizonsfcu.com"
  },
  {
    "value": "9486",
    "label": "http://www.nextstepfcu.org"
  },
  {
    "value": "24658",
    "label": "http://www.NextMarkcu.org"
  },
  {
    "value": "10613",
    "label": "http://www.niagarafallsafcu.com"
  },
  {
    "value": "98",
    "label": "http://www.nftfcu.org"
  },
  {
    "value": "63793",
    "label": "https://nffmecu.com/"
  },
  {
    "value": "3285",
    "label": "http://www.niagararegionalfcu.org"
  },
  {
    "value": "8693",
    "label": "http://www.niagaraschoice.org"
  },
  {
    "value": "14040",
    "label": "http://www.nwfcuanytime.com"
  },
  {
    "value": "9000",
    "label": "https://www.nickelsteelfcu.com"
  },
  {
    "value": "65059",
    "label": "http://www.nikkeicu.org"
  },
  {
    "value": "24384",
    "label": "http://www.nizaricu.org"
  },
  {
    "value": "19454",
    "label": "www.njgateway.org"
  },
  {
    "value": "24552",
    "label": "http://www.noblecu.com"
  },
  {
    "value": "4461",
    "label": "http://nodafcu.org"
  },
  {
    "value": "24657",
    "label": "http://www.nslfcu.org"
  },
  {
    "value": "1788",
    "label": "http://www.nordcu.org"
  },
  {
    "value": "8828",
    "label": "http://www.norfolkcommunityfcu.org"
  },
  {
    "value": "3746",
    "label": "http://www.norfolkfirefcu.org"
  },
  {
    "value": "14972",
    "label": "http://www.ncefcu.com"
  },
  {
    "value": "68297",
    "label": "www.3hillcu.com"
  },
  {
    "value": "4951",
    "label": "norstarfcu.com"
  },
  {
    "value": "4261",
    "label": "http://www.norstatefcu.org"
  },
  {
    "value": "13274",
    "label": "http://Namefed.webs.com"
  },
  {
    "value": "60500",
    "label": "http://www.naecu.org"
  },
  {
    "value": "63373",
    "label": "http://www.northbaycu.com"
  },
  {
    "value": "12004",
    "label": "http://www.nccfcu.org"
  },
  {
    "value": "24140",
    "label": "http://www.ncpafcu.org"
  },
  {
    "value": "61953",
    "label": "http://www.ncacu.org"
  },
  {
    "value": "66734",
    "label": "http://www.northcoastcu.com"
  },
  {
    "value": "95786",
    "label": "http://www.nccuonline.com"
  },
  {
    "value": "24312",
    "label": "http://www.northcountry.org"
  },
  {
    "value": "68463",
    "label": "http://www.northcountycu.org"
  },
  {
    "value": "61636",
    "label": "https://www.ndcupa.org"
  },
  {
    "value": "8687",
    "label": "http://www.newelchfcu.com"
  },
  {
    "value": "67395",
    "label": "http://www.ngcu.org"
  },
  {
    "value": "64191",
    "label": "http://WWW.NICCU.COM"
  },
  {
    "value": "1015",
    "label": "http://njfcu.org"
  },
  {
    "value": "24434",
    "label": "http://www.nlrsd.org/credit-union-i"
  },
  {
    "value": "16248",
    "label": "http://www.northmemorialfcu.com"
  },
  {
    "value": "10720",
    "label": "http://northpennfcu.org"
  },
  {
    "value": "65528",
    "label": "http://www.upemployeescu.com"
  },
  {
    "value": "18473",
    "label": "https://www.northshorefcu.org"
  },
  {
    "value": "60689",
    "label": "http://www.northstarcreditunion.org"
  },
  {
    "value": "62563",
    "label": "http://www.northstarccu.com"
  },
  {
    "value": "62638",
    "label": "http://www.nsccu.org"
  },
  {
    "value": "9812",
    "label": "http://www.northtownsfcu.com"
  },
  {
    "value": "64282",
    "label": "https://www.nwecu.org"
  },
  {
    "value": "24911",
    "label": "https://www.nasdecu.com"
  },
  {
    "value": "68219",
    "label": "https://www.necu.org"
  },
  {
    "value": "11056",
    "label": "http://WWW.NEAPFCU.COM"
  },
  {
    "value": "23780",
    "label": "http://www.necfcu.org"
  },
  {
    "value": "67021",
    "label": "http://www.BeMyCU.org"
  },
  {
    "value": "2709",
    "label": "http://www.nefamily.coop"
  },
  {
    "value": "14965",
    "label": "https://www.nnfcu.com"
  },
  {
    "value": "19171",
    "label": "http://www.nptfcu.com"
  },
  {
    "value": "64709",
    "label": "http://www.mynrcu.com"
  },
  {
    "value": "64462",
    "label": "http://nshcu.virtualcu.net"
  },
  {
    "value": "14170",
    "label": "http://nettfcu.com"
  },
  {
    "value": "66057",
    "label": "http://www.necthealthcarecreditunion.org"
  },
  {
    "value": "21630",
    "label": "http://www.noefcuny.org"
  },
  {
    "value": "68696",
    "label": "http://www.mynorthern.com"
  },
  {
    "value": "65775",
    "label": "www.nococu.org"
  },
  {
    "value": "64655",
    "label": "http://nccyou.com"
  },
  {
    "value": "6986",
    "label": "http://www.northernhillsfcu.org"
  },
  {
    "value": "19708",
    "label": "http://mynifcu.com"
  },
  {
    "value": "168",
    "label": "http://www.nifedcu.org"
  },
  {
    "value": "21339",
    "label": "http://www.nkfcu.org"
  },
  {
    "value": "24878",
    "label": "http://www.northernlightscu.com"
  },
  {
    "value": "5272",
    "label": "http://www.nlightsfcu.com"
  },
  {
    "value": "4805",
    "label": "http://www.nmsefcu.org"
  },
  {
    "value": "10362",
    "label": "https://www.northernredwoodfcu.org"
  },
  {
    "value": "18204",
    "label": "http://www.nsfcu.org"
  },
  {
    "value": "66956",
    "label": "http://www.nstarcu.org"
  },
  {
    "value": "62733",
    "label": "http://www.mynspcu.org"
  },
  {
    "value": "1344",
    "label": "http://northernvalleyfcu.com"
  },
  {
    "value": "11979",
    "label": "http://www.northlandcu.com"
  },
  {
    "value": "60251",
    "label": "http://northparkccu.org"
  },
  {
    "value": "61257",
    "label": "www.nrccu.org"
  },
  {
    "value": "24292",
    "label": "http://www.ngfcu.us"
  },
  {
    "value": "20410",
    "label": "http://www.northsidefcu.com"
  },
  {
    "value": "2467",
    "label": "http://www.nslfcu.com"
  },
  {
    "value": "5500",
    "label": "http://www.nwfcu.org"
  },
  {
    "value": "14786",
    "label": "http://www.mynwcu.com"
  },
  {
    "value": "195",
    "label": "http://www.nwarkansasfcu.com"
  },
  {
    "value": "97073",
    "label": "http://www.mynccu.org"
  },
  {
    "value": "97108",
    "label": "http://www.nwccu.com"
  },
  {
    "value": "8077",
    "label": "http://www.nwconsumers.org"
  },
  {
    "value": "65803",
    "label": "https://www.nwhcu.com"
  },
  {
    "value": "62611",
    "label": "http://www.nwmrcu.org"
  },
  {
    "value": "17752",
    "label": "http://www.mynmfcu.com"
  },
  {
    "value": "67262",
    "label": "http://nwpluscu.com"
  },
  {
    "value": "6949",
    "label": "http://northwesternfcu.com"
  },
  {
    "value": "21609",
    "label": "http://nweefcu.org"
  },
  {
    "value": "66555",
    "label": "http://www.mynmcu.com"
  },
  {
    "value": "5062",
    "label": "http://northwoodfcu.com"
  },
  {
    "value": "62847",
    "label": "https://www.northwoodscu.org"
  },
  {
    "value": "67240",
    "label": "http://www.northwoodscu.com"
  },
  {
    "value": "65954",
    "label": "http://www.nohocu.org"
  },
  {
    "value": "7842",
    "label": "http://www.norwinteachersfcu.org"
  },
  {
    "value": "17112",
    "label": "http://norwoodcreditunion.com"
  },
  {
    "value": "13559",
    "label": "http://www.noteworthyfcu.com"
  },
  {
    "value": "24034",
    "label": "http://WWW.NOTREDAMEFCU.COM"
  },
  {
    "value": "2792",
    "label": "http://ndcfcu.com"
  },
  {
    "value": "63425",
    "label": "https://www.novacu.com"
  },
  {
    "value": "13439",
    "label": "http://www.novafcu.com"
  },
  {
    "value": "65063",
    "label": "http://www.Novation.org"
  },
  {
    "value": "15784",
    "label": "http://www.novocu.org"
  },
  {
    "value": "24583",
    "label": "http://www.starfcu.net"
  },
  {
    "value": "61028",
    "label": "http://www.nucorcu.org"
  },
  {
    "value": "68603",
    "label": "www.nuevaesperanzaccu.org"
  },
  {
    "value": "61447",
    "label": "http://www.numarkcu.org"
  },
  {
    "value": "66841",
    "label": "http://www.numericacu.com"
  },
  {
    "value": "948",
    "label": "http://www.nusenda.org"
  },
  {
    "value": "68657",
    "label": "http://www.nutmegstatefcu.org"
  },
  {
    "value": "566",
    "label": "http://www.nuvisionfederal.org"
  },
  {
    "value": "16476",
    "label": "http://www.nuvista.org"
  },
  {
    "value": "3853",
    "label": "http://nwpreferredfcu.com"
  },
  {
    "value": "63315",
    "label": "http://www.nwprioritycu.org"
  },
  {
    "value": "609",
    "label": "https://www.nybravestfcu.org/"
  },
  {
    "value": "18238",
    "label": "http://www.nymfcu.org"
  },
  {
    "value": "16408",
    "label": "http://www.nymeo.org"
  },
  {
    "value": "23700",
    "label": "http://www.orutilfcu.org"
  },
  {
    "value": "60059",
    "label": "https://www.obee.com"
  },
  {
    "value": "15000",
    "label": "http://www.oasfcu.org"
  },
  {
    "value": "67946",
    "label": "http://omcecu.com"
  },
  {
    "value": "10243",
    "label": "http://www.oahefcu.coop"
  },
  {
    "value": "24830",
    "label": "https://OAHUFCU.ORG"
  },
  {
    "value": "24804",
    "label": "https://www.occfcu.org"
  },
  {
    "value": "67624",
    "label": "http://www.ofecu.com"
  },
  {
    "value": "62717",
    "label": "olmecu.virualcu.net"
  },
  {
    "value": "67200",
    "label": "http://oakdalecu.coop"
  },
  {
    "value": "67938",
    "label": "http://www.ocalacommunitycu.com"
  },
  {
    "value": "19269",
    "label": "http://oceanfinancial.org"
  },
  {
    "value": "66595",
    "label": "http://www.oceanstatecu.org"
  },
  {
    "value": "5987",
    "label": "http://ocnac.com"
  },
  {
    "value": "67534",
    "label": "http://odessaecu.com"
  },
  {
    "value": "9208",
    "label": "http://www.ohiocatholicfcu.com"
  },
  {
    "value": "19881",
    "label": "http://www.ocpsfcu.virtualcu.net"
  },
  {
    "value": "95107",
    "label": "http://ohecu.com"
  },
  {
    "value": "24725",
    "label": "http://www.ohiohealthcarefcu.com"
  },
  {
    "value": "61136",
    "label": "http://www.ohioteamsterscu.com"
  },
  {
    "value": "22688",
    "label": "http://www.ovfcu.org"
  },
  {
    "value": "24909",
    "label": "http://www.ohiovalleycu.org"
  },
  {
    "value": "10158",
    "label": "http://www.okfcu.org"
  },
  {
    "value": "7154",
    "label": "http://www.omffcu.org"
  },
  {
    "value": "13534",
    "label": "http://okaloosafcu.org"
  },
  {
    "value": "7151",
    "label": "http://www.okfcu.com"
  },
  {
    "value": "62322",
    "label": "http://www.oklahomacentral.org"
  },
  {
    "value": "18965",
    "label": "http://www.okcommunitycu.com"
  },
  {
    "value": "61088",
    "label": "http://www.oecu.com"
  },
  {
    "value": "65032",
    "label": "http://www.okcu.org"
  },
  {
    "value": "67982",
    "label": "http://www.ohcu.org"
  },
  {
    "value": "6175",
    "label": "http://www.oofcu.org"
  },
  {
    "value": "61963",
    "label": "http://oldspanishtrailcreditunion.c"
  },
  {
    "value": "12012",
    "label": "http://oldwestfcu.com"
  },
  {
    "value": "21083",
    "label": "http://oleanareafcu.org"
  },
  {
    "value": "7656",
    "label": "http://otpfcu.com"
  },
  {
    "value": "1034",
    "label": "http://www.OliveViewFCU.com"
  },
  {
    "value": "68331",
    "label": "http://www.olycu.org"
  },
  {
    "value": "135",
    "label": "www.omahafcu.org"
  },
  {
    "value": "6208",
    "label": "http://www.omahadouglasfcu.org"
  },
  {
    "value": "66126",
    "label": "http://omahafirefighterscu.org"
  },
  {
    "value": "12319",
    "label": "http://opfcu.net"
  },
  {
    "value": "2507",
    "label": "http://www.oppd-efcu.org"
  },
  {
    "value": "3711",
    "label": "http://www.omegafcu.com"
  },
  {
    "value": "24234",
    "label": "http://www.oppffcu.com"
  },
  {
    "value": "60105",
    "label": "http://www.omnicommunitycu.org"
  },
  {
    "value": "64913",
    "label": "http://www.ontapcu.org"
  },
  {
    "value": "383",
    "label": "http://www.cuonthegrid.com"
  },
  {
    "value": "63923",
    "label": "http://www.onecu.org"
  },
  {
    "value": "12143",
    "label": "http://www.onecommunityfcu.net"
  },
  {
    "value": "60716",
    "label": "http://www.OneCreditUnion.org"
  },
  {
    "value": "61495",
    "label": "http://WWW.OneDetroitCU.org"
  },
  {
    "value": "68613",
    "label": "https://www.onenevada.org"
  },
  {
    "value": "2115",
    "label": "http://www.onesourcefcu.org"
  },
  {
    "value": "8840",
    "label": "http://WWW.OLDSOUTHFCU.COM"
  },
  {
    "value": "64340",
    "label": "http://www.113creditunion.com"
  },
  {
    "value": "9097",
    "label": "http://www.onevisionfcu.org"
  },
  {
    "value": "61315",
    "label": "https://www.oneazcu.com"
  },
  {
    "value": "13102",
    "label": "http://www.oneidacfcu.org"
  },
  {
    "value": "24402",
    "label": "http://www.bankingwithyou.com"
  },
  {
    "value": "3050",
    "label": "http://www.onomeafcu.org"
  },
  {
    "value": "14692",
    "label": "https://beonpath.org"
  },
  {
    "value": "62745",
    "label": "http://www.onpointcu.com"
  },
  {
    "value": "14542",
    "label": "http://www.omsefcu.org"
  },
  {
    "value": "13044",
    "label": "http://www.opefcu.org"
  },
  {
    "value": "14462",
    "label": "http://www.osfcu.com"
  },
  {
    "value": "24235",
    "label": "http://oefederal.org"
  },
  {
    "value": "67251",
    "label": "www.oppsvt.org"
  },
  {
    "value": "65111",
    "label": "http://www.optionscreditunion.com"
  },
  {
    "value": "68416",
    "label": "http://www.orangecountyscu.org"
  },
  {
    "value": "65856",
    "label": "http://www.myoccu.org"
  },
  {
    "value": "68656",
    "label": "http://www.oregonstatecu.com"
  },
  {
    "value": "68669",
    "label": "http://www.oregonianscu.com"
  },
  {
    "value": "13965",
    "label": "http://cu.orem.org"
  },
  {
    "value": "61942",
    "label": "http://www.olcu.org"
  },
  {
    "value": "24831",
    "label": "http://www.orionfcu.com"
  },
  {
    "value": "68706",
    "label": "http://www.OrlandoCreditUnion.org"
  },
  {
    "value": "64782",
    "label": "http://www.orlex.com"
  },
  {
    "value": "5797",
    "label": "http://www.ornlfcu.com"
  },
  {
    "value": "66779",
    "label": "http://www.oshkoshcommunitycu.com"
  },
  {
    "value": "66418",
    "label": "http://www.opecu.com"
  },
  {
    "value": "21927",
    "label": "http://www.oswegofcu.org"
  },
  {
    "value": "14436",
    "label": "http://oswegoteacherscu.org"
  },
  {
    "value": "932",
    "label": "http://www.oteencreditunion.com"
  },
  {
    "value": "8475",
    "label": "https://www.oterofcu.org"
  },
  {
    "value": "1956",
    "label": "http://oterocountyteachersfcu.com"
  },
  {
    "value": "9349",
    "label": "http://www.otisfcu.coop"
  },
  {
    "value": "24910",
    "label": "http://OtoeMissouriafcu.org"
  },
  {
    "value": "63213",
    "label": "http://ottawahiwaycu.com"
  },
  {
    "value": "9335",
    "label": "https://www.oufcu.com"
  },
  {
    "value": "10181",
    "label": "http://ouachitavalleyfcu.org"
  },
  {
    "value": "61048",
    "label": "http://www.oucu.org"
  },
  {
    "value": "62028",
    "label": "http://www.ourcuonline.org"
  },
  {
    "value": "68609",
    "label": "http://www.ourcu.com"
  },
  {
    "value": "17067",
    "label": "http://www.omomfcu.org"
  },
  {
    "value": "18857",
    "label": "http://www.oursundayvisitorefcu.com"
  },
  {
    "value": "8922",
    "label": "http://www.outreachcu.com"
  },
  {
    "value": "8988",
    "label": "http://www.ofcuonline.com"
  },
  {
    "value": "2645",
    "label": "www.ofcu.org"
  },
  {
    "value": "7641",
    "label": "http://www.ozarkfcu.com"
  },
  {
    "value": "20861",
    "label": "http://www.pgmfcu.org"
  },
  {
    "value": "67056",
    "label": "http://www.pandscu.org"
  },
  {
    "value": "10704",
    "label": "http://www.pcfederalcu.org"
  },
  {
    "value": "66975",
    "label": "http://www.pwcecu.org"
  },
  {
    "value": "21879",
    "label": "http://www.phifcu.com"
  },
  {
    "value": "67779",
    "label": "http://piascu.org"
  },
  {
    "value": "66582",
    "label": "http://piecu.org"
  },
  {
    "value": "24226",
    "label": "http://www.psegnuclearcreditunion.c"
  },
  {
    "value": "11323",
    "label": "http://www.pacefederal.com"
  },
  {
    "value": "19501",
    "label": "http://WWW.PACERESOURCESFCU.VIRTUAL"
  },
  {
    "value": "14545",
    "label": "http://wesaveyou.com"
  },
  {
    "value": "960",
    "label": "https://www.mypcfcu.org"
  },
  {
    "value": "67147",
    "label": "http://www.pacifichorizoncu.com"
  },
  {
    "value": "4878",
    "label": "http://www.pnwfcu.org"
  },
  {
    "value": "64835",
    "label": "http://www.ppcu.org"
  },
  {
    "value": "68413",
    "label": "http://www.pacificservice.org"
  },
  {
    "value": "15532",
    "label": "http://www.pagodafcu.org"
  },
  {
    "value": "6464",
    "label": "http://www.pahofcu.org"
  },
  {
    "value": "12686",
    "label": "http://www.pvfcu.net"
  },
  {
    "value": "63679",
    "label": "http://www.painesvillecu.org/"
  },
  {
    "value": "13103",
    "label": "http://www.palacecitycu.org"
  },
  {
    "value": "2959",
    "label": "https://www.palcofcu.org"
  },
  {
    "value": "4480",
    "label": "http://www.palisadesfcu.org"
  },
  {
    "value": "1472",
    "label": "http://www.palmettocitizens.org"
  },
  {
    "value": "24491",
    "label": "http://www.palmettofirst.com"
  },
  {
    "value": "61260",
    "label": "http://www.palmettohealthcu.org"
  },
  {
    "value": "8858",
    "label": "http://www.pamcelfcu.com"
  },
  {
    "value": "2046",
    "label": "http://www.ptfcu-tx.com"
  },
  {
    "value": "8920",
    "label": "http://panamocofcu.com"
  },
  {
    "value": "12033",
    "label": "http://www.panhandlefcu.com"
  },
  {
    "value": "68720",
    "label": "http://www.panhandlecu.org"
  },
  {
    "value": "3571",
    "label": "http://www.pantexfcu.com"
  },
  {
    "value": "11953",
    "label": "http://www.paradisevalleyfcu.org"
  },
  {
    "value": "21686",
    "label": "http://www.parda.com"
  },
  {
    "value": "22723",
    "label": "http://www.creducomp.com"
  },
  {
    "value": "2935",
    "label": "http://www.parishfcu.coop"
  },
  {
    "value": "14650",
    "label": "http://www.parishionersfcu.org"
  },
  {
    "value": "24931",
    "label": "http://www.parkcommunity.com"
  },
  {
    "value": "67196",
    "label": "http://www.parkcitycu.org"
  },
  {
    "value": "60923",
    "label": "pmcccreditunion.com"
  },
  {
    "value": "68647",
    "label": "http://www.parksidefcu.com"
  },
  {
    "value": "19541",
    "label": "http://www.pvfcu.org"
  },
  {
    "value": "16933",
    "label": "http://www.parksheritagefcu.org"
  },
  {
    "value": "61985",
    "label": "http://WWW.PARKSIDECU.ORG"
  },
  {
    "value": "479",
    "label": "http://www.pc-fcu.org"
  },
  {
    "value": "2246",
    "label": "http://WWW.PARLINDUPONTEFCU.COM"
  },
  {
    "value": "4809",
    "label": "http://www.parthenonfcu.org"
  },
  {
    "value": "62903",
    "label": "http://partnercoloradocu.org"
  },
  {
    "value": "18708",
    "label": "http://www.partnersfcu.org"
  },
  {
    "value": "7688",
    "label": "www.partners1stcu.org"
  },
  {
    "value": "62854",
    "label": "http://WWW.MYPFCU.ORG"
  },
  {
    "value": "594",
    "label": "http://www.pfcu.org"
  },
  {
    "value": "1368",
    "label": "http://www.mypsfcu.org"
  },
  {
    "value": "68579",
    "label": "http://WWW.PATELCO.ORG"
  },
  {
    "value": "4936",
    "label": "http://www.ptofcu.org"
  },
  {
    "value": "8339",
    "label": "http://www.patersonpolicefcu.com"
  },
  {
    "value": "8118",
    "label": "https://www.pathfcu.org"
  },
  {
    "value": "9148",
    "label": "http://www.pathfinderfcu.com"
  },
  {
    "value": "68124",
    "label": "http://www.pathwaycredit.com"
  },
  {
    "value": "66376",
    "label": "http://www.pathwayscu.com"
  },
  {
    "value": "16574",
    "label": "http://www.patriotfcu.org"
  },
  {
    "value": "64856",
    "label": "http://www.patriotcu.com"
  },
  {
    "value": "60082",
    "label": "http://www.pecujax.org"
  },
  {
    "value": "11902",
    "label": "http://www.pfcuonline.org"
  },
  {
    "value": "23658",
    "label": "http://PaulQuinnFCU.org"
  },
  {
    "value": "11045",
    "label": "http://www.pbafcu.com"
  },
  {
    "value": "66424",
    "label": "http://www.pcmcu.org"
  },
  {
    "value": "14676",
    "label": "http://www.peachstatefcu.org"
  },
  {
    "value": "7162",
    "label": "lcurtispdfcu@att.net"
  },
  {
    "value": "1961",
    "label": "http://www.pearlhawaii.com"
  },
  {
    "value": "24880",
    "label": "http://pearlcu.com"
  },
  {
    "value": "22530",
    "label": "http://www.pdfcu.org"
  },
  {
    "value": "62710",
    "label": "http://www.pelicanstatecu.com"
  },
  {
    "value": "68734",
    "label": "https://www.penair.org"
  },
  {
    "value": "4291",
    "label": "http://peninsulafcu.com"
  },
  {
    "value": "665",
    "label": "www.pcfcu.org"
  },
  {
    "value": "2834",
    "label": "http://WWW.PENLANCOFCU.COM"
  },
  {
    "value": "20207",
    "label": "http://www.penncu.org"
  },
  {
    "value": "2742",
    "label": "http://www.penneastfcu.org"
  },
  {
    "value": "15459",
    "label": "https://www.pennsouthcreditunion.or"
  },
  {
    "value": "12729",
    "label": "https://pennstatefederal.com"
  },
  {
    "value": "13760",
    "label": "http://pennstarfederal.com"
  },
  {
    "value": "2625",
    "label": "https://www.pacentralfcu.com"
  },
  {
    "value": "64131",
    "label": "http://www.psecu.com"
  },
  {
    "value": "21556",
    "label": "http://www.pawfcu.com"
  },
  {
    "value": "13469",
    "label": "http://penntechfcu.com"
  },
  {
    "value": "227",
    "label": "http://www.penfed.org"
  },
  {
    "value": "61068",
    "label": "http://www.peopledrivencu.org"
  },
  {
    "value": "6885",
    "label": "https//www.peoplefirst.com"
  },
  {
    "value": "24940",
    "label": "www.ptcfederal.org"
  },
  {
    "value": "16993",
    "label": "http://www.Peoplesfcu.com"
  },
  {
    "value": "64140",
    "label": "http://www.peoples-credit-union.com"
  },
  {
    "value": "66155",
    "label": "http://www.peoples-cu.com"
  },
  {
    "value": "62564",
    "label": "http://peoplescu.info"
  },
  {
    "value": "17464",
    "label": "http://peoplesadvfcu.org"
  },
  {
    "value": "3633",
    "label": "http://www.pafcu.org"
  },
  {
    "value": "60039",
    "label": "http://mypccu.com"
  },
  {
    "value": "24713",
    "label": "http://www.peoplescu.org"
  },
  {
    "value": "81200",
    "label": "http://peoplesenergycu.org"
  },
  {
    "value": "12956",
    "label": "http://Peoplestransportfcu.com"
  },
  {
    "value": "68442",
    "label": "http://www.peopleschoicecreditunion"
  },
  {
    "value": "65905",
    "label": "http://www.peoriabell.org"
  },
  {
    "value": "60873",
    "label": "http://peoriacu.com"
  },
  {
    "value": "61232",
    "label": "http://www.peoriahiwaycu.com"
  },
  {
    "value": "60863",
    "label": "http://www.ppecu.info"
  },
  {
    "value": "367",
    "label": "Pepcofcu.org"
  },
  {
    "value": "68719",
    "label": "http://www.pccu.com"
  },
  {
    "value": "68690",
    "label": "http://www.pfcu4me.com"
  },
  {
    "value": "66942",
    "label": "http://www.pfdcu.com"
  },
  {
    "value": "6277",
    "label": "http://phbfcu.com"
  },
  {
    "value": "7297",
    "label": "http://www.pheplefcu.org"
  },
  {
    "value": "24262",
    "label": "http://www.pbsfcu.org"
  },
  {
    "value": "7238",
    "label": "http://www.pfcu.com"
  },
  {
    "value": "5539",
    "label": "http://www.phillycreditunion.com"
  },
  {
    "value": "3291",
    "label": "http://www.picacreditunion.com"
  },
  {
    "value": "65781",
    "label": "http://www.piedmontcreditunionnc.co"
  },
  {
    "value": "60160",
    "label": "http://www.pacu.com"
  },
  {
    "value": "68266",
    "label": "http://www.pikespeakcu.com"
  },
  {
    "value": "61324",
    "label": "http://pillarcu.com"
  },
  {
    "value": "7316",
    "label": "http://www.pimafederal.org"
  },
  {
    "value": "9099",
    "label": "http://www.pinalcountyfcu.com"
  },
  {
    "value": "12601",
    "label": "http://www.pinefcu.com"
  },
  {
    "value": "4799",
    "label": "http://www.pinebeltfcu.org"
  },
  {
    "value": "1245",
    "label": "https://www.pbcbcu.org"
  },
  {
    "value": "10880",
    "label": "http://www.pinellasfcu.org"
  },
  {
    "value": "20415",
    "label": "http://www.pineyhillsfcu.org"
  },
  {
    "value": "15095",
    "label": "https://www.pinnaclefcu.com"
  },
  {
    "value": "67632",
    "label": "https://www.pinnaclecu.org"
  },
  {
    "value": "11242",
    "label": "https://www.pinpointfcu.org"
  },
  {
    "value": "9247",
    "label": "http://www.pioneerfcu.org"
  },
  {
    "value": "3576",
    "label": "http://www.pioneerwv.org"
  },
  {
    "value": "24682",
    "label": "http://pioneercreditunion.net"
  },
  {
    "value": "24818",
    "label": "http://www.pioneeronline.org"
  },
  {
    "value": "24443",
    "label": "https://www.pvcu.org"
  },
  {
    "value": "63157",
    "label": "www.pipefitterscu.org"
  },
  {
    "value": "1399",
    "label": "http://www.pbefcu.com"
  },
  {
    "value": "422",
    "label": "http://www.pittsburghfcu.org"
  },
  {
    "value": "1239",
    "label": "pche-fcu.org"
  },
  {
    "value": "234",
    "label": "http://www.pghffcu.org"
  },
  {
    "value": "19085",
    "label": "http://www.pittsfordfcu.org"
  },
  {
    "value": "12274",
    "label": "http://www.plainsfcu.com"
  },
  {
    "value": "61643",
    "label": "http://www.planites.org"
  },
  {
    "value": "24631",
    "label": "http://www.Platinumfcu.org"
  },
  {
    "value": "12873",
    "label": "http://www.plattscsdfcu.org"
  },
  {
    "value": "86756",
    "label": "http://www.pluscu.org"
  },
  {
    "value": "14850",
    "label": "http://www.pctfcu.org"
  },
  {
    "value": "63405",
    "label": "http://www.pocsimplotcu.com"
  },
  {
    "value": "21240",
    "label": "http://pmcfcu.org"
  },
  {
    "value": "66585",
    "label": "http://www.pbcu.com"
  },
  {
    "value": "63971",
    "label": "http://www.pointwestcu.com"
  },
  {
    "value": "19739",
    "label": "http://WWW.POLAM.ORG"
  },
  {
    "value": "21502",
    "label": "http://www.polamfcu.com"
  },
  {
    "value": "16401",
    "label": "http://www.policefcu.com"
  },
  {
    "value": "2551",
    "label": "http://www.pffcu.org"
  },
  {
    "value": "6628",
    "label": "https://pcuct.org"
  },
  {
    "value": "18393",
    "label": "http://WWW.POLICEMENSFCU.ORG"
  },
  {
    "value": "22592",
    "label": "www.psfcu.com"
  },
  {
    "value": "66597",
    "label": "http://www.pncu.com"
  },
  {
    "value": "22280",
    "label": "http://www.mypafcu.com"
  },
  {
    "value": "65039",
    "label": "http://www.polkcountycreditunion.co"
  },
  {
    "value": "67318",
    "label": "http://WWW.PBECU.COM"
  },
  {
    "value": "23004",
    "label": "http://www.popafcu.org"
  },
  {
    "value": "7023",
    "label": "http//www.pac-fcu.com"
  },
  {
    "value": "8413",
    "label": "http://www.patfcu.org"
  },
  {
    "value": "5116",
    "label": "http://www.portcityfcu.com"
  },
  {
    "value": "12351",
    "label": "http://www.portconnfcu.com"
  },
  {
    "value": "22049",
    "label": "http://WWW.ILAFCU.ORG"
  },
  {
    "value": "60782",
    "label": "http://www.pohcu.com"
  },
  {
    "value": "21029",
    "label": "http://www.phwfcu.org"
  },
  {
    "value": "14512",
    "label": "http://www.portterminalfcu.org"
  },
  {
    "value": "18635",
    "label": "http://www.pwfcu.org"
  },
  {
    "value": "6958",
    "label": "http://www.pwtfcu.org"
  },
  {
    "value": "15435",
    "label": "http://porterfcu.org"
  },
  {
    "value": "9288",
    "label": "http://www.pdx8fcu.com"
  },
  {
    "value": "90",
    "label": "http://WWW.POSTGAZETTEFCU.ORG"
  },
  {
    "value": "95742",
    "label": "www.pocumd.org"
  },
  {
    "value": "24712",
    "label": "http://www.poefcu.org"
  },
  {
    "value": "63069",
    "label": "http://www.poecu.org"
  },
  {
    "value": "62148",
    "label": "http://www.brlapcu.com"
  },
  {
    "value": "64145",
    "label": "http://www.yourpccu.org"
  },
  {
    "value": "64882",
    "label": "http://www.pecu24.org"
  },
  {
    "value": "951",
    "label": "http://www.postalfamilyfcu.com"
  },
  {
    "value": "97079",
    "label": "http://urmycu.com"
  },
  {
    "value": "86063",
    "label": "http://www.postcitycu.org"
  },
  {
    "value": "67554",
    "label": "http://www.postelcu.com"
  },
  {
    "value": "68700",
    "label": "https://www.p1fcu.org"
  },
  {
    "value": "12794",
    "label": "https://psfcupowell.com/"
  },
  {
    "value": "64037",
    "label": "http://www.powercu.org"
  },
  {
    "value": "61920",
    "label": "http://www.powercoopcu.com"
  },
  {
    "value": "68445",
    "label": "http://www.powerfi.org"
  },
  {
    "value": "620",
    "label": "http://poweronefcu.org"
  },
  {
    "value": "68042",
    "label": "http://www.powernetcu.org"
  },
  {
    "value": "370",
    "label": "http://ppgfcu.org"
  },
  {
    "value": "24816",
    "label": "http://www.tspcu.org"
  },
  {
    "value": "62340",
    "label": "www.preferredcu.org"
  },
  {
    "value": "64271",
    "label": "http://www.premiercu.org"
  },
  {
    "value": "68428",
    "label": "http://www.PremierAmerica.com"
  },
  {
    "value": "66760",
    "label": "http://www.yourpfcu.com"
  },
  {
    "value": "62574",
    "label": "http://www.pmcu.org"
  },
  {
    "value": "68540",
    "label": "http://premieronecu.org"
  },
  {
    "value": "2460",
    "label": "http://www.prescottfcu.org"
  },
  {
    "value": "24530",
    "label": "http://www.presidentsfcu.org"
  },
  {
    "value": "67507",
    "label": "https://www.prestigecu.org"
  },
  {
    "value": "23015",
    "label": "https://prestonfcu.com/"
  },
  {
    "value": "66449",
    "label": "http://www.primefinancialcu.org"
  },
  {
    "value": "67911",
    "label": "http://www.primesourcecu.org"
  },
  {
    "value": "489",
    "label": "http://www.primetrustcu.com"
  },
  {
    "value": "2131",
    "label": "https://www.primewayfcu.com"
  },
  {
    "value": "18271",
    "label": "http://www.princegeorgescfcu.org"
  },
  {
    "value": "21009",
    "label": "http://www.princetonfcu.org"
  },
  {
    "value": "18353",
    "label": "http://www.priorityfederal.us"
  },
  {
    "value": "67319",
    "label": "http://www.prioritycu.org"
  },
  {
    "value": "19655",
    "label": "http://www.priorityfirstfcu.org"
  },
  {
    "value": "60024",
    "label": "https://www.priorityonecu.org"
  },
  {
    "value": "67444",
    "label": "http://www.priorityonefl.org"
  },
  {
    "value": "663",
    "label": "http://www.priorityplusfcu.org"
  },
  {
    "value": "67476",
    "label": "http://www.prioritypostalcu.com"
  },
  {
    "value": "61457",
    "label": "http://www.plus4cu.org"
  },
  {
    "value": "19960",
    "label": "http://www.proctorfcu.com"
  },
  {
    "value": "96770",
    "label": "http://www.producerscu.com"
  },
  {
    "value": "17012",
    "label": "http://www.profedcu.org"
  },
  {
    "value": "62337",
    "label": "http://www.profirefighterscu.com"
  },
  {
    "value": "82769",
    "label": "https://www.ppocu.org"
  },
  {
    "value": "2711",
    "label": "http://www.profinancefcu.com"
  },
  {
    "value": "68255",
    "label": "http://progressionscu.org"
  },
  {
    "value": "21226",
    "label": "http://www.promedicafcu.com"
  },
  {
    "value": "20448",
    "label": "http://www.profcu.org"
  },
  {
    "value": "6072",
    "label": "http://prospectorsfcu.org"
  },
  {
    "value": "66652",
    "label": "http://www.myprospera.com"
  },
  {
    "value": "19522",
    "label": "http://www.providencecu.org"
  },
  {
    "value": "16364",
    "label": "http://www.providentfcu.com"
  },
  {
    "value": "66343",
    "label": "https://providentcu.org"
  },
  {
    "value": "909",
    "label": "http://www.prrsouthfork.com"
  },
  {
    "value": "96768",
    "label": "http://psecreditunion.org"
  },
  {
    "value": "64390",
    "label": "http://www.publicemployeescu.com"
  },
  {
    "value": "67428",
    "label": "http://www.pecutx.org"
  },
  {
    "value": "62812",
    "label": "http://www.publicsafetycu.org"
  },
  {
    "value": "11779",
    "label": "www.psfcunj.com"
  },
  {
    "value": "60683",
    "label": "http://www.pscunow.com"
  },
  {
    "value": "66461",
    "label": "http://pscu-wausau.com"
  },
  {
    "value": "64275",
    "label": "http://www.mypscu.com"
  },
  {
    "value": "12619",
    "label": "https://psepefcu.cmycu.com/"
  },
  {
    "value": "8993",
    "label": "http://PSPLAZAFCU.com"
  },
  {
    "value": "23020",
    "label": "http://www.pefcu.com"
  },
  {
    "value": "4509",
    "label": "http://www.pudfcu.com"
  },
  {
    "value": "1499",
    "label": "http://www.pgafcu.org"
  },
  {
    "value": "13939",
    "label": "http://www.prfedcu.com"
  },
  {
    "value": "12771",
    "label": "http://premployeesfcu.com"
  },
  {
    "value": "68204",
    "label": "http://www.psccu.org"
  },
  {
    "value": "9145",
    "label": "http://psrfcu.org"
  },
  {
    "value": "19307",
    "label": "https://www.purduefed.com"
  },
  {
    "value": "21107",
    "label": "http://www.psewv.com"
  },
  {
    "value": "24826",
    "label": "http://www.pyramidfcu.com"
  },
  {
    "value": "3947",
    "label": "http://WWW.qsidefcu.org"
  },
  {
    "value": "64991",
    "label": "http://www.quakeroatscu.com"
  },
  {
    "value": "68223",
    "label": "http://www.qualstarcu.com"
  },
  {
    "value": "67187",
    "label": "http://www.theq.org"
  },
  {
    "value": "19508",
    "label": "http://www.questfcu.com"
  },
  {
    "value": "66252",
    "label": "www.questacreditunion.org"
  },
  {
    "value": "67709",
    "label": "http://www.qcu.org"
  },
  {
    "value": "22769",
    "label": "http://quorumfcu.org"
  },
  {
    "value": "11962",
    "label": "http://RGFCU.COM"
  },
  {
    "value": "18550",
    "label": "http://www.rtpfcu.org"
  },
  {
    "value": "17117",
    "label": "http://riafcu.com"
  },
  {
    "value": "66622",
    "label": "rmecu.org"
  },
  {
    "value": "68723",
    "label": "https://www.radiantcu.org"
  },
  {
    "value": "340",
    "label": "http://www.radificu.org"
  },
  {
    "value": "24715",
    "label": "http://www.radiusfcu.com"
  },
  {
    "value": "14845",
    "label": "http://www.rahfcu.org"
  },
  {
    "value": "68688",
    "label": "https://railroadccu.com"
  },
  {
    "value": "62841",
    "label": "http://www.brecu.org"
  },
  {
    "value": "490",
    "label": "http://www.railswestcu.org"
  },
  {
    "value": "60787",
    "label": "http://www.railwaycu.com"
  },
  {
    "value": "61474",
    "label": "http://www.railwaycreditunion.com"
  },
  {
    "value": "1409",
    "label": "http://www.raiz.us"
  },
  {
    "value": "23722",
    "label": "http://www.raleighcountyefcu.com"
  },
  {
    "value": "7790",
    "label": "http://www.raleighcountyfcu.com"
  },
  {
    "value": "68615",
    "label": "rallycu.com"
  },
  {
    "value": "1204",
    "label": "http://www.ranchofcu.org"
  },
  {
    "value": "8111",
    "label": "http://www.rbfcu.org"
  },
  {
    "value": "12225",
    "label": "http://www.rapidesfederalcu.com"
  },
  {
    "value": "8074",
    "label": "http://WWW.RGHEFCU.COM"
  },
  {
    "value": "4726",
    "label": "http://www.rbfcu.coop"
  },
  {
    "value": "19732",
    "label": "http://ravallicountyfcu.org"
  },
  {
    "value": "13321",
    "label": "http://www.rfcu.net"
  },
  {
    "value": "62815",
    "label": "http://rlsccu.org"
  },
  {
    "value": "60392",
    "label": "http://rbsecu.com"
  },
  {
    "value": "21997",
    "label": "https://readingcreditunion.com"
  },
  {
    "value": "68196",
    "label": "http://www.redcanoecu.com"
  },
  {
    "value": "2585",
    "label": "http://www.redcrown.org"
  },
  {
    "value": "6144",
    "label": "http://redlakecfcu.com"
  },
  {
    "value": "63940",
    "label": "http://www.redoakcu.com"
  },
  {
    "value": "12082",
    "label": "http://www.redriverfcu.com"
  },
  {
    "value": "5029",
    "label": "http://www.rrcu.com"
  },
  {
    "value": "68560",
    "label": "http://www.redrocks.org"
  },
  {
    "value": "61778",
    "label": "http://www.redwingcu.com"
  },
  {
    "value": "60346",
    "label": "http://WWW.REDBRANDCU.COM"
  },
  {
    "value": "24867",
    "label": "http://www.redeemerfcu.org"
  },
  {
    "value": "1317",
    "label": "www.rediformfcu.com"
  },
  {
    "value": "9778",
    "label": "http://redlandsfcu.org"
  },
  {
    "value": "7544",
    "label": "https://www.redfcu.org"
  },
  {
    "value": "60793",
    "label": "http://www.redwoodcu.org"
  },
  {
    "value": "67486",
    "label": "http://REEDCREDITUNION.COM"
  },
  {
    "value": "12365",
    "label": "http://www.refugiocountyfcu.com"
  },
  {
    "value": "15072",
    "label": "http://www.regionalfcu.org"
  },
  {
    "value": "21126",
    "label": "http://www.rmcefcu.virtualcu.net"
  },
  {
    "value": "376",
    "label": "http://www.rmfcu.org"
  },
  {
    "value": "4120",
    "label": "http://www.reliancefcu.com"
  },
  {
    "value": "63169",
    "label": "http://www.reliancecu.org"
  },
  {
    "value": "20258",
    "label": "http://Reliantcu.com"
  },
  {
    "value": "68325",
    "label": "http://www.relyoncu.org"
  },
  {
    "value": "2008",
    "label": "remingtonfcu.com"
  },
  {
    "value": "7907",
    "label": "www.rcefcu.com"
  },
  {
    "value": "1166",
    "label": "http://www.1166fcu.org"
  },
  {
    "value": "24575",
    "label": "http://Resourcefcu.com"
  },
  {
    "value": "68439",
    "label": "http://r1cu.org"
  },
  {
    "value": "68305",
    "label": "https://www.myrescu.org/"
  },
  {
    "value": "9986",
    "label": "http://www.revfcu.com"
  },
  {
    "value": "67269",
    "label": "http://www.ricreditunion.org"
  },
  {
    "value": "3391",
    "label": "http://richlandfcu.com"
  },
  {
    "value": "22785",
    "label": "http://www.richland.k12.la.us/credi"
  },
  {
    "value": "1282",
    "label": "http://www.richheritage.org"
  },
  {
    "value": "66899",
    "label": "http://WWW.FirePoliceCU.Org"
  },
  {
    "value": "5419",
    "label": "http://WWW.RIDGEDALEFCU.ORG"
  },
  {
    "value": "7546",
    "label": "http://www.ridgelinecu.com"
  },
  {
    "value": "13944",
    "label": "http://www.riegelfcu.org"
  },
  {
    "value": "16190",
    "label": "http://www.rimcountryfcu.com"
  },
  {
    "value": "63183",
    "label": "http://www.rimrockcu.org"
  },
  {
    "value": "13161",
    "label": "http://www.rioblancoschoolsfcu.org"
  },
  {
    "value": "3963",
    "label": "http://www.riograndefcu.org"
  },
  {
    "value": "62573",
    "label": "http://riograndecu.org"
  },
  {
    "value": "61734",
    "label": "http://www.rgvcu.coop"
  },
  {
    "value": "67183",
    "label": "http://www.ripco.org"
  },
  {
    "value": "926",
    "label": "http://www.rcityfcu.org"
  },
  {
    "value": "64986",
    "label": "http://www.rivercommunitycu.org"
  },
  {
    "value": "13763",
    "label": "http://riverregionfcu.org"
  },
  {
    "value": "64283",
    "label": "http://www.rrcu.org"
  },
  {
    "value": "61152",
    "label": "http://r2rcu.com"
  },
  {
    "value": "24257",
    "label": "https://www.rivertownfcu.org"
  },
  {
    "value": "61664",
    "label": "http://www.rvcu.org"
  },
  {
    "value": "62123",
    "label": "http://www.myrivervalleycu.org"
  },
  {
    "value": "97109",
    "label": "http://www.rivervalleycu.org"
  },
  {
    "value": "8812",
    "label": "http://www.myrvcu.com"
  },
  {
    "value": "66346",
    "label": "http://www.rwcu.com"
  },
  {
    "value": "3757",
    "label": "www.riverbankfcu.com"
  },
  {
    "value": "60942",
    "label": "http://riverfallcu.com"
  },
  {
    "value": "13067",
    "label": "http://riverforkfcu.com"
  },
  {
    "value": "5856",
    "label": "http://riverfrontfcu.org"
  },
  {
    "value": "24860",
    "label": "http://www.riverlandfcu.org"
  },
  {
    "value": "65644",
    "label": "http://www.rivermarkcu.org"
  },
  {
    "value": "3775",
    "label": "https://www.riversidecommunityfcu.o"
  },
  {
    "value": "63791",
    "label": "http://www.riversidecu.com"
  },
  {
    "value": "473",
    "label": "http://www.rivertrustfcu.com"
  },
  {
    "value": "24904",
    "label": "http://www.riverviewcu.com"
  },
  {
    "value": "65448",
    "label": "http://myrcu.com"
  },
  {
    "value": "12890",
    "label": "http://www.riverwaysfcu.com"
  },
  {
    "value": "5604",
    "label": "http://www.rvccu.org"
  },
  {
    "value": "2414",
    "label": "http://www.robertsdairyefcu.org"
  },
  {
    "value": "68670",
    "label": "http://www.robinsfcu.org"
  },
  {
    "value": "817",
    "label": "http://www.rocmon.org"
  },
  {
    "value": "680",
    "label": "http://rasefcu.org"
  },
  {
    "value": "21020",
    "label": "http://www.rockcommfcu.com"
  },
  {
    "value": "68687",
    "label": "http://www.rockvalleycreditunion.or"
  },
  {
    "value": "19926",
    "label": "http://www.rockdalefcu.org"
  },
  {
    "value": "9799",
    "label": "http://www.rocketfcu.com"
  },
  {
    "value": "9662",
    "label": "http://www.rocketcityfcu.org"
  },
  {
    "value": "65955",
    "label": "http://WWW.RBCU.NET"
  },
  {
    "value": "62826",
    "label": "http://www.rmecu.net"
  },
  {
    "value": "24224",
    "label": "http://www.rfcu.com"
  },
  {
    "value": "15080",
    "label": "www.refcu.org"
  },
  {
    "value": "97110",
    "label": "http://www.rmcu.net"
  },
  {
    "value": "2847",
    "label": "http://www.rmlefcu.org"
  },
  {
    "value": "68660",
    "label": "http://www.roguecu.org"
  },
  {
    "value": "61128",
    "label": "http://www.rollingf.org"
  },
  {
    "value": "1810",
    "label": "http://romefcu.com"
  },
  {
    "value": "60707",
    "label": "http://romekraftcu.com"
  },
  {
    "value": "12624",
    "label": "http://www.rtfcu.com"
  },
  {
    "value": "10301",
    "label": "http://www.romneyfcu.com"
  },
  {
    "value": "9093",
    "label": "http://www.roswellcu.org"
  },
  {
    "value": "5982",
    "label": "www.route31cu.com"
  },
  {
    "value": "66834",
    "label": "http://www.rcu.org"
  },
  {
    "value": "8540",
    "label": "http://www.rsbellcofcu.com"
  },
  {
    "value": "61693",
    "label": "http://rccu.com"
  },
  {
    "value": "16444",
    "label": "http://www.refcu.coop"
  },
  {
    "value": "5387",
    "label": "http://www.russellcountryfcu.org"
  },
  {
    "value": "9237",
    "label": "http://www.rutgersfcu.org"
  },
  {
    "value": "6878",
    "label": "http://www.rvafinancial.com"
  },
  {
    "value": "16655",
    "label": "http://www.scifcu.com"
  },
  {
    "value": "13248",
    "label": "http://sshpecu.org"
  },
  {
    "value": "24824",
    "label": "https://www.scscu.com"
  },
  {
    "value": "22522",
    "label": "http://www.smalltubeproducts.com"
  },
  {
    "value": "64062",
    "label": "http://www.srcu4u.com"
  },
  {
    "value": "5866",
    "label": "http://www.sabinefcu.org"
  },
  {
    "value": "68324",
    "label": "http://www.sacovalley.org"
  },
  {
    "value": "62641",
    "label": "http://www.sactocu.org"
  },
  {
    "value": "3946",
    "label": "http://sacredheartcu.com"
  },
  {
    "value": "9988",
    "label": "https://www.safefed.org"
  },
  {
    "value": "68366",
    "label": "http://www.safecu.org"
  },
  {
    "value": "61598",
    "label": "http://safe1.org"
  },
  {
    "value": "61988",
    "label": "http://www.safeharborcu.org"
  },
  {
    "value": "97093",
    "label": "http://www.safeamerica.com"
  },
  {
    "value": "8132",
    "label": "http://safewayfcu.com"
  },
  {
    "value": "15317",
    "label": "http://www.sagaftrafcu.org"
  },
  {
    "value": "19593",
    "label": "http://www.sffcutulsa.org"
  },
  {
    "value": "9871",
    "label": "http://www.stlawrfcu.com"
  },
  {
    "value": "2583",
    "label": "http://www.snfcu.org"
  },
  {
    "value": "13933",
    "label": "http://www.svefcu.com"
  },
  {
    "value": "66349",
    "label": "http://www.salalcu.org"
  },
  {
    "value": "65346",
    "label": "http://www.salemschoolscreditunion."
  },
  {
    "value": "1127",
    "label": "www.salemvafcu.org"
  },
  {
    "value": "3080",
    "label": "http://WWW.SAFCU.COM"
  },
  {
    "value": "10699",
    "label": "http://www.sacfcu.org"
  },
  {
    "value": "61004",
    "label": "http://www.sdccu.com"
  },
  {
    "value": "24110",
    "label": "http://www.sdffcu.org"
  },
  {
    "value": "24542",
    "label": "http://www.sanfranciscofcu.com"
  },
  {
    "value": "97069",
    "label": "http://www.sffirecu.org"
  },
  {
    "value": "16547",
    "label": "http://WWW.LEECU.COM"
  },
  {
    "value": "66788",
    "label": "http://www.sjpecu.org"
  },
  {
    "value": "67163",
    "label": "http://SANJUANCU.COM"
  },
  {
    "value": "60110",
    "label": "http://www.sjmcu.com"
  },
  {
    "value": "62736",
    "label": "http://www.smcu.org"
  },
  {
    "value": "6326",
    "label": "http://smcfcu.org"
  },
  {
    "value": "11721",
    "label": "http://www.sanpatcu.com"
  },
  {
    "value": "11316",
    "label": "http://www.sandia.org"
  },
  {
    "value": "5851",
    "label": "http://www.slfcu.org"
  },
  {
    "value": "24520",
    "label": "http://www.safcu.org"
  },
  {
    "value": "6711",
    "label": "http://www.sbcreditunion.com"
  },
  {
    "value": "1909",
    "label": "http://www.sbtfcu.org"
  },
  {
    "value": "24299",
    "label": "http://www.sccfcu.org"
  },
  {
    "value": "64029",
    "label": "http://scccu.org"
  },
  {
    "value": "9971",
    "label": "http://www.santafefcu.com"
  },
  {
    "value": "12508",
    "label": "http://smaefcu.org"
  },
  {
    "value": "11780",
    "label": "http://www.srcfcu.org"
  },
  {
    "value": "60745",
    "label": "http://www.santeecoopercreditunion."
  },
  {
    "value": "9822",
    "label": "http://santochristocu.com"
  },
  {
    "value": "5252",
    "label": "https://saratogafcu.org"
  },
  {
    "value": "1669",
    "label": "http://sarcofcu.org"
  },
  {
    "value": "67364",
    "label": "http://savannahpostalcu.com"
  },
  {
    "value": "9408",
    "label": "http://www.ssfcuonline.org"
  },
  {
    "value": "444",
    "label": "http://shenangochinafcu.com"
  },
  {
    "value": "7835",
    "label": "http://www.scefcu.org"
  },
  {
    "value": "67747",
    "label": "http://www.mysccu.com"
  },
  {
    "value": "16278",
    "label": "http://schcofcu.org"
  },
  {
    "value": "67625",
    "label": "http://www.secu.slb.com"
  },
  {
    "value": "7423",
    "label": "http://www.schofieldfcu.org"
  },
  {
    "value": "13300",
    "label": "http://www.sd3fcu.org"
  },
  {
    "value": "66662",
    "label": "secusuperior.com"
  },
  {
    "value": "3526",
    "label": "http://www.Schoolsfcu.org"
  },
  {
    "value": "24212",
    "label": "https://www.schoolsfirstfcu.org"
  },
  {
    "value": "3790",
    "label": "http://scienceparkfcu.org"
  },
  {
    "value": "24029",
    "label": "https://www.scientfcu.org"
  },
  {
    "value": "66370",
    "label": "http://www.scu.org"
  },
  {
    "value": "67612",
    "label": "http://www.swecu.org"
  },
  {
    "value": "63386",
    "label": "http://www.mysacu.com"
  },
  {
    "value": "15591",
    "label": "http://www.scrantontimesefcu.com"
  },
  {
    "value": "10551",
    "label": "http://scurrycountyschoolfcu.com"
  },
  {
    "value": "7817",
    "label": "http://www.seaairfcu.org"
  },
  {
    "value": "15672",
    "label": "http://www.seacomm.org"
  },
  {
    "value": "12814",
    "label": "http://www.seawest.coop"
  },
  {
    "value": "4118",
    "label": "http://www.seaboardfcu.com"
  },
  {
    "value": "6008",
    "label": "http://seagovillefcu.com"
  },
  {
    "value": "19185",
    "label": "http://www.seaportfcu.org"
  },
  {
    "value": "854",
    "label": "http://www.seasonsfcu.org"
  },
  {
    "value": "68315",
    "label": "http://www.seattlecu.com"
  },
  {
    "value": "8936",
    "label": "http://svfcume.com"
  },
  {
    "value": "20496",
    "label": "http://www.secny.org"
  },
  {
    "value": "12877",
    "label": "http://www.securedadvantagefcu.com"
  },
  {
    "value": "61159",
    "label": "http://www.securitycu.org"
  },
  {
    "value": "5547",
    "label": "https://securityfirstcu.com"
  },
  {
    "value": "11065",
    "label": "http://www.ssfcu.org"
  },
  {
    "value": "2769",
    "label": "http://www.securityplusfcu.org"
  },
  {
    "value": "6370",
    "label": "http://www.securtrust.org"
  },
  {
    "value": "7405",
    "label": "http://www.segfcu.org"
  },
  {
    "value": "517",
    "label": "http://www.seius.org"
  },
  {
    "value": "63196",
    "label": "http://www.selco.org"
  },
  {
    "value": "3064",
    "label": "http://WWW.SELECTFCU.ORG"
  },
  {
    "value": "60531",
    "label": "http://www.selectcu.com"
  },
  {
    "value": "6079",
    "label": "http://www.selectsevencu.org"
  },
  {
    "value": "17831",
    "label": "http://www.selfmemorialfcu.net"
  },
  {
    "value": "7217",
    "label": "http://www.selfrelianceny.org"
  },
  {
    "value": "24802",
    "label": "http://www.self-helpfcu.org"
  },
  {
    "value": "66258",
    "label": "http://www.self-help.org"
  },
  {
    "value": "7346",
    "label": "http://www.selfreliance.com"
  },
  {
    "value": "24863",
    "label": "http://snifcu.org"
  },
  {
    "value": "10257",
    "label": "http://www.sentinelfcu.org"
  },
  {
    "value": "66439",
    "label": "http://www.sentrycu.org"
  },
  {
    "value": "65150",
    "label": "http://www.servecu.org"
  },
  {
    "value": "24921",
    "label": "https://www.servicecu.org"
  },
  {
    "value": "66530",
    "label": "http://www.service-cu.com"
  },
  {
    "value": "4038",
    "label": "http://www.service1fcu.com"
  },
  {
    "value": "22128",
    "label": "http://www.service1.org"
  },
  {
    "value": "67645",
    "label": "s1cu.org"
  },
  {
    "value": "77",
    "label": "http://www.servicefirstfcu.org"
  },
  {
    "value": "62484",
    "label": "http://www.socu.com"
  },
  {
    "value": "65770",
    "label": "http://www.service-pluscu.org"
  },
  {
    "value": "10933",
    "label": "http://www.servucu.com"
  },
  {
    "value": "4980",
    "label": "http://www.sesloc.org"
  },
  {
    "value": "24793",
    "label": "http://www.settlersfcu.com"
  },
  {
    "value": "61077",
    "label": "http://www.717cu.com"
  },
  {
    "value": "23060",
    "label": "http://www.scscreditunion.org"
  },
  {
    "value": "2056",
    "label": "http://www.swbfcu.com"
  },
  {
    "value": "60242",
    "label": "http://www.myshareadvantage.com"
  },
  {
    "value": "67477",
    "label": "http://www.srcu.net"
  },
  {
    "value": "61090",
    "label": "http://WWW.SHAREFAX.ORG"
  },
  {
    "value": "63098",
    "label": "https://www.sharepointcu.com/"
  },
  {
    "value": "67836",
    "label": "http://www.scucu.com"
  },
  {
    "value": "22458",
    "label": "http://www.sharonview.org"
  },
  {
    "value": "9497",
    "label": "http://www.stvafcu.virtualcu.net"
  },
  {
    "value": "66616",
    "label": "http://www.shebareacu.com"
  },
  {
    "value": "14733",
    "label": "http://shelbycountycu.com"
  },
  {
    "value": "9974",
    "label": "www.shelbycu.org"
  },
  {
    "value": "1941",
    "label": "http://www.shellfcu.org"
  },
  {
    "value": "5484",
    "label": "https://www.swsfcu.org"
  },
  {
    "value": "23355",
    "label": "http://www.shelterfcu.com"
  },
  {
    "value": "4374",
    "label": "www.sheridancreditunion.com"
  },
  {
    "value": "12259",
    "label": "http://www.sherwinfcu.org"
  },
  {
    "value": "61368",
    "label": "http://swcu.org"
  },
  {
    "value": "66444",
    "label": "http://www.shipbuilderscu.com"
  },
  {
    "value": "8349",
    "label": "http://www.shore2shorecu.com"
  },
  {
    "value": "66447",
    "label": "http://www.shorelinecu.org"
  },
  {
    "value": "62960",
    "label": "http://showmecu.org"
  },
  {
    "value": "13601",
    "label": "http://WWW.SHREWSBURYCU.COM"
  },
  {
    "value": "24811",
    "label": "http://www.shufordcu.org"
  },
  {
    "value": "6011",
    "label": "http://www.sfcuonline.org"
  },
  {
    "value": "68053",
    "label": "http://www.sierracentral.com"
  },
  {
    "value": "1000",
    "label": "http://www.sierrapacificfcu.org"
  },
  {
    "value": "5571",
    "label": "https://www.signalfinancialfcu.org"
  },
  {
    "value": "20061",
    "label": "http://www.signaturefcu.org"
  },
  {
    "value": "23949",
    "label": "http://www.signetfcu.com"
  },
  {
    "value": "63830",
    "label": "https://spscu.com"
  },
  {
    "value": "68453",
    "label": "http://WWW.SIKORSKYCU.org"
  },
  {
    "value": "97085",
    "label": "http://www.silverstatecu.com"
  },
  {
    "value": "68491",
    "label": "http://www.silveradocu.com"
  },
  {
    "value": "66678",
    "label": "http://www.simplicity.coop"
  },
  {
    "value": "4441",
    "label": "http://singsingfcu.org"
  },
  {
    "value": "8694",
    "label": "http://WWW.SRFCU.ORG"
  },
  {
    "value": "64417",
    "label": "http://www.siouxvalleyccu.com"
  },
  {
    "value": "8752",
    "label": "http://www.siouxvalleyco-opfcu.com"
  },
  {
    "value": "24327",
    "label": "www.siouxlandfederalcu.com"
  },
  {
    "value": "61682",
    "label": "http://www.siskiyoucu.org"
  },
  {
    "value": "23309",
    "label": "http://www.sisseton-wahpetonfcu.com"
  },
  {
    "value": "20279",
    "label": "http://www.shefcu.org"
  },
  {
    "value": "60279",
    "label": "http://www.siucu.org"
  },
  {
    "value": "65046",
    "label": "http://www.siuecu.org"
  },
  {
    "value": "15938",
    "label": "http://www.sixthavebaptistfcu.org"
  },
  {
    "value": "3306",
    "label": "http://sjpfcu.org"
  },
  {
    "value": "761",
    "label": "http://www.skyfcu.org"
  },
  {
    "value": "24808",
    "label": "http://www.skylacu.com"
  },
  {
    "value": "68100",
    "label": "http://www.skylinecreditunion.com"
  },
  {
    "value": "452",
    "label": "http://www.skylinefinancialfcu.com"
  },
  {
    "value": "6248",
    "label": "http://www.skyone.org"
  },
  {
    "value": "18917",
    "label": "http://www.skypointfcu.com"
  },
  {
    "value": "63300",
    "label": "https://www.skywardcu.com"
  },
  {
    "value": "61779",
    "label": "http://www.slocu.com"
  },
  {
    "value": "13941",
    "label": "http://WWW.SMFEDERAL.ORG"
  },
  {
    "value": "7772",
    "label": "http://www.smartfedcu.com"
  },
  {
    "value": "68513",
    "label": "http://smartchoicecreditunion.com"
  },
  {
    "value": "67927",
    "label": "http://www.smartcu.org"
  },
  {
    "value": "14934",
    "label": "http://www.smw104fcu.org"
  },
  {
    "value": "68676",
    "label": "http://www.smwfcu.com"
  },
  {
    "value": "8749",
    "label": "http://snakeriverfcu.com"
  },
  {
    "value": "68293",
    "label": "http://www.snofalls.com"
  },
  {
    "value": "68622",
    "label": "http://snocope.org/"
  },
  {
    "value": "61391",
    "label": "https://www.ssacu.com/"
  },
  {
    "value": "19433",
    "label": "http://softitecfcu.com"
  },
  {
    "value": "68061",
    "label": "http://www.solaritycu.org"
  },
  {
    "value": "9923",
    "label": "https://www.solfcu.org/"
  },
  {
    "value": "15451",
    "label": "http://www.soloncu.com"
  },
  {
    "value": "7846",
    "label": "http://solutionscu.org"
  },
  {
    "value": "16556",
    "label": "http://www.solutionsfirstcu.com"
  },
  {
    "value": "24239",
    "label": "http://www.somersetfcu.com"
  },
  {
    "value": "4524",
    "label": "http://www.sommfcu.com"
  },
  {
    "value": "3475",
    "label": "http://SSEFCU.ORG"
  },
  {
    "value": "14415",
    "label": "http://www.sonomafed.com"
  },
  {
    "value": "62325",
    "label": "http://www.soocoop.com"
  },
  {
    "value": "62776",
    "label": "http://www.soopercu.org"
  },
  {
    "value": "6772",
    "label": "http://www.sorgbaywest.com"
  },
  {
    "value": "24705",
    "label": "http://www.mysoundcu.org"
  },
  {
    "value": "66331",
    "label": "https://www.soundcu.com"
  },
  {
    "value": "68620",
    "label": "http://www.soundviewfcu.org"
  },
  {
    "value": "21202",
    "label": "http://WWW.SAFCU1.ORG"
  },
  {
    "value": "95052",
    "label": "http://southbaycu.com"
  },
  {
    "value": "13279",
    "label": "http://sbfcu.org"
  },
  {
    "value": "4487",
    "label": "http://www.sbtransitfcu.net"
  },
  {
    "value": "1022",
    "label": "http://www.scfederal.org"
  },
  {
    "value": "60715",
    "label": "http://scmccu.org"
  },
  {
    "value": "17636",
    "label": "http://WWW.SCNATIONALGUARDFCU.ORG"
  },
  {
    "value": "62143",
    "label": "http://southcentralcu.org"
  },
  {
    "value": "64222",
    "label": "http://www.creditunionmo.com"
  },
  {
    "value": "9376",
    "label": "http://southcoastilwufcu.com"
  },
  {
    "value": "24482",
    "label": "http://www.southmet.com"
  },
  {
    "value": "2682",
    "label": "http://www.southshopfcu.org"
  },
  {
    "value": "24704",
    "label": "http://www.southsidecommunityfcu.or"
  },
  {
    "value": "8237",
    "label": "http://www.southtexasfcu.com"
  },
  {
    "value": "7024",
    "label": "http://www.strfcu.com"
  },
  {
    "value": "17891",
    "label": "http://www.stownsfcu.com"
  },
  {
    "value": "66374",
    "label": "https://www.southbridgecu.com"
  },
  {
    "value": "22426",
    "label": "http://www.southcoastcu.org"
  },
  {
    "value": "68599",
    "label": "http://www.southeastfinancial.org"
  },
  {
    "value": "5839",
    "label": "http://www.slvhcsfcu.com"
  },
  {
    "value": "7628",
    "label": "http://www.smsefcu.com"
  },
  {
    "value": "67716",
    "label": "http://southeastcreditunion.com"
  },
  {
    "value": "68673",
    "label": "http://www.southeasterncu.com"
  },
  {
    "value": "9897",
    "label": "http://www.seafcu.com"
  },
  {
    "value": "96674",
    "label": "http://southeasternohiocu.org"
  },
  {
    "value": "9214",
    "label": "http://www.southernfederalcu.org"
  },
  {
    "value": "66980",
    "label": "http://www.southerncu.com"
  },
  {
    "value": "9107",
    "label": "http://www.665-7000.com"
  },
  {
    "value": "24885",
    "label": "http://www.socofcu.org"
  },
  {
    "value": "23327",
    "label": "http://www.SouthernGasFCU.com"
  },
  {
    "value": "66707",
    "label": "http://southernlakescu.org"
  },
  {
    "value": "3126",
    "label": "http://www.myfavoritecu.com"
  },
  {
    "value": "67403",
    "label": "http://www.southernpinecu.org"
  },
  {
    "value": "5974",
    "label": "http://www.southernsecurity.org"
  },
  {
    "value": "67660",
    "label": "http://www.southernstarcu.org"
  },
  {
    "value": "2068",
    "label": "http://www.stpfcu.com"
  },
  {
    "value": "9105",
    "label": "http://www.smfcu.net"
  },
  {
    "value": "15815",
    "label": "http://www.southlandfcu.com"
  },
  {
    "value": "68415",
    "label": "http://www.southlandcu.org"
  },
  {
    "value": "68667",
    "label": "http://www.southpointfinancial.com"
  },
  {
    "value": "964",
    "label": "http://www.sfcunm.org"
  },
  {
    "value": "67550",
    "label": "https://www.southwest66.com"
  },
  {
    "value": "22157",
    "label": "http://www.swacu.org"
  },
  {
    "value": "3278",
    "label": "http://www.swcfcu.org"
  },
  {
    "value": "68047",
    "label": "http://WWW.SWCSECU.US"
  },
  {
    "value": "15327",
    "label": "http://swfinancial.org"
  },
  {
    "value": "60533",
    "label": "http://www.southwestheritagecu.com"
  },
  {
    "value": "62756",
    "label": "http://www.swlacu.com"
  },
  {
    "value": "4401",
    "label": "http://www.swmcfcu.org"
  },
  {
    "value": "12271",
    "label": "http://www.swofcu.com"
  },
  {
    "value": "10913",
    "label": "https://www.swrcfcu.com"
  },
  {
    "value": "60666",
    "label": "http://www.sovitacu.org"
  },
  {
    "value": "68737",
    "label": "http://www.spaceagefcu.coop"
  },
  {
    "value": "19355",
    "label": "http://www.spaceagetulsafcu.com"
  },
  {
    "value": "61135",
    "label": "http://www.spacecitycu.com"
  },
  {
    "value": "67297",
    "label": "http://www.sccu.com"
  },
  {
    "value": "60686",
    "label": "http://www.spccu.org"
  },
  {
    "value": "61438",
    "label": "http://www.spcocu.org"
  },
  {
    "value": "14522",
    "label": "http://www.specialmetalsfcu.com"
  },
  {
    "value": "5380",
    "label": "http://www.spectracu.com"
  },
  {
    "value": "14884",
    "label": "http://www.spnfcu.org"
  },
  {
    "value": "361",
    "label": "https://www.spero.financial"
  },
  {
    "value": "68651",
    "label": "http://www.myspire.com"
  },
  {
    "value": "60369",
    "label": "http://www.spiritfinancialcu.org"
  },
  {
    "value": "13583",
    "label": "http://www.spiritofak.com"
  },
  {
    "value": "24724",
    "label": "http://www.spiritofamericafcu.org"
  },
  {
    "value": "62998",
    "label": "http://www.spojniacreditunion.org"
  },
  {
    "value": "68283",
    "label": "http://www.sccu.net"
  },
  {
    "value": "68205",
    "label": "http://www.firecu.net"
  },
  {
    "value": "24451",
    "label": "http://smfcu.org"
  },
  {
    "value": "68203",
    "label": "http://www.stcu.org"
  },
  {
    "value": "65424",
    "label": "http://www.scecu.org"
  },
  {
    "value": "11872",
    "label": "http://SRIFCU.ORG"
  },
  {
    "value": "24410",
    "label": "https://www.srpfcu.org"
  },
  {
    "value": "12654",
    "label": "http://www.srufcu.org"
  },
  {
    "value": "16755",
    "label": "http://www.SSMOKEFCU.COM"
  },
  {
    "value": "97066",
    "label": "http://www.stjoevalleycu.com"
  },
  {
    "value": "67605",
    "label": "http://www.stannes.com"
  },
  {
    "value": "6184",
    "label": "http://www.rc.net/arlington/stann/c"
  },
  {
    "value": "8616",
    "label": "http://www.stanthonyofpaduafcu.com"
  },
  {
    "value": "68697",
    "label": "https://www.stcloudfcu.coop"
  },
  {
    "value": "3462",
    "label": "http://stcolmanaffiliatesfcu.com"
  },
  {
    "value": "7219",
    "label": "http://www.stfranciscu.org"
  },
  {
    "value": "60680",
    "label": "http://www.stgregorycu.org"
  },
  {
    "value": "66296",
    "label": "www.sthelenacreditunion.com"
  },
  {
    "value": "62624",
    "label": "http://WWW.STJPCU.COM"
  },
  {
    "value": "67959",
    "label": "http://www.stjeanscu.com"
  },
  {
    "value": "21355",
    "label": "http://www.stjohnunitedfcu.com"
  },
  {
    "value": "18651",
    "label": "http://www.sjmcfcu.org"
  },
  {
    "value": "67708",
    "label": "http://SJTCREDITUNION.COM"
  },
  {
    "value": "4884",
    "label": "WWW.STJFCU.ORG"
  },
  {
    "value": "65484",
    "label": "http://www.stjudecreditunion.org"
  },
  {
    "value": "60400",
    "label": "http://www.stlouiscommunity.com"
  },
  {
    "value": "64425",
    "label": "http://www.stlpolicecu.com"
  },
  {
    "value": "61566",
    "label": "http://st.markcu.org"
  },
  {
    "value": "67864",
    "label": "http://www.stmaryscu.org"
  },
  {
    "value": "63829",
    "label": "http://www.stmarysbank.com"
  },
  {
    "value": "8915",
    "label": "http://www.stmichaelsfcu.com"
  },
  {
    "value": "19017",
    "label": "http://www.StPatsCU.com"
  },
  {
    "value": "24358",
    "label": "http://www.stpaulfcu.org"
  },
  {
    "value": "18090",
    "label": "http://www.spxfcu.org"
  },
  {
    "value": "11942",
    "label": "http://www.sttammanyfederalcu.org"
  },
  {
    "value": "7970",
    "label": "https://stthomasfcu.com/"
  },
  {
    "value": "68071",
    "label": "www.stthomascu.org"
  },
  {
    "value": "20629",
    "label": "http://www.svmcfcu.org"
  },
  {
    "value": "687",
    "label": "http://www.stagepointfcu.com"
  },
  {
    "value": "81416",
    "label": "http://www.staleycu.com"
  },
  {
    "value": "66002",
    "label": "http://www.shccu.org"
  },
  {
    "value": "13392",
    "label": "https://www.sfcu.org"
  },
  {
    "value": "4088",
    "label": "http://www.stanwoodareafcu.org"
  },
  {
    "value": "64706",
    "label": "http://www.starchoicecu.org"
  },
  {
    "value": "68300",
    "label": "http://www.starcu.org"
  },
  {
    "value": "67512",
    "label": "http://www.staroftexascu.org"
  },
  {
    "value": "68465",
    "label": "http://www.starone.org"
  },
  {
    "value": "3182",
    "label": "http://www.star-techfcu.com"
  },
  {
    "value": "911",
    "label": "http://www.star.coop"
  },
  {
    "value": "14120",
    "label": "http://www.starkcu.org"
  },
  {
    "value": "18559",
    "label": "http://www.starrfcu.com"
  },
  {
    "value": "8006",
    "label": "http://WWW.STATEAGENCIESFCU.COM"
  },
  {
    "value": "16207",
    "label": "http://www.statecs.org"
  },
  {
    "value": "435",
    "label": "http://www.sdfcu.org"
  },
  {
    "value": "65513",
    "label": "http://www.secunm.org"
  },
  {
    "value": "66310",
    "label": "http://www.ncsecu.org"
  },
  {
    "value": "65259",
    "label": "https://stateemployeescu.virtualcu.net/"
  },
  {
    "value": "66330",
    "label": "http://WWW.SECUMD.ORG"
  },
  {
    "value": "1273",
    "label": "http://www.statefarmfcu.com"
  },
  {
    "value": "13432",
    "label": "http://www.patrolcu.com"
  },
  {
    "value": "60048",
    "label": "http://www.cspcu.org"
  },
  {
    "value": "6369",
    "label": "http://www.statewidefcu.org"
  },
  {
    "value": "67801",
    "label": "http://www.stationerycreditunion.co"
  },
  {
    "value": "4828",
    "label": "www.steelstrongcfcu.org"
  },
  {
    "value": "17555",
    "label": "http://www.steelvalleyfcu.org"
  },
  {
    "value": "24845",
    "label": "http://www.sscreditunion.org"
  },
  {
    "value": "2840",
    "label": "http://www.sterlingcreditunion.org"
  },
  {
    "value": "11273",
    "label": "http://www.sufcu.org"
  },
  {
    "value": "6225",
    "label": "http://steubencitizensfcu.com"
  },
  {
    "value": "24036",
    "label": "http://www.stewartscu.com"
  },
  {
    "value": "15393",
    "label": "http://www.smefcu.org"
  },
  {
    "value": "68231",
    "label": "http://www.svcu.com"
  },
  {
    "value": "7428",
    "label": "http://www.mi-safcu.org"
  },
  {
    "value": "6032",
    "label": "http://www.stratacu.org"
  },
  {
    "value": "21319",
    "label": "http://www.strategicfcu.org"
  },
  {
    "value": "62939",
    "label": "http://www.streatorccu.org"
  },
  {
    "value": "68226",
    "label": "http://www.socu.org"
  },
  {
    "value": "8418",
    "label": "http://STRIPSTEELCFCU.COM"
  },
  {
    "value": "5922",
    "label": "http://subiacofcu.org"
  },
  {
    "value": "12808",
    "label": "https://www.successcreditunion.com"
  },
  {
    "value": "17914",
    "label": "www.suffolkcu.org"
  },
  {
    "value": "3558",
    "label": "sugarvalleyfcu.org"
  },
  {
    "value": "16360",
    "label": "http://www.sumafcu.org"
  },
  {
    "value": "7182",
    "label": "https://www.summitfcu.com"
  },
  {
    "value": "63020",
    "label": "http://summitcu.org"
  },
  {
    "value": "67190",
    "label": "http://www.summitcreditunion.com"
  },
  {
    "value": "19973",
    "label": "http://www.shrfcu.com"
  },
  {
    "value": "64394",
    "label": "http://mysrcu.com"
  },
  {
    "value": "7064",
    "label": "http://www.sunfederalcu.org"
  },
  {
    "value": "67332",
    "label": "http://www.suncu.org"
  },
  {
    "value": "9296",
    "label": "https://www.suncommunity.com"
  },
  {
    "value": "6160",
    "label": "http://www.suneast.org"
  },
  {
    "value": "5359",
    "label": "http://sunpacific.org"
  },
  {
    "value": "8905",
    "label": "www.sunbeltfcu.org"
  },
  {
    "value": "68645",
    "label": "http://www.suncoastcreditunion.com"
  },
  {
    "value": "22340",
    "label": "http://suncompefcu.virtualcu.net"
  },
  {
    "value": "7610",
    "label": "http://www.sunflowerrocks.com"
  },
  {
    "value": "4393",
    "label": "http://sunkistefcu.com"
  },
  {
    "value": "8733",
    "label": "https://www.sunlightfcu.com"
  },
  {
    "value": "68710",
    "label": "http://sunmark.org"
  },
  {
    "value": "61995",
    "label": "http://www.sunrisefamilycu.org"
  },
  {
    "value": "13416",
    "label": "http://www.sspfcu.com"
  },
  {
    "value": "2226",
    "label": "http://www.mysunwest.com"
  },
  {
    "value": "65082",
    "label": "http://sunwestecu.com"
  },
  {
    "value": "21851",
    "label": "http://WWW.SUNYFREDONIAFCU.COM"
  },
  {
    "value": "67173",
    "label": "http://www.superiorchoice.com"
  },
  {
    "value": "60215",
    "label": "http://www.superiorcu.org"
  },
  {
    "value": "68665",
    "label": "http://www.superiorcu.com"
  },
  {
    "value": "18242",
    "label": "https://www.svfcu.org"
  },
  {
    "value": "1086",
    "label": "http://www.suwanneeriverfcu.com"
  },
  {
    "value": "11470",
    "label": "http://WWW.SWEETHOMEFCU.ORG"
  },
  {
    "value": "11377",
    "label": "http://www.sweetwatercredit.com"
  },
  {
    "value": "61035",
    "label": "http://swindelldresslercu.com"
  },
  {
    "value": "5241",
    "label": "http://WWW.SYCAMOREFCU.COM"
  },
  {
    "value": "17733",
    "label": "http://www.sylvaniaareafcu.com"
  },
  {
    "value": "23605",
    "label": "http://www.synergyfcu.org"
  },
  {
    "value": "60657",
    "label": "www.synergypartnerscu.org"
  },
  {
    "value": "23865",
    "label": "http://www.cooperativefederal.org"
  },
  {
    "value": "6627",
    "label": "http://www.syrfirecu.com"
  },
  {
    "value": "18462",
    "label": "http://www.teafcu.org"
  },
  {
    "value": "4488",
    "label": "https://bsdc.onlinecu.com/thpfcu/#/"
  },
  {
    "value": "15175",
    "label": "http://www.tmhfcu.org"
  },
  {
    "value": "61879",
    "label": "www.ticreditunion.org"
  },
  {
    "value": "11671",
    "label": "http://www.tesfcu.org"
  },
  {
    "value": "68229",
    "label": "http://tlcu23.com"
  },
  {
    "value": "60423",
    "label": "http://WWW.TACUMI.COM"
  },
  {
    "value": "95821",
    "label": "http://www.taleriscu.org"
  },
  {
    "value": "352",
    "label": "http://www.tampabayfederal.com"
  },
  {
    "value": "4395",
    "label": "http://www.tpcu.org"
  },
  {
    "value": "4787",
    "label": "http://www.tandemfcu.com"
  },
  {
    "value": "60475",
    "label": "http://www.tangiteachers.com"
  },
  {
    "value": "67165",
    "label": "http://www.tannercu.com"
  },
  {
    "value": "68298",
    "label": "http://www.tapcocu.org"
  },
  {
    "value": "96796",
    "label": "http://www.tappancu.com"
  },
  {
    "value": "60051",
    "label": "http://www.tccu-tx.com"
  },
  {
    "value": "5602",
    "label": "http://www.tauntonfcu.com"
  },
  {
    "value": "23803",
    "label": "https://taupaboston.com"
  },
  {
    "value": "66388",
    "label": "http://taylorcu.org"
  },
  {
    "value": "60335",
    "label": "http://www.tccu.org"
  },
  {
    "value": "63672",
    "label": "http://www.tcsecu.com"
  },
  {
    "value": "61795",
    "label": "http://www.tbacu.com"
  },
  {
    "value": "65384",
    "label": "http://tcpcu.org"
  },
  {
    "value": "12957",
    "label": "https://www.tctfcu.org"
  },
  {
    "value": "8116",
    "label": "http://www.teachersfcu.org"
  },
  {
    "value": "65774",
    "label": "http://www.tcuok.org"
  },
  {
    "value": "66877",
    "label": "http://www.tcubeloit.org"
  },
  {
    "value": "21639",
    "label": "http://www.team-wheelfcu.org"
  },
  {
    "value": "20147",
    "label": "http://www.teamffcu.org"
  },
  {
    "value": "14829",
    "label": "http://WWW.TEAMFIRSTFCU.ORG"
  },
  {
    "value": "62141",
    "label": "http://WWW.TEAMONECU.ORG"
  },
  {
    "value": "61791",
    "label": "http://Teamsterscreditunion.com"
  },
  {
    "value": "61824",
    "label": "http://mnteamsterscu.com"
  },
  {
    "value": "14196",
    "label": "http://www.tcu37.com"
  },
  {
    "value": "65606",
    "label": "teamsterslocal238cu.org"
  },
  {
    "value": "20957",
    "label": "http://teamsters697creditunion.com"
  },
  {
    "value": "19430",
    "label": "http://teamsters92fcu.com"
  },
  {
    "value": "2021",
    "label": "http://TEANECKFCU.COM"
  },
  {
    "value": "68225",
    "label": "http://www.techcu.org"
  },
  {
    "value": "8230",
    "label": "https://www.technicolorfcu.org"
  },
  {
    "value": "68383",
    "label": "http://www.techcu.com"
  },
  {
    "value": "60390",
    "label": "http://www.teepakcu.com"
  },
  {
    "value": "19401",
    "label": "http://TEGFCU.COM"
  },
  {
    "value": "66319",
    "label": "http://www.telcu.com"
  },
  {
    "value": "63545",
    "label": "http://www.telcoccu.org"
  },
  {
    "value": "66351",
    "label": "http://www.telcoplus.org"
  },
  {
    "value": "7999",
    "label": "http://www.telcoroswell.org"
  },
  {
    "value": "63218",
    "label": "http://www.telcotriad.org"
  },
  {
    "value": "24202",
    "label": "http://www.telcoe.com"
  },
  {
    "value": "63116",
    "label": "http://www.telcommcu.com"
  },
  {
    "value": "62848",
    "label": "http://www.telhio.org"
  },
  {
    "value": "146",
    "label": "http://www.teluwatt.com"
  },
  {
    "value": "67573",
    "label": "http://www.templecreditunion.com"
  },
  {
    "value": "6726",
    "label": "http://www.t-ifcu.com"
  },
  {
    "value": "63648",
    "label": "https://www.tendtocu.com"
  },
  {
    "value": "67971",
    "label": "TDOSCU.COM"
  },
  {
    "value": "68101",
    "label": "http://www.tnecu.org"
  },
  {
    "value": "5318",
    "label": "http://www.mymembersfirst.org"
  },
  {
    "value": "1520",
    "label": "http://tvfcu.com"
  },
  {
    "value": "13926",
    "label": "http://www.tewksburyfcu.com"
  },
  {
    "value": "67579",
    "label": "http://tmcu.com"
  },
  {
    "value": "3938",
    "label": "http://www.texansky.com"
  },
  {
    "value": "60806",
    "label": "http://WWW.TEXANSCU.ORG"
  },
  {
    "value": "24567",
    "label": "http://WWW.GOTEXAR.COM"
  },
  {
    "value": "5822",
    "label": "http://WWW.TEXARKANATERMINALFCU.COM"
  },
  {
    "value": "23184",
    "label": "http://www.tapfcu.coop"
  },
  {
    "value": "67465",
    "label": "http://www.texasbaycu.org"
  },
  {
    "value": "61306",
    "label": "http://WWW.TXBCU.COM"
  },
  {
    "value": "1879",
    "label": "https://www.texasfcu.org"
  },
  {
    "value": "60705",
    "label": "http://www.tdecu.org"
  },
  {
    "value": "67511",
    "label": "http://www.txdpscu.org"
  },
  {
    "value": "67452",
    "label": "http://thcu.org"
  },
  {
    "value": "18218",
    "label": "http://www.texasleefcu.net"
  },
  {
    "value": "5337",
    "label": "http://www.fwtpfcu.org"
  },
  {
    "value": "3828",
    "label": "http://www.texasplainsfederal.org"
  },
  {
    "value": "12858",
    "label": "https://www.texastechfcu.org"
  },
  {
    "value": "67490",
    "label": "http://www.textelcu.org"
  },
  {
    "value": "68434",
    "label": "http://www.texastrustcu.org"
  },
  {
    "value": "2322",
    "label": "http://www.texasgulffcu.org"
  },
  {
    "value": "68608",
    "label": "http://www.texell.org"
  },
  {
    "value": "11576",
    "label": "https://Texhillco.org"
  },
  {
    "value": "4292",
    "label": "http://www.texomafcu.com"
  },
  {
    "value": "61527",
    "label": "http://www.TexomaCU.com"
  },
  {
    "value": "11670",
    "label": "http://www.texomaeducators.com"
  },
  {
    "value": "17953",
    "label": "http://www.texstarfcu.com"
  },
  {
    "value": "16067",
    "label": "http://www.andoverfcu.com"
  },
  {
    "value": "477",
    "label": "https://web.atlfedcu.com"
  },
  {
    "value": "13852",
    "label": "http://www.bridgewaycu.org"
  },
  {
    "value": "63177",
    "label": "http://WWW.CUCATHOLIC.COM"
  },
  {
    "value": "10794",
    "label": "http://www.countyfcu.org"
  },
  {
    "value": "63541",
    "label": "www.cuforall.com"
  },
  {
    "value": "14369",
    "label": "http://www.districtcreditunion.com"
  },
  {
    "value": "60728",
    "label": "http://www.familycu.com"
  },
  {
    "value": "11364",
    "label": "http://www.familyfirstfcu.org"
  },
  {
    "value": "24862",
    "label": "http://thefinestfcu.org"
  },
  {
    "value": "24875",
    "label": "http://www.ffcu.org"
  },
  {
    "value": "19567",
    "label": "http://www.thefloristfcu.org"
  },
  {
    "value": "10283",
    "label": "http://www.focusok.com"
  },
  {
    "value": "61650",
    "label": "http://www.golden1.com"
  },
  {
    "value": "10800",
    "label": "http://www.thefcu.com"
  },
  {
    "value": "22456",
    "label": "http://theheartcentercu.com"
  },
  {
    "value": "21794",
    "label": "http://www.theinfirmaryfcu.com"
  },
  {
    "value": "22747",
    "label": "http://www.thelocalfcu.com"
  },
  {
    "value": "63342",
    "label": "http://www.thelocalcreditunion.com"
  },
  {
    "value": "24943",
    "label": "https://www.tmsfcu.com/"
  },
  {
    "value": "24939",
    "label": "http://www.municipalcu.com"
  },
  {
    "value": "10729",
    "label": "www.nhpdfcu.com"
  },
  {
    "value": "6",
    "label": "http://www.noffcu.org"
  },
  {
    "value": "6187",
    "label": "http://www.onefcu.com"
  },
  {
    "value": "65861",
    "label": "http://www.peoplescu.com"
  },
  {
    "value": "8290",
    "label": "http://www.tpfcu.com"
  },
  {
    "value": "60968",
    "label": "http://www.thepolicecu.org"
  },
  {
    "value": "68638",
    "label": "http://www.southernonline.org"
  },
  {
    "value": "67222",
    "label": "http://WWW.WVPECU.ORG"
  },
  {
    "value": "4580",
    "label": "http://WWW.SUMMITFCU.ORG"
  },
  {
    "value": "66886",
    "label": "http://TTCU.ORG"
  },
  {
    "value": "21305",
    "label": "http://www.tufcu.org"
  },
  {
    "value": "60726",
    "label": "http://www.wtncu.org"
  },
  {
    "value": "67564",
    "label": "https://thewrightcu.org"
  },
  {
    "value": "7770",
    "label": "http://www.thinkwisecu.org"
  },
  {
    "value": "12185",
    "label": "http://www.thiokolfcu.org"
  },
  {
    "value": "62196",
    "label": "https://www.thornapplecu.com"
  },
  {
    "value": "159",
    "label": "http://www.3riversfcu.org"
  },
  {
    "value": "3631",
    "label": "http://www.thrivecu.org"
  },
  {
    "value": "24849",
    "label": "http://www.thriventcu.com"
  },
  {
    "value": "7289",
    "label": "http://tbafcu.com"
  },
  {
    "value": "13121",
    "label": "http://www.tidemarkfcu.org"
  },
  {
    "value": "21413",
    "label": "https://www.timberlandfcu.org"
  },
  {
    "value": "11678",
    "label": "http://www.timberlinefcu.org"
  },
  {
    "value": "24524",
    "label": "https://www.tinkerfcu.org"
  },
  {
    "value": "8654",
    "label": "www.TippecanoeFCU.com"
  },
  {
    "value": "1882",
    "label": "www.titanfcu.com"
  },
  {
    "value": "60509",
    "label": "http://www.tlccu.org"
  },
  {
    "value": "68286",
    "label": "http://tlcufinancial.org"
  },
  {
    "value": "67666",
    "label": "http://www.tnconnectcu.org"
  },
  {
    "value": "1077",
    "label": "http://www.tvtfcu.org"
  },
  {
    "value": "62715",
    "label": "www.togethercu.org"
  },
  {
    "value": "3748",
    "label": "http://www.tfffcu.org"
  },
  {
    "value": "9189",
    "label": "http://www.toledometro.com"
  },
  {
    "value": "3720",
    "label": "http://www.tpolcu.com"
  },
  {
    "value": "24578",
    "label": "http://www.toledourban.net"
  },
  {
    "value": "66615",
    "label": "http://www.tacuonline.com"
  },
  {
    "value": "17681",
    "label": "http://www.tombigb.virtualcu.net"
  },
  {
    "value": "22962",
    "label": "http://www.tefcu.com"
  },
  {
    "value": "22464",
    "label": "http://Toncomfcu.com"
  },
  {
    "value": "22167",
    "label": "http://tvfcubatavia.com"
  },
  {
    "value": "15523",
    "label": "http://www.tongassfcu.com"
  },
  {
    "value": "3655",
    "label": "http://www.toptierfcu.org"
  },
  {
    "value": "63042",
    "label": "http://tfcu9.org"
  },
  {
    "value": "67785",
    "label": "https://toppdcu.com"
  },
  {
    "value": "68731",
    "label": "http://www.toplinecu.com"
  },
  {
    "value": "15438",
    "label": "www.topmarkfcu.com"
  },
  {
    "value": "14754",
    "label": "http://topsidefcu.org"
  },
  {
    "value": "10099",
    "label": "http://www.torchlakefederal.com"
  },
  {
    "value": "21644",
    "label": "http://www.torofcu.org"
  },
  {
    "value": "12543",
    "label": "http://www.torranceccu.org"
  },
  {
    "value": "14003",
    "label": "http://tmtfcu.org"
  },
  {
    "value": "22369",
    "label": "http://www.totalchoicefcu.com"
  },
  {
    "value": "62245",
    "label": "http://www.tccu.us"
  },
  {
    "value": "22581",
    "label": "http://www.tca-nola.org/creditunion"
  },
  {
    "value": "8333",
    "label": "http://towerfcu.org"
  },
  {
    "value": "8486",
    "label": "https://www.tcfcu.com"
  },
  {
    "value": "60790",
    "label": "http://www.townandcountry.org"
  },
  {
    "value": "65168",
    "label": "http://www.towncountrycu.com"
  },
  {
    "value": "23717",
    "label": "http://WWW.TOWNOFCHEEKTOWAGAFCU.COM"
  },
  {
    "value": "15539",
    "label": "http://www.tohefcu.org"
  },
  {
    "value": "96658",
    "label": "http://www.towpathcu.com"
  },
  {
    "value": "1133",
    "label": "http://www.trademarkfcu.org"
  },
  {
    "value": "24674",
    "label": "http://www.tradesandlabor.com"
  },
  {
    "value": "644",
    "label": "http://www.trailheadcu.org"
  },
  {
    "value": "9219",
    "label": "http://www.tfcunow.com"
  },
  {
    "value": "67835",
    "label": "http://www.transtexas.coop"
  },
  {
    "value": "68666",
    "label": "http://www.transcendcu.com"
  },
  {
    "value": "15067",
    "label": "http://transfigurationfcu.org"
  },
  {
    "value": "21831",
    "label": "http://WWW.ATU1181.ORG"
  },
  {
    "value": "798",
    "label": "http://www.divbfcu.com"
  },
  {
    "value": "3764",
    "label": "http://www.tefcu.org"
  },
  {
    "value": "24539",
    "label": "tofcu.org"
  },
  {
    "value": "17885",
    "label": "http://www.twfcu.org"
  },
  {
    "value": "3140",
    "label": "http://www.transfcu.com"
  },
  {
    "value": "7482",
    "label": "http://www.transtarfcu.org"
  },
  {
    "value": "66705",
    "label": "http://www.transwestcu.com"
  },
  {
    "value": "6844",
    "label": "http://www.tcfedcu.org"
  },
  {
    "value": "68429",
    "label": "http://www.traviscu.org"
  },
  {
    "value": "67604",
    "label": "https://www.tccu.net/"
  },
  {
    "value": "24703",
    "label": "https://www.traxcu.com"
  },
  {
    "value": "162",
    "label": "http://www.tdfcu.org"
  },
  {
    "value": "68349",
    "label": "http://www.tremontcu.org"
  },
  {
    "value": "794",
    "label": "http://www.triboro-fcu.org"
  },
  {
    "value": "6269",
    "label": "http://www.tcafcu.org"
  },
  {
    "value": "8885",
    "label": "http://www.tsafcu.com"
  },
  {
    "value": "6070",
    "label": "http://www.tristaterailfcu.com"
  },
  {
    "value": "13334",
    "label": "http://www.trianglefcu.com"
  },
  {
    "value": "68210",
    "label": "http://trianglecu.org"
  },
  {
    "value": "119",
    "label": "http://www.tpfcu.net"
  },
  {
    "value": "62508",
    "label": "http://www.tricu.org"
  },
  {
    "value": "19608",
    "label": "http://www.tri-cu.com"
  },
  {
    "value": "21875",
    "label": "http://www.tricountyfederalcreditun"
  },
  {
    "value": "66640",
    "label": "http://www.tri-county.org"
  },
  {
    "value": "23198",
    "label": "http://www.trilakesfcu.com"
  },
  {
    "value": "67634",
    "label": "http://tvtcu.com"
  },
  {
    "value": "17999",
    "label": "http://triusfcu.com"
  },
  {
    "value": "6690",
    "label": "http://www.trivalleyservice.com"
  },
  {
    "value": "15741",
    "label": "http://www.tronavalley.com"
  },
  {
    "value": "68476",
    "label": "http://tropicalfcu.org"
  },
  {
    "value": "24104",
    "label": "http://www.trouvaillefcu.com"
  },
  {
    "value": "68691",
    "label": "http://www.truficu.org"
  },
  {
    "value": "10100",
    "label": "http://WWW.TRUFCU.COM"
  },
  {
    "value": "68718",
    "label": "http://www.trueccu.com"
  },
  {
    "value": "5892",
    "label": "http://www.truenorthfcu.org"
  },
  {
    "value": "24934",
    "label": "https://www.TrueSkyCU.org"
  },
  {
    "value": "4024",
    "label": "http://www.truecore.org"
  },
  {
    "value": "3323",
    "label": "http://truenergyfcu.org"
  },
  {
    "value": "17127",
    "label": "http://www.trugrocer.com"
  },
  {
    "value": "3130",
    "label": "www.truitycu.org"
  },
  {
    "value": "7840",
    "label": "http://WWW.TRULIANTFCU.ORG"
  },
  {
    "value": "66158",
    "label": "http://www.trumarkonline.org"
  },
  {
    "value": "6332",
    "label": "http://www.trunorthcu.org"
  },
  {
    "value": "21026",
    "label": "http://www.trunorthernfcu.org"
  },
  {
    "value": "95802",
    "label": "http://www.Trupartnercu.org"
  },
  {
    "value": "24390",
    "label": "http://www.truservice.net"
  },
  {
    "value": "24629",
    "label": "https://www.trustfcu.com"
  },
  {
    "value": "24626",
    "label": "http://www.trustarfcu.com"
  },
  {
    "value": "68722",
    "label": "https://www.trustonefinancial.org"
  },
  {
    "value": "63832",
    "label": "http://www.truwest.org"
  },
  {
    "value": "6667",
    "label": "https://www.tnstate.edu"
  },
  {
    "value": "24887",
    "label": "http://www.ttcu.com"
  },
  {
    "value": "5718",
    "label": "http://tucoemas.org"
  },
  {
    "value": "2157",
    "label": "http://www.tucsonfcu.com"
  },
  {
    "value": "66116",
    "label": "http://www.topcu.org"
  },
  {
    "value": "23540",
    "label": "http://www.tulane-loyolafcu.com"
  },
  {
    "value": "3218",
    "label": "https://www.tularefcu.org"
  },
  {
    "value": "64464",
    "label": "http://www.tuscaloosacountycu.com"
  },
  {
    "value": "7532",
    "label": "http://www.tvacu.com"
  },
  {
    "value": "2791",
    "label": "http://www.tuskegeefcu.com"
  },
  {
    "value": "68559",
    "label": "http://www.tvaccu.com"
  },
  {
    "value": "6311",
    "label": "TVHFCU.ORG"
  },
  {
    "value": "22681",
    "label": "http://www.twinoaksfcu.com"
  },
  {
    "value": "20290",
    "label": "http://www.twinriversfcu.org"
  },
  {
    "value": "68271",
    "label": "http://www.twinstarcu.com"
  },
  {
    "value": "5721",
    "label": "http://www.thfcu.org"
  },
  {
    "value": "20114",
    "label": "http://www.tworiversfcu.com"
  },
  {
    "value": "67439",
    "label": "http://www.txdotcu.com"
  },
  {
    "value": "67638",
    "label": "http://tylercityecu.com"
  },
  {
    "value": "10754",
    "label": "http://www.tyndall.org"
  },
  {
    "value": "15652",
    "label": "http://www.1776creditunion.org"
  },
  {
    "value": "1364",
    "label": "http://WWW.USFEDERALCU.ORG"
  },
  {
    "value": "4427",
    "label": "http://usplkefcu.org"
  },
  {
    "value": "16402",
    "label": "http://www.uspsfcu.org"
  },
  {
    "value": "12333",
    "label": "http://www.ufcwlocal72fcu.com"
  },
  {
    "value": "21586",
    "label": "http://www.cuonlineuhs.org"
  },
  {
    "value": "61793",
    "label": "http://www.usemployees.org"
  },
  {
    "value": "66372",
    "label": "http://www.usecuweb.com"
  },
  {
    "value": "2744",
    "label": "http://WWW.USECREDITUNION.ORG"
  },
  {
    "value": "13132",
    "label": "http://usifcu.com"
  },
  {
    "value": "19448",
    "label": "http://www.utfcu.org"
  },
  {
    "value": "21956",
    "label": "http://ufcw23fcu.org"
  },
  {
    "value": "4098",
    "label": "http://www.upconnection.org"
  },
  {
    "value": "24435",
    "label": "http://www.upemployees.org"
  },
  {
    "value": "62085",
    "label": "http://www.upscu.com"
  },
  {
    "value": "21532",
    "label": "http://WWW.UPSCREDITUNION.ORG"
  },
  {
    "value": "808",
    "label": "http://www.useagle.org"
  },
  {
    "value": "10580",
    "label": "http://www.u1stcommfcu.net"
  },
  {
    "value": "16946",
    "label": "http://www.uarkfcu.com"
  },
  {
    "value": "10973",
    "label": "www.ufcwpa.org"
  },
  {
    "value": "20613",
    "label": "http://www.ufirstfcu.net"
  },
  {
    "value": "15847",
    "label": "http://www.uhaulfedcu.com"
  },
  {
    "value": "8854",
    "label": "https://ukrainianfcu.org"
  },
  {
    "value": "17107",
    "label": "http://www.ukrnatfcu.org"
  },
  {
    "value": "7875",
    "label": "http://www.ukrfcu.com"
  },
  {
    "value": "8000",
    "label": "https://www.usmfcu.org"
  },
  {
    "value": "13107",
    "label": "http://WWW.USNEFCU.COM"
  },
  {
    "value": "21530",
    "label": "http://www.samopomich.com"
  },
  {
    "value": "21694",
    "label": "http://www.ulsfedcu.org"
  },
  {
    "value": "18181",
    "label": "http://www.umassfive.coop"
  },
  {
    "value": "23986",
    "label": "www.umatillacfcu.com"
  },
  {
    "value": "3945",
    "label": "http://www.umecreditunion.com"
  },
  {
    "value": "3313",
    "label": "http://www.umicofcu.net"
  },
  {
    "value": "64482",
    "label": "http://WWW.UNCLECU.ORG"
  },
  {
    "value": "24767",
    "label": "http://www.uhifcu.virtualcu.net"
  },
  {
    "value": "15732",
    "label": "http://www.unifyfcu.com"
  },
  {
    "value": "5736",
    "label": "http://www.unileverfcu.org"
  },
  {
    "value": "21244",
    "label": "http://www.ubtfcu.org"
  },
  {
    "value": "14329",
    "label": "http://unioncountycreditunion.com"
  },
  {
    "value": "15049",
    "label": "http://unionfidelityfcu.com"
  },
  {
    "value": "2260",
    "label": "https://www.upcu.org"
  },
  {
    "value": "68664",
    "label": "http://www.unionsquare.org"
  },
  {
    "value": "21100",
    "label": "http://uniontradesfcu.com"
  },
  {
    "value": "24131",
    "label": "http://www.uyfcu.og"
  },
  {
    "value": "66851",
    "label": "http://www.unisoncu.org"
  },
  {
    "value": "65071",
    "label": "http://www.unite-cu.org"
  },
  {
    "value": "6288",
    "label": "https://www.unitedfcu.com"
  },
  {
    "value": "60041",
    "label": "http://www.unitedcreditunion.com"
  },
  {
    "value": "63597",
    "label": "http://www.unitedcu.org"
  },
  {
    "value": "65557",
    "label": "http://www.unitedcreditunion.org"
  },
  {
    "value": "67587",
    "label": "http://unitedcu.coop"
  },
  {
    "value": "23952",
    "label": "http://www.unitedarkansas.org"
  },
  {
    "value": "65155",
    "label": "http://UNITEDASSOCIATIONCU.ORG"
  },
  {
    "value": "61186",
    "label": "http://www.ubccu.org"
  },
  {
    "value": "7244",
    "label": "ubifcu.com"
  },
  {
    "value": "12029",
    "label": "http://www.unitedcatholicsfcu.org"
  },
  {
    "value": "62144",
    "label": "http://www.unitedchurchescu.org"
  },
  {
    "value": "62796",
    "label": "http://nbeecu.org"
  },
  {
    "value": "7415",
    "label": "http://www.unitedcommunityfcu.org"
  },
  {
    "value": "60095",
    "label": "http://www.myuccu.com"
  },
  {
    "value": "68369",
    "label": "http://www.unitedccu.com"
  },
  {
    "value": "68410",
    "label": "http://www.uccumo.com"
  },
  {
    "value": "60263",
    "label": "http://www.unitedecu.com"
  },
  {
    "value": "67493",
    "label": "http://www.unitedenergycu.com"
  },
  {
    "value": "81688",
    "label": "https://www.uecu.com"
  },
  {
    "value": "61657",
    "label": "http://unitedfinancialcu.org"
  },
  {
    "value": "68251",
    "label": "http://www.uhcu.org"
  },
  {
    "value": "12673",
    "label": "http://www.uhcfcu.virtualcu.net"
  },
  {
    "value": "67009",
    "label": "http://UNITEDLABORCU.ORG"
  },
  {
    "value": "60408",
    "label": "http://www.unitedlocal.org"
  },
  {
    "value": "9248",
    "label": "http://www.unitedmembersfcu.com"
  },
  {
    "value": "5588",
    "label": "http://www.unfcu.org"
  },
  {
    "value": "24683",
    "label": "http://www.unfcu1.com"
  },
  {
    "value": "6214",
    "label": "http://WWW.UNWFCU.ORG"
  },
  {
    "value": "16577",
    "label": "WWW.UNITEDPOLESFCU.COM"
  },
  {
    "value": "67588",
    "label": "http://WWW.USTCU.COM"
  },
  {
    "value": "65578",
    "label": "http://www.unitedsavingscu.org"
  },
  {
    "value": "4714",
    "label": "http://usfcu.org"
  },
  {
    "value": "624",
    "label": "https://www.ussfcu.org"
  },
  {
    "value": "18195",
    "label": "http://www.utfinancial.org"
  },
  {
    "value": "97107",
    "label": "https://www.utxcu.com"
  },
  {
    "value": "10442",
    "label": "http://www.unitedtradesfcu.org"
  },
  {
    "value": "67248",
    "label": "www.unitedone.org"
  },
  {
    "value": "63440",
    "label": "http://www.unitusccu.com"
  },
  {
    "value": "4966",
    "label": "http://www.unitycatholiccu.org"
  },
  {
    "value": "24935",
    "label": "https://unityofeatonvillefcu.com"
  },
  {
    "value": "68589",
    "label": "http://www.unityone.org"
  },
  {
    "value": "785",
    "label": "http://www.universalfcu.com"
  },
  {
    "value": "64970",
    "label": "http://www.universal1cu.org"
  },
  {
    "value": "64893",
    "label": "http://WWW.UCSCU.ORG"
  },
  {
    "value": "11477",
    "label": "http://www.universalcoopfcu.com"
  },
  {
    "value": "1367",
    "label": "http://www.ufcu.org"
  },
  {
    "value": "1904",
    "label": "http://www.universityfederalcu.org"
  },
  {
    "value": "63133",
    "label": "http://www.ucumaine.com"
  },
  {
    "value": "64171",
    "label": "https://www.ucu.org"
  },
  {
    "value": "67348",
    "label": "http://WWW.MYUNICU.COOP"
  },
  {
    "value": "24710",
    "label": "http://www.ufirstcu.com"
  },
  {
    "value": "10465",
    "label": "http://www.uhfcu.com"
  },
  {
    "value": "60583",
    "label": "http://www.uoficreditunion.org"
  },
  {
    "value": "24165",
    "label": "http://www.ukfcu.org"
  },
  {
    "value": "12570",
    "label": "http://ulfcu.com"
  },
  {
    "value": "61552",
    "label": "http://www.umcu.org"
  },
  {
    "value": "2384",
    "label": "http://www.nufcu.org"
  },
  {
    "value": "24290",
    "label": "http://www.upennsfcu.org"
  },
  {
    "value": "13659",
    "label": "http://www.usafedcu.com"
  },
  {
    "value": "16174",
    "label": "http://www.uoftfcu.com"
  },
  {
    "value": "62504",
    "label": "http://www.uvacreditunion.org"
  },
  {
    "value": "66492",
    "label": "http://www.uwcu.org"
  },
  {
    "value": "9089",
    "label": "http://www.uniwyo.com"
  },
  {
    "value": "20842",
    "label": "http://WWW.UNOFCU.ORG"
  },
  {
    "value": "24422",
    "label": "http://www.upfcu.org"
  },
  {
    "value": "832",
    "label": "http://www.ucfcu.org"
  },
  {
    "value": "3819",
    "label": "http://www.udbell.org"
  },
  {
    "value": "67608",
    "label": "http://www.upscu.org"
  },
  {
    "value": "24484",
    "label": "http://www.upstatefcu.org"
  },
  {
    "value": "10746",
    "label": "http://www.upstatetelcofcu.org"
  },
  {
    "value": "65323",
    "label": "https://www.upwardcu.org"
  },
  {
    "value": "24781",
    "label": "http://www.ubc-fcu.org"
  },
  {
    "value": "24823",
    "label": "http://www.uufcu.org"
  },
  {
    "value": "64568",
    "label": "http://umecu-urbana.com"
  },
  {
    "value": "986",
    "label": "http://urefcu.org"
  },
  {
    "value": "19867",
    "label": "http://www.urwfcu.org"
  },
  {
    "value": "67720",
    "label": "http://www.usccu.org"
  },
  {
    "value": "17587",
    "label": "http://WWW.USAlliance.org"
  },
  {
    "value": "68459",
    "label": "http://www.usccreditunion.org"
  },
  {
    "value": "18343",
    "label": "http://usem.virtualcu.net/"
  },
  {
    "value": "24223",
    "label": "http://www.usffcu.com"
  },
  {
    "value": "287",
    "label": "http://WWW.USNEPFCU.ORG"
  },
  {
    "value": "12219",
    "label": "http://www.usscofcu.org"
  },
  {
    "value": "2740",
    "label": "http://www.usxfcu.org"
  },
  {
    "value": "10709",
    "label": "https://www.uccu.com"
  },
  {
    "value": "528",
    "label": "http://www.utahfirst.com"
  },
  {
    "value": "67076",
    "label": "http://www.utahheritagecu.org"
  },
  {
    "value": "67132",
    "label": "http://www.utahpowercu.org"
  },
  {
    "value": "11157",
    "label": "WWW.UTELFCU.NET"
  },
  {
    "value": "2252",
    "label": "http://ugefcu.com"
  },
  {
    "value": "64896",
    "label": "http://www.uecu.org"
  },
  {
    "value": "61085",
    "label": "http://UDCU.ORG"
  },
  {
    "value": "1851",
    "label": "uefcuonline.com"
  },
  {
    "value": "15536",
    "label": "http://VBPFCU.ORG"
  },
  {
    "value": "5530",
    "label": "http://www.vadpfcu.org"
  },
  {
    "value": "24429",
    "label": "http://VAHospitalFCU.org"
  },
  {
    "value": "9479",
    "label": "http://www.vapghefcu.org"
  },
  {
    "value": "11293",
    "label": "https://vlfcu.org"
  },
  {
    "value": "12290",
    "label": "http://www.valdostateachersfcu.org"
  },
  {
    "value": "6116",
    "label": "http://www.valexfcu.org"
  },
  {
    "value": "850",
    "label": "http://www.vfcu.net"
  },
  {
    "value": "60930",
    "label": "http://www.valleycreditunion.net"
  },
  {
    "value": "64169",
    "label": "https://www.valleycu.org/"
  },
  {
    "value": "649",
    "label": "http://WWW.VALLEY1ST.ORG"
  },
  {
    "value": "17841",
    "label": "http://www.valleyagfcu.org"
  },
  {
    "value": "66430",
    "label": "http://valleycommunities.org"
  },
  {
    "value": "63468",
    "label": "http://WWW.ITSME247.COM"
  },
  {
    "value": "68689",
    "label": "http://www.valleyfcu.com"
  },
  {
    "value": "68402",
    "label": "http://www.valleyfirstcu.org"
  },
  {
    "value": "18454",
    "label": "http://vhfcu.org"
  },
  {
    "value": "3574",
    "label": "http://www.vicfcu.org"
  },
  {
    "value": "64122",
    "label": "http://www.valleyoak.org"
  },
  {
    "value": "16756",
    "label": "http://valleyonecfcu.com"
  },
  {
    "value": "23467",
    "label": "https://www.itsme247.com/408"
  },
  {
    "value": "68712",
    "label": "http://www.valleystrong.com"
  },
  {
    "value": "10264",
    "label": "https://www.valleywideofpa.com"
  },
  {
    "value": "66924",
    "label": "http://www.valleystar.org"
  },
  {
    "value": "21788",
    "label": "http://www.valwoodpark.com"
  },
  {
    "value": "1125",
    "label": "http://www.vccfcu.com"
  },
  {
    "value": "68119",
    "label": "https://www.vanderbiltcu.org"
  },
  {
    "value": "67804",
    "label": "http://www.vcu.com"
  },
  {
    "value": "17630",
    "label": "http://VANTAGEPOINTFCU.ORG"
  },
  {
    "value": "5867",
    "label": "http://WWW.VANTAGETRUSTFCU.ORG"
  },
  {
    "value": "68558",
    "label": "http://www.vantagewest.org"
  },
  {
    "value": "6918",
    "label": "http://www.vaprfcu.com"
  },
  {
    "value": "24773",
    "label": "http://www.varexfederalcu.net"
  },
  {
    "value": "18301",
    "label": "http://www.vascofcu.com"
  },
  {
    "value": "67661",
    "label": "https://www.vatatcu.org/"
  },
  {
    "value": "68359",
    "label": "http://www.velocitycu.com"
  },
  {
    "value": "12458",
    "label": "http://www.velocitycommunity.org"
  },
  {
    "value": "68458",
    "label": "https://onlinebanking.vccuonline.ne"
  },
  {
    "value": "64418",
    "label": "http://Veridiancu.org"
  },
  {
    "value": "21354",
    "label": "http://www.veritasfcu.org"
  },
  {
    "value": "68517",
    "label": "http://www.veritycu.com"
  },
  {
    "value": "61540",
    "label": "vermilioncu.org"
  },
  {
    "value": "24405",
    "label": "http://www.vermontfederal.org"
  },
  {
    "value": "66777",
    "label": "https://www.Verveacu.com"
  },
  {
    "value": "68572",
    "label": "http://www.viacu.org"
  },
  {
    "value": "61522",
    "label": "http://www.vibecu.com"
  },
  {
    "value": "61093",
    "label": "http://www.vibrantcreditunion.org"
  },
  {
    "value": "24892",
    "label": "http://www.vrcums.com"
  },
  {
    "value": "24876",
    "label": "https://vickswoodcu.com"
  },
  {
    "value": "3736",
    "label": "http://victoriafcu.org"
  },
  {
    "value": "13672",
    "label": "http://vccefcu.org"
  },
  {
    "value": "10327",
    "label": "http://www.myvtfcu.com"
  },
  {
    "value": "138",
    "label": "http://vigocountyfcu.org"
  },
  {
    "value": "63965",
    "label": "http://www.vccu.org"
  },
  {
    "value": "66836",
    "label": "http://www.vacu.org"
  },
  {
    "value": "66700",
    "label": "http://www.vecu.org"
  },
  {
    "value": "3029",
    "label": "http://www.vsufcu.org"
  },
  {
    "value": "62536",
    "label": "http://www.tumcu.org"
  },
  {
    "value": "13492",
    "label": "http://www.vffcu.org"
  },
  {
    "value": "63137",
    "label": "http://www.visionone.org"
  },
  {
    "value": "14018",
    "label": "http://www.visionaryfcu.org"
  },
  {
    "value": "17429",
    "label": "www.visionsfcu.org"
  },
  {
    "value": "24536",
    "label": "https://www.vitalfcu.com"
  },
  {
    "value": "23294",
    "label": "http://www.vitelcocu.com"
  },
  {
    "value": "68677",
    "label": "http://www.vocal.coop"
  },
  {
    "value": "65388",
    "label": "http://www.vocalityccu.org"
  },
  {
    "value": "63389",
    "label": "http://voltcu.org"
  },
  {
    "value": "3458",
    "label": "http://voyagefcu.org"
  },
  {
    "value": "64408",
    "label": "http://www.vueccu.com"
  },
  {
    "value": "23287",
    "label": "http://vulcraftfcu.com"
  },
  {
    "value": "68490",
    "label": "http://www.vystarcu.org"
  },
  {
    "value": "10274",
    "label": "http://www.wbhfcu.com"
  },
  {
    "value": "12735",
    "label": "http://www.wbrtfcu.com"
  },
  {
    "value": "16268",
    "label": "www.wsscfcu.org"
  },
  {
    "value": "10047",
    "label": "http://www.wabfcu.org"
  },
  {
    "value": "6368",
    "label": "http://www.wacofcu.org"
  },
  {
    "value": "2049",
    "label": "http://www.waialuafcu.com"
  },
  {
    "value": "9924",
    "label": "http://www.wailukufcu.com"
  },
  {
    "value": "15016",
    "label": "https://wakefernfcu.org/"
  },
  {
    "value": "24579",
    "label": "http://www.wakotafcu.org"
  },
  {
    "value": "12068",
    "label": "http://www.walkercountyfcu.com"
  },
  {
    "value": "20610",
    "label": "http://www.WalkerCountyEducatorsFCU"
  },
  {
    "value": "6613",
    "label": "http://www.wlsefcu.com"
  },
  {
    "value": "14186",
    "label": "http://waltoncountyteachersfcu.com"
  },
  {
    "value": "15265",
    "label": "http://www.warecountyschoolemployee"
  },
  {
    "value": "7633",
    "label": "http://warrenmunicipalfcu.com"
  },
  {
    "value": "24780",
    "label": "http://www.wasatchpeaks.com"
  },
  {
    "value": "13035",
    "label": "http://www.watfcu.org"
  },
  {
    "value": "6353",
    "label": "http://www.wctfcu.net"
  },
  {
    "value": "60042",
    "label": "wsecu.org"
  },
  {
    "value": "68493",
    "label": "http://www.WPCU.ORG"
  },
  {
    "value": "22",
    "label": "http://www.wctfcu.com"
  },
  {
    "value": "3337",
    "label": "www.waterburypostalfcu.org/"
  },
  {
    "value": "16178",
    "label": "http://www.waterfrontfcu.org"
  },
  {
    "value": "8593",
    "label": "http://WATERSEDGEFCU.COM"
  },
  {
    "value": "67615",
    "label": "http://www.watertownmcu.com"
  },
  {
    "value": "6112",
    "label": "http://WATERTOWNPOSTALFCU.ORG"
  },
  {
    "value": "14832",
    "label": "http://www.wahfcu.com"
  },
  {
    "value": "17990",
    "label": "http://www.waunafcu.org"
  },
  {
    "value": "24501",
    "label": "https://www.wavefcu.org"
  },
  {
    "value": "14449",
    "label": "http://www.waynecountyfcu.org"
  },
  {
    "value": "17449",
    "label": "http://www.wccfcu.com"
  },
  {
    "value": "5928",
    "label": "http://www.wwfcu.org"
  },
  {
    "value": "2657",
    "label": "http://www.wbeefcu.com"
  },
  {
    "value": "68328",
    "label": "http://loggerscu.com"
  },
  {
    "value": "61150",
    "label": "https://www.wcucu.com"
  },
  {
    "value": "61525",
    "label": "http://www.wefloridafinancial.com"
  },
  {
    "value": "66677",
    "label": "https://www.weacu.com"
  },
  {
    "value": "16732",
    "label": "http://www.weatherheadfcu.com"
  },
  {
    "value": "24557",
    "label": "https://www.websterfirst.com"
  },
  {
    "value": "24937",
    "label": "http://wedevelopmentfcu.com"
  },
  {
    "value": "14257",
    "label": "http://www.weefederal.org"
  },
  {
    "value": "24807",
    "label": "http://www.welcomecu.org"
  },
  {
    "value": "63122",
    "label": "http://www.weldccu.com"
  },
  {
    "value": "10623",
    "label": "http://www.wellesleymunefcu.com"
  },
  {
    "value": "24786",
    "label": "https://www.wellnessfcu.org"
  },
  {
    "value": "12181",
    "label": "http://www.wellspringcu.com"
  },
  {
    "value": "24899",
    "label": "http://www.weokie.org"
  },
  {
    "value": "6685",
    "label": "http://www.wepcofcu.com"
  },
  {
    "value": "61233",
    "label": "http://www.wescreditunion.com"
  },
  {
    "value": "66703",
    "label": "https://www.wescom.org"
  },
  {
    "value": "20749",
    "label": "http://www.wesla.org"
  },
  {
    "value": "7821",
    "label": "https://www.wbvfcu.org"
  },
  {
    "value": "62801",
    "label": "http://www.westcommunitycu.org"
  },
  {
    "value": "62726",
    "label": "http://westmetroschoolscu.org"
  },
  {
    "value": "62347",
    "label": "http://www.WestMichiganCU.com"
  },
  {
    "value": "7910",
    "label": "http://www.wmpsfcu.com"
  },
  {
    "value": "9476",
    "label": "http://WWW.WESTPPFCU.COM"
  },
  {
    "value": "67592",
    "label": "http://www.westtexascu.com"
  },
  {
    "value": "17057",
    "label": "http://www.wvfcu.org"
  },
  {
    "value": "24888",
    "label": "http://www.wvccu.org"
  },
  {
    "value": "6233",
    "label": "http://www.westaircomm.com"
  },
  {
    "value": "15386",
    "label": "http://www.westarfcu.com"
  },
  {
    "value": "67208",
    "label": "http://WCCUCREDITUNION.COOP"
  },
  {
    "value": "9532",
    "label": "http://portchesterteachersfcu.org"
  },
  {
    "value": "66742",
    "label": "http://www.westconsincu.org"
  },
  {
    "value": "8113",
    "label": "http://www.westedgecu.org"
  },
  {
    "value": "67270",
    "label": "http://www.westerlyccu.com"
  },
  {
    "value": "61758",
    "label": "http://www.wccu.org"
  },
  {
    "value": "24731",
    "label": "http://WWW.WESTERNDIVISION.ORG"
  },
  {
    "value": "65426",
    "label": "http://WISECU.COM"
  },
  {
    "value": "82789",
    "label": "http://www.westernindianacu.com"
  },
  {
    "value": "16042",
    "label": "http://www.wnyfcu.com"
  },
  {
    "value": "13481",
    "label": "http://www.wsfcunion.com"
  },
  {
    "value": "4202",
    "label": "http://www.wsfcu.com"
  },
  {
    "value": "3972",
    "label": "http://www.wvista.com"
  },
  {
    "value": "65788",
    "label": "http://westerracu.com"
  },
  {
    "value": "5373",
    "label": "http://www.westexfcu.com"
  },
  {
    "value": "67915",
    "label": "https://wccu.net"
  },
  {
    "value": "68353",
    "label": "http://www.westmark.org"
  },
  {
    "value": "13842",
    "label": "http://www.wfcu.com"
  },
  {
    "value": "9042",
    "label": "www.wwcu.org"
  },
  {
    "value": "7652",
    "label": "http://www.westport.org"
  },
  {
    "value": "24812",
    "label": "http://www.westreet.org"
  },
  {
    "value": "15324",
    "label": "http://www.wscfcu.com"
  },
  {
    "value": "68227",
    "label": "http://www.weststar.org"
  },
  {
    "value": "65639",
    "label": "http://www.wexccu.com"
  },
  {
    "value": "66324",
    "label": "http://weycocreditunion.com"
  },
  {
    "value": "67458",
    "label": "https://wctcu.org/"
  },
  {
    "value": "66373",
    "label": "http://www.wecu.com"
  },
  {
    "value": "63211",
    "label": "http://www.wheatstatecu.com"
  },
  {
    "value": "1658",
    "label": "http://www.wheatlandcu.com"
  },
  {
    "value": "61003",
    "label": "http://www.wheelhousecu.com"
  },
  {
    "value": "24428",
    "label": "https://whitecountyfcu.org"
  },
  {
    "value": "63189",
    "label": "http://www.whiteeaglecu.com"
  },
  {
    "value": "64421",
    "label": "http://www.whitercu.com"
  },
  {
    "value": "67201",
    "label": "http://www.whiterivercu.com"
  },
  {
    "value": "68296",
    "label": "http://whiterosecu.com"
  },
  {
    "value": "7960",
    "label": "http://www.wsfcu.org"
  },
  {
    "value": "61949",
    "label": "http://www.whitefishcu.com"
  },
  {
    "value": "62432",
    "label": "http://www.whitesvillecommunitycu.c"
  },
  {
    "value": "61760",
    "label": "http://www.whitewaterccu.org"
  },
  {
    "value": "3536",
    "label": "http://www.wwrfcu.org"
  },
  {
    "value": "198",
    "label": "http://www.wrfcu.org"
  },
  {
    "value": "3907",
    "label": "http://www.wichitafcu.com"
  },
  {
    "value": "6317",
    "label": "wffedcu.com"
  },
  {
    "value": "2112",
    "label": "http://www.wftfcu.com"
  },
  {
    "value": "1117",
    "label": "https://www.widgetfinancial.com"
  },
  {
    "value": "60057",
    "label": "http://www.wildfirecu.org"
  },
  {
    "value": "63459",
    "label": "http://www.wcccu.us"
  },
  {
    "value": "65211",
    "label": "http://www.williamsportteacherscu.c"
  },
  {
    "value": "8618",
    "label": "http://www.williamsvillefcu.com"
  },
  {
    "value": "8921",
    "label": "http://www.wkfcu.org"
  },
  {
    "value": "8474",
    "label": "http://wifcu.com"
  },
  {
    "value": "11347",
    "label": "http://www.wpffcu.org"
  },
  {
    "value": "16373",
    "label": "http://www.windthorstfcu.org"
  },
  {
    "value": "68601",
    "label": "http://www.wingsfinancial.com"
  },
  {
    "value": "66437",
    "label": "http://www.wincu.org"
  },
  {
    "value": "63627",
    "label": "http://wsfcu.net"
  },
  {
    "value": "97076",
    "label": "http://www.winsouthcu.com"
  },
  {
    "value": "24814",
    "label": "http://www.mywsfcu.org"
  },
  {
    "value": "2301",
    "label": "http://WWW.WINTHROPCREDITUNION.ORG"
  },
  {
    "value": "96808",
    "label": "http://wiremenscu.com"
  },
  {
    "value": "66686",
    "label": "http://www.wislat.com"
  },
  {
    "value": "66625",
    "label": "bsdc.onlinecu.com/wimedical/"
  },
  {
    "value": "66854",
    "label": "http://wncccu.org"
  },
  {
    "value": "15375",
    "label": "http://wpfcu.com"
  },
  {
    "value": "62203",
    "label": "http://www.wolverinescu.com"
  },
  {
    "value": "16",
    "label": "http://WWW.WOODMENFCU.ORG"
  },
  {
    "value": "64144",
    "label": "http://www.worcestercu.com"
  },
  {
    "value": "67683",
    "label": "http://www.wfdcu.org"
  },
  {
    "value": "24868",
    "label": "http://www.wpdfcu.com"
  },
  {
    "value": "24923",
    "label": "http://www.wcu.com"
  },
  {
    "value": "67892",
    "label": "http://www.workmenscirclecu.com"
  },
  {
    "value": "8797",
    "label": "http://www.congressionalfcu.org"
  },
  {
    "value": "66328",
    "label": "http://wpcu.coop"
  },
  {
    "value": "15028",
    "label": "wufface.com"
  },
  {
    "value": "12938",
    "label": "http://wvngfcu.com"
  },
  {
    "value": "14611",
    "label": "http://wvucu.com"
  },
  {
    "value": "9094",
    "label": "http://www.wyhy.org"
  },
  {
    "value": "12330",
    "label": "http://www.wymarfcu.com"
  },
  {
    "value": "12354",
    "label": "http://www.wyocentral.org"
  },
  {
    "value": "21345",
    "label": "http://www.wyomingareafcu.com"
  },
  {
    "value": "11689",
    "label": "http://www.wyrope.org"
  },
  {
    "value": "12748",
    "label": "http://www.xula.edu"
  },
  {
    "value": "16218",
    "label": "http://www.xcelfcu.org"
  },
  {
    "value": "23017",
    "label": "http://www.xplorefcu.com"
  },
  {
    "value": "6775",
    "label": "http://www.y12fcu.org"
  },
  {
    "value": "10502",
    "label": "http://www.yantisfcu.com"
  },
  {
    "value": "9293",
    "label": "www.yolofcu.org"
  },
  {
    "value": "19429",
    "label": "http://www.yefcu.org"
  },
  {
    "value": "96812",
    "label": "http://www.ycsd.org"
  },
  {
    "value": "67757",
    "label": "https://yourbestcu.com/"
  },
  {
    "value": "11972",
    "label": "http://www.yourchoicefcu.com"
  },
  {
    "value": "16702",
    "label": "http://www.yourhometownfcu.org"
  },
  {
    "value": "7674",
    "label": "http://www.yourlegacyfcu.com"
  },
  {
    "value": "5732",
    "label": "http://yscu.org"
  },
  {
    "value": "17550",
    "label": "http://yorkvillefcu.org"
  },
  {
    "value": "6424",
    "label": "www.ycfcu.com"
  },
  {
    "value": "62426",
    "label": "http://www.zealcu.org"
  },
  {
    "value": "871",
    "label": "http://zellcofcu.org"
  },
  {
    "value": "60467",
    "label": "http://www.ziacu.org"
  }
];
